import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

export default function useMdDown() {
  const theme = useTheme();

  const getMdDown = () => window.innerWidth <= theme.breakpoints.values.md;

  const [mdDown, setMdDown] = useState(getMdDown());

  useEffect(() => {
    const onResize = () => {
      setMdDown(getMdDown());
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return mdDown;
}
