import type { FC } from 'react';
import type { Theme } from '@mui/material';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import type { SxProps } from '@mui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoValorantV: FC<LogoProps> = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 1279.96 1279.96" {...props}>
    <g id="valorant-logo-v">
      <path d="M1093.36,637.8V290.17c0-5.37-6.7-7.6-10.27-3.58L703.28,761.13c-3.13,3.57-.45,9.38,4.47,9.38H982.1a23.49,23.49,0,0,0,18.77-9.38L1087.56,653a23,23,0,0,0,5.8-15.2Zm-888.3,15.64L490.14,1010c4.47,5.8,11.62,8.93,19.21,8.93H783.71c4.91,0,7.59-5.36,4.47-8.93L209.53,287c-3.58-4-10.28-1.79-10.28,3.57V638.25a27.8,27.8,0,0,0,5.81,15.19Z" />
    </g>
  </SvgIcon>
);

export default LogoValorantV;
