import { ValorantItemListObject } from 'src/components/app/board/tools/ValorantItemList';
import { ObjectTypes } from 'src/types/board';
import CrossIcon from '../../icons/CrossIcon';
import PinIcon from '../../icons/PinIcon';
import SpikeIcon from '../../icons/SpikeIcon';
import SkullIcon from '../../icons/SkullIcon';

export const BASIC_OBJECTS: { [key in ObjectTypes]: ValorantItemListObject } = {
  [ObjectTypes.SPIKE]: {
    title: 'Spike',
    value: ObjectTypes.SPIKE,
    icon: <SpikeIcon />
  },
  [ObjectTypes.CROSS]: {
    title: 'Cross',
    value: ObjectTypes.CROSS,
    icon: <CrossIcon />
  },
  [ObjectTypes.PIN]: {
    title: 'Pin',
    value: ObjectTypes.PIN,
    icon: <PinIcon />
  },
  [ObjectTypes.SKULL]: {
    title: 'Skull',
    value: ObjectTypes.SKULL,
    icon: <SkullIcon />
  }
};
