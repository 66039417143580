import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Button,
  Chip,
  Divider,
  Grid,
  Typography,
  useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import useAuth from 'src/hooks/useAuth';
import getInitials from 'src/utils/getInitials';
import CustomAvatar from '../CustomAvatar';
import { CreateTeamStepOneData } from './CreateTeamStepOne';
import { ChipItem } from './CreateTeamStepTwo';
import EmailIcon from '@mui/icons-material/Email';
import { TeamInviteTeamside } from 'src/types/team';

export interface CreateTeamStepThreeData extends CreateTeamStepOneData {
  invitees: ChipItem[];
}

interface CreateTeamStepThreeProps {
  backToStepTwo: Function;
  stepTwoData: ChipItem[];
  stepOneData: any;
  submitFunction: Function;
}

const CreateTeamStepThree: FC<CreateTeamStepThreeProps> = (props) => {
  const { backToStepTwo, stepTwoData, stepOneData, submitFunction } = props;

  const theme = useTheme();

  const { user } = useAuth();

  if (!stepOneData) {
    return null;
  }

  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: '100%', mb: 6 }}
      >
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            height: '100%',
            p: 4,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: `${theme.shape.borderRadius}px`
          }}
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              xs={12}
              sx={{ pb: 2 }}
            >
              <CustomAvatar src={stepOneData.photoURL} isTeamAvatar />

              <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                  pl: 2,
                  width: 'auto'
                }}
              >
                <Typography color="textPrimary" variant="h3">
                  {stepOneData.name}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  a Valorant Team
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ pb: 2 }}>
              <Divider />
            </Grid>

            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              {stepOneData.description ? (
                <Typography color="textPrimary" variant="body2">
                  {stepOneData.description}
                </Typography>
              ) : (
                <Typography
                  color="textPrimary"
                  variant="body2"
                  sx={{ maxWidth: '370px' }}
                >
                  You have not provided a team description. This is optional and
                  can always be changed later on.
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sx={{ py: 2 }}>
              <Divider />
            </Grid>

            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ width: '100%' }}
              >
                <Grid item sx={{ p: '0 !important' }}>
                  <Chip
                    avatar={
                      <CustomAvatar
                        src={user.photoURL}
                        alt={getInitials(user.displayName)}
                        sizeFactor={0.55}
                        isTeamAvatar={false}
                      />
                    }
                    label={user.displayName}
                    sx={{ m: 0.5, pl: 0.5 }}
                  />
                </Grid>

                {stepTwoData.map((data) => (
                  <Grid item key={data.username} sx={{ p: '0 !important' }}>
                    <Chip
                      avatar={
                        <CustomAvatar
                          alt={getInitials(
                            data.displayName || data.username || data.email
                          )}
                          src={data.photoURL}
                          sizeFactor={0.55}
                          isTeamAvatar={false}
                        >
                          {!data.displayName && <EmailIcon />}
                        </CustomAvatar>
                      }
                      label={data.displayName || data.username || data.email}
                      sx={{ m: 0.5, pl: 0.5 }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          sx={{}}
          variant="text"
          startIcon={<ArrowBackIcon />}
          onClick={() => backToStepTwo()}
        >
          Back
        </Button>

        <Button
          sx={{}}
          variant="contained"
          onClick={() =>
            submitFunction({
              name: stepOneData.name,
              description: stepOneData.description,
              photoURL: stepOneData.photoURL,
              invitees: stepTwoData
            })
          }
        >
          Create Team
        </Button>
      </Grid>

      <Grid item />
    </Grid>
  );
};

CreateTeamStepThree.propTypes = {
  backToStepTwo: PropTypes.func,
  stepOneData: PropTypes.object,
  stepTwoData: PropTypes.array,
  submitFunction: PropTypes.func
};

export default CreateTeamStepThree;
