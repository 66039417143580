import { Team } from 'src/types/team';
import { AccountData } from 'src/types/user';

const getSpaceTier = (
  isTeamSpace: boolean,
  team: Team,
  accountData: AccountData
): 'BASIC' | 'PRO' | '' => {
  if (isTeamSpace) {
    if (!team) {
      return '';
    }

    if (team.spaceTier === 'basic') {
      return 'BASIC';
    }
    return 'PRO';
  }

  if (!accountData) {
    return '';
  }

  if (accountData.spaceTier === 'basic') {
    return 'BASIC';
  }
  return 'PRO';
};

export default getSpaceTier;
