import {
  Box,
  Button,
  Container,
  DialogContent,
  Grid,
  Theme,
  useTheme
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import CustomDialogHeader from 'src/components/CustomDialogHeader';
import { toPersonalPlay, toTeamPlay } from 'src/nav';
import { PlayInfo } from 'src/types/play';
import mapToSource from 'src/utils/maptoSource';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import AspectRatio from '../../AspectRatio';
import { ClonePlayDialog, DeletePlayDialog } from './controls';
import CustomDialog from 'src/components/CustomDialog';

export interface IProps {
  playInfo: PlayInfo;
  open: boolean;
  setOpen: Function;
  activeWorkspace: string;
}

export default function PlayPreviewDialog(props: IProps) {
  const { open, setOpen, playInfo, activeWorkspace } = props;
  const theme: Theme = useTheme();
  const navigate = useNavigate();
  const [clonePlaysOpen, setClonePlaysOpen] = useState<boolean>(false);
  const [deletePlayDialogOpen, setDeletePlayDialogOpen] =
    useState<boolean>(false);
  const [playPreviewLoaded, setPlayPreviewLoaded] = useState<boolean>(true);

  const handleClickEditPlay = async () => {
    // open/join  session when in workspace

    // get play
    navigate(
      activeWorkspace
        ? toTeamPlay(activeWorkspace, playInfo.id)
        : toPersonalPlay(playInfo.id)
    );
  };

  const handleClickDeletePlay = () => {
    setDeletePlayDialogOpen(true);
  };

  const onClose = () => setOpen(false);
  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      title={playInfo.title}
      width={440}
      buttons={[
        {
          variant: 'text',
          title: 'Delete',
          onClick: handleClickDeletePlay
        },
        {
          variant: 'outlined',
          title: 'Clone',
          onClick: () => setClonePlaysOpen(true)
        },
        {
          variant: 'contained',
          title: 'Edit',
          onClick: () => handleClickEditPlay()
        }
      ]}
    >
      <Container
        sx={{
          width: '100%',
          height: '100%',
          padding: '0px !important',
          maxHeight: '750px'
        }}
      >
        <Box sx={{ width: '100%', height: '100%' }}>
          <AspectRatio ratio={1 / 1}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                height: '100%',
                width: '100%',
                borderRadius: `${theme.shape.borderRadius}px`,
                border: `1px solid ${theme.palette.divider}`,
                overflow: 'hidden',
                backgroundColor: theme.palette.background.default
              }}
            >
              {playPreviewLoaded ? (
                <img
                  style={{
                    width: '100%',
                    height: 'auto',
                    filter: 'saturate(100%)'
                  }}
                  alt={playInfo.title}
                  onError={() => setPlayPreviewLoaded(false)}
                  src={playInfo.photoURL || mapToSource(playInfo.map)}
                />
              ) : (
                <NoPhotographyIcon
                  sx={{ fontSize: 70, color: theme.palette.background.paper }}
                />
              )}
            </Grid>
          </AspectRatio>
        </Box>

        <ClonePlayDialog
          open={clonePlaysOpen}
          setOpen={setClonePlaysOpen}
          playID={playInfo.id}
        />
        <DeletePlayDialog
          open={deletePlayDialogOpen}
          setOpen={setDeletePlayDialogOpen}
          playID={playInfo.id}
        />
      </Container>
    </CustomDialog>
  );
}
