import DeleteIcon from '@mui/icons-material/Delete';
import MovingIcon from '@mui/icons-material/Moving';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import BlurOffIcon from '@mui/icons-material/BlurOff';
import {
  Box,
  Fade,
  IconButton,
  Paper,
  Popper,
  Stack,
  Theme,
  useTheme
} from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import CustomTooltip from 'src/components/CustomTooltip';
import { customOpacityDarkThemeBoard } from 'src/constants';
import useGlobalDOMEvents from 'src/hooks/useGlobalDOMEvents';
import { addCommand } from 'src/slices/board/boardModel';
import { setSelectedObject } from 'src/slices/board/boardUi';
import store, { RootState, useSelector } from 'src/store';
import {
  ActionTypes,
  IAddRemoveValue,
  ICommand,
  ICommandParams,
  IConnections,
  IObject
} from 'src/types/board';
import createResourceId from 'src/utils/createResourceId';
import hexToRGB from 'src/utils/hexToRGB';

export const menuButtonClass = 'cb-object-menu-button';

interface IProps {
  object: IObject;
  open: boolean;
  anchorEl: any;
  menuPosition: 'top' | 'bottom';
}
const ObjectMenu: FC<IProps> = (props: IProps): ReactElement => {
  const { object, anchorEl, open, menuPosition } = props;
  const { playId, teamId } = useParams();
  const sessionId = teamId ? playId : null;
  const theme: Theme = useTheme();
  const { activeStep } = useSelector(
    (state: RootState) => state.boardSlice.model
  );

  useGlobalDOMEvents({
    keydown(e: KeyboardEvent) {
      if (e.key === 'Delete') {
        e.preventDefault();
        handleDeleteClick();
      } else if (e.key === 'Escape') {
        e.preventDefault();
        store.dispatch(setSelectedObject(null));
      }
    }
  });

  const toggleHideAreaOfEffect = () => {
    const newObj: IObject = {
      ...object,
      areaOfEffect: {
        ...object.areaOfEffect,
        hidden: !object.areaOfEffect.hidden
      }
    };

    const oldObj: IObject = {
      ...object
    };

    const command: ICommand = {
      id: createResourceId(),
      type: ActionTypes.CHANGE_OBJECT,
      params: {
        id: object.id,
        value: {
          newObj,
          oldObj
        },
        stepLayer: store.getState().boardSlice.model.activeStep,
        sessionId,
        playId
      } as ICommandParams,
      inverse: ActionTypes.REVERT_OBJECT
    };

    store.dispatch(addCommand(command));
  };

  const handleDeleteClick = () => {
    // add connection to the two connected objects
    const connections: IConnections = {};
    object.connections?.forEach((value) => {
      if (store.getState()?.boardSlice?.model?.connections[value]) {
        connections[value] =
          store.getState().boardSlice.model.connections[value];
      }
    });

    const command: ICommand = {
      id: createResourceId(),
      type: ActionTypes.REMOVE_OBJECT,
      params: {
        value: { object, connections } as IAddRemoveValue,
        stepLayer: activeStep,
        sessionId,
        playId
      } as ICommandParams,
      inverse: ActionTypes.ADD_OBJECT
    };

    store.dispatch(addCommand(command));
    store.dispatch(setSelectedObject(null));
  };

  const buttonSx = { margin: theme.spacing(0.5) };
  return (
    <Popper open={open} anchorEl={anchorEl} placement={menuPosition} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={100}>
          <Paper
            sx={{
              backgroundColor: theme.palette.background.default,
              boxShadow: theme.shadows[19],
              mb: menuPosition === 'top' && 1.5,
              mt: menuPosition === 'bottom' && 1.5
            }}
          >
            <Paper
              sx={{
                backgroundColor: hexToRGB(
                  theme.palette.primary.main,
                  (customOpacityDarkThemeBoard * 2).toString()
                )
              }}
            >
              <Stack direction="row">
                <CustomTooltip
                  title="Connect"
                  subtitle="Coming soon"
                  subTitleType="info"
                  placement="top"
                >
                  <Box>
                    <IconButton
                      className={menuButtonClass}
                      component="div"
                      disabled
                      id="text-button"
                      size="large"
                      sx={buttonSx}
                    >
                      <MovingIcon />
                    </IconButton>
                  </Box>
                </CustomTooltip>

                {object.areaOfEffect && (
                  <CustomTooltip
                    title={
                      object.areaOfEffect.hidden ? 'Show range' : 'Hide range'
                    }
                    placement="top"
                  >
                    <IconButton
                      className={menuButtonClass}
                      size="large"
                      onClick={toggleHideAreaOfEffect}
                      sx={buttonSx}
                    >
                      {object.areaOfEffect.hidden ? (
                        <BlurOnIcon />
                      ) : (
                        <BlurOffIcon />
                      )}
                    </IconButton>
                  </CustomTooltip>
                )}

                <CustomTooltip title="Delete" subtitle="DEL" placement="top">
                  <IconButton
                    className={menuButtonClass}
                    size="large"
                    onClick={handleDeleteClick}
                    sx={buttonSx}
                  >
                    <DeleteIcon />
                  </IconButton>
                </CustomTooltip>
              </Stack>
            </Paper>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default ObjectMenu;
