/* eslint-disable react/no-array-index-key */
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import algoliasearch from 'algoliasearch/lite';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { useState } from 'react';
import {
  Configure,
  connectHits,
  connectSearchBox,
  InstantSearch
} from 'react-instantsearch-dom';
import { algoliaConfig } from 'src/config';
import { RootState, useSelector } from 'src/store';
import getInitials from 'src/utils/getInitials';
import SearchIcon from '../../icons/Search';
import CustomAvatar from './CustomAvatar';

const searchClient = algoliasearch(
  algoliaConfig.appId,
  algoliaConfig.searchApiKey
);

interface ChipItem {
  username: string;
  displayName: string;
  email: string;
  photoURL: string | null;
}

const SearchBox = (props: any) => {
  const { currentRefinement, refine, theme } = props;

  return (
    <form noValidate action="" role="search">
      <TextField
        fullWidth
        type="search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="primary" fontSize="small" />
            </InputAdornment>
          ),
          sx: {
            borderRadius: 300,
            pl: 3,
            '& fieldset': {
              borderColor: theme.palette.text.secondary
            },
            '& ::placeholder': {
              color: `${theme.palette.text.secondary} !important`
            },
            height: '40px'
          }
        }}
        onChange={(event) => refine(event.currentTarget.value)}
        variant="outlined"
        placeholder="Search user by username..."
        value={currentRefinement}
      />
    </form>
  );
};

const CustomSearchBox = connectSearchBox(SearchBox);

const Hits = (props: any) => {
  const { hits, invitees, setInvitees, accountUsername, hideUsernames } = props;

  return (
    <List>
      {hits.map(
        (hit) =>
          !(hit.username === accountUsername) &&
          !hideUsernames.includes(hit.username) && (
            <Box sx={{ width: '100%' }} key={hit.username}>
              <ListItem sx={{ px: 1, py: 0 }}>
                <>
                  <ListItemAvatar>
                    <CustomAvatar
                      src={hit.photoURL}
                      sizeFactor={0.95}
                      alt={getInitials(
                        hit.displayName ? hit.displayName : hit.username
                      )}
                      isTeamAvatar={false}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{
                      sx: {
                        color: 'text.primary'
                      }
                    }}
                    primary={hit.displayName}
                    secondary={hit.username}
                  />

                  {!invitees.some(
                    (member) => member.username === hit.username
                  ) ? (
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={() =>
                          setInvitees([
                            ...invitees,
                            {
                              username: hit.username,
                              displayName: hit.displayName,
                              email: null,
                              photoURL: hit.photoURL
                            }
                          ])
                        }
                        size="large"
                      >
                        <AddIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  ) : (
                    <ListItemSecondaryAction>
                      <IconButton edge="end" disabled size="large">
                        <CheckIcon sx={{ color: 'success.main' }} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  )}
                </>
              </ListItem>
            </Box>
          )
      )}
    </List>
  );
};

const CustomHits = connectHits(Hits);

export interface UserSearchProps {
  invitees: ChipItem[];
  setInvitees: Function;
  hideUsernames: string[];
}

const UserSearch: FC<UserSearchProps> = (props) => {
  const theme = useTheme();
  const [searching, setSearching] = useState<boolean>(false);
  const username = useSelector((state: RootState) =>
    state.accountDataSlice.accountData
      ? state.accountDataSlice.accountData.username
      : null
  );
  const { invitees, setInvitees, hideUsernames } = props;

  const handleDeleteChip = (chipToDelete) => () => {
    setInvitees((chips) =>
      chips.filter((chip) => chip.username !== chipToDelete.username)
    );
  };

  return (
    <Grid container>
      <Grid
        item
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        xs={12}
      >
        <Grid item sx={{ width: '100%' }}>
          {invitees.length > 0 ? (
            <>
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: '100%',
                  px: 2,
                  minHeight: '100px'
                }}
              >
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  {invitees.map((data) => (
                    <Grid item key={data.username} sx={{ p: '0 !important' }}>
                      <Chip
                        avatar={
                          <CustomAvatar
                            isTeamAvatar={false}
                            src={data.photoURL}
                            alt={getInitials(
                              data.displayName || data.username || data.email
                            )}
                            sizeFactor={0.75}
                          />
                        }
                        label={data.displayName || data.username || data.email}
                        onDelete={handleDeleteChip(data)}
                        sx={{ m: 0.5, pl: '3px' }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid
              item
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ height: '100px' }}
            >
              <Typography variant="body2" color="text.secondary" align="center">
                Search for users by their username (e.g. user#1234) <br /> &
                select the ones you want to invite to the team.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ pt: 3, px: 3 }}>
        <InstantSearch
          indexName="find_user"
          searchClient={searchClient}
          onSearchStateChange={(searchState) =>
            /[a-zA-Z]/g.test(searchState.query) || /\d/g.test(searchState.query)
              ? setSearching(true)
              : setSearching(false)
          }
        >
          <Configure hitsPerPage={3} />

          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            xs={12}
          >
            <Grid item xs={12} sx={{ pb: 1 }}>
              <CustomSearchBox theme={theme} />
            </Grid>

            <Grid item xs={12}>
              {searching && (
                <>
                  <Box
                    sx={{
                      height: 'auto',
                      width: '100%'
                    }}
                  >
                    <CustomHits
                      theme={theme}
                      invitees={invitees}
                      setInvitees={setInvitees}
                      accountUsername={username}
                      hideUsernames={hideUsernames}
                    />
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </InstantSearch>
      </Grid>
    </Grid>
  );
};

UserSearch.propTypes = {
  invitees: PropTypes.array,
  setInvitees: PropTypes.func,
  hideUsernames: PropTypes.array
};

export default UserSearch;
