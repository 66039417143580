import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { FC, useEffect, useState } from 'react';
import { promoteToTeamAdmin } from 'src/data/firestore/setters/team';

interface PromoteTeamMemberDialogProps {
  teamID: string;
  teamDisplayName: string;
  targetUserID: string;
  targetUserDisplayName: string;
  open: boolean;
  setOpen: Function;
}

const PromoteTeamMemberDialog: FC<PromoteTeamMemberDialogProps> = (props) => {
  const {
    teamID,
    teamDisplayName,
    targetUserID,
    targetUserDisplayName,
    open,
    setOpen
  } = props;
  const [waitingForFunction, setWaitingForFunction] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handlePromoteTeamMember = async () => {
    try {
      setWaitingForFunction(true);

      await promoteToTeamAdmin(teamID, targetUserID);

      setWaitingForFunction(false);

      enqueueSnackbar(`Promoted '${targetUserDisplayName}' to 'Admin'`, {
        variant: 'success'
      });

      setOpen(false);
    } catch (err) {
      console.error(err);

      setWaitingForFunction(false);

      enqueueSnackbar(
        `Promoting '${targetUserDisplayName}' to 'Admin' failed`,
        {
          variant: 'error'
        }
      );

      setOpen(false);
    }
  };

  // cleanup on unmount
  useEffect(() => {
    const returnVal = null;
    return () => returnVal;
  }, []);

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        if (!waitingForFunction) {
          setOpen(false);
        }
      }}
    >
      {waitingForFunction ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '200px', width: '100%' }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <DialogContent sx={{ height: 'auto', maxHeight: '750px' }}>
          <Container
            sx={{
              pt: 2
            }}
          >
            <Typography
              color="textPrimary"
              component="span"
              gutterBottom
              variant="body1"
              sx={{ pb: 1, fontWeight: 500 }}
            >
              You are about to promote
              <Box fontStyle="italic" display="inline">
                {' '}
                {targetUserDisplayName}{' '}
              </Box>
              to be an admin of
              <Box fontStyle="italic" display="inline">
                {' '}
                {teamDisplayName}{' '}
              </Box>
              . If you continue, you two will have the same permissions within
              the team. Are you sure you want to continue ?
            </Typography>

            <Grid container sx={{ py: 3 }} spacing={2}>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => setOpen(false)}
                  variant="text"
                  sx={{ width: '100%' }}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => handlePromoteTeamMember()}
                  variant="contained"
                  color="primary"
                  sx={{
                    width: '100%'
                  }}
                >
                  Promote
                </Button>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
      )}
    </Dialog>
  );
};

PromoteTeamMemberDialog.propTypes = {
  teamID: PropTypes.string,
  teamDisplayName: PropTypes.string,
  targetUserID: PropTypes.string,
  targetUserDisplayName: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func
};

export default PromoteTeamMemberDialog;
