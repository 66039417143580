import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { StorageReference } from 'firebase/storage';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { FC, useEffect, useState } from 'react';
import { avatarSize } from 'src/constants';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { RootState, useSelector } from 'src/store';
import * as Yup from 'yup';
import CustomAvatar from '../CustomAvatar';
import ImageUploader from '../ImageUploader';

interface CreateTeamStepOneProps {
  stepOneData: any;
  submitFunction: Function;
  setUploadedImageRef: Function;
  waitingForImage: boolean;
  setWaitingForImage: Function;
}

export interface CreateTeamStepOneData {
  name: string;
  description: string | null;
  photoURL: string | null;
  photoRef: StorageReference | null;
}

const largerAvatarSize = avatarSize * 1.2;

const displayUploadedImage = (
  imageDownloadURL: string | null,
  waitingForImage: boolean
) => {
  if (waitingForImage) {
    return (
      <CircularProgress
        style={{
          height: largerAvatarSize,
          width: largerAvatarSize,
          padding: 15
        }}
      />
    );
  }

  if (imageDownloadURL) {
    return (
      <CustomAvatar src={imageDownloadURL} sizeFactor={1.25} isTeamAvatar />
    );
  }

  return <CustomAvatar src={null} sizeFactor={1.25} isTeamAvatar />;
};

const CreateTeamStepOne: FC<CreateTeamStepOneProps> = (props) => {
  const { waitingForImage, setWaitingForImage } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { submitFunction, stepOneData, setUploadedImageRef } = props;
  const [imageDownloadURL, setImageDownloadURL] = useState(
    stepOneData && stepOneData.photoURL ? stepOneData.photoURL : null
  );
  const [imageRef, setImageRef] = useState<StorageReference | null>(
    stepOneData && stepOneData.photoRef ? stepOneData.photoRef : null
  );
  const { user } = useAuth();
  const username = useSelector((state: RootState) =>
    state.accountDataSlice.accountData
      ? state.accountDataSlice.accountData.username
      : null
  );
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    setUploadedImageRef(imageRef);
  }, [imageRef]);

  return (
    <Formik
      initialValues={{
        name: stepOneData ? stepOneData.name : '',
        description: stepOneData ? stepOneData.description : '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .min(3, 'Must be at least 3 characters long')
          .max(30, 'Cannot be longer than 30 characters')
          .required('Required'),
        description: Yup.string().min(10, 'Must be at least 10 characters long')
      })}
      onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const createTeamStepOneData: CreateTeamStepOneData = {
            name: values.name,
            description: values.description,
            photoURL: imageDownloadURL,
            photoRef: imageRef
          };

          submitFunction(createTeamStepOneData);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item lg={4} md={4} xs={12}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  mt: 3,
                  width: '100%'
                }}
              >
                <Box
                  sx={{
                    mb: 1,
                    borderRadius: '50%'
                  }}
                >
                  {displayUploadedImage(imageDownloadURL, waitingForImage)}
                </Box>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    mt: 2,
                    mb: 1,
                    width: '100%',
                    px: 4
                  }}
                >
                  <ImageUploader
                    downloadURL={imageDownloadURL}
                    setDownloadURL={setImageDownloadURL}
                    setImageRef={setImageRef}
                    imageRef={imageRef}
                    storagePath={`users/${user.id}/`}
                    prefix={username}
                    setWaitingForImage={setWaitingForImage}
                    enqueueSnackbar={enqueueSnackbar}
                  />
                </Box>

                <Typography
                  color="textSecondary"
                  variant="body2"
                  sx={{ mt: 1 }}
                >
                  Click to upload an avatar or change the avatar you have
                  chosen.
                </Typography>
              </Box>
            </Grid>

            <Grid item lg={8} md={8} xs={12} container sx={{ mt: 1 }}>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={
                      (touched.name && errors.name) || "Enter your team's name"
                    }
                    label="Team Name"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    helperText={
                      (touched.description && errors.description) ||
                      "Enter your team's description"
                    }
                    rows={4}
                    multiline
                    label="Team Description"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </Grid>

            <Grid
              container
              item
              xs={12}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item />

              <Grid item>
                <Button
                  sx={{}}
                  endIcon={<ArrowForwardIcon />}
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting || waitingForImage}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

CreateTeamStepOne.propTypes = {
  stepOneData: PropTypes.any,
  submitFunction: PropTypes.func,
  setUploadedImageRef: PropTypes.func,
  waitingForImage: PropTypes.bool,
  setWaitingForImage: PropTypes.func
};

export default CreateTeamStepOne;
