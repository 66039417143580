import { StorageReference } from '@firebase/storage';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useTheme
} from '@mui/material';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { ChangeEvent, FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomDialogHeader from 'src/components/CustomDialogHeader';
import Transition from 'src/components/Transition';
import {
  subscriptionTierBasicColor,
  subscriptionTierProColor
} from 'src/constants';
import { RootState, useSelector } from 'src/store';
import { backgroundColorSettingsSidebar } from 'src/theme/dark-theme-options';
import { AccountData } from 'src/types/user';
import getSpaceTier from 'src/utils/getSpaceTier';
import Logo from '../../../icons/Logo';
import { removeImageByRef } from '../ImageUploader';
import AccountGeneralSettings from './AccountGeneralSettings';
import AccountOtherSettings from './AccountOtherSettings';
import AccountSecuritySettings from './AccountSecuritySettings';
import AccountSubscriptionSettings from './AccountSubscriptionSettings';

const tabs = [
  { label: 'General', value: 'general' },
  { label: 'Security', value: 'security' },
  { label: 'Subscription', value: 'subscription' },
  { label: 'Other', value: 'other' }
];

interface AccountSettingsDialogProps {
  initialTab: string;
  open: boolean;
  setDialogOpen: Function;
}

const AccountSettingsDialog: FC<AccountSettingsDialogProps> = (props) => {
  const { initialTab, open, setDialogOpen } = props;
  const theme = useTheme();
  const [currentTab, setCurrentTab] = useState<string>(initialTab);
  const [waitingForImage, setWaitingForImage] = useState<boolean>(false);
  const [waitingForAccountSettingsChange, setWaitingForAccountSettingsChange] =
    useState<boolean>(false);
  const [imageDownloadURL, setImageDownloadURL] = useState<string | null>(null);
  const [imageRef, setImageRef] = useState<StorageReference | null>(null);

  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );

  const { teamId } = useParams();

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    if (!waitingForAccountSettingsChange && !waitingForImage) {
      if (imageRef) {
        removeImageByRef(imageRef);
        setImageRef(null);
        setImageDownloadURL(null);
      }

      setCurrentTab(value);
    }
  };

  const onClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      if (imageRef) {
        if (!waitingForAccountSettingsChange && !waitingForImage) {
          removeImageByRef(imageRef);
          setImageRef(null);
          setImageDownloadURL(null);
        }
      }
      // setCurrentTab(initialTab);
    }

    if (!waitingForAccountSettingsChange && !waitingForImage) {
      setDialogOpen(false);
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <DialogContent
        sx={{
          height: '100%',
          width: '100%',
          padding: '0px !important',
          margin: '0px !important'
        }}
      >
        <Grid
          container
          sx={{
            width: '100%',
            height: '100%'
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            item
            xs={4}
            sx={{
              backgroundColor: backgroundColorSettingsSidebar,
              py: '5.5%'
            }}
          >
            <Box sx={{ width: 210, height: 600, px: 2 }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                  mb: 1,
                  width: '100%',
                  px: 2
                }}
              >
                <Logo
                  sx={{
                    height: '100%',
                    width: 75,
                    fill: `#fff`
                  }}
                />

                <Typography
                  variant="overline"
                  sx={{
                    position: 'relative',
                    ml: 10.5,
                    mt: -1.71,
                    color:
                      getSpaceTier(false, null, accountData) === 'BASIC'
                        ? `${subscriptionTierBasicColor} !important`
                        : `${subscriptionTierProColor} !important`
                  }}
                >
                  {getSpaceTier(false, null, accountData)}
                </Typography>
              </Grid>

              {tabs.map(
                (tab) =>
                  (tab.value !== 'subscription' ||
                    (tab.value === 'subscription' && !teamId)) && (
                    <Button
                      key={tab.value}
                      variant="text"
                      disabled={waitingForAccountSettingsChange}
                      onClick={(event) => handleTabsChange(event, tab.value)}
                      sx={{
                        width: '100%',
                        mt: 1,
                        height: 33,
                        color:
                          currentTab === tab.value
                            ? theme.palette.text.primary
                            : theme.palette.text.secondary,

                        backgroundColor:
                          currentTab !== tab.value && 'transparent',
                        ':hover': {
                          color: theme.palette.text.primary
                        }
                      }}
                    >
                      <Grid container>{tab.label}</Grid>
                    </Button>
                  )
              )}
            </Box>
          </Grid>

          <Grid
            item
            xs={8}
            sx={{
              backgroundColor: theme.palette.background.default,
              borderLeft: `1px solid ${theme.palette.divider}`,
              py: '5.5%',
              px: 8
            }}
          >
            <Box sx={{ maxWidth: 750 }}>
              <CustomDialogHeader
                title={capitalize(currentTab)}
                onClose={onClose}
              />
              <Box sx={{ mr: 8 }}>
                <Box sx={{ mt: 4 }}>
                  {currentTab === 'general' && (
                    <AccountGeneralSettings
                      waitingForImage={waitingForImage}
                      setWaitingForImage={setWaitingForImage}
                      waitingForAccountSettingsChange={
                        waitingForAccountSettingsChange
                      }
                      setWaitingForAccountSettingsChange={
                        setWaitingForAccountSettingsChange
                      }
                      imageRef={imageRef}
                      setImageRef={setImageRef}
                      imageDownloadURL={imageDownloadURL}
                      setImageDownloadURL={setImageDownloadURL}
                      setDialogOpen={setDialogOpen}
                    />
                  )}
                  {currentTab === 'security' && <AccountSecuritySettings />}
                  {currentTab === 'subscription' && (
                    <AccountSubscriptionSettings />
                  )}
                  {currentTab === 'other' && <AccountOtherSettings />}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

AccountSettingsDialog.propTypes = {
  initialTab: PropTypes.string.isRequired,
  open: PropTypes.bool,
  setDialogOpen: PropTypes.func
};

export default AccountSettingsDialog;
