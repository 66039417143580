import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import type { DropzoneOptions } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import hexToRGB from 'src/utils/hexToRGB';
import DuplicateIcon from '../icons/Duplicate';
import XIcon from '../icons/X';
import bytesToSize from '../utils/bytesToSize';
import CustomTooltip from './CustomTooltip';

interface FileDropzoneProps extends DropzoneOptions {
  isSubmitting?: boolean;
  files?: any[];
  onRemove?: (file: any) => void;
  onRemoveAll?: () => void;
  onUpload?: () => void;
}

const FileDropzone: FC<FileDropzoneProps> = (props) => {
  const {
    isSubmitting,
    accept,
    disabled,
    files,
    getFilesFromEvent,
    maxFiles,
    maxSize,
    minSize,
    noClick,
    noDrag,
    noDragEventsBubbling,
    noKeyboard,
    onDrop,
    onDropAccepted,
    onDropRejected,
    onFileDialogCancel,
    onRemove,
    onRemoveAll,
    onUpload,
    preventDropOnDocument,
    ...other
  } = props;

  // We did not add the remaining props to avoid component complexity
  // but you can simply add it if you need to.
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    minSize,
    onDrop,
    disabled
  });

  const theme = useTheme();

  return (
    <div {...other}>
      {files.length > 0 ? (
        <Box sx={{ mt: 0 }}>
          <List>
            {files.map((file) => (
              <ListItem
                key={file.path}
                sx={{
                  border: 1,
                  borderColor: 'divider',
                  borderRadius: 1,
                  '& + &': {
                    mt: 1
                  }
                }}
              >
                <ListItemIcon>
                  <DuplicateIcon
                    fontSize="small"
                    sx={{ color: theme.palette.secondary.main }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={file.name}
                  primaryTypographyProps={{
                    color: `${theme.palette.text.primary} !important`,
                    variant: 'subtitle2'
                  }}
                  secondary={bytesToSize(file.size)}
                />
                <CustomTooltip title="Remove">
                  <IconButton
                    edge="end"
                    onClick={() => onRemove && onRemove(file)}
                    size="medium"
                    disabled={isSubmitting}
                    sx={{ mr: 0.2 }}
                  >
                    <XIcon fontSize="small" />
                  </IconButton>
                </CustomTooltip>
              </ListItem>
            ))}
          </List>
        </Box>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              alignItems: 'center',
              width: '100%',

              borderRadius: `${theme.shape.borderRadius}px`,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              outline: 'none',
              opacity: disabled && 0.5,
              backgroundColor: hexToRGB(theme.palette.secondary.main, '0.05'),
              border: `2px dashed ${hexToRGB(
                theme.palette.secondary.main,
                '0.5'
              )}`,
              ...(isDragActive && {
                opacity: 0.7
              }),
              '&:hover': !disabled && {
                cursor: 'pointer',
                opacity: 0.7
              }
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ py: 5 }}
            >
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={12}
              >
                <FileUploadOutlinedIcon sx={{ fontSize: 32 }} />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={12}
              >
                <Typography
                  variant="h3"
                  sx={{
                    mt: 1,
                    mb: 0.5,
                    fontWeight: 500,
                    fontSize: '1rem !important'
                  }}
                >
                  {maxFiles === 1
                    ? 'Upload a file here'
                    : `Upload up to ${maxFiles} files at once`}
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={12}
              >
                <Typography variant="subtitle1">
                  Drag & drop, or click to select
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
    </div>
  );
};

FileDropzone.propTypes = {
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  disabled: PropTypes.bool,
  files: PropTypes.array,
  getFilesFromEvent: PropTypes.func,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  noDragEventsBubbling: PropTypes.bool,
  noKeyboard: PropTypes.bool,
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
  preventDropOnDocument: PropTypes.bool
};

FileDropzone.defaultProps = {
  files: []
};

export default FileDropzone;
