import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import useSettings from 'src/hooks/useSettings';
import { auth } from 'src/lib/firebase';
import useAuth from '../../hooks/useAuth';

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { settings } = useSettings();

  if (isAuthenticated && !auth.currentUser?.isAnonymous) {
    if (settings.activeSpaceID !== null) {
      return <Navigate to={`/app/team/${settings.activeSpaceID}`} />;
    }

    return <Navigate to="/app/personal" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
