import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useTheme
} from '@mui/material';
import { StorageReference } from 'firebase/storage';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { ChangeEvent, FC, useState } from 'react';
import CustomDialogHeader from 'src/components/CustomDialogHeader';
import Transition from 'src/components/Transition';
import {
  subscriptionTierBasicColor,
  subscriptionTierProColor
} from 'src/constants';
import { RootState, useSelector } from 'src/store';
import { backgroundColorSettingsSidebar } from 'src/theme/dark-theme-options';
import { Team } from 'src/types/team';
import getSpaceTier from 'src/utils/getSpaceTier';
import Logo from '../../../icons/Logo';
import { removeImageByRef } from '../ImageUploader';
import TeamGeneralSettings from './TeamGeneralSettings';
import TeamMembersSettings from './TeamMembersSettings';
import TeamOtherSettings from './TeamOtherSettings';
import TeamSubscriptionSettings from './TeamSubscriptionSettings';

const tabs = [
  { label: 'General', value: 'general' },
  { label: 'Members', value: 'members' },
  { label: 'Subscription', value: 'subscription' },
  { label: 'Other', value: 'other' }
];

interface TeamSettingsDialogProps {
  initialTab: string;
  open: boolean;
  setDialogOpen: Function;
}

const TeamSettingsDialog: FC<TeamSettingsDialogProps> = (props) => {
  const { open, setDialogOpen, initialTab } = props;
  const [currentTab, setCurrentTab] = useState<string>(initialTab || 'general');
  const [waitingForImage, setWaitingForImage] = useState<boolean>(false);
  const [waitingForTeamSettingsChange, setWaitingForTeamSettingsChange] =
    useState<boolean>(false);
  const [imageRef, setImageRef] = useState<StorageReference | null>(null);

  const theme = useTheme();

  const team: Team = useSelector((state: RootState) => state.teamSlice.team);

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    if (!waitingForTeamSettingsChange && !waitingForImage) {
      if (imageRef) {
        removeImageByRef(imageRef);
        setImageRef(null);
      }

      setCurrentTab(value);
    }
  };

  const onClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      if (imageRef) {
        if (!waitingForTeamSettingsChange && !waitingForImage) {
          removeImageByRef(imageRef);
          setImageRef(null);
        }
      }
    }

    if (!waitingForTeamSettingsChange && !waitingForImage) {
      setDialogOpen(false);
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <DialogContent
        sx={{
          height: '100%',
          width: '100%',
          padding: '0px !important',
          margin: '0px !important'
        }}
      >
        <Grid
          container
          sx={{
            width: '100%',
            height: '100%'
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            item
            xs={4}
            sx={{ backgroundColor: backgroundColorSettingsSidebar, py: '5.5%' }}
          >
            <Box sx={{ width: 210, height: 600, px: 2 }}>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                  mb: 1,
                  width: '100%',
                  px: 2
                }}
              >
                <Logo
                  sx={{
                    height: '100%',
                    width: 75,
                    fill: `#fff`
                  }}
                />

                <Typography
                  variant="overline"
                  sx={{
                    position: 'relative',
                    ml: 10.5,
                    mt: -1.71,
                    color:
                      getSpaceTier(true, team, null) === 'BASIC'
                        ? `${subscriptionTierBasicColor} !important`
                        : `${subscriptionTierProColor} !important`
                  }}
                >
                  {getSpaceTier(true, team, null)}
                </Typography>
              </Grid>

              {tabs.map((tab) => (
                <Button
                  key={tab.value}
                  variant="text"
                  disabled={waitingForTeamSettingsChange}
                  onClick={(event) => handleTabsChange(event, tab.value)}
                  sx={{
                    width: '100%',
                    mt: 1,
                    height: 33,
                    color:
                      currentTab === tab.value
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary,

                    backgroundColor: currentTab !== tab.value && 'transparent',
                    ':hover': {
                      color: theme.palette.text.primary
                    }
                  }}
                >
                  <Grid container>{tab.label}</Grid>
                </Button>
              ))}
            </Box>
          </Grid>

          <Grid
            item
            xs={8}
            sx={{
              backgroundColor: theme.palette.background.default,
              borderLeft: `1px solid ${theme.palette.divider}`,
              py: '5.5%',
              px: 8
            }}
          >
            <Box sx={{ maxWidth: 750 }}>
              <CustomDialogHeader
                title={capitalize(currentTab)}
                onClose={onClose}
              />
              <Box sx={{ mr: 8 }}>
                <Box sx={{ mt: 4 }}>
                  {currentTab === 'general' && (
                    <TeamGeneralSettings
                      waitingForImage={waitingForImage}
                      setWaitingForImage={setWaitingForImage}
                      waitingForTeamSettingsChange={
                        waitingForTeamSettingsChange
                      }
                      setWaitingForTeamSettingsChange={
                        setWaitingForTeamSettingsChange
                      }
                      imageRef={imageRef}
                      setImageRef={setImageRef}
                      setDialogOpen={setDialogOpen}
                    />
                  )}
                  {currentTab === 'members' && <TeamMembersSettings />}
                  {currentTab === 'subscription' && (
                    <TeamSubscriptionSettings />
                  )}
                  {currentTab === 'other' && <TeamOtherSettings />}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

TeamSettingsDialog.propTypes = {
  open: PropTypes.bool,
  setDialogOpen: PropTypes.func
};

export default TeamSettingsDialog;
