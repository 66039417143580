import type { ListItemProps } from '@mui/material';
import {
  Box,
  Button,
  Collapse,
  Grid,
  ListItem,
  Typography,
  useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import ChevronDownIcon from '../icons/ChevronDown';
import ChevronRightIcon from '../icons/ChevronRight';

interface NavItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  path?: string;
  title: string;
}

const NavItem: FC<NavItemProps> = (props) => {
  const {
    active,
    children,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    ...other
  } = props;
  const [open, setOpen] = useState<boolean>(openProp);
  const theme = useTheme();

  /*
  addAlphaToHex(
    theme.palette.primary.main,
    0.065
  );
  */

  const handleToggle = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 32;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          py: 2
        }}
        {...other}
      >
        <Button
          endIcon={
            !open ? (
              <ChevronRightIcon fontSize="small" />
            ) : (
              <ChevronDownIcon fontSize="small" />
            )
          }
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            m: 1,
            color: 'text.secondary',
            fontWeight: 'fontWeightMedium',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: '8px',
            py: '12px',
            textAlign: 'left',
            textTransform: 'none',
            width: '100%'
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
        px: 0
      }}
    >
      <Button
        component={path && RouterLink}
        sx={{
          justifyContent: 'flex-start',
          textAlign: 'left',
          my: 0.9,
          mx: 1.25,
          px: 2.5,
          textTransform: 'none',
          width: '100%',
          height: 33,
          backgroundColor: 'transparent',
          borderRadius: `${theme.shape.borderRadius}px`,
          ':hover': { backgroundColor: theme.palette.action.hover },

          ...(active && {
            backgroundColor: `${theme.palette.action.active}`
          })
        }}
        size="large"
        variant="text"
        to={path}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              width: 22,
              height: 22,
              ...(active && {
                backgroundColor: 'transparent'
              })
            }}
          >
            {React.cloneElement(icon as React.ReactElement<any>, {
              sx: {
                fontSize: 16,
                fill: theme.palette.text.secondary,
                ...(active && {
                  fill: theme.palette.secondary.main
                })
              }
            })}
          </Grid>

          <Box>
            <Typography
              variant="h5"
              sx={{
                pl: 2.5,
                color: `${theme.palette.text.secondary} !important`,
                ...(active && {
                  color: `${theme.palette.text.primary} !important`
                })
              }}
            >
              {title}
            </Typography>
          </Box>
        </Grid>
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  active: false,
  open: false
};

export default NavItem;
