import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import React from 'react';

interface SettingsSectionProps {
  title: string;
  children?: any;
}

const SettingsSection: FC<SettingsSectionProps> = (props) => {
  const { title, children } = props;

  return (
    <Grid container direction="column">
      <Grid item sx={{ mb: 0.5 }}>
        <Typography variant="overline" color="textSecondary">
          {title}
        </Typography>
      </Grid>

      <Grid item>{children}</Grid>
    </Grid>
  );
};

SettingsSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

export default SettingsSection;
