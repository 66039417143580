import { AccountData } from 'src/types/user';
import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';

type AccountDataNoId = Omit<AccountData, 'id'>;

const accountDataConverter = {
  toFirestore: (accountData: AccountData) => {
    const accountDataNoId: AccountDataNoId = {
      username: accountData.username,
      teamsInfo: accountData.teamsInfo,
      pendingInvites: accountData.pendingInvites,
      playsInfo: accountData.playsInfo,
      playbooksInfo: accountData.playbooksInfo,
      spaceTier: accountData.spaceTier || 'basic'
    };
    return accountDataNoId;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): AccountData {
    const data = snapshot.data(options);
    return <AccountData>{
      username: data.username,
      teamsInfo: data.teamsInfo,
      pendingInvites: data.pendingInvites || [],
      playsInfo: data.playsInfo || [],
      playbooksInfo: data.playbooksInfo || [],
      spaceTier: data.spaceTier || 'basic'
    };
  }
};

export { accountDataConverter };
