import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { deleteTeam } from 'src/data/firestore/setters/team';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { RootState } from 'src/store';
import { Team } from 'src/types/team';
import * as Yup from 'yup';

interface DeleteTeamDialogProps {
  open: boolean;
  setDialogOpen: Function;
}

const DeleteTeamDialog: FC<DeleteTeamDialogProps> = (props) => {
  const { open, setDialogOpen } = props;
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const team: Team = useSelector((state: RootState) => state.teamSlice.team);
  const isMountedRef = useIsMountedRef();
  const [waitingForResponse, setWaitingForResponse] = useState(false);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={() => {
        if (!waitingForResponse) {
          setDialogOpen(false);
        }
      }}
    >
      <DialogContent sx={{ height: 'auto', maxHeight: '750px' }}>
        <Container
          sx={{
            width: '100%',
            height: '100%',
            py: 2
          }}
        >
          <Typography color="textPrimary" variant="h5">
            Delete Team
          </Typography>

          <Box sx={{ width: '100%' }}>
            {waitingForResponse ? (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ height: '300px' }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sx={{ pt: 2 }}>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    sx={{ fontStyle: 'italic' }}
                  >
                    Attention! You are about to delete this team. Once this
                    process is completed, you will not be able to undo the
                    deletion. If you still want to continue, please fill in the
                    following field:
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ pt: 2 }}>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      confirmDeletion: '',
                      submit: null
                    }}
                    validationSchema={Yup.object().shape({
                      confirmDeletion: Yup.string()
                        .required("Please type 'DELETE TEAM'")
                        .oneOf(
                          ['DELETE TEAM', 'DELETE TEAM '],
                          "Please type 'DELETE TEAM'"
                        )
                    })}
                    onSubmit={async (
                      values,
                      { resetForm, setStatus, setSubmitting }
                    ): Promise<void> => {
                      try {
                        setWaitingForResponse(true);

                        const deletionSuccessful = await deleteTeam(team);
                        if (!deletionSuccessful) {
                          throw new Error('Deletion was not successful');
                        }

                        enqueueSnackbar('Team Deleted', {
                          variant: 'success'
                        });

                        if (isMountedRef.current) {
                          resetForm();
                          setStatus({ success: true });
                          setSubmitting(false);
                          setWaitingForResponse(false);
                          setDialogOpen(false);
                        }
                        navigate(`/app/personal`);
                      } catch (err) {
                        enqueueSnackbar('Team Deletion failed', {
                          variant: 'error'
                        });

                        if (isMountedRef.current) {
                          setStatus({ success: false });

                          setWaitingForResponse(false);

                          setSubmitting(false);

                          setDialogOpen(false);
                        }
                      }
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      touched,
                      values
                    }): JSX.Element => (
                      <form onSubmit={handleSubmit}>
                        <Card>
                          <CardContent>
                            <Grid item xs={12}>
                              <TextField
                                error={Boolean(
                                  touched.confirmDeletion &&
                                    errors.confirmDeletion
                                )}
                                fullWidth
                                helperText={
                                  (touched.confirmDeletion &&
                                    errors.confirmDeletion) ||
                                  ''
                                }
                                label="type: DELETE TEAM"
                                name="confirmDeletion"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.confirmDeletion}
                                variant="outlined"
                              />
                              {errors.submit && (
                                <Box sx={{ mt: 1 }}>
                                  <FormHelperText error>
                                    {errors.submit}
                                  </FormHelperText>
                                </Box>
                              )}
                            </Grid>

                            <Grid item xs={12}>
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Button
                                  variant="contained"
                                  type="submit"
                                  sx={{
                                    backgroundColor: `${theme.palette.error.main}`,
                                    borderColor: `${theme.palette.error.main}`,
                                    '&:hover': {
                                      borderColor: `${theme.palette.error.main}`,
                                      backgroundColor: `${theme.palette.error.main}`
                                    },
                                    px: 5,
                                    mt: 5
                                  }}
                                >
                                  Delete Team
                                </Button>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </form>
                    )}
                  </Formik>
                </Grid>
              </Grid>
            )}
          </Box>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

DeleteTeamDialog.propTypes = {
  open: PropTypes.bool,
  setDialogOpen: PropTypes.func
};

export default DeleteTeamDialog;
