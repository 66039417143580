import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, capitalize, Grid, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FC, useState } from 'react';
import useSettings from 'src/hooks/useSettings';
import { RootState, useSelector } from 'src/store';
import { PlayInfo } from 'src/types/play';
import { Team } from 'src/types/team';

interface CreatePlaybookStepTwoProps {
  stepTwoData: string[];
  submitFunction: Function;
  preselectedPlays: string[];
}

interface DataGridPlayInfo {
  id: string;
  createdAt: string;
  map: string;
  title: string;
  updatedAt: string;
}

const playInfosToDataGridPlayInfos = (playInfos: PlayInfo[]) => {
  if (!playInfos) {
    return [];
  }

  return playInfos.map((info) => {
    const dataGridPlayInfo: DataGridPlayInfo = {
      id: info.id,

      createdAt: `${moment(info.createdAt).fromNow()}`,

      map: capitalize(info.map),

      title: info.title,

      updatedAt: `${moment(info.updatedAt).fromNow()}`
    };
    return dataGridPlayInfo;
  });
};

const CreatePlaybookStepTwo: FC<CreatePlaybookStepTwoProps> = (props) => {
  const { submitFunction, stepTwoData, preselectedPlays } = props;
  const [selectedPlays, setSelectedPlays] = useState(
    preselectedPlays?.length === 0
      ? stepTwoData || []
      : [...new Set([...preselectedPlays, ...stepTwoData])]
  );
  const { settings } = useSettings();

  const activeWorkspace = settings?.activeSpaceID;

  const personalPlaysInfo: PlayInfo[] = useSelector(
    (state: RootState) => state.accountDataSlice.accountData.playsInfo
  );

  const team: Team = useSelector((state: RootState) => state.teamSlice.team);

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Title', flex: 1, minWidth: 120 },
    { field: 'map', headerName: 'Map', minWidth: 120 },
    { field: 'createdAt', headerName: 'created', minWidth: 120 },
    { field: 'updatedAt', headerName: 'updated', minWidth: 120 },
    { field: 'id', hide: true }
  ];

  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ px: 2 }}
    >
      <Grid item xs={12}>
        {(activeWorkspace && team?.playsInfo?.length === 0) ||
        !personalPlaysInfo ? (
          <Box sx={{ p: 2 }}>
            <Typography variant="subtitle1" color="textSecondary">
              You have not created any plays yet ...
            </Typography>
          </Box>
        ) : (
          <DataGrid
            loading={activeWorkspace ? !team : !personalPlaysInfo}
            checkboxSelection
            onSelectionModelChange={(newSelection) => {
              setSelectedPlays(
                newSelection?.map((playID) => playID.toString())
              );
            }}
            selectionModel={selectedPlays}
            pageSize={10}
            disableSelectionOnClick
            disableColumnSelector
            disableColumnMenu
            disableColumnFilter
            hideFooterPagination={
              activeWorkspace
                ? team?.playsInfo?.length <= 10
                : personalPlaysInfo?.length <= 10
            }
            density="compact"
            autoHeight
            rows={
              activeWorkspace
                ? playInfosToDataGridPlayInfos(team?.playsInfo) || []
                : playInfosToDataGridPlayInfos(personalPlaysInfo) || []
            }
            columns={columns}
          />
        )}
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: 5 }}
      >
        <Button
          sx={{}}
          variant="text"
          startIcon={<ArrowBackIcon />}
          onClick={() => submitFunction(selectedPlays, 'back')}
        >
          Back
        </Button>

        <Button
          sx={{}}
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          onClick={() => submitFunction(selectedPlays, 'forward')}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
};

CreatePlaybookStepTwo.propTypes = {
  stepTwoData: PropTypes.array,
  submitFunction: PropTypes.func
};

export default CreatePlaybookStepTwo;
