import { Avatar, Box } from '@mui/material';
import { FC, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import CustomTooltip from 'src/components/CustomTooltip';
import { AGENT_SIZE, OBJECT_SIZE } from 'src/constants';
import { setSelectedObject } from 'src/slices/board/boardUi';
import store from 'src/store';
import { DragTypes, IDropItem, INode, TeamSides } from 'src/types/board';
import determineBorderColor from 'src/utils/boardUtils/determineBorderColor';
import hexToRGB from 'src/utils/hexToRGB';

interface IProps {
  type: DragTypes;
  value: string;
  title: string;
  icon: any;
  backgroundColor?: string;
  startNode?: INode;
  attack?: TeamSides;
}

const DraggableObjectItem: FC<IProps> = (props: IProps) => {
  const { value, title, icon, attack, type, startNode, backgroundColor } =
    props;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      // "type" is required. It is used by the "accept" specification of drop targets.

      type,
      item: {
        value,
        startNode,
        attack
      } as IDropItem,
      // The collect function utilizes a "monitor" instance (see the Overview for what this is)
      // to pull important pieces of state from the DnD system.
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging()
      })
    }),
    [attack, startNode]
  );

  useEffect(() => {
    if (isDragging) {
      store.dispatch(setSelectedObject(null));
    }
  }, [isDragging]);

  return (
    <CustomTooltip title={title} subtitle="Drag & Drop" placement="top">
      <Avatar
        ref={drag}
        variant="circular"
        src={icon instanceof Object ? undefined : icon}
        sx={{
          backgroundColor:
            type === DragTypes.BASIC ? 'transparent' : backgroundColor,
          width: type === DragTypes.AGENT ? AGENT_SIZE - 6 : OBJECT_SIZE - 6,
          height: type === DragTypes.AGENT ? AGENT_SIZE - 6 : OBJECT_SIZE - 6,
          opacity: isDragging ? '0.2' : '1',
          transform: 'translate(0, 0)',
          cursor: isDragging ? 'grabbing' : 'grab',
          p: type === DragTypes.AGENT ? 0 : 0.5,
          borderRadius: 99,
          outline:
            type !== DragTypes.BASIC
              ? `3px solid ${hexToRGB(
                  determineBorderColor(attack),
                  isDragging ? '0.05' : '0.3'
                )}`
              : '3xp solid transparent'
        }}
      >
        {icon}
      </Avatar>
    </CustomTooltip>
  );
};

export default DraggableObjectItem;
