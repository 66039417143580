import { Container, Theme, useTheme } from '@mui/material';
import { FC, ReactElement, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet } from 'react-helmet-async';
import { Prompt, useParams } from 'react-router-dom';
import ToolsDrawer from 'src/components/app/board/tools/drawer/ToolsDrawer';
import NavigationTools from 'src/components/app/board/tools/NavigationTools';
import SequenceStepper from 'src/components/app/board/tools/SequenceStepper';
import useAuth from 'src/hooks/useAuth';
import useBeforeClose from 'src/hooks/useBeforeClose';
import useGlobalDOMEvents from 'src/hooks/useGlobalDOMEvents';
import useBeforeUnload from 'src/hooks/useUnload';
import { clearBoard, initBoard } from 'src/slices/board/boardModel';
import {
  resetUi,
  setSelectedObject,
  setSelectedTool
} from 'src/slices/board/boardUi';
import store, { RootState, useSelector } from 'src/store';
import { BoardTools } from 'src/types/board';
import { Play } from 'src/types/play';
import { SessionUsers } from 'src/types/session';
import PanWrapper from '../../components/app/board/PanWrapper';

const BoardPage: FC = (): ReactElement => {
  const { playId, teamId } = useParams();
  const { user } = useAuth();
  const theme: Theme = useTheme();
  const play: Play = useSelector(
    (state: RootState) => state.playSlice.play || undefined
  );
  const sessionId = teamId ? playId : null;

  const sessionUsers: SessionUsers = useSelector(
    (state: RootState) =>
      state.sessionInfosSlice?.sessionInfos[play?.id]?.users || {}
  );

  const { lastSaveTimestamp } = useSelector(
    (state: RootState) => state.boardSlice.ui
  );

  useGlobalDOMEvents({
    // cancel action
    keydown(e: any) {
      if (e.key === 'Escape') {
        store.dispatch(setSelectedTool(BoardTools.SELECT));
        store.dispatch(setSelectedObject(null));
      }
      // SPACE + Drag
      else if (
        e.code === 'Space' &&
        !e.target.classList.contains('MuiInputBase-input')
      ) {
        // prevent this key event from triggering when an input is focused
        store.dispatch(setSelectedTool(BoardTools.PAN));
      }
    },
    keyup(e: any) {
      if (
        e.code === 'Space' &&
        !e.target.classList.contains('MuiInputBase-input')
      ) {
        e.preventDefault();
        store.dispatch(setSelectedTool(BoardTools.SELECT));
      }
    }
  });

  const unloadCallback = (e) => {
    e.returnValue = 'Hello there!';
    return 'Hello there';
  };

  useBeforeUnload(unloadCallback);
  useBeforeClose(unloadCallback);

  useEffect(() => {
    // only do this when we are not in a session
    // TODO: try to get this into playslice/guard
    if (!sessionId && play?.board && playId) {
      store.dispatch(initBoard(play?.board));
    }

    return () => {
      store.dispatch(resetUi());
      store.dispatch(clearBoard());
    };
  }, [!!play?.board]);

  const determinePrompt = () => {
    if (!sessionId) {
      // if last save is older than 1 min

      const timeDiff = lastSaveTimestamp
        ? Date.now() - lastSaveTimestamp
        : null;
      if (!lastSaveTimestamp || timeDiff > 1000 * 60 * 1) {
        return true;
      }
      return false;
    }
    if (
      Object.entries(sessionUsers).filter(([, value]) => value.active).length <=
      1
    ) {
      return true;
    }
    return false;
  };

  const showLeavePromptMsg = () => {
    // only show this when you are last to leave or have
    if (teamId) {
      return '\n Click OK when you want to proceed leaving the play without saving.\n';
    }

    // if last save is older than 1 min;
    const timeDiff = lastSaveTimestamp ? Date.now() - lastSaveTimestamp : null;
    if (!lastSaveTimestamp || timeDiff < 1000 * 60 * 1) {
      return 'Click OK when you want to proceed leaving the play without saving.\n Recent changes you made may not be saved.';
    }

    return '\n Click OK when you want to proceed leaving the play without saving.\n Recent changes you made may not be saved.';
  };
  // TODO: add touch backend
  return (
    <>
      <Prompt message={showLeavePromptMsg()} when={determinePrompt()} />
      <Helmet>
        <title>Board | Critbase</title>
        <meta name="description" content={play.title} />
        <meta name="theme-color" content="#373e99" />
        <meta property="og:title" content={play.title} data-rh="true" />
        <meta
          property="og:description"
          content={`Play on ${play.map} has been shared by ${user.displayName}`}
          data-rh="true"
        />
        <meta property="og:image" content={play.photoURL} data-rh="true" />
      </Helmet>

      <Container
        sx={{
          // @ts-ignore  hatte kb eine modul augmentation zu machen
          backgroundColor: theme.palette.background.whiteboard,
          m: 0,
          p: '0px !important',
          height: '100%',
          width: '100% !important',
          maxWidth: '100% !important',
          position: 'relative'
        }}
      >
        <DndProvider backend={HTML5Backend}>
          <PanWrapper />
          <NavigationTools />
          <ToolsDrawer />
          <SequenceStepper />
        </DndProvider>
      </Container>
    </>
  );
};
export default BoardPage;
