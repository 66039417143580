import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import { FC, useState } from 'react';
import { appSpacebarWidth, appSpaceBarZIndex } from 'src/constants';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import { RootState, useSelector } from 'src/store';
import { backgroundColorSpacebar } from 'src/theme/dark-theme-options';
import { Team, TeamInfo } from 'src/types/team';
import { AccountData } from 'src/types/user';
import { AccountSettingsDialog } from './account';
import CustomAvatar from './CustomAvatar';
import OverviewCreatTeam from './overview/OverviewCreateTeam';
import OverviewTeamMembers from './overview/OverviewTeamMembers';
import { PersonalInvitesDialog } from './personal';
import SubscriptionTierChip from './SubscriptionTierChip';
import { TeamInvitesDialog, TeamSettingsDialog } from './team';

const spacebarHeaderHeight = 48;

interface SpacebarHeaderProps {
  title: string;
  singleBorder: boolean;
}

const SpacebarHeader: FC<SpacebarHeaderProps> = (
  props: SpacebarHeaderProps
) => {
  const { title, singleBorder } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        height: spacebarHeaderHeight,
        width: '100%',
        borderTop: !singleBorder && `1px solid ${theme.palette.divider}`,
        borderBottom: `1px solid ${theme.palette.divider}`,
        px: 2.5
      }}
    >
      <Typography variant="h4">{title}</Typography>
    </Grid>
  );
};

const selectTeamInfoProperty = (
  teamsInfo: TeamInfo[],
  objID: string,
  property: 'displayName' | 'photoURL'
) => {
  const team = teamsInfo.find((teamInfo) => teamInfo.id === objID);
  if (team) {
    if (property === 'displayName') {
      return team.displayName;
    }

    return team.photoURL;
  }

  return null;
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 14,
    color: '#fff !important',
    backgroundColor: `${theme.palette.error.main} !important`,
    fontSize: 10,
    height: 17,
    minWidth: 15,
    borderRadius: '4px !important',
    fontWeight: '800 !important'
  }
}));

type DialogContent = 'general' | 'subscription' | null;

const AppSpacebar: FC = () => {
  const { settings } = useSettings();
  const { user } = useAuth();
  const teamsInfo: TeamInfo[] = useSelector((state: RootState) =>
    state.accountDataSlice.accountData
      ? state.accountDataSlice.accountData.teamsInfo
      : []
  );
  const theme = useTheme();
  const team: Team = useSelector((state: RootState) => state.teamSlice.team);
  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );
  const horizontalPadding = 4.5;

  const [dialogContent, setDialogContent] = useState<DialogContent>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);

  const spaceProfile = (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ width: '100%', pt: 8, pb: horizontalPadding }}
    >
      <CustomAvatar
        src={
          settings.activeSpaceID
            ? selectTeamInfoProperty(
                teamsInfo,
                settings.activeSpaceID,
                'photoURL'
              )
            : user.photoURL
        }
        isTeamAvatar={settings.activeSpaceID !== null}
        sizeFactor={1.75}
      />

      <Typography
        variant="h3"
        sx={{ pt: 2, color: theme.palette.primary.contrastText }}
      >
        {team ? team.displayName : `Personal Space`}
      </Typography>

      <Typography
        variant="subtitle2"
        sx={{
          color: `${theme.palette.text.secondary} !important`,
          mt: -0.3,
          mb: 2
        }}
      >
        {team ? 'a Valorant Team' : 'a Valorant Space'}
      </Typography>

      <SubscriptionTierChip
        subscriptionTier={team ? team.spaceTier : accountData?.spaceTier}
      />

      <Box sx={{ px: horizontalPadding, mt: 4 }}>
        <Typography
          variant="body2"
          sx={{ color: `${theme.palette.text.secondary} !important` }}
        >
          {settings.activeSpaceID && (
            <>
              {team?.description ||
                'This is a Valorant team space. You can customize this team description in the team settings.'}
            </>
          )}

          {!settings.activeSpaceID &&
            'This is your personal space. If you want to collaborate with your team mates, create or switch to a team space. '}
        </Typography>
      </Box>
    </Grid>
  );

  const handleOpenDialog = (content: DialogContent) => () => {
    setDialogOpen(true);
    setDialogContent(content);
  };

  const spaceLinks = (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        width: '100%',
        py: horizontalPadding - 1,
        px: horizontalPadding - 2.5
      }}
    >
      <Button
        fullWidth
        variant="text"
        sx={{
          height: '34px',
          backgroundColor: 'transparent !important',
          mb: 1.5,
          '&:hover': {
            backgroundColor: `${theme.palette.action.hover} !important`
          }
        }}
        onClick={() => setNotificationsOpen(true)}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <StyledBadge
            color="secondary"
            badgeContent={
              settings.activeSpaceID ? 0 : accountData?.pendingInvites?.length
            }
          >
            <Typography
              variant="button"
              sx={{
                textTransform: 'none',
                fontSize: '12px !important',
                wordSpacing: 1.5,
                ml: 0.5,
                mr: 3
              }}
            >
              Invitations
            </Typography>
          </StyledBadge>

          <ArrowRightIcon />
        </Grid>
      </Button>

      <Button
        fullWidth
        variant="text"
        sx={{
          height: '34px',
          backgroundColor: 'transparent !important',
          '&:hover': {
            backgroundColor: `${theme.palette.action.hover} !important`
          },
          mb: 1.5
        }}
        onClick={handleOpenDialog('subscription')}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="button"
            sx={{
              textTransform: 'none',
              fontSize: '12px !important',
              wordSpacing: 1.5,
              ml: 0.5
            }}
          >
            Subscription
          </Typography>

          <ArrowRightIcon />
        </Grid>
      </Button>

      <Button
        fullWidth
        variant="text"
        sx={{
          height: '34px',
          backgroundColor: 'transparent !important',
          mb: 1.5,
          '&:hover': {
            backgroundColor: `${theme.palette.action.hover} !important`
          }
        }}
        onClick={handleOpenDialog('general')}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="button"
            sx={{
              textTransform: 'none',
              fontSize: '12px !important',
              wordSpacing: 1.5,
              ml: 0.5
            }}
          >
            {settings.activeSpaceID ? 'Team' : 'Account'} Settings
          </Typography>

          <ArrowRightIcon />
        </Grid>
      </Button>
    </Grid>
  );

  return (
    <Box
      sx={{
        width: appSpacebarWidth,
        borderLeft: `1px solid ${theme.palette.divider}`,
        backgroundColor: backgroundColorSpacebar,
        height: '100%',
        right: 0,
        top: 0,
        position: 'absolute',
        overflow: 'scroll',
        zIndex: appSpaceBarZIndex
      }}
    >
      <SpacebarHeader
        title={settings?.activeSpaceID ? 'Team Info' : 'Personal Space'}
        singleBorder
      />
      {spaceProfile}
      {settings.activeSpaceID && (
        <>
          <SpacebarHeader title="Team Members" singleBorder={false} />
          <OverviewTeamMembers padding={horizontalPadding - 0.5} />
        </>
      )}
      {!settings.activeSpaceID && (
        <>
          <SpacebarHeader title="Create Team" singleBorder={false} />
          <OverviewCreatTeam padding={horizontalPadding - 0.5} />
        </>
      )}
      <Divider />
      {spaceLinks}
      {settings.activeSpaceID && dialogOpen && (
        <TeamSettingsDialog
          initialTab={dialogContent}
          open={dialogOpen}
          setDialogOpen={setDialogOpen}
        />
      )}
      {!settings.activeSpaceID && dialogOpen && (
        <AccountSettingsDialog
          initialTab={dialogContent}
          open={dialogOpen}
          setDialogOpen={setDialogOpen}
        />
      )}
      {/* {settings.activeSpaceID && generalSettingsOpen ? (
        <TeamSettingsDialog
          initialTab="general"
          open={generalSettingsOpen}
          setDialogOpen={setGeneralSettingsOpen}
        />
      ) : (
        <AccountSettingsDialog
          initialTab="general"
          open={generalSettingsOpen}
          setDialogOpen={setGeneralSettingsOpen}
        />
      )}

      {settings.activeSpaceID && subscriptionSettingsOpen ? (
        <TeamSettingsDialog
          initialTab="subscription"
          open={subscriptionSettingsOpen}
          setDialogOpen={setSubscriptionSettingsOpen}
        />
      ) : (
        <AccountSettingsDialog
          initialTab="subscription"
          open={subscriptionSettingsOpen}
          setDialogOpen={setSubscriptionSettingsOpen}
        />
      )} */}
      {notificationsOpen && (
        <>
          {settings.activeSpaceID ? (
            <TeamInvitesDialog
              open={notificationsOpen}
              setDialogOpen={setNotificationsOpen}
              inviteUsersViewOpen={false}
            />
          ) : (
            <PersonalInvitesDialog
              open={notificationsOpen}
              setDialogOpen={setNotificationsOpen}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default AppSpacebar;
