import { Grid, Typography, useTheme } from '@mui/material';
import {
  subscriptionTierBasicColor,
  subscriptionTierProColor
} from 'src/constants';
import { SpaceTier } from 'src/types/subscription';
import hexToRGB from 'src/utils/hexToRGB';

interface IProps {
  subscriptionTier: 'pro' | 'basic' | SpaceTier;
  customText?: string;
}

const SubscriptionTierChip: React.FC<IProps> = (props: IProps) => {
  const { subscriptionTier, customText } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        border: `1px solid ${
          subscriptionTier === 'basic'
            ? subscriptionTierBasicColor
            : subscriptionTierProColor
        }`,
        px: 1.5,
        width: 'auto',
        py: 0.5,
        borderRadius: `${theme.shape.borderRadius}px`,
        backgroundColor: hexToRGB(
          subscriptionTier === 'basic'
            ? subscriptionTierBasicColor.toString()
            : subscriptionTierProColor.toString(),
          '0.2'
        )
      }}
    >
      {customText ? (
        <Typography
          sx={{
            fontWeight: 900,
            fontSize: 9,
            lineHeight: 1,
            pl: 0.35
          }}
          variant="overline"
        >
          {customText}
        </Typography>
      ) : (
        <Typography
          sx={{
            fontWeight: 900,
            fontSize: 9,
            lineHeight: 1,
            pl: 0.35
          }}
          variant="overline"
        >
          {subscriptionTier === 'basic' ? 'Basic' : 'Pro'} Tier
        </Typography>
      )}
    </Grid>
  );
};

export default SubscriptionTierChip;
