import * as React from 'react';
import Button, { ButtonTypeMap } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Divider, ExtendButtonBase, Grid, useTheme } from '@mui/material';
import { hexToRgb } from '@mui/system';
import hexToRGB from 'src/utils/hexToRGB';
import ClearIcon from '@mui/icons-material/Clear';
import { customOpacityDarkTheme } from 'src/constants';

interface ButtonProps {
  variant: 'contained' | 'text' | 'outlined';
  title: string;
  onClick: Function;
  disabled?: boolean;
}

interface IPropsDialog {
  open: boolean;
  setOpen: Function;
  title: string;
  children: any;
  width: number;
  buttons: ButtonProps[];
  onClose?: Function;
}

interface IPropsDialogButtons {
  children: any;
}

const CustomDialogButtons = (props: IPropsDialogButtons) => {
  const { children } = props;
  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      sx={{ px: 3, pb: 3 }}
    >
      {children}
    </Grid>
  );
};

const CustomDialog = (props: IPropsDialog) => {
  const { title, open, setOpen, children, width, buttons, onClose } = props;

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };
  const theme = useTheme();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent sx={{ p: 0, width }}>
        <Grid
          container
          sx={{
            width: '100%',
            height: '100%',
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.background.default,
            borderRadius: `${theme.shape.borderRadius}px`
          }}
        >
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            xs={12}
            sx={{
              backgroundColor: theme.palette.background.paper,
              px: 2.25,
              py: 1,
              borderTopRightRadius: `${theme.shape.borderRadius}px`,
              borderTopLeftRadius: `${theme.shape.borderRadius}px`
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: 500 }}>
              {title}
            </Typography>
            <IconButton
              onClick={() => handleClose()}
              sx={{
                backgroundColor: hexToRGB(
                  theme.palette.primary.main,
                  (customOpacityDarkTheme * 2.5).toString()
                ),
                '&:hover': {
                  backgroundColor: hexToRGB(
                    theme.palette.primary.main,
                    (customOpacityDarkTheme * 5).toString()
                  )
                }
              }}
            >
              <ClearIcon />
            </IconButton>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item sx={{ p: 3 }}>
            {children}
          </Grid>

          {buttons.length > 0 && (
            <CustomDialogButtons>
              {buttons.map((button, i) => (
                <Button
                  variant={button.variant}
                  onClick={() => button.onClick()}
                  sx={{ ml: i > 0 && 1 }}
                  disabled={button.disabled || false}
                >
                  {' '}
                  {button.title}{' '}
                </Button>
              ))}
            </CustomDialogButtons>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
