import PropTypes from 'prop-types';
import { FC, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { terminateSessionListener } from 'src/data';
import useAuth from 'src/hooks/useAuth';
import useBeforeClose from 'src/hooks/useBeforeClose';
import useBeforeUnload from 'src/hooks/useBeforeUnload';
import useUnload from 'src/hooks/useUnload';
import NotFound from 'src/pages/NotFound';
import { clearPlay, initPlay, loadPlay } from 'src/slices/play';
import store, { RootState, useSelector } from 'src/store';
import { Play } from 'src/types/play';
import LoadingScreen from '../LoadingScreen';

interface PlayGuardProps {
  children?: ReactNode;
}

const PlayGuard: FC<PlayGuardProps> = ({ children }) => {
  const { playId, teamId } = useParams();
  const [playExists, setPlayExists] = useState<any>(true);
  const { user } = useAuth();

  const play: Play = useSelector((state: RootState) => state.playSlice?.play);

  const cleanup = async () => {
    // this is triggered to close the session when the user leaves the page by closing the tab
    // normal useEffects effects are not triggered when closing tab. thats why we need this.
    if (teamId) {
      await terminateSessionListener();
    }
    store.dispatch(clearPlay());
  };

  const checkPlay = async () => {
    setPlayExists(await store.dispatch(loadPlay(playId, teamId, user.id)));
  };

  // triggers prompt, which can be canceled
  useBeforeUnload(() => {});
  // is triggered to cleanup
  useUnload(cleanup);
  useBeforeClose(cleanup);

  useEffect(() => {
    if (playId) {
      checkPlay();
    } else {
      store.dispatch(initPlay());
    }

    return () => {
      cleanup();
    };
  }, []);

  if (!playExists) {
    return (
      <NotFound
        header="Error: Play not found"
        title="404: The play you are looking for does not exist"
        description="You either have no permission to see this play or tried some shady route."
      />
    );
  }

  if (!play) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
};

PlayGuard.propTypes = {
  children: PropTypes.node
};

export default PlayGuard;
