import { getDoc } from '@firebase/firestore';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  FormHelperText,
  TextField,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { playExists } from 'src/data/firestore/getter/play';
import { updatePlay as updatePlayStore } from 'src/slices/play';
import { addPlay, updatePlay } from 'src/data/firestore/setters/play';
import { updatePlayInSession } from 'src/data/realtimeDb/setters/session';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { auth } from 'src/lib/firebase';
import { setLastSaveTimestamp } from 'src/slices/board/boardUi';
import store, { RootState, useSelector } from 'src/store';
import { Play } from 'src/types/play';
import { AccountData } from 'src/types/user';
import * as Yup from 'yup';

interface IProps {
  onClose: () => void;
  open: boolean;
}

const SavePlayForm: FC<IProps> = (props: IProps): ReactElement => {
  const { onClose, open } = props;
  const navigate = useNavigate();
  const { teamId, playId } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const sessionId = teamId ? playId : null;
  const { team } = store.getState().teamSlice;
  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );
  const spaceTier = teamId ? team.spaceTier : accountData.spaceTier;
  const play: Play = store.getState().playSlice?.play;

  const { model: board } = store.getState().boardSlice;

  const handleClose = (submitting?: boolean) => () => {
    if (onClose && !submitting) {
      onClose();
    }
  };

  const saveExistingPlay = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    const timestamp = Date.now();
    const newPlay: Play = {
      ...play,
      title: values.title,
      board,
      updatedAt: timestamp,
      map: board.map
    };

    try {
      console.log('saveExistingPlay : ', newPlay);

      await updatePlay(
        newPlay,
        true,
        play.createdAt === play.updatedAt,
        teamId
      );

      await updatePlayInSession(sessionId, newPlay);

      enqueueSnackbar(t('play.board.save.success', { title: newPlay.title }), {
        variant: 'success'
      });
      if (isMountedRef.current) {
        setStatus({ success: true });
        setSubmitting(false);
      }
    } catch (err) {
      enqueueSnackbar(
        t('play.board.save.error', {
          title: newPlay.title,
          errorMessage: err.message
        }),
        {
          variant: 'error'
        }
      );
      if (isMountedRef.current) {
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
    }
  };

  const saveNewPlay = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    const timestamp = Date.now();
    const newPlay: Play = {
      id: playId || null,
      photoURL: null,
      title: values.title,
      map: board.map,
      updatedAt: timestamp,
      createdAt: timestamp,
      board,
      referencePlaybookIDs: [],
      whitelistedIDs: teamId ? team.memberIDs : [auth.currentUser.uid]
    };
    try {
      const playsInfoLength = teamId
        ? team.playsInfo.length
        : accountData.playsInfo.length;

      const data = await addPlay(
        newPlay,
        true,
        playsInfoLength,
        spaceTier,
        teamId || undefined
      );
      // update session play

      const snapshot = await getDoc(data);

      enqueueSnackbar(t('play.board.save.success', { title: newPlay.title }), {
        variant: 'success'
      });
      const updatedPlay: Play = snapshot.data();
      // update session
      // think about session update
      if (sessionId) {
        await updatePlayInSession(sessionId, updatedPlay);
      } else {
        // update play in store
        store.dispatch(updatePlayStore(updatedPlay, true));
      }

      if (!teamId) {
        navigate(`../../../../app/personal/plays/${updatedPlay.id}`, {
          replace: true
        });
      }

      if (isMountedRef.current) {
        setStatus({ success: true });
        setSubmitting(false);
      }
    } catch (err) {
      enqueueSnackbar(
        t('play.board.save.error', {
          title: newPlay.title,
          errorMessage: err.message
        }),
        {
          variant: 'error'
        }
      );
      if (isMountedRef.current) {
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
    }
  };

  const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    if (!values.saveAsNew) {
      // set saving timestamp
      store.dispatch(setLastSaveTimestamp(Date.now()));
      if (await playExists(playId)) {
        // only when the play has a id attached it is already been saved
        await saveExistingPlay(values, { setErrors, setStatus, setSubmitting });
      } else {
        await saveNewPlay(values, { setErrors, setStatus, setSubmitting });
      }
    } else {
      // await saveNewPlay(values, { setErrors, setStatus, setSubmitting });
    }

    // close dialog
    if (onClose) {
      onClose();
    }
  };

  const playSchema = Yup.object().shape({
    title: Yup.string()
      .min(3, 'The title is too short!')
      .max(50, 'The title is too long!')
      .required('Title is required for saving!')
      .nullable()
  });

  return (
    <Formik
      initialValues={{
        title: play?.title,
        submit: null
      }}
      validationSchema={playSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({
        errors,
        setFieldValue,
        handleSubmit,
        handleBlur,
        handleChange,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <Dialog
          maxWidth="xs"
          fullWidth
          onClose={handleClose(isSubmitting)}
          open={open}
          disableEscapeKeyDown={isSubmitting}
        >
          <DialogContent sx={{ height: 'auto', maxHeight: '750px', pb: 4 }}>
            <Container
              sx={{
                width: '100%',
                height: '100%',
                py: 2
              }}
            >
              <Form>
                <Typography color="textPrimary" variant="h2">
                  {t('play.board.save.title')}
                </Typography>
                <Box sx={{ mt: 3 }}>
                  <Typography variant="body1" color="textSecondary">
                    {t('play.board.save.description')}
                  </Typography>
                  <Box sx={{ mt: 3 }}>
                    <TextField
                      autoFocus
                      error={Boolean(touched.title && errors.title)}
                      fullWidth
                      helperText={
                        (touched.title && errors.title) ||
                        'Enter a title for your play'
                      }
                      label="Title"
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title}
                      variant="outlined"
                    />
                    {errors.submit && (
                      <Box sx={{ mt: 3 }}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ mt: 2, float: 'right' }}>
                    <Button
                      onClick={handleClose(isSubmitting)}
                      disabled={isSubmitting}
                    >
                      {t('play.board.save.buttons.cancel.label')}
                    </Button>

                    <LoadingButton
                      type="button"
                      name="confirm"
                      onClick={() => {
                        setFieldValue('saveAsNew', false, true);
                        handleSubmit();
                      }}
                      loading={isSubmitting}
                      disabled={isSubmitting || !!errors.title}
                      variant="contained"
                      sx={{ ml: 1 }}
                    >
                      {t('play.board.save.buttons.confirm.label')}
                    </LoadingButton>

                    {/* <LoadingButton
                type="button"
                name="saveasnew"
                loading={isSubmitting}
                disabled={isSubmitting || !!errors.title}
                onClick={() => {
                  setFieldValue('saveAsNew', true, true);
                  handleSubmit();
                }}
              >
                {t('play.board.save.buttons.saveasnew.label')}
              </LoadingButton> */}
                  </Box>
                </Box>
              </Form>
            </Container>
          </DialogContent>
        </Dialog>
      )}
    </Formik>
  );
};

export default SavePlayForm;
