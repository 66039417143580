import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, FormHelperText, Grid, TextField } from '@mui/material';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { FC } from 'react';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import * as Yup from 'yup';

interface CreatePlaybookStepOneProps {
  stepOneData: any;
  submitFunction: Function;
}

export interface CreatePlaybookStepOneData {
  name: string;
  description: string | null;
}

const CreatePlaybookStepOne: FC<CreatePlaybookStepOneProps> = (props) => {
  const { submitFunction, stepOneData } = props;
  const isMountedRef = useIsMountedRef();

  return (
    <Formik
      initialValues={{
        name: stepOneData ? stepOneData.name : '',
        description: stepOneData ? stepOneData.description : '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .min(3, 'Must be at least 3 characters long')
          .max(30, 'Cannot be longer than 30 characters')
          .required('Required'),
        description: Yup.string().min(10, 'Must be at least 10 characters long')
      })}
      onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const createPlaybookStepOneData: CreatePlaybookStepOneData = {
            name: values.name,
            description: values.description
          };

          submitFunction(createPlaybookStepOneData);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={4} sx={{ px: 2 }}>
            <Grid item xs={12} container>
              <Grid item container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={
                      (touched.name && errors.name) ||
                      "Enter your playbook's name"
                    }
                    label="Playbook Name"
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    helperText={
                      (touched.description && errors.description) ||
                      "Enter your playbook's description"
                    }
                    rows={4}
                    multiline
                    label="Playbook Description"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </Grid>

            <Grid
              container
              item
              xs={12}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item />

              <Grid item>
                <Button
                  sx={{}}
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  type="submit"
                  disabled={isSubmitting}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

CreatePlaybookStepOne.propTypes = {
  stepOneData: PropTypes.any,
  submitFunction: PropTypes.func
};

export default CreatePlaybookStepOne;
