import AREASOFEFFECT from 'src/ressources/shapes/areasOfEffect';
import { AOEObject } from 'src/types/board';

const determineAreaOfEffect = (objValue: string): AOEObject => {
  const [agent, ability] = objValue.split('.');

  if (ability) {
    const result = AREASOFEFFECT[agent][ability];
    return result;
  }

  if (agent) {
    return AREASOFEFFECT.AGENT.VIEW;
  }

  return undefined;
};

export default determineAreaOfEffect;
