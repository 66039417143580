import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { IBug } from 'src/types/bug';

type BugNoId = Omit<IBug, 'id'>;

const bugConverter = {
  toFirestore: (bug: IBug): BugNoId => {
    const bugNoId: BugNoId = {
      userId: bug.userId,
      userName: bug.userName,
      email: bug.email,
      createdAt: bug.createdAt,
      content: bug.content,
      photoUrls: bug.photoUrls || null
    };

    return bugNoId;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): IBug {
    const data = snapshot.data(options);
    const { id } = snapshot;
    return <IBug>{
      id,
      userId: data.userId,
      userName: data.userName,
      email: data.email,
      createdAt: data.createdAt,
      content: data.content,
      photoUrls: data.photoUrls
    };
  }
};

export { bugConverter };
