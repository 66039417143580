import DeleteIcon from '@mui/icons-material/Delete';
import SignalWifiStatusbar4BarIcon from '@mui/icons-material/SignalWifiStatusbar4Bar';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import {
  Divider,
  Fade,
  Grid,
  IconButton,
  Paper,
  Popper,
  Theme,
  useTheme
} from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import CustomTooltip from 'src/components/CustomTooltip';
import { customOpacityDarkThemeBoard } from 'src/constants';
import useGlobalDOMEvents from 'src/hooks/useGlobalDOMEvents';
import { ABILITIES } from 'src/ressources/shapes/abilities';
import { AGENTS } from 'src/ressources/shapes/agents';
import { addCommand } from 'src/slices/board/boardModel';
import { setSelectedObject } from 'src/slices/board/boardUi';
import store, { RootState, useSelector } from 'src/store';
import {
  ActionTypes,
  AgentTypes,
  DragTypes,
  IAddRemoveValue,
  ICommand,
  ICommandParams,
  IConnections,
  INode,
  IObject,
  TeamSides
} from 'src/types/board';
import createResourceId from 'src/utils/createResourceId';
import hexToRGB from 'src/utils/hexToRGB';
import DraggableObjectItem from '../../elements/DraggableObjectItem';
import { ValorantItemListObjects } from '../ValorantItemList';

export const menuButtonClass = 'cb-object-menu-button';

interface IProps {
  object: IObject;
  open: boolean;
  anchorEl: any;
  menuPosition: 'top' | 'bottom';
}

const renderAbilities = (
  objects: ValorantItemListObjects,
  startNode: INode,
  attack: TeamSides,
  agent: AgentTypes
) => {
  const list = [];
  Object.entries(objects).forEach(([i, object]) => {
    list.push(
      <Grid
        key={`${object.value}-${i}`}
        sx={{
          m: 1
        }}
        item
      >
        <DraggableObjectItem
          type={DragTypes.ABILITY}
          backgroundColor={AGENTS[agent].backgroundColor}
          startNode={startNode}
          title={object.title}
          value={object.value}
          icon={object.icon}
          attack={attack}
        />
      </Grid>
    );
  });

  return list;
};

const AbilityMenu: FC<IProps> = (props: IProps): ReactElement => {
  const { object, anchorEl, open, menuPosition } = props;
  const { attack } = object;
  const abilities = ABILITIES[object?.value];
  const { playId, teamId } = useParams();
  const theme: Theme = useTheme();
  const sessionId = teamId ? playId : null;
  const { activeStep } = useSelector(
    (state: RootState) => state.boardSlice.model
  );

  useGlobalDOMEvents({
    keydown(e: KeyboardEvent) {
      if (e.key === 'Delete') {
        e.preventDefault();
        handleDeleteClick();
      } else if (e.key === 'Escape') {
        e.preventDefault();
        store.dispatch(setSelectedObject(null));
      }
    }
  });

  const toggleHideVision = () => {
    const newObj: IObject = {
      ...object,
      areaOfEffect: {
        ...object.areaOfEffect,
        hidden: !object.areaOfEffect.hidden
      }
    };

    const oldObj: IObject = {
      ...object
    };

    const command: ICommand = {
      id: createResourceId(),
      type: ActionTypes.CHANGE_OBJECT,
      params: {
        id: object.id,
        value: {
          newObj,
          oldObj
        },
        stepLayer: store.getState().boardSlice.model.activeStep,
        sessionId,
        playId
      } as ICommandParams,
      inverse: ActionTypes.REVERT_OBJECT
    };

    store.dispatch(addCommand(command));
  };

  const handleDeleteClick = () => {
    // add connection to the two connected objects
    const connections: IConnections = {};
    object.connections?.forEach((value) => {
      if (store.getState()?.boardSlice?.model?.connections[value]) {
        connections[value] =
          store.getState().boardSlice.model.connections[value];
      }
    });

    const command: ICommand = {
      id: createResourceId(),
      type: ActionTypes.REMOVE_OBJECT,
      params: {
        value: { object, connections } as IAddRemoveValue,
        stepLayer: activeStep,
        sessionId,
        playId
      } as ICommandParams,
      inverse: ActionTypes.ADD_OBJECT
    };

    store.dispatch(addCommand(command));
    store.dispatch(setSelectedObject(null));
  };

  const buttonSx = { margin: theme.spacing(0.5) };

  const startNode: INode = {
    id: object.id,
    position: object.position
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={menuPosition}
      transition
      // modifiers={[
      //   {
      //     name: 'offset',
      //     options: {
      //       offset: [0, 40]
      //     }
      //   }
      // ]}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={100}>
          <Paper>
            <Paper
              className="cb-ability-menu"
              sx={{
                position: 'relative',
                backgroundColor: hexToRGB(
                  theme.palette.primary.main,
                  (customOpacityDarkThemeBoard * 2).toString()
                ),
                boxShadow: theme.shadows[19],
                mb: menuPosition === 'top' && 1.5,
                mt: menuPosition === 'bottom' && 1.5
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                sx={{
                  borderRadius: `${theme.shape.borderRadius}px`
                }}
              >
                {renderAbilities(abilities, startNode, attack, object.value)}

                <Divider orientation="vertical" variant="middle" flexItem />

                {object.areaOfEffect && (
                  <CustomTooltip
                    title={
                      object.areaOfEffect.hidden ? 'Show vision' : 'Hide vision'
                    }
                    placement="top"
                  >
                    <Grid item>
                      <IconButton
                        className={menuButtonClass}
                        size="large"
                        onClick={toggleHideVision}
                        sx={buttonSx}
                      >
                        {object.areaOfEffect.hidden ? (
                          <SignalWifiStatusbar4BarIcon />
                        ) : (
                          <SignalWifiOffIcon />
                        )}
                      </IconButton>
                    </Grid>
                  </CustomTooltip>
                )}

                <CustomTooltip title="Delete" subtitle="DEL" placement="top">
                  <Grid item>
                    <IconButton
                      className={menuButtonClass}
                      size="large"
                      onClick={handleDeleteClick}
                      sx={buttonSx}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </CustomTooltip>
              </Grid>
            </Paper>{' '}
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default AbilityMenu;
