import {
  Button,
  CircularProgress,
  Container,
  DialogContent,
  Grid,
  Link,
  Typography,
  useTheme
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useNavigate, useParams } from 'react-router-dom';
import SubscriptionTierChip from './app/SubscriptionTierChip';
import TierChip from './TierChip';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import hexToRGB from 'src/utils/hexToRGB';

type BlockedContentType =
  | 'create_play'
  | 'merge_plays'
  | 'clone_plays'
  | 'create_playbook';

interface IProps {
  isTeamSpace: boolean;
  blockedContentType: BlockedContentType;
}

const blockedContentTypeToInfoText = (
  blockedContentType: BlockedContentType,
  isTeamSpace: boolean
) => {
  if (isTeamSpace) {
    if (blockedContentType === 'create_play') {
      return 'Your team is currently running on the basic plan which allows to save up to 5 plays. Upgrade to PRO to save an unlimited amount of plays.';
    }

    if (blockedContentType === 'merge_plays') {
      return 'Your team is currently running on the basic plan which allows to save up to 3 playbooks. Upgrade to PRO to save an unlimited amount of playbooks.';
    }

    if (blockedContentType === 'clone_plays') {
      return 'Your team is currently running on the basic plan which does not allow to clone plays. Upgrade to PRO to clone plays to other spaces.';
    }

    if (blockedContentType === 'create_playbook') {
      return 'Your team is currently running on the basic plan which allows to save up to 3 playbooks. Upgrade to PRO to save an unlimited amount of playbooks.';
    }
  }

  if (blockedContentType === 'create_play') {
    return 'Your personal space is currently subscribed to the basic plan which allows to save up to 5 plays. Upgrade to PRO to save an unlimited amount of plays.';
  }

  if (blockedContentType === 'merge_plays') {
    return 'Your personal space is currently subscribed to the basic plan which allows to save up to 3 playbooks. Upgrade to PRO to save an unlimited amount of playbooks.';
  }

  if (blockedContentType === 'clone_plays') {
    return 'Your personal space is currently subscribed to the basic plan which does not allow to clone plays. Upgrade to PRO to clone plays to other spaces.';
  }

  if (blockedContentType === 'create_playbook') {
    return 'Your personal space is currently subscribed to the basic plan which allows to save up to 3 playbooks. Upgrade to PRO to save an unlimited amount of playbooks.';
  }

  return ' ';
};

const UpgradeSubscriptionDialogContent = (props: IProps) => {
  const { isTeamSpace, blockedContentType } = props;
  const { teamId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const handleNavigateBack = () => {
    if (teamId) {
      navigate(`/app/team/${teamId}`);
    } else {
      navigate(`/app/personal`);
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ px: 3, py: 2 }}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        item
        sx={{
          border: `7px solid ${hexToRGB(theme.palette.secondary.main, '0.3')}`,
          mb: 2,
          width: 'auto',
          borderRadius: 9999
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          item
          sx={{
            p: 1,
            width: 'auto',
            borderRadius: 999,
            backgroundColor: theme.palette.secondary.main
          }}
        >
          <ArrowUpwardIcon
            sx={{
              color: theme.palette.primary.main,
              fontSize: 24
            }}
          />
        </Grid>{' '}
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" align="center" sx={{ mb: 0.5 }}>
          Upgrade your space
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          color="textSecondary"
          variant="body1"
          align="center"
          sx={{ display: 'block' }}
        >
          {blockedContentTypeToInfoText(blockedContentType, isTeamSpace)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UpgradeSubscriptionDialogContent;
