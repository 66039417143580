import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { cloneDeep } from 'lodash';
import { IBoard } from 'src/types/board';
import { Play } from 'src/types/play';

type PlayNoID = Omit<Play, 'id'>;

const playConverter = {
  toFirestore: (play: Play): PlayNoID => {
    // remove these keys from stepLayers since we do  not want them in firestore

    const firestoreStepLayers = Object.fromEntries(
      Object.entries(cloneDeep(play.board.stepLayers)).map(([key, val]) => {
        delete val.commandsFuture;
        delete val.commandsHistory;

        return [key, val];
      })
    );

    const playNoID: PlayNoID = {
      title: play.title || null,
      photoURL: play.photoURL || null,
      map: play.map,
      updatedAt: play.updatedAt,
      createdAt: play.createdAt,
      board: {
        stepLayers: firestoreStepLayers,
        lines: play.board.lines || null,
        objects: play.board.objects || null,
        connections: play.board.connections || null,
        map: play.board.map,
        mapRotation: play.board.mapRotation || 0
      } as IBoard,
      referencePlaybookIDs: play.referencePlaybookIDs || null,
      whitelistedIDs: play.whitelistedIDs || null
    };
    return playNoID;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Play {
    const data = snapshot.data(options);
    const { id } = snapshot;

    return <Play>{
      id,
      title: data.title,
      photoURL: data.photoURL || null,
      map: data.map,
      updatedAt: data.updatedAt,
      createdAt: data.createdAt,
      board: data.board,
      referencePlaybookIDs: data.referencePlaybookIDs,
      whitelistedIDs: data.whitelistedIDs
    };
  }
};

export { playConverter };
