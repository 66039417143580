/* eslint-disable no-await-in-loop */
import { getDownloadURL, StorageReference } from 'firebase/storage';
import wait from 'src/utils/wait';

// Waits for resized URL and returns null if not received until timeout
/* tslint:disable:no-await-in-loop */
const waitForResizedImageURL = async (
  targetRef: StorageReference,
  maxWaitTimeInSec = 10
): Promise<string> => {
  let time = 0;
  let resizedURL = null;
  while (time <= maxWaitTimeInSec) {
    try {
      resizedURL = await getDownloadURL(targetRef);
      break;
      // Do whatever
    } catch (err) {
      console.info('waiting for resized image ...');
    }
    await wait(1000);
    time += 1;
  }

  return resizedURL;
};

export default waitForResizedImageURL;
