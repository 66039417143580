import { CleanConsoleConfiguration } from './logging/clientSideLogger';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const stripeConfig = {
  personalProductId: process.env.REACT_APP_STRIPE_PERSONAL_PRODUCT_ID,
  teamProductId: process.env.REACT_APP_STRIPE_TEAM_PRODUCT_ID
};

export const sendGridConfig = {
  apiKey: process.env.REACT_APP_SENDGRID_API_KEY,
  newsletterId: process.env.REACT_APP_SENDGRID_NEWSLETTER_LIST_ID,
  betaSignupId: process.env.REACT_APP_SENDGRID_BETA_SIGNUP_LIST_ID
};

export const algoliaConfig = {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID,
  searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
  apiKey: process.env.REACT_APP_ALGOLIA_ADMIN_API_KEY
};

export const loggerConfig: CleanConsoleConfiguration = {
  debugLocalStorageKey: 'debug-critbase'
};
