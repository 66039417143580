import { IDrawingLine, IPoint } from 'src/types/board';

const createLineObj = (
  point: IPoint,
  currentLine: IDrawingLine
): IDrawingLine => {
  const newPoints = [...currentLine.points, point];
  const newPath = `M ${newPoints
    .map((p: IPoint) => `${p.x} ${p.y}`)
    .join(' L ')}`;

  return {
    ...currentLine,
    points: newPoints,
    path: newPath
  } as IDrawingLine;
};

export default createLineObj;
