import SearchIcon from '@mui/icons-material/Search';
import { Box, Divider, Grid, TextField, Theme, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { AGENTS } from 'src/ressources/shapes/agents';
import { DragTypes, INode, TeamSides } from 'src/types/board';
import hexToRGB from 'src/utils/hexToRGB';
import DraggableObjectItem from '../elements/DraggableObjectItem';
import AttackDefendSwitch from './AttackDefendSwitch';

export interface ValorantItemListObject {
  value: string;
  backgroundColor?: string;
  title: string;
  icon: any;
}

export interface ValorantItemListObjects {
  [key: string]: ValorantItemListObject;
}

export interface NestedValorantItemListObjects {
  [key: string]: ValorantItemListObjects;
}

const agentAndAbilitiesToSubarray = (targetArray: any[]) => {
  let remainingArray: any[] = targetArray;
  const returnArray: any[][] = [];

  while (remainingArray.length > 5) {
    returnArray.push(remainingArray.slice(0, 5));

    remainingArray = remainingArray.slice(5, remainingArray.length);
  }

  returnArray.push(remainingArray);

  return returnArray;
};

interface IProps {
  dragType: DragTypes;
  objects: ValorantItemListObjects;
  nestedObjects?: NestedValorantItemListObjects;
  attack?: TeamSides;
  setAttack?: Function;
  filterEnabled?: boolean;
  startNode?: INode;
}

const ValorantItemList: FC<IProps> = (props: IProps) => {
  const {
    objects,
    nestedObjects,
    attack,
    setAttack,
    dragType,
    filterEnabled,
    startNode
  } = props;
  const theme: Theme = useTheme();
  const [filter, setFilter] = useState<string>();

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAttack(event.target.checked ? TeamSides.ATTACK : TeamSides.DEFEND);
  };

  const filterObject = (object, filterValue) => {
    if (filterValue && filterValue.length > 0) {
      return Object.fromEntries(
        Object.entries(object).filter(([key]) =>
          key.toLowerCase().includes(filter.toLowerCase().trim())
        )
      );
    }
    return object;
  };

  const filterAgents = (filterValue) => {
    if (filterValue && filterValue.length > 0) {
      const temp = Object.entries(AGENTS)
        .filter(([key]) =>
          key.toLowerCase().includes(filter.toLowerCase().trim())
        )
        .map((entry) => entry[1]);
      return temp;
    }
    return Object.entries(AGENTS).map((entry) => entry[1]);
  };

  const renderItems = (type: 'agents' | 'objects') => {
    if (type === 'agents') {
      const agentsAndAbilities = [];
      if (nestedObjects) {
        let newNestedObjects = nestedObjects;

        if (filterEnabled) {
          newNestedObjects = filterObject(nestedObjects, filter);
        }

        Object.entries(newNestedObjects).forEach(([i, agent]) => {
          agentsAndAbilities.push(
            <Grid
              key={`${AGENTS[i].value}-${AGENTS[i].title}`}
              sx={{ m: 0.75, backgroundColor: 'transparent' }}
              item
            >
              <DraggableObjectItem
                startNode={startNode}
                backgroundColor={AGENTS[i].backgroundColor}
                type={DragTypes.AGENT}
                title={`${AGENTS[i].title}`}
                value={AGENTS[i].value}
                icon={AGENTS[i].icon}
                attack={attack}
              />
            </Grid>
          );

          Object.entries(agent).forEach(([, ability]) => {
            agentsAndAbilities.push(
              <Grid
                key={`${ability.value}-${ability.title}`}
                sx={{ m: 0.75 }}
                item
              >
                <DraggableObjectItem
                  startNode={startNode}
                  backgroundColor={AGENTS[i].backgroundColor}
                  type={dragType}
                  title={`${ability.title}`}
                  value={ability.value}
                  icon={ability.icon}
                  attack={attack}
                />
              </Grid>
            );
          });
        });
      }

      return agentsAndAbilities;
    }

    if (objects) {
      const list = [];
      Object.entries(objects).forEach(([i, object]) => {
        list.push(
          <Grid
            key={`${object.value}-${i}`}
            sx={{
              m: 0.75
            }}
            item
          >
            <DraggableObjectItem
              type={dragType}
              backgroundColor={object.backgroundColor}
              startNode={startNode}
              title={object.title}
              value={object.value}
              icon={object.icon}
              attack={attack}
            />
          </Grid>
        );
      });
      return list;
    }

    return [];
  };

  const handleFilter = (event) => {
    setFilter(event.target.value);
  };

  return (
    <>
      {filterEnabled && (
        <>
          <Grid container sx={{ mb: 2 }}>
            <Grid item sx={{ width: '70%' }}>
              <TextField
                autoComplete="off"
                label="Search agent"
                size="small"
                onChange={handleFilter}
                InputProps={{
                  endAdornment: (
                    <SearchIcon sx={{ fill: theme.palette.text.secondary }} />
                  )
                }}
                sx={{
                  mt: 0,
                  pt: 0,
                  width: '100%',
                  backgroundColor: hexToRGB(
                    theme.palette.primary.main,
                    '0.015'
                  ),
                  borderRadius: `${theme.shape.borderRadius}px`
                }}
              />
            </Grid>
            <Grid
              item
              sx={{ width: '30%' }}
              container
              justifyContent="center"
              alignItems="center"
            >
              <AttackDefendSwitch
                checked={attack === TeamSides.ATTACK}
                onChange={handleSwitchChange}
              />
            </Grid>
          </Grid>
          <Divider sx={{ pt: 1, mb: 1.5 }} />
        </>
      )}

      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
        sx={{
          width: '100%',
          height: '100%'
        }}
      >
        {((dragType === DragTypes.ABILITY && nestedObjects) ||
          (dragType === DragTypes.AGENT && nestedObjects)) &&
          agentAndAbilitiesToSubarray(renderItems('agents')).map(
            (subarray, i) => (
              <Box
                key={`item_${i.toString()}`}
                sx={{
                  mb: 1.5,
                  width: '100%'
                }}
              >
                {/* 
                <Typography
                  sx={{
                    color: `${theme.palette.text.secondary}!important`,
                    pl: 0.75
                  }}
                  variant="overline"
                >
                  {filterAgents(filter)[i] && filterAgents(filter)[i].title}
                </Typography>
                */}
                <Grid
                  container
                  justifyContent="space-evenly"
                  alignItems="center"
                  sx={{
                    borderRadius: `${theme.shape.borderRadius}px`,
                    backgroundColor:
                      filterAgents(filter)[i] &&
                      hexToRGB(theme.palette.primary.main, '0.03'),
                    width: '100%',
                    px: 0,
                    py: 0
                  }}
                >
                  {subarray.map((item) => item)}
                </Grid>
              </Box>
            )
          )}

        {objects && (
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="center"
            sx={{
              borderRadius: `${theme.shape.borderRadius}px`,
              backgroundColor: hexToRGB(theme.palette.primary.main, '0.04'),
              py: 0.5
            }}
          >
            {renderItems('objects')}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ValorantItemList;
