import {
  addConnection,
  addLine,
  addObject,
  removeConnection,
  removeLine,
  removeObject as removeObjectDb,
  updateConnection,
  updateObject
} from 'src/data/realtimeDb/setters/session';
import { ActionTypes, ICommand } from 'src/types/board';

const executeCommandInDb = async (
  action: { command: ICommand },
  inverseCommand?: boolean
) => {
  const { command } = action;
  const { type, inverse, params } = command;
  const actionType = inverseCommand ? inverse : type;
  switch (actionType) {
    case ActionTypes.DRAW: {
      await addLine(params.value, params.sessionId, params.stepLayer);
      break;
    }
    case ActionTypes.ERASE: {
      await removeLine(params.value, params.sessionId, params.stepLayer);
      break;
    }
    case ActionTypes.ADD_OBJECT: {
      await addObject(params.value, params.sessionId, params.stepLayer);
      break;
    }
    case ActionTypes.REMOVE_OBJECT: {
      await removeObjectDb(params.value, params.sessionId, params.stepLayer);
      break;
    }
    case ActionTypes.CHANGE_OBJECT: {
      await updateObject(
        params.value.newObj,
        params.sessionId,
        params.stepLayer
      );
      break;
    }
    case ActionTypes.REVERT_OBJECT: {
      await updateObject(
        params.value.oldObj,
        params.sessionId,
        params.stepLayer
      );
      break;
    }

    case ActionTypes.ADD_CONNECTION: {
      await addConnection(params.value, params.sessionId, params.stepLayer);
      break;
    }
    case ActionTypes.REMOVE_CONNECTION: {
      await removeConnection(params.value, params.sessionId, params.stepLayer);
      break;
    }
    case ActionTypes.CHANGE_CONNECTION: {
      await updateConnection(
        params.value.newConnection,
        params.sessionId,
        params.stepLayer
      );
      break;
    }
    case ActionTypes.REVERT_CONNECTION: {
      await updateConnection(
        params.value.oldConnection,
        params.sessionId,
        params.stepLayer
      );
      break;
    }

    default: {
      break;
    }
  }
};

export default executeCommandInDb;
