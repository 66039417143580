import {
  createTheme as createMuiTheme,
  responsiveFontSizes,
  Theme
} from '@mui/material/styles';
import { baseThemeOptions } from './base-theme-options';
import { darkThemeOptions } from './dark-theme-options';
import { lightThemeOptions } from './light-theme-options';

interface ThemeConfig {
  theme: 'light' | 'dark';
  responsiveFontSizes: boolean;
}

export const createTheme = (config: ThemeConfig): Theme => {
  let theme = createMuiTheme(
    baseThemeOptions,

    config.theme === 'dark' ? darkThemeOptions : lightThemeOptions
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  // prevent Error msg that shadow[24] is not defined
  const defaultShadow = theme.shadows[0];
  theme.shadows[24] = defaultShadow;

  return theme;
};
