import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { usePlaybookSliceLogs } from 'src/constants';
import type { AppThunk } from 'src/store';
import { Playbook } from 'src/types/play';

interface PlaybookState {
  playbook: Playbook | null;
}

const initialState: PlaybookState = {
  playbook: null
};

const slice = createSlice({
  name: 'playbookSlice',
  initialState,
  reducers: {
    updatePlaybook(
      state: PlaybookState,
      action: PayloadAction<{ playbook: Playbook }>
    ) {
      const { playbook } = action.payload;
      state.playbook = playbook || null;
    },
    clearPlaybook(state: PlaybookState) {
      state.playbook = initialState.playbook;
    }
  }
});

export const { reducer } = slice;

export const updatePlaybook =
  (playbookToUpdate: Playbook, exists: boolean): AppThunk =>
  (dispatch) => {
    if (exists) {
      const response = { playbook: playbookToUpdate };
      dispatch(slice.actions.updatePlaybook(response));
      if (usePlaybookSliceLogs) {
        console.log('PLAYBOOK UPDATE: - ADDED/MODIFIED PLAYBOOK:');
        console.log(playbookToUpdate);
      }
    } else {
      dispatch(slice.actions.clearPlaybook());
      if (usePlaybookSliceLogs) {
        console.log('PLAYBOOK UPDATE: - CLEARED PLAYBOOK');
      }
    }
  };

export const clearPlaybook = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.clearPlaybook());
  if (usePlaybookSliceLogs) {
    console.log('PLAYBOOK CLEARED!');
  }
};

export default slice;
