import { Playbook, PlaybookInfo } from 'src/types/play';

const playbookToPlaybookInfo = (
  playbook: Playbook,
  id: string
): PlaybookInfo => {
  const playbookInfo = {
    id,
    title: playbook.title,
    photoURL: playbook.photoURL ? playbook.photoURL : null,
    updatedAt: playbook.updatedAt,
    createdAt: playbook.createdAt,
    playsInfo: playbook.playsInfo,
    description: playbook.description,
    numberOfPlays: playbook.numberOfPlays || playbook.playsInfo.length,
    thumbnail: playbook.thumbnail
  };

  return playbookInfo;
};

export { playbookToPlaybookInfo };
