import { TeamInfo } from 'src/types/team';

const selectTeamInfoProperty = (
  teamsInfo: TeamInfo[],
  objID: string,
  property: 'displayName' | 'photoURL' | 'spaceTier'
) => {
  const team = teamsInfo.find((teamInfo) => teamInfo.id === objID);
  if (team) {
    if (property === 'displayName') {
      return team.displayName;
    }

    return team.photoURL;
  }

  return null;
};

export default selectTeamInfoProperty;
