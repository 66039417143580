import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  useTheme
} from '@mui/material';
import { FC, useState } from 'react';
import { PlaybookInfo } from 'src/types/play';
import DeletePlaybookDialog from './DeletePlaybookDialog';

interface IProps {
  playbook: PlaybookInfo;
}

const PlaybookOtherSettings: FC<IProps> = (props: IProps) => {
  const { playbook } = props;
  const [deletePlaybookDialogOpen, setDeletePlaybookDialogOpen] =
    useState(false);
  const theme = useTheme();

  return (
    <Card>
      <CardContent>
        <Grid container sx={{ p: 0 }}>
          <Grid item xs={12}>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="subtitle2"
              sx={{ pb: 1 }}
            >
              Delete Playbook
            </Typography>
          </Grid>
          <Grid item xs={12} container>
            <Typography color="textSecondary" sx={{ mb: 0 }} variant="body2">
              This is where you can delete this playbook if you intend to do so.
              Deleting this playbook will NOT delete its included plays. This
              step is not reversible.
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 1
        }}
      >
        <>
          <Button
            variant="contained"
            sx={{
              backgroundColor: `${theme.palette.error.main}`,
              '&:hover': {
                backgroundColor: `${theme.palette.error.dark}`
              }
            }}
            type="button"
            onClick={() => setDeletePlaybookDialogOpen(true)}
          >
            Delete Playbook
          </Button>
          <DeletePlaybookDialog
            open={deletePlaybookDialogOpen}
            setOpen={setDeletePlaybookDialogOpen}
            playbookID={playbook.id}
          />
        </>
      </Box>
    </Card>
  );
};

export default PlaybookOtherSettings;
