import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FC, useEffect } from 'react';
import CustomDialog from 'src/components/CustomDialog';
import CustomDialogHeader from 'src/components/CustomDialogHeader';
import { TeamMemberData } from 'src/types/team';
import CustomAvatar from '../../CustomAvatar';

interface TeamMemberInfoDialogProps {
  teamMemberData: TeamMemberData;
  open: boolean;
  setOpen: Function;
}

const TeamMemberInfoDialog: FC<TeamMemberInfoDialogProps> = (props) => {
  const { teamMemberData, open, setOpen } = props;

  // cleanup on unmount
  useEffect(() => {
    const returnVal = null;
    return () => returnVal;
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      onClose={onClose}
      title="Member Info"
      width={390}
      buttons={[]}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ p: 2 }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ pb: 3 }}
        >
          <CustomAvatar
            src={teamMemberData.url}
            sizeFactor={1.25}
            isTeamAvatar={false}
          />
        </Grid>

        <Box sx={{ width: '100%', pb: 2 }}>
          <Divider />
        </Box>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ pb: 1 }}
        >
          <Grid
            item
            xs={4}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="subtitle2" color="textSecondary">
              Name
            </Typography>
          </Grid>

          <Grid item xs={8} sx={{ pl: 1 }}>
            <Typography variant="h4">{teamMemberData.name}</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ pb: 1 }}
        >
          <Grid
            item
            xs={4}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="subtitle2" color="textSecondary">
              Username
            </Typography>
          </Grid>

          <Grid item xs={8} sx={{ pl: 1 }}>
            <Typography>{teamMemberData.username}</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ pb: 1 }}
        >
          <Grid
            item
            xs={4}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="subtitle2" color="textSecondary">
              Role
            </Typography>
          </Grid>

          <Grid item xs={8} sx={{ pl: 1 }}>
            <Typography>{teamMemberData.role}</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ pb: 2 }}
        >
          <Grid
            item
            xs={4}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="subtitle2" color="textSecondary">
              joined
            </Typography>
          </Grid>

          <Grid item xs={8} sx={{ pl: 1 }}>
            <Typography>{`${moment(
              teamMemberData.joined
            ).fromNow()}`}</Typography>
          </Grid>
        </Grid>

        <Box sx={{ width: '100%', pb: 3 }}>
          <Divider />
        </Box>
      </Grid>
    </CustomDialog>
  );
};

TeamMemberInfoDialog.propTypes = {
  teamMemberData: PropTypes.any,
  open: PropTypes.bool,
  setOpen: PropTypes.func
};

export default TeamMemberInfoDialog;
