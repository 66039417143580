import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { multiClonePlays } from 'src/data/firestore/setters/play';
import useAuth from 'src/hooks/useAuth';
import { RootState } from 'src/store';
import { Team, TeamInfo } from 'src/types/team';
import { AccountData } from 'src/types/user';
import TeamList from '../../../TeamList';

interface IProps {
  open: boolean;
  setOpen: Function;
  playID: string;
}

const ClonePlayDialog: FC<IProps> = (props: IProps) => {
  const { open, setOpen, playID } = props;
  const [waitingForFunctionResponse, setWaitingForFunctionResponse] =
    useState<boolean>(false);
  const { user } = useAuth();
  const { teamId } = useParams();
  const team: Team = useSelector((state: RootState) => state.teamSlice.team);
  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );
  const spaceTier = teamId ? team.spaceTier : accountData.spaceTier;
  const [selectedTeams, setSelectedTeams] = useState<TeamInfo[]>([]);
  const [personalSelected, setPersonalSelected] = useState<boolean>(false);

  const teams: TeamInfo[] = useSelector((state: RootState) =>
    state.accountDataSlice.accountData
      ? state.accountDataSlice.accountData.teamsInfo
      : []
  );
  const { enqueueSnackbar } = useSnackbar();
  const handleClickCancel = () => {
    setOpen(false);
  };

  const handleSelectTeams = (value) => {
    setSelectedTeams([...value]);
  };

  const handleClonePlay = async () => {
    setWaitingForFunctionResponse(true);

    try {
      await multiClonePlays(
        [playID],
        true,
        selectedTeams.map((value) => value.id),
        personalSelected && user.id,
        spaceTier
      );

      enqueueSnackbar(`Successfully cloned play`, {
        variant: 'success'
      });
    } catch (err) {
      console.error(`Cloning play failed:`, err);
      enqueueSnackbar(`Cloning play failed`, {
        variant: 'error'
      });
    }
    setSelectedTeams([]);
    setPersonalSelected(false);
    setOpen(false);
    setWaitingForFunctionResponse(false);
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        if (!waitingForFunctionResponse) {
          setOpen(false);
        }
      }}
    >
      <DialogContent sx={{ height: 'auto', maxHeight: '750px' }}>
        <Container
          sx={{
            width: '100%',
            height: '100%',
            py: 2
          }}
        >
          {waitingForFunctionResponse ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ height: '150px' }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={12}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sx={{ mt: 3, mb: 1 }}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  Select the spaces that you want this play to be cloned to.
                </Typography>
              </Grid>

              <Grid item xs={12} sx={{ mt: 1, mb: 4 }}>
                <TeamList
                  teams={teams}
                  setSelectedTeams={handleSelectTeams}
                  selectedTeams={selectedTeams}
                  personalSelected={personalSelected}
                  setPersonalSelected={setPersonalSelected}
                />
              </Grid>

              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  sx={{}}
                  variant="text"
                  onClick={() => handleClickCancel()}
                  disabled={waitingForFunctionResponse}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleClonePlay()}
                  disabled={waitingForFunctionResponse}
                >
                  Ok
                </Button>
              </Grid>
            </Grid>
          )}
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default ClonePlayDialog;
