import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Step,
  StepLabel,
  Stepper
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CustomDialog from 'src/components/CustomDialog';
import CustomDialogHeader from 'src/components/CustomDialogHeader';
import { addPlaybook } from 'src/data/firestore/setters/playbook';
import useSettings from 'src/hooks/useSettings';
import { auth } from 'src/lib/firebase';
import { RootState, useSelector } from 'src/store';
import { Playbook, PlayInfo } from 'src/types/play';
import { Team } from 'src/types/team';
import { AccountData } from 'src/types/user';
import playsInfoToThumbnail from 'src/utils/playsInfoToThumbnail';
import CreatePlaybookStepOne, {
  CreatePlaybookStepOneData
} from './CreatePlaybookStepOne';
import CreatePlaybookStepThree, {
  CreatePlaybookStepThreeData
} from './CreatePlaybookStepThree';
import CreatePlaybookStepTwo from './CreatePlaybookStepTwo';

const steps = [
  'Enter Playbook Information',
  'Select Plays',
  'Confirm Playbook Creation'
];

interface CreatePlaybookDialogProps {
  open: boolean;
  setDialogOpen: Function;
  preselectedPlays: string[];
}

const CreatePlaybookDialog: FC<CreatePlaybookDialogProps> = (props) => {
  const { open, setDialogOpen, preselectedPlays } = props;
  const { settings } = useSettings();
  const activeWorkspace = settings?.activeSpaceID;

  const personalPlaysInfo: PlayInfo[] = useSelector(
    (state: RootState) => state.accountDataSlice?.accountData?.playsInfo
  );
  const { teamId } = useParams();
  const team: Team = useSelector((state: RootState) => state.teamSlice.team);

  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );
  const spaceTier = teamId ? team?.spaceTier : accountData?.spaceTier;

  const [activeStep, setActiveStep] = useState(0);

  const [stepOneData, setStepOneData] =
    useState<CreatePlaybookStepOneData | null>(null);

  const [stepTwoData, setStepTwoData] = useState<string[]>([]);

  const [waitingForPlaybookCreation, setWaitingForPlaybookCreation] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleResetActiveStep = () => {
    setActiveStep(0);
  };

  const handleSubmitStepOneData = (data: CreatePlaybookStepOneData) => {
    setStepOneData(data);
    handleNext();
  };

  const handleSubmitStepTwoData = (
    data: string[],
    direction: 'forward' | 'back'
  ) => {
    setStepTwoData(data);
    if (direction === 'forward') {
      handleNext();
    } else {
      handleBack();
    }
  };

  const handleSubmitStepThreeData = async (
    data: CreatePlaybookStepThreeData
  ) => {
    setWaitingForPlaybookCreation(true);
    handleNext();

    const tempPlayInfos = data.teamID
      ? team?.playsInfo?.filter((info) => data.plays.includes(info.id))
      : personalPlaysInfo?.filter((info) => data.plays.includes(info.id));

    const playsInfo = tempPlayInfos || [];

    const newPlaybookObject: Playbook = {
      playsInfo,
      id: null,
      title: data.name,
      photoURL: null,
      updatedAt: Date.now(),
      createdAt: Date.now(),
      description: data.description,
      numberOfPlays: playsInfo.length,
      thumbnail: playsInfoToThumbnail(playsInfo),
      whitelistedIDs: activeWorkspace ? team.memberIDs : [auth.currentUser.uid]
    };

    try {
      const response = await addPlaybook(
        newPlaybookObject,
        true,
        spaceTier,
        activeWorkspace
          ? team.playbooksInfo.length
          : accountData.playbooksInfo.length,
        activeWorkspace || null
      );

      if (response.id) {
        enqueueSnackbar('Playbook created', {
          variant: 'success'
        });
        setDialogOpen(false);
        navigate(
          activeWorkspace
            ? `/app/team/${activeWorkspace}/playbooks/${response.id}`
            : `/app/personal/playbooks/${response.id}`
        );
      } else {
        console.error('Playbook Creation failed');
        enqueueSnackbar('Playbook Creation failed', {
          variant: 'error'
        });

        setDialogOpen(false);
      }
    } catch (err) {
      console.error('Playbook Creation failed: ', err);
      enqueueSnackbar('Playbook Creation failed', {
        variant: 'error'
      });

      setDialogOpen(false);
      setWaitingForPlaybookCreation(false);
      handleResetActiveStep();
      handleResetStepOneData();
      handleResetStepTwoData();
    }
  };

  const handleResetStepOneData = () => {
    setStepOneData(null);
  };

  const handleResetStepTwoData = () => {
    setStepTwoData([]);
  };

  const onClose = () => {
    if (!waitingForPlaybookCreation) {
      setDialogOpen(false);
      handleResetActiveStep();
      handleResetStepOneData();
      handleResetStepTwoData();
    }
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setDialogOpen}
      onClose={onClose}
      title="Create a new Playbook"
      width={700}
      buttons={[]}
    >
      <Box sx={{ width: '100%', maxHeight: '750px', mb: 1 }}>
        <Box sx={{ width: '100%', mt: 2, mb: 5 }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {activeStep === 0 && (
          <CreatePlaybookStepOne
            submitFunction={handleSubmitStepOneData}
            stepOneData={stepOneData}
          />
        )}

        {activeStep === 1 && (
          <CreatePlaybookStepTwo
            stepTwoData={stepTwoData}
            submitFunction={handleSubmitStepTwoData}
            preselectedPlays={preselectedPlays}
          />
        )}

        {activeStep === 2 && (
          <CreatePlaybookStepThree
            backToStepTwo={handleBack}
            stepTwoData={stepTwoData}
            stepOneData={stepOneData}
            submitFunction={handleSubmitStepThreeData}
          />
        )}

        {activeStep > 2 && waitingForPlaybookCreation && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ height: '300px' }}
          >
            <CircularProgress />
          </Grid>
        )}
      </Box>
    </CustomDialog>
  );
};

CreatePlaybookDialog.propTypes = {
  open: PropTypes.bool,
  setDialogOpen: PropTypes.func,
  preselectedPlays: PropTypes.array
};

export default CreatePlaybookDialog;
