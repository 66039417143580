import { addDoc, collection, DocumentReference } from 'firebase/firestore';
import { feedbackConverter } from 'src/data/converter/feedbackConverter';
import { trackGiveFeedback } from 'src/data/mixpanel/setters/trackEvent';
import { auth, firestore } from 'src/lib/firebase';
import serverSideLogger from 'src/logging/serverSideLogger';
import { IFeedback } from 'src/types/feedback';
import { LoggerInput } from 'src/types/functionsInput';

const feedbacksRef = collection(firestore, 'feedback');

export const giveFeedback = async (
  content: string
): Promise<DocumentReference<IFeedback>> => {
  // call this function when we want to save the play

  const feedback: IFeedback = {
    id: null,
    content,
    userId: auth.currentUser.uid,
    userName: auth.currentUser.displayName,
    email: auth.currentUser.email,
    createdAt: Date.now()
  };
  try {
    const result = await addDoc(
      feedbacksRef.withConverter(feedbackConverter),
      feedback
    );

    trackGiveFeedback({
      feedback_id: result.id,
      content: feedback.content,
      user_id: feedback.userId,
      user_display_name: feedback.userName,
      email: feedback.email
    });
    return result;
  } catch (e) {
    console.error(e);
    const logObject: LoggerInput = {
      kind: 'error',
      function: 'giveFeedback',
      message: e,
      metaData: {
        content
      }
    };
    serverSideLogger(logObject);
    throw new Error('Creating feedback failed');
  }
};
