import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import { IconButton, Popover, Theme, useTheme } from '@mui/material';
import React, { FC, ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomTooltip from 'src/components/CustomTooltip';
import { DEFAULT_TEXTAREA_FONT_COLOR, LINE_COLOR_OPTIONS } from 'src/constants';
import { addCommand } from 'src/slices/board/boardModel';
import store from 'src/store';
import {
  ActionTypes,
  DragTypes,
  ICommand,
  ICommandParams,
  IObject
} from 'src/types/board';
import createResourceId from 'src/utils/createResourceId';
import ColorSelector from '../ColorSelector';

interface IProps {
  object: IObject;
}

const FontColorPicker: FC<IProps> = (props: IProps): ReactElement => {
  const { object } = props;
  const { playId, teamId } = useParams();
  const sessionId = teamId ? playId : null;

  const theme: Theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [fontColor, setFontColor] = useState<string>(
    object?.specs?.[DragTypes.TEXTAREA]?.fontColor ||
      DEFAULT_TEXTAREA_FONT_COLOR
  );

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleItemClick = (newFontColor: string) => {
    if (newFontColor) {
      setFontColor(newFontColor);

      const newObj: IObject = {
        ...object,
        specs: {
          [DragTypes.TEXTAREA]: {
            ...object.specs?.[DragTypes.TEXTAREA],
            fontColor: newFontColor
          }
        }
      };

      const oldObj: IObject = {
        ...object
      };
      const command: ICommand = {
        id: createResourceId(),
        type: ActionTypes.CHANGE_OBJECT,
        params: {
          value: {
            newObj,
            oldObj
          },
          stepLayer: store.getState().boardSlice.model.activeStep,
          sessionId,
          playId
        } as ICommandParams,
        inverse: ActionTypes.REVERT_OBJECT
      };
      store.dispatch(addCommand(command));
    }
    setAnchorEl(null);
  };

  const buttonSx = {
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    border: 0,
    height: 40,
    width: 40
  };
  return (
    <div className="cb-font-color-picker">
      <CustomTooltip title="Font color" placement="top" enterDelay={500}>
        <IconButton sx={buttonSx} onClick={handleClick} size="large">
          <>
            <FormatColorTextIcon sx={{ color: theme.palette.text.secondary }} />
            <div
              style={{
                backgroundColor: fontColor,
                position: 'absolute',
                width: 16,
                height: 4,
                top: 25
              }}
            />
          </>
        </IconButton>
      </CustomTooltip>

      <Popover
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        PaperProps={{
          sx: {
            mt: `-${theme.spacing(2)}`,
            border: '0px !important',
            boxShadow: theme.shadows[19]
          }
        }}
      >
        <ColorSelector
          colors={LINE_COLOR_OPTIONS}
          selectedValue={fontColor}
          onSelect={handleItemClick}
          textColors
        />
      </Popover>
    </div>
  );
};

export default FontColorPicker;
