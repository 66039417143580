import { useSwitch, UseSwitchProps } from '@mui/core/SwitchUnstyled';
import { styled } from '@mui/material';
import clsx from 'clsx';
import { ATTACK_COLOR, DEFEND_COLOR } from 'src/constants';
import DefenderIcon from 'src/ressources/toolbar/shield.svg';
import AttackerIcon from 'src/ressources/toolbar/sword.svg';

const SwitchRoot = styled('span')`
  display: inline-block;
  position: relative;
  width: 48px;
  height: 28px;
  padding: 7px;
`;

const SwitchInput = styled('input')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`;

const SwitchThumb = styled('span')(
  ({ theme }) => `
  position: absolute;
  background-color:${DEFEND_COLOR};
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 16px;
  top: 1px;
  left: 0px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);

  &:before {
    
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-color:${DEFEND_COLOR};
    background: url(${DefenderIcon}) center center no-repeat;
  }

  &.focusVisible {
    background-color: ${theme.palette.text.secondary}; 
  }

  &.checked {
    transform: translateX(22px);
    background-color:${ATTACK_COLOR} ;

    &:before {
      background-image: url(${AttackerIcon})
    }
  }
`
);

const SwitchTrack = styled('span')(
  ({ theme }) => `
  background-color: ${theme.palette.text.secondary};
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: block;
`
);

const AttackDefendSwitch = (props: UseSwitchProps) => {
  const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);

  const stateClasses = {
    checked,
    disabled,
    focusVisible
  };

  return (
    <SwitchRoot className={clsx(stateClasses)}>
      <SwitchTrack>
        <SwitchThumb className={clsx(stateClasses)} />
      </SwitchTrack>
      <SwitchInput {...getInputProps()} aria-label="Demo switch" />
    </SwitchRoot>
  );
};

export default AttackDefendSwitch;
