import { Badge, styled } from '@mui/material';

interface StyledBadgeProps {
  isonline: string;
  rippleactivated?: boolean;
}

const StyledBadge = styled(Badge)<StyledBadgeProps>(
  ({ theme, isonline, rippleactivated }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: isonline
        ? theme.palette.success.main
        : theme.palette.error.main,
      color: isonline ? theme.palette.success.main : theme.palette.error.main,
      boxShadow: `0 0 0 1px #18181d`,
      bottom: 5,
      marginRight: 6,
      right: -1,
      ...(rippleactivated && {
        '&::after': {
          position: 'absolute',
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          animation: rippleactivated && 'ripple 1.2s infinite ease-in-out',
          border: '1px solid currentColor',
          content: '""'
        }
      })
    },
    ...(rippleactivated && {
      '@keyframes ripple': {
        '0%': {
          transform: 'scale(.8)',
          opacity: 1
        },
        '100%': {
          transform: 'scale(1.8)',
          opacity: 0
        }
      }
    })
  })
);

export default StyledBadge;
