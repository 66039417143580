import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { giveFeedback } from 'src/data/firestore/setters/feedback';
import * as Yup from 'yup';

interface IProps {
  onClose: () => void;
}

const FeedbackContact: FC<IProps> = (props: IProps) => {
  const { onClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const onSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting, resetForm }
  ) => {
    try {
      await giveFeedback(values.description);

      resetForm();
      setStatus({ success: true });
      setSubmitting(false);
      enqueueSnackbar('We received your feedback. Thanks!', {
        variant: 'success'
      });

      if (onClose) {
        onClose();
      }
    } catch (err) {
      setStatus({ success: false });
      setErrors({
        submit: 'Something went wrong sending your feedback. Please try again.'
      });
      setSubmitting(false);
      enqueueSnackbar(
        'Something went wrong sending your feedback. Please try again.',
        { variant: 'error' }
      );
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          color="textPrimary"
          gutterBottom
          variant="body1"
          sx={{ pb: 3, pt: 1 }}
        >
          Please let us know what you think of critbase. We are open to
          suggestions for improvement and always try to provide the best user
          experience for you.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Formik
          enableReinitialize
          initialValues={{
            description: '',
            submit: null
          }}
          validationSchema={Yup.object().shape({
            description: Yup.string()
              .min(10, 'Must be atleast 10 characters long')
              .max(1000, 'Cannot be longer than 1000 characters')
              .required('A description is required')
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }): JSX.Element => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    helperText={
                      (touched.description && errors.description) ||
                      'Tell us what you think'
                    }
                    label="Your Feedback"
                    name="description"
                    rows={4}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    variant="outlined"
                    multiline
                  />
                </Grid>
              </Grid>
              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 0
                }}
              >
                <LoadingButton
                  color="primary"
                  onClick={() => handleSubmit()}
                  loading={isSubmitting}
                  disabled={isSubmitting || !!errors.submit}
                  type="submit"
                  variant="contained"
                  sx={{ borderRadius: `${theme.shape.borderRadius}px`, mt: 2 }}
                >
                  Send feedback
                </LoadingButton>
              </Box>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default FeedbackContact;
