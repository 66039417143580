import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { IDrawingLines } from 'src/types/board';

const useLines = (): IDrawingLines => {
  const lines: IDrawingLines = useSelector((state: RootState) => {
    if (state?.boardSlice?.model?.stepLayers && state.boardSlice.model.lines) {
      const { activeStep } = state.boardSlice.model;

      const lineIds = new Set(
        state.boardSlice.model.stepLayers[activeStep]?.lines
      );
      return Object.fromEntries(
        Object.entries(state.boardSlice.model.lines).filter(([, val]) =>
          lineIds.has(val.id)
        )
      );
    }
    return undefined;
  });

  return lines;
};

export default useLines;
