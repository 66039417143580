import { useRef, useEffect } from 'react';

const useBeforeClose = (fn) => {
  const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onClose = (...args) => cb.current?.(...args);

    window.addEventListener('close', onClose);

    return () => window.removeEventListener('close', onClose);
  }, []);
};

export default useBeforeClose;
