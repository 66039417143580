import {
  getAdditionalUserInfo,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup
} from '@firebase/auth';
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider
} from 'firebase/auth';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import SplashScreen from 'src/components/SplashScreen';
import { startUniversalListeners, terminateAllListeners } from 'src/data';
import {
  trackCreateAccount,
  trackSignIn,
  trackSignOut
} from 'src/data/mixpanel/setters/trackEvent';
import { leaveSession } from 'src/data/realtimeDb/setters/session';
import { auth } from 'src/lib/firebase';
import type { User } from 'src/types/user';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  platform: 'Firebase';
  createUserWithEmailAndPassword: (
    name: string,
    email: string,
    password: string,
    subscribedToNewsletter: boolean
  ) => Promise<any>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInWithGoogle: (subscribedToNewsletter?: boolean) => Promise<any>;
  signInWithFacebook: () => Promise<any>;
  logout: (playId: string) => Promise<void>;
  updateUserData: (displayName: string, photoURL: string) => Promise<void>;
  updateEmail: (newEmail: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction = {
  type: 'AUTH_STATE_CHANGED';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type UserStateChangedAction = {
  type: 'USER_STATE_CHANGED';
  payload: {
    user: User | null;
  };
};

type Action = AuthStateChangedAction | UserStateChangedAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'USER_STATE_CHANGED': {
      const { user } = action.payload;

      return {
        ...state,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  platform: 'Firebase',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signInWithFacebook: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  updateUserData: () => Promise.resolve(),
  updateEmail: () => Promise.resolve()
});

// used to avoid calling terminateListeners on initial Load, which throws error when no listener is running
let isInitialLoad = true;

export const AuthProvider: FC<AuthProviderProps> = ({
  children
}: AuthProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const signOutAnonymousUser = async () => {
    if (auth?.currentUser?.isAnonymous) {
      const anonymousId = auth.currentUser.uid;

      await auth.signOut();

      return anonymousId;
    }

    return null;
  };

  const handleSignInWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<any> => {
    const anonymousId = await signOutAnonymousUser();

    return signInWithEmailAndPassword(auth, email, password).then(() =>
      trackSignIn({
        link_anonymous_id: anonymousId
      })
    );
  };

  const handleSignInWithGoogle = async (): Promise<any> => {
    const provider = new GoogleAuthProvider();

    const anonymousId = await signOutAnonymousUser();

    return signInWithPopup(auth, provider).then((userCredential) => {
      if (userCredential.user) {
        if (getAdditionalUserInfo(userCredential).isNewUser) {
          trackCreateAccount({
            has_subscribed_to_newsletter: false,
            link_anonymous_id: anonymousId
          });
        } else {
          trackSignIn({
            link_anonymous_id: anonymousId
          });
        }
      }
    });
  };

  const handleSignInWithFacebook = (): Promise<any> => {
    const provider = new FacebookAuthProvider();
    return signInWithPopup(auth, provider).then(() => trackSignIn({}));
  };

  const handleCreateUserWithEmailAndPassword = async (
    name: string,
    email: string,
    password: string,
    subscribedToNewsletter: boolean
  ): Promise<any> => {
    const anonymousId = await signOutAnonymousUser();

    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    if (userCredential.user) {
      trackCreateAccount({
        has_subscribed_to_newsletter: subscribedToNewsletter,
        link_anonymous_id: anonymousId
      });
    }

    try {
      const response = await updateUserData(name, null);

      console.log('SUCCESS:', response);
    } catch (err) {
      console.error('ERROR: ', err);
    }

    return userCredential;
  };
  const logout = async (playId: string): Promise<void> => {
    const currentUser = await auth.currentUser;
    trackSignOut({});

    // check whether we are in a live session atm and leave it
    if (playId) {
      await leaveSession(playId, currentUser.uid);
    }
    const signOut = await auth.signOut();
    return signOut;
  };
  const updateUserData = async (displayName: string, photoURL: string) => {
    const currentUser = await auth.currentUser;

    dispatch({
      type: 'USER_STATE_CHANGED',
      payload: {
        user: {
          id: currentUser.uid,
          photoURL: photoURL || currentUser.photoURL,
          email: currentUser.email,
          displayName:
            displayName || currentUser.displayName || currentUser.email,
          // verified: currentUser.emailVerified,
          provider: currentUser.providerData.map((obj) => obj.providerId)
        }
      }
    });
  };

  const updateEmail = async (newEmail: string) => {
    const currentUser = await auth.currentUser;

    dispatch({
      type: 'USER_STATE_CHANGED',
      payload: {
        user: {
          id: currentUser.uid,
          photoURL: currentUser.photoURL,
          email: newEmail,
          displayName: currentUser.displayName || currentUser.email,
          // verified: false,
          provider: currentUser.providerData.map((obj) => obj.providerId)
        }
      }
    });
  };

  // start and terminate Firestore Listeners here

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user && !user?.isAnonymous) {
        // Here you should extract the complete user profile to make it available in your entire app.
        // The auth state only provides basic information.

        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: true,
            user: {
              id: user.uid,
              photoURL: user.photoURL,
              email: user.email,
              displayName: user.displayName || user.email,
              // verified: user.emailVerified,
              provider: user.providerData.map((obj) => obj.providerId)
            }
          }
        });
        // start Firestore Listeners
        if (isInitialLoad) {
          isInitialLoad = false;
        }

        startUniversalListeners(user.uid);
      } else {
        // terminate Firestore Listeners
        if (!isInitialLoad) {
          terminateAllListeners();
        }
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    });

    return unsubscribeAuth;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Firebase',
        createUserWithEmailAndPassword: handleCreateUserWithEmailAndPassword,
        signInWithEmailAndPassword: handleSignInWithEmailAndPassword,
        signInWithGoogle: handleSignInWithGoogle,
        signInWithFacebook: handleSignInWithFacebook,
        logout,
        updateUserData,
        updateEmail
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
