import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { usePlaySliceLogs } from 'src/constants';
import { startSessionListener } from 'src/data';
import { getPlay } from 'src/data/firestore/getter/play';
import {
  createSession,
  sessionExists
} from 'src/data/realtimeDb/setters/session';
import type { AppThunk } from 'src/store';
import { IStepLayer, IStepLayers, Maps } from 'src/types/board';
import { Play } from 'src/types/play';

interface PlayState {
  play: Play | null;
}

const initialState: PlayState = {
  play: null
};

const defaultPlay: Play = {
  id: null,
  title: 'Untitled Play',
  photoURL: null,
  map: Maps.BIND,
  updatedAt: Date.now(),
  createdAt: Date.now(),
  referencePlaybookIDs: [],
  whitelistedIDs: [],
  board: {
    stepLayers: {
      0: {
        id: 0,
        objects: [],
        lines: [],
        connections: [],
        commandsHistory: [],
        commandsFuture: []
      } as IStepLayer
    } as IStepLayers,
    lines: {},
    objects: {},
    connections: {},
    map: Maps.SPLIT,
    mapRotation: 0,
    commands: {},
    activeStep: 0 // Split as initial map is randomly chosen, maybe discuss what map makes most sense
  }
};

const slice = createSlice({
  name: 'playSlice',
  initialState,
  reducers: {
    initPlay(state: PlayState) {
      state.play = defaultPlay;
    },
    updatePlay(state: PlayState, action: PayloadAction<{ play: Play }>) {
      const { play } = action.payload;
      state.play = play || null;
    },
    setTitle(state: PlayState, action: PayloadAction<{ title: string }>) {
      const { title } = action.payload;

      state.play.title = title || ' ';
    },
    clearPlay() {
      return initialState;
    }
  }
});

export const { reducer } = slice;

export const updatePlay =
  (playToUpdate: Play, exists: boolean): AppThunk =>
  (dispatch) => {
    if (exists) {
      const response = { play: playToUpdate };
      dispatch(slice.actions.updatePlay(response));
      if (usePlaySliceLogs) {
        console.log('PLAY UPDATE: - ADDED/MODIFIED PLAY:');
        console.log(playToUpdate);
      }
    } else {
      dispatch(slice.actions.clearPlay());
      if (usePlaySliceLogs) {
        console.log('PLAY UPDATE: - CLEARED PLAY');
      }
    }
  };

export const setTitle =
  (title: string): AppThunk =>
  (dispatch) => {
    const response = { title };
    dispatch(slice.actions.setTitle(response));
    if (usePlaySliceLogs) {
      console.log('Play title set!');
    }
  };

export const clearPlay = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.clearPlay());
  if (usePlaySliceLogs) {
    console.log('PLAY CLEARED!');
  }
};
export const initPlay = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.initPlay());
  if (usePlaySliceLogs) {
    console.log('Empty play initialized!');
  }
};

export const loadPlay =
  (playId: string, teamId: string, userId: string): AppThunk =>
  async (dispatch) => {
    try {
      const playDoc = await getPlay(playId);
      if (playDoc.exists()) {
        const play: Play = await playDoc.data();
        const response = { play };
        if (teamId) {
          await loadSession(play, teamId, userId);
        }

        dispatch(slice.actions.updatePlay(response));

        if (usePlaySliceLogs) {
          console.log('PLAY UPDATE: - ADDED/MODIFIED PLAY:');
          console.log(play);
        }
        return true;
      }
      if (teamId) {
        const newPlay: Play = cloneDeep(defaultPlay);
        newPlay.id = playId;
        const exists = await sessionExists(newPlay.id);
        if (exists) {
          dispatch(slice.actions.initPlay());
          startSessionListener(newPlay.id, userId);
          return true;
        }
      }
      if (usePlaySliceLogs) {
        console.log('PLAY UPDATE: - ADDED/MODIFIED PLAY:');
      }
      return false;
    } catch (err) {
      // we catch this, bc checking existence of a non existent doc throws a permission error
      if (teamId) {
        const newPlay: Play = cloneDeep(defaultPlay);
        newPlay.id = playId;
        const exists = await sessionExists(newPlay.id);
        if (exists) {
          dispatch(slice.actions.initPlay());
          startSessionListener(newPlay.id, userId);
          return true;
        }
      }
      if (usePlaySliceLogs) {
        console.log('PLAY UPDATE: - ADDED/MODIFIED PLAY:');
      }
      return false;
    }
  };

const loadSession = async (existingPlay: Play, teamId: string, userId) => {
  try {
    const exists = await sessionExists(existingPlay.id);
    if (exists) {
      startSessionListener(existingPlay.id, userId);
    } else {
      const sessionKey: string = await createSession(teamId, existingPlay);
      // TODO: find out how to correctly await creation or how to wait with session listener until session
      startSessionListener(sessionKey, userId);
    }
  } catch (err) {
    console.error(err);
  }
};

export default slice;
