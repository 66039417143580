import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router';
import CustomDialog from 'src/components/CustomDialog';
import CustomDialogHeader from 'src/components/CustomDialogHeader';
import CustomTooltip from 'src/components/CustomTooltip';
import {
  acceptInvitation,
  declineInvitation
} from 'src/data/firestore/setters/team';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { RootState, useSelector } from 'src/store';
import { TeamInviteUserside } from 'src/types/team';
import CustomAvatar from '../CustomAvatar';

interface PersonalInvitesDialogProps {
  open: boolean;
  setDialogOpen: Function;
}

const PersonalInvitesDialog: FC<PersonalInvitesDialogProps> = (props) => {
  const { open, setDialogOpen } = props;
  const { user } = useAuth();
  const username = useSelector((state: RootState) =>
    state.accountDataSlice.accountData
      ? state.accountDataSlice.accountData.username
      : null
  );

  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const navigate = useNavigate();

  const pendingInvitesUserside: TeamInviteUserside[] = useSelector(
    (state: RootState) =>
      state.accountDataSlice.accountData
        ? state.accountDataSlice.accountData.pendingInvites
        : []
  );

  const [waitingForFunction, setWaitingForFunction] = useState(false);

  const handleAcceptInvite = async (
    teamId: string,
    teamDisplayName: string
  ) => {
    setWaitingForFunction(true);

    try {
      await acceptInvitation(
        username,
        user.id,
        user.email,
        user.displayName,
        user.photoURL,
        teamId,
        teamDisplayName
      );

      enqueueSnackbar(`Joined team ${teamDisplayName}.`, {
        variant: 'success'
      });

      navigate(`/app/team/${teamId}`);

      if (isMountedRef.current) {
        setDialogOpen(false);
        setWaitingForFunction(false);
      }
    } catch (error) {
      enqueueSnackbar(`Failed to accept invite from team ${teamDisplayName}.`, {
        variant: 'error'
      });

      setWaitingForFunction(false);
    }
  };

  const handleDeclineInvite = async (
    teamId: string,
    teamDisplayName: string
  ) => {
    setWaitingForFunction(true);

    try {
      await declineInvitation(username, user.email, user.id, teamId);

      enqueueSnackbar(`Deciled invite from team ${teamDisplayName}.`, {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar(`Failed to decile invite from team ${teamDisplayName}.`, {
        variant: 'error'
      });
    }
    setWaitingForFunction(false);
  };

  const onClose = () => {
    if (!waitingForFunction) {
      setDialogOpen(false);
    }
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setDialogOpen}
      onClose={onClose}
      title="Team invitations"
      width={650}
      buttons={[]}
    >
      <Container
        sx={{
          width: '100%',
          height: '100%',
          maxHeight: '750px'
        }}
      >
        {waitingForFunction ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ height: '300px', mt: 3 }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Box>
            <Grid container spacing={3} sx={{ pb: 3 }}>
              <Grid item xs={12}>
                {pendingInvitesUserside.length > 0 ? (
                  <List sx={{ height: '100%', mt: 2 }}>
                    {pendingInvitesUserside.map((invite) => (
                      <ListItem
                        key={`${invite.teamID}`}
                        sx={{ pt: 1, p: '0px !important' }}
                      >
                        <ListItemAvatar>
                          <CustomAvatar
                            src={invite.inviterPhotoURL}
                            alt={invite.inviterDisplayName.charAt(0)}
                            isTeamAvatar={false}
                          />
                        </ListItemAvatar>

                        <ListItemText
                          primary={
                            <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Typography fontStyle="italic">
                                {invite.inviterDisplayName}
                              </Typography>
                              <Typography>
                                &nbsp; invited you to join &nbsp;
                              </Typography>
                              <Typography fontStyle="italic">
                                {invite.teamDisplayName}
                              </Typography>
                            </Grid>
                          }
                          secondary={`${moment(invite.createdAt).fromNow()}`}
                        />

                        <ListItemSecondaryAction sx={{ right: 4 }}>
                          <CustomTooltip title="Accept" placement="bottom">
                            <IconButton
                              sx={{
                                mr: 1
                              }}
                              disabled={waitingForFunction}
                              onClick={() =>
                                handleAcceptInvite(
                                  invite.teamID,
                                  invite.teamDisplayName
                                )
                              }
                              size="small"
                              color="success"
                            >
                              <CheckIcon sx={{ width: '75%' }} />
                            </IconButton>
                          </CustomTooltip>
                          <CustomTooltip title="Decline" placement="bottom">
                            <IconButton
                              edge="end"
                              size="small"
                              color="error"
                              disabled={waitingForFunction}
                              onClick={() =>
                                handleDeclineInvite(
                                  invite.teamID,
                                  invite.teamDisplayName
                                )
                              }
                            >
                              <ClearIcon sx={{ width: '75%' }} />
                            </IconButton>
                          </CustomTooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item sx={{ py: 6 }}>
                      <Typography variant="body1" color="textSecondary">
                        {' '}
                        There are currently no team invitations pending...{' '}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
    </CustomDialog>
  );
};

PersonalInvitesDialog.propTypes = {
  open: PropTypes.bool,
  setDialogOpen: PropTypes.func
};

export default PersonalInvitesDialog;
