import { MouseEvent, TouchEvent } from 'react';
import { appTopbarHeight } from 'src/constants';
import { IPoint } from 'src/types/board';
import rotateCoordsAroundPoint from './rotateCoordsAroundPoint';

/*
function rotate(cx, cy, x, y, angle) {
  const radians = (Math.PI / 180) * angle,
      cos = Math.cos(radians),
      sin = Math.sin(radians),
      nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
      ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
  return [nx, ny];
}
*/

const relativeCoordinatesForMouseEvent = (
  mouseEvent: MouseEvent,
  posX: number,
  posY: number,
  scale: number,
  mapRotation: number
): IPoint => {
  if (posX && posY && scale && mouseEvent) {
    const point: IPoint = {
      x: (mouseEvent.clientX - posX) / scale,
      y: (mouseEvent.clientY - posY - appTopbarHeight) / scale
    };

    const rotatedCoordinates = rotateCoordsAroundPoint(
      point.x,
      point.y,
      2000,
      2000,
      360 - mapRotation
    );

    return {
      x: rotatedCoordinates[0],
      y: rotatedCoordinates[1]
    };
  }
  return undefined;
};

const relativeCoordinatesForTouchEvent = (
  mouseEvent: TouchEvent,
  posX: number,
  posY: number,
  scale: number,
  mapRotation: number
): IPoint => {
  if (posX && posY && scale && mouseEvent) {
    const point: IPoint = {
      // @ts-ignore
      x: (mouseEvent.touches[0]?.clientX - posX) / scale,
      // @ts-ignore
      y: (mouseEvent.touches[0]?.clientY - posY - appTopbarHeight) / scale
    };

    const rotatedCoordinates = rotateCoordsAroundPoint(
      point.x,
      point.y,
      2000,
      2000,
      360 - mapRotation
    );

    return {
      x: rotatedCoordinates[0],
      y: rotatedCoordinates[1]
    };
  }
  return undefined;
};

const relativeCoordinatesForPoint = (
  point: IPoint,
  posX: number,
  posY: number,
  scale: number,
  mapRotation: number
): IPoint => {
  if (posX && posY && scale && point) {
    const newPoint: IPoint = {
      // @ts-ignore
      x: (point.x - posX) / scale,
      // @ts-ignore
      y: (point.y - posY - appTopbarHeight) / scale
    };

    const rotatedCoordinates = rotateCoordsAroundPoint(
      newPoint.x,
      newPoint.y,
      2000,
      2000,
      360 - mapRotation
    );

    return {
      x: rotatedCoordinates[0],
      y: rotatedCoordinates[1]
    };
  }
  return undefined;
};

export {
  relativeCoordinatesForMouseEvent,
  relativeCoordinatesForTouchEvent,
  relativeCoordinatesForPoint
};
