import {
  Box,
  Container,
  DialogContent,
  Grid,
  Typography,
  Button,
  useTheme
} from '@mui/material';
import { useEffect } from 'react';
import DiscordIcon from 'src/icons/DiscordIcon';
import { discordLink } from 'src/constants';
import CustomDialogHeader from 'src/components/CustomDialogHeader';

interface IProps {
  onClose: Function;
}

const ContactDialogContent = (props: IProps) => {
  const { onClose } = props;
  const theme = useTheme();

  return (
    <DialogContent sx={{ height: 'auto', maxHeight: '750px' }}>
      <Container
        sx={{
          width: '100%',
          height: '100%'
        }}
      >
        <Box sx={{ mt: 3 }}>
          <Typography variant="body2" color="text.secondary">
            You can reach us via this email address:
          </Typography>
          <Typography
            sx={{ mt: 1, color: `${theme.palette.text.primary} !important` }}
            variant="subtitle2"
            color="text.secondary"
          >
            dev@critbase.com
          </Typography>
          <Typography sx={{ mt: 1 }} variant="body2" color="text.secondary">
            You can also message us on our Discord server.
          </Typography>
          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            xs={12}
            sx={{ pt: 2 }}
          >
            <Button
              variant="contained"
              href={discordLink}
              target="_blank"
              endIcon={<DiscordIcon />}
              sx={{ mt: 0 }}
            >
              Join Discord
            </Button>
          </Grid>
        </Box>
      </Container>
    </DialogContent>
  );
};

export default ContactDialogContent;
