import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Button,
  Menu,
  MenuItem,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import React, { FC, ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomTooltip from 'src/components/CustomTooltip';
import { DEFAULT_TEXTAREA_FONT_SIZE } from 'src/constants';
import { addCommand } from 'src/slices/board/boardModel';
import store from 'src/store';
import {
  ActionTypes,
  DragTypes,
  ICommand,
  ICommandParams,
  IObject
} from 'src/types/board';
import createResourceId from 'src/utils/createResourceId';

interface IProps {
  object: IObject;
}

const FONT_SIZE_OPTIONS: number[] = [8, 10, 14, 18];

const fontSizeToFontSizeLabel = (fontSize: number) => {
  if (fontSize === 8) {
    return 'S';
  }

  if (fontSize === 10) {
    return 'M';
  }

  if (fontSize === 14) {
    return 'L';
  }

  if (fontSize === 18) {
    return 'XL';
  }

  return '?';
};

const FontSizePicker: FC<IProps> = (props: IProps): ReactElement => {
  const { object } = props;
  const { playId, teamId } = useParams();
  const theme: Theme = useTheme();
  const sessionId = teamId ? playId : null;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [fontSize, setFontSize] = useState<number>(
    object?.specs?.[DragTypes.TEXTAREA]?.fontSize || DEFAULT_TEXTAREA_FONT_SIZE
  );

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleItemClick = (newFontSize: number) => () => {
    if (newFontSize) {
      setFontSize(newFontSize);

      const newObj: IObject = {
        ...object,
        specs: {
          [DragTypes.TEXTAREA]: {
            ...object.specs?.[DragTypes.TEXTAREA],
            fontSize: newFontSize
          }
        }
      };

      const oldObj: IObject = {
        ...object
      };
      const command: ICommand = {
        id: createResourceId(),
        type: ActionTypes.CHANGE_OBJECT,
        params: {
          value: {
            newObj,
            oldObj
          },
          stepLayer: store.getState().boardSlice.model.activeStep,
          sessionId,
          playId
        } as ICommandParams,
        inverse: ActionTypes.REVERT_OBJECT
      };
      store.dispatch(addCommand(command));
    }
    setAnchorEl(null);
  };

  const renderMenuItems = (options: number[]) => {
    const items = [];
    options.forEach((value) => {
      items.push(
        <MenuItem
          key={value}
          onClick={handleItemClick(value)}
          selected={value === fontSize}
        >
          {fontSizeToFontSizeLabel(value)}
        </MenuItem>
      );
    });

    return items;
  };

  const buttonSx = {
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    border: 0,
    height: 40,
    width: 40
  };
  return (
    <div className="cb-font-size-picker">
      <CustomTooltip title="Font size" placement="top" enterDelay={500}>
        <Button size="large" variant="text" sx={buttonSx} onClick={handleClick}>
          <Typography
            variant="body2"
            sx={{ pl: 0.5, pr: 0.25, fontWeight: 600 }}
          >
            {fontSizeToFontSizeLabel(fontSize)}
          </Typography>
          <KeyboardArrowDownIcon />
        </Button>
      </CustomTooltip>

      <Menu
        PaperProps={{
          sx: {
            mt: `-${theme.spacing(1)}`,
            boxShadow: theme.shadows[19]
          }
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {renderMenuItems(FONT_SIZE_OPTIONS)}
      </Menu>
    </div>
  );
};

export default FontSizePicker;
