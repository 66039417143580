import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormHelperText,
  Grid,
  TextField
} from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { updatePlaybook } from 'src/data/firestore/setters/playbook';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { RootState } from 'src/store';
import { Playbook, PlaybookInfo } from 'src/types/play';
import { Team } from 'src/types/team';
import { AccountData } from 'src/types/user';
import * as Yup from 'yup';

const dataChanged = (
  initalTitle,
  currentTitle,
  initialDescription,
  currentDescription
) => {
  if (
    initalTitle === currentTitle &&
    initialDescription === currentDescription
  ) {
    return false;
  }

  return true;
};

interface IProps {
  waitingForPlaybookSettingsChange: boolean;
  setWaitingForPlaybookSettingsChange: Function;
  setDialogOpen: Function;
  playbook: PlaybookInfo;
}

const PlaybookGeneralSettings: FC<IProps> = (props: IProps) => {
  const {
    waitingForPlaybookSettingsChange,
    setWaitingForPlaybookSettingsChange,
    setDialogOpen,
    playbook
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();

  const fullPlaybook: Playbook = useSelector(
    (state: RootState) => state.playbookSlice.playbook
  );

  const { teamId } = useParams();
  const team: Team = useSelector((state: RootState) => state.teamSlice.team);
  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );
  const spaceTier = teamId ? team.spaceTier : accountData.spaceTier;

  // only for cleanup
  useEffect(() => () => null, []);

  if (!playbook) {
    return null;
  }

  return (
    <>
      {waitingForPlaybookSettingsChange ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '300px' }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <Formik
              enableReinitialize
              initialValues={{
                title: playbook.title,
                description: playbook.description || '',
                submit: null
              }}
              validationSchema={Yup.object().shape({
                title: Yup.string()
                  .min(3, 'Must be at least 3 characters long')
                  .max(30, 'Cannot be longer than 30 characters')
                  .required('Required'),
                description: Yup.string().min(
                  10,
                  'Must be at least 10 characters long'
                )
              })}
              onSubmit={async (
                values,
                { resetForm, setErrors, setStatus, setSubmitting }
              ): Promise<void> => {
                try {
                  setWaitingForPlaybookSettingsChange(true);

                  const newPlaybook: Playbook = {
                    id: fullPlaybook.id,
                    title: values.title,
                    photoURL: fullPlaybook.photoURL,
                    updatedAt: Date.now(),
                    createdAt: fullPlaybook.createdAt,
                    description: values.description,
                    numberOfPlays: fullPlaybook.numberOfPlays,
                    thumbnail: fullPlaybook.thumbnail,
                    playsInfo: fullPlaybook.playsInfo,
                    whitelistedIDs: fullPlaybook.whitelistedIDs
                  };

                  // update playbook information
                  if (teamId) {
                    await updatePlaybook(newPlaybook, true, teamId);
                  } else {
                    await updatePlaybook(newPlaybook, true);
                  }

                  enqueueSnackbar('Playbook information updated', {
                    variant: 'success'
                  });
                  setWaitingForPlaybookSettingsChange(false);
                  setDialogOpen(false);

                  if (isMountedRef.current) {
                    resetForm();
                    setStatus({ success: true });
                    setSubmitting(false);
                  }
                } catch (err) {
                  console.error(err);

                  enqueueSnackbar('Updating playbook information failed', {
                    variant: 'error'
                  });

                  if (isMountedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setWaitingForPlaybookSettingsChange(false);
                    setSubmitting(false);
                  }
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }): JSX.Element => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <CardContent>
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(touched.title && errors.title)}
                            fullWidth
                            helperText={
                              (touched.title && errors.title) ||
                              'Enter the new title for the playbook '
                            }
                            label="Title"
                            name="title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(
                              touched.description && errors.description
                            )}
                            fullWidth
                            helperText={
                              (touched.description && errors.description) ||
                              'Enter the new description for the playbook '
                            }
                            label="Description"
                            name="description"
                            rows={4}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.description}
                            variant="outlined"
                            multiline
                          />
                        </Grid>
                      </Grid>
                      {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                      )}
                    </CardContent>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={
                          isSubmitting ||
                          !dataChanged(
                            playbook.title,
                            values.title,
                            playbook.description,
                            values.description
                          )
                        }
                        type="submit"
                        variant="contained"
                      >
                        Save Changes
                      </Button>
                    </Box>
                  </Card>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PlaybookGeneralSettings;
