/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneDeep } from 'lodash';
import {
  IBoard,
  IConnection,
  IConnections,
  IDrawingLines,
  INode,
  IObject,
  IObjects
} from 'src/types/board';
import createResourceId from 'src/utils/createResourceId';

const updateConnections = (
  node: INode,
  connections: IConnections,
  connectionIds: string[]
): IConnections => {
  if (connectionIds && connectionIds.length > 0 && connections) {
    connectionIds.forEach((id) => {
      const connection: IConnection = connections[id];
      if (connection) {
        if (node.id === connection.startNode.id) {
          connections[id].startNode = node;
        } else if (node.id === connection.endNode.id) {
          connections[id].endNode = node;
        }
      }
      // else do nothing
    });
  }

  return connections;
};

const removeConnections = (
  connections: IConnections,
  connectionIds: string[]
): IConnections => {
  if (connectionIds && connectionIds.length > 0 && connections) {
    connectionIds.forEach((id) => {
      if (connections[id]) {
        delete connections[id];
      }
    });
  }
  return connections;
};

const removeObject = (id: string, objects: IObjects): IObjects => {
  const { [id]: foo, ...otherObjects } = objects;
  return otherObjects;
};

const removeConnectionsFromObjects = (
  objects: IObjects,
  connectionIds: string[]
): IObjects => {
  if (connectionIds && connectionIds.length > 0 && objects) {
    connectionIds.forEach((id) => {
      Object.entries(objects).forEach(([, object]) => {
        if (object?.connections) {
          object.connections = object.connections.filter(
            (connectionId) => connectionId !== id
          );
        }
      });
    });
  }

  return objects;
};

const removeConnectionsFromStepLayer = (
  stepLayerConnections: string[],
  connectionIds: string[]
): string[] => {
  if (connectionIds && connectionIds.length > 0) {
    const toDelete = new Set(connectionIds);
    const newArray = stepLayerConnections?.filter(
      (string) => !toDelete.has(string)
    );
    return newArray;
  }
  return stepLayerConnections || [];
};

const addConnections = (
  connections: IConnections,
  objects: IObjects,
  newConnections: IConnections
): { connections: IConnections; objects: IObjects } => {
  let clonedConnections: IConnections = cloneDeep(connections);
  const clonedObjects: IObjects = cloneDeep(objects);

  Object.entries(newConnections).forEach(([, connection]) => {
    const { startNode, endNode }: IConnection = connection;

    if (
      startNode &&
      endNode &&
      clonedObjects[startNode.id] &&
      clonedObjects[endNode.id]
    ) {
      // add connection
      clonedConnections = createConnection(clonedConnections, connection);
      // add connection to startNode
      if (!clonedObjects[startNode.id].connections) {
        clonedObjects[startNode.id].connections = [connection.id];
      } else {
        clonedObjects[startNode.id].connections = [
          ...clonedObjects[startNode.id]?.connections,
          connection.id
        ];
      }
      // add connection to endNode
      if (!clonedObjects[endNode.id].connections) {
        clonedObjects[endNode.id].connections = [connection.id];
      } else {
        clonedObjects[endNode.id].connections = [
          ...clonedObjects[endNode.id]?.connections,
          connection.id
        ];
      }
    }
  });

  return {
    connections: clonedConnections,
    objects: clonedObjects
  };
};

const addConnectionsToStepLayer = (
  stepLayerConnections: string[],
  newConnections: IConnections
): string[] => {
  const connectionIds = Object.entries(newConnections).map(([, con]) => con.id);
  if (!stepLayerConnections && !connectionIds) {
    return [];
  }
  if (!stepLayerConnections) {
    return [...connectionIds];
  }
  return [...stepLayerConnections, ...connectionIds];
};

const createConnection = (
  connections: IConnections,
  newConnection: IConnection
): IConnections => {
  if (!connections) {
    connections = {}; // init connections when its still null
  }
  // add connections
  connections[newConnection.id] = newConnection;

  return connections;
};

const createObject = (value: IObject, objects: IObjects): IObjects => {
  if (!objects) {
    objects = {}; // init lines when its still null
  }
  objects[value.id] = value;
  return objects;
};

// clone elements and give them new ids
const cloneStepLayer = (
  model: IBoard,
  activeStep: number,
  nextStep: number
): IBoard => {
  const clonedStepLayer = cloneDeep(model.stepLayers[activeStep]);
  const {
    lines: lineIds,
    objects: objectIds,
    connections: connectionIds
  } = clonedStepLayer;

  // get all elements that need to be duplicated
  const filteredLines: IDrawingLines = filterObject(model.lines, lineIds);
  const filteredObjects: IObjects = filterObject(model.objects, objectIds);
  const filteredConnections: IConnections = filterObject(
    model.connections,
    connectionIds
  );

  // generate new elements
  const duplicatedLines: IDrawingLines = generateNewIds(filteredLines);
  // track ids for objects to update possible connection references
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const objectResult: any = generateNewIds(filteredObjects, true);
  let duplicatedObjects: IObjects = objectResult.newObject;
  // const duplicatedConnections: IConnections =
  //   generateNewIds(filteredConnections);

  const { newConnections, newObjects } = generateNewConnections(
    filteredConnections,
    objectResult.trackedIds,
    duplicatedObjects
  );
  const duplicatedConnections = newConnections;
  duplicatedObjects = newObjects;
  // add duplicated elements to model
  model.lines = { ...model.lines, ...duplicatedLines };
  model.objects = { ...model.objects, ...duplicatedObjects };
  model.connections = { ...model.connections, ...duplicatedConnections };

  // add new id arrays to stepLayer
  model.stepLayers[nextStep] = {
    id: nextStep,
    lines: getObjectIdsAsArray(duplicatedLines),
    objects: getObjectIdsAsArray(duplicatedObjects),
    connections: getObjectIdsAsArray(duplicatedConnections),
    commandsFuture: [],
    commandsHistory: []
  };

  return model;
};

const filterObject = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  object: any,
  filterSet: string[],
  remove = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  const filteredObject = object || {};
  const objectIds = new Set(filterSet);

  return Object.fromEntries(
    Object.entries(filteredObject).filter(([key]) =>
      remove ? !objectIds.has(key) : objectIds.has(key)
    )
  );
};

const getObjectIdsAsArray = (object: IObjects | IConnections | IDrawingLines) =>
  Object.entries(object).map(([, con]) => con.id);

const generateNewIds = (
  object: IObjects | IConnections | IDrawingLines,
  trackIds = false
) => {
  const newObject = {};
  const trackedIds = {};

  Object.entries(cloneDeep(object)).forEach(([key, value]) => {
    const newId = createResourceId();
    newObject[newId] = { ...value };
    newObject[newId].id = newId;
    if (trackIds) {
      newObject[newId].connections = []; // will be set afterwards
      trackedIds[key] = { old: key, new: newId };
    }
  });
  if (trackIds) {
    return { newObject, trackedIds };
  }

  return newObject;
};

const generateNewConnections = (
  filteredConnections: IConnections,
  trackedIds,
  duplicatedObjects: IObjects
) => {
  // manually update connections TODO:
  let newConnections = {};
  const clonedConnections = cloneDeep(filteredConnections);
  const clonedObjects = cloneDeep(duplicatedObjects);
  Object.entries(clonedConnections).forEach(([, value]) => {
    const newId = createResourceId();
    const newConnection = {
      ...value,
      id: newId
    };
    newConnections = createConnection(newConnections, newConnection);
    const { startNode, endNode } = newConnection;

    // get new object Id with helper list of updated objects
    // for start Node
    // if connected to an object
    if (trackedIds[startNode.id]) {
      const newStartNodeId = trackedIds[startNode.id].new;

      clonedObjects[newStartNodeId].connections = [
        ...clonedObjects[newStartNodeId].connections,
        newId
      ];

      // update start node
      newConnections[newId].startNode.id = newStartNodeId;
    }
    // for end Node
    // if connected to an object
    if (trackedIds[endNode.id]) {
      const newEndNodeId = trackedIds[endNode.id].new;
      clonedObjects[newEndNodeId].connections = [
        ...clonedObjects[newEndNodeId].connections,
        newId
      ];
      // update end node
      newConnections[newId].endNode.id = newEndNodeId;
    }
  });

  return { newConnections, newObjects: clonedObjects };
};

export {
  updateConnections,
  removeConnections,
  removeObject,
  removeConnectionsFromObjects,
  removeConnectionsFromStepLayer,
  addConnections,
  addConnectionsToStepLayer,
  createObject,
  cloneStepLayer,
  filterObject,
  createConnection
};
