import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { useAnnouncementSliceLogs } from 'src/constants';
import type { AppThunk } from 'src/store';
import { Announcement } from 'src/types/announcement';

interface AnnouncementsState {
  announcements: Announcement[];
}

const initialState: AnnouncementsState = {
  announcements: []
};

const slice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {
    updateAnnouncements(
      state: AnnouncementsState,
      action: PayloadAction<{ announcements: Announcement[] }>
    ) {
      const { announcements } = action.payload;
      state.announcements = announcements;
    },
    clearAnnouncements(state: AnnouncementsState) {
      state.announcements = initialState.announcements;
    }
  }
});

export const { reducer } = slice;

export const updateAnnouncements =
  (announcementsUpdated: Announcement[]): AppThunk =>
  (dispatch) => {
    if (useAnnouncementSliceLogs) {
      console.log('ANNOUNCEMENTS UPDATE: ');
      console.log(announcementsUpdated);
    }
    const response = { announcements: announcementsUpdated };
    dispatch(slice.actions.updateAnnouncements(response));
  };

export const clearAnnouncements = (): AppThunk => (dispatch) => {
  if (useAnnouncementSliceLogs) {
    console.log('ANNOUNCEMENTS CLEARED!');
  }
  dispatch(slice.actions.clearAnnouncements());
};

export default slice;
