import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import {
  Box,
  ButtonGroup,
  Fade,
  Paper,
  Popper,
  styled,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  useTheme
} from '@mui/material';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomTooltip from 'src/components/CustomTooltip';
import { customOpacityDarkThemeBoard } from 'src/constants';
import { addCommand } from 'src/slices/board/boardModel';
import store from 'src/store';
import {
  ActionTypes,
  DragTypes,
  ICommand,
  ICommandParams,
  IObject,
  TextOptions
} from 'src/types/board';
import createResourceId from 'src/utils/createResourceId';
import hexToRGB from 'src/utils/hexToRGB';
import FontColorPicker from './FontColorPicker';
import FontSizePicker from './FontSizePicker';

interface IProps {
  object: IObject;
  anchorEl: any;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    border: 0,
    '&.Mui-disabled': {
      border: 0
    },
    '&:not(:first-of-type)': {
      borderRadius: `${theme.shape.borderRadius}px`
    },
    '&:first-of-type': {
      borderRadius: `${theme.shape.borderRadius}px`
    }
  }
}));

const TextMenu: FC<IProps> = (props: IProps): ReactElement => {
  const { object, anchorEl } = props;
  const { playId, teamId } = useParams();
  const theme: Theme = useTheme();
  const open = Boolean(anchorEl);
  const sessionId = teamId ? playId : null;
  const [fontFormats, setFontFormats] = useState<TextOptions[]>(
    object?.specs?.[DragTypes.TEXTAREA]?.fontFormat || []
  );

  useEffect(() => {
    setFontFormats(object?.specs?.[DragTypes.TEXTAREA]?.fontFormat);
  }, [object?.specs?.[DragTypes.TEXTAREA]?.fontFormat]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: TextOptions[]
  ) => {
    if (newFormats) {
      setFontFormats(newFormats);

      const newObj: IObject = {
        ...object,
        specs: {
          [DragTypes.TEXTAREA]: {
            ...object.specs?.[DragTypes.TEXTAREA],
            fontFormat: newFormats
          }
        }
      };

      const oldObj: IObject = {
        ...object
      };
      const command: ICommand = {
        id: createResourceId(),
        type: ActionTypes.CHANGE_OBJECT,
        params: {
          value: {
            newObj,
            oldObj
          },
          stepLayer: store.getState().boardSlice.model.activeStep,
          sessionId,
          playId
        } as ICommandParams,
        inverse: ActionTypes.REVERT_OBJECT
      };
      store.dispatch(addCommand(command));
    }
  };

  const buttonSx = {
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    border: 0
  };
  return (
    <Box className={`cb-text-menu-${object.id} cb-textarea`}>
      <Popper open={open} anchorEl={anchorEl} placement="top" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={100}>
            <Paper
              sx={{
                marginBottom: 2,
                backgroundColor: theme.palette.background.default
              }}
            >
              <Paper
                sx={{
                  backgroundColor: hexToRGB(
                    theme.palette.primary.main,
                    customOpacityDarkThemeBoard.toString()
                  ),
                  boxShadow: theme.shadows[19]
                }}
              >
                <StyledToggleButtonGroup
                  value={fontFormats}
                  onChange={handleChange}
                  style={{ justifyContent: 'space-around' }}
                >
                  <ToggleButton
                    value={TextOptions.BOLD}
                    component="div"
                    id="text-bold-button"
                    sx={buttonSx}
                  >
                    <CustomTooltip
                      title="Bold"
                      placement="top"
                      enterDelay={500}
                    >
                      <FormatBoldIcon
                        sx={{ color: theme.palette.text.secondary }}
                      />
                    </CustomTooltip>
                  </ToggleButton>

                  <ToggleButton value={TextOptions.ITALIC} sx={buttonSx}>
                    <CustomTooltip
                      title="Italic"
                      placement="top"
                      enterDelay={500}
                    >
                      <FormatItalicIcon
                        sx={{ color: theme.palette.text.secondary }}
                      />
                    </CustomTooltip>
                  </ToggleButton>
                  <ToggleButton value={TextOptions.UNDERLINE} sx={buttonSx}>
                    <CustomTooltip
                      title="Underline"
                      placement="top"
                      enterDelay={500}
                    >
                      <FormatUnderlinedIcon
                        sx={{ color: theme.palette.text.secondary }}
                      />
                    </CustomTooltip>
                  </ToggleButton>
                  <ToggleButton value={TextOptions.STRIKETHROUGH} sx={buttonSx}>
                    <CustomTooltip
                      title="Strikethrough"
                      placement="top"
                      enterDelay={500}
                    >
                      <FormatClearIcon
                        sx={{ color: theme.palette.text.secondary }}
                      />
                    </CustomTooltip>
                  </ToggleButton>
                </StyledToggleButtonGroup>
                <ButtonGroup sx={{ verticalAlign: 'top' }}>
                  <FontColorPicker object={object} />
                  <FontSizePicker object={object} />
                </ButtonGroup>
              </Paper>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

export default TextMenu;
