import { Grid, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { FC } from 'react';
import PlaybookPreviewBox from 'src/components/app/playbooks/PlaybookPreviewBox';
import { PlaybookInfo } from 'src/types/play';
import sortPlaybooks from 'src/utils/sortPlaybooks';

interface IProps {
  maxNumberOfPlaybooks: number;
  playbookInfos: PlaybookInfo[];
}
const PlaybooksPreviewList: FC<IProps> = (props: IProps) => {
  const theme = useTheme();
  const { playbookInfos, maxNumberOfPlaybooks } = props;
  const spacing = 12;

  const numberOfRenderedPlaybooks = () => {
    if (playbookInfos) {
      if (playbookInfos.length > maxNumberOfPlaybooks) {
        return maxNumberOfPlaybooks;
      }
      return playbookInfos.length;
    }
    return 0;
  };

  return (
    <>
      {playbookInfos?.length === 0 ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%', height: 180 }}
        >
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.secondary }}
          >
            There are currently no playbooks here.{' '}
          </Typography>
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{
            width: `calc( (100% / ${maxNumberOfPlaybooks}) * (${numberOfRenderedPlaybooks()}))`,
            height: '100%'
          }}
        >
          {sortPlaybooks(playbookInfos, 'editedDesc')?.map(
            (playbook, i) =>
              (i < maxNumberOfPlaybooks || !maxNumberOfPlaybooks) && (
                <Grid
                  key={playbook.id}
                  item
                  sx={{
                    width: `calc(${
                      100 / numberOfRenderedPlaybooks()
                    }% - ${spacing}px)`
                  }}
                >
                  <PlaybookPreviewBox
                    playbook={playbook}
                    selectionEnabled={false}
                    selected={false}
                  />
                </Grid>
              )
          )}
        </Grid>
      )}
    </>
  );
};

PlaybooksPreviewList.propTypes = {
  maxNumberOfPlaybooks: PropTypes.number,
  playbookInfos: PropTypes.any
};

export default PlaybooksPreviewList;
