import type { FC } from 'react';
import type { Theme } from '@mui/material';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import type { SxProps } from '@mui/system';

import { OBJECT_SIZE } from 'src/constants';

interface IProps {
  sx?: SxProps<Theme>;
}

const SkullIcon: FC<IProps> = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    sx={{
      color: '#E5E7EB',
      width: OBJECT_SIZE - 10,
      height: OBJECT_SIZE - 10,
      opacity: 0.9
    }}
  >
    <svg
      version="1.2"
      baseProfile="tiny-ps"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        id="Path 0"
        fillRule="evenodd"
        d="m243 69l35 1l28 7l27 15l17 19l10 23l3 17l-1 36l-8 54l13 16l-7 28l-5 5l-35 16l-6 8l-2 16l-3 3l-21 6l-52 1l-29-5l-7-5l-1-13l-7-11l-35-16l-5-5l-7-28l13-17l-9-63v-27l8-30l10-16l22-19l26-11l28-5zm-55 125l-10 15l-4 32l5 9l19 3l24-3l11-10l6-19l-2-15l-8-9l-15-6l-15-1l-11 4zm100 0l-9 6l-5 9l-1 13l6 18l14 11l27 2l13-3l5-9l-4-32l-10-15l-18-4l-18 4zm-49 67l-5 18l12 1l11-5l9 5l12-1l-8-24l-8-13l-8-4l-15 23zm-139-87l14 3l11 9l6 15l-5 20l17 8l1 9l-8 7l-5 11l6 24l-32-14l-4 9l-9 8l-20 4l-13-5l-10-11l-3-9l1-15l6-11l11-8l14-2l-8-15v-12l5-12l10-9l15-4zm311 0l16 4l10 9l5 12v11l-8 16l11 1l11 6l10 18v11l-5 12l-17 12l-12 1l-14-5l-11-16l-32 14l6-24l-5-11l-8-7l1-9l17-8l-4-7l-1-14l6-14l11-9l13-3zm-230 142l4 3l4 19l6 6l25 9l-94 43l5 12l-2 16l-11 14l-8 4h-19l-14-9l-6-11l-1-15l8-16h-9l-11-5l-7-7l-5-12v-12l4-10l16-13l17-1l10 4l13 17l75-36zm149 0l76 36l13-17l10-4l17 1l16 13l4 21l-5 13l-7 7l-11 5h-9l8 16l-1 15l-6 11l-18 10l-18-2l-13-11l-4-9l-1-13l5-12l-94-43l25-9l8-10l1-12l4-6z"
      />
    </svg>
  </SvgIcon>
);

export default SkullIcon;
