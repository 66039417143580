const rotateCoordsAroundPoint = (
  x: number,
  y: number,
  centerx: number,
  centery: number,
  degrees: number
): [x: number, y: number] => {
  const newx =
    (x - centerx) * Math.cos((degrees * Math.PI) / 180) -
    (y - centery) * Math.sin((degrees * Math.PI) / 180) +
    centerx;
  const newy =
    (x - centerx) * Math.sin((degrees * Math.PI) / 180) +
    (y - centery) * Math.cos((degrees * Math.PI) / 180) +
    centery;
  return [newx, newy];
};

export default rotateCoordsAroundPoint;
