import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { leaveTeam } from 'src/data/firestore/setters/team';

interface LeaveTeamDialogProps {
  open: boolean;
  setOpen: Function;
  teamID: string;
  teamDisplayName: string;
}

const LeaveTeamDialog: FC<LeaveTeamDialogProps> = (props) => {
  const { open, setOpen, teamID, teamDisplayName } = props;
  const theme = useTheme();
  const [waitingForFunction, setWaitingForFunction] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleLeaveTeam = async () => {
    try {
      setWaitingForFunction(true);

      await leaveTeam(teamID);

      navigate(`/app/personal`);

      setWaitingForFunction(false);

      enqueueSnackbar(`Left team '${teamDisplayName}'`, {
        variant: 'success'
      });

      setOpen(false);
    } catch (err) {
      console.error(err);

      setWaitingForFunction(false);

      enqueueSnackbar(`Leaving team '${teamDisplayName} failed'`, {
        variant: 'error'
      });

      setOpen(false);
    }
  };

  // cleanup on unmount
  useEffect(() => {
    const returnVal = null;
    return () => returnVal;
  }, []);

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        if (!waitingForFunction) {
          setOpen(false);
        }
      }}
    >
      {waitingForFunction ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '200px', width: '100%' }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <DialogContent sx={{ height: 'auto', maxHeight: '750px' }}>
          <Container
            sx={{
              pt: 3
            }}
          >
            <Typography
              color="textPrimary"
              component="span"
              gutterBottom
              variant="body1"
              sx={{ pb: 1, fontWeight: 500 }}
            >
              You are about to leave team
              <Box fontStyle="italic" display="inline">
                {' '}
                {teamDisplayName}
              </Box>
              . Are you sure you want to continue ?
            </Typography>

            <Grid container sx={{ pt: 3, pb: 3 }} spacing={2}>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => setOpen(false)}
                  variant="text"
                  sx={{ width: '100%' }}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  onClick={() => handleLeaveTeam()}
                  variant="contained"
                  sx={{
                    width: '100%',

                    backgroundColor: `${theme.palette.error.main}`,
                    '&:hover': {
                      backgroundColor: `${theme.palette.error.dark}`
                    }
                  }}
                >
                  Leave
                </Button>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
      )}
    </Dialog>
  );
};

LeaveTeamDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  teamID: PropTypes.string,
  teamDisplayName: PropTypes.string
};

export default LeaveTeamDialog;
