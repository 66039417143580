import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import {
  addStepLayer,
  changeMap as changeMapDb,
  clearBoard as clearBoardDb,
  rotateMap as rotateMapDb,
  removeStepLayer
} from 'src/data/realtimeDb/setters/session';
import type { AppThunk } from 'src/store';
import {
  IBoard,
  ICommand,
  INode,
  IObject,
  IStepLayer,
  IStepLayers,
  Maps
} from 'src/types/board';
import rotateMapBy from 'src/utils/boardUtils/rotateMapBy';
import { shiftArray } from 'src/utils/shiftArray';
import CommandController from './CommandController';
import executeCommandInDb from './DbCommandController';
import { cloneStepLayer, filterObject, updateConnections } from './utils';

const MAX_HISTORY_FUTURE_LENGTH = 30;

const controller = CommandController();

const initialState: IBoard = {
  stepLayers: {
    0: {
      id: 0,
      objects: [],
      lines: [],
      connections: [],
      commandsHistory: [],
      commandsFuture: []
    } as IStepLayer
  } as IStepLayers,
  lines: {},
  objects: {},
  connections: {},
  map: Maps.SPLIT,
  mapRotation: 0,
  commands: {},
  activeStep: 0 // Split as initial map is randomly chosen, maybe discuss what map makes most sense
};

const slice = createSlice({
  name: 'commands',
  initialState,
  reducers: {
    initBoard(state: IBoard, action: PayloadAction<{ board: IBoard }>) {
      const { board } = action.payload;

      if (board) {
        const updatedStepLayers = Object.fromEntries(
          Object.entries(cloneDeep(board.stepLayers)).map(([key, val]) => {
            val.commandsFuture = [];
            val.commandsHistory = [];

            return [key, val];
          })
        );
        const updatedBoard = {
          commands: state.commands || {},
          activeStep: state.activeStep,
          stepLayers: updatedStepLayers,
          lines: board.lines || {},
          objects: board.objects || {},
          connections: board.connections || {},
          map: board.map || Maps.SPLIT,
          mapRotation: board.mapRotation
        };

        return { ...updatedBoard };
      }
      return state;
    },
    addCommand(state: IBoard, action: PayloadAction<{ command: ICommand }>) {
      const { command } = action.payload;
      command.params.stepLayer = state.activeStep; // add stepLayer here to prevent rerenders
      // push command to state array
      state.commands[command.id] = command;
      state.stepLayers[state.activeStep].commandsHistory = [
        ...state.stepLayers[state.activeStep].commandsHistory,
        command.id
      ];
      // always clear the future as soon as a new command is added
      state.stepLayers[state.activeStep].commandsFuture = [];

      state.stepLayers[state.activeStep].commandsHistory = shiftArray(
        state.stepLayers[state.activeStep].commandsHistory,
        MAX_HISTORY_FUTURE_LENGTH
      );
    },
    executeCommand(
      state: IBoard,
      action: PayloadAction<{ command: ICommand }>
    ) {
      const { command } = action.payload;
      state = controller.execute(state, command);
    },
    invertCommand(state: IBoard, action: PayloadAction<{ command: ICommand }>) {
      const { command } = action.payload;
      state = controller.invert(state, command);
    },
    updateBoard(state: IBoard, action: PayloadAction<{ board: IBoard }>) {
      const { board } = action.payload;
      if (board) {
        const updatedStepLayers = Object.fromEntries(
          Object.entries(cloneDeep(board.stepLayers)).map(([key, val]) => {
            // merge commands back into stepLayers

            if (state.stepLayers[state.activeStep] && val) {
              val.commandsFuture =
                state.stepLayers[state.activeStep].commandsFuture || [];
              val.commandsHistory =
                state.stepLayers[state.activeStep].commandsHistory || [];
            }

            return [key, val];
          })
        );
        const updatedBoard = {
          commands: state.commands || {},
          activeStep: board.stepLayers[state.activeStep] ? state.activeStep : 0,
          stepLayers: updatedStepLayers,
          lines: board.lines || {},
          objects: board.objects || {},
          connections: board.connections || {},
          map: board.map || Maps.SPLIT,
          mapRotation: board.mapRotation
        };

        return { ...updatedBoard };
      }
      return state;
    },
    clearBoard() {
      return initialState;
    },
    rotateMap(state: IBoard) {
      const newRotation = rotateMapBy(state.mapRotation, 90);
      state.mapRotation = newRotation;
    },
    undo(state: IBoard) {
      const commandId =
        state.stepLayers[state.activeStep].commandsHistory.pop();
      if (commandId) {
        // pop last added item from state array
        // history
        state.stepLayers[state.activeStep].commandsHistory = [
          ...state.stepLayers[state.activeStep].commandsHistory.slice(
            0,
            state.stepLayers[state.activeStep].commandsHistory.length
          )
        ];
        state.stepLayers[state.activeStep].commandsHistory = shiftArray(
          state.stepLayers[state.activeStep].commandsHistory,
          MAX_HISTORY_FUTURE_LENGTH
        );
        // future
        state.stepLayers[state.activeStep].commandsFuture = [
          ...state.stepLayers[state.activeStep].commandsFuture,
          commandId
        ];
        state.stepLayers[state.activeStep].commandsFuture = shiftArray(
          state.stepLayers[state.activeStep].commandsFuture,
          MAX_HISTORY_FUTURE_LENGTH
        );
      }
    },
    redo(state: IBoard) {
      const commandId = state.stepLayers[state.activeStep].commandsFuture.pop();
      if (commandId) {
        // pop last added item from state array
        // future
        state.stepLayers[state.activeStep].commandsFuture = [
          ...state.stepLayers[state.activeStep].commandsFuture.slice(
            0,
            state.stepLayers[state.activeStep].commandsFuture.length
          )
        ];
        state.stepLayers[state.activeStep].commandsFuture = shiftArray(
          state.stepLayers[state.activeStep].commandsFuture,
          MAX_HISTORY_FUTURE_LENGTH
        );
        // history
        state.stepLayers[state.activeStep].commandsHistory = [
          ...state.stepLayers[state.activeStep].commandsHistory,
          commandId
        ];
        state.stepLayers[state.activeStep].commandsHistory = shiftArray(
          state.stepLayers[state.activeStep].commandsHistory,
          MAX_HISTORY_FUTURE_LENGTH
        );
      }
    },

    addStepLayer(state: IBoard, action: PayloadAction<{ newStep: number }>) {
      const { newStep } = action.payload;
      // clone everything
      const latestLayerIndex = Object.keys(state.stepLayers).length - 1;
      state = cloneStepLayer(state, latestLayerIndex, newStep);
      state.activeStep = newStep;
    },
    removeStepLayer(state: IBoard, action: PayloadAction<{ newStep: number }>) {
      const { newStep } = action.payload;

      // remove elements
      state.lines = filterObject(
        state.lines,
        state.stepLayers[newStep].lines,
        true
      );
      state.objects = filterObject(
        state.objects,
        state.stepLayers[newStep].objects,
        true
      );
      state.connections = filterObject(
        state.connections,
        state.stepLayers[newStep].connections,
        true
      );

      // remove stepLayer
      const { [newStep]: foo, ...otherObjects } = state.stepLayers;
      state.stepLayers = otherObjects;
    },
    changeStepLayer(state: IBoard, action: PayloadAction<{ newStep: number }>) {
      const { newStep } = action.payload;

      if (
        newStep !== undefined &&
        newStep !== null &&
        newStep <= Object.keys(state.stepLayers).length
      ) {
        state.activeStep = newStep;
      }

      if (!state.stepLayers[newStep]) {
        state.stepLayers = {
          ...state.stepLayers,
          [newStep]: {
            commandsFuture: [],
            commandsHistory: []
          }
        };
      }
    },
    updateSelectedConnections(
      state: IBoard,
      action: PayloadAction<{ object: IObject }>
    ) {
      const { object } = action.payload;

      const node: INode = {
        id: object.id,
        position: object.position
      };

      if (state?.connections) {
        state.connections = updateConnections(
          node,
          state.connections,
          object.connections
        );
      }
    },
    changeMap(state: IBoard, action: PayloadAction<{ newMap: Maps }>) {
      const { newMap } = action.payload;

      if (newMap) {
        state.map = newMap;
      }
    },

    setActiveStep(state: IBoard, action: PayloadAction<{ newStep: number }>) {
      const { newStep } = action.payload;
      state.activeStep = newStep;
    }
  }
});

export const { reducer } = slice;

export const addCommand =
  (command: ICommand): AppThunk =>
  async (dispatch) => {
    const response = { command };
    dispatch(slice.actions.addCommand(response));

    if (command.params.sessionId) {
      await executeCommandInDb(response);
    } else {
      dispatch(slice.actions.executeCommand(response));
    }
  };

export const updateBoard =
  (board: IBoard): AppThunk =>
  (dispatch) => {
    const response = { board };
    dispatch(slice.actions.updateBoard(response));
  };

export const initBoard =
  (board: IBoard): AppThunk =>
  (dispatch) => {
    const response = { board };
    dispatch(slice.actions.initBoard(response));
  };

export const clearBoard =
  (sessionId?: string): AppThunk =>
  async (dispatch) => {
    // only delete board in database when we clear button is pressed
    if (sessionId) {
      await clearBoardDb(sessionId);
    }
    // also do this to clear commands
    dispatch(slice.actions.clearBoard());
  };

export const undo =
  (sessionId?: string): AppThunk =>
  async (dispatch, getState) => {
    const { model } = getState().boardSlice;
    const { commandsHistory } = model.stepLayers[model.activeStep];
    const id: string = commandsHistory[commandsHistory.length - 1];
    const undoCommand: ICommand = model.commands[id];
    if (undoCommand) {
      if (!sessionId) {
        dispatch(slice.actions.invertCommand({ command: undoCommand }));
      } else {
        await executeCommandInDb({ command: undoCommand }, true);
      }
      dispatch(slice.actions.undo());
    }
  };

export const redo =
  (sessionId?: string): AppThunk =>
  async (dispatch, getState) => {
    const { model } = getState().boardSlice;
    const { commandsFuture } = model.stepLayers[model.activeStep];
    const id: string = commandsFuture[commandsFuture.length - 1];
    const redoCommand: ICommand = model.commands[id];
    if (redoCommand) {
      if (!sessionId) {
        dispatch(slice.actions.executeCommand({ command: redoCommand }));
      } else {
        await executeCommandInDb({ command: redoCommand }, false);
      }
      dispatch(slice.actions.redo());
    }
  };

export const rotateMap =
  (sessionId?: string): AppThunk =>
  async (dispatch) => {
    if (!sessionId) {
      dispatch(slice.actions.rotateMap());
    } else {
      await rotateMapDb(sessionId);
    }
    dispatch(slice.actions.redo());
  };

export const addStepLayers =
  (newStep: number, sessionId: string): AppThunk =>
  async (dispatch) => {
    if (sessionId) {
      await addStepLayer(sessionId, newStep);
    } else {
      dispatch(slice.actions.addStepLayer({ newStep }));
    }

    dispatch(slice.actions.setActiveStep({ newStep }));
  };

export const removeStepLayers =
  (stepCount: number, sessionId: string): AppThunk =>
  async (dispatch, getState) => {
    const { model } = getState().boardSlice;
    const newStep = stepCount - 1;
    if (model.activeStep >= newStep) {
      dispatch(slice.actions.setActiveStep({ newStep: newStep - 1 }));
    }

    if (sessionId) {
      await removeStepLayer(sessionId, newStep);
    } else {
      dispatch(slice.actions.removeStepLayer({ newStep }));
    }
  };

export const changeStepLayer =
  (newStep: number, sessionId: string): AppThunk =>
  (dispatch) => {
    const response = { newStep, sessionId };
    dispatch(slice.actions.changeStepLayer(response));
  };

export const changeMap =
  (newMap: Maps, sessionId: string): AppThunk =>
  async (dispatch) => {
    const response = { newMap, sessionId };
    if (sessionId) {
      await changeMapDb(newMap, sessionId);
    } else {
      dispatch(slice.actions.changeMap(response));
    }
  };

export const updateSelectedConnections =
  (object: IObject): AppThunk =>
  async (dispatch) => {
    const response = { object };

    dispatch(slice.actions.updateSelectedConnections(response));
  };

export default slice;
