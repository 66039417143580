import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import useSettings from 'src/hooks/useSettings';

export interface CreatePlaybookStepThreeData {
  name: string;
  description: string | null;
  plays: string[];
  teamID: string | null;
}

interface CreatePlaybookStepThreeProps {
  backToStepTwo: Function;
  stepTwoData: string[];
  stepOneData: any;
  submitFunction: Function;
}

const CreatePlaybookStepThree: FC<CreatePlaybookStepThreeProps> = (props) => {
  const { backToStepTwo, stepTwoData, stepOneData, submitFunction } = props;
  const { settings } = useSettings();
  const activeWorkspace = settings?.activeSpaceID;

  const theme = useTheme();

  if (!stepOneData) {
    return null;
  }

  if (!stepTwoData) {
    return null;
  }

  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: '100%', mb: 6, mt: 1 }}
      >
        <Grid
          item
          xs={12}
          md={8}
          sx={{
            height: '100%',
            p: 4,

            border: `1px solid ${theme.palette.divider}`,
            borderRadius: `${theme.shape.borderRadius}px`
          }}
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              xs={12}
              sx={{ pb: 2 }}
            >
              <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
                sx={{
                  width: 'auto'
                }}
              >
                <Typography color="textPrimary" variant="h3">
                  {stepOneData.name}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  a Valorant Playbook with {stepTwoData.length} Play
                  {stepTwoData.length === 1 ? <></> : <>s</>}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ pb: 2 }}>
              <Divider />
            </Grid>

            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              {stepOneData.description ? (
                <Typography color="textPrimary" variant="body2">
                  {stepOneData.description}
                </Typography>
              ) : (
                <Typography
                  color="textPrimary"
                  variant="body2"
                  sx={{ maxWidth: '370px' }}
                >
                  You have not provided a playbook description. This is optional
                  and can always be changed later on.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          sx={{}}
          variant="text"
          startIcon={<ArrowBackIcon />}
          onClick={() => backToStepTwo()}
        >
          Back
        </Button>

        <Button
          sx={{}}
          variant="contained"
          onClick={() => {
            const createPlaybookStepThreeData: CreatePlaybookStepThreeData = {
              name: stepOneData.name,
              description: stepOneData.description,
              plays: stepTwoData,
              teamID: activeWorkspace || null
            };

            submitFunction(createPlaybookStepThreeData);
          }}
        >
          Create Playbook
        </Button>
      </Grid>

      <Grid item />
    </Grid>
  );
};

CreatePlaybookStepThree.propTypes = {
  backToStepTwo: PropTypes.func,
  stepOneData: PropTypes.object,
  stepTwoData: PropTypes.array,
  submitFunction: PropTypes.func
};

export default CreatePlaybookStepThree;
