import CheckIcon from '@mui/icons-material/Check';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import CircularLoadingDialog from 'src/components/CircularLoadingDialog';
import { stripeConfig } from 'src/config';
import {
  basicFeaturesPersonal,
  proFeaturesPersonal,
  subscriptionTierBasicColor,
  subscriptionTierProColor
} from 'src/constants';
import {
  createCheckoutSession,
  listProductsAndPrices,
  redirectToCustomerPortal
} from 'src/data/firestore/setters/subscription';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import { RootState, useSelector } from 'src/store';
import { TeamInfo } from 'src/types/team';
import { AccountData } from 'src/types/user';
import hexToRGB from 'src/utils/hexToRGB';
import CustomAvatar from '../CustomAvatar';

interface SpaceCardProps {
  displayName: string;
  photoURL: string;
  id: string;
  theme: Theme;
  isTeam: boolean;
}

const priceToInterval = (price) => {
  if (!price) {
    return '';
  }

  if (price.interval === 'month') {
    if (price.intervalCount === 1) {
      return 'monthly';
    }

    return 'biannually';
  }

  return 'annually';
};

const SpaceCard: FC<SpaceCardProps> = (props) => {
  const { displayName, photoURL, id, theme, isTeam } = props;

  return (
    <Grid
      container
      direction="column"
      sx={{
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: `${theme.shape.borderRadius}px`,
        overflow: 'hidden',
        mb: 2
      }}
    >
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          width: '100%',

          p: 1.5
        }}
      >
        <CustomAvatar src={photoURL} isTeamAvatar={isTeam} />

        <Grid container item direction="column" sx={{ width: 'auto', ml: 1.5 }}>
          <Typography variant="h5"> {displayName} </Typography>

          <Typography variant="body2" color="textSecondary">
            {' '}
            {isTeam ? 'a Valorant Team Space' : 'your Personal Space'}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        sx={{ p: 1.5 }}
      >
        {isTeam ? (
          <Button>Go to Space</Button>
        ) : (
          <Button variant="contained"> Manage Subscriptions </Button>
        )}
      </Grid>
    </Grid>
  );
};

const AccountSubscriptionSettings: FC = () => {
  const [product, setProduct] = useState(null);
  const [price, setPrice] = useState(null);
  const [redirecting, setRedirecting] = useState(false);
  const { user } = useAuth();
  const { settings } = useSettings();
  const theme = useTheme();

  useEffect(() => {
    async function getProducts() {
      const response = await listProductsAndPrices();

      const targetProduct = response.find(
        (someProduct) =>
          someProduct.productId === stripeConfig.personalProductId
      );

      setProduct(targetProduct);

      setPrice(targetProduct?.prices[0]);
    }

    getProducts();
  }, []);

  const priceFromInterval = (interval) => {
    if (interval === 'monthly') {
      return product?.prices.find(
        (price_i) => price_i.intervalCount === 1 && price_i.interval === 'month'
      );
    }

    if (interval === 'biannually') {
      return product?.prices.find(
        (price_i) => price_i.intervalCount === 6 && price_i.interval === 'month'
      );
    }

    return product?.prices.find((price_i) => price_i.interval === 'year');
  };

  const teamsInfo: TeamInfo[] = useSelector((state: RootState) =>
    state.accountDataSlice.accountData
      ? state.accountDataSlice.accountData.teamsInfo
      : []
  );

  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );

  return (
    <>
      {redirecting && <CircularLoadingDialog title="Redirecting ..." />}

      {!product || !price ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%', height: 300 }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          container
          columnSpacing={3}
          rowSpacing={2}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {accountData.spaceTier === 'basic' && (
            <>
              <Grid item container xs={12} sx={{ mb: 1 }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                      p: 0.5,
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: `${theme.shape.borderRadius}px`
                    }}
                  >
                    <Button
                      sx={{
                        width: 176,
                        backgroundColor:
                          priceToInterval(price) !== 'monthly' && 'transparent'
                      }}
                      onClick={() => setPrice(priceFromInterval('monthly'))}
                    >
                      Monthly{' '}
                    </Button>
                    <Button
                      sx={{
                        width: 176,
                        mx: 0.5,
                        backgroundColor:
                          priceToInterval(price) !== 'biannually' &&
                          'transparent'
                      }}
                      onClick={() => setPrice(priceFromInterval('biannually'))}
                    >
                      Biannually
                      <Box
                        sx={{
                          backgroundColor: subscriptionTierProColor,
                          px: '5px',
                          borderRadius: 99,
                          ml: 0.5
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 9,
                            fontWeight: 600
                          }}
                        >
                          Save 1 month
                        </Typography>
                      </Box>
                    </Button>
                    <Button
                      sx={{
                        width: 176,
                        backgroundColor:
                          priceToInterval(price) !== 'annually' && 'transparent'
                      }}
                      onClick={() => setPrice(priceFromInterval('annually'))}
                    >
                      Annually
                      <Box
                        sx={{
                          backgroundColor: subscriptionTierProColor,
                          px: '5px',
                          borderRadius: 99,
                          ml: 0.5
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 9,
                            fontWeight: 600
                          }}
                        >
                          Save 3 months
                        </Typography>
                      </Box>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}

          <Grid item container xs={6} justifyContent="center">
            <Grid
              item
              direction="column"
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundColor: theme.palette.background.paper,
                border:
                  accountData.spaceTier === 'basic'
                    ? `2px solid #ffffff`
                    : `1px solid ${theme.palette.divider}`,
                borderRadius: `${theme.shape.borderRadius}px`,
                p: 5,
                position: 'relative'
              }}
            >
              {accountData.spaceTier === 'basic' && (
                <Box
                  sx={{
                    backgroundColor: '#ffffff',
                    position: 'absolute',
                    top: -11,
                    right: 40,
                    px: 1.5,
                    py: 0.25,
                    borderRadius: `${theme.shape.borderRadius}px`
                  }}
                >
                  {' '}
                  <Typography
                    sx={{
                      color: theme.palette.background.default,
                      fontSize: 11,
                      fontWeight: 600
                    }}
                  >
                    ACTIVE
                  </Typography>{' '}
                </Box>
              )}

              <Grid
                item
                sx={{
                  backgroundColor: hexToRGB(subscriptionTierBasicColor, '0.1'),
                  border: `1px solid ${subscriptionTierBasicColor}`,
                  px: 2,
                  py: 0.1,
                  borderRadius: `${theme.shape.borderRadius}px`
                }}
              >
                <Typography sx={{ fontWeight: 500, fontSize: 15 }}>
                  Basic Tier
                </Typography>
              </Grid>

              <Typography
                sx={{ fontWeight: 500, fontSize: 26, pt: 1.5, pb: 0.5 }}
              >
                FREE
              </Typography>

              <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
                No Payment Details required
              </Typography>
              <Divider sx={{ width: '100%', mb: 3 }} />
              {basicFeaturesPersonal.map((feature) => (
                <Grid
                  key={feature}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ py: 1, pl: 1 }}
                >
                  <CheckIcon
                    sx={{
                      color: subscriptionTierBasicColor,
                      backgroundColor: hexToRGB(
                        subscriptionTierBasicColor,
                        '0.3'
                      ),
                      borderRadius: 99,
                      p: '2px'
                    }}
                  />

                  <Typography sx={{ ml: 2 }}>{feature}</Typography>
                </Grid>
              ))}

              {accountData.spaceTier !== 'basic' &&
                !accountData.spaceTier.cancelAt && (
                  <Button
                    variant="text"
                    onClick={() => {
                      setRedirecting(true);
                      redirectToCustomerPortal();
                    }}
                    sx={{ mt: 4, width: 170 }}
                  >
                    Downgrade
                  </Button>
                )}
            </Grid>
          </Grid>

          <Grid item container xs={6} justifyContent="center">
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              xs={12}
              sx={{
                backgroundColor: theme.palette.background.paper,
                border:
                  accountData.spaceTier === 'basic'
                    ? `1px solid ${theme.palette.divider}`
                    : `2px solid #ffffff`,
                borderRadius: `${theme.shape.borderRadius}px`,
                p: 5,
                position: 'relative'
              }}
            >
              {accountData.spaceTier !== 'basic' && (
                <Box
                  sx={{
                    backgroundColor: '#ffffff',
                    position: 'absolute',
                    top: -11,
                    right: 40,
                    px: 1.5,
                    py: 0.25,
                    borderRadius: `${theme.shape.borderRadius}px`
                  }}
                >
                  {' '}
                  <Typography
                    sx={{
                      fontSize: 11,
                      fontWeight: 600,
                      color: theme.palette.background.default
                    }}
                  >
                    ACTIVE
                  </Typography>{' '}
                </Box>
              )}

              <Grid
                item
                sx={{
                  backgroundColor: hexToRGB(subscriptionTierProColor, '0.1'),
                  border: `1px solid ${subscriptionTierProColor}`,
                  px: 2,
                  py: 0.1,
                  borderRadius: `${theme.shape.borderRadius}px`
                }}
              >
                <Typography sx={{ fontWeight: 500, fontSize: 15 }}>
                  Pro Tier
                </Typography>
              </Grid>

              <Typography
                sx={{ fontWeight: 500, fontSize: 26, pt: 1.5, pb: 0.5 }}
              >
                ${(price?.amount / 100)?.toFixed(2)}
              </Typography>

              <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
                {priceToInterval(price) === 'monthly' && '/ per month'}
                {priceToInterval(price) === 'biannually' && '/ every 6 months'}
                {priceToInterval(price) === 'annually' && '/ every year'}
              </Typography>

              {accountData.spaceTier !== 'basic' &&
                accountData.spaceTier.cancelAt && (
                  <Alert
                    severity="error"
                    sx={{
                      py: 1,
                      mt: 1,
                      mb: 4,
                      border: `1px solid ${theme.palette.error.main}`,
                      backgroundColor: hexToRGB(theme.palette.error.main, '0.1')
                    }}
                  >
                    <AlertTitle>
                      Canceled{' '}
                      {moment(accountData.spaceTier.cancelAt).fromNow()}
                    </AlertTitle>
                    You will still be able to use all PRO features until the end
                    of this subscription period.
                  </Alert>
                )}

              <Divider sx={{ width: '100%', mb: 3 }} />

              {proFeaturesPersonal.map((feature) => (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ py: 1, pl: 1 }}
                  key={feature}
                >
                  <CheckIcon
                    sx={{
                      color: subscriptionTierProColor,
                      backgroundColor: hexToRGB(
                        subscriptionTierProColor,
                        '0.3'
                      ),
                      borderRadius: 99,
                      p: '2px'
                    }}
                  />

                  <Typography sx={{ ml: 2 }}>{feature}</Typography>
                </Grid>
              ))}

              <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                {accountData.spaceTier === 'basic' &&
                  'This subscription unlocks premium features for your personal space.'}
                {accountData.spaceTier !== 'basic' &&
                  accountData.spaceTier.subscriberId === user.id &&
                  `You are paying for this subscription. You thus unlock premium features for your personal space.`}
              </Typography>

              {accountData.spaceTier === 'basic' && (
                <Button
                  variant="contained"
                  sx={{
                    mt: 3.5,
                    width: 170,
                    backgroundColor: subscriptionTierProColor
                  }}
                  onClick={() => {
                    setRedirecting(true);
                    createCheckoutSession(
                      price.priceId,
                      settings.activeSpaceID || null, // null if personal space
                      user.id
                    );
                  }}
                >
                  Upgrade
                </Button>
              )}

              {accountData.spaceTier !== 'basic' && (
                <Button
                  variant="contained"
                  sx={{
                    mt: 3.5,
                    width: 170,
                    backgroundColor: subscriptionTierProColor
                  }}
                  onClick={() => {
                    setRedirecting(true);
                    redirectToCustomerPortal();
                  }}
                >
                  Manage Subscription
                </Button>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
              Note: This subscription applies to your personal space. If you
              want to manage the subscription of a team space, please change
              your active space and navigate to the settings section
              &apos;Subscription&apos;.
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AccountSubscriptionSettings;
