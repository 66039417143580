import { ValorantItemListObject } from 'src/components/app/board/tools/ValorantItemList';
import AstraIcon from 'src/ressources/shapes/agents/astra_new.png';
import BreachIcon from 'src/ressources/shapes/agents/breach_new.png';
import BrimstoneIcon from 'src/ressources/shapes/agents/brimstone_new.png';
import ChamberIcon from 'src/ressources/shapes/agents/chamber_new.png';
import CypherIcon from 'src/ressources/shapes/agents/cypher_new.png';
import FadeIcon from 'src/ressources/shapes/agents/fade_new.png';
import JettIcon from 'src/ressources/shapes/agents/jett_new.png';
import KayoIcon from 'src/ressources/shapes/agents/kayo_new.png';
import KilljoyIcon from 'src/ressources/shapes/agents/killjoy_new.png';
import OmenIcon from 'src/ressources/shapes/agents/omen_new.png';
import PhoenixIcon from 'src/ressources/shapes/agents/phoenix_new.png';
import RazeIcon from 'src/ressources/shapes/agents/raze_new.png';
import ReynaIcon from 'src/ressources/shapes/agents/reyna_new.png';
import SageIcon from 'src/ressources/shapes/agents/sage_new.png';
import SkyeIcon from 'src/ressources/shapes/agents/skye_new.png';
import SovaIcon from 'src/ressources/shapes/agents/sova_new.png';
import ViperIcon from 'src/ressources/shapes/agents/viper_new.png';
import YoruIcon from 'src/ressources/shapes/agents/yoru_new.png';
import NeonIcon from 'src/ressources/shapes/agents/neon.png';
import HarborIcon from 'src/ressources/shapes/agents/harbor_new.png';
import GekkoIcon from 'src/ressources/shapes/agents/gekko.png';
import DeadlockIcon from 'src/ressources/shapes/agents/deadlock.png';
import { AgentTypes } from 'src/types/board';

export const AGENTS: { [key in AgentTypes]: ValorantItemListObject } = {
  [AgentTypes.ASTRA]: {
    title: 'Astra',
    backgroundColor: '#a43cc1',
    value: AgentTypes.ASTRA,
    icon: AstraIcon
  },
  [AgentTypes.BREACH]: {
    title: 'Breach',
    backgroundColor: '#b46008',
    value: AgentTypes.BREACH,
    icon: BreachIcon
  },

  [AgentTypes.BRIMSTONE]: {
    title: 'Brimstone',
    backgroundColor: '#007fff',
    value: AgentTypes.BRIMSTONE,
    icon: BrimstoneIcon
  },
  [AgentTypes.CHAMBER]: {
    title: 'Chamber',
    backgroundColor: '#D1D5DB',
    value: AgentTypes.CHAMBER,
    icon: ChamberIcon
  },
  [AgentTypes.CYPHER]: {
    title: 'Cypher',
    backgroundColor: '#f0f0f0',
    value: AgentTypes.CYPHER,
    icon: CypherIcon
  },
  [AgentTypes.FADE]: {
    title: 'Fade',
    backgroundColor: '#000000',
    value: AgentTypes.FADE,
    icon: FadeIcon
  },
  [AgentTypes.JETT]: {
    title: 'Jett',
    backgroundColor: '#00ffff',
    value: AgentTypes.JETT,
    icon: JettIcon
  },
  [AgentTypes.KAYO]: {
    title: 'Kayo',
    backgroundColor: '#f60f9f',
    value: AgentTypes.KAYO,
    icon: KayoIcon
  },
  [AgentTypes.KILLJOY]: {
    title: 'Killjoy',
    backgroundColor: '#e7ce2f',
    value: AgentTypes.KILLJOY,
    icon: KilljoyIcon
  },
  [AgentTypes.OMEN]: {
    title: 'Omen',
    backgroundColor: '#5e62b8',
    value: AgentTypes.OMEN,
    icon: OmenIcon
  },
  [AgentTypes.PHOENIX]: {
    title: 'Phoenix',
    backgroundColor: '#ff4200',
    value: AgentTypes.PHOENIX,
    icon: PhoenixIcon
  },
  [AgentTypes.RAZE]: {
    title: 'Raze',
    backgroundColor: '#ff9c06',
    value: AgentTypes.RAZE,
    icon: RazeIcon
  },
  [AgentTypes.REYNA]: {
    title: 'Reyna',
    backgroundColor: '#ec50f7',
    value: AgentTypes.REYNA,
    icon: ReynaIcon
  },
  [AgentTypes.SAGE]: {
    title: 'Sage',
    backgroundColor: '#22d48e',
    value: AgentTypes.SAGE,
    icon: SageIcon
  },
  [AgentTypes.SKYE]: {
    title: 'Skye',
    backgroundColor: '#577a17',
    value: AgentTypes.SKYE,
    icon: SkyeIcon
  },
  [AgentTypes.SOVA]: {
    title: 'Sova',
    backgroundColor: '#a1c6e8',
    value: AgentTypes.SOVA,
    icon: SovaIcon
  },
  [AgentTypes.VIPER]: {
    title: 'Viper',
    backgroundColor: '#01a543',
    value: AgentTypes.VIPER,
    icon: ViperIcon
  },
  [AgentTypes.YORU]: {
    title: 'Yoru',
    backgroundColor: '#005eb2',
    value: AgentTypes.YORU,
    icon: YoruIcon
  },
  [AgentTypes.NEON]: {
    title: 'Neon',
    backgroundColor: '#5f2a85',
    value: AgentTypes.NEON,
    icon: NeonIcon
  },
  [AgentTypes.HARBOR]: {
    title: 'Harbor',
    backgroundColor: '#2596be',
    value: AgentTypes.HARBOR,
    icon: HarborIcon
  },
  [AgentTypes.GEKKO]: {
    title: 'Gekko',
    backgroundColor: '#ddc057',
    value: AgentTypes.GEKKO,
    icon: GekkoIcon
  },
  [AgentTypes.DEADLOCK]: {
    title: 'Deadlock',
    backgroundColor: '#9772b6',
    value: AgentTypes.DEADLOCK,
    icon: DeadlockIcon
  }
};
