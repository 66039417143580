import { doc, onSnapshot } from 'firebase/firestore';
import serverSideLogger from 'src/logging/serverSideLogger';
import { updateTeam } from 'src/slices/team';
import store from 'src/store';
import { LoggerInput } from 'src/types/functionsInput';
import { firestore } from '../../../lib/firebase';
import { teamConverter } from '../../converter/team/teamConverter';

/**
 * Listener for team state
 * @param {string} teamId : id if team document in firestore
 * @returns listener for reference of team document
 */
export const subscribeToTeam = (teamId) => {
  try {
    const ref = doc(firestore, 'teams', teamId).withConverter(teamConverter);

    return onSnapshot(
      ref,
      async (document) => {
        if (document.data()) {
          store.dispatch(updateTeam(document.data(), document.exists()));
          // if there is
        } else {
          window.location.href = '/app/personal';
        }
      },
      (error) => {
        console.error(
          'Error occured while trying to subscribe to Team ',
          teamId,
          ':',
          error
        );

        window.location.href = '/app/personal';
      }
    );
  } catch (err) {
    console.error(
      'Error occured while trying to subscribe to Team ',
      teamId,
      ':',
      err
    );

    const logObject: LoggerInput = {
      kind: 'error',
      function: 'subscribeToTeam',
      message: err,
      metaData: {
        teamId
      }
    };
    serverSideLogger(logObject);
    return undefined;
  }
};
