import { httpsCallable } from 'firebase/functions';
import { functions } from 'src/lib/firebase';
import { LoggerInput } from 'src/types/functionsInput';

const triggerLogger = httpsCallable(functions, 'logger');

/**
 * It takes a logObject as an argument, and returns a new object with the same properties as the
 * logObject, but with the message property stringified
 * @param {LoggerInput} logObject - LoggerInput
 */
const serverSideLogger = (logObject: LoggerInput) => {
  const loggerInput: LoggerInput = {
    kind: logObject.kind,
    function: logObject.function,
    message: JSON.stringify(
      logObject.message,
      Object.getOwnPropertyNames(logObject.message)
    ),
    metaData: logObject.metaData
  };
  triggerLogger(loggerInput);
};

export default serverSideLogger;
