import { detect } from 'detect-browser';
import { MPEventGeneralPropsClientSide } from 'src/types/mixpanel/event/mpEvent';

const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

const generateMPEventGeneralPropsClientSide = () => {
  const browser = detect();

  const data: MPEventGeneralPropsClientSide = {
    browser_name: browser?.name,
    browser_version: browser?.version,
    browser_os: browser?.os,
    is_mobile: isMobile,
    current_url: window.location.href,
    screen_width: window.screen.width,
    screen_height: window.screen.height,
    screen_width_available: window.screen.availWidth,
    screen_height_available: window.screen.availHeight,
    time: Date.now(),
    window_outer_width: window.outerWidth,
    window_outer_height: window.outerHeight,
    web_page_width: document.documentElement.scrollWidth,
    web_page_height: document.documentElement.scrollHeight
  };

  return data;
};

export default generateMPEventGeneralPropsClientSide;
