import {
  collection,
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocs
} from 'firebase/firestore';
import serverSideLogger from 'src/logging/serverSideLogger';
import { LoggerInput } from 'src/types/functionsInput';
import { Play } from 'src/types/play';
import { firestore } from '../../../lib/firebase';
import { playConverter } from '../../converter/playConverter';

/**
 *
 * @param {string} playId
 * @returns
 */
export const getPlay = (playId: string): Promise<DocumentSnapshot<Play>> => {
  try {
    const ref = doc(firestore, 'plays', playId).withConverter(playConverter);
    return getDoc(ref);
  } catch (err) {
    console.error('Error occured while trying to get Play ', playId, ':', err);

    const logObject: LoggerInput = {
      kind: 'error',
      function: 'subscribeToTeam',
      message: err,
      metaData: {
        playId
      }
    };
    serverSideLogger(logObject);
    return undefined;
  }
};

/**
 *
 * @param {string} playId
 * @returns
 */
export const playExists = async (playId: string): Promise<boolean> => {
  try {
    const ref = doc(firestore, 'plays', playId).withConverter(playConverter);
    const playDoc = await getDoc(ref);
    return playDoc.exists();
  } catch (err) {
    return false;
  }
};
