import AssignmentIcon from '@mui/icons-material/Assignment';
import BugReportIcon from '@mui/icons-material/BugReportRounded';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppIcon from '@mui/icons-material/ExitToAppRounded';
import FeedbackIcon from '@mui/icons-material/FeedbackRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LaunchIcon from '@mui/icons-material/Launch';
import NotificationsIcon from '@mui/icons-material/Notifications';
// Icons
import NewspaperIcon from '@mui/icons-material/Newspaper';
import TopicIcon from '@mui/icons-material/Topic';
import {
  Box,
  Button,
  Divider,
  Drawer,
  duration,
  Grid,
  Link,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import {
  appSidebarWidth,
  appTopbarHeight,
  discordLink,
  subscriptionTierBasicColor,
  subscriptionTierProColor
} from 'src/constants';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import { RootState } from 'src/store';
import { backgroundColorSidebar } from 'src/theme/dark-theme-options';
import { Team, TeamInfo } from 'src/types/team';
import { AccountData } from 'src/types/user';
import getSpaceTier from 'src/utils/getSpaceTier';
import selectTeamInfoProperty from 'src/utils/selectTeamInfoProperty';
import Logo from '../../icons/Logo';
import CustomDialog from '../CustomDialog';
import NavDialog from '../NavDialog';
import NavSection from '../NavSection';
import AccountPopover from './AccountPopover';
import CustomAvatar from './CustomAvatar';
import { LegalDialogContent } from './legal';
import { PrivacyDialogContent } from './privacy';
import { SwitchSpaceDialogContent } from './switchspace';
import { TermsDialogContent } from './terms';

interface AppSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  hidden: boolean;
}

const sectionsSpace = (activeSpaceId: string | null) => {
  if (activeSpaceId === null) {
    return [
      {
        title: 'Space',
        items: [
          {
            title: 'Dashboard',
            path: '/app/personal',
            icon: <DashboardIcon />
          },
          {
            title: 'Plays',
            path: '/app/personal/plays',
            icon: <AssignmentIcon />
          },
          {
            title: 'Playbooks',
            path: '/app/personal/playbooks',
            icon: <TopicIcon />
          }
        ]
      }
    ];
  }

  return [
    {
      title: 'Space',
      items: [
        {
          title: 'Dashboard',
          path: `/app/team/${activeSpaceId}`,
          icon: <DashboardIcon />
        },
        {
          title: 'Plays',
          path: `/app/team/${activeSpaceId}/plays`,
          icon: <AssignmentIcon />
        },
        {
          title: 'Playbooks',
          path: `/app/team/${activeSpaceId}/playbooks`,
          icon: <TopicIcon />
        }
      ]
    }
  ];
};

type InfoDialogOptions = 'terms' | 'privacy' | 'legal';

const sectionDisclosures = [
  {
    title: 'Privacy Policy',
    dialog: 'privacy' as InfoDialogOptions
  },
  {
    title: 'Terms',
    dialog: 'terms' as InfoDialogOptions
  },
  {
    title: 'Legal Notice',
    dialog: 'legal' as InfoDialogOptions
  }
];

const sectionGeneral = [
  {
    title: 'Notifications',
    dialog: 'notifications',
    icon: <NotificationsIcon />
  },
  {
    title: 'Feedback',
    dialog: 'feedback',
    icon: <FeedbackIcon />
  },
  {
    title: 'Report Bugs',
    dialog: 'bugs',
    icon: <BugReportIcon />
  },

  {
    title: 'Announcements',
    dialog: 'announcements',
    icon: <NewspaperIcon />
  },
  {
    title: 'Sign Out',
    dialog: 'signout',
    icon: <ExitToAppIcon />
  }
];

const AppSidebar: FC<AppSidebarProps> = (props) => {
  const { onMobileClose, openMobile, hidden } = props;

  const navigate = useNavigate();
  const teamsInfo: TeamInfo[] = useSelector((state: RootState) =>
    state.accountDataSlice.accountData
      ? state.accountDataSlice.accountData.teamsInfo
      : []
  );

  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );

  const team: Team = useSelector((state: RootState) => state.teamSlice.team);

  const [switchSpaceDialogOpen, setSwitchSpaceDialogOpen] =
    useState<boolean>(false);

  const [disclosureDialogTab, setDisclosureDialogTab] =
    useState<InfoDialogOptions>(null);

  const theme = useTheme();
  const location = useLocation();
  // replaces <Hidden/>
  const mobile = useMediaQuery((currentTheme: Theme) =>
    currentTheme.breakpoints.down('lg')
  );

  const { settings } = useSettings();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const handleSwitchSpace = (newSpaceId: string | null) => {
    setSwitchSpaceDialogOpen(false);

    // navigate to space --> rest is handled in guards

    if (newSpaceId !== null) {
      navigate(`/app/team/${newSpaceId}`);
      return;
    }

    navigate('/app/personal');
  };

  const handleOpenDisclosureDialog = (dialog: InfoDialogOptions) => () => {
    setDisclosureDialogTab(dialog);
  };

  const handleCloseDisclosureDialog = () => {
    setDisclosureDialogTab(null);
  };

  const renderSwitchSpaceButton = (
    spaceName: string,
    spacePhotoURL: string,
    isTeamSpace: boolean
  ) => (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: '100%',
        height: '100%',
        py: '10px'
      }}
    >
      <Button
        sx={{
          py: '5px',
          px: '5px',
          width: '100%',
          height: '100%',
          mx: 1.25,
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        }}
        onClick={() => setSwitchSpaceDialogOpen(true)}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: '100%',
            height: '100%',
            pl: '10px'
          }}
        >
          <CustomAvatar src={spacePhotoURL} isTeamAvatar={isTeamSpace} />
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              flexGrow: 1,
              maxWidth: 'calc(100% - 63px)',
              pl: 1.75,
              height: '100%'
            }}
          >
            <Typography
              variant="h5"
              sx={{
                textTransform: 'none',
                color: `${theme.palette.text.primary} !important`
              }}
            >
              {spaceName}
            </Typography>
          </Grid>
          <Box
            sx={{
              width: 30,
              height: 30
            }}
          >
            <KeyboardArrowUpIcon
              sx={{
                fontSize: 14,
                position: 'absolute',
                right: 21,
                top: 14,
                fill: theme.palette.text.secondary
              }}
            />

            <KeyboardArrowDownIcon
              sx={{
                fontSize: 14,
                position: 'absolute',
                right: 21,
                top: 21,
                fill: theme.palette.text.secondary
              }}
            />
          </Box>
        </Grid>
      </Button>
    </Grid>
  );

  const content = (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      sx={{
        flexGrow: 1,
        borderRight: `1px solid ${theme.palette.divider}`,
        backgroundColor: backgroundColorSidebar
      }}
    >
      <Box sx={{ p: 0, m: 0, height: '100%' }}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          wrap="nowrap"
          sx={{
            p: 0,
            m: 0,
            height: '100%'
          }}
        >
          <Box sx={{ p: 0, m: 0 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: `${appTopbarHeight}px`
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                sx={{ height: '100%', width: '100% ', px: 4 }}
              >
                <RouterLink to="/">
                  <Logo
                    sx={{
                      pt: 1,
                      height: '100%',
                      width: 75,
                      fill: theme.palette.secondary.main
                    }}
                  />
                </RouterLink>
                <Typography
                  variant="overline"
                  sx={{
                    position: 'absolute',
                    ml:
                      getSpaceTier(
                        settings.activeSpaceID !== null &&
                          settings.activeSpaceID !== undefined,
                        team,
                        accountData
                      ) === 'BASIC'
                        ? 17
                        : 15,
                    mt: 1.7,
                    color:
                      getSpaceTier(
                        settings.activeSpaceID !== null &&
                          settings.activeSpaceID !== undefined,
                        team,
                        accountData
                      ) === 'BASIC'
                        ? `${subscriptionTierBasicColor} !important`
                        : `${subscriptionTierProColor} !important`
                  }}
                >
                  {getSpaceTier(
                    settings.activeSpaceID !== null &&
                      settings.activeSpaceID !== undefined,
                    team,
                    accountData
                  )}
                </Typography>
              </Grid>
            </Box>

            <Box
              sx={{
                height: 68,
                mb: 0.75,
                mt: 0.25
              }}
            >
              <>
                {renderSwitchSpaceButton(
                  settings.activeSpaceID
                    ? selectTeamInfoProperty(
                        teamsInfo,
                        settings.activeSpaceID,
                        'displayName'
                      )
                    : 'Personal Space',
                  settings.activeSpaceID
                    ? selectTeamInfoProperty(
                        teamsInfo,
                        settings.activeSpaceID,
                        'photoURL'
                      )
                    : user.photoURL,
                  settings.activeSpaceID !== null
                )}

                <CustomDialog
                  open={switchSpaceDialogOpen}
                  setOpen={setSwitchSpaceDialogOpen}
                  title="Switch space"
                  width={650}
                  buttons={[]}
                >
                  <SwitchSpaceDialogContent
                    handleSwitchSpace={handleSwitchSpace}
                    onClose={() => setSwitchSpaceDialogOpen(false)}
                  />
                </CustomDialog>
              </>
            </Box>

            <Divider />

            <Box sx={{ mt: 1 }}>
              {sectionsSpace(settings.activeSpaceID).map((section) => (
                <NavSection
                  key={section.title}
                  pathname={location.pathname}
                  sx={{
                    '& + &': {
                      mt: 5
                    }
                  }}
                  {...section}
                />
              ))}
            </Box>
            <Divider />

            <Box sx={{ mt: 1 }}>
              {sectionGeneral.map((item) => (
                <NavDialog
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  dialog={item.dialog}
                />
              ))}
            </Box>
          </Box>

          <Box>
            <Box
              sx={{
                px: 3,
                pt: 2,
                pb: 2
              }}
            >
              <Link href={discordLink}>
                <Typography variant="h5" sx={{ mt: 1, mb: 0 }}>
                  Join the community
                  <LaunchIcon
                    sx={{
                      pt: '2px',
                      mb: '-3px',
                      ml: '5px',
                      fill: theme.palette.secondary.main
                    }}
                  />
                </Typography>
              </Link>

              <Typography
                variant="body2"
                sx={{
                  mt: 0.5,
                  color: `${theme.palette.text.secondary} !important`
                }}
              >
                Hop on our discord to help shape the future of critbase
              </Typography>
            </Box>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ px: 3, mb: 2 }}
            >
              {sectionDisclosures.map((section) => (
                <Link
                  key={section.title}
                  color={`${theme.palette.text.disabled}!important`}
                  onClick={handleOpenDisclosureDialog(section.dialog)}
                  variant="body2"
                  sx={{ fontSize: '0.6rem', cursor: 'pointer' }}
                >
                  {section.title}
                </Link>
              ))}
            </Grid>

            <CustomDialog
              title={capitalize(disclosureDialogTab)}
              width={600}
              open={!!disclosureDialogTab}
              setOpen={setDisclosureDialogTab}
              onClose={handleCloseDisclosureDialog}
              buttons={[]}
            >
              {disclosureDialogTab === 'terms' && (
                <TermsDialogContent onClose={handleCloseDisclosureDialog} />
              )}
              {disclosureDialogTab === 'privacy' && (
                <PrivacyDialogContent onClose={handleCloseDisclosureDialog} />
              )}
              {disclosureDialogTab === 'legal' && (
                <LegalDialogContent onClose={handleCloseDisclosureDialog} />
              )}
            </CustomDialog>

            <AccountPopover />
          </Box>
        </Grid>
      </Box>
    </Grid>
  );

  return (
    <>
      {hidden || mobile ? (
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          transitionDuration={{
            enter: duration.enteringScreen,
            exit: duration.leavingScreen
          }}
          PaperProps={{
            sx: {
              width: appSidebarWidth,
              backgroundColor: theme.palette.background.default,
              border: '0px !important'
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      ) : (
        <Drawer
          anchor="left"
          open
          transitionDuration={{ enter: 0, exit: 0 }}
          PaperProps={{
            sx: {
              backgroundColor: theme.palette.background.default,
              height: '100%',
              top: 0,
              position: 'absolute',
              zIndex: theme.zIndex.drawer,
              width: appSidebarWidth,
              border: '0px !important'
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      )}
    </>
  );
};

AppSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default AppSidebar;
