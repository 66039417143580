import { Unsubscribe } from 'firebase/database';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { startTeamListener, terminateTeamListener } from 'src/data/index';
import { subscribeToSessionInfo } from 'src/data/realtimeDb/listeners/session';
import useSettings from 'src/hooks/useSettings';
import {
  addSessionInfo,
  clearSessionInfos,
  removeSessionInfo
} from 'src/slices/sessionInfos';
import store, { RootState, useSelector } from 'src/store';
import { SessionInfos, SessionInfo } from 'src/types/session';
import { Team } from 'src/types/team';

interface TeamGuardProps {
  children?: ReactNode;
}

const TeamGuard: FC<TeamGuardProps> = ({ children }) => {
  const { teamId } = useParams();
  const { settings, saveSettings } = useSettings();
  let listeners: { [key: string]: Unsubscribe } = {};

  const team: Team = useSelector(
    (state: RootState) => state.teamSlice.team || undefined
  );
  const sessionInfos: SessionInfos = useSelector(
    (state: RootState) => state.sessionInfosSlice.sessionInfos
  );

  useEffect(() => {
    terminateTeamListener();
    saveSettings({
      ...settings,
      activeSpaceID: teamId
    });
  }, [teamId]);

  useEffect(() => {
    // this needs to be refactored. Problem is that async calls are trying to negitate (?) each other
    resetListeners();
    // if there are sessionrefs to listen to, listent to them
    if (team?.sessionRefs) {
      team.sessionRefs.forEach((ref) => {
        // need to work on duplicate handling
        if (!listeners[ref]) {
          listeners[ref] = subscribeToSessionInfo(
            ref,
            onSessionInfoValue(ref),
            onSessionInfoError(ref)
          );
        }
      });
    }
  }, [team?.sessionRefs]);

  const onSessionInfoValue = (ref) => async (snapshot) => {
    // success
    const info: SessionInfo = snapshot.val();
    if (info) {
      store.dispatch(addSessionInfo(info, ref));
    }
  };

  const onSessionInfoError = (ref) => (error) => {
    // remove from list
    if (sessionInfos?.[ref]) {
      store.dispatch(removeSessionInfo(ref));
    }

    // error
    console.warn(
      'Error occured while trying to subscribe to session users ',
      ref,
      ':',
      error
    );

    /// remove session, you only need to do something when sessionUsers was defined
    if (sessionInfos) {
      store.dispatch(removeSessionInfo(ref));
    }
  };

  const resetListeners = () => {
    store.dispatch(clearSessionInfos());
    Object.entries(listeners).forEach(([, listener]) => {
      // call the func again to unsubscribe
      listener();
    });

    // reset listeners
    listeners = {};
  };

  // starts listener if not already running
  if (teamId) {
    startTeamListener(teamId);
    // start sessionInfosListener
  }

  return <>{children}</>;
};

TeamGuard.propTypes = {
  children: PropTypes.node
};

export default TeamGuard;
