import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { StorageReference } from 'firebase/storage';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateTeamInformation } from 'src/data/firestore/setters/team';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { RootState } from 'src/store';
import { Team } from 'src/types/team';
import * as Yup from 'yup';
import CustomAvatar from '../CustomAvatar';
import ImageUploader, { removeImageByRef } from '../ImageUploader';
import SettingsSection from '../SettingsSection';

const displayUploadedImage = (
  previousImageURL: string | null,
  imageDownloadURL: string | null,
  waitingForImage: boolean
) => {
  if (waitingForImage) {
    return <CircularProgress style={{ height: 90, width: 90, padding: 15 }} />;
  }

  if (imageDownloadURL) {
    return (
      <CustomAvatar src={imageDownloadURL} isTeamAvatar sizeFactor={1.25} />
    );
  }

  if (previousImageURL) {
    return (
      <CustomAvatar src={previousImageURL} isTeamAvatar sizeFactor={1.25} />
    );
  }

  return <CustomAvatar src={null} isTeamAvatar sizeFactor={1.25} />;
};

const dataChanged = (
  initalDisplayName,
  currentDisplayName,
  initialDescription,
  currentDescription,
  imageRef
) => {
  const currentDescriptionOriginal =
    currentDescription === '' ? null : currentDescription;

  if (
    initalDisplayName === currentDisplayName &&
    initialDescription === currentDescriptionOriginal &&
    !imageRef
  ) {
    return false;
  }

  return true;
};

interface TeamGeneralSettingsProps {
  waitingForImage: boolean;
  setWaitingForImage: Function;
  waitingForTeamSettingsChange: boolean;
  setWaitingForTeamSettingsChange: Function;
  imageRef: StorageReference | null;
  setImageRef: Function;

  setDialogOpen: Function;
}

const TeamGeneralSettings: FC<TeamGeneralSettingsProps> = (props) => {
  const {
    waitingForImage,
    setWaitingForImage,
    waitingForTeamSettingsChange,
    setWaitingForTeamSettingsChange,
    imageRef,
    setImageRef,
    setDialogOpen
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { teamId } = useParams();
  const { user } = useAuth();
  const isMountedRef = useIsMountedRef();

  const username = useSelector((state: RootState) =>
    state.accountDataSlice.accountData
      ? state.accountDataSlice.accountData.username
      : null
  );
  const team: Team = useSelector((state: RootState) => state.teamSlice.team);

  const [imageDownloadURL, setImageDownloadURL] = useState(null);

  const theme = useTheme();

  if (teamId && !team) {
    return null;
  }

  if (!team) {
    return null;
  }

  if (!teamId) {
    return null;
  }

  return (
    <>
      {waitingForTeamSettingsChange ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '300px' }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          container
          columnSpacing={6}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item container xs={6}>
            <Formik
              enableReinitialize
              initialValues={{
                name: team.displayName,
                description: team.description || '',
                submit: null
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .min(3, 'Must be at least 3 characters long')
                  .max(30, 'Cannot be longer than 30 characters')
                  .required('Required'),
                description: Yup.string().min(
                  10,
                  'Must be at least 10 characters long'
                )
              })}
              onSubmit={async (
                values,
                { resetForm, setErrors, setStatus, setSubmitting }
              ): Promise<void> => {
                try {
                  setWaitingForTeamSettingsChange(true);

                  await updateTeamInformation(
                    team.displayName,
                    team.description,
                    team.photoURL,
                    values.name,
                    values.description,
                    imageDownloadURL,
                    team.id
                  );

                  enqueueSnackbar('Team updated', {
                    variant: 'success'
                  });
                  setWaitingForTeamSettingsChange(false);
                  setDialogOpen(false);

                  if (isMountedRef.current) {
                    resetForm();
                    setStatus({ success: true });
                    setSubmitting(false);
                    setImageRef(null);
                    setImageDownloadURL(null);
                  }
                } catch (err) {
                  console.error(err);

                  enqueueSnackbar('Team update failed', {
                    variant: 'error'
                  });

                  if (isMountedRef.current) {
                    setStatus({ success: false });
                    setErrors({ submit: err.message });
                    setSubmitting(false);
                    setWaitingForTeamSettingsChange(false);
                    setImageRef(null);
                    setImageDownloadURL(null);
                    await removeImageByRef(imageRef);
                  }
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }): JSX.Element => (
                <form onSubmit={handleSubmit}>
                  <Grid item container rowSpacing={4}>
                    <Grid item xs={12}>
                      <SettingsSection title="display name">
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={
                            (touched.name && errors.name) ||
                            'Enter the new name for the team '
                          }
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          variant="outlined"
                        />
                      </SettingsSection>
                    </Grid>

                    <Grid item xs={12}>
                      <SettingsSection title="description">
                        <TextField
                          error={Boolean(
                            touched.description && errors.description
                          )}
                          fullWidth
                          helperText={
                            (touched.description && errors.description) ||
                            'Enter the new description for the team '
                          }
                          name="description"
                          rows={4}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.description}
                          multiline
                          sx={{ padding: 0 }}
                        />
                      </SettingsSection>
                    </Grid>

                    <Grid item xs={12}>
                      {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                          <FormHelperText error>{errors.submit}</FormHelperText>
                        </Box>
                      )}

                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          pt: 2
                        }}
                      >
                        <Button
                          color="primary"
                          disabled={
                            isSubmitting ||
                            waitingForImage ||
                            !dataChanged(
                              team.displayName,
                              values.name,
                              team.description,
                              values.description,
                              imageRef
                            )
                          }
                          type="submit"
                          variant="contained"
                        >
                          Save Changes
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Grid>

          <Grid item container xs={6} rowSpacing={4}>
            <Grid item xs={12}>
              <SettingsSection title="avatar">
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    mt: 0,
                    width: '100%',
                    backgroundColor: theme.palette.background.paper,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: `${theme.shape.borderRadius}px`,
                    p: 6
                  }}
                >
                  <Box>
                    {displayUploadedImage(
                      team.photoURL,
                      imageDownloadURL,
                      waitingForImage
                    )}
                  </Box>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'center',
                      py: 2,
                      width: '100%',
                      px: 4
                    }}
                  >
                    <ImageUploader
                      downloadURL={imageDownloadURL}
                      setDownloadURL={setImageDownloadURL}
                      setImageRef={setImageRef}
                      imageRef={imageRef}
                      storagePath={`users/${user.id}/`}
                      prefix={username}
                      setWaitingForImage={setWaitingForImage}
                      enqueueSnackbar={enqueueSnackbar}
                    />
                  </Box>

                  <Typography
                    color="textSecondary"
                    variant="body2"
                    align="center"
                  >
                    Click to upload an avatar or change the avatar you have
                    chosen.
                  </Typography>
                </Grid>
              </SettingsSection>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

TeamGeneralSettings.propTypes = {
  waitingForImage: PropTypes.bool,
  setWaitingForImage: PropTypes.func,
  waitingForTeamSettingsChange: PropTypes.bool,
  setWaitingForTeamSettingsChange: PropTypes.func,
  imageRef: PropTypes.any,
  setImageRef: PropTypes.func,

  setDialogOpen: PropTypes.func
};

export default TeamGeneralSettings;
