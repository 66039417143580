import { FC, KeyboardEvent, MouseEvent, TouchEvent, useState } from 'react';
import { useDrop } from 'react-dnd';
import { shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TransformComponent } from 'react-zoom-pan-pinch';
import { BOARD_SIZE } from 'src/constants';
import { addCommand, redo, undo } from 'src/slices/board/boardModel';
import { setCursorState, setSelectedTool } from 'src/slices/board/boardUi';
import store, { RootState, useSelector } from 'src/store';
import { backgroundColorWhiteboard } from 'src/theme/dark-theme-options';
import {
  ActionTypes,
  BoardTools,
  DragTypes,
  IAddRemoveConnection,
  IAddRemoveValue,
  ICommand,
  ICommandParams,
  IConnection,
  IDrawingLine,
  IDropItem,
  INode,
  IObject,
  IPoint
} from 'src/types/board';
import createLineObj from 'src/utils/boardUtils/createLineObj';
import determineAreaOfEffect from 'src/utils/boardUtils/determineAreaOfEffect';
import determineBackgroundColor from 'src/utils/boardUtils/determineBackgroundColor';
import {
  relativeCoordinatesForMouseEvent,
  relativeCoordinatesForPoint,
  relativeCoordinatesForTouchEvent
} from 'src/utils/boardUtils/relativeCoordinatesForEvent';
import createResourceId from 'src/utils/createResourceId';
import { isMobile } from 'src/utils/isMobile';
import AreasOfEffectLayer from './layers/AreasOfEffectLayer';
import ConnectionLayer from './layers/ConnectionLayer';
import DrawLayer from './layers/DrawLayer';
import MapLayer from './layers/MapLayer';
import ObjectLayer from './layers/ObjectLayer';

interface IProps {
  wrapperScale: number;
  wrapperPoint: IPoint;
  wrapperComponent: HTMLDivElement;
}

interface ILayerOrder {
  drawLayer: number;
  objectLayer: number;
  connectionLayer: number;
  aoeLayer: number;
}

const Whiteboard: FC<IProps> = (props: IProps) => {
  const { wrapperPoint, wrapperScale, wrapperComponent } = props;

  const [, drop] = useDrop(
    () => ({
      // The type (or types) to accept - strings or symbols
      accept: [DragTypes.ABILITY, DragTypes.BASIC, DragTypes.AGENT],
      drop: (item: IDropItem, monitor) => {
        if (monitor.canDrop()) {
          const point = monitor.getClientOffset();
          const { value, attack, startNode } = item;

          const connectionId = startNode ? createResourceId() : undefined;

          const dragType = monitor.getItemType() as DragTypes;

          const obj: IObject = {
            id: createResourceId(),
            type: dragType,
            value,
            attack: attack || null,
            position: relativeCoordinatesForPoint(
              point,
              wrapperPoint?.x, // center on icon on drop
              wrapperPoint?.y,
              wrapperScale,
              mapRotation
            ),
            connections: connectionId ? [connectionId] : []
          };

          const areaOfEffect =
            dragType === DragTypes.ABILITY || dragType === DragTypes.AGENT
              ? determineAreaOfEffect(value)
              : undefined;

          if (areaOfEffect) {
            obj.areaOfEffect = {
              hidden: false,
              dx: areaOfEffect.maxLength,
              dy:
                obj.attack === 'ATTACK'
                  ? areaOfEffect.maxLength - areaOfEffect.defaultLength // attacker -> upwards
                  : areaOfEffect.maxLength * 2 -
                    (areaOfEffect.maxLength - areaOfEffect.defaultLength) // defender -> downwards
            };
          }

          let newConnection: IConnection;

          if (startNode) {
            const endNode: INode = {
              id: obj.id,
              position: obj.position
            };

            newConnection = {
              id: connectionId,
              startNode,
              endNode,
              color: determineBackgroundColor(value)
            };
          }

          const command: ICommand = {
            id: createResourceId(),
            type: ActionTypes.ADD_OBJECT,
            params: {
              value: {
                object: obj,
                connections: newConnection
                  ? { [newConnection?.id]: newConnection }
                  : undefined
              } as IAddRemoveValue,
              sessionId,
              playId,
              stepLayer: activeStep
            } as ICommandParams,
            inverse: ActionTypes.REMOVE_OBJECT
          };
          store.dispatch(addCommand(command));
          store.dispatch(setSelectedTool(BoardTools.SELECT));
        }
      },
      // Props to collect
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop()
      })
    }),
    [wrapperPoint, wrapperScale]
  );

  const { playId, teamId } = useParams();
  const sessionId = teamId ? playId : null;
  const { activeStep, map, mapRotation } = useSelector(
    (state: RootState) => state.boardSlice.model,
    shallowEqual
  );

  const { visibility, selectedTool, cursorState, lineOptions, arrowOptions } =
    useSelector((state: RootState) => state.boardSlice.ui);

  /**
   * depending on which tool is selected we need to put certain layers to the front so you are able to interact with the layer
   * this is down via zIndices
   */
  const determineLayerOrder = (tool: BoardTools): ILayerOrder => {
    if (tool === BoardTools.DRAW || tool === BoardTools.ERASE) {
      return {
        drawLayer: 1,
        objectLayer: null,
        connectionLayer: null,
        aoeLayer: null
      };
    }
    return {
      drawLayer: null,
      objectLayer: 1,
      connectionLayer: null,
      aoeLayer: null
    };
  };

  const INIT_LINE: IDrawingLine = {
    id: createResourceId(),
    path: '',
    points: [],
    strokeWidth: lineOptions.strokeWidth,
    strokeDashArray: lineOptions.strokeDashArray,
    color: lineOptions?.color,
    showDistance: lineOptions.showDistance
  };

  const INIT_CONNECTION: IConnection = {
    id: createResourceId(),
    startNode: null,
    endNode: null,
    markerStart: arrowOptions.markerStart,
    markerEnd: arrowOptions.markerEnd,
    strokeWidth: arrowOptions.strokeWidth,
    strokeDashArray: arrowOptions.strokeDashArray,
    color: arrowOptions?.color,
    opacity: 1
  };

  const [currentLine, setCurrentLine] = useState<IDrawingLine>(null);
  const [currentConnection, setCurrentConnection] = useState<IConnection>();

  const {
    mapBaseVisible,
    mapLabelsVisible,
    mapAreasVisible,
    mapObjectsVisible
  } = visibility;

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.ctrlKey && event.key === '90') {
      store.dispatch(undo(sessionId));
    }
    if (event.ctrlKey && event.key === '89') {
      store.dispatch(redo(sessionId));
    }
  };
  const handleEventStart = (point: IPoint) => {
    if (selectedTool === BoardTools.DRAW) {
      store.dispatch(setCursorState(ActionTypes.DRAW));
      const lineObj: IDrawingLine = createLineObj(point, INIT_LINE);
      setCurrentLine(lineObj);
    } else if (selectedTool === BoardTools.ARROW) {
      store.dispatch(setCursorState(ActionTypes.ADD_CONNECTION));
      const startNode: INode = { id: createResourceId(), position: point };
      const endNode: INode = { id: createResourceId(), position: point };
      const connection: IConnection = {
        ...INIT_CONNECTION,
        id: createResourceId(),
        startNode,
        endNode
      };

      setCurrentConnection(connection);
    } else if (selectedTool === BoardTools.ERASE) {
      store.dispatch(setCursorState(ActionTypes.ERASE));
    } else if (selectedTool === BoardTools.TEXT) {
      const value = '';
      const obj: IObject = {
        id: createResourceId(),
        type: DragTypes.TEXTAREA,
        value,
        attack: null,
        position: point,
        connections: []
      };

      const command: ICommand = {
        id: createResourceId(),
        type: ActionTypes.ADD_OBJECT,
        params: {
          value: {
            object: obj,
            connections: null
          } as IAddRemoveValue,
          sessionId,
          playId,
          stepLayer: activeStep
        } as ICommandParams,
        inverse: ActionTypes.REMOVE_OBJECT
      };
      store.dispatch(addCommand(command));
      store.dispatch(setSelectedTool(BoardTools.SELECT));
    }
  };
  const handleEventMove = (point: IPoint) => {
    if (selectedTool === BoardTools.DRAW && cursorState === ActionTypes.DRAW) {
      const lineObj: IDrawingLine = createLineObj(point, currentLine);

      setCurrentLine(lineObj);
    } else if (
      selectedTool === BoardTools.ARROW &&
      cursorState === ActionTypes.ADD_CONNECTION
    ) {
      const endNode: INode = { ...currentConnection.endNode, position: point };

      setCurrentConnection({ ...currentConnection, endNode });
    }
  };
  const handleEventEnd = async () => {
    store.dispatch(setCursorState(null));
    if (selectedTool === BoardTools.DRAW) {
      // TODO: Remove points property here

      if (currentLine) {
        const { points, ...finalLine } = currentLine;

        const command: ICommand = {
          id: createResourceId(),
          type: ActionTypes.DRAW,
          params: {
            value: {
              ...finalLine
            } as IDrawingLine,
            sessionId,
            playId,
            stepLayer: activeStep
          } as ICommandParams,
          inverse: ActionTypes.ERASE
        };

        // reset CurrentLine
        await store.dispatch(addCommand(command));
      }
      setCurrentLine(null);
    } else if (selectedTool === BoardTools.ARROW) {
      if (currentConnection) {
        const command: ICommand = {
          id: createResourceId(),
          type: ActionTypes.ADD_CONNECTION,
          params: {
            value: {
              connection: currentConnection
            } as IAddRemoveConnection,
            sessionId,
            playId,
            stepLayer: activeStep
          } as ICommandParams,
          inverse: ActionTypes.REMOVE_CONNECTION
        };

        await store.dispatch(addCommand(command));
      }
      setCurrentConnection(null);
    }
  };

  const handleMouseDown = (mouseEvent: MouseEvent) => {
    if (mouseEvent.button === 0 && !isMobile()) {
      const point = relativeCoordinatesForMouseEvent(
        mouseEvent,
        wrapperPoint.x,
        wrapperPoint.y,
        wrapperScale,
        mapRotation
      );
      handleEventStart(point);
    }
  };
  const handleMouseMove = (mouseEvent: MouseEvent) => {
    if (mouseEvent.button === 0 && !isMobile()) {
      const point = relativeCoordinatesForMouseEvent(
        mouseEvent,
        wrapperPoint.x,
        wrapperPoint.y,
        wrapperScale,
        mapRotation
      );
      handleEventMove(point);
    }
  };

  const handleMouseUp = (mouseEvent: MouseEvent) => {
    if (mouseEvent.button === 0 && !isMobile()) {
      handleEventEnd();
    }
  };

  const handleTouchStart = (touchEvent: TouchEvent<HTMLDivElement>) => {
    if (isMobile()) {
      const point = relativeCoordinatesForTouchEvent(
        touchEvent,
        wrapperPoint.x,
        wrapperPoint.y,
        wrapperScale,
        mapRotation
      );
      handleEventStart(point);
    }
  };
  const handleTouchMove = (touchEvent: TouchEvent<HTMLDivElement>) => {
    if (isMobile()) {
      const point = relativeCoordinatesForTouchEvent(
        touchEvent,
        wrapperPoint.x,
        wrapperPoint.y,
        wrapperScale,
        mapRotation
      );
      handleEventMove(point);
    }
  };

  const handleTouchEnd = () => {
    if (isMobile()) {
      handleEventEnd();
    }
  };

  const determineCursor = (tool: BoardTools) => {
    if (
      tool === BoardTools.DRAW ||
      tool === BoardTools.ERASE ||
      tool === BoardTools.ARROW
    ) {
      return 'crosshair';
    }
    if (tool === BoardTools.TEXT) {
      return 'text';
    }
    if (tool === BoardTools.PAN) {
      return 'move';
    }

    return 'default';
  };

  return (
    <TransformComponent
      wrapperStyle={{
        width: '100%',
        height: '100%'
      }}
    >
      <div
        role="main"
        id="cb-whiteboard"
        tabIndex={-1}
        onKeyDown={handleKeyDown}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={handleTouchEnd}
        style={{
          overflow: 'hidden',
          top: 0,
          left: 0,
          cursor: determineCursor(selectedTool),
          width: BOARD_SIZE,
          height: BOARD_SIZE,
          transform: `rotate(${mapRotation}deg)`,
          backgroundColor: backgroundColorWhiteboard
        }}
        ref={drop}
      >
        <svg
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
           
          }}
        >
          <MapLayer
            map={map}
            mapRotation={mapRotation}
          />
        </svg>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: determineLayerOrder(selectedTool).objectLayer
          }}
        >
          <AreasOfEffectLayer />
        </div>
        <svg
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: determineLayerOrder(selectedTool).drawLayer
          }}
        >
          <DrawLayer currentLine={currentLine} mapRotation={mapRotation} />
          <ConnectionLayer
            wrapperScale={wrapperScale}
            currentConnection={currentConnection}
          />
        </svg>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: determineLayerOrder(selectedTool).objectLayer
          }}
        >
          <ObjectLayer
            wrapperScale={wrapperScale}
            wrapperComponent={wrapperComponent}
            wrapperPoint={wrapperPoint}
          />
        </div>
      </div>
    </TransformComponent>
  );
};

export default Whiteboard;
