import EmailIcon from '@mui/icons-material/Email';
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { FC, useState } from 'react';
import { useParams } from 'react-router';
import CustomDialog from 'src/components/CustomDialog';
import { inviteToTeam } from 'src/data/firestore/setters/team';
import useAuth from 'src/hooks/useAuth';
import { RootState, useSelector } from 'src/store';
import { Team } from 'src/types/team';
import { ChipItem } from '../createTeam/CreateTeamStepTwo';
import CustomAvatar from '../CustomAvatar';
import UserSearch from '../UserSearch';
import TeamInviteEmailInput from './TeamInviteEmailInput';

interface TeamInvitesDialogProps {
  open: boolean;
  setDialogOpen: Function;
  inviteUsersViewOpen: boolean;
}

const TeamInvitesDialog: FC<TeamInvitesDialogProps> = (props) => {
  const { open, setDialogOpen, inviteUsersViewOpen } = props;

  const { teamId } = useParams();
  const team: Team = useSelector((state: RootState) => state.teamSlice.team);
  const { user } = useAuth();

  const [invitees, setInvitees] = useState<ChipItem[]>([]);

  const [inviteUsersOpen, setInviteUsersOpen] = useState(inviteUsersViewOpen);

  const [waitingForFunctions, setWaitingForFunctions] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();

  const handleSendInvite = async (
    inviteeUsername: string,
    inviteeEmail: string
  ) => {
    const snackbarName =
      inviteeUsername === null ? inviteeEmail : inviteeUsername;
    await inviteToTeam(
      inviteeUsername,
      inviteeEmail,
      team.displayName,
      team.id,
      user.displayName,
      user.photoURL
    )
      .then(() =>
        enqueueSnackbar(`Sent invite to ${snackbarName}`, {
          variant: 'success'
        })
      )
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(`Sending invite to ${snackbarName} failed`, {
          variant: 'error'
        });
        return null;
      });
  };

  const handleSendInvites = async () => {
    setWaitingForFunctions(true);

    console.log('waiting');

    const promises = invitees.map((invitee) => {
      const inviteeUsername = invitee.username;
      const inviteeEmail = invitee.email;
      return handleSendInvite(inviteeUsername, inviteeEmail);
    });

    await Promise.allSettled(promises);
    setWaitingForFunctions(false);
    setInvitees([]);
    setDialogOpen(false);
    setInviteUsersOpen(false);
  };

  const getIllegalUsernames = () => {
    const pendingInvitesUsernames = team.pendingInvites.map(
      (invite) => invite.inviteeUsername
    );

    const memberUsernames = team.membersInfo.map((info) => info.username);

    return [...pendingInvitesUsernames, ...memberUsernames];
  };

  if (!teamId || (teamId && !team)) {
    return null;
  }

  const onClose = () => {
    if (!waitingForFunctions) {
      setInvitees([]);
      setDialogOpen(false);
      setInviteUsersOpen(inviteUsersViewOpen);
    }
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setDialogOpen}
      title={inviteUsersOpen ? 'Invite a user' : 'Pending invitations'}
      width={650}
      buttons={
        inviteUsersOpen
          ? [
              {
                variant: 'text',
                title: 'Pending invitations',
                onClick: () => {
                  setInvitees([]);
                  setInviteUsersOpen(false);
                },
                disabled: waitingForFunctions
              },
              {
                variant: 'contained',
                title: 'Invite selected Users',
                onClick: handleSendInvites,
                disabled: invitees.length === 0 || waitingForFunctions
              }
            ]
          : [
              {
                variant: 'contained',
                title: 'Invite a User',
                onClick: () => setInviteUsersOpen(true)
              }
            ]
      }
    >
      <Container
        sx={{
          width: '100%',
          height: '100%',
          maxHeight: '750px',
          padding: '0px !important'
        }}
      >
        {inviteUsersOpen && (
          <>
            <Grid
              container
              item
              xs={12}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {waitingForFunctions ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ height: '300px' }}
                >
                  <CircularProgress />
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    <UserSearch
                      invitees={invitees}
                      setInvitees={setInvitees}
                      hideUsernames={getIllegalUsernames()}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        mt: 2
                      }}
                    >
                      <Box sx={{ flexGrow: 1 }}>
                        <Divider orientation="horizontal" />
                      </Box>
                      <Typography
                        color="textSecondary"
                        sx={{ m: 2 }}
                        variant="body1"
                      >
                        OR
                      </Typography>
                      <Box sx={{ flexGrow: 1 }}>
                        <Divider orientation="horizontal" />
                      </Box>
                    </Box>
                    <Typography
                      align="center"
                      variant="body1"
                      sx={{
                        color: `${theme.palette.text.secondary} !important`
                      }}
                    >
                      Invite via email address
                    </Typography>
                    <TeamInviteEmailInput
                      invitees={invitees}
                      setInvitees={setInvitees}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}

        {!inviteUsersOpen && (
          <>
            <Grid container sx={{ px: 0 }}>
              <Grid item xs={12}>
                {team.pendingInvites.length > 0 ? (
                  <List sx={{ height: '100%' }}>
                    {team.pendingInvites.map((invite, i) => (
                      <ListItem
                        key={`${
                          invite.inviteeUsername || invite.inviteeEmail
                        }-key`}
                        sx={{ pt: 1, p: 0, pl: 1 }}
                      >
                        <ListItemAvatar>
                          <CustomAvatar
                            isTeamAvatar={false}
                            src={null}
                            alt={(i + 1).toString()}
                          >
                            {!invite.inviteeUsername && (
                              <EmailIcon sx={{ height: 24, width: 24 }} />
                            )}
                          </CustomAvatar>
                        </ListItemAvatar>

                        <ListItemText
                          primary={
                            invite.inviteeUsername || invite.inviteeEmail
                          }
                          secondary={`invited ${moment(
                            invite.createdAt
                          ).fromNow()}`}
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item sx={{ py: 6 }}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: `${theme.palette.text.secondary} !important`
                        }}
                      >
                        {' '}
                        There are currently no invites pending...{' '}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </CustomDialog>
  );
};

TeamInvitesDialog.propTypes = {
  open: PropTypes.bool,
  setDialogOpen: PropTypes.func,
  inviteUsersViewOpen: PropTypes.bool
};

export default TeamInvitesDialog;
