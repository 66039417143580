import { Button, Typography } from '@mui/material';
import {
  deleteObject,
  ref,
  StorageReference,
  uploadBytesResumable
} from 'firebase/storage';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';
import PropTypes from 'prop-types';
import React, { FC, useEffect, useState } from 'react';
import { storage } from 'src/lib/firebase';
import addSizeToFileName from 'src/utils/addSizeToFileName';
import waitForResizedImageURL from 'src/utils/waitForResizedImageURL';
import ImageCropper from './ImageCropper';

interface ImageUploaderProps {
  setDownloadURL: Function;
  downloadURL: string;
  setImageRef: Function;
  imageRef: StorageReference;
  storagePath: string;
  prefix: string;
  setWaitingForImage?: Function;
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey;
}

// Waits for resized URL and returns null if not received until timeout
export const removeImageByRef = async (imgRef: StorageReference) => {
  try {
    if (imgRef) {
      await deleteObject(imgRef);
    }
  } catch (err) {
    console.error(err[0]);
  }
};

// Uploads images to Firebase Storage
const ImageUploader: FC<ImageUploaderProps> = ({
  setDownloadURL,
  downloadURL,
  setImageRef,
  imageRef,
  storagePath,
  prefix,
  setWaitingForImage,
  enqueueSnackbar
}) => {
  const [uploading, setUploading] = useState(false);
  const [resizing, setResizing] = useState(false);
  const [error, setError] = useState(null);
  const [unformattedImage, setUnformattedImage] = useState(null);
  const [formattedImage, setFormattedImage] = useState(null);
  const [cropperOpen, setCropperOpen] = useState<boolean>(false);

  useEffect(() => {
    if (resizing || uploading) {
      setWaitingForImage(true);
    } else {
      setWaitingForImage(false);
    }
  }, [resizing, uploading]);

  useEffect(() => {
    if (formattedImage) {
      uploadFile();
    }
  }, [formattedImage]);

  // Waits for resized URL and returns null if not received until timeout
  /* tslint:disable:no-await-in-loop */

  // Creates a Firebase Upload Task
  const uploadFile = async () => {
    // Get the file
    const response = await fetch(formattedImage);
    const file = await response.blob();
    const extension = file.type.split('/')[1];

    // Makes reference to the storage bucket location of original image
    const originalFilename = `upload_${prefix}_${Date.now()}`;
    const originalRefPath = `${storagePath}${originalFilename}.${extension}`;

    const originalRef = ref(storage, originalRefPath);

    // Makes reference to the storage bucket location of resized image
    const resizedFilename = addSizeToFileName(originalFilename, '100x100');
    const resizedRefPath = `${storagePath}${resizedFilename}.jpeg`;

    setUploading(true);

    // Starts the upload
    const task = uploadBytesResumable(originalRef, file);

    // Listen for state changes, errors, and completion of the upload.
    task.on(
      'state_changed',

      () => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      },

      (err) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (err.code) {
          case 'storage/unauthorized':
            console.error("User doesn't have permission to access the object");
            enqueueSnackbar('Permission error occured', { variant: 'error' });
            setResizing(false);
            setUploading(false);
            // User doesn't have permission to access the object

            break;
          case 'storage/canceled':
            // User canceled the upload
            console.error('User canceled the upload');
            enqueueSnackbar('Upload canceled', { variant: 'error' });
            setResizing(false);
            setUploading(false);
            break;

          // ...

          default:
            console.error('Unknown error occurred');
            enqueueSnackbar('Unknown error occurred', { variant: 'error' });
            setResizing(false);
            setUploading(false);
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      async () => {
        // Upload completed successfully, now we can get the download URL

        try {
          const resizedImageRef = ref(storage, resizedRefPath);
          const resizedDownloadURL = await waitForResizedImageURL(
            resizedImageRef,
            10
          );

          if (resizedDownloadURL) {
            setDownloadURL(resizedDownloadURL);
            setImageRef(resizedImageRef);
            setUploading(false);
            setResizing(false);
          } else {
            enqueueSnackbar('An error occured', { variant: 'error' });
            setUploading(false);
            setResizing(false);
            enqueueSnackbar('Timeout: Image upload took too long ...', {
              variant: 'error'
            });
            console.error('Timeout: Image upload took too long ...');
          }

          setResizing(false);
        } catch (err) {
          console.error('Error occured while waiting for resultingURL', err);

          enqueueSnackbar('Error occured while waiting for server response', {
            variant: 'error'
          });
        }
      }
    );
  };

  const readFile = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });

  const isFileImage = (file) => {
    const acceptedImageTypes = ['image/png', 'image/jpg', 'image/jpeg'];

    return file && acceptedImageTypes.includes(file.type);
  };

  const handleStartCrop = async (e) => {
    // if file is not an image, return enqueue snackbar(File no image)

    // image to string
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      if (!isFileImage(file)) {
        enqueueSnackbar('Selected file is no image', {
          variant: 'error'
        });
      }

      const imageDataUrl = await readFile(file);

      setUnformattedImage(imageDataUrl);

      // open dialog
      setCropperOpen(true);
    }

    e.target.value = '';
  };

  return (
    <div style={{ width: '100%' }}>
      {(uploading || resizing) && (
        <Typography variant="subtitle2" sx={{ py: 1, px: 2 }}>
          Uploading ...
        </Typography>
      )}

      {!uploading && !resizing && !downloadURL && (
        <Button variant="text" component="label">
          Upload Image
          <input
            type="file"
            onChange={handleStartCrop}
            accept="image/png, image/jpeg,image/jpg"
            hidden
          />
        </Button>
      )}

      {downloadURL && (
        <>
          <Button
            type="button"
            disabled={uploading || resizing}
            variant="text"
            onClick={async () => {
              try {
                await removeImageByRef(imageRef);
                setDownloadURL(null);
                setImageRef(null);
              } catch (err) {
                setError(err);
              }
            }}
          >
            Remove Image
          </Button>
          {error && (
            <Typography variant="subtitle2" sx={{ py: 1, px: 2 }}>
              {error}
            </Typography>
          )}
        </>
      )}

      <ImageCropper
        open={cropperOpen}
        imageSrc={unformattedImage}
        setResult={setFormattedImage}
        setOpen={setCropperOpen}
      />
    </div>
  );
};

ImageUploader.propTypes = {
  setDownloadURL: PropTypes.func,
  setImageRef: PropTypes.func,
  imageRef: PropTypes.any,
  storagePath: PropTypes.string,
  prefix: PropTypes.string,
  setWaitingForImage: PropTypes.func,
  enqueueSnackbar: PropTypes.func
};

export default ImageUploader;
