import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { useTeamSliceLogs } from 'src/constants';
import type { AppThunk } from 'src/store';
import { Team } from 'src/types/team';

interface TeamState {
  team: Team | null;
}

const initialState: TeamState = {
  team: null
};

const slice = createSlice({
  name: 'teamSlice',
  initialState,
  reducers: {
    updateTeam(state: TeamState, action: PayloadAction<{ team: Team }>) {
      const { team } = action.payload;
      state.team = team || null;
    },
    clearTeam(state: TeamState) {
      state.team = initialState.team;
    }
  }
});

export const { reducer } = slice;

export const updateTeam =
  (teamToUpdate: Team, exists: boolean): AppThunk =>
  (dispatch) => {
    if (exists) {
      const response = { team: teamToUpdate };
      dispatch(slice.actions.updateTeam(response));
      if (useTeamSliceLogs) {
        console.log('TEAM UPDATED - ADDED/MODIFIED TEAM:');
        console.log(teamToUpdate);
      }
    } else {
      dispatch(slice.actions.clearTeam());
      if (useTeamSliceLogs) {
        console.log('TEAM UPDATE: - CLEARED TEAM');
      }
    }
  };

export const clearTeam = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.clearTeam());
  if (useTeamSliceLogs) {
    console.log('TEAM CLEARED!');
  }
};

export default slice;
