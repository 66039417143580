import { FC } from 'react';
import { shallowEqual } from 'react-redux';
import useConnections from 'src/hooks/useConnections';
import { RootState, useSelector } from 'src/store';
import { IBoardVisibility, IConnection, IConnections } from 'src/types/board';
import Connection from '../elements/Connection';

interface IProps {
  wrapperScale: number;
  currentConnection: IConnection;
}

const ConnectionLayer: FC<IProps> = (props: IProps) => {
  const { wrapperScale, currentConnection } = props;

  const { visibility } = useSelector(
    (state: RootState) => state.boardSlice.ui,
    shallowEqual
  );

  const connections: IConnections = useConnections();
  const { connectionsVisible }: IBoardVisibility = visibility;

  return (
    <svg
      className="cb-connection-layer"
      style={{
        width: '100%',
        height: '100%',
        display: 'block',
        margin: 'auto'
      }}
    >
      {currentConnection && (
        <Connection
          connection={currentConnection}
          wrapperScale={wrapperScale}
        />
      )}
      {connectionsVisible &&
        connections &&
        Object.entries(connections).map(
          ([key, connection]) =>
            connection && (
              <Connection
                key={key}
                connection={connection}
                wrapperScale={wrapperScale}
              />
            )
        )}
    </svg>
  );
};

export default ConnectionLayer;
