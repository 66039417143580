import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import { TeamInfo } from 'src/types/team';
import CustomAvatar from './CustomAvatar';

interface IProps {
  teams: TeamInfo[];
  selectedTeams: TeamInfo[];
  setSelectedTeams: (value: TeamInfo[]) => void;
  personalSelected: boolean;
  setPersonalSelected: (value: boolean) => void;
}

const TeamList: React.FC<IProps> = (props: IProps) => {
  const {
    teams,
    selectedTeams,
    setSelectedTeams,
    personalSelected,
    setPersonalSelected
  } = props;

  const { user } = useAuth();
  const { settings } = useSettings();

  const handleToggle = (value: TeamInfo) => () => {
    const currentIndex = selectedTeams.indexOf(value);
    const newChecked = [...selectedTeams];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedTeams(newChecked);
  };

  const handlePersonalSelected = () => {
    setPersonalSelected(!personalSelected);
  };

  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {teams
        .filter((team) => team.id !== settings.activeSpaceID)
        .map((value, index) => {
          const labelId = `checkbox-list-label-${value.id}`;

          return (
            <div key={value.id}>
              {index !== 0 && <Divider />}
              <ListItem disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(value)}
                  dense
                  sx={{ pl: 0, pr: 0 }}
                >
                  <ListItemIcon>
                    <CustomAvatar
                      alt={value.displayName}
                      src={value.photoURL}
                      isTeamAvatar
                      sizeFactor={0.725}
                    />
                  </ListItemIcon>

                  <ListItemText
                    id={labelId}
                    primary={value.displayName}
                    secondary="a Valorant Team Space"
                  />
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={selectedTeams.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </div>
          );
        })}
      <Divider />

      {settings.activeSpaceID && (
        <ListItem key="personal-space-option" disablePadding>
          <ListItemButton
            role={undefined}
            onClick={handlePersonalSelected}
            dense
            sx={{ pl: 0, pr: 0 }}
          >
            <ListItemIcon>
              <CustomAvatar
                alt={user.displayName}
                src={user.photoURL}
                sizeFactor={0.725}
                isTeamAvatar
              />
            </ListItemIcon>

            <ListItemText
              primary="Personal Space"
              secondary="your Personal Space"
            />
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={personalSelected}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      )}
    </List>
  );
};

export default TeamList;
