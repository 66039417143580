import { FC, ReactElement } from 'react';
import { ReactZoomPanPinchRef, TransformWrapper } from 'react-zoom-pan-pinch';
import Whiteboard from 'src/components/app/board/Whiteboard';
import { RootState, useSelector } from 'src/store';
import { BoardTools, IPoint } from 'src/types/board';
import ScaleSelector from './tools/ScaleSelector';

const PanWrapper: FC = (): ReactElement => {
  const { selectedTool } = useSelector(
    (state: RootState) => state.boardSlice.ui
  );

  const intialScale = 1.35;

  // TODO: add touch backend
  return (
    <TransformWrapper
      minScale={0.75}
      maxScale={4}
      centerOnInit
      initialScale={intialScale}
      centerZoomedOut
      wheel={{ step: 0.1 }}
      panning={{ disabled: selectedTool !== BoardTools.PAN }}
      doubleClick={{ disabled: selectedTool !== BoardTools.PAN }}
    >
      {(transformWrapper: ReactZoomPanPinchRef) => (
        <>
          <Whiteboard
            wrapperComponent={transformWrapper.instance.wrapperComponent}
            wrapperPoint={
              {
                x: transformWrapper?.state?.positionX,
                y: transformWrapper?.state?.positionY
              } as IPoint
            }
            wrapperScale={transformWrapper?.state?.scale}
          />
          <ScaleSelector
            transformWrapper={transformWrapper}
            initialScale={intialScale}
          />
        </>
      )}
    </TransformWrapper>
  );
};
export default PanWrapper;
