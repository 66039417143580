import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { addCommand } from 'src/slices/board/boardModel';
import store, { RootState, useSelector } from 'src/store';
import {
  ActionTypes,
  BoardTools,
  IAddRemoveConnection,
  ICommand,
  ICommandParams,
  IConnection
} from 'src/types/board';
import createResourceId from 'src/utils/createResourceId';

interface IProps {
  connection: IConnection;
  wrapperScale: number;
}

const Connection: FC<IProps> = (props: IProps) => {
  const { connection, wrapperScale } = props;
  const { playId, teamId } = useParams();
  const sessionId = teamId ? playId : null;
  const {
    id,
    startNode,
    endNode,
    color,
    markerEnd,
    markerStart,
    strokeDashArray,
    strokeWidth,
    opacity
  } = connection;
  const path =
    startNode &&
    endNode &&
    `M${startNode.position?.x} ${startNode.position?.y} L${endNode.position?.x} ${endNode.position?.y}`;

  const { activeStep } = useSelector(
    (state: RootState) => state.boardSlice.model
  );
  const { selectedTool, cursorState } = useSelector(
    (state: RootState) => state.boardSlice.ui
  );

  const handleMouseMove = (mouseEvent): void => {
    if (mouseEvent.button === 0) {
      if (
        selectedTool === BoardTools.ERASE &&
        cursorState === ActionTypes.ERASE
      ) {
        const command: ICommand = {
          id: createResourceId(),
          type: ActionTypes.REMOVE_CONNECTION,
          params: {
            value: {
              connection
            } as IAddRemoveConnection,
            stepLayer: activeStep,
            sessionId,
            playId
          } as ICommandParams,
          inverse: ActionTypes.ADD_CONNECTION
        };
        store.dispatch(addCommand(command));
      }
    }
  };
  // TODO: touchMove event is not recognized yet, find out why
  const handleTouchMove = (): void => {
    if (
      selectedTool === BoardTools.ERASE &&
      cursorState === ActionTypes.ERASE
    ) {
      const command: ICommand = {
        id: createResourceId(),
        type: ActionTypes.REMOVE_CONNECTION,
        params: {
          value: {
            connection
          } as IAddRemoveConnection,
          stepLayer: activeStep,
          sessionId,
          playId
        } as ICommandParams,
        inverse: ActionTypes.ADD_CONNECTION
      };
      store.dispatch(addCommand(command));
    }
  };

  return (
    <>
      <defs>
        <marker
          id={`${id}-arrowhead`}
          viewBox="0 0 10 10"
          refX="1"
          refY="5"
          markerWidth="6"
          markerHeight="6"
          orient="auto"
        >
          <path d="M 0 0 L 10 5 L 0 10 z" fill={connection.color} />
        </marker>
      </defs>

      <path
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseMove}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchMove}
        className="path"
        markerStart={markerStart && `url(#${id}-${markerStart})`}
        markerEnd={markerEnd && `url(#${id}-${markerEnd})`}
        style={{
          fill: 'none',
          strokeWidth: strokeWidth || 1 / wrapperScale,
          opacity: opacity || 0.3,
          stroke: color,
          strokeDasharray: strokeDashArray,
          strokeLinejoin: 'round',
          strokeLinecap: 'round'
        }}
        d={path}
        id={id}
      />
    </>
  );
};

export default Connection;
