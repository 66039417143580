import GroupsIcon from '@mui/icons-material/Groups';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import TitleCard from 'src/components/TitleCard';
import { customOpacityDarkTheme, customOpacityLightTheme } from 'src/constants';
import hexToRGB from 'src/utils/hexToRGB';
import { CreateTeamDialog } from '../createTeam/CreateTeamDialog';

interface OverviewTeamMembersProps {
  padding: number;
}

const OverviewCreatTeam: FC<OverviewTeamMembersProps> = (
  props: OverviewTeamMembersProps
) => {
  const theme = useTheme();
  const [createTeamDialogOpen, setCreateTeamDialogOpen] = useState(false);

  const { padding } = props;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        p: padding
      }}
    >
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Typography color="textSecondary">
          Create a team to collaborate with others and design plays together.
        </Typography>
      </Grid>

      <Button
        variant="contained"
        onClick={() => setCreateTeamDialogOpen(true)}
        sx={{ mt: 3 }}
      >
        Create a Team
      </Button>

      <CreateTeamDialog
        setDialogOpen={setCreateTeamDialogOpen}
        open={createTeamDialogOpen}
      />
    </Grid>
  );
};

export default OverviewCreatTeam;
