import { Box } from '@mui/material';
import type { FC } from 'react';
import { backgroundColorSidebar } from 'src/theme/dark-theme-options';
import Logo from 'src/icons/Logo';

const SplashScreen: FC = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: backgroundColorSidebar,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 2000
    }}
  >
    <Logo sx={{ width: '20%', height: 'auto' }} color="#ffffff" />
  </Box>
);

export default SplashScreen;
