import AddSharpIcon from '@mui/icons-material/AddSharp';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import { Button, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { useParams } from 'react-router';
import CustomDialog from 'src/components/CustomDialog';
import UpgradeSubscriptionDialogContent from 'src/components/UpgradeSubscriptionDialogContent';
import { basicTierMaxPlaybooks, customOpacityDarkTheme } from 'src/constants';
import { useSelector, RootState } from 'src/store';
import { PlaybookInfo } from 'src/types/play';
import { Team } from 'src/types/team';
import { AccountData } from 'src/types/user';
import hexToRGB from 'src/utils/hexToRGB';
import {
  playbookSortByOptions,
  PlaybooksSortByOptions
} from 'src/utils/sortPlaybooks';
import { AccountSettingsDialog } from '../account';
import { CreatePlaybookDialog } from '../createPlaybook';
import { TeamSettingsDialog } from '../team';
import { MultiDeletePlaybooksDialog } from './controls';
import SortByPlaybooksListPopover from './controls/SortByPlaybooksListPopover';

interface IProps {
  selectedPlaybooks: string[];
  setSelectedPlaybooks: Function;
  toggleSelectAllPlaybooks: Function;
  allPlaybooks: PlaybookInfo[];
  selectedSortByOption: PlaybooksSortByOptions;
  setSelectedSortByOption: Function;
}

const PlaybooksListControls: FC<IProps> = (props: IProps) => {
  const {
    selectedPlaybooks,
    toggleSelectAllPlaybooks,
    allPlaybooks,
    setSelectedPlaybooks,
    selectedSortByOption,
    setSelectedSortByOption
  } = props;
  const theme = useTheme();
  const sortByButtonAnchorRef = useRef<HTMLButtonElement | null>(null);
  const [openSortByPopover, setOpenSortByPopover] = useState<boolean>(false);
  const [multiDeletePlaybooksDialogOpen, setMultiDeletePlaybooksDialogOpen] =
    useState<boolean>(false);
  const [createPlaybookDialogOpen, setCreatePlaybookDialogOpen] =
    useState<boolean>(false);

  const [upgradeSubscriptionDialogOpen, setUpgradeSubscriptionDialogOpen] =
    useState<boolean>(false);

  const [subscriptionSettingsOpen, setSubscriptionSettingsOpen] =
    useState<boolean>(false);

  const handleOpenSubscriptionDialog = () => {
    setUpgradeSubscriptionDialogOpen(false);
    setSubscriptionSettingsOpen(true);
  };

  const { teamId } = useParams();

  const team: Team = useSelector((state: RootState) => state.teamSlice.team);

  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );

  const activeWorkspaceData = teamId ? team : accountData;

  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{
        backgroundColor: theme.palette.background.paper,
        p: 1,
        mt: 1,
        borderRadius: `${theme.shape.borderRadius}px`,
        boxShadow: theme.shadows[24],
        border: `1px solid ${theme.palette.divider}`
      }}
    >
      {allPlaybooks?.length > 0 ? (
        <Grid
          item
          sx={{ width: 'auto' }}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ width: 'auto', mr: 1 }}
          >
            <IconButton
              sx={{
                mr: 1,
                backgroundColor: hexToRGB(
                  theme.palette.primary.main,
                  (customOpacityDarkTheme * 2).toString()
                ),
                '&:hover': {
                  backgroundColor: hexToRGB(
                    theme.palette.primary.main,
                    (customOpacityDarkTheme * 4).toString()
                  )
                }
              }}
              size="small"
              onClick={() => toggleSelectAllPlaybooks()}
            >
              {selectedPlaybooks?.length > 0 ? (
                <IndeterminateCheckBoxIcon />
              ) : (
                <CheckBoxOutlineBlankOutlinedIcon />
              )}
            </IconButton>

            <Typography variant="body1" sx={{ p: 0, m: 0 }}>
              {selectedPlaybooks?.length > 0 ? 'Deselect all' : 'Select all'}
            </Typography>
          </Grid>

          <Typography
            sx={{
              mx: 0.5,
              color: `${theme.palette.text.secondary} !important`
            }}
            variant="subtitle1"
          >
            |
          </Typography>

          <Typography
            sx={{
              mx: 0.5,
              color: `${theme.palette.text.secondary} !important`
            }}
            variant="subtitle1"
          >
            {selectedPlaybooks?.length}

            {selectedPlaybooks?.length === 1 && ' Playbook selected'}

            {selectedPlaybooks?.length !== 1 && ' Playbooks selected'}
          </Typography>
        </Grid>
      ) : (
        <Grid item />
      )}

      <Grid
        item
        sx={{ width: 'auto' }}
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {selectedPlaybooks?.length > 0 && (
          <>
            <Button
              variant="text"
              endIcon={<DeleteOutlineIcon />}
              sx={{ ml: 1 }}
              onClick={() => setMultiDeletePlaybooksDialogOpen(true)}
            >
              Delete Playbook{selectedPlaybooks?.length === 1 ? null : 's'}
            </Button>
            <MultiDeletePlaybooksDialog
              open={multiDeletePlaybooksDialogOpen}
              setOpen={setMultiDeletePlaybooksDialogOpen}
              targetPlaybookIDs={selectedPlaybooks}
              setSelected={setSelectedPlaybooks}
            />
          </>
        )}
        <Button
          variant="text"
          endIcon={<AddSharpIcon />}
          sx={{ ml: 1 }}
          onClick={
            activeWorkspaceData.spaceTier === 'basic' &&
            activeWorkspaceData?.playbooksInfo?.length >= basicTierMaxPlaybooks
              ? () => setUpgradeSubscriptionDialogOpen(true)
              : () => setCreatePlaybookDialogOpen(true)
          }
        >
          Create Playbook
        </Button>
        <CreatePlaybookDialog
          setDialogOpen={setCreatePlaybookDialogOpen}
          open={createPlaybookDialogOpen}
          preselectedPlays={[]}
        />
        <CustomDialog
          open={upgradeSubscriptionDialogOpen}
          setOpen={setUpgradeSubscriptionDialogOpen}
          title="Upgrade your space"
          width={400}
          buttons={[
            {
              variant: 'text',
              title: 'Cancel',
              onClick: () => setUpgradeSubscriptionDialogOpen(false)
            },
            {
              variant: 'contained',
              title: 'Upgrade',
              onClick: () => handleOpenSubscriptionDialog()
            }
          ]}
        >
          <UpgradeSubscriptionDialogContent
            blockedContentType="clone_plays"
            isTeamSpace={!(teamId === null)}
          />
        </CustomDialog>

        {teamId ? (
          <TeamSettingsDialog
            initialTab="subscription"
            open={subscriptionSettingsOpen}
            setDialogOpen={setSubscriptionSettingsOpen}
          />
        ) : (
          <AccountSettingsDialog
            initialTab="subscription"
            open={subscriptionSettingsOpen}
            setDialogOpen={setSubscriptionSettingsOpen}
          />
        )}

        <Button
          variant="text"
          endIcon={<KeyboardArrowDownSharpIcon />}
          sx={{ ml: 1 }}
          ref={sortByButtonAnchorRef}
          onClick={() => setOpenSortByPopover(true)}
        >
          {playbookSortByOptions[selectedSortByOption].label}
        </Button>
        <SortByPlaybooksListPopover
          selectedSortByOption={selectedSortByOption}
          setSelectedSortByOption={setSelectedSortByOption}
          anchorRef={sortByButtonAnchorRef}
          open={openSortByPopover}
          setOpen={setOpenSortByPopover}
        />
      </Grid>
    </Grid>
  );
};

export default PlaybooksListControls;
