import {
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  reauthenticateWithCredential,
  reauthenticateWithPopup,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword
} from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { ref } from 'firebase/storage';
import {
  trackResetEmail,
  trackResetPassword
} from 'src/data/mixpanel/setters/trackEvent';
import { auth, functions, storage } from 'src/lib/firebase';
import {
  DeleteAccountInput,
  LoggerInput,
  UpdateProfileDataInput
} from 'src/types/functionsInput';
import serverSideLogger from 'src/logging/serverSideLogger';
import generateMPEventGeneralPropsClientSide from 'src/utils/mixpanelUtils/generateMPEventGeneralPropsClientSide';

const triggerUpdateProfileData = httpsCallable(functions, 'updateProfileData');
const triggerDeleteAccount = httpsCallable(functions, 'deleteAccount');

export const updateAvatarAndDisplayName = async (
  currentDisplayName: string,
  newDisplayName: string,
  currentPhotoURL: string,
  newPhotoURL: string
) => {
  try {
    const getDeletionRef = () => {
      if (newPhotoURL) {
        // check if previous photoURL is a firebase storage url (e.g. not the case if someone just signed up using google)
        if (currentPhotoURL?.includes('firebasestorage.googleapis.com')) {
          return ref(storage, currentPhotoURL).fullPath;
        }
      }
      return null;
    };

    const deletionRef = getDeletionRef();

    const functionInput: UpdateProfileDataInput = {
      mpEventGeneralPropsClient: generateMPEventGeneralPropsClientSide(),
      currentDisplayName,
      newDisplayName,
      currentPhotoURL,
      newPhotoURL,
      deletionRef
    };

    const response = await triggerUpdateProfileData(functionInput);

    return response.data;
  } catch (err) {
    console.error(
      'Error occured while trying to update Display Name & Avatar URL',
      err
    );

    const logObject: LoggerInput = {
      kind: 'error',
      function: 'updateAvatarAndDisplayName',
      message: err,
      metaData: {
        currentDisplayName,
        newDisplayName,
        currentPhotoURL,
        newPhotoURL
      }
    };
    serverSideLogger(logObject);

    throw new Error(
      'Error occured while trying to update Display Name & Avatar URL'
    );
  }
};

export const resetPassword = async (
  email: string,
  oldPassword: string,
  newPassword: string
) => {
  const credential = EmailAuthProvider.credential(email, oldPassword);

  const user = auth.currentUser;

  try {
    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, newPassword);

    trackResetPassword({
      email
    });

    return true;
  } catch (err) {
    console.error('Error occured while trying to reset Password', err);

    const logObject: LoggerInput = {
      kind: 'error',
      function: 'resetPassword',
      message: err,
      metaData: {
        email,
        oldPassword,
        newPassword,
        user
      }
    };
    serverSideLogger(logObject);

    throw new Error('Error occured while trying to reset Password');
  }
};

export const resetEmail = async (newEmail: string, password: string) => {
  const user = auth.currentUser;

  const credential = EmailAuthProvider.credential(user.email, password);
  try {
    await reauthenticateWithCredential(user, credential);
    await updateEmail(user, newEmail);
    await sendEmailVerification(user);

    trackResetEmail({
      new_email: newEmail
    });

    return true;
  } catch (err) {
    console.error('Error occured while trying to update email address', err);
    const logObject: LoggerInput = {
      kind: 'error',
      function: 'resetEmail',
      message: err,
      metaData: {
        newEmail,
        password,
        user
      }
    };
    serverSideLogger(logObject);
    throw new Error('Error occured while trying to update email address');
  }
};

export const sendResetPasswordEmail = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);

    return true;
  } catch (err) {
    console.error(
      'Error occured while trying to send a password reset email',
      err
    );
    const logObject: LoggerInput = {
      kind: 'error',
      function: 'sendResetPasswordEmail',
      message: err,
      metaData: {
        email
      }
    };
    serverSideLogger(logObject);
    throw new Error(
      'Error occured while trying to send a password reset email'
    );
  }
};

export const deleteAccount = async (
  providerList: string[],
  currentEmail?: string,
  password?: string
) => {
  try {
    const user = auth.currentUser;

    let reauthResponse = null;

    if (providerList.includes('password')) {
      const emailProvider = await EmailAuthProvider.credential(
        currentEmail,
        password
      );
      reauthResponse = await reauthenticateWithCredential(user, emailProvider);
    } else if (providerList.includes('google.com')) {
      const googleProvider = new GoogleAuthProvider();
      reauthResponse = await reauthenticateWithPopup(user, googleProvider);
    } else if (providerList.includes('facebook.com')) {
      const facebookProvider = new FacebookAuthProvider();
      reauthResponse = await reauthenticateWithPopup(user, facebookProvider);
    }

    if (reauthResponse && reauthResponse.user) {
      const deleteAccountInput: DeleteAccountInput = {
        mpEventGeneralPropsClient: generateMPEventGeneralPropsClientSide()
      };

      await triggerDeleteAccount(deleteAccountInput);
    }

    return true;
  } catch (err) {
    console.error(err);
    const logObject: LoggerInput = {
      kind: 'error',
      function: 'deleteAccount',
      message: err,
      metaData: {
        providerList,
        currentEmail,
        password
      }
    };
    serverSideLogger(logObject);
    throw new Error(`Error occured while trying to delete Account:${err}`);
  }
};
