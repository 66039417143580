import {
  Box,
  Button,
  Badge,
  ListItemProps,
  styled,
  Container,
  Dialog,
  DialogContent,
  Grid,
  ListItem,
  Typography,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import { RootState, useSelector } from 'src/store';
import { AccountData } from 'src/types/user';
import AnnouncementsContent from './announcements/announcementsContent';
import { BugsDialogContent } from './app/bugs';
import { FeedbackDialogContent } from './app/feedback';
import { HelpDialogContent } from './app/help';
import { PersonalInvitesDialog } from './app/personal';
import { PrivacyDialogContent } from './app/privacy';
import CustomDialog from './CustomDialog';

interface NavDialogProps extends ListItemProps {
  icon: ReactNode;
  title: string;
  dialog: string;
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 8,
    right: -24,
    color: '#fff !important',
    backgroundColor: `${theme.palette.error.main} !important`,
    fontSize: 10,
    height: 17,
    minWidth: 15,
    borderRadius: '4px !important',
    fontWeight: '800 !important'
  }
}));

const NavDialog: FC<NavDialogProps> = (props) => {
  const { icon, title, dialog } = props;
  const { logout } = useAuth();
  const { playId, teamId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);

  const [notificationsOpen, setNotificationsOpen] = useState<boolean>(false);

  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );

  const { settings } = useSettings();

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      // check for session
      await logout(playId && teamId ? playId : null);
      navigate('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };
  const selectDialogTitle = (selectedDialog: string) => {
    if (selectedDialog === 'help') {
      return 'Need Help ?';
    }

    if (selectedDialog === 'feedback') {
      return 'Give Feedback';
    }

    if (selectedDialog === 'bugs') {
      return 'Report a Bug';
    }

    if (selectedDialog === 'privacy') {
      return 'Privacy Policy';
    }
    if (selectedDialog === 'announcements') {
      return 'Announcements';
    }

    return 'Sign out';
  };

  const selectDialogContent = (selectedDialog: string) => {
    if (selectedDialog === 'help') {
      return <HelpDialogContent />;
    }

    if (selectedDialog === 'feedback') {
      return <FeedbackDialogContent onClose={handleClose} />;
    }

    if (selectedDialog === 'bugs') {
      return <BugsDialogContent onClose={handleClose} />;
    }

    if (selectedDialog === 'privacy') {
      return <PrivacyDialogContent onClose={handleClose} />;
    }

    if (selectedDialog === 'announcements') {
      return <AnnouncementsContent />;
    }

    return (
      <Grid container sx={{ p: 1 }}>
        <Grid item>
          <Typography variant="body1" color="textSecondary">
            Would you like to log out ? Alternatively you can stay logged in to
            retain quick access to your critbase-spaces.{' '}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  // Leaf
  return (
    <>
      {(dialog !== 'notifications' ||
        (dialog === 'notifications' &&
          accountData?.pendingInvites.length > 0)) && (
        <ListItem
          disableGutters
          sx={{
            display: 'flex',
            py: 0,
            px: 0
          }}
        >
          <Button
            onClick={() =>
              dialog !== 'notifications'
                ? setOpen(true)
                : setNotificationsOpen(true)
            }
            sx={{
              justifyContent: 'flex-start',
              textAlign: 'left',
              my: 0.9,
              mx: 1.25,
              px: 2.5,
              height: 33,
              textTransform: 'none',
              width: '100%',
              backgroundColor: 'transparent',
              borderRadius: `${theme.shape.borderRadius}px`,
              ':hover': { backgroundColor: theme.palette.action.hover }
            }}
            size="large"
            variant="text"
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: 22,
                  height: 22
                }}
              >
                {React.cloneElement(icon as React.ReactElement<any>, {
                  sx: { fontSize: 16, fill: theme.palette.text.secondary }
                })}
              </Grid>

              <Box>
                {dialog !== 'notifications' ? (
                  <Typography
                    variant="h5"
                    sx={{
                      pl: 2.5,
                      color: `${theme.palette.text.secondary} !important`
                    }}
                  >
                    {title}
                  </Typography>
                ) : (
                  <StyledBadge
                    color="secondary"
                    badgeContent={accountData?.pendingInvites.length}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        pl: 2.5,
                        color: `${theme.palette.text.secondary} !important`
                      }}
                    >
                      {title}
                    </Typography>
                  </StyledBadge>
                )}
              </Box>
            </Grid>
          </Button>

          {dialog !== 'notifications' && (
            <CustomDialog
              open={open}
              setOpen={setOpen}
              title={selectDialogTitle(dialog)}
              width={dialog === 'signout' ? 350 : 700}
              buttons={
                dialog === 'signout'
                  ? [
                      {
                        variant: 'text',
                        title: 'Cancel',
                        onClick: () => setOpen(false)
                      },
                      {
                        variant: 'contained',
                        title: 'Sign out',
                        onClick: handleLogout
                      }
                    ]
                  : []
              }
            >
              {' '}
              {selectDialogContent(dialog)}
            </CustomDialog>
          )}
        </ListItem>
      )}

      {notificationsOpen && (
        <PersonalInvitesDialog
          open={notificationsOpen}
          setDialogOpen={setNotificationsOpen}
        />
      )}
    </>
  );
};

NavDialog.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dialog: PropTypes.string.isRequired
};

export default NavDialog;
