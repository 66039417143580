import SaveIcon from '@mui/icons-material/Save';
import { ListItemButton, ListItemIcon, useTheme } from '@mui/material';
import { FC, ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomDialog from 'src/components/CustomDialog';
import CustomTooltip from 'src/components/CustomTooltip';
import UpgradeSubscriptionDialogContent from 'src/components/UpgradeSubscriptionDialogContent';
import { basicTierMaxPlays, customOpacityDarkTheme } from 'src/constants';
import useGlobalDOMEvents from 'src/hooks/useGlobalDOMEvents';
import { useSelector, RootState } from 'src/store';
import { Team } from 'src/types/team';
import { AccountData } from 'src/types/user';
import hexToRGB from 'src/utils/hexToRGB';
import SavePlayForm from './SavePlayForm';

const SavePlayButton: FC = (): ReactElement => {
  const [savePlayFormOpen, setSavePlayFormOpen] = useState(false);
  const [upgradeSubscriptionDialogOpen, setUpgradeSubscriptionDialogOpen] =
    useState<boolean>(false);

  const theme = useTheme();

  const { teamId } = useParams();

  const team: Team = useSelector((state: RootState) => state.teamSlice.team);

  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );

  const activeWorkspaceData = teamId ? team : accountData;

  // keyboard event listeners
  useGlobalDOMEvents({
    keydown(e: KeyboardEvent) {
      // CTRL + 'S', open save play form
      if (e.key.toLowerCase() === 's' && e.ctrlKey) {
        e.preventDefault();
        if (
          activeWorkspaceData.spaceTier === 'basic' &&
          activeWorkspaceData.playsInfo?.length >= basicTierMaxPlays
        ) {
          setUpgradeSubscriptionDialogOpen(true);
        } else {
          setSavePlayFormOpen(true);
        }
      }
    }
  });

  const handleOpenSavePlayForm = () => {
    if (
      activeWorkspaceData.spaceTier === 'basic' &&
      activeWorkspaceData.playsInfo?.length >= basicTierMaxPlays
    ) {
      setUpgradeSubscriptionDialogOpen(true);
    } else {
      setSavePlayFormOpen(true);
    }
  };

  const handleCloseSavePlayForm = () => {
    setUpgradeSubscriptionDialogOpen(false);
    setSavePlayFormOpen(false);
  };

  return (
    <>
      <CustomTooltip title="Save play" subtitle="CTRL + S" placement="left">
        <ListItemButton
          key="save"
          sx={{
            height: '35px',
            maxHeight: '35px',
            margin: theme.spacing(0.75),
            borderRadius: `${theme.shape.borderRadius}px`,

            p: '9px',
            backgroundColor: hexToRGB(
              theme.palette.primary.main,
              (customOpacityDarkTheme * 2.5).toString()
            ),
            '&:hover': {
              backgroundColor: hexToRGB(
                theme.palette.primary.main,
                (customOpacityDarkTheme * 5).toString()
              )
            }
          }}
          onClick={handleOpenSavePlayForm}
        >
          <ListItemIcon>
            <SaveIcon style={{ fill: theme.palette.secondary.main }} />
          </ListItemIcon>
        </ListItemButton>
      </CustomTooltip>

      <SavePlayForm onClose={handleCloseSavePlayForm} open={savePlayFormOpen} />

      <CustomDialog
        open={upgradeSubscriptionDialogOpen}
        setOpen={setUpgradeSubscriptionDialogOpen}
        title="Upgrade your space"
        width={400}
        buttons={[
          {
            variant: 'text',
            title: 'Cancel',
            onClick: () => setUpgradeSubscriptionDialogOpen(false)
          }
        ]}
      >
        <UpgradeSubscriptionDialogContent
          blockedContentType="create_play"
          isTeamSpace={!(teamId === null)}
        />
      </CustomDialog>
    </>
  );
};

export default SavePlayButton;
