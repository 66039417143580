import { combineReducers } from '@reduxjs/toolkit';
import { reducer as boardUiReducer } from './boardUi';
import { reducer as boardModelReducer } from './boardModel';

const reducer = combineReducers({
  ui: boardUiReducer,
  model: boardModelReducer
});

export { reducer };

export default reducer;
