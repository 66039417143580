import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { FC } from 'react';
import FileDropzone from 'src/components/FileDropzone';
import { reportBug } from 'src/data/firestore/setters/bug';
import useAuth from 'src/hooks/useAuth';
import { storage } from 'src/lib/firebase';
import * as Yup from 'yup';

interface IProps {
  onClose: () => void;
}

const MAX_FILE_COUNT = 3;
const FILE_SIZE = 5 * 1024 * 1024; // 5MB
const SUPPORTED_FORMATS = ['image/jpeg', 'image/png'];

const checkIfFilesAreTooBig = (files?: [File]): boolean => {
  let valid = true;
  if (files) {
    files.forEach((file) => {
      if (file.size > FILE_SIZE) {
        valid = false;
      }
    });
  }
  return valid;
};

const checkIfFilesAreCorrectType = (files?: [File]): boolean => {
  let valid = true;
  if (files) {
    files.forEach((file) => {
      if (!SUPPORTED_FORMATS.includes(file.type)) {
        valid = false;
      }
    });
  }
  return valid;
};

const readFile = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });

const BugsContact: FC<IProps> = (props: IProps) => {
  const { onClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { user } = useAuth();

  const uploadImages = (files: [File]): Promise<string[]> => {
    const prefix = 'bug_report';
    const storagePath = `users/${user.id}/bug_reports/`;

    const downloadUrls = files.map(async (img, key) => {
      const dataUrl: any = await readFile(img);
      const response = await fetch(dataUrl);
      const file = await response.blob();
      const extension = file.type.split('/')[1];
      // Makes reference to the storage bucket location of original image
      const originalFilename = `upload_${prefix}_${Date.now()}_${key}`;
      const originalRefPath = `${storagePath}${originalFilename}.${extension}`;

      const originalRef = ref(storage, originalRefPath);

      try {
        // Starts the upload
        await uploadBytes(originalRef, file);
        // get download url
        const downloadResult = await getDownloadURL(originalRef);
        return downloadResult;
      } catch (err) {
        enqueueSnackbar(
          'Uploading your attached image to the bug report failed. Please try again.',
          { variant: 'error' }
        );
        return null;
      }
    });
    return Promise.all(downloadUrls);
  };

  const onSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting, resetForm }
  ) => {
    try {
      if (values.files.length > 0) {
        const photoUrls: string[] = await uploadImages(values.files);
        if (photoUrls) {
          await reportBug(values.description, photoUrls);

          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar('You successfully reported a bug.', {
            variant: 'success'
          });

          if (onClose) {
            onClose();
          }
        }
      } else {
        await reportBug(values.description);

        resetForm();
        setStatus({ success: true });
        setSubmitting(false);
        enqueueSnackbar('You successfully reported a bug.', {
          variant: 'success'
        });

        if (onClose) {
          onClose();
        }
      }
    } catch (err) {
      setStatus({ success: false });
      setErrors({
        submit: 'Something went wrong reporting your bug. Please try again.'
      });
      setSubmitting(false);
      enqueueSnackbar(
        'Something went wrong reporting your bug. Please try again.',
        { variant: 'error' }
      );
    }
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          color="textPrimary"
          gutterBottom
          variant="body1"
          sx={{ pb: 3, pt: 1 }}
        >
          Found a bug ? Please describe it as detailed as possible. It is useful
          for us to know what exactly you did to find this bug. If possible,
          please attach screenshots. Thanks for helping us improve !
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Formik
          enableReinitialize
          initialValues={{
            description: '',
            files: [],
            submit: null
          }}
          validationSchema={Yup.object().shape({
            description: Yup.string()
              .min(10, 'Must be atleast 10 characters long')
              .max(1000, 'Cannot be longer than 1000 characters')
              .required('A description is required'),
            files: Yup.array()
              .nullable()
              .max(MAX_FILE_COUNT, 'Only 3 attachments are allowed')
              .test(
                'is-correct-file',
                'One of your files is too bug. Maximum size is 5MB',
                checkIfFilesAreTooBig
              )
              .test(
                'is-big-file',
                'One of your files has the wrong type. Only *.png and *.jpeg are allowed',
                checkIfFilesAreCorrectType
              )
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values
          }): JSX.Element => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12} sx={{ my: 1 }}>
                  <FileDropzone
                    isSubmitting={isSubmitting}
                    onDrop={(acceptedFiles) => {
                      setFieldValue('files', acceptedFiles);
                    }}
                    maxFiles={3}
                    files={values.files}
                    disabled={values.files.length > MAX_FILE_COUNT}
                    onRemove={(file) =>
                      setFieldValue(
                        'files',
                        values.files.filter((item) => item !== file)
                      )
                    }
                    onRemoveAll={() => setFieldValue('files', [])}
                  />
                  {errors.files && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>{errors.files}</FormHelperText>
                    </Box>
                  )}
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      helperText={
                        (touched.description && errors.description) ||
                        'Describe the bug as detailed as possible'
                      }
                      label="Bug Description"
                      name="description"
                      rows={4}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      variant="outlined"
                      multiline
                    />
                  </Grid>
                  {errors.submit && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Box>
                  )}
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <LoadingButton
                  color="primary"
                  loading={isSubmitting}
                  onClick={() => handleSubmit()}
                  type="submit"
                  variant="contained"
                  sx={{ borderRadius: `${theme.shape.borderRadius}px`, mt: 3 }}
                >
                  Report Bug
                </LoadingButton>
              </Box>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default BugsContact;
