import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deletePlaybook } from 'src/data/firestore/setters/playbook';

interface IProps {
  open: boolean;
  setOpen: Function;
  targetPlaybookIDs: string[];
  setSelected: Function;
}

const MultiDeletePlaybooksDialog: FC<IProps> = (props: IProps) => {
  const { open, setOpen, targetPlaybookIDs, setSelected } = props;
  const [waitingForFunctionResponse, setWaitingForFunctionResponse] =
    useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { teamId } = useParams();
  const handleClickCancel = () => {
    setOpen(false);
  };

  // only for cleanup
  useEffect(() => () => null, []);

  const handleDeletePlaybook = async (id, i) => {
    try {
      if (teamId) {
        await deletePlaybook(id, true, teamId);
      } else {
        await deletePlaybook(id, true);
      }

      enqueueSnackbar(
        `Deleted playbook ${i + 1} of ${targetPlaybookIDs.length}`,
        {
          variant: 'success'
        }
      );

      return await Promise.resolve();
    } catch (err) {
      console.error(
        `Deleting playbook ${i + 1} of ${targetPlaybookIDs.length} failed:`,
        err
      );
      enqueueSnackbar(
        `Deleting playbook ${i + 1} of ${targetPlaybookIDs.length} failed`,
        {
          variant: 'error'
        }
      );
      return await Promise.reject();
    }
  };

  const handleClickDeletePlaybooks = async () => {
    setWaitingForFunctionResponse(true);

    const promises: Promise<void>[] = targetPlaybookIDs.map(async (id, i) =>
      handleDeletePlaybook(id, i)
    );

    await Promise.allSettled(promises);

    setSelected([]);
    setWaitingForFunctionResponse(false);
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        if (!waitingForFunctionResponse) {
          setOpen(false);
        }
      }}
    >
      <DialogContent sx={{ height: 'auto', maxHeight: '750px' }}>
        <Container
          sx={{
            width: '100%',
            height: '100%',
            py: 2
          }}
        >
          {waitingForFunctionResponse ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ height: '150px' }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={12}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sx={{ mt: 3, mb: 4 }}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  You are about to delete {targetPlaybookIDs.length} playbook
                  {targetPlaybookIDs.length === 1 ? null : 's'}. This will NOT
                  delete the plays included in the playbook
                  {targetPlaybookIDs.length === 1 ? null : 's'}! Do you wish to
                  continue ?
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  sx={{}}
                  variant="text"
                  onClick={() => handleClickCancel()}
                  disabled={waitingForFunctionResponse}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  variant="contained"
                  color="error"
                  onClick={() => handleClickDeletePlaybooks()}
                  disabled={waitingForFunctionResponse}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          )}
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default MultiDeletePlaybooksDialog;
