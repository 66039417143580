export enum Maps {
  ASCENT = 'ascent',
  BIND = 'bind',
  BREEZE = 'breeze',
  FRACTURE = 'fracture',
  HAVEN = 'haven',
  ICEBOX = 'icebox',
  SPLIT = 'split',
  PEARL = 'pearl',
  LOTUS = 'lotus',
  SUNSET = 'sunset'
}

export enum TeamSides {
  ATTACK = 'ATTACK',
  DEFEND = 'DEFEND'
}

export enum ActionTypes {
  WRITE = 'WRITE',
  CLEAR = 'CLEAR',
  DRAW = 'DRAW',
  ERASE = 'ERASE',
  ADD_OBJECT = 'ADD_OBJECT',
  REMOVE_OBJECT = 'REMOVE_OBJECT',
  REVERT_OBJECT = 'REVERT_OBJECT',
  SELECT_OBJECT = 'SELECT_OBJECT',
  CHANGE_OBJECT = 'CHANGE_OBJECT',
  ADD_CONNECTION = 'ADD_CONNECTION',
  REMOVE_CONNECTION = 'REMOVE_CONNECTION',
  REVERT_CONNECTION = 'REVERT_CONNECTION',
  SELECT_CONNECTION = 'SELECT_CONNECTION',
  CHANGE_CONNECTION = 'CHANGE_CONNECTION'
}

export enum ObjectTypes {
  SPIKE = 'SPIKE',
  CROSS = 'CROSS',
  PIN = 'PIN',
  SKULL = 'SKULL'
}

export enum AgentTypes {
  ASTRA = 'ASTRA',
  BREACH = 'BREACH',
  BRIMSTONE = 'BRIMSTONE',
  CHAMBER = 'CHAMBER',
  CYPHER = 'CYPHER',
  FADE = 'FADE',
  JETT = 'JETT',
  OMEN = 'OMEN',
  PHOENIX = 'PHOENIX',
  RAZE = 'RAZE',
  REYNA = 'REYNA',
  SAGE = 'SAGE',
  SOVA = 'SOVA',
  VIPER = 'VIPER',
  KAYO = 'KAYO',
  KILLJOY = 'KILLJOY',
  SKYE = 'SKYE',
  YORU = 'YORU',
  NEON = 'NEON',
  HARBOR = 'Harbor',
  GEKKO = 'Gekko',
  DEADLOCK = 'Deadlock'
}

export enum DragTypes {
  BASIC = 'BASIC',
  AGENT = 'AGENT',
  ABILITY = 'ABILITY',
  TEXTAREA = 'TEXTAREA',
  ARROW = 'ARROW'
}

export interface IDropItem {
  value: string;
  attack: TeamSides;
  startNode: INode;
}

export interface IAreaOfEffect {
  hidden: boolean;
  dx: number; // distance from object coordinate to end of AOE (x axis)
  dy: number; // distance from object coordinate to end of AOE (y axis)
}

export enum BoardTools {
  PAN = 'pan',
  SELECT = 'select',
  DRAW = 'draw',
  ERASE = 'erase',
  OBJECT = 'object',
  TEXT = 'text',
  ARROW = 'arrow'
}
export enum BoardVisibilities {
  MAP_BASE = 'mapBase',
  MAP_OBJECTS = 'mapObjects',
  MAP_AREAS = 'mapAreas',
  MAP_LABELS = 'mapLabels',
  LINES = 'lines',
  OBJECTS = 'objects',
  CONNECTIONS = 'connections',
  AREASOFEFFECT = 'areasOfEffect'
}

export enum TextOptions {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
  STRIKETHROUGH = 'STRIKETHROUGH',
  UNDERLINE = 'UNDERLINE'
}

export interface TextFormat {
  fontFormat?: TextOptions[];
  fontSize?: number;
  fontColor?: string;
}

export interface IObject {
  type: DragTypes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: ObjectTypes | AgentTypes | any;
  specs?: {
    [key: string]: TextFormat; // for example : TEXTAREA etc..
  };
  attack?: TeamSides;
  connections: string[]; // array of connection ids, we need this to update the position of end and start nodes TODO: remove ? , if not possible try to merge IObject with INode
  position: IPoint;
  id: string;
  areaOfEffect?: IAreaOfEffect;
}

export interface INode {
  position: IPoint;
  id: string;
}

export interface IConnection {
  color: string;
  startNode: INode;
  endNode: INode;
  id: string;
  markerStart?: string;
  markerEnd?: string;
  strokeWidth?: number;
  strokeDashArray?: string;
  opacity?: number;
}

export interface IDrawingLine {
  path: string;
  points?: IPoint[];
  color: string;
  strokeWidth: number;
  strokeDashArray: string;
  id: string;
  showDistance: boolean;
}

export interface ICommandLayer {
  id: string;
  commandsHistory: string[];
  commandsFuture: string[];
}

export interface IStepLayer {
  id: number;
  lines: string[];
  objects: string[];
  connections: string[];
  commandsHistory?: string[]; // not stored in firestore / rt db
  commandsFuture?: string[];
}

export interface ICommands {
  [key: string]: ICommand;
}

export interface IPoint {
  x: number;
  y: number;
}

export interface IDrawingLines {
  [key: string]: IDrawingLine;
}
export interface IObjects {
  [key: string]: IObject;
}

export interface IConnections {
  [key: string]: IConnection;
}
export interface IStepLayers {
  [key: string]: IStepLayer;
}

export interface ICommandLayers {
  [key: string]: ICommandLayer;
}
export interface IBoard {
  stepLayers: IStepLayers;
  lines: IDrawingLines;
  objects: IObjects;
  connections: IConnections;
  map: Maps;
  commands?: ICommands;
  activeStep?: number;
  mapRotation: number; // default 0 is attack on bottom
}

export interface ControllerFunctions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (prevState: IBoard, params: any) => IBoard;
}

export interface IAddRemoveValue {
  object: IObject;
  connections?: IConnections;
}

export interface IAddRemoveConnection {
  connection: IConnection;
}
export interface IMoveConnectionValue {
  oldConnection: IConnection;
  newConnection: IConnection;
}

export interface IMoveValue {
  oldObj: IObject;
  newObj: IObject;
}

export interface ICommandParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any; // needs to be any use interfaces above for type safety
  stepLayer?: number;
  sessionId?: string;
  playId?: string;
}

export interface ICommand {
  id: string;
  type: ActionTypes;
  params: ICommandParams;
  inverse: ActionTypes;
}

export interface IBoardVisibility {
  mapBaseVisible: boolean;
  mapObjectsVisible: boolean;
  mapAreasVisible: boolean;
  mapLabelsVisible: boolean;
  linesVisible: boolean;
  objectsVisible: boolean;
  connectionsVisible: boolean;
  areasOfEffectVisible: boolean;
  // TODO: what layer are visible? for multi-step strategies. needs to be decoupled from actual layers
  // TODO: visibility of agents etc.
}

export interface ILineOptions {
  color: string;
  strokeWidth: number;
  strokeDashArray: string;
  showDistance: boolean;
  markerStart?: string;
  markerEnd?: string;
}
export interface IUIBoard {
  visibility: IBoardVisibility;
  lineOptions: ILineOptions;
  arrowOptions: ILineOptions;
  selectedTool: BoardTools;
  selectedObject: string;
  cursorState: ActionTypes;
  lastSaveTimestamp: number;
}

export interface ICommandsState {
  ui: IUIBoard;
  model: IBoard;
}

export interface AOEObject {
  svg: JSX.Element;
  masked: boolean;
  rotatable: boolean;
  maxLength: number;
  minLength: number; // if minLength === maxLength -> not scalable
  defaultLength: number; // minLength <= defaultLength <= maxlength
  originYMovementPercentage: number; // 0-100
  width: number | 'auto'; // if "auto" --> maintain originial aspectRatio of the svg
  multiplySvgLength?: number; // e.g. 2 -> 2x svg length (only changes displayed size, vector stays the same)
}
