import client from '@sendgrid/client';
import { ClientRequest } from '@sendgrid/client/src/request';
import { sendGridConfig } from 'src/config';

const addContact = async (
  mail: string,
  listIds: string[],
  cb?
): Promise<any> => {
  client.setApiKey(sendGridConfig.apiKey);

  const data = {
    list_ids: listIds,
    contacts: [
      {
        email: mail
      }
    ]
  };

  const request: ClientRequest = {
    url: `/v3/marketing/contacts`,
    method: 'PUT',
    body: data
  };

  try {
    const response = await client.request(request, cb);

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const removeContact = async (
  contactId: string,
  listId: string
): Promise<any> => {
  client.setApiKey(sendGridConfig.apiKey);

  const queryParams = {
    contact_ids: contactId
  };

  const request: ClientRequest = {
    url: `/v3/marketing/lists/${listId}/contacts`,
    method: 'DELETE',
    qs: queryParams
  };

  try {
    await client.request(request);
  } catch (error) {
    console.error(error);
  }
};

const getContactInLists = async (
  mail: string,
  listIds: string[]
): Promise<any> => {
  client.setApiKey(sendGridConfig.apiKey);

  const data = {
    query: `email = LOWER('${mail}') AND CONTAINS(list_ids, '${listIds}')`
  };

  const request: ClientRequest = {
    url: `/v3/marketing/contacts/search`,
    method: 'POST',
    body: data
  };

  try {
    // [response, body]
    const result = await client.request(request);
    return result;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export { addContact, getContactInLists, removeContact };
