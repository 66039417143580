import { Box, Input, Theme, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomTooltip from 'src/components/CustomTooltip';
import {
  DEFAULT_TEXTAREA_FONT_COLOR,
  DEFAULT_TEXTAREA_FONT_SIZE,
  LINE_COLOR_OPTIONS
} from 'src/constants';
import { addCommand } from 'src/slices/board/boardModel';
import { setCursorState } from 'src/slices/board/boardUi';
import store from 'src/store';
import {
  ActionTypes,
  DragTypes,
  ICommand,
  ICommandParams,
  IObject,
  TextFormat,
  TextOptions
} from 'src/types/board';
import createResourceId from 'src/utils/createResourceId';
import hexToRGB from 'src/utils/hexToRGB';

interface IProps {
  object: IObject;
  selected: boolean;
  edit: boolean;
  isDragging?: boolean;
  onEditClick: (val: boolean) => void;
  wrapperScale: number;
  mapRotation: number;
}

const MAX_WIDTH = 150;
const placeholder = 'Double click to edit text ';
const TextArea = React.forwardRef<any, IProps>((props: IProps, ref) => {
  const {
    selected,
    edit,
    onEditClick,
    object,
    isDragging,
    wrapperScale,
    mapRotation
  } = props;
  const { id, value, specs } = object;

  const theme: Theme = useTheme();

  const [text, setText] = useState<string>(value);
  const { playId, teamId } = useParams();
  const sessionId = teamId ? playId : null;

  const textFormat: TextFormat = specs?.[DragTypes.TEXTAREA];
  const { fontFormat, fontSize, fontColor } = textFormat || {
    fontFormat: [],
    fontSize: DEFAULT_TEXTAREA_FONT_SIZE,
    fontColor: DEFAULT_TEXTAREA_FONT_COLOR
  };
  useEffect(() => {
    if (value !== text) {
      setText(value);
    }
  }, [value]);

  useEffect(() => {
    if (text !== object.value) {
      const newObj: IObject = {
        ...object,
        value: text
      };

      const oldObj: IObject = {
        ...object
      };
      const command: ICommand = {
        id: createResourceId(),
        type: ActionTypes.CHANGE_OBJECT,
        params: {
          value: {
            newObj,
            oldObj
          },
          stepLayer: store.getState().boardSlice.model.activeStep,
          sessionId,
          playId
        } as ICommandParams,
        inverse: ActionTypes.REVERT_OBJECT
      };

      store.dispatch(addCommand(command));
      store.dispatch(setCursorState(null));
    }
  }, [selected === true && edit === true]);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleDoubleClick = () => {
    onEditClick(true);
  };

  const determineBorder = () => {
    if (selected || edit) {
      return `${1 / wrapperScale}px solid ${
        theme.palette.primary.contrastText
      }`;
    }

    return '1px solid transparent';
  };

  const determineFontWeight = (format: TextOptions[]) => {
    if (format?.includes(TextOptions.BOLD)) {
      return 'bold';
    }
    return 500;
  };
  const determineFontStyle = (format: TextOptions[]) => {
    if (format?.includes(TextOptions.ITALIC)) {
      return 'italic';
    }
    return undefined;
  };
  const determineTextDecoration = (format: TextOptions[]) => {
    if (
      format?.includes(TextOptions.STRIKETHROUGH) &&
      format?.includes(TextOptions.UNDERLINE)
    ) {
      return 'line-through underline';
    }
    if (format?.includes(TextOptions.STRIKETHROUGH)) {
      return 'line-through';
    }
    if (format?.includes(TextOptions.UNDERLINE)) {
      return 'underline';
    }
    return undefined;
  };

  const determineTextColor = (color: string) => {
    if (!text || text === '') {
      return theme.palette.text.secondary;
    }

    return color;
  };

  return (
    <Box
      className="cb-textarea"
      id={`${value}-${id}`}
      ref={ref}
      sx={{
        transform: `translate(-50%, -50%) rotate(-${mapRotation}deg)`,
        border: determineBorder(),
        padding: 0.5,
        borderRadius: `${(theme.shape.borderRadius as number) / 2}px`,
        backgroundColor:
          fontColor === LINE_COLOR_OPTIONS[1]
            ? hexToRGB(theme.palette.primary.main, '0.3')
            : hexToRGB(theme.palette.background.default, '0.7'),
        lineHeight: 0,
        cursor: isDragging ? 'grabbing' : 'pointer'
      }}
    >
      {!edit ? (
        <CustomTooltip title={placeholder} placement="bottom">
          <Typography
            onDoubleClick={handleDoubleClick}
            fontWeight={500}
            sx={{
              fontSize: { fontSize },
              fontStyle: determineFontStyle(fontFormat),
              textDecoration: determineTextDecoration(fontFormat),
              fontWeight: determineFontWeight(fontFormat),
              maxWidth: MAX_WIDTH,
              overflowWrap: 'break-word',
              opacity: isDragging ? '0.9' : 1,
              whiteSpace: 'pre-wrap',
              wordSpacing: '0px',
              lineHeight: 1.5
            }}
            style={{
              color: determineTextColor(fontColor)
            }}
          >
            {text || `${placeholder}${' '}`}
          </Typography>
        </CustomTooltip>
      ) : (
        <Input
          className="cb-textinput"
          sx={{
            fontSize: { fontSize },
            fontStyle: determineFontStyle(fontFormat),
            textDecoration: determineTextDecoration(fontFormat),
            fontWeight: determineFontWeight(fontFormat),
            lineHeight: 1.5,
            maxWidth: MAX_WIDTH,
            minWidth: 100,
            zIndex: 10000
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          style={{
            color: determineTextColor(fontColor),
            margin: 0,
            padding: 0
          }}
          fullWidth
          multiline
          value={text}
          onChange={handleChange}
          autoFocus
          disableUnderline
          placeholder={placeholder}
        />
      )}
    </Box>
  );
});

export default TextArea;
