import { ReactSVG } from 'react-svg';
import determineBackgroundColor from 'src/utils/boardUtils/determineBackgroundColor';
import { AgentTypes, AOEObject } from '../../types/board';
import SVGCircle from './AOEs/circle.svg';

const borderRadius = '20';
const fillOpacity = 0.475;

const SVGBreachE = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="cb-aoe-svg"
    height="100%"
    width="auto"
    viewBox="0 0 181 1085"
    fill={determineBackgroundColor(AgentTypes.BREACH)}
    stroke={determineBackgroundColor(AgentTypes.BREACH)}
    strokeWidth="1"
    strokeOpacity="0.8"
    fillOpacity={fillOpacity}
    strokeLinejoin="round"
    preserveAspectRatio="none"
  >
    <rect
      x="1"
      y="1"
      width="179"
      height="1083"
      rx={borderRadius}
      ry={borderRadius}
    />
  </svg>
);

const getSVGBreachE = (length: number): AOEObject => ({
  svg: SVGBreachE,
  masked: true,
  rotatable: true,
  maxLength: length,
  minLength: length,
  defaultLength: length,
  originYMovementPercentage: 8,
  width: 'auto'
});

const SVGBreachC = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="cb-aoe-svg"
    height="100%"
    width="auto"
    viewBox="0 0 133 322"
    fill={determineBackgroundColor(AgentTypes.BREACH)}
    stroke={determineBackgroundColor(AgentTypes.BREACH)}
    strokeWidth="1"
    strokeOpacity="0.75"
    fillOpacity={fillOpacity}
    strokeLinejoin="round"
    preserveAspectRatio="none"
  >
    <rect width="131" height="320" rx="100%" ry="20%" x="1" y="1" />
  </svg>
);

const getSVGBreachC = (length: number): AOEObject => ({
  svg: SVGBreachC,
  masked: true,
  rotatable: true,
  maxLength: length,
  minLength: length,
  defaultLength: length,
  originYMovementPercentage: 20,
  width: 'auto'
});

const SVGBreachX = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="cb-aoe-svg"
    height="100%"
    width="auto"
    viewBox="0 0 554 748"
    fill={determineBackgroundColor(AgentTypes.BREACH)}
    stroke={determineBackgroundColor(AgentTypes.BREACH)}
    strokeWidth="1"
    strokeOpacity="0.8"
    fillOpacity={fillOpacity}
    strokeLinejoin="round"
    preserveAspectRatio="none"
  >
    <rect
      x="1"
      y="1"
      width="552"
      height="746"
      rx={borderRadius}
      ry={borderRadius}
    />
  </svg>
);

const getSVGBreachX = (length: number): AOEObject => ({
  svg: SVGBreachX,
  masked: true,
  rotatable: true,
  maxLength: length,
  minLength: length,
  defaultLength: length,
  originYMovementPercentage: 14,
  width: 'auto'
});

const SVGFadeX = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="cb-aoe-svg"
    height="100%"
    width="auto"
    viewBox="0 0 166 261"
    fill={determineBackgroundColor(AgentTypes.FADE)}
    stroke={determineBackgroundColor(AgentTypes.FADE)}
    strokeWidth="1"
    strokeOpacity="0.8"
    fillOpacity={fillOpacity}
    strokeLinejoin="round"
    preserveAspectRatio="none"
  >
    <rect x="1" y="1" width="164" height="259" rx="6" ry="6" />
  </svg>
);

const getSVGFadeX = (length: number): AOEObject => ({
  svg: SVGFadeX,
  masked: true,
  rotatable: true,
  maxLength: length,
  minLength: length,
  defaultLength: length,
  originYMovementPercentage: 0,
  width: 'auto'
});

const SVGNeonC = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="cb-aoe-svg"
    height="100%"
    width="auto"
    viewBox="0 0 115 539"
    fill={determineBackgroundColor(AgentTypes.NEON)}
    stroke={determineBackgroundColor(AgentTypes.NEON)}
    strokeWidth="1"
    strokeOpacity="0.8"
    fillOpacity={fillOpacity}
    strokeLinejoin="round"
    preserveAspectRatio="none"
  >
    <rect x="1" y="1" width="15" height="537" />

    <rect x="99" y="1" width="15" height="537" />
  </svg>
);

const getSVGNeonC = (length: number): AOEObject => ({
  svg: SVGNeonC,
  masked: true,
  rotatable: true,
  maxLength: length,
  minLength: 24,
  defaultLength: length * 0.75,
  originYMovementPercentage: 0,
  width: 24
});

const SVGOmenQ = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="cb-aoe-svg"
    height="100%"
    width="auto"
    viewBox="0 0 181 1085"
    fill={determineBackgroundColor(AgentTypes.OMEN)}
    stroke={determineBackgroundColor(AgentTypes.OMEN)}
    strokeWidth="1"
    strokeOpacity="0.8"
    fillOpacity={fillOpacity}
    strokeLinejoin="round"
    preserveAspectRatio="none"
  >
    <rect
      x="1"
      y="1"
      width="179"
      height="1083"
      rx={borderRadius}
      ry={borderRadius}
    />
  </svg>
);

const getSVGOmenQ = (length: number): AOEObject => ({
  svg: SVGOmenQ,
  masked: true,
  rotatable: true,
  maxLength: length,
  minLength: length,
  defaultLength: length,
  originYMovementPercentage: 8,
  width: 'auto'
});

const SVGSovaX = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="cb-aoe-svg"
    height="100%"
    width="auto"
    viewBox="0 0 68 1151"
    fill={determineBackgroundColor(AgentTypes.SOVA)}
    stroke={determineBackgroundColor(AgentTypes.SOVA)}
    strokeWidth="0.5"
    strokeOpacity="0.8"
    fillOpacity={fillOpacity}
    strokeLinejoin="round"
    preserveAspectRatio="none"
  >
    <rect x="1" y="1" width="66" height="1149" rx="7" ry="7" />
  </svg>
);

const getSVGSovaX = (length: number): AOEObject => ({
  svg: SVGSovaX,
  masked: true,
  rotatable: true,
  maxLength: length,
  minLength: length,
  defaultLength: length,
  originYMovementPercentage: 3,
  width: 'auto'
});

const getCircularSVG = (
  agentName: AgentTypes,
  size: number,
  colorType: 'fill' | 'border'
): AOE => ({
  svg: (
    <ReactSVG
      src={SVGCircle}
      beforeInjection={(svg) => {
        svg.classList.add('cb-aoe-svg');
        if (colorType === 'fill') {
          svg.setAttribute('fill', determineBackgroundColor(agentName));

          svg.setAttribute('fill-opacity', fillOpacity.toString());
          svg.setAttribute('stroke', determineBackgroundColor(agentName));
          svg.setAttribute('stroke-width', (50 / size).toString());
          svg.setAttribute('stroke-opacity', '0.35');
        }

        if (colorType === 'border') {
          svg.setAttribute('fill', 'none');
          svg.setAttribute('stroke', determineBackgroundColor(agentName));
          svg.setAttribute('stroke-width', (50 / size).toString());
          svg.setAttribute('stroke-opacity', '0.5');
        }

        svg.setAttribute('width', `${size}px`);
      }}
    />
  ),
  masked: true,
  rotatable: false,
  maxLength: size / 2,
  minLength: size / 2,
  defaultLength: size / 2,
  originYMovementPercentage: 50,
  width: size
});

const SVGLine = (agentName: AgentTypes) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="cb-aoe-svg"
    height="100%"
    width="auto"
    viewBox="0 0 17 539"
    fill={determineBackgroundColor(agentName)}
    stroke={determineBackgroundColor(agentName)}
    strokeWidth="1"
    strokeOpacity="0.6"
    fillOpacity={fillOpacity}
    strokeLinejoin="round"
    preserveAspectRatio="none"
    strokeLinecap="round"
  >
    <rect x="1" y="1" width="15" height="537" />
  </svg>
);

const getLineSVG = (
  agentName: AgentTypes,
  length: number,
  width: number
): AOEObject => ({
  svg: SVGLine(agentName),
  masked: true,
  rotatable: true,
  maxLength: length,
  minLength: length,
  defaultLength: length,
  originYMovementPercentage: 50,
  width
});

type AOE = AOEObject | undefined;

type AgentAOEs = {
  [key: string]: AOE;
};

type AOEList = {
  [key: string]: AgentAOEs;
};

/**
 * IMPORTANT: every SVG listed here MUST HAVE className="cb-aoe-svg" !
 */

// AGENT: { VIEW: undefined },visionConeSVG(300, false)

const AREASOFEFFECT: AOEList = {
  AGENT: {
    VIEW: undefined
  },
  [AgentTypes.ASTRA]: {
    Q: getCircularSVG(AgentTypes.ASTRA, 42, 'fill'),
    E: getCircularSVG(AgentTypes.ASTRA, 33, 'fill'),
    C: getCircularSVG(AgentTypes.ASTRA, 42, 'fill'),
    X: getLineSVG(AgentTypes.ASTRA, 500, 4)
  },
  [AgentTypes.BREACH]: {
    Q: undefined,
    E: getSVGBreachE(201),
    C: getSVGBreachC(50),
    X: getSVGBreachX(131)
  },
  [AgentTypes.BRIMSTONE]: {
    Q: getCircularSVG(AgentTypes.BRIMSTONE, 40, 'fill'),
    E: getCircularSVG(AgentTypes.BRIMSTONE, 33, 'fill'),
    C: getCircularSVG(AgentTypes.BRIMSTONE, 52, 'fill'),
    X: getCircularSVG(AgentTypes.BRIMSTONE, 77, 'fill')
  },
  [AgentTypes.CHAMBER]: {
    Q: undefined,
    E: getCircularSVG(AgentTypes.CHAMBER, 350, 'border'),
    C: getCircularSVG(AgentTypes.CHAMBER, 82, 'fill'),
    X: undefined
  },
  [AgentTypes.CYPHER]: {
    Q: getCircularSVG(AgentTypes.CYPHER, 30, 'fill'),
    E: undefined,
    C: undefined,
    X: undefined
  },
  [AgentTypes.FADE]: {
    Q: getCircularSVG(AgentTypes.FADE, 54, 'fill'),
    E: getCircularSVG(AgentTypes.FADE, 270, 'border'),
    C: undefined,
    X: getSVGFadeX(190)
  },
  [AgentTypes.JETT]: {
    Q: undefined,
    E: undefined,
    C: getCircularSVG(AgentTypes.JETT, 27, 'fill'),
    X: undefined
  },
  [AgentTypes.KAYO]: {
    Q: undefined,
    E: getCircularSVG(AgentTypes.KAYO, 155, 'fill'),
    C: getCircularSVG(AgentTypes.KAYO, 31, 'fill'),
    X: getCircularSVG(AgentTypes.KAYO, 430, 'border')
  },
  [AgentTypes.KILLJOY]: {
    Q: getCircularSVG(AgentTypes.KILLJOY, 50, 'fill'),
    E: undefined,
    C: getCircularSVG(AgentTypes.KILLJOY, 33, 'fill'),
    X: getCircularSVG(AgentTypes.KILLJOY, 305, 'border')
  },
  [AgentTypes.OMEN]: {
    Q: getSVGOmenQ(141),
    E: getCircularSVG(AgentTypes.OMEN, 33, 'fill'),
    C: undefined,
    X: undefined
  },
  [AgentTypes.PHOENIX]: {
    Q: undefined,
    E: getCircularSVG(AgentTypes.PHOENIX, 36, 'fill'),
    C: getLineSVG(AgentTypes.PHOENIX, 48, 4),
    X: undefined
  },
  [AgentTypes.RAZE]: {
    Q: undefined,
    E: undefined,
    C: undefined,
    X: undefined
  },
  [AgentTypes.REYNA]: {
    Q: undefined,
    E: undefined,
    C: undefined,
    X: undefined
  },
  [AgentTypes.SAGE]: {
    Q: getCircularSVG(AgentTypes.SAGE, 44, 'fill'),
    E: undefined,
    C: getLineSVG(AgentTypes.SAGE, 28, 8),
    X: undefined
  },
  [AgentTypes.SKYE]: {
    Q: undefined,
    E: undefined,
    C: getCircularSVG(AgentTypes.SKYE, 168, 'border'),
    X: undefined
  },
  [AgentTypes.SOVA]: {
    Q: getCircularSVG(AgentTypes.SOVA, 33, 'fill'),
    E: getCircularSVG(AgentTypes.SOVA, 235, 'border'),
    C: undefined,
    X: getSVGSovaX(310)
  },
  [AgentTypes.VIPER]: {
    Q: getCircularSVG(AgentTypes.VIPER, 36, 'fill'),
    E: getLineSVG(AgentTypes.VIPER, 150, 4),
    C: getCircularSVG(AgentTypes.VIPER, 31, 'fill'),
    X: getCircularSVG(AgentTypes.VIPER, 70, 'fill')
  },
  [AgentTypes.YORU]: {
    Q: undefined,
    E: undefined,
    C: undefined,
    X: undefined
  },
  [AgentTypes.NEON]: {
    Q: getCircularSVG(AgentTypes.NEON, 45, 'fill'),
    E: undefined,
    C: getSVGNeonC(225),
    X: undefined
  },
  [AgentTypes.HARBOR]: {
    Q: getCircularSVG(AgentTypes.HARBOR, 45, 'fill'),
    E: getLineSVG(AgentTypes.HARBOR, 150, 4),
    C: getLineSVG(AgentTypes.HARBOR, 28, 4),
    X: undefined
  },

  [AgentTypes.GEKKO]: {
    Q: undefined,
    E: undefined,
    C: getCircularSVG(AgentTypes.GEKKO, 60, 'fill'),
    X: undefined
  },
  [AgentTypes.DEADLOCK]: {
    Q: undefined,
    E: undefined,
    C: undefined,
    X: undefined
  }
};

export default AREASOFEFFECT;
