import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { Announcement } from 'src/types/announcement';

type AnnouncementNoID = Omit<Announcement, 'id'>;

const announcementConverter = {
  toFirestore: (announcement: Announcement) => {
    const announcementNoID: AnnouncementNoID = {
      title: announcement.title,
      photoURL: announcement.photoURL ? announcement.photoURL : null,
      publishedAt: announcement.publishedAt,
      content: announcement.content,
      author: announcement.author
    };

    return announcementNoID;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Announcement {
    const data = snapshot.data(options);
    const { id } = snapshot;
    return <Announcement>{
      id,
      title: data.title,
      photoURL: data.photoURL,
      publishedAt: data.publishedAt,
      content: data.content,
      author: data.author
    };
  }
};

export { announcementConverter };
