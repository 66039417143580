import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import { terminateTeamListener } from 'src/data';
import useSettings from 'src/hooks/useSettings';

interface PersonalGuardProps {
  children?: ReactNode;
}

const PersonalGuard: FC<PersonalGuardProps> = ({ children }) => {
  const { settings, saveSettings } = useSettings();

  useEffect(() => {
    if (settings.activeSpaceID !== null) {
      // terminate listener of previously active team space
      terminateTeamListener();

      // update activeSpace in settingsContext
      saveSettings({
        ...settings,
        activeSpaceID: null
      });
    }
  }, []);

  return <>{children}</>;
};

PersonalGuard.propTypes = {
  children: PropTypes.node
};

export default PersonalGuard;
