import RedoIcon from '@mui/icons-material/Redo';
import { Box, IconButton, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import CustomTooltip from 'src/components/CustomTooltip';
import useCommandsFuture from 'src/hooks/useCommandsFuture';
import useGlobalDOMEvents from 'src/hooks/useGlobalDOMEvents';
import { redo } from 'src/slices/board/boardModel';
import store from 'src/store';

interface IProps {
  sx?: SxProps<Theme>;
}

const RedoButton: FC<IProps> = (props: IProps): ReactElement => {
  const { sx } = props;
  const { playId: sessionId, teamId } = useParams();

  const commandsFuture = useCommandsFuture();
  const disabled: boolean = commandsFuture?.length < 1;

  useGlobalDOMEvents({
    keydown(e: KeyboardEvent) {
      if (e.key.toLowerCase() === 'y' && e.ctrlKey) {
        store.dispatch(redo(teamId ? sessionId : null));
      }
    }
  });

  const handleClick = () => {
    store.dispatch(redo(teamId ? sessionId : null));
  };

  return (
    <CustomTooltip title="Redo" subtitle="CTRL + Y" placement="top">
      <Box>
        <IconButton
          disabled={disabled}
          onClick={handleClick}
          sx={{ ...sx }}
          size="large"
        >
          <RedoIcon />
        </IconButton>
      </Box>
    </CustomTooltip>
  );
};

export default RedoButton;
