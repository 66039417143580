const rotateMapBy = (currentRotation: number, rotateBy: 90 | 180): number => {
  if (currentRotation !== 270) {
    return currentRotation + rotateBy;
  }

  if (currentRotation === 270) {
    if (rotateBy === 180) {
      return 90;
    }
  }

  return 0;
};

export default rotateMapBy;
