import { PlayInfo } from 'src/types/play';

export const playSortByOptions = {
  editedAsc: {
    label: 'Edited at (asc)'
  },
  editedDesc: {
    label: 'Edited at (desc)'
  },
  createdAsc: {
    label: 'Created at (asc)'
  },
  createdDesc: {
    label: 'Created at (desc)'
  },
  mapAsc: {
    label: 'Map (asc)'
  },
  mapDesc: {
    label: 'Map (desc)'
  },
  nameAsc: {
    label: 'Play Title (asc)'
  },
  nameDesc: {
    label: 'Play Title (desc)'
  }
};

export type PlaysSortByOptions =
  | 'editedAsc'
  | 'editedDesc'
  | 'createdAsc'
  | 'createdDesc'
  | 'mapAsc'
  | 'mapDesc'
  | 'nameAsc'
  | 'nameDesc';

const sortPlays = (
  plays: PlayInfo[],
  sortByKey: PlaysSortByOptions
): PlayInfo[] => {
  if (!plays || plays.length === 0) {
    return [];
  }

  if (sortByKey === 'createdAsc') {
    return [...plays].sort((x, y) => x.createdAt - y.createdAt);
  }

  if (sortByKey === 'editedAsc') {
    return [...plays].sort((x, y) => x.updatedAt - y.updatedAt);
  }

  if (sortByKey === 'createdDesc') {
    const temp = [...plays].sort((x, y) => x.createdAt - y.createdAt);

    return [...temp].reverse();
  }

  if (sortByKey === 'editedDesc') {
    const temp = [...plays].sort((x, y) => x.updatedAt - y.updatedAt);

    return [...temp].reverse();
  }

  if (sortByKey === 'nameAsc') {
    return [...plays].sort((x, y) =>
      x.title.toLowerCase().localeCompare(y.title.toLowerCase())
    );
  }

  if (sortByKey === 'nameDesc') {
    const temp = [...plays].sort((x, y) =>
      x.title.toLowerCase().localeCompare(y.title.toLowerCase())
    );
    return [...temp].reverse();
  }

  if (sortByKey === 'mapAsc') {
    return [...plays].sort((x, y) =>
      x.map.toLowerCase().localeCompare(y.map.toLowerCase())
    );
  }

  if (sortByKey === 'mapDesc') {
    const temp = [...plays].sort((x, y) =>
      x.map.toLowerCase().localeCompare(y.map.toLowerCase())
    );
    return [...temp].reverse();
  }

  return plays;
};

export default sortPlays;
