import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Theme,
  Typography,
  useTheme /* useTheme */
} from '@mui/material';
import { SxProps } from '@mui/system';
import moment from 'moment';
import { FC, useState } from 'react';
import SessionUserGroup from 'src/components/app/board/SessionUserGroup';
import {
  customAspectRatioX,
  customAspectRatioXPercentage,
  customAspectRatioY,
  customOpacityDarkTheme
} from 'src/constants';
import { PlayInfo } from 'src/types/play';
import { SessionInfo } from 'src/types/session';
import hexToRGB from 'src/utils/hexToRGB';
import AspectRatio from '../../AspectRatio';
import PlayPreviewDialog from './PlayPreviewDialog';

interface IProps {
  sx?: SxProps<Theme>;
  play: PlayInfo;
  activeWorkspace?: string;
  selectionEnabled: boolean;
  selected: boolean;
  toggleSelected?: Function;
  sessionInfo?: SessionInfo;
}

const PlayPreviewBox: FC<IProps> = (props: IProps) => {
  const {
    play,
    activeWorkspace,
    sx,
    selectionEnabled,
    selected,
    toggleSelected,
    sessionInfo
  } = props;
  const { title, updatedAt, photoURL } = play;
  const theme = useTheme();
  const [playPreviewDialogOpen, setPlayPreviewDialogOpen] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [playPreviewLoaded, setPlayPreviewLoaded] = useState<boolean>(true);
  const handleClickPlayPreview = () => {
    setPlayPreviewDialogOpen(true);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const selectOptionOpen = Boolean(anchorEl);

  return (
    <>
      <Box
        sx={{
          ...sx,
          position: 'relative',
          borderRadius: `${theme.shape.borderRadius}px`,
          overflow: 'hidden',
          border: `1px solid ${theme.palette.divider}`
        }}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        aria-owns={selectOptionOpen ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
      >
        <AspectRatio ratio={customAspectRatioY / customAspectRatioX}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ height: '100%', width: '100%' }}
          >
            {playPreviewLoaded ? (
              <img
                style={{
                  width: '100%',
                  height: 'auto'
                }}
                alt={title}
                src={photoURL}
                onError={() => setPlayPreviewLoaded(false)}
              />
            ) : (
              <NoPhotographyIcon
                sx={{ fontSize: 45, color: theme.palette.background.paper }}
              />
            )}
          </Grid>
        </AspectRatio>

        <Button
          sx={{
            borderRadius: `${theme.shape.borderRadius}px`,

            p: 0,
            top: 0,
            pt: `${customAspectRatioXPercentage}%`,
            width: '100%',
            position: 'absolute',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: hexToRGB(theme.palette.primary.main, '0.015')
            }
          }}
          onClick={() => handleClickPlayPreview()}
        />
        {sessionInfo && (
          <Box
            sx={{
              bottom: theme.spacing(5.5),
              right: theme.spacing(0.5),
              position: 'absolute'
            }}
          >
            <SessionUserGroup
              sessionUsers={sessionInfo?.users}
              sizeFactor={0.5}
              showOnlyOnline
            />
          </Box>
        )}

        {selectionEnabled && (
          <IconButton
            size="small"
            sx={{
              backgroundColor: theme.palette.background.paper,
              p: 0,
              m: 1,
              top: 0,
              position: 'absolute',
              zIndex: 999,
              '&:hover': {
                backgroundColor: hexToRGB(
                  theme.palette.primary.main,
                  (customOpacityDarkTheme * 4).toString()
                )
              }
            }}
            onClick={() => toggleSelected(play.id)}
          >
            {selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
          </IconButton>
        )}
        <Divider />
        <Box
          sx={{
            p: '5%',

            backgroundColor: theme.palette.background.paper
          }}
        >
          <Typography
            variant="h5"
            sx={{ pl: 0.5, cursor: 'pointer', fontWeight: 500 }}
            onClick={handleClickPlayPreview}
          >
            {title}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              pl: 0.5,
              color: `${theme.palette.text.secondary} !important`
            }}
          >
            {moment(updatedAt).fromNow()}
          </Typography>
        </Box>
      </Box>

      <PlayPreviewDialog
        open={playPreviewDialogOpen}
        setOpen={setPlayPreviewDialogOpen}
        playInfo={play}
        activeWorkspace={activeWorkspace}
      />
    </>
  );
};

export default PlayPreviewBox;
