import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from 'src/store';

const useCommandsHistory = (): string[] => {
  const commandsHistory: string[] = useSelector((state: RootState) => {
    const { activeStep } = state.boardSlice.model;
    return state.boardSlice.model.stepLayers[activeStep]?.commandsHistory;
  }, shallowEqual);

  return commandsHistory;
};

export default useCommandsHistory;
