import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import TopicIcon from '@mui/icons-material/Topic';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Theme,
  Typography,
  useTheme /* useTheme */
} from '@mui/material';
import { SxProps } from '@mui/system';
import moment from 'moment';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import { customOpacityDarkTheme } from 'src/constants';
import useSettings from 'src/hooks/useSettings';
import { PlaybookInfo } from 'src/types/play';
import hexToRGB from 'src/utils/hexToRGB';

interface IProps {
  sx?: SxProps<Theme>;
  playbook: PlaybookInfo;
  selectionEnabled: boolean;
  selected: boolean;
  toggleSelected?: Function;
}

const PlaybookPreviewBox: FC<IProps> = (props: IProps) => {
  const { playbook, sx, selectionEnabled, selected, toggleSelected } = props;
  const { settings } = useSettings();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClickPlaybook = () => {
    if (settings.activeSpaceID) {
      navigate(`/app/team/${settings.activeSpaceID}/playbooks/${playbook.id}`);
    } else {
      navigate(`/app/personal/playbooks/${playbook.id}`);
    }
  };

  return (
    <>
      <Box
        sx={{
          ...sx,
          position: 'relative'
        }}
      >
        {selectionEnabled && (
          <IconButton
            size="small"
            sx={{
              p: 0,
              m: 1,
              top: 0,
              position: 'absolute',
              zIndex: 999,
              backgroundColor: hexToRGB(
                theme.palette.primary.main,
                (customOpacityDarkTheme * 2).toString()
              ),
              '&:hover': {
                backgroundColor: hexToRGB(
                  theme.palette.primary.main,
                  (customOpacityDarkTheme * 4).toString()
                )
              }
            }}
            onClick={() => toggleSelected(playbook.id)}
          >
            {selected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
          </IconButton>
        )}

        <Box
          sx={{
            borderRadius: `${theme.shape.borderRadius}px`
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
              border: `1px solid ${theme.palette.divider}`,
              height: 'auto',
              width: '100%',
              backgroundColor: theme.palette.background.paper,
              borderRadius: `${theme.shape.borderRadius}px`
            }}
          >
            <Button
              onClick={() => handleClickPlaybook()}
              sx={{
                borderRadius: `${theme.shape.borderRadius}px`,
                width: '100%',
                p: '0px !important',
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: `${hexToRGB(
                    theme.palette.primary.main,
                    '0.025'
                  )} !important`
                }
              }}
            >
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{
                  height: 'auto',
                  width: '100%',
                  p: 4.5
                }}
              >
                <TopicIcon
                  sx={{
                    fontSize: 32,
                    fill: theme.palette.text.secondary,
                    opacity: 0.5,
                    mb: 1
                  }}
                />

                <Typography
                  variant="h4"
                  sx={{
                    color: theme.palette.error.contrastText,
                    pt: 0.5,
                    pl: 0.5,
                    cursor: 'pointer',
                    fontWeight: 500
                  }}
                >
                  {playbook.title}
                </Typography>

                <Typography
                  variant="caption"
                  sx={{
                    pl: 0.5,
                    pt: 0.2,
                    color: `${theme.palette.text.secondary} !important`
                  }}
                >
                  {playbook.numberOfPlays} Play
                  {playbook.numberOfPlays === 1 ? ' ' : 's'} |{' '}
                  {`${moment(playbook.updatedAt).fromNow()}`}
                </Typography>
              </Grid>
            </Button>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default PlaybookPreviewBox;
