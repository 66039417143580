// Colors
import { customOpacityDarkTheme } from 'src/constants';
import hexToRGB from 'src/utils/hexToRGB';

const neutral = {
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D1D5DB',
  400: '#9CA3AF',
  500: '#6B7280',
  600: '#4B5563',
  700: '#374151',
  800: '#1F2937',
  900: '#111827'
};

// previous color: #1b1c23
/*
const background = {
  default: '#1A191F',
  paper: '#201F25'
};
*/

const background = {
  default: '#2A2B30',
  paper: '#323339'
};

export const backgroundColorSpacebar = '#323339';
export const backgroundColorSidebar = '#232429';
export const backgroundColorSettingsSidebar = '#25262b';
export const backgroundColorWhiteboard = '#2A2B30';

const primary = {
  main: '#ffffff',
  light: '#25262e',
  dark: '#ffffff',
  contrastText: '#ebebeb'
};

const divider = '#44444a';

// hexToRGB("#ffffff", "0.08")
//"#35363d"

// original colors
const secondary = {
  main: '#4953de', // alternative for valorant-colors: #fa4454
  light: '#5c67ff',
  dark: '#373e99',
  contrastText: neutral[900]
};

/*
// valorant colors
const secondary = {
  main: '#fa4454', // alternative for valorant-colors: #fa4454
  light: '#f55663',
  dark: '#dc3d4b',
  contrastText: neutral[900]
};
*/

const success = {
  main: '#00d452',
  light: '#00b847',
  dark: '#008533',
  contrastText: neutral[900]
};

const info = {
  main: '#2196F3',
  light: '#9CA3AF',
  dark: '#9CA3AF',
  contrastText: neutral[900]
};

const warning = {
  main: '#FFB020',
  light: '#FFBF4C',
  dark: '#B27B16',
  contrastText: neutral[900]
};

const error = {
  main: '#c92c2c',
  light: '#DA6868',
  dark: '#922E2E',
  contrastText: neutral[100]
};

const text = {
  primary: '#fff',
  secondary: '#9da2a6',
  disabled: 'rgba(255, 255, 255, 0.48)'
};

export const darkThemeOptions = {
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important'
        }
      }
    },

    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: hexToRGB(primary.main, '0.075'),
          color: hexToRGB(primary.main, '1')
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-outlined': {
            backgroundColor: background.paper
          },
          '&.MuiButton-containedError': {
            backgroundColor: `${error.main} !important`,
            '&:hover': {
              backgroundColor: `${error.dark} !important`
            }
          },
          '&.MuiButton-outlinedError': {
            color: `${error.main} !important`
          }
        },
        contained: {
          backgroundColor: hexToRGB(secondary.main, '0.9'),
          '&:hover': {
            backgroundColor: hexToRGB(secondary.main, '0.7')
          }
        },
        text: {
          backgroundColor: hexToRGB(
            primary.main,
            (customOpacityDarkTheme * 2.5).toString()
          ),
          '&:hover': {
            backgroundColor: hexToRGB(
              primary.main,
              (customOpacityDarkTheme * 5).toString()
            )
          }
        },
        outlined: {
          color: hexToRGB(secondary.light, '1'),
          backgroundColor: `${hexToRGB(
            primary.main,
            (customOpacityDarkTheme * 2.5).toString()
          )} !important`,
          '&:hover': {
            backgroundColor: `${hexToRGB(
              primary.main,
              (customOpacityDarkTheme * 5).toString()
            )} !important`
          }
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {}
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-filledDefault': {
            border: `1px solid ${divider}`,
            backgroundColor: background.paper,
            '& .MuiChip-deleteIcon': {
              color: hexToRGB(primary.main, '0.2')
            }
          },
          '&.MuiChip-outlinedDefault': {
            borderColor: neutral[700],
            '& .MuiChip-deleteIcon': {
              color: neutral[700]
            }
          }
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: secondary.main
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderColor: divider,

          '& .MuiDataGrid-row': {
            border: 0,
            '&:nth-of-type(2n-1)': {
              backgroundColor: hexToRGB(primary.main, '0.015')
            }
          },
          '& .MuiDataGrid-cell:focus-within': {
            outlineWidth: 0,
            outlineOffset: 0
          },
          '& .MuiDataGrid-cell': {
            border: 0
          },
          '& .MuiDataGrid-cellCheckbox': {
            border: 0
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            color: `${text.secondary} !important`
          },
          '& .MuiDataGrid-columnHeader:focus-within': {
            outlineWidth: 0,
            outlineOffset: 0
          },
          '& .MuiDataGrid-columnsContainer': {
            borderBottom: `1px solid ${divider}`
          },
          '& .MuiDataGrid-columnSeparator': {
            color: divider
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: `1px solid ${divider}`
          },

          '& .MuiDataGrid-checkboxInput': {
            color: `${text.primary} !important`
          }
        }
      }
    },

    MuiIcon: {
      styleOverrides: {
        root: {
          fontSize: '10px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: background.paper,
          borderRadius: '5px'
        },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: text.secondary
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: primary.main,
          '&.MuiIconButton-colorSuccess': {
            color: success.main
          },
          '&.MuiIconButton-colorError': {
            color: error.main
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused ': {
            color: text.secondary
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: divider
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: 'solid',
          borderWidth: 1
        }
      }
    },
    MuiPopover: {
      defaultProps: {
        elevation: 23
      },
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: 'solid',
          borderWidth: 1,
          backgroundColor: `${background.paper} !important`
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: hexToRGB(primary.main, '0.05'),
          fontSize: 21,
          fontWeight: 700,
          '&.Mui-active': {
            color: secondary.main
          },
          '&.Mui-completed': {
            color: secondary.main
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: neutral[700]
        },
        track: {
          backgroundColor: neutral[500],
          opacity: 1
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${divider}`
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[800],
          '.MuiTableCell-root': {
            color: neutral[300]
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-indicator': {
            borderBottom: `2px solid ${secondary.main}`
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            // color: secondary.main,
          },
          '& .MuiInput-underline:after': {
            borderColor: hexToRGB(primary.main, '0.15')
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: hexToRGB(primary.main, '0.075')
            },
            '&:hover fieldset': {
              borderColor: hexToRGB(primary.main, '0.5')
            },
            '&.Mui-focused fieldset': {
              borderColor: hexToRGB(primary.main, '0.5')
            }
          }
        }
      }
    }
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: '#18181d !important'
      },
      arrow: {
        color: '#18181d'
      }
    }
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        color: text.primary,
        '&.MuiTypography-subtitle1': {
          color: text.secondary
        },
        '&.MuiTypography-subtitle2': {
          color: text.secondary
        },
        '&.MuiTypography-body1': {
          color: text.secondary
        },
        '&.MuiTypography-body2': {
          color: text.secondary
        },
        '&.MuiTypography-caption': {
          color: text.primary
        },
        '&.MuiTypography-overline': {
          color: text.primary
        }
      }
    }
  },

  palette: {
    action: {
      active: hexToRGB(primary.main, (customOpacityDarkTheme * 2).toString()),
      hover: hexToRGB(primary.main, (customOpacityDarkTheme * 2).toString()),
      selected: hexToRGB(primary.main, (customOpacityDarkTheme * 2).toString()),
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      disabled: 'rgba(255, 255, 255, 0.26)'
    },
    background,
    divider,
    error,
    info,
    mode: 'dark',
    neutral,
    primary,
    secondary,
    success,
    text,
    warning
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    // 18: landing screenshot
    '0px 0px 7px 0px rgba(0, 0, 0, 0.175),0px 0px 14px 0px rgba(0, 0, 0, 0.05),0px 0px 0px 0px rgba(0, 0, 0, 0.0)',
    //  19: board background
    '0px 0px 10px 0px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(145, 158, 171, 0.0),0px 4px 18px 3px rgba(145, 158, 171, 0.0)',
    // 20: google login button
    '0px 0px 20px 0px  rgba(14, 14, 18, 0.2),0px 0px 30px 0px rgba(14, 14, 18, 0.25),0px 0px 30px 0px rgba(14, 14, 18, 0.2)',
    '0px 2px 10px 0px rgba(0, 0, 0, 0.4),0px 10px 14px 1px rgba(145, 158, 171, 0.0),0px 4px 18px 3px rgba(145, 158, 171, 0.0)',
    // navbar
    '0px 6px 6px -3px  rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
    '0px 0px 10px 0px rgba(0, 0, 0, 0.3),0px 0px 30px 0px rgba(0, 0, 0, 0.02),0px 0px 40px 0px rgba(0, 0, 0, 0.02)'
  ]
};
