import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { auth } from 'src/lib/firebase';
import useAuth from '../../hooks/useAuth';

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  // const { isAuthenticated, user } = useAuth();
  const authContext = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!authContext.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Navigate to="/" />;
  }

  if (auth.currentUser?.isAnonymous && location.pathname.includes('app')) {
    return <Navigate to="/" />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  // if (isAuthenticated && !user.verified && user.provider.includes('password')) {
  //   return <Navigate to="/authentication/verify-email" />;
  // }

  if (location.pathname === '/app' || location.pathname === '/app/') {
    if (authContext.isAuthenticated) {
      return <Navigate to="/app/personal" />;
    }
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
