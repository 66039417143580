import KeyboardIcon from '@mui/icons-material/Keyboard';
import {
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';
import CustomTooltip from 'src/components/CustomTooltip';

interface IProps {
  sx?: SxProps<Theme>;
}

interface IKeyboardCommand {
  command: string;
  title: string;
}

const KeyCommandInfoDialog: React.FC<IProps> = (props: IProps) => {
  const { sx } = props;
  const [open, setOpen] = React.useState(false);
  const theme: Theme = useTheme();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const commands: IKeyboardCommand[] = [
    { command: 'CTRL + Z', title: 'Undo' },
    { command: 'CTRL + Y', title: 'Redo' },
    { command: 'CTRL + S', title: 'Save play' },
    { command: 'CTRL + DEL', title: 'Clear board' },
    { command: 'CTRL + "+"', title: 'Zoom in' },
    { command: 'CTRL + "-"', title: 'Zoom out' },
    { command: 'Space + Drag', title: 'Pan mode' },
    { command: 'ESC', title: 'Cancel action' }
  ];

  const renderContent = () => {
    const items = [];
    commands.forEach((item: IKeyboardCommand) => {
      items.push(
        <Stack key={item.command} direction="row" spacing={theme.spacing(1)}>
          <Typography variant="h5" sx={{ minWidth: 100 }}>
            {item.command}
          </Typography>
          <Typography sx={{ minWidth: 100 }}> {item.title}</Typography>
        </Stack>
      );
    });

    return items;
  };
  return (
    <>
      <CustomTooltip title="Keyboard shortcuts">
        <IconButton sx={sx} onClick={handleClickOpen} size="large">
          <KeyboardIcon />
        </IconButton>
      </CustomTooltip>
      <Dialog maxWidth="xs" open={open} onClose={handleClose}>
        <DialogContent>
          <Typography variant="h3" sx={{ mb: theme.spacing(2) }}>
            Keyboard shortcuts
          </Typography>
          <Stack spacing={theme.spacing(1)}>{renderContent()}</Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default KeyCommandInfoDialog;
