import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, useTheme, Typography, Theme } from '@mui/material';
import { avatarSize } from 'src/constants';
import hexToRGB from 'src/utils/hexToRGB';

interface IProps {
  src: string;
  isTeamAvatar: boolean;
  sizeFactor?: number; // e.g. 1.2 -> 20% larger than avatarSize
  fillParent?: boolean;
  alt?: string;
  text?: string;
  children?: any;
}

const CustomAvatar: React.FC<IProps> = (props: IProps) => {
  const { src, sizeFactor, isTeamAvatar, fillParent, alt, text, children } =
    props;

  const theme: Theme = useTheme();

  const avatarIconSize = isTeamAvatar ? 22 : 24;

  const determineAvatarSize = () => {
    if (fillParent) {
      return '100%';
    }
    if (sizeFactor) {
      return avatarSize * sizeFactor;
    }

    return avatarSize;
  };

  const determineAvatarIconSize = () => {
    if (sizeFactor) {
      return avatarIconSize * sizeFactor;
    }

    return avatarIconSize;
  };
  if (children) {
    return (
      <Avatar
        alt={alt}
        src={src}
        sx={{
          width: determineAvatarSize(),
          height: determineAvatarSize(),
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: isTeamAvatar
            ? `${theme.shape.borderRadius}px !important`
            : '100% !important'
        }}
      >
        {children}
      </Avatar>
    );
  }

  return (
    <Avatar
      alt={alt}
      src={src}
      sx={{
        width: determineAvatarSize(),
        height: determineAvatarSize(),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: isTeamAvatar
          ? `${theme.shape.borderRadius}px !important`
          : '100% !important'
      }}
    >
      {text && (
        <Typography
          sx={{ fontSize: theme.typography.fontSize * sizeFactor * 1.5 }}
        >
          {text}
        </Typography>
      )}
      {!text && isTeamAvatar && (
        <GroupsIcon
          sx={{
            color: hexToRGB(theme.palette.text.secondary, '1'),
            fontSize: determineAvatarIconSize()
          }}
        />
      )}
      {!text && !isTeamAvatar && (
        <PersonIcon
          sx={
            fillParent
              ? {
                  color: hexToRGB(theme.palette.text.secondary, '1'),
                  width: '70%',
                  height: 'auto'
                }
              : {
                  color: hexToRGB(theme.palette.text.secondary, '1'),
                  fontSize: determineAvatarIconSize()
                }
          }
        />
      )}
    </Avatar>
  );
};

export default CustomAvatar;
