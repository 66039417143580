import { httpsCallable } from 'firebase/functions';
import { functions } from 'src/lib/firebase';
import { TrackEventInput } from 'src/types/functionsInput';
import { MPEventAddSoloPlayPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpAddSoloPlay';
import { MPEventAddSoloPlaybookPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpAddSoloPlaybook';
import { MPEventAddTeamPlayPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpAddTeamPlay';
import { MPEventAddTeamPlaybookPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpAddTeamPlaybook';
import { MPEventClonePlayPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpClonePlay';
import { MPEventCreateAccountPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpCreateAccount';
import { MPEventCreateTeamPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpCreateTeam';
import { MPEventDeleteSoloPlayPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpDeleteSoloPlay';
import { MPEventDeleteSoloPlaybookPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpDeleteSoloPlaybook';
import { MPEventDeleteTeamPlayPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpDeleteTeamPlay';
import { MPEventDeleteTeamPlaybookPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpDeleteTeamPlaybook';
import { MPEventGiveFeedbackPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpGiveFeedback';
import { MPEventJoinSessionPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpJoinSession';
import { MPEventReportBugPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpReportBug';
import { MPEventResetEmailPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpResetEmail';
import { MPEventResetPasswordPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpResetPassword';
import { MPEventSignInPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpSignIn';
import { MPEventSignOutPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpSignOut';
import { MPEventSubscribeToNewsletterPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpSubscribeToNewsletter';
import { MPEventUnsubscribeFromNewsletterPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpUnsubscribeFromNewsletter';
import { MPEventUpdateSoloPlayPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpUpdateSoloPlay';
import { MPEventUpdateSoloPlaybookPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpUpdateSoloPlaybook';
import { MPEventUpdateTeamPlayPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpUpdateTeamPlay';
import { MPEventUpdateTeamPlaybookPropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpUpdateTeamPlaybook';
import { MPEventViewLandingPagePropsClientSide } from 'src/types/mixpanel/event/clientTriggered/mpViewLandingPage';
import {
  MPEventCustomPropsClientSide,
  MPEventName
} from 'src/types/mixpanel/event/mpEvent';
import generateMPEventGeneralPropsClientSide from 'src/utils/mixpanelUtils/generateMPEventGeneralPropsClientSide';

const triggerTrackEvent = httpsCallable(functions, 'trackClientEvent');

const trackEvent = (
  eventName: MPEventName,
  eventCustomPropsClientSide: MPEventCustomPropsClientSide
) => {
  const eventGeneralPropsClientSide = generateMPEventGeneralPropsClientSide();

  const functionInput: TrackEventInput = {
    eventName,
    eventGeneralPropsClientSide,
    eventCustomPropsClientSide
  };

  console.log('TRACKING EVENT :', functionInput);

  triggerTrackEvent(functionInput);
};

export const trackCreateAccount = (
  eventProps: MPEventCreateAccountPropsClientSide
) => trackEvent('Create Account', eventProps);

export const trackCreateTeam = (eventProps: MPEventCreateTeamPropsClientSide) =>
  trackEvent('Create Team', eventProps);

export const trackResetPassword = (
  eventProps: MPEventResetPasswordPropsClientSide
) => trackEvent('Reset Password', eventProps);

export const trackResetEmail = (eventProps: MPEventResetEmailPropsClientSide) =>
  trackEvent('Reset Email', eventProps);

export const trackReportBug = (eventProps: MPEventReportBugPropsClientSide) =>
  trackEvent('Report Bug', eventProps);

export const trackGiveFeedback = (
  eventProps: MPEventGiveFeedbackPropsClientSide
) => trackEvent('Give Feedback', eventProps);

export const trackAddSoloPlay = (
  eventProps: MPEventAddSoloPlayPropsClientSide
) => trackEvent('Create Play (User)', eventProps);

export const trackAddSoloPlaybook = (
  eventProps: MPEventAddSoloPlaybookPropsClientSide
) => trackEvent('Create Playbook (User)', eventProps);

export const trackAddTeamPlay = (
  eventProps: MPEventAddTeamPlayPropsClientSide
) => trackEvent('Create Play (Team)', eventProps);

export const trackAddTeamPlaybook = (
  eventProps: MPEventAddTeamPlaybookPropsClientSide
) => trackEvent('Create Playbook (Team)', eventProps);

export const trackDeleteSoloPlay = (
  eventProps: MPEventDeleteSoloPlayPropsClientSide
) => trackEvent('Delete Play (User)', eventProps);

export const trackDeleteSoloPlaybook = (
  eventProps: MPEventDeleteSoloPlaybookPropsClientSide
) => trackEvent('Delete Playbook (User)', eventProps);

export const trackDeleteTeamPlay = (
  eventProps: MPEventDeleteTeamPlayPropsClientSide
) => trackEvent('Delete Play (Team)', eventProps);

export const trackDeleteTeamPlaybook = (
  eventProps: MPEventDeleteTeamPlaybookPropsClientSide
) => trackEvent('Delete Playbook (Team)', eventProps);

export const trackClonePlay = (eventProps: MPEventClonePlayPropsClientSide) =>
  trackEvent('Clone Play', eventProps);

export const trackUpdateSoloPlay = (
  eventProps: MPEventUpdateSoloPlayPropsClientSide
) => trackEvent('Update Play (User)', eventProps);

export const trackUpdateSoloPlaybook = (
  eventProps: MPEventUpdateSoloPlaybookPropsClientSide
) => trackEvent('Update Playbook (User)', eventProps);

export const trackUpdateTeamPlay = (
  eventProps: MPEventUpdateTeamPlayPropsClientSide
) => trackEvent('Update Play (Team)', eventProps);

export const trackUpdateTeamPlaybook = (
  eventProps: MPEventUpdateTeamPlaybookPropsClientSide
) => trackEvent('Update Playbook (Team)', eventProps);

export const trackJoinSession = (
  eventProps: MPEventJoinSessionPropsClientSide
) => trackEvent('Join Session', eventProps);

export const trackSignIn = (eventProps: MPEventSignInPropsClientSide) =>
  trackEvent('Sign In', eventProps);

export const trackSignOut = (eventProps: MPEventSignOutPropsClientSide) =>
  trackEvent('Sign Out', eventProps);

export const trackSubscribeToNewsletter = (
  eventProps: MPEventSubscribeToNewsletterPropsClientSide
) => trackEvent('Subscribe to Newsletter', eventProps);

export const trackUnsubscribeFromNewsletter = (
  eventProps: MPEventUnsubscribeFromNewsletterPropsClientSide
) => trackEvent('Unsubscribe from Newsletter', eventProps);

export const trackViewLandingPage = (
  eventProps: MPEventViewLandingPagePropsClientSide
) => trackEvent('View Landing Page', eventProps);
