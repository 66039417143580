import { child, DataSnapshot, get, off, onValue, ref } from 'firebase/database';
import { cloneDeep } from 'lodash';
import { realTimeDB } from 'src/lib/firebase';
import serverSideLogger from 'src/logging/serverSideLogger';
import { updateBoard } from 'src/slices/board/boardModel';
import { updatePlay } from 'src/slices/play';
import store from 'src/store';
import { LoggerInput } from 'src/types/functionsInput';
import { Play } from 'src/types/play';
import { Session } from 'src/types/session';
import SnackbarUtils from 'src/utils/snackbar/SnackbarUtils';
import { leaveSession } from '../setters/session';

/**
 *
 * @param sessionId
 */
export const subscribeToSessionPlay = async (sessionId: string) => {
  const sessionRef = ref(realTimeDB, `sessions/${sessionId}/play`);
  console.log(`Started listening to session with id:${sessionId}`);

  onValue(
    sessionRef,
    (snapshot) => {
      if (snapshot.exists()) {
        // success
        const play: Play = snapshot.val();
        store.dispatch(updateBoard(play?.board));
        store.dispatch(updatePlay(play, snapshot.exists()));
      }
    },
    (err) => {
      // error
      console.log(
        'Error occured while listening to session ',
        sessionId,
        ':',
        err
      );
      SnackbarUtils.error(
        'Your connection to the database stopped working. Please save the play and reload the page.'
      );

      const logObject: LoggerInput = {
        kind: 'error',
        function: 'subscribeToSessionPlay',
        message: err,
        metaData: {
          sessionId
        }
      };
      serverSideLogger(logObject);
    }
  );
};

export const unsubscribeFromSessionPlay = async (
  sessionId: string,
  userId: string
) => {
  if (sessionId) {
    const sessionRef = ref(realTimeDB, `sessions/${sessionId}/play`);
    await leaveSession(sessionId, userId);
    off(sessionRef);
    console.log(`Stopped listening to session with id:${sessionId}`);
  }
};

/**
 *
 * @param sessionId
 */
export const subscribeToSessionInfo = (
  sessionId: string,
  cb: (snapshot: DataSnapshot) => void, // callback function
  eb: (err: Error) => void // errorback function
) => {
  const sessionUsers = ref(realTimeDB, `sessions/${sessionId}/info`);
  console.log(`Started listening to session info with id:${sessionId}`);
  return onValue(sessionUsers, cb, eb);
};

export const getSessionPlayId = async (sessionId: string) =>
  get(child(ref(realTimeDB), `sessions/${sessionId}/play/id`));
