import { ThemeOptions } from '@mui/material';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { shape } from 'prop-types';

export const baseThemeOptions: ThemeOptions = {
  breakpoints: {
    /* starting at, e.g. "xs: 0, sm: 600" -> 0-600 is xs, 600+ is sm */
    values: {
      xs: 0, // min width is 420px
      sm: 795,
      md: 1170,
      lg: 1545,
      xl: 1920
    }
  },
  components: {
    MuiAvatar: {
      defaultProps: {
        variant: 'rounded'
      },
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 600,
          letterSpacing: 0,
          width: 40,
          height: 40,
          '&.MuiAvatar-rounded': {
            borderRadius: 11
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: 'small',
        disableRipple: true
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '12px !important',
          wordSpacing: 1.5,
          borderRadius: 5
        },
        outlined: {
          border: '1px',
          '&:hover': {
            border: '1px'
          }
        },
        sizeSmall: {
          padding: '0px 16px',
          fontSize: '0.65rem'
        },
        sizeMedium: {
          padding: '2px 20px'
        },
        sizeLarge: {
          padding: '6px 26px'
        },
        // always one more px
        textSizeSmall: {
          padding: '0px 16px'
        },
        textSizeMedium: {
          padding: '2px 20px'
        },
        textSizeLarge: {
          padding: '6px 24px'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '16px 24px'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '32px 24px',
          '&:last-child': {
            paddingBottom: '32px'
          }
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        },
        subheaderTypographyProps: {
          variant: 'body2'
        }
      },
      styleOverrides: {
        root: {
          padding: '32px 24px'
        }
      }
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary'
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box'
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%'
        },
        body: {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%'
        },
        '#__next': {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          height: '100%',
          width: '100%'
        },
        '#nprogress': {
          pointerEvents: 'none'
        },
        '#nprogress .bar': {
          backgroundColor: '#5048E5',
          height: 3,
          left: 0,
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 2000
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderRadius: 6,

          '& .MuiDataGrid-footerContainer': {
            minHeight: '0px !important',
            height: 39
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            justifyContent: 'space-between'
          },
          '& .MuiDataGrid-cell': {
            cursor: 'default'
          },
          '& .MuiDataGrid-cell:focus': {
            outline: '0px !important'
          },
          '& .MuiDataGrid-columnHeader:focus': {
            outline: '0px !important'
          }
        }
      }
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: {
          borderRadius: 999,
          padding: 8,
          '&.MuiIconButton-sizeMedium': {
            height: '36px',
            width: '36px'
          },
          '&.MuiIconButton-sizeSmall': {
            height: '32px',
            width: '32px'
          },
          '&.MuiIconButton-sizeLarge': {
            height: '40px',
            width: '40px'
          }
        },
        sizeSmall: {
          padding: 4
        }
      }
    },
    MuiInput: {
      defaultProps: {
        autoComplete: 'off'
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '18px',
          '&.MuiSvgIcon-fontSizeSmall': {
            fontSize: '16px'
          },
          '&.MuiSvgIcon-fontSizeLarge': {
            fontSize: '20px'
          }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden'
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          marginRight: '16px',
          '&.MuiListItemIcon-root': {
            minWidth: 'unset'
          }
        }
      }
    },
    MuiMenu: {
      defaultProps: {
        elevation: 16
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontWeight: 500
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        }
      }
    },
    MuiRadio: {
      defaultProps: {
        color: 'primary'
      }
    },
    MuiSwitch: {
      defaultProps: {
        color: 'primary'
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 12,
          fontWeight: 600,
          lineHeight: 1.71,
          minWidth: 'auto',
          paddingLeft: 5,
          paddingRight: 5,
          paddingBottom: 0,
          textTransform: 'uppercase',
          '& + &': {
            marginLeft: 24
          }
        }
      }
    },

    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '15px 16px'
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          '& .MuiTableCell-root': {
            borderBottom: 'none',
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: 1,
            letterSpacing: 0.5,
            textTransform: 'uppercase'
          },
          '& .MuiTableCell-paddingCheckbox': {
            paddingTop: 4,
            paddingBottom: 4
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: 'off',
        size: 'small'
      },
      styleOverrides: {
        root: {
          borderWidth: '2px'
        }
      }
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true
      }
    },
    MuiTypography: {
      defaultProps: {
        color: 'textPrimary'
      },

      styleOverrides: {
        root: {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',

          '&.MuiTypography-body1': {
            textOverflow: 'clip',
            whiteSpace: 'normal',
            overflow: 'visible'
          },
          '&.MuiTypography-body2': {
            textOverflow: 'clip',
            whiteSpace: 'normal',
            overflow: 'visible'
          }
        }
      }
    }
  },
  direction: 'ltr',
  shape: {
    borderRadius: 6
  },
  typography: {
    button: {
      fontWeight: 600,
      lineHeight: '1.7rem',
      letterSpacing: 0.5
    },
    fontFamily: `"Plus Jakarta Sans", "Inter", sans-serif, "Plus Jakarta Sans",  ${
      /* "IBM Plex Sans", sans-serif,  "Inter", sans-serif;, */ ''
    }  "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
    body1: {
      fontSize: 13,
      fontWeight: 500,
      wordSpacing: 1
    },
    body2: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '143%',
      letterSpacing: '0.17px',
      wordSpacing: 0
    },
    subtitle1: {
      fontSize: '0.84rem',
      fontWeight: 500,
      lineHeight: 1.5,
      wordSpacing: 1
    },
    subtitle2: {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: 1.5,
      wordSpacing: 1
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: 1.5,
      wordSpacing: 1
    },

    overline: {
      fontSize: '0.55rem',
      fontWeight: 800,
      letterSpacing: '0.4em',
      lineHeight: 2.5,
      textTransform: 'uppercase'
    },
    h1: {
      fontWeight: 600,
      fontSize: '1.6rem',
      lineHeight: 1.2223,
      letterSpacing: 0.75,
      wordSpacing: 3
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.2rem',
      lineHeight: 1.25,
      letterSpacing: 0.2,
      wordSpacing: 2
    },
    h3: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 1.4,
      letterSpacing: 0.2,
      wordSpacing: 2
    },
    h4: {
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 1.25
    },
    /* --- sidebar items --- */
    h5: {
      fontWeight: 600,
      fontSize: '0.75rem',
      letterSpacing: 0.5
    },
    h6: {
      fontWeight: 500,
      fontSize: '0.6rem',
      lineHeight: 1.1143
    }
    /* ---------------------- */
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
};
