import { Box, ListItemButton, Typography, useTheme } from '@mui/material';
import List from '@mui/material/List';
import * as React from 'react';
import {
  DrawerAccordion,
  DrawerAccordionDetails,
  DrawerAccordionSummary
} from 'src/components/app/board/DrawerAccordion';
import {
  BOLD_LINE_STROKEDASHARRAY,
  BOLD_LINE_STROKEWIDTH,
  DEFAULT_LINE_STROKEDASHARRAY,
  DEFAULT_LINE_STROKEWIDTH,
  DISTANCE_LINE_STROKEDASHARRAY,
  LINE_COLOR_OPTIONS
} from 'src/constants';
import { setLineOptions as setLineOptionsStore } from 'src/slices/board/boardUi';
import store from 'src/store';
import { ILineOptions } from 'src/types/board';
import ColorSelector from '../ColorSelector';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import CustomTooltip from 'src/components/CustomTooltip';

interface IStrokeFormat {
  strokeWidth: number;
  strokeDashArray: string;
  showDistance?: boolean;
}

type DrawingDrawerSection = 'color' | 'linetype' | 'tool';

const STROKE_FORMAT_OPTIONS: IStrokeFormat[] = [
  {
    strokeWidth: DEFAULT_LINE_STROKEWIDTH,
    strokeDashArray: null,
    showDistance: false
  },
  {
    strokeWidth: BOLD_LINE_STROKEWIDTH,
    strokeDashArray: null,
    showDistance: false
  },
  {
    strokeWidth: DEFAULT_LINE_STROKEWIDTH,
    strokeDashArray: DEFAULT_LINE_STROKEDASHARRAY,
    showDistance: false
  },
  {
    strokeWidth: BOLD_LINE_STROKEWIDTH,
    strokeDashArray: BOLD_LINE_STROKEDASHARRAY,
    showDistance: false
  },
  {
    strokeWidth: DEFAULT_LINE_STROKEWIDTH,
    strokeDashArray: DISTANCE_LINE_STROKEDASHARRAY, // show distance when this option is selected
    showDistance: true
  }
];

const DrawingDrawer: React.FC = () => {
  const theme = useTheme();

  const [lineOptions, setLineOptions] = React.useState<ILineOptions>(
    store.getState().boardSlice.ui.lineOptions
  );

  const [expanded, setExpanded] = React.useState<DrawingDrawerSection[]>([
    'color',
    'linetype',
    'tool'
  ]);

  const handleExpandPanel = (panel: DrawingDrawerSection) => {
    setExpanded(expanded.concat([panel]));
  };

  const handleContractPanel = (panel: DrawingDrawerSection) => {
    setExpanded(expanded.filter((item) => item !== panel));
  };

  const handleStrokeFormatClick = (newValue: IStrokeFormat) => () => {
    if (newValue) {
      const newLineOption: ILineOptions = {
        ...lineOptions,
        strokeDashArray: newValue.strokeDashArray,
        strokeWidth: newValue.strokeWidth,
        showDistance: newValue.showDistance
      };
      setLineOptions(newLineOption);
      store.dispatch(setLineOptionsStore(newLineOption));
    }
  };
  const handleColorSelect = (newColor) => {
    if (newColor) {
      const newLineOptions: ILineOptions = { ...lineOptions, color: newColor };
      setLineOptions(newLineOptions);
      store.dispatch(setLineOptionsStore(newLineOptions));
    }
  };

  const renderStrokeItems = (options: IStrokeFormat[]) => {
    const items = [];
    options.forEach((value: IStrokeFormat) => {
      items.push(
        DISTANCE_LINE_STROKEDASHARRAY === value.strokeDashArray ? (
          <CustomTooltip title="Travel time running with knife">
            <ListItemButton
              sx={{
                borderRadius: `${theme.shape.borderRadius}px`,
                mb: 1,
                height: 36,
                pt: 0.75
              }}
              key={`${value.strokeDashArray}-${value.strokeWidth}`}
              onClick={handleStrokeFormatClick(value)}
              selected={
                value.strokeWidth === lineOptions?.strokeWidth &&
                value.strokeDashArray === lineOptions?.strokeDashArray
              }
            >
              {DISTANCE_LINE_STROKEDASHARRAY === value.strokeDashArray && (
                <DirectionsRunIcon htmlColor={lineOptions.color} />
              )}
              <svg
                width="168"
                height="16.258"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <marker
                    id="head"
                    viewBox="0 0 10 10"
                    refX="5"
                    refY="5"
                    markerWidth="5"
                    markerHeight="5"
                  >
                    <circle cx="5" cy="5" r="4" fill={lineOptions.color} />
                  </marker>
                </defs>
                <path
                  d="M 10 10 L 146 10"
                  markerStart="url(#head)"
                  markerEnd="url(#head)"
                  stroke={lineOptions.color}
                  strokeDasharray={value.strokeDashArray}
                  strokeLinecap="round"
                  strokeWidth={
                    value.strokeWidth === DEFAULT_LINE_STROKEWIDTH
                      ? DEFAULT_LINE_STROKEWIDTH
                      : BOLD_LINE_STROKEWIDTH * 1.3
                  }
                />
              </svg>
            </ListItemButton>
          </CustomTooltip>
        ) : (
          <ListItemButton
            sx={{
              borderRadius: `${theme.shape.borderRadius}px`,
              mb: 1,
              height: 36,
              pt: 0.75
            }}
            key={`${value.strokeDashArray}-${value.strokeWidth}`}
            onClick={handleStrokeFormatClick(value)}
            selected={
              value.strokeWidth === lineOptions?.strokeWidth &&
              value.strokeDashArray === lineOptions?.strokeDashArray
            }
          >
            <svg width="168" height="16.258" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M 10 10 L 158 10"
                stroke={lineOptions.color}
                strokeDasharray={value.strokeDashArray}
                strokeLinecap="round"
                strokeWidth={
                  value.strokeWidth === DEFAULT_LINE_STROKEWIDTH
                    ? DEFAULT_LINE_STROKEWIDTH
                    : BOLD_LINE_STROKEWIDTH * 1.3
                }
              />
            </svg>
          </ListItemButton>
        )
      );
    });
    return items;
  };

  return (
    <Box>
      <DrawerAccordion
        expanded={expanded.includes('color')}
        onChange={
          expanded.includes('color')
            ? () => handleContractPanel('color')
            : () => handleExpandPanel('color')
        }
      >
        <DrawerAccordionSummary sx={{ height: '32px !important' }}>
          <Typography variant="subtitle1">Color</Typography>
        </DrawerAccordionSummary>
        <DrawerAccordionDetails>
          <ColorSelector
            colors={LINE_COLOR_OPTIONS}
            onSelect={handleColorSelect}
            selectedValue={lineOptions?.color}
            textColors={false}
          />
        </DrawerAccordionDetails>
      </DrawerAccordion>
      <DrawerAccordion
        expanded={expanded.includes('linetype')}
        onChange={
          expanded.includes('linetype')
            ? () => handleContractPanel('linetype')
            : () => handleExpandPanel('linetype')
        }
      >
        <DrawerAccordionSummary>
          <Typography variant="subtitle1">Line Type</Typography>
        </DrawerAccordionSummary>
        <DrawerAccordionDetails sx={{ px: 3 }}>
          <List>{renderStrokeItems(STROKE_FORMAT_OPTIONS)}</List>
        </DrawerAccordionDetails>
      </DrawerAccordion>
    </Box>
  );
};

export default DrawingDrawer;
