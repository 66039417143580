import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { Playbook } from 'src/types/play';

type PlaybookNoID = Omit<Playbook, 'id'>;

const playbookConverter = {
  toFirestore: (playbook: Playbook) => {
    const playbookNoID: PlaybookNoID = {
      title: playbook.title,
      photoURL: playbook.photoURL ? playbook.photoURL : null,
      updatedAt: playbook.updatedAt,
      createdAt: playbook.createdAt,
      playsInfo: playbook.playsInfo,
      description: playbook.description,
      numberOfPlays: playbook.numberOfPlays,
      thumbnail: playbook.thumbnail,
      whitelistedIDs: playbook.whitelistedIDs
    };

    return playbookNoID;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Playbook {
    const data = snapshot.data(options);
    const { id } = snapshot;
    return <Playbook>{
      id,
      title: data.title,
      photoURL: data.photoURL,
      updatedAt: data.updatedAt,
      createdAt: data.createdAt,
      playsInfo: data.playsInfo,
      description: data.description,
      numberOfPlays: data.numberOfPlays,
      thumbnail: data.thumbnail,
      whitelistedIDs: data.whitelistedIDs
    };
  }
};

export { playbookConverter };
