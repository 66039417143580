import { doc, onSnapshot } from 'firebase/firestore';
import serverSideLogger from 'src/logging/serverSideLogger';
import { updateAccountData } from 'src/slices/accountdata';
import store from 'src/store';
import { LoggerInput } from 'src/types/functionsInput';
import { firestore } from '../../../lib/firebase';
import { accountDataConverter } from '../../converter/accountDataConverter';

/**
 *
 * @param {string} userId
 * @returns
 */
export const subscribeToAccountData = (userId) => {
  try {
    const ref = doc(firestore, 'users', userId).withConverter(
      accountDataConverter
    );

    return onSnapshot(
      ref,
      (document) => {
        store.dispatch(updateAccountData(document.data()));
      },
      (error) => {
        console.error(
          'Error occured while trying to subscribe to AccountData:',
          error
        );
      }
    );
  } catch (err) {
    console.log('Error occured while trying to subscribe to AccountData:', err);

    const logObject: LoggerInput = {
      kind: 'error',
      function: 'subscribeToAccountData',
      message: err,
      metaData: {
        userId
      }
    };
    serverSideLogger(logObject);
    return undefined;
  }
};
