import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { updatePlaybook } from 'src/data/firestore/setters/playbook';
import { RootState, useSelector } from 'src/store';
import { Playbook, PlayInfo } from 'src/types/play';
import { Team } from 'src/types/team';
import { AccountData } from 'src/types/user';
import playsInfoToThumbnail from 'src/utils/playsInfoToThumbnail';

interface IProps {
  open: boolean;
  setOpen: Function;
  targetPlayIDs: string[];
  setSelectedPlayIDs: Function;
}

const RemovePlaysFromPlaybookDialog: FC<IProps> = (props: IProps) => {
  const { open, setOpen, targetPlayIDs, setSelectedPlayIDs } = props;
  const [waitingForFunctionResponse, setWaitingForFunctionResponse] =
    useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { teamId } = useParams();
  const team: Team = useSelector((state: RootState) => state.teamSlice.team);
  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );
  const spaceTier = teamId ? team.spaceTier : accountData.spaceTier;

  const handleClickCancel = () => {
    setOpen(false);
  };

  const fullPlaybook: Playbook = useSelector(
    (state: RootState) => state.playbookSlice.playbook
  );

  const handleClickRemovePlays = async () => {
    setWaitingForFunctionResponse(true);

    try {
      const updatedPlaysInfo: PlayInfo[] = fullPlaybook.playsInfo.filter(
        (playInfo) => !targetPlayIDs.includes(playInfo.id)
      );

      const updatedPlaybook: Playbook = {
        id: fullPlaybook.id,
        title: fullPlaybook.title,
        photoURL: fullPlaybook.photoURL,
        updatedAt: Date.now(),
        createdAt: fullPlaybook.createdAt,
        description: fullPlaybook.description,
        numberOfPlays: updatedPlaysInfo.length,
        thumbnail: playsInfoToThumbnail(updatedPlaysInfo),
        playsInfo: updatedPlaysInfo,
        whitelistedIDs: fullPlaybook.whitelistedIDs
      };

      if (teamId) {
        await updatePlaybook(updatedPlaybook, true, teamId);
      } else {
        await updatePlaybook(updatedPlaybook, true);
      }

      setSelectedPlayIDs([]);

      enqueueSnackbar('Removed plays from playbook', {
        variant: 'success'
      });
    } catch (err) {
      console.error('Removing plays from playbook failed: ', err);
      enqueueSnackbar('Removing plays from playbook failed', {
        variant: 'error'
      });
    }
    setWaitingForFunctionResponse(false);
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        if (!waitingForFunctionResponse) {
          setOpen(false);
        }
      }}
    >
      <DialogContent sx={{ height: 'auto', maxHeight: '750px' }}>
        <Container
          sx={{
            width: '100%',
            height: '100%',
            py: 2
          }}
        >
          {waitingForFunctionResponse ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ height: '150px' }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={12}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sx={{ mt: 3, mb: 4 }}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  You are about to remove {targetPlayIDs.length} play
                  {targetPlayIDs.length === 1 ? null : 's'} from this playbook.
                  <br /> Do you wish to continue ?
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  sx={{}}
                  variant="text"
                  onClick={() => handleClickCancel()}
                  disabled={waitingForFunctionResponse}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  variant="contained"
                  onClick={() => handleClickRemovePlays()}
                  disabled={waitingForFunctionResponse}
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          )}
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default RemovePlaysFromPlaybookDialog;
