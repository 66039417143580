import AddSharpIcon from '@mui/icons-material/AddSharp';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Button, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { customOpacityDarkTheme } from 'src/constants';
import { PlaybookInfo, PlayInfo } from 'src/types/play';
import hexToRGB from 'src/utils/hexToRGB';
import { RemovePlaysFromPlaybookDialog } from './controls';
import AddPlaysToPlaybookDialog from './controls/AddPlaysToPlaybookDialog';
import SortByPlaysListPopover, {
  PlaysSortByOptions,
  sortByOptions
} from './controls/SortByPlaybookDetailsPopover';
import { PlaybookSettingsDialog } from './settings';

interface IProps {
  selectedPlays: string[];
  setSelectedPlays: Function;
  toggleSelectAllPlays: Function;
  allPlays: PlayInfo[];
  setAllPlays: Function;
  playbook: PlaybookInfo;
  addPlaysToPlaybookDialogOpen: boolean;
  setAddPlaysToPlaybookDialogOpen: Function;
  handleAddPlaysToPlaybook: Function;
}

const sortPlays = (plays: PlayInfo[], sortByKey: PlaysSortByOptions) => {
  if (plays === []) {
    return [];
  }

  if (sortByKey === 'createdAsc') {
    return [...plays].sort((x, y) => x.createdAt - y.createdAt);
  }

  if (sortByKey === 'editedAsc') {
    return [...plays].sort((x, y) => x.updatedAt - y.updatedAt);
  }

  if (sortByKey === 'createdDesc') {
    const temp = [...plays].sort((x, y) => x.createdAt - y.createdAt);

    return [...temp].reverse();
  }

  if (sortByKey === 'editedDesc') {
    const temp = [...plays].sort((x, y) => x.updatedAt - y.updatedAt);

    return [...temp].reverse();
  }

  if (sortByKey === 'nameAsc') {
    return [...plays].sort((x, y) =>
      x.title.toLowerCase().localeCompare(y.title.toLowerCase())
    );
  }

  if (sortByKey === 'nameDesc') {
    const temp = [...plays].sort((x, y) =>
      x.title.toLowerCase().localeCompare(y.title.toLowerCase())
    );
    return [...temp].reverse();
  }

  if (sortByKey === 'mapAsc') {
    return [...plays].sort((x, y) =>
      x.map.toLowerCase().localeCompare(y.map.toLowerCase())
    );
  }

  if (sortByKey === 'mapDesc') {
    const temp = [...plays].sort((x, y) =>
      x.map.toLowerCase().localeCompare(y.map.toLowerCase())
    );
    return [...temp].reverse();
  }

  return plays;
};

const PlaybookDetailsControls: FC<IProps> = (props: IProps) => {
  const {
    selectedPlays,
    setSelectedPlays,
    toggleSelectAllPlays,
    allPlays,
    setAllPlays,
    playbook,
    addPlaysToPlaybookDialogOpen,
    setAddPlaysToPlaybookDialogOpen,
    handleAddPlaysToPlaybook
  } = props;
  const theme = useTheme();
  const [selectedSortByOption, setSelectedSortByOption] =
    useState<PlaysSortByOptions>('editedDesc');
  const sortByButtonAnchorRef = useRef<HTMLButtonElement | null>(null);
  const [openSortByPopover, setOpenSortByPopover] = useState<boolean>(false);
  const [
    removePlaysFromPlaybookDialogOpen,
    setRemovePlaysFromPlaybookDialogOpen
  ] = useState<boolean>(false);
  const [playbookSettingsDialogOpen, setPlaybookSettingsDialogOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const sortedPlays = sortPlays(allPlays, selectedSortByOption);

    setAllPlays(sortedPlays);
  }, [selectedSortByOption]);

  const handleClickAddPlays = () => {
    setAddPlaysToPlaybookDialogOpen(true);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        p: 1,
        borderRadius: `${theme.shape.borderRadius}px`
      }}
    >
      {allPlays.length > 0 ? (
        <Grid
          item
          sx={{ width: 'auto' }}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ width: 'auto', mr: 1 }}
          >
            <IconButton
              sx={{
                mr: 1,
                backgroundColor: hexToRGB(
                  theme.palette.primary.main,
                  (customOpacityDarkTheme * 2).toString()
                ),
                '&:hover': {
                  backgroundColor: hexToRGB(
                    theme.palette.primary.main,
                    (customOpacityDarkTheme * 4).toString()
                  )
                }
              }}
              size="small"
              onClick={() => toggleSelectAllPlays()}
            >
              {selectedPlays?.length > 0 ? (
                <IndeterminateCheckBoxIcon />
              ) : (
                <CheckBoxOutlineBlankOutlinedIcon />
              )}
            </IconButton>

            <Typography variant="body1" sx={{ p: 0, m: 0 }}>
              {selectedPlays?.length > 0 ? 'Deselect all' : 'Select all'}
            </Typography>
          </Grid>

          <Typography
            sx={{ mx: 0.5, color: theme.palette.text.secondary }}
            variant="subtitle1"
          >
            |
          </Typography>

          <Typography
            sx={{ mx: 0.5, color: theme.palette.text.secondary }}
            variant="subtitle1"
          >
            {selectedPlays?.length}

            {selectedPlays?.length === 1 && ' Play selected'}

            {selectedPlays?.length !== 1 && ' Plays selected'}
          </Typography>
        </Grid>
      ) : (
        <Grid item />
      )}

      <Grid
        item
        sx={{ width: 'auto' }}
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {selectedPlays.length > 0 && (
          <>
            <Button
              variant="text"
              endIcon={<DeleteOutlineIcon />}
              sx={{ ml: 1 }}
              onClick={() => setRemovePlaysFromPlaybookDialogOpen(true)}
            >
              Remove Play{selectedPlays.length === 1 ? null : 's'}
            </Button>

            <RemovePlaysFromPlaybookDialog
              open={removePlaysFromPlaybookDialogOpen}
              setOpen={setRemovePlaysFromPlaybookDialogOpen}
              targetPlayIDs={selectedPlays}
              setSelectedPlayIDs={setSelectedPlays}
            />
          </>
        )}

        <Button
          variant="text"
          endIcon={<AddSharpIcon />}
          sx={{ ml: 1 }}
          onClick={() => handleClickAddPlays()}
        >
          Add Play(s)
        </Button>
        <AddPlaysToPlaybookDialog
          open={addPlaysToPlaybookDialogOpen}
          setOpen={setAddPlaysToPlaybookDialogOpen}
          excludedPlayIDs={allPlays.map((play) => play.id)}
          submitFunction={handleAddPlaysToPlaybook}
        />

        <Button
          variant="text"
          endIcon={<SettingsOutlinedIcon />}
          sx={{ ml: 1 }}
          onClick={() => setPlaybookSettingsDialogOpen(true)}
        >
          Settings
        </Button>
        <PlaybookSettingsDialog
          open={playbookSettingsDialogOpen}
          setDialogOpen={setPlaybookSettingsDialogOpen}
          playbook={playbook}
        />

        <Button
          variant="text"
          endIcon={<KeyboardArrowDownSharpIcon />}
          sx={{ ml: 1 }}
          ref={sortByButtonAnchorRef}
          onClick={() => setOpenSortByPopover(true)}
        >
          {sortByOptions[selectedSortByOption].label}
        </Button>
        <SortByPlaysListPopover
          selectedSortByOption={selectedSortByOption}
          setSelectedSortByOption={setSelectedSortByOption}
          anchorRef={sortByButtonAnchorRef}
          open={openSortByPopover}
          setOpen={setOpenSortByPopover}
        />
      </Grid>
    </Grid>
  );
};

export default PlaybookDetailsControls;
