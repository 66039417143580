import { Box, ListItemButton, Typography, useTheme } from '@mui/material';
import List from '@mui/material/List';
import * as React from 'react';
import {
  DrawerAccordion,
  DrawerAccordionDetails,
  DrawerAccordionSummary
} from 'src/components/app/board/DrawerAccordion';
import CustomTooltip from 'src/components/CustomTooltip';
import {
  BOLD_LINE_STROKEWIDTH,
  DEFAULT_LINE_STROKEWIDTH,
  LINE_COLOR_OPTIONS
} from 'src/constants';
import { setArrowOptions as setArrowOptionsStore } from 'src/slices/board/boardUi';
import store from 'src/store';
import { ILineOptions } from 'src/types/board';
import ColorSelector from '../ColorSelector';

interface IStrokeFormat {
  strokeWidth: number;
  strokeDashArray: string;
  markerStart: string;
  markerEnd: string;
}

type DrawingDrawerSection = 'color' | 'linetype' | 'tool';

const STROKE_FORMAT_OPTIONS: IStrokeFormat[] = [
  {
    strokeWidth: DEFAULT_LINE_STROKEWIDTH,
    strokeDashArray: null,
    markerStart: null,
    markerEnd: null
  },
  {
    strokeWidth: DEFAULT_LINE_STROKEWIDTH,
    strokeDashArray: null,
    markerStart: null,
    markerEnd: 'arrowhead'
  }
];

const ArrowDrawer: React.FC = () => {
  const theme = useTheme();

  const [arrowOptions, setArrowOptions] = React.useState<ILineOptions>(
    store.getState().boardSlice.ui.arrowOptions
  );

  const [expanded, setExpanded] = React.useState<DrawingDrawerSection[]>([
    'color',
    'linetype',
    'tool'
  ]);

  const handleExpandPanel = (panel: DrawingDrawerSection) => {
    setExpanded(expanded.concat([panel]));
  };

  const handleContractPanel = (panel: DrawingDrawerSection) => {
    setExpanded(expanded.filter((item) => item !== panel));
  };

  const handleStrokeFormatClick = (newValue: IStrokeFormat) => () => {
    if (newValue) {
      const newLineOption: ILineOptions = {
        ...arrowOptions,
        markerEnd: newValue.markerEnd
      };
      setArrowOptions(newLineOption);
      store.dispatch(setArrowOptionsStore(newLineOption));
    }
  };
  const handleColorSelect = (newColor) => {
    if (newColor) {
      const newLineOptions: ILineOptions = { ...arrowOptions, color: newColor };
      setArrowOptions(newLineOptions);
      store.dispatch(setArrowOptionsStore(newLineOptions));
    }
  };

  const renderStrokeItems = (options: IStrokeFormat[]) => {
    const items = [];
    options.forEach((value: IStrokeFormat) => {
      items.push(
        <ListItemButton
          sx={{
            borderRadius: `${theme.shape.borderRadius}px`,
            mb: 1,
            height: 36,
            pt: 0.75
          }}
          key={`${value.strokeDashArray}-${value.strokeWidth}`}
          onClick={handleStrokeFormatClick(value)}
          selected={value.markerEnd === arrowOptions?.markerEnd}
        >
          <svg width="168" height="16.258" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <marker
                id="arrowhead"
                viewBox="0 0 10 10"
                refX="1"
                refY="5"
                markerWidth="6"
                markerHeight="6"
                orient="auto"
              >
                <path d="M 0 0 L 10 5 L 0 10 z" fill={arrowOptions.color} />
              </marker>
            </defs>

            <path
              d="M 10 10 L 146 10"
              markerEnd={`url(#${value.markerEnd})`}
              markerStart={`url(#${value.markerStart})`}
              stroke={arrowOptions.color}
              strokeDasharray={value.strokeDashArray}
              strokeLinecap="round"
              strokeWidth={
                value.strokeWidth === DEFAULT_LINE_STROKEWIDTH
                  ? DEFAULT_LINE_STROKEWIDTH
                  : BOLD_LINE_STROKEWIDTH * 1.3
              }
            />
          </svg>
        </ListItemButton>
      );
    });
    return items;
  };

  return (
    <Box>
      <DrawerAccordion
        expanded={expanded.includes('color')}
        onChange={
          expanded.includes('color')
            ? () => handleContractPanel('color')
            : () => handleExpandPanel('color')
        }
      >
        <DrawerAccordionSummary sx={{ height: '32px !important' }}>
          <Typography variant="subtitle1">Color</Typography>
        </DrawerAccordionSummary>
        <DrawerAccordionDetails>
          <ColorSelector
            colors={LINE_COLOR_OPTIONS}
            onSelect={handleColorSelect}
            selectedValue={arrowOptions?.color}
            textColors={false}
          />
        </DrawerAccordionDetails>
      </DrawerAccordion>
      <DrawerAccordion
        expanded={expanded.includes('linetype')}
        onChange={
          expanded.includes('linetype')
            ? () => handleContractPanel('linetype')
            : () => handleExpandPanel('linetype')
        }
      >
        <DrawerAccordionSummary>
          <Typography variant="subtitle1">Line Type</Typography>
        </DrawerAccordionSummary>
        <DrawerAccordionDetails sx={{ px: 3 }}>
          <List>{renderStrokeItems(STROKE_FORMAT_OPTIONS)}</List>
        </DrawerAccordionDetails>
      </DrawerAccordion>
    </Box>
  );
};

export default ArrowDrawer;
