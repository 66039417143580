import { StorageReference } from '@firebase/storage';
import {
  Box,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Step,
  StepLabel,
  Stepper
} from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateTeamStepOne, {
  CreateTeamStepOneData
} from 'src/components/app/createTeam/CreateTeamStepOne';
import CreateTeamStepThree, {
  CreateTeamStepThreeData
} from 'src/components/app/createTeam/CreateTeamStepThree';
import CreateTeamStepTwo, {
  ChipItem
} from 'src/components/app/createTeam/CreateTeamStepTwo';
import CustomDialog from 'src/components/CustomDialog';
import CustomDialogHeader from 'src/components/CustomDialogHeader';
import { addTeam } from 'src/data/firestore/setters/team';
import useAuth from 'src/hooks/useAuth';
import { RootState, useSelector } from 'src/store';
import { Team, TeamInviteTeamside } from 'src/types/team';
import { removeImageByRef } from '../ImageUploader';
import createResourceId from 'src/utils/createResourceId';

const steps = [
  'Enter Team Information',
  'Invite Teammates',
  'Confirm Team Creation'
];

interface CreateTeamDialogProps {
  open: boolean;
  setDialogOpen: Function;
}

const toTeamInvites = (chipItems: ChipItem[]): TeamInviteTeamside[] =>
  chipItems.map((chip) => ({
    inviteeEmail: chip.email,
    inviteeUsername: chip.username,
    inviterID: null,
    inviterDisplayName: null,
    inviterPhotoURL: null,
    createdAt: null,
    id: null
  }));

const CreateTeamDialog: FC<CreateTeamDialogProps> = (props) => {
  const { open, setDialogOpen } = props;
  const [activeStep, setActiveStep] = useState(0);
  // team name(required), description & photoURL
  const [stepOneData, setStepOneData] = useState<CreateTeamStepOneData | null>(
    null
  );

  // array of usernames of invitees
  const [stepTwoData, setStepTwoData] = useState<ChipItem[]>([]);

  const { user } = useAuth();
  const username = useSelector((state: RootState) =>
    state.accountDataSlice.accountData
      ? state.accountDataSlice.accountData.username
      : null
  );

  const [waitingForImage, setWaitingForImage] = useState<boolean>(false);
  const [waitingForTeamCreation, setWaitingForTeamCreation] =
    useState<boolean>(false);

  // used to delet uplaoded image when component unmounts ()
  const [uploadedImageRef, setUploadedImageRef] =
    useState<StorageReference | null>(null);

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleResetActiveStep = () => {
    setActiveStep(0);
  };

  const handleSubmitStepOneData = (data: CreateTeamStepOneData) => {
    setStepOneData(data);
    handleNext();
  };

  const handleSubmitStepTwoData = (
    data: ChipItem[],
    direction: 'forward' | 'back'
  ) => {
    setStepTwoData(data);
    if (direction === 'forward') {
      handleNext();
    } else {
      handleBack();
    }
  };

  const handleSubmitStepThreeData = async (data: CreateTeamStepThreeData) => {
    setWaitingForTeamCreation(true);
    handleNext();

    const newTeamObject: Team = {
      id: null,
      displayName: data.name,
      createdAt: moment().toDate().getTime(),
      description: data.description === '' ? null : data.description,
      membersCount: 1,
      membersInfo: [
        {
          id: user.id,
          username,
          displayName: user.displayName,
          joinedAt: moment().toDate().getTime(),
          photoURL: user.photoURL
        }
      ],
      adminIDs: [user.id],
      memberIDs: [user.id],
      pendingInvites: [],
      playsInfo: [],
      playbooksInfo: [],
      photoURL: data.photoURL === '' ? null : data.photoURL,
      sessionRefs: null
    };

    const response = await addTeam(
      newTeamObject,
      toTeamInvites(data.invitees),
      user.displayName,
      user.photoURL,
      enqueueSnackbar
    );

    if (response) {
      if (response.id) {
        setDialogOpen(false);
        navigate(`/app/team/${response.id}`);
      }
    }
  };

  const handleResetStepOneData = () => {
    setStepOneData(null);
  };

  const handleResetStepTwoData = () => {
    setStepTwoData([]);
  };

  const handleBackToStepTwo = () => {
    handleBack();
  };

  const onClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      if (uploadedImageRef) {
        if (!waitingForTeamCreation && !waitingForImage) {
          removeImageByRef(uploadedImageRef);
        }
      }
    }

    if (!waitingForTeamCreation && !waitingForImage) {
      setDialogOpen(false);
      handleResetActiveStep();
      handleResetStepOneData();
      handleResetStepTwoData();
    }
  };

  return (
    <CustomDialog
      open={open}
      setOpen={setDialogOpen}
      onClose={onClose}
      title="Create a new Team"
      width={740}
      buttons={[]}
    >
      <Container
        sx={{
          width: '100%',
          height: '100%',
          pb: 2,
          maxHeight: '750px'
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box sx={{ width: '100%', mt: 2, mb: 6 }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          {activeStep === 0 && (
            <CreateTeamStepOne
              submitFunction={handleSubmitStepOneData}
              stepOneData={stepOneData}
              setUploadedImageRef={setUploadedImageRef}
              waitingForImage={waitingForImage}
              setWaitingForImage={setWaitingForImage}
            />
          )}

          {activeStep === 1 && (
            <CreateTeamStepTwo
              stepTwoData={stepTwoData}
              submitFunction={handleSubmitStepTwoData}
            />
          )}

          {activeStep === 2 && (
            <CreateTeamStepThree
              backToStepTwo={handleBackToStepTwo}
              stepTwoData={stepTwoData}
              stepOneData={stepOneData}
              submitFunction={handleSubmitStepThreeData}
            />
          )}

          {activeStep > 2 && waitingForTeamCreation && (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ height: '300px' }}
            >
              <CircularProgress />
            </Grid>
          )}
        </Box>
      </Container>
    </CustomDialog>
  );
};

CreateTeamDialog.propTypes = {
  open: PropTypes.bool,
  setDialogOpen: PropTypes.func
};
export { CreateTeamDialog };
export default CreateTeamDialog;
