import CheckIcon from '@mui/icons-material/Check';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useTheme
} from '@mui/material';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import CircularLoadingDialog from 'src/components/CircularLoadingDialog';
import { stripeConfig } from 'src/config';
import {
  basicFeaturesTeam,
  proFeaturesTeam,
  subscriptionTierBasicColor,
  subscriptionTierProColor
} from 'src/constants';
import {
  createCheckoutSession,
  listProductsAndPrices,
  redirectToCustomerPortal
} from 'src/data/firestore/setters/subscription';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import { RootState, useSelector } from 'src/store';
import { ProTier } from 'src/types/subscription';
import { Team, TeamMemberInfo } from 'src/types/team';
import hexToRGB from 'src/utils/hexToRGB';

const priceToInterval = (price) => {
  if (!price) {
    return '';
  }

  if (price.interval === 'month') {
    if (price.intervalCount === 1) {
      return 'monthly';
    }

    return 'biannually';
  }

  return 'annually';
};

const TeamSubscriptionSettings: FC = () => {
  const [product, setProduct] = useState(null);
  const [price, setPrice] = useState(null);
  const [redirecting, setRedirecting] = useState(false);
  const theme = useTheme();
  const team: Team = useSelector((state: RootState) => state.teamSlice.team);
  const { user } = useAuth();
  const { settings } = useSettings();
  useEffect(() => {
    async function getProducts() {
      const response = await listProductsAndPrices();

      const targetProduct = response.find(
        (someProduct) => someProduct.productId === stripeConfig.teamProductId
      );

      console.log('targetPRODUCT: ', targetProduct);

      setProduct(targetProduct);
    }

    getProducts();
  }, []);

  useEffect(() => {
    if (team.spaceTier === 'basic') {
      setPrice(product?.prices[0]);
    } else {
      const teamTierCasted: ProTier = team.spaceTier;

      console.log('teamTierCasted', teamTierCasted);

      setPrice(
        product?.prices.find(
          (iterationPrice) =>
            iterationPrice.interval === teamTierCasted.interval &&
            iterationPrice.intervalCount === teamTierCasted.intervalCount
        )
      );
    }
  }, [product]);

  const teamMemberById = (id) => {
    const targetMember: TeamMemberInfo = team.membersInfo.find(
      (member) => member.id === id
    );

    return targetMember;
  };

  console.log(user.id);
  const priceFromInterval = (interval) => {
    if (interval === 'monthly') {
      return product?.prices.find(
        (price_i) => price_i.intervalCount === 1 && price_i.interval === 'month'
      );
    }

    if (interval === 'biannually') {
      return product?.prices.find(
        (price_i) => price_i.intervalCount === 6 && price_i.interval === 'month'
      );
    }

    return product?.prices.find((price_i) => price_i.interval === 'year');
  };

  return (
    <>
      {redirecting && <CircularLoadingDialog title="Redirecting ..." />}
      {!product || !price || !team ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%', height: 300 }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid
            container
            columnSpacing={3}
            rowSpacing={2}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {team.spaceTier === 'basic' && (
              <Grid item container xs={12} sx={{ mb: 1 }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                      p: 0.5,
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: `${theme.shape.borderRadius}px`
                    }}
                  >
                    <Button
                      sx={{
                        width: 176,
                        backgroundColor:
                          priceToInterval(price) !== 'monthly' && 'transparent'
                      }}
                      onClick={() => setPrice(priceFromInterval('monthly'))}
                    >
                      Monthly{' '}
                    </Button>
                    <Button
                      sx={{
                        width: 176,
                        mx: 0.5,
                        backgroundColor:
                          priceToInterval(price) !== 'biannually' &&
                          'transparent'
                      }}
                      onClick={() => setPrice(priceFromInterval('biannually'))}
                    >
                      Biannually
                      <Box
                        sx={{
                          backgroundColor: subscriptionTierProColor,
                          px: '5px',
                          borderRadius: 99,
                          ml: 0.5
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 9,
                            fontWeight: 600
                          }}
                        >
                          Save 1 month
                        </Typography>
                      </Box>
                    </Button>
                    <Button
                      sx={{
                        width: 176,
                        backgroundColor:
                          priceToInterval(price) !== 'annually' && 'transparent'
                      }}
                      onClick={() => setPrice(priceFromInterval('annually'))}
                    >
                      Annually
                      <Box
                        sx={{
                          backgroundColor: subscriptionTierProColor,
                          px: '5px',
                          borderRadius: 99,
                          ml: 0.5
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 9,
                            fontWeight: 600
                          }}
                        >
                          Save 3 months
                        </Typography>
                      </Box>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )}

            <Grid item container xs={6} justifyContent="center">
              <Grid
                item
                direction="column"
                container
                xs={12}
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  border:
                    team.spaceTier === 'basic'
                      ? `2px solid #ffffff`
                      : `1px solid ${theme.palette.divider}`,
                  borderRadius: `${theme.shape.borderRadius}px`,
                  p: 5,
                  position: 'relative'
                }}
              >
                {team.spaceTier === 'basic' && (
                  <Box
                    sx={{
                      backgroundColor: '#ffffff',
                      position: 'absolute',
                      top: -11,
                      right: 40,
                      px: 1.5,
                      py: 0.25,
                      borderRadius: `${theme.shape.borderRadius}px`
                    }}
                  >
                    {' '}
                    <Typography
                      sx={{
                        color: theme.palette.background.default,
                        fontSize: 11,
                        fontWeight: 600
                      }}
                    >
                      ACTIVE
                    </Typography>{' '}
                  </Box>
                )}

                <Grid
                  item
                  sx={{
                    backgroundColor: hexToRGB(
                      subscriptionTierBasicColor,
                      '0.1'
                    ),
                    border: `1px solid ${subscriptionTierBasicColor}`,
                    px: 2,
                    py: 0.1,
                    borderRadius: `${theme.shape.borderRadius}px`
                  }}
                >
                  <Typography sx={{ fontWeight: 500, fontSize: 15 }}>
                    Basic Tier
                  </Typography>
                </Grid>

                <Typography
                  sx={{ fontWeight: 500, fontSize: 26, pt: 1.5, pb: 0.5 }}
                >
                  FREE
                </Typography>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mb: 3 }}
                >
                  No Payment Details required
                </Typography>
                <Divider sx={{ width: '100%', mb: 3 }} />
                {basicFeaturesTeam.map((feature) => (
                  <Grid
                    key={feature}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ py: 1, pl: 1 }}
                  >
                    <CheckIcon
                      sx={{
                        color: subscriptionTierBasicColor,
                        backgroundColor: hexToRGB(
                          subscriptionTierBasicColor,
                          '0.3'
                        ),
                        borderRadius: 99,
                        p: '2px'
                      }}
                    />

                    <Typography sx={{ ml: 2 }}>{feature}</Typography>
                  </Grid>
                ))}

                {team.spaceTier !== 'basic' &&
                  team.spaceTier.subscriberId === user.id &&
                  !team.spaceTier.cancelAt && (
                    <Button
                      variant="text"
                      onClick={() => {
                        redirectToCustomerPortal();
                        setRedirecting(true);
                      }}
                      sx={{ mt: 4, width: 170 }}
                    >
                      Downgrade
                    </Button>
                  )}
              </Grid>
            </Grid>

            <Grid item container xs={6} justifyContent="center">
              <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={12}
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  border:
                    team.spaceTier === 'basic'
                      ? `1px solid ${theme.palette.divider}`
                      : `2px solid #ffffff`,
                  borderRadius: `${theme.shape.borderRadius}px`,
                  p: 5,
                  position: 'relative'
                }}
              >
                {team.spaceTier !== 'basic' && (
                  <Box
                    sx={{
                      backgroundColor: '#ffffff',
                      position: 'absolute',
                      top: -11,
                      right: 40,
                      px: 1.5,
                      py: 0.25,
                      borderRadius: `${theme.shape.borderRadius}px`
                    }}
                  >
                    {' '}
                    <Typography
                      sx={{
                        fontSize: 11,
                        fontWeight: 600,
                        color: theme.palette.background.default
                      }}
                    >
                      ACTIVE
                    </Typography>{' '}
                  </Box>
                )}

                <Grid
                  item
                  sx={{
                    backgroundColor: hexToRGB(subscriptionTierProColor, '0.1'),
                    border: `1px solid ${subscriptionTierProColor}`,
                    px: 2,
                    py: 0.1,
                    borderRadius: `${theme.shape.borderRadius}px`
                  }}
                >
                  <Typography sx={{ fontWeight: 500, fontSize: 15 }}>
                    Pro Tier
                  </Typography>
                </Grid>

                <Typography
                  sx={{ fontWeight: 500, fontSize: 26, pt: 1.5, pb: 0.5 }}
                >
                  ${(price?.amount / 100)?.toFixed(2)}
                </Typography>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mb: 3 }}
                >
                  {priceToInterval(price) === 'monthly' && '/ per month'}
                  {priceToInterval(price) === 'biannually' &&
                    '/ every 6 months'}
                  {priceToInterval(price) === 'annually' && '/ every year'}
                </Typography>

                {team.spaceTier !== 'basic' && team.spaceTier.cancelAt && (
                  <Alert
                    severity="error"
                    sx={{
                      py: 1,
                      mt: 1,
                      mb: 4,
                      border: `1px solid ${theme.palette.error.main}`,
                      backgroundColor: hexToRGB(theme.palette.error.main, '0.1')
                    }}
                  >
                    <AlertTitle>
                      {' '}
                      Canceled {moment(team.spaceTier.cancelAt).fromNow()}{' '}
                    </AlertTitle>
                    You will still be able to use all PRO features until the end
                    of this subscription period.
                  </Alert>
                )}

                <Divider sx={{ width: '100%', mb: 3 }} />

                {proFeaturesTeam.map((feature) => (
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ py: 1, pl: 1 }}
                    key={feature}
                  >
                    <CheckIcon
                      sx={{
                        color: subscriptionTierProColor,
                        backgroundColor: hexToRGB(
                          subscriptionTierProColor,
                          '0.3'
                        ),
                        borderRadius: 99,
                        p: '2px'
                      }}
                    />

                    <Typography sx={{ ml: 2 }}>{feature}</Typography>
                  </Grid>
                ))}

                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: 2 }}
                >
                  {team.spaceTier === 'basic' &&
                    'This subscription unlocks premium features for the ENTIRE team space. Individual team members do NOT need to subscribe again to use these features.'}
                  {team.spaceTier !== 'basic' &&
                    team.spaceTier.subscriberId === user.id &&
                    `You are paying for this subscription. You thus unlock premium features for the entire team space.`}
                  {team.spaceTier !== 'basic' &&
                    team.spaceTier.subscriberId !== user.id &&
                    `${
                      teamMemberById(team.spaceTier.subscriberId)
                        ?.displayName || 'A former member'
                    } is paying for this subscription. Only that user can modify the status of the subscription or terminate it.`}
                </Typography>

                {team.spaceTier === 'basic' && (
                  <Button
                    variant="contained"
                    sx={{
                      mt: 3.5,
                      width: 170,
                      backgroundColor: subscriptionTierProColor
                    }}
                    onClick={() => {
                      createCheckoutSession(
                        price.priceId,
                        settings.activeSpaceID || null, // null if personal space
                        user.id
                      );
                      setRedirecting(true);
                    }}
                  >
                    {team.spaceTier === 'basic' && 'Upgrade'}
                  </Button>
                )}

                {team.spaceTier !== 'basic' &&
                  team.spaceTier.subscriberId === user.id && (
                    <Button
                      variant="contained"
                      sx={{
                        mt: 3.5,
                        width: 170,
                        backgroundColor: subscriptionTierProColor
                      }}
                      onClick={() => {
                        redirectToCustomerPortal();
                        setRedirecting(true);
                      }}
                    >
                      Manage Subscription
                    </Button>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default TeamSubscriptionSettings;
