import RotateRightIcon from '@mui/icons-material/RotateRight';
import { IconButton, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import CustomTooltip from 'src/components/CustomTooltip';
import { rotateMap } from 'src/slices/board/boardModel';
import store from 'src/store';

interface IProps {
  sx?: SxProps<Theme>;
}

const RotateMapButton: FC<IProps> = (props: IProps): ReactElement => {
  const { sx } = props;
  const { playId: sessionId, teamId } = useParams();

  const handleClick = () => {
    store.dispatch(rotateMap(teamId ? sessionId : null));
  };

  return (
    <CustomTooltip title="Rotate Map" placement="top">
      <IconButton onClick={handleClick} sx={{ ...sx }} size="large">
        <RotateRightIcon />
      </IconButton>
    </CustomTooltip>
  );
};

export default RotateMapButton;
