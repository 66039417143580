import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme
} from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { SessionUser } from 'src/types/session';
import CustomAvatar from '../CustomAvatar';
import StyledBadge from '../StyledBadge';

interface IProps {
  users: SessionUser[];
  sizeFactor?: number;
  rippleActivated?: boolean;
}

const ExtraUserPopover: React.FC<IProps> = (props: IProps) => {
  const { users, sizeFactor, rippleActivated } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div key="extra-user-popover">
      <span
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        style={{
          left: -8,

          position: 'relative',
          display: 'inline-flex'
        }}
      >
        <CustomAvatar
          alt={`+${users.length}`}
          text={`+${users.length}`}
          src={null}
          isTeamAvatar={false}
          sizeFactor={sizeFactor}
        />
      </span>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none'
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List dense sx={{ height: '100%' }}>
          {users.map((user) => (
            <ListItem
              key={`${user.displayName}-${user.id}-list-item`}
              sx={{ pl: 1, pr: 1 }}
            >
              <ListItemIcon>
                <StyledBadge
                  isonline={user.active && 'true'}
                  rippleactivated={rippleActivated}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant="dot"
                >
                  <CustomAvatar
                    src={user.photoURL}
                    alt={user.displayName}
                    sizeFactor={0.5}
                    isTeamAvatar={false}
                  />
                </StyledBadge>
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: `${theme.palette.text.primary} !important`,
                  width: '100%'
                }}
              >
                <Typography variant="body1" color="textSecondary">
                  {user.displayName}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
};

export default ExtraUserPopover;
