import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from 'src/store';
import { IConnections } from 'src/types/board';

const useConnections = (): IConnections => {
  const connections: IConnections = useSelector((state: RootState) => {
    if (
      state?.boardSlice?.model?.stepLayers &&
      state.boardSlice.model.connections
    ) {
      const { activeStep } = state.boardSlice.model;
      const connectionIds = new Set(
        state.boardSlice.model.stepLayers[activeStep]?.connections
      );
      return Object.fromEntries(
        Object.entries(state.boardSlice.model.connections).filter(([, val]) =>
          connectionIds.has(val.id)
        )
      );
    }
    return undefined;
  }, shallowEqual);

  return connections;
};

export default useConnections;
