import { IStepLayer } from 'src/types/board';

const updateStepLayerIds = (
  stepLayer: IStepLayer,
  id: string,
  reference: 'lines' | 'objects' | 'connections'
): IStepLayer => {
  if (!stepLayer) {
    return null;
  }
  if (!stepLayer[reference]) {
    stepLayer[reference] = [id];
  } else {
    stepLayer[reference] = [...stepLayer[reference], id];
  }

  return stepLayer;
};

export { updateStepLayerIds };
