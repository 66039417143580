import { Grid, Paper, Theme, useTheme } from '@mui/material';
import { FC, ReactElement } from 'react';
import RedoButton from './RedoButton';
import RotateMapButton from './RotateMapButton';
import UndoButton from './UndoButton';

const NavigationTools: FC = (): ReactElement => {
  const theme: Theme = useTheme();

  return (
    <div
      className="cb-nav-tools"
      style={{
        position: 'absolute',
        zIndex: 100,
        bottom: 0,
        right: 0,
        marginRight: theme.spacing(38)
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid
          item
          sx={{
            margin: theme.spacing(2),

            borderRadius: `${theme.shape.borderRadius}px`
          }}
        >
          <Paper
            sx={{
              backgroundColor: '#37383D',
              border: `1px solid ${theme.palette.divider}`,
              boxShadow: theme.shadows[19]
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Grid item>
                <UndoButton sx={{ border: 0, margin: theme.spacing(0.5) }} />
              </Grid>
              <Grid item>
                <RedoButton sx={{ border: 0, margin: theme.spacing(0.5) }} />
              </Grid>
              <Grid item>
                <RotateMapButton
                  sx={{ border: 0, margin: theme.spacing(0.5) }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default NavigationTools;
