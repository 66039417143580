import AddSharpIcon from '@mui/icons-material/AddSharp';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import { Button, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import CustomDialog from 'src/components/CustomDialog';
import UpgradeSubscriptionDialogContent from 'src/components/UpgradeSubscriptionDialogContent';
import { basicTierMaxPlaybooks, customOpacityDarkTheme } from 'src/constants';
import { useSelector, RootState } from 'src/store';
import { backgroundColorSidebar } from 'src/theme/dark-theme-options';
import { PlayInfo } from 'src/types/play';
import { Team } from 'src/types/team';
import { AccountData } from 'src/types/user';
import hexToRGB from 'src/utils/hexToRGB';
import { playSortByOptions, PlaysSortByOptions } from 'src/utils/sortPlays';
import { AccountSettingsDialog } from '../account';
import { CreatePlaybookDialog } from '../createPlaybook';
import { TeamSettingsDialog } from '../team';
import {
  MultiClonePlaysDialog,
  MultiDeletePlaysDialog,
  SortByPlaysListPopover
} from './controls';

interface IProps {
  selectedPlays: string[];
  setSelectedPlays: Function;
  toggleSelectAllPlays: Function;
  allPlays: PlayInfo[];
  handleCreatePlay: Function;
  selectedSortByOption: PlaysSortByOptions;
  setSelectedSortByOption: Function;
}

const PlaysListControls: FC<IProps> = (props: IProps) => {
  const {
    selectedPlays,
    setSelectedPlays,
    toggleSelectAllPlays,
    allPlays,
    handleCreatePlay,
    selectedSortByOption,
    setSelectedSortByOption
  } = props;
  const theme = useTheme();
  const sortByButtonAnchorRef = useRef<HTMLButtonElement | null>(null);
  const [openSortByPopover, setOpenSortByPopover] = useState<boolean>(false);
  const [createPlaybookDialogOpen, setCreatePlaybookDialogOpen] =
    useState<boolean>(false);
  const [multiDeletePlaysOpen, setMultiDeletePlaysOpen] =
    useState<boolean>(false);
  const [multiClonePlaysOpen, setMultiClonePlaysOpen] =
    useState<boolean>(false);

  const [
    upgradeSubscriptionDialogCloneOpen,
    setUpgradeSubscriptionDialogCloneOpen
  ] = useState<boolean>(false);
  const [
    upgradeSubscriptionDialogMergeOpen,
    setUpgradeSubscriptionDialogMergeOpen
  ] = useState<boolean>(false);

  const [subscriptionSettingsOpen, setSubscriptionSettingsOpen] =
    useState<boolean>(false);

  const { teamId } = useParams();

  const team: Team = useSelector((state: RootState) => state.teamSlice.team);

  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );

  const activeWorkspaceData = teamId ? team : accountData;

  const handleOpenSubscriptionDialog = () => {
    setUpgradeSubscriptionDialogMergeOpen(false);
    setUpgradeSubscriptionDialogCloneOpen(false);
    setSubscriptionSettingsOpen(true);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      sx={{
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        p: 1,
        borderRadius: `${theme.shape.borderRadius}px`,
        boxShadow: theme.shadows[24]
      }}
    >
      {allPlays?.length > 0 ? (
        <Grid
          item
          sx={{ width: 'auto' }}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{ width: 'auto', mr: 1 }}
          >
            <IconButton
              sx={{
                mr: 1,
                backgroundColor: hexToRGB(
                  theme.palette.primary.main,
                  (customOpacityDarkTheme * 2).toString()
                ),
                '&:hover': {
                  backgroundColor: hexToRGB(
                    theme.palette.primary.main,
                    (customOpacityDarkTheme * 4).toString()
                  )
                }
              }}
              size="small"
              onClick={() => toggleSelectAllPlays()}
            >
              {selectedPlays?.length > 0 ? (
                <IndeterminateCheckBoxIcon />
              ) : (
                <CheckBoxOutlineBlankOutlinedIcon />
              )}
            </IconButton>

            <Typography variant="body1" sx={{ p: 0, m: 0 }}>
              {selectedPlays?.length > 0 ? 'Deselect all' : 'Select all'}
            </Typography>
          </Grid>

          <Typography
            sx={{ mx: 0.5, color: theme.palette.text.secondary }}
            variant="subtitle1"
          >
            |
          </Typography>

          <Typography
            sx={{ mx: 0.5, color: theme.palette.text.secondary }}
            variant="subtitle1"
          >
            {selectedPlays?.length}

            {selectedPlays?.length === 1 && ' Play selected'}

            {selectedPlays?.length !== 1 && ' Plays selected'}
          </Typography>
        </Grid>
      ) : (
        <Grid item />
      )}

      <Grid
        item
        sx={{ width: 'auto' }}
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {selectedPlays.length > 0 && (
          <>
            <Button
              variant="text"
              endIcon={<DeleteOutlineIcon />}
              sx={{ ml: 1 }}
              onClick={() => setMultiDeletePlaysOpen(true)}
            >
              Delete Play{selectedPlays.length === 1 ? null : 's'}
            </Button>
            <MultiDeletePlaysDialog
              open={multiDeletePlaysOpen}
              setOpen={setMultiDeletePlaysOpen}
              targetPlayIDs={selectedPlays}
              setSelected={setSelectedPlays}
            />
          </>
        )}

        {selectedPlays.length > 0 && (
          <>
            <Button
              variant="text"
              endIcon={<ControlPointDuplicateIcon />}
              sx={{ ml: 1 }}
              onClick={
                activeWorkspaceData.spaceTier === 'basic'
                  ? () => setUpgradeSubscriptionDialogCloneOpen(true)
                  : () => setMultiClonePlaysOpen(true)
              }
            >
              Clone play{selectedPlays.length === 1 ? null : 's'}
            </Button>
            <MultiClonePlaysDialog
              open={multiClonePlaysOpen}
              setOpen={setMultiClonePlaysOpen}
              targetPlayIDs={selectedPlays}
              setSelected={setSelectedPlays}
            />
          </>
        )}

        {selectedPlays.length > 0 && (
          <Button
            variant="text"
            endIcon={<CallMergeIcon />}
            onClick={
              activeWorkspaceData.spaceTier === 'basic' &&
              activeWorkspaceData.playbooksInfo.length >= basicTierMaxPlaybooks
                ? () => setUpgradeSubscriptionDialogMergeOpen(true)
                : () => setCreatePlaybookDialogOpen(true)
            }
            sx={{ ml: 1 }}
          >
            Merge to Playbook
          </Button>
        )}
        <CreatePlaybookDialog
          setDialogOpen={setCreatePlaybookDialogOpen}
          open={createPlaybookDialogOpen}
          preselectedPlays={selectedPlays}
        />

        <CustomDialog
          open={upgradeSubscriptionDialogCloneOpen}
          setOpen={setUpgradeSubscriptionDialogCloneOpen}
          title="Upgrade your space"
          width={400}
          buttons={[
            {
              variant: 'text',
              title: 'Cancel',
              onClick: () => setUpgradeSubscriptionDialogCloneOpen(false)
            },
            {
              variant: 'contained',
              title: 'Upgrade',
              onClick: () => handleOpenSubscriptionDialog()
            }
          ]}
        >
          <UpgradeSubscriptionDialogContent
            blockedContentType="clone_plays"
            isTeamSpace={!(teamId === null)}
          />
        </CustomDialog>

        <CustomDialog
          open={upgradeSubscriptionDialogMergeOpen}
          setOpen={setUpgradeSubscriptionDialogMergeOpen}
          title="Upgrade your space"
          width={420}
          buttons={[
            {
              variant: 'text',
              title: 'Cancel',
              onClick: () => setUpgradeSubscriptionDialogMergeOpen(false)
            },
            {
              variant: 'contained',
              title: 'Upgrade',
              onClick: () => handleOpenSubscriptionDialog()
            }
          ]}
        >
          <UpgradeSubscriptionDialogContent
            blockedContentType="merge_plays"
            isTeamSpace={!(teamId === null)}
          />
        </CustomDialog>

        <Button
          variant="text"
          endIcon={<AddSharpIcon />}
          sx={{ ml: 1 }}
          onClick={() => handleCreatePlay()}
        >
          Create Play
        </Button>

        <Button
          variant="text"
          endIcon={<KeyboardArrowDownSharpIcon />}
          sx={{ ml: 1 }}
          ref={sortByButtonAnchorRef}
          onClick={() => setOpenSortByPopover(true)}
        >
          {playSortByOptions[selectedSortByOption].label}
        </Button>
        <SortByPlaysListPopover
          selectedSortByOption={selectedSortByOption}
          setSelectedSortByOption={setSelectedSortByOption}
          anchorRef={sortByButtonAnchorRef}
          open={openSortByPopover}
          setOpen={setOpenSortByPopover}
        />

        {teamId ? (
          <TeamSettingsDialog
            initialTab="subscription"
            open={subscriptionSettingsOpen}
            setDialogOpen={setSubscriptionSettingsOpen}
          />
        ) : (
          <AccountSettingsDialog
            initialTab="subscription"
            open={subscriptionSettingsOpen}
            setDialogOpen={setSubscriptionSettingsOpen}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PlaysListControls;
