import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import { FC, useState } from 'react';
import TeamInviteEmailInput from '../team/TeamInviteEmailInput';
import UserSearch from '../UserSearch';

interface CreateTeamStepTwoProps {
  stepTwoData: ChipItem[];
  submitFunction: Function;
}

export interface ChipItem {
  username: string;
  email: string;
  displayName: string;
  photoURL: string | null;
}

const CreateTeamStepTwo: FC<CreateTeamStepTwoProps> = (props) => {
  const { submitFunction, stepTwoData } = props;
  const [invitees, setInvitees] = useState<ChipItem[]>(stepTwoData || []);
  const theme = useTheme();
  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        sx={{ pb: 3 }}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <UserSearch
          invitees={invitees}
          setInvitees={setInvitees}
          hideUsernames={[]}
        />
        <Grid item xs={12}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              mt: 2
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Divider orientation="horizontal" />
            </Box>
            <Typography color="textSecondary" sx={{ m: 2 }} variant="body1">
              OR
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <Divider orientation="horizontal" />
            </Box>
          </Box>
          <Typography
            align="center"
            variant="body1"
            sx={{
              color: `${theme.palette.text.secondary} !important`
            }}
          >
            Invite via email address
          </Typography>
          <TeamInviteEmailInput invitees={invitees} setInvitees={setInvitees} />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          sx={{}}
          variant="text"
          startIcon={<ArrowBackIcon />}
          onClick={() => submitFunction(invitees, 'back')}
        >
          Back
        </Button>

        <Button
          sx={{}}
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          onClick={() => submitFunction(invitees, 'forward')}
        >
          Next
        </Button>
      </Grid>

      <Grid item />
    </Grid>
  );
};

CreateTeamStepTwo.propTypes = {
  stepTwoData: PropTypes.array,
  submitFunction: PropTypes.func
};

export default CreateTeamStepTwo;
