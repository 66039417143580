import { ref } from '@firebase/storage';
import {
  addDoc,
  arrayUnion,
  collection,
  doc,
  updateDoc
} from 'firebase/firestore';
import { httpsCallable, HttpsCallableResult } from 'firebase/functions';
import moment from 'moment';
import { terminateTeamListener } from 'src/data';
import { trackCreateTeam } from 'src/data/mixpanel/setters/trackEvent';
import { auth, firestore, functions, storage } from 'src/lib/firebase';
import {
  AcceptTeamInvitationInput,
  DeclineTeamInvitationInput,
  DeleteTeamInput,
  InviteToTeamInput,
  LeaveTeamInput,
  PromoteToTeamAdminInput,
  RemoveTeamMemberInput,
  UpdateTeamInformationInput
} from 'src/types/functionsInput';
import generateMPEventGeneralPropsClientSide from 'src/utils/mixpanelUtils/generateMPEventGeneralPropsClientSide';
import { Team, TeamInviteTeamside } from '../../../types/team';
import { teamToTeamInfo } from '../../../utils/teamToTeamInfo';
import { teamConverter } from '../../converter/team/teamConverter';

const teamsRef = collection(firestore, 'teams');

const usersRef = collection(firestore, 'users');

const triggerInviteToTeam = httpsCallable(functions, 'inviteToTeam');

const triggerDeclineTeamInvitation = httpsCallable(
  functions,
  'declineTeamInvitation'
);

const triggerAcceptTeamInvitation = httpsCallable(
  functions,
  'acceptTeamInvitation'
);

const triggerDeleteTeam = httpsCallable(functions, 'deleteTeam');

const triggerUpdateTeamInformation = httpsCallable(
  functions,
  'updateTeamInformation'
);

const triggerRemoveTeamMember = httpsCallable(functions, 'removeTeamMember');

const triggerLeaveTeam = httpsCallable(functions, 'leaveTeam');

const triggerPromoteToTeamAdmin = httpsCallable(
  functions,
  'promoteToTeamAdmin'
);

/**
 *
 * @param {Team} team
 * @param {TeamInvite[]} invites
 * @param {string} inviterName
 * @param {Function} enqueueSnackbar
 * @param {Function} setWaitingForTeamCreation
 * @param {Function} setDialogOpen
 * @returns
 */
export const addTeam = async (
  team: Team,
  invites: TeamInviteTeamside[],
  inviterDisplayName: string,
  inviterPhotoURL: string,
  enqueueSnackbar?: Function,
  setWaitingForTeamCreation?: Function,
  setDialogOpen?: Function
) => {
  let newTeamDocRef;

  const closeComponents = () => {
    if (setWaitingForTeamCreation) {
      setWaitingForTeamCreation(false);
    }

    if (setDialogOpen) {
      setDialogOpen(false);
    }
  };

  const enqueueSnackbarConditionally = (message: string, variant: Object) => {
    if (enqueueSnackbar) {
      enqueueSnackbar(message, variant);
    }
  };

  try {
    newTeamDocRef = await addDoc(teamsRef.withConverter(teamConverter), team);

    const userDocRef = doc(usersRef, auth.currentUser.uid);

    updateDoc(userDocRef, {
      teamsInfo: arrayUnion(teamToTeamInfo(team, newTeamDocRef.id))
    });

    trackCreateTeam({
      team_id: newTeamDocRef.id,
      avatar: team.photoURL,
      display_name: team.displayName
    });

    enqueueSnackbarConditionally(`Created Team:  ${team.displayName} `, {
      variant: 'success'
    });
  } catch (err) {
    enqueueSnackbarConditionally(`Creating Team ${team.displayName} failed`, {
      variant: 'error'
    });

    console.error('Error occured while trying to add a Team', err);

    closeComponents();

    throw new Error('Error occured while trying to add a Team');
  }

  invites.forEach(async (invite) => {
    const functionInput: InviteToTeamInput = {
      mpEventGeneralPropsClient: generateMPEventGeneralPropsClientSide(),
      inviteeUsername: invite.inviteeUsername,
      inviteeEmail: invite.inviteeEmail,
      teamDisplayName: team.displayName,
      teamID: newTeamDocRef.id,
      inviterDisplayName,
      inviterPhotoURL,
      inviterID: auth.currentUser.uid,
      createdAt: moment().toDate().getTime()
    };

    try {
      await triggerInviteToTeam(functionInput).then(() => {
        enqueueSnackbarConditionally(
          `Sent invite to ${invite.inviteeUsername || invite.inviteeEmail} `,
          {
            variant: 'success'
          }
        );
      });

      closeComponents();
    } catch (error) {
      const { code } = error;
      const { message } = error;
      enqueueSnackbarConditionally(
        `Sending invite to ${
          invite.inviteeUsername || invite.inviteeEmail
        } failed`,
        {
          variant: 'error'
        }
      );

      console.error(`Error ${code}: ${message}`);

      closeComponents();
    }
  });

  return newTeamDocRef;
};

/**
 *
 * @param {string} myUsername
 * @param {string} myID
 * @param {string} teamID
 */
export const declineInvitation = async (
  myUsername: string,
  myEmail: string,
  myID: string,
  teamID: string
) => {
  const functionInput: DeclineTeamInvitationInput = {
    mpEventGeneralPropsClient: generateMPEventGeneralPropsClientSide(),
    inviteeEmail: myEmail,
    inviteeUsername: myUsername,
    inviteeID: myID,
    teamID
  };
  try {
    await triggerDeclineTeamInvitation(functionInput);
  } catch (err) {
    console.error(
      'Error occured while trying to decline a Team Invitation',
      err
    );
    throw new Error('Error occured while trying to decline a Team Invitation');
  }
};
/**
 *
 * @param {string} myUsername
 * @param {string} myID
 * @param {string} myImage
 * @param {string} teamID
 * @param {string} teamName
 */
export const acceptInvitation = async (
  myUsername: string,
  myID: string,
  myEmail: string,
  myDisplayName: string,
  myPhotoURL: string,
  teamID: string,
  teamDisplayName: string
) => {
  const functionInput: AcceptTeamInvitationInput = {
    mpEventGeneralPropsClient: generateMPEventGeneralPropsClientSide(),
    teamID,
    teamDisplayName,
    inviteeUsername: myUsername,
    inviteeEmail: myEmail,
    inviteeID: myID,
    inviteeDisplayName: myDisplayName,
    inviteePhotoURL: myPhotoURL,
    joinedAt: moment().toDate().getTime()
  };

  try {
    await triggerAcceptTeamInvitation(functionInput);
  } catch (err) {
    console.error(
      'Error occured while trying to accept a Team Invitation',
      err
    );
    throw new Error(err);
  }
};

/**
 * It takes a team object, and deletes it from the database
 * @param {Team} deletionTeam - Team = {
 * @returns A boolean value.
 */
export const deleteTeam = async (deletionTeam: Team) => {
  const deletionRef = deletionTeam.photoURL
    ? ref(storage, deletionTeam.photoURL).fullPath
    : null;

  try {
    console.log('teamId', deletionTeam.id);

    const functionInput: DeleteTeamInput = {
      mpEventGeneralPropsClient: generateMPEventGeneralPropsClientSide(),
      teamID: deletionTeam.id,
      deletionRef
    };

    await triggerDeleteTeam(functionInput);

    terminateTeamListener();

    return true;
  } catch (err) {
    console.error('Error occured while trying to delete Team', err);
    throw new Error('Error occured while trying to delete Team');
  }
};

/**
 * It takes in a bunch of parameters, and then calls a cloud function that updates the team information
 * in the database.
 * @param {string} currentDisplayName - string,
 * @param {string} currentDescription - string,
 * @param {string} currentPhotoURL - string,
 * @param {string} newDisplayName - string,
 * @param {string} newDescription - string,
 * @param {string} newPhotoURL - string ; The new photo URL that is being uploaded to the storage
 * bucket
 * @param {string} teamId - string
 * @returns a Promise of type HttpsCallableResult.
 */
export const updateTeamInformation = async (
  currentDisplayName: string,
  currentDescription: string,
  currentPhotoURL: string,
  newDisplayName: string,
  newDescription: string,
  newPhotoURL: string,
  teamId: string
): Promise<HttpsCallableResult> => {
  const deletionRef = newPhotoURL
    ? ref(storage, currentPhotoURL).fullPath
    : null;

  try {
    const functionInput: UpdateTeamInformationInput = {
      mpEventGeneralPropsClient: generateMPEventGeneralPropsClientSide(),
      currentDisplayName,
      currentDescription,
      currentPhotoURL,
      newDisplayName,
      newDescription,
      newPhotoURL,
      teamId,
      deletionRef
    };

    const response: Promise<HttpsCallableResult> =
      triggerUpdateTeamInformation(functionInput);
    return await response;
  } catch (err) {
    console.error('Error occured while trying to add a Team', err);
    throw new Error('Error occured while trying to delete Team');
  }
};

/**
 * It takes in a bunch of parameters, and then returns a promise that resolves to a
 * HttpsCallableResult.
 * @param {string} inviteeUsername - string,
 * @param {string} inviteeEmail - string,
 * @param {string} teamDisplayName - string,
 * @param {string} teamID - string,
 * @param {string} inviterDisplayName - string,
 * @param {string | null} inviterPhotoURL - string | null
 * @returns a Promise.
 */
export const inviteToTeam = async (
  inviteeUsername: string,
  inviteeEmail: string,
  teamDisplayName: string,
  teamID: string,
  inviterDisplayName: string,
  inviterPhotoURL: string | null
): Promise<HttpsCallableResult> => {
  const functionInput: InviteToTeamInput = {
    mpEventGeneralPropsClient: generateMPEventGeneralPropsClientSide(),
    inviteeUsername,
    inviteeEmail,
    teamDisplayName,
    teamID,
    inviterDisplayName,
    inviterPhotoURL,
    inviterID: auth.currentUser.uid,
    createdAt: moment().toDate().getTime()
  };

  return triggerInviteToTeam(functionInput);
};

export const removeTeamMember = async (
  teamID: string,
  memberToRemoveID: string
): Promise<HttpsCallableResult> => {
  const functionInput: RemoveTeamMemberInput = {
    mpEventGeneralPropsClient: generateMPEventGeneralPropsClientSide(),
    teamID,
    memberToRemoveID
  };

  return triggerRemoveTeamMember(functionInput);
};

/**
 * This function is called when a user clicks a button to leave a team. It calls a Firebase Cloud
 * Function that removes the user from the team and returns a success or error message.
 * @param {string} teamID - string
 * @returns a Promise.
 */
export const leaveTeam = async (
  teamID: string
): Promise<HttpsCallableResult> => {
  const functionInput: LeaveTeamInput = {
    mpEventGeneralPropsClient: generateMPEventGeneralPropsClientSide(),
    teamID
  };

  return triggerLeaveTeam(functionInput);
};

/**
 * This function is called when a user clicks a button to promote another user to team admin.
 * @param {string} teamID - string
 * @param {string} memberToPromoteID - string
 * @returns a Promise.
 */
export const promoteToTeamAdmin = async (
  teamID: string,
  memberToPromoteID: string
): Promise<HttpsCallableResult> => {
  const functionInput: PromoteToTeamAdminInput = {
    mpEventGeneralPropsClient: generateMPEventGeneralPropsClientSide(),
    teamID,
    memberToPromoteID
  };

  return triggerPromoteToTeamAdmin(functionInput);
};
