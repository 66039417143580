import CircleIcon from '@mui/icons-material/Circle';
import { Grid, Paper, Radio, useTheme } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import {
  customOpacityDarkTheme,
  customOpacityDarkThemeBoard
} from 'src/constants';
import hexToRGB from 'src/utils/hexToRGB';

interface IProps {
  colors: string[];
  onSelect: (color: string) => void;
  selectedValue: string;
  textColors: boolean;
}

const ColorSelector: FC<IProps> = (props: IProps): ReactElement => {
  const { colors, onSelect, selectedValue, textColors } = props;
  // TODO: think about making separate state
  const theme = useTheme();

  const handleItemClick = (newColor: string) => () => {
    if (newColor) {
      onSelect(newColor);
    }
  };

  const controlProps = (item: string) => ({
    checked: selectedValue === item,
    onChange: handleItemClick(item),
    value: item,
    name: 'color-radio-button-demo'
  });

  const renderColorItems = (options: string[]) => {
    const items = [];
    options.forEach((value) => {
      items.push(
        <Grid item key={value}>
          <Radio
            {...controlProps(value)}
            checkedIcon={<CircleIcon sx={{ fill: value }} />}
            icon={<CircleIcon sx={{ fill: value }} />}
            sx={{
              color: value,
              p: 0.5,
              '&.Mui-checked': {
                color: value,
                backgroundColor: hexToRGB(
                  theme.palette.primary.main,
                  (customOpacityDarkTheme * 5).toString()
                )
              },
              '& .MuiSvgIcon-root': {
                fontSize: 36
              }
            }}
          />
        </Grid>
      );
    });
    return items;
  };

  return (
    <Paper
      className="cb-color-picker"
      sx={{
        width: '100%',
        maxWidth: textColors ? '247px' : null,
        boxShadow: textColors && theme.shadows[19],
        backgroundColor: textColors
          ? theme.palette.background.default
          : 'transparent'
      }}
    >
      <Paper
        sx={{
          width: '100%',
          padding: textColors && 2,

          backgroundColor: textColors
            ? hexToRGB(
                theme.palette.primary.main,
                customOpacityDarkThemeBoard.toString()
              )
            : 'transparent'
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1.6}
        >
          {renderColorItems(colors)}
        </Grid>
      </Paper>
    </Paper>
  );
};

export default ColorSelector;
