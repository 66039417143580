import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { deletePlaybook } from 'src/data/firestore/setters/playbook';

interface IProps {
  open: boolean;
  setOpen: Function;
  playbookID: string;
}

const DeletePlaybookDialog: FC<IProps> = (props: IProps) => {
  const { open, setOpen, playbookID } = props;
  const [waitingForFunctionResponse, setWaitingForFunctionResponse] =
    useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { teamId } = useParams();
  const navigate = useNavigate();

  const handleClickCancel = () => {
    setOpen(false);
  };

  const handleClickDeletePlaybook = async () => {
    setWaitingForFunctionResponse(true);

    try {
      if (teamId) {
        await deletePlaybook(playbookID, true, teamId);
      } else {
        await deletePlaybook(playbookID, true);
      }

      enqueueSnackbar('Deleted playbook', {
        variant: 'success'
      });
      setOpen(false);
      if (teamId) {
        navigate(`/app/teams/${teamId}/playbooks`);
      } else {
        navigate(`/app/personal/playbooks`);
      }
    } catch (err) {
      console.error('Deleting playbook failed: ', err);
      enqueueSnackbar('Deleting playbook failed', {
        variant: 'error'
      });

      setOpen(false);
    }
    setWaitingForFunctionResponse(false);
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        if (!waitingForFunctionResponse) {
          setOpen(false);
        }
      }}
    >
      <DialogContent sx={{ height: 'auto', maxHeight: '750px' }}>
        <Container
          sx={{
            width: '100%',
            height: '100%',
            py: 2
          }}
        >
          {waitingForFunctionResponse ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ height: '150px' }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <Grid
              container
              item
              xs={12}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sx={{ mt: 3, mb: 4 }}>
                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  You are about to delete this playbook. Deleting this playbook
                  will NOT delete its included plays. Do you wish to continue ?
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  sx={{}}
                  variant="text"
                  onClick={() => handleClickCancel()}
                  disabled={waitingForFunctionResponse}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ ml: 1 }}
                  variant="contained"
                  color="error"
                  onClick={() => handleClickDeletePlaybook()}
                  disabled={waitingForFunctionResponse}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          )}
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default DeletePlaybookDialog;
