import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid
} from '@mui/material';
import PropTypes from 'prop-types';
import { FC, useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import getCroppedImg from 'src/utils/getCroppedImg';

interface ImageCropperProps {
  open: boolean;
  setOpen: Function;
  setResult: Function;
  imageSrc: string;
}

const ImageCropper: FC<ImageCropperProps> = (props) => {
  const { open, setResult, setOpen, imageSrc } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<any>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, newCroppedAreaPixels) => {
    setCroppedAreaPixels(newCroppedAreaPixels);
  }, []);

  const handleClose = () => {
    setOpen(false);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    // if crop canceled by backdrop --> handle cancel
    // if crop canceled b finish button --> handle finish
  };

  const handleFinish = async () => {
    const imageType = imageSrc.split(':')[1].split(';')[0];
    const imageTypeControlled = imageType.includes('png')
      ? 'image/png'
      : 'image/jpeg';

    const croppedImage = await getCroppedImg(
      imageSrc,
      croppedAreaPixels,
      imageTypeControlled
    );
    setResult(croppedImage);
    setOpen(false);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  return (
    <Dialog onClose={handleClose} onBackdropClick={handleClose} open={open}>
      <DialogContent>
        <Box
          sx={{
            width: '300px',
            height: '300px',
            position: 'relative',
            mt: 0.5
          }}
        >
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={1 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="round"
            objectFit="vertical-cover"
            showGrid={false}
            restrictPosition
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
          sx={{ px: 3, pt: 1, pb: 2 }}
        >
          <Grid item sx={{ ml: 2, width: '55%' }}>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, newZoom) => setZoom(newZoom)}
              classes={{ root: 'slider' }}
            />
          </Grid>
          <Grid item>
            <Button
              sx={{ ml: 1, px: 2 }}
              variant="contained"
              startIcon={<ContentCutIcon />}
              onClick={handleFinish}
            >
              Crop
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

ImageCropper.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setResult: PropTypes.func,
  imageSrc: PropTypes.string
};

export default ImageCropper;
