import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { firebaseConfig } from '../config';

const firebase = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebase);

export const auth = getAuth(firebase);

export const realTimeDB = getDatabase(firebase);

export const functions = getFunctions(firebase, 'us-central1');

// export   const functionsEUW = getFunctions(firebase, 'europe-west1');

export const storage = getStorage(
  firebase,
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
);

export default firebase;
