import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import PlaybookPreviewBox from 'src/components/app/playbooks/PlaybookPreviewBox';
import useSettings from 'src/hooks/useSettings';
import { RootState, useSelector } from 'src/store';
import { PlaybookInfo } from 'src/types/play';
import { Team } from 'src/types/team';
import hexToRGB from 'src/utils/hexToRGB';
import sortPlaybooks, { PlaybooksSortByOptions } from 'src/utils/sortPlaybooks';
import {
  customAspectRatioXPercentage,
  customOpacityLightTheme,
  customOpacityDarkTheme
} from 'src/constants';
import { CreatePlaybookDialog } from '../createPlaybook';
import PlaybooksListControls from './PlaybooksListControls';

const PlaybooksList: FC = () => {
  const theme = useTheme();
  const settings = useSettings();
  const spacing = 12;
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const xlDown = useMediaQuery(theme.breakpoints.down('xl'));
  const [selectedPlaybooks, setSelectedPlaybooks] = useState<string[]>([]);
  const [allPlaybooks, setAllPlaybooks] = useState<PlaybookInfo[]>([]);
  const [createPlaybookDialogOpen, setCreatePlaybookDialogOpen] =
    useState<boolean>(false);
  const [selectedSortByOption, setSelectedSortByOption] =
    useState<PlaybooksSortByOptions>('editedDesc');

  const maxNumberOfPlaybooksPerRow = () => {
    /*
    if (smDown) {
      return 2;
    }

    if (mdDown) {
      return 4;
    }
    */

    if (lgDown) {
      return 3;
    }

    if (xlDown) {
      return 3;
    }

    return 4;
  };

  const playbookInfosToRows = (
    playbookInfos: PlaybookInfo[],
    itemsPerRow: number
  ) => {
    const playbooksInfo = [...playbookInfos];

    const newPlaybookInfosArray: any[][] = [];

    while (playbooksInfo.length)
      newPlaybookInfosArray.push(playbooksInfo.splice(0, itemsPerRow));

    // fill up last row so that spacing is correct

    let fillups = [];

    if (newPlaybookInfosArray.at(-1)) {
      if (newPlaybookInfosArray.at(-1).length < itemsPerRow) {
        const numberOfFillups =
          itemsPerRow - newPlaybookInfosArray.at(-1).length;

        for (let i = 0; i < numberOfFillups; i++) {
          fillups = [...fillups, `fillup_${i}`];
        }
      }

      newPlaybookInfosArray[newPlaybookInfosArray.length - 1] =
        newPlaybookInfosArray[newPlaybookInfosArray.length - 1].concat(fillups);
    }

    return newPlaybookInfosArray;
  };

  const activeWorkspace = settings?.settings?.activeSpaceID;

  const personalPlaybooksInfo: PlaybookInfo[] = useSelector(
    (state: RootState) => state.accountDataSlice.accountData.playbooksInfo
  );

  const team: Team = useSelector((state: RootState) => state.teamSlice.team);

  useEffect(() => {
    if (activeWorkspace) {
      setAllPlaybooks(team?.playbooksInfo);
    } else {
      setAllPlaybooks(personalPlaybooksInfo);
    }
  }, [personalPlaybooksInfo, team?.playbooksInfo]);

  const handleClickCreatePlaybook = () => {
    setCreatePlaybookDialogOpen(true);
  };

  const handleToggleSelectAllPlaybooks = () => {
    if (selectedPlaybooks.length === 0) {
      setSelectedPlaybooks(allPlaybooks.map((playbook) => playbook.id));
    } else {
      setSelectedPlaybooks([]);
    }
  };

  const handleToggleSelectPlaybook = (targetId: string) => {
    if (selectedPlaybooks.includes(targetId)) {
      setSelectedPlaybooks(
        selectedPlaybooks.filter(
          (selectedPlaybookId) => selectedPlaybookId !== targetId
        )
      );
    } else {
      setSelectedPlaybooks([...selectedPlaybooks, targetId]);
    }
  };

  return (
    <>
      <PlaybooksListControls
        selectedPlaybooks={selectedPlaybooks}
        setSelectedPlaybooks={setSelectedPlaybooks}
        toggleSelectAllPlaybooks={handleToggleSelectAllPlaybooks}
        allPlaybooks={allPlaybooks}
        selectedSortByOption={selectedSortByOption}
        setSelectedSortByOption={setSelectedSortByOption}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{
          width: `100%`,
          height: '100%'
        }}
      >
        {allPlaybooks?.length > 0 ? (
          playbookInfosToRows(
            sortPlaybooks(allPlaybooks, selectedSortByOption),
            maxNumberOfPlaybooksPerRow()
          ).map((row) =>
            row.map((playbook) => {
              if (playbook === 'add') {
                return (
                  <Grid
                    key={playbook}
                    item
                    sx={{
                      width: `calc(${
                        100 / maxNumberOfPlaybooksPerRow()
                      }% - ${spacing}px)`,
                      mt: `${spacing + 4}px`
                    }}
                  >
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{ position: 'relative' }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          paddingTop: `${customAspectRatioXPercentage}%`,
                          borderRadius: `${theme.shape.borderRadius}px`,
                          backgroundColor: hexToRGB(
                            theme.palette.primary.main,
                            `${
                              theme.palette.mode === 'light'
                                ? customOpacityLightTheme
                                : customOpacityDarkTheme
                            }`
                          ),
                          boxShadow: theme.shadows[24]
                        }}
                      />
                      <Grid
                        item
                        sx={{
                          position: 'absolute',
                          width: '14%'
                        }}
                      >
                        <AddIcon
                          sx={{
                            color: 'text.secondary',
                            width: '100%',
                            height: 'auto',
                            opacity: 0.5
                          }}
                        />
                      </Grid>

                      <Button
                        sx={{
                          borderRadius: `${theme.shape.borderRadius}px`,
                          p: 0,
                          top: 0,
                          left: 0,
                          position: 'absolute',
                          width: '100%',
                          pt: `${customAspectRatioXPercentage}%`,
                          border: '1px solid transparent',
                          backgroundColor: 'transparent',
                          '&:hover': {
                            backgroundColor: hexToRGB(
                              theme.palette.primary.main,
                              '0.015'
                            )
                          }
                        }}
                        onClick={handleClickCreatePlaybook}
                      />
                      <CreatePlaybookDialog
                        setDialogOpen={setCreatePlaybookDialogOpen}
                        open={createPlaybookDialogOpen}
                        preselectedPlays={[]}
                      />
                    </Grid>
                  </Grid>
                );
              }

              if (typeof playbook === 'string' && playbook.includes('fillup')) {
                return (
                  <Grid
                    key={playbook}
                    item
                    sx={{
                      width: `calc(${
                        100 / maxNumberOfPlaybooksPerRow()
                      }% - ${spacing}px)`,
                      mt: `${spacing + 4}px`
                    }}
                  />
                );
              }

              return (
                <Grid
                  key={playbook.id}
                  item
                  sx={{
                    width: `calc(${
                      100 / maxNumberOfPlaybooksPerRow()
                    }% - ${spacing}px)`,
                    mt: `${spacing + 4}px`
                  }}
                >
                  <PlaybookPreviewBox
                    playbook={playbook}
                    selectionEnabled
                    selected={selectedPlaybooks.includes(playbook.id)}
                    toggleSelected={handleToggleSelectPlaybook}
                  />
                </Grid>
              );
            })
          )
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%', height: 180 }}
          >
            <Typography
              variant="body2"
              sx={{ color: theme.palette.text.secondary }}
            >
              There are currently no playbooks here.{' '}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PlaybooksList;
