import { FC } from 'react';
import { shallowEqual } from 'react-redux';
import useLines from 'src/hooks/useLines';
import { RootState, useSelector } from 'src/store';
import { IBoardVisibility, IDrawingLine, IDrawingLines } from 'src/types/board';
import DrawingLine from '../elements/DrawingLine';

interface IProps {
  currentLine: IDrawingLine;
  mapRotation: number;
}

const DrawLayer: FC<IProps> = (props: IProps) => {
  const { currentLine, mapRotation } = props;
  const { visibility } = useSelector(
    (state: RootState) => state.boardSlice.ui,
    shallowEqual
  );

  const lines: IDrawingLines = useLines();
  const { linesVisible }: IBoardVisibility = visibility;

  return (
    <svg
      className="cb-draw-layer"
      style={{
        width: '100%',
        height: '100%'
      }}
    >
      {linesVisible &&
        lines &&
        Object.entries(lines).map(
          ([key, line]) =>
            line && (
              <DrawingLine line={line} key={key} mapRotation={mapRotation} />
            )
        )}
      {currentLine && (
        <DrawingLine line={currentLine} mapRotation={mapRotation} />
      )}
    </svg>
  );
};

export default DrawLayer;
