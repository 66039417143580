import { PlaybookInfo } from 'src/types/play';

export const playbookSortByOptions = {
  editedAsc: {
    label: 'Edited at (asc)'
  },
  editedDesc: {
    label: 'Edited at (desc)'
  },
  createdAsc: {
    label: 'Created at (asc)'
  },
  createdDesc: {
    label: 'Created at (desc)'
  },
  numPlaysAsc: {
    label: 'Number of Plays (asc)'
  },
  numPlaysDesc: {
    label: 'Number of Plays (desc)'
  },
  nameAsc: {
    label: 'Playbook Title (asc)'
  },
  nameDesc: {
    label: 'Playbook Title (desc)'
  }
};

export type PlaybooksSortByOptions =
  | 'editedAsc'
  | 'editedDesc'
  | 'createdAsc'
  | 'createdDesc'
  | 'numPlaysAsc'
  | 'numPlaysDesc'
  | 'nameAsc'
  | 'nameDesc';

const sortPlaybooks = (
  playbooks: PlaybookInfo[],
  sortByKey: PlaybooksSortByOptions
): PlaybookInfo[] => {
  if (!playbooks || playbooks.length === 0) {
    return [];
  }

  if (sortByKey === 'createdAsc') {
    return [...playbooks].sort((x, y) => x.createdAt - y.createdAt);
  }

  if (sortByKey === 'editedAsc') {
    return [...playbooks].sort((x, y) => x.updatedAt - y.updatedAt);
  }

  if (sortByKey === 'numPlaysAsc') {
    return [...playbooks].sort((x, y) => x.numberOfPlays - y.numberOfPlays);
  }

  if (sortByKey === 'createdDesc') {
    const temp = [...playbooks].sort((x, y) => x.createdAt - y.createdAt);

    return [...temp].reverse();
  }

  if (sortByKey === 'editedDesc') {
    const temp = [...playbooks].sort((x, y) => x.updatedAt - y.updatedAt);

    return [...temp].reverse();
  }

  if (sortByKey === 'numPlaysDesc') {
    const temp = [...playbooks].sort(
      (x, y) => x.numberOfPlays - y.numberOfPlays
    );

    return [...temp].reverse();
  }

  if (sortByKey === 'nameAsc') {
    return [...playbooks].sort((x, y) =>
      x.title.toLowerCase().localeCompare(y.title.toLowerCase())
    );
  }

  if (sortByKey === 'nameDesc') {
    const temp = [...playbooks].sort((x, y) =>
      x.title.toLowerCase().localeCompare(y.title.toLowerCase())
    );
    return [...temp].reverse();
  }

  return playbooks;
};

export default sortPlaybooks;
