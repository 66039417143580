import hexToRGB from './utils/hexToRGB';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const useListenerLogs = false;

export const useListenerAlreadyRunningLogs = false;

export const useTeamSliceLogs = false;

export const usePlaySliceLogs = false;

export const usePlaybookSliceLogs = false;

export const useAnnouncementSliceLogs = false;

export const useAccountDataSliceLogs = false;

// reload page after change
export const appTopbarHeight = 48;

// reload page after change
export const appSidebarWidth = 230;

export const appSpacebarWidth = 280;

export const appSpaceBarZIndex = 1100;

export const customAspectRatioY = 1;

export const customAspectRatioX = 1;

export const customAspectRatioXPercentage =
  (customAspectRatioX / customAspectRatioY) * 100;

export const customOpacityLightTheme = 0.03;

export const customOpacityDarkTheme = 0.025;

export const customOpacityDarkThemeBoard = 0.025;

export const avatarSize = 33;

export const BOARD_SIZE = 4000; // copied from stratrat

export const mapSize = 708;

export const OBJECT_SIZE = 28;
export const AGENT_SIZE = 44;

export const ATTACK_COLOR = '#21dbb9';
export const DEFEND_COLOR = '#e03a40';

// map colors
export const baseColor = '#373840';
export const objectsColor = '#464852';
export const outlineColor = '#525561';
export const transparentObjectsColor = '#3b3c45';
export const wallsColor = '#464852';
export const areaColor = '#cecee0';
export const normalTextColor = '#95959c';
export const areaTextColor = '#ffffff';
export const attackersTextColor = '#21dbb9';
export const defendersTextColor = '#e03a40';

// drawing colors
export const LINE_COLOR_OPTIONS: string[] = [
  hexToRGB('#ffffff', '0.75'),
  hexToRGB('#070708', '0.9'),
  hexToRGB(ATTACK_COLOR, '0.75'),
  hexToRGB(DEFEND_COLOR, '0.75'),
  hexToRGB('#006ee6', '0.75'),
  hexToRGB('#ffc200', '0.75'),
  hexToRGB('#249225', '0.75'),
  hexToRGB('#c30f16', '0.75')
];

export const subscriptionTierBasicColor = '#9c9fa6';
export const subscriptionTierProColor = '#4953de';

export const basicTierMaxPlays = 5;
export const basicTierMaxPlaybooks = 3;

export const DEFAULT_TEXTAREA_FONT_SIZE = 10;
export const DEFAULT_TEXTAREA_FONT_COLOR = LINE_COLOR_OPTIONS[0];

export const DEFAULT_LINE_COLOR = LINE_COLOR_OPTIONS[0];
export const DEFAULT_LINE_STROKEWIDTH = 2;
export const DISTANCE_LINE_STROKEDASHARRAY = '1 6';
export const BOLD_LINE_STROKEWIDTH = 4;
export const DEFAULT_LINE_STROKEDASHARRAY = '3 6';
export const BOLD_LINE_STROKEDASHARRAY = '3 12';

export const RUNNING_SPEED = 6.573; // measured in meter/s
export const unitInMeter = 0.1786623; // reference value 94.07244873046875 ~= 16.8072  meters in ~ 2.557 seconds

export const discordLink = 'https://discord.gg/EU6EQAyCXY';

export const basicFeaturesPersonal = [
  'Personal Space',
  'All strategy board tools',
  'Save up to 5 plays',
  'Save up to 3 playbooks'
];

export const basicFeaturesTeam = [
  'Team Space',
  'All strategy board tools',
  'Real-time collaboration',
  'Save up to 5 plays',
  'Save up to 3 playbooks'
];

export const proFeaturesPersonal = [
  'Personal Space',
  'All strategy board tools',
  'Unlimited plays',
  'Unlimited playbooks',
  'Discord "Pro Tier" role (optional)'
];

export const proFeaturesTeam = [
  'Team Space',
  'All strategy board tools',
  'Real-time collaboration',
  'Unlimited plays',
  'Unlimited playbooks',
  'Discord "Pro Tier Role" (optional)'
];
