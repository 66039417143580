import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { FC, ReactElement, useEffect, useState } from 'react';
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import CustomTooltip from 'src/components/CustomTooltip';
import useGlobalDOMEvents from 'src/hooks/useGlobalDOMEvents';
import KeyCommandInfoDialog from './KeyCommandInfoDialog';
import VisibilitySelector from './VisibilitySelector';

interface IProps {
  transformWrapper: ReactZoomPanPinchRef;
  initialScale: number;
}

const ScaleSelector: FC<IProps> = (props: IProps): ReactElement => {
  const { transformWrapper, initialScale } = props;
  const theme: Theme = useTheme();

  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  useGlobalDOMEvents({
    keydown(e: KeyboardEvent) {
      // STRG + '+'
      if (e.code === 'BracketRight' && e.ctrlKey) {
        e.preventDefault();
        if (transformWrapper) {
          transformWrapper.zoomIn(0.1);
        }
      }
      // STRG + '-'
      if (e.code === 'Slash' && e.ctrlKey) {
        e.preventDefault();
        if (transformWrapper) {
          transformWrapper.zoomOut(0.1);
        }
      }
    }
  });

  const [scale, setScale] = useState(
    Math.ceil(transformWrapper?.state?.scale * 100)
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (transformWrapper?.state?.scale) {
      setScale(Math.floor(transformWrapper?.state?.scale * 100));
    }
  }, [transformWrapper?.state?.scale]);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleItemClick = (scaleValue: number) => () => {
    if (transformWrapper) {
      transformWrapper.centerView(scaleValue);
    }
    setAnchorEl(null);
  };

  const handleZoomIn = () => {
    if (transformWrapper) {
      transformWrapper.zoomIn(0.1);
    }
  };
  const handleZoomOut = () => {
    if (transformWrapper) {
      transformWrapper.zoomOut(0.1);
    }
  };

  const buttonSx = {
    height: '40px',
    width: '40px',
    margin: theme.spacing(0.5),
    border: 0
  };

  return (
    <div
      className="cb-scale-selector"
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: 100,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        borderRadius: `${theme.shape.borderRadius}px`
      }}
    >
      <Paper
        sx={{
          backgroundColor: '#37383D',
          border: `1px solid ${theme.palette.divider}`,
          boxShadow: theme.shadows[19]
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid item>
            <KeyCommandInfoDialog sx={buttonSx} />
          </Grid>
          <Grid item>
            <VisibilitySelector sx={buttonSx} />
          </Grid>
          <Grid item>
            <CustomTooltip title="Reset zoom" placement="top">
              <IconButton
                onClick={() => {
                  transformWrapper.centerView(initialScale);
                }}
                size="large"
                sx={{ ...buttonSx }}
              >
                <ZoomOutMapIcon />
              </IconButton>
            </CustomTooltip>
          </Grid>
          {!smDown && (
            <>
              <Grid item>
                <CustomTooltip
                  title="Zoom out"
                  subtitle="CTRL + '-' "
                  placement="top"
                >
                  <IconButton
                    sx={buttonSx}
                    onClick={handleZoomOut}
                    size="large"
                  >
                    <RemoveIcon />
                  </IconButton>
                </CustomTooltip>
              </Grid>
              <Grid item>
                <Typography
                  align="center"
                  sx={{ width: 50 }}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  {scale}%
                </Typography>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                >
                  <MenuItem onClick={handleItemClick(3)}>{3 * 100}%</MenuItem>
                  <MenuItem onClick={handleItemClick(2)}>{2 * 100}%</MenuItem>
                  <MenuItem onClick={handleItemClick(1.5)}>
                    {1.5 * 100}%
                  </MenuItem>
                  <MenuItem onClick={handleItemClick(1)}>{1 * 100}%</MenuItem>
                  <MenuItem onClick={handleItemClick(0.75)}>
                    {0.75 * 100}%
                  </MenuItem>
                  <MenuItem onClick={handleItemClick(0.5)}>
                    {0.5 * 100}%
                  </MenuItem>
                </Menu>
              </Grid>

              <Grid item>
                <CustomTooltip title="Zoom in" subtitle="CTRL + '+'">
                  <IconButton sx={buttonSx} onClick={handleZoomIn} size="large">
                    <AddIcon />
                  </IconButton>
                </CustomTooltip>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </div>
  );
};

export default ScaleSelector;
