import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material';
import type { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { RootState } from 'src/store';
import { Team, TeamMemberData } from 'src/types/team';
import CustomAvatar from '../CustomAvatar';
import SettingsSection from '../SettingsSection';
import TeamMemberOptionsPopover from './memberoptions/TeamMemberOptionsPopover';

const teamToTeamMemberData = (team: Team): TeamMemberData[] => {
  const result: TeamMemberData[] = team.membersInfo.map((member) => ({
    id: member.id,
    name: member.displayName,
    username: member.username,
    joined: member.joinedAt,
    url: member.photoURL,
    alt: member.displayName.charAt(0),
    role: team.adminIDs.includes(member.id) ? 'Admin' : 'Member'
  }));

  return result;
};

const TeamMembersSettings: FC = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const { teamId } = useParams();
  const team: Team = useSelector((state: RootState) => state.teamSlice.team);

  if (!teamId || (teamId && !team)) {
    return null;
  }

  return (
    <Grid
      container
      columnSpacing={6}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item container xs={6} rowSpacing={4}>
        <Grid item xs={12}>
          <SettingsSection title="Remove or promote members">
            <List dense sx={{ height: '100%' }}>
              {teamToTeamMemberData(team).map((member) => (
                <ListItem key={`${member.name}`} sx={{ mb: 1, p: 0 }}>
                  <>
                    <ListItemAvatar>
                      <CustomAvatar
                        src={member.url}
                        alt={member.alt}
                        isTeamAvatar={false}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={member.name}
                      secondary={member.role}
                      sx={{
                        '.MuiListItemText-primary': {
                          fontSize: 12,
                          fontWeight: 500,
                          color: theme.palette.primary.main
                        },

                        '.MuiListItemText-secondary': {
                          fontSize: 12,
                          fontWeight: 500,
                          color: theme.palette.text.secondary
                        }
                      }}
                    />

                    <ListItemSecondaryAction>
                      <TeamMemberOptionsPopover
                        userRole={
                          team.adminIDs.includes(user.id) ? 'Admin' : 'Member'
                        }
                        targetMemberData={member}
                        teamID={teamId}
                        teamDisplayName={
                          team.displayName ? team.displayName : null
                        }
                      />
                    </ListItemSecondaryAction>
                  </>
                </ListItem>
              ))}
            </List>
          </SettingsSection>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TeamMembersSettings;
