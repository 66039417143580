import { useListenerAlreadyRunningLogs, useListenerLogs } from 'src/constants';
import { clearAccountData } from 'src/slices/accountdata';
import { clearAnnouncements } from 'src/slices/announcement';
import { clearPlay } from 'src/slices/play';
import { clearPlaybook } from 'src/slices/playbook';
import { clearTeam } from 'src/slices/team';
import store from 'src/store';
import { subscribeToAccountData } from './firestore/listeners/accountData';
import { subscribeToAnnouncements } from './firestore/listeners/announcements';
import { subscribeToPlay } from './firestore/listeners/play';
import { subscribeToPlaybook } from './firestore/listeners/playbook';
import { subscribeToTeam } from './firestore/listeners/team';
import {
  subscribeToSessionPlay,
  unsubscribeFromSessionPlay
} from './realtimeDb/listeners/session';
import { joinSession } from './realtimeDb/setters/session';
// utils

const logListenerStarted = (listener, objId?) => {
  if (useListenerLogs) {
    if (objId) {
      console.log(`Start listening to ${listener} with ID ${objId} ...`);
    } else {
      console.log(`Start ${listener}Listener ...`);
    }
  }
};

const logListenerAlreadyRunning = (listener, objId?) => {
  if (useListenerAlreadyRunningLogs) {
    if (objId) {
      console.log(
        `Listener for ${listener} with ID ${objId} is already running !`
      );
    } else {
      console.log(`A ${listener} is already running!`);
    }
  }
};

// type

type ByIDSubscription = {
  id: string;
  unsubscribe: () => void;
};

// Listener: Announcements

let unsubscribeAnnouncements: Function;

const startAnnouncementsListener = () => {
  logListenerStarted('Announcements');
  unsubscribeAnnouncements = subscribeToAnnouncements();
};

const terminateAnnouncementsListener = () => {
  console.log(`Terminate AnnouncementsListener ...`);
  unsubscribeAnnouncements();
  store.dispatch(clearAnnouncements());
  unsubscribeAnnouncements = null;
};

// Listener: AccountData

let unsubscribeAccountData: Function;

const startAccountDataListener = (userId: string) => {
  logListenerStarted('AccountData');
  unsubscribeAccountData = subscribeToAccountData(userId);
};

const terminateAccountDataListener = () => {
  console.log(`Terminate AccountDataListener ...`);
  unsubscribeAccountData();
  store.dispatch(clearAccountData());
  unsubscribeAccountData = null;
};

// Listener: Session
let subscribedSession: ByIDSubscription = null;

const startSessionListener = async (sessionId: string, userId: string) => {
  if (!subscribedSession) {
    logListenerStarted('Session');
    subscribeToSessionPlay(sessionId);
    await joinSession(sessionId);
    const unsubscribe = () => unsubscribeFromSessionPlay(sessionId, userId);
    subscribedSession = { id: sessionId, unsubscribe };
  } else {
    logListenerAlreadyRunning('Session');
  }
};

const terminateSessionListener = () => {
  if (subscribedSession) {
    console.log(`Terminate SessionListener ...`);
    subscribedSession?.unsubscribe();
    subscribedSession = null;
  }
};

// Listener: Team
let subscribedTeam: ByIDSubscription = null;

const startTeamListener = (teamId: string) => {
  if (!subscribedTeam) {
    logListenerStarted('Team', teamId);
    subscribedTeam = {
      id: teamId,
      unsubscribe: subscribeToTeam(teamId)
    };
  } else {
    logListenerAlreadyRunning('Team', teamId);
  }
};

const terminateTeamListener = () => {
  if (subscribedTeam) {
    console.log(`Terminate TeamListener ...`);
    subscribedTeam.unsubscribe();
    store.dispatch(clearTeam());

    if (subscribedSession) {
      terminateSessionListener();
    }

    subscribedTeam = null;
  }
};

// Listener: Play

let subscribedPlay: ByIDSubscription = null;

const startPlayListener = (playId: string, teamId: string | null) => {
  if (!subscribedPlay) {
    logListenerStarted('Play', playId);
    subscribedPlay = {
      id: playId,
      unsubscribe: subscribeToPlay(playId, teamId)
    };
  } else {
    logListenerAlreadyRunning('Play', playId);
  }
};

const terminatePlayListener = () => {
  if (subscribedPlay) {
    console.log(`Terminate PlayListener ...`);
    subscribedPlay.unsubscribe();
    subscribedPlay = null;
  }
  store.dispatch(clearPlay());
};

// Listener: Playbook

let subscribedPlaybook: ByIDSubscription = null;

const startPlaybookListener = (playbookId: string, teamId: string | null) => {
  if (!subscribedPlaybook) {
    logListenerStarted('Playbook', playbookId);
    subscribedPlaybook = {
      id: playbookId,
      unsubscribe: subscribeToPlaybook(playbookId, teamId)
    };
  } else {
    logListenerAlreadyRunning('Playbook', playbookId);
  }
};

const terminatePlaybookListener = () => {
  if (subscribedPlaybook) {
    console.log(`Terminate PlaybookListener ...`);
    subscribedPlaybook.unsubscribe();
    store.dispatch(clearPlaybook());
    subscribedPlaybook = null;
  }
};

// functions that aggregate the above

const terminateAllListeners = () => {
  terminateAnnouncementsListener();
  terminateAccountDataListener();
  terminateSessionListener();
  terminateTeamListener();
  terminatePlayListener();
  terminatePlaybookListener();
};

const startUniversalListeners = (userId: string) => {
  startAnnouncementsListener();
  startAccountDataListener(userId);
};

const terminateUniversalListeners = () => {
  terminateAnnouncementsListener();
  terminateAccountDataListener();
};

export {
  startUniversalListeners,
  startSessionListener,
  startTeamListener,
  startPlayListener,
  startPlaybookListener,
  terminateUniversalListeners,
  terminateAllListeners,
  terminateTeamListener,
  terminatePlaybookListener,
  terminatePlayListener,
  terminateSessionListener
};
