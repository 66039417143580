import { Avatar, Theme, useTheme, Box } from '@mui/material';
import React from 'react';
import { AGENT_SIZE } from 'src/constants';
import { AGENTS } from 'src/ressources/shapes/agents';
import { AgentTypes, DragTypes, IObject, ObjectTypes } from 'src/types/board';
import determineBackgroundColor from 'src/utils/boardUtils/determineBackgroundColor';
import determineBorderColor from 'src/utils/boardUtils/determineBorderColor';
import hexToRGB from 'src/utils/hexToRGB';

interface IProps {
  selected: boolean;
  object: IObject;
  isDragging?: boolean;
  wrapperScale: number;
  mapRotation: number;
}

const ImgAvatar = React.forwardRef<any, IProps>((props: IProps, ref) => {
  const { object, selected, wrapperScale, mapRotation, isDragging } = props;
  const { id, type, value, attack } = object;

  const theme: Theme = useTheme();

  const renderObjImg = (objValue: ObjectTypes | AgentTypes) => {
    if (type === DragTypes.AGENT) {
      return AGENTS[objValue]?.icon;
    }
    return undefined;
  };
  return (
    <Box
      ref={ref}
      sx={{
        cursor: isDragging ? 'grabbing' : 'pointer',
        transform: `scale(${1 / wrapperScale})`,
        position: 'absolute',
        top: -AGENT_SIZE / 2,
        left: -AGENT_SIZE / 2,
        border: selected && `1px solid ${theme.palette.primary.main}`,
        borderRadius: 99,
        padding: selected && '6px',
        margin: selected && '-7px' // to counter the border when selected
      }}
    >
      <Box
        sx={{
          border: `3px solid ${hexToRGB(determineBorderColor(attack), '0.3')}`,
          boxShadow: selected && theme.shadows[19],
          borderRadius: 99,
          height: AGENT_SIZE,
          width: AGENT_SIZE
        }}
      >
        <Avatar
          {...props}
          alt={`${value}-${id}`}
          id={`${value}-${id}`}
          variant="circular"
          sx={{
            height: AGENT_SIZE - 6,
            width: AGENT_SIZE - 6,
            backgroundColor: determineBackgroundColor(value),
            margin: 0,
            transform: `rotate(-${mapRotation}deg)`
          }}
          src={renderObjImg(value)}
        />
      </Box>
    </Box>
  );
});

export default ImgAvatar;
