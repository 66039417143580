import { useTheme } from '@mui/material';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { RootState, useSelector } from 'src/store';
import determineAreaOfEffect from 'src/utils/boardUtils/determineAreaOfEffect';
import rotateCoordsAroundPoint from 'src/utils/boardUtils/rotateCoordsAroundPoint';

interface IProps {
  objectID: string;
  renderTransformer: boolean;
  transformerHidden: boolean;
  child: JSX.Element;
  selected: boolean;
  isDragging: boolean;
  wrapperScale: number;
  mapRotation: number;
  menuPosition: 'top' | 'bottom';
  setMenuPosition: Function;
  targetObjectValue: string;
  updateAreaOfEffect: Function;
}

interface Coordinates {
  x: number;
  y: number;
}

const vectorMagnitude = (x: number, y: number) => Math.sqrt(x ** 2 + y ** 2);

const keepCoordinatesInBounds = (
  originalX: number,
  originalY: number,
  maxLength: number,
  minDistancePercantage?: number // 0.0 - 1.0
): Coordinates => {
  // 1. move origin to center & scale to unit circle

  const x = (originalX - maxLength) / maxLength;
  const y = (originalY - maxLength) / maxLength;

  // calculate the length of vector (x, y)
  const magnitudeXY = vectorMagnitude(x, y);

  // calculate rotation angle from coordinates
  const angleInRadians = Math.atan2(y, x);

  // find point on unit circle
  const xLimited = Math.cos(angleInRadians);
  const yLimited = Math.sin(angleInRadians);

  // if point lies outside of maximum bounds
  if (magnitudeXY > 1) {
    // scale back, then move origin back to top left
    const xLimitedScaledBack = xLimited * maxLength + maxLength;
    const yLimitedScaledBack = yLimited * maxLength + maxLength;

    return { x: xLimitedScaledBack, y: yLimitedScaledBack };
  }

  // if point lies within minimum bounds (is smaller than minimum distance)
  if (magnitudeXY < minDistancePercantage) {
    // scale back, then move origin back to top left
    const xLimitedScaledBack =
      xLimited * (minDistancePercantage * maxLength) + maxLength;

    const yLimitedScaledBack =
      yLimited * (minDistancePercantage * maxLength) + maxLength;

    return { x: xLimitedScaledBack, y: yLimitedScaledBack };
  }

  return { x: originalX, y: originalY };

  /* if point lies outside of minimum bounds 
    if(magnitudeXY < (MIN_DISTANCE / MAX_DISTANCE) ){

    }
    */
};

const calculateSVGHeight = (
  radius: number,
  svgTransformOriginYPercentage: number
) => {
  const offsetPerc = svgTransformOriginYPercentage / 100;

  const offsetDistance = radius * offsetPerc;

  const targetDistance = radius * (1 - offsetPerc);

  const scaleFactor = radius / targetDistance;

  return offsetDistance * scaleFactor + targetDistance * scaleFactor;
};

type IPropsTransformer = Omit<IProps, 'child'>;

const Transformer: FC<IPropsTransformer> = (
  props: IPropsTransformer
): ReactElement => {
  const {
    objectID,
    renderTransformer,
    transformerHidden,
    selected,
    isDragging,
    wrapperScale,
    mapRotation,
    menuPosition,
    setMenuPosition,
    targetObjectValue,
    updateAreaOfEffect
  } = props;
  const theme = useTheme();
  const transformerPointSize = 5 / wrapperScale;
  const transformerPointSizeDraggable = transformerPointSize * 1.5;
  const areaOfEffect = determineAreaOfEffect(targetObjectValue);
  const svgOriginTransformXPercentage = 50; // 50: standard for now, change this if necessary

  // get dx & dy of object from store

  const objectAOE = useSelector(
    (state: RootState) =>
      Object.entries(state.boardSlice.model.objects).find(
        ([key]) => key === objectID
      )[1]?.areaOfEffect
  );

  const initialPosition = rotateCoordsAroundPoint(
    objectAOE.dx,
    objectAOE.dy,
    areaOfEffect.maxLength,
    areaOfEffect.maxLength,
    mapRotation
  );

  const [isDraggingInTransformer, setIsDraggingInTransformer] =
    useState<boolean>(false);

  const [controlledPosition, setControlledPosition] = useState<Coordinates>({
    x: initialPosition[0],
    y: initialPosition[1]
  });

  const [previousMapRotation, setPreviousMapRotation] =
    useState<number>(mapRotation);

  const vectorLength =
    areaOfEffect.maxLength === areaOfEffect.minLength
      ? areaOfEffect.maxLength
      : vectorMagnitude(
          controlledPosition.x - areaOfEffect.maxLength,
          controlledPosition.y - areaOfEffect.maxLength
        );

  const editable = selected && !isDragging;

  const transformable = !(
    areaOfEffect.minLength === areaOfEffect.maxLength && !areaOfEffect.rotatable
  );

  const onControlledDrag = (event, position) => {
    const { x, y } = position;

    const coordinatesInBounds: Coordinates = keepCoordinatesInBounds(
      x,
      y,
      areaOfEffect.maxLength,
      areaOfEffect.minLength / areaOfEffect.maxLength
    );

    setControlledPosition({
      x: coordinatesInBounds.x,
      y: coordinatesInBounds.y
    });
  };

  const handleDragStop = () => {
    setIsDraggingInTransformer(false);

    const newAOECoords = rotateCoordsAroundPoint(
      controlledPosition.x,
      controlledPosition.y,
      areaOfEffect.maxLength,
      areaOfEffect.maxLength,
      -mapRotation
    );

    updateAreaOfEffect({
      hidden: false,
      dx: newAOECoords[0],
      dy: newAOECoords[1]
    });
  };

  useEffect(() => {
    const rotationDiff = mapRotation - previousMapRotation;

    const coordsRotated = rotateCoordsAroundPoint(
      controlledPosition.x,
      controlledPosition.y,
      areaOfEffect.maxLength,
      areaOfEffect.maxLength,
      rotationDiff
    );

    setControlledPosition({ x: coordsRotated[0], y: coordsRotated[1] });
    setPreviousMapRotation(mapRotation);
  }, [mapRotation]);

  useEffect(() => {
    if (
      controlledPosition.y < areaOfEffect.maxLength &&
      menuPosition === 'top'
    ) {
      setMenuPosition('bottom');
    }

    if (
      controlledPosition.y > areaOfEffect.maxLength &&
      menuPosition === 'bottom'
    ) {
      setMenuPosition('top');
    }
  }, [controlledPosition]);

  useEffect(() => {
    // if not isDraggingInTransformer
    if (!isDraggingInTransformer) {
      const rotatedCoords = rotateCoordsAroundPoint(
        objectAOE.dx,
        objectAOE.dy,
        areaOfEffect.maxLength,
        areaOfEffect.maxLength,
        mapRotation
      );

      setControlledPosition({
        x: rotatedCoords[0],
        y: rotatedCoords[1]
      });
    }
  }, [objectAOE]);

  return (
    <>
      {selected && renderTransformer && (
        <div
          className="cb-aoe-transformer"
          style={{
            display: transformerHidden && 'none',
            width: areaOfEffect.maxLength * 2,
            height: areaOfEffect.maxLength * 2,
            borderRadius: '100%',
            transform: `translate(-50%, -50%) rotate(-${mapRotation}deg)`
          }}
        >
          <div
            className="cb-aoe-transformer-svg-wrapper"
            style={{
              position: 'absolute',
              height:
                calculateSVGHeight(
                  vectorLength,
                  areaOfEffect.originYMovementPercentage
                ) * (areaOfEffect.multiplySvgLength || 1),
              width: areaOfEffect.width,
              top: areaOfEffect.maxLength,
              left: areaOfEffect.maxLength,
              transform: `translate(-${svgOriginTransformXPercentage}%, -${
                100 - areaOfEffect.originYMovementPercentage
              }%) rotate(${
                Math.atan2(
                  controlledPosition.y - areaOfEffect.maxLength,
                  controlledPosition.x - areaOfEffect.maxLength
                ) *
                  (180 / Math.PI) +
                90
              }deg)`,
              transformOrigin: `${svgOriginTransformXPercentage}% ${
                100 - areaOfEffect.originYMovementPercentage
              }%`
            }}
          >
            {areaOfEffect.svg}
          </div>

          {!isDragging && (
            <Draggable
              positionOffset={{
                x: `-50%`,
                y: '-50%'
              }}
              disabled
              position={{
                x: controlledPosition.x,
                y: controlledPosition.y
              }}
              scale={1}
            >
              <div
                style={{
                  backgroundColor:
                    editable && transformable && theme.palette.primary.main,
                  opacity: 0.8,
                  borderRadius: '100%',
                  width: transformerPointSizeDraggable,
                  height: transformerPointSizeDraggable,
                  position: 'absolute'
                }}
              />
            </Draggable>
          )}

          {!isDragging && (
            <Draggable
              positionOffset={{ x: '-50%', y: '-50%' }}
              disabled={!editable || !transformable}
              position={{
                x: controlledPosition.x,
                y: controlledPosition.y
              }}
              onDrag={onControlledDrag}
              onStart={() => setIsDraggingInTransformer(true)}
              onStop={handleDragStop}
              scale={wrapperScale}
            >
              <div
                style={{
                  backgroundColor: 'transparent',
                  cursor:
                    isDraggingInTransformer || !transformable
                      ? 'default'
                      : 'pointer',
                  borderRadius: '100%',
                  width: transformerPointSizeDraggable,
                  height: transformerPointSizeDraggable
                }}
              />
            </Draggable>
          )}
        </div>
      )}
    </>
  );
};

const AreaOfEffectTransformer: FC<IProps> = (props: IProps): ReactElement => {
  const {
    child,
    objectID,
    renderTransformer,
    transformerHidden,
    selected,
    isDragging,
    wrapperScale,
    mapRotation,
    menuPosition,
    setMenuPosition,
    targetObjectValue,
    updateAreaOfEffect
  } = props;

  return (
    <>
      {renderTransformer && (
        <Transformer
          objectID={objectID}
          renderTransformer={renderTransformer}
          transformerHidden={transformerHidden}
          selected={selected}
          isDragging={isDragging}
          wrapperScale={wrapperScale}
          mapRotation={mapRotation}
          menuPosition={menuPosition}
          setMenuPosition={setMenuPosition}
          targetObjectValue={targetObjectValue}
          updateAreaOfEffect={updateAreaOfEffect}
        />
      )}

      {child}
    </>
  );
};

export default AreaOfEffectTransformer;
