import { getDoc } from '@firebase/firestore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  ListItemButton,
  ListItemIcon,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import CustomTooltip from 'src/components/CustomTooltip';
import { basicTierMaxPlays, customOpacityDarkTheme } from 'src/constants';
import { addPlay, updatePlay } from 'src/data/firestore/setters/play';
import useGlobalDOMEvents from 'src/hooks/useGlobalDOMEvents';
import { auth } from 'src/lib/firebase';
import { clearBoard } from 'src/slices/board/boardModel';
import store, { RootState, useSelector } from 'src/store';
import { Play } from 'src/types/play';
import { Team } from 'src/types/team';
import { AccountData } from 'src/types/user';
import hexToRGB from 'src/utils/hexToRGB';

const ClearButton: FC = (): ReactElement => {
  const theme: Theme = useTheme();
  const { teamId, playId } = useParams();
  const team: Team = useSelector((state: RootState) => state.teamSlice.team);
  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );
  const activeWorkspaceData = teamId ? team : accountData;
  const sessionId = teamId ? playId : null;
  const spaceTier = teamId ? team.spaceTier : accountData.spaceTier;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { play } = store.getState().playSlice;
  const { model: board } = store.getState().boardSlice;

  // keyboard event listeners
  useGlobalDOMEvents({
    keydown(e: KeyboardEvent) {
      // CTRL + 'DEL', open delete play form
      if (e.key === 'Delete' && e.ctrlKey) {
        e.preventDefault();
        setOpen(true);
      }
    }
  });

  const handleConfirm = () => {
    store.dispatch(clearBoard(sessionId));
    setOpen(false);
  };

  const savePlay = async () => {
    setDisabled(true);
    const timestamp = Date.now();
    let newPlay: Play;
    if (play) {
      newPlay = { ...play, board, updatedAt: timestamp, map: board.map };

      await updatePlay(newPlay, true, play.createdAt === play.updatedAt, teamId)
        .then(() => {
          enqueueSnackbar(
            t('play.board.save.success', { title: newPlay.title }),
            {
              variant: 'success'
            }
          );
          setDisabled(false);
        })
        .catch((err) => {
          enqueueSnackbar(
            t('play.board.save.error', {
              title: newPlay.title,
              errorMessage: err.message
            }),
            {
              variant: 'error'
            }
          );
          setDisabled(false);
        });
    } else {
      newPlay = {
        id: null,
        photoURL: null,
        title: `Untitled Play`, // TODO: add option to change title,
        map: board.map,
        updatedAt: timestamp,
        createdAt: timestamp,
        board,
        referencePlaybookIDs: [],
        whitelistedIDs: teamId ? team.memberIDs : [auth.currentUser.uid]
      };

      const playsInfoLength = teamId
        ? team.playsInfo.length
        : accountData.playsInfo.length;

      await addPlay(
        newPlay,
        true,
        playsInfoLength,
        spaceTier,
        teamId || undefined
      )
        .then((data) => {
          getDoc(data).then((snapshot) => {
            enqueueSnackbar(
              t('play.board.save.success', { title: newPlay.title }),
              {
                variant: 'success'
              }
            );
            const updatedPlay: Play = snapshot.data();
            if (!teamId) {
              navigate(`../../app/personal/plays/${updatedPlay.id}`, {
                replace: true
              });
            }

            setDisabled(false);
          });
        })
        .catch((err) => {
          enqueueSnackbar(
            t('play.board.save.error', {
              title: newPlay.title,
              errorMessage: err.message
            }),
            {
              variant: 'error'
            }
          );
          setDisabled(false);
        });
    }
  };
  const handleSaveAndConfirm = async () => {
    await savePlay();

    store.dispatch(clearBoard(sessionId));
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="cb-board-map-selector">
      <CustomTooltip title="Clear board" subtitle="CTRL + DEL" placement="left">
        <ListItemButton
          key="clear"
          sx={{
            height: '35px',
            maxHeight: '35px',
            margin: theme.spacing(0.75),
            borderRadius: `${theme.shape.borderRadius}px`,
            p: '9px',
            backgroundColor: hexToRGB(
              theme.palette.primary.main,
              (customOpacityDarkTheme * 2.5).toString()
            ),
            '&:hover': {
              backgroundColor: hexToRGB(
                theme.palette.primary.main,
                (customOpacityDarkTheme * 5).toString()
              )
            }
          }}
          onClick={handleOpen}
        >
          <ListItemIcon>
            <DeleteForeverIcon style={{ fill: theme.palette.error.main }} />
          </ListItemIcon>
        </ListItemButton>
      </CustomTooltip>
      <Dialog maxWidth="xs" fullWidth onClose={handleClose} open={open}>
        <DialogContent sx={{ height: 'auto', maxHeight: '750px', pb: 4 }}>
          <Container
            sx={{
              width: '100%',
              height: '100%',
              pt: 2
            }}
          >
            <Typography color="textPrimary" variant="h2">
              {t('play.board.clear.title')}
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Typography variant="body1" color="textSecondary">
                {t('play.board.clear.description')}
              </Typography>
              <Box sx={{ mt: 4, float: 'right' }}>
                <Button disabled={disabled} onClick={handleClose}>
                  {t('play.board.clear.buttons.cancel.label')}
                </Button>
                <Button
                  disabled={disabled}
                  onClick={handleConfirm}
                  sx={{ ml: 1 }}
                  variant="contained"
                  color="error"
                >
                  {t('play.board.clear.buttons.confirm.label')}
                </Button>
              </Box>
            </Box>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ClearButton;
