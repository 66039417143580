import { AGENTS } from 'src/ressources/shapes/agents';
import { ABILITIES } from 'src/ressources/shapes/abilities';
import { BASIC_OBJECTS } from 'src/ressources/shapes/basicObjects';
import { DragTypes, IObject } from 'src/types/board';

const determineObjectTitle = (obj: IObject): string => {
  if (obj.type === DragTypes.AGENT) {
    return AGENTS[obj.value]?.title;
  }

  if (obj.type === DragTypes.ABILITY) {
    const [agent, ability] = obj.value.split('.');
    return `${AGENTS[agent]?.title} - ${ABILITIES[agent][ability]?.title}`;
  }

  if (obj.type === DragTypes.BASIC) {
    return BASIC_OBJECTS[obj.value]?.title;
  }

  return '';
};

export default determineObjectTitle;
