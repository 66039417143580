import {
  Container,
  Grid,
  Typography,
  useMediaQuery,
  Card,
  CardHeader,
  CardContent
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import SnowpardsPartnership from '../../ressources/design/announcements/snowpards-critbase-partnership.jpg';

const AnnouncementsContent: FC = () => {
  const theme = useTheme();

  const announcements = [
    {
      title: 'Snowpards Partnership',
      createdAt: '26.06.2022',
      content: (
        <>
          <p dir="ltr">Hi everybody,</p>
          <br />
          <p>
            we are excited to announce that we are from now on partnering with
            Snowpards! They are a young and fast growing e-sport organization
            that supports smaller teams and helps them grow and accomplish their
            goals.
          </p>
          <br />
          <p dir="ltr">Max &amp; Alex</p>
          <br />
          <br />
          <img
            style={{ width: '100%', height: 'auto' }}
            src={SnowpardsPartnership}
            alt="Snowpards partnership"
          />
        </>
      )
    },
    {
      title: 'Changelog',
      createdAt: '05.04.2022',
      content: (
        <>
          <p dir="ltr">Hi everybody,</p>
          <br />
          <p dir="ltr">
            we are excited to announce that critbase will start a new chapter!
            The beta phase ends now and it comes with quite some changes.
          </p>
          <br />
          <Typography align="left" variant="h3">
            Changes:
          </Typography>
          <br />
          <ul>
            <li dir="ltr" style={{ marginLeft: 20 }}>
              <p dir="ltr">
                <b>Design:</b> There is a major change to the design theme of
                the overall website. We hope you like it, let us know what you
                think about it!
              </p>
            </li>
          </ul>
          <br />
          <ul>
            <li dir="ltr" style={{ marginLeft: 20 }}>
              <p dir="ltr">
                <b>Arrows:</b> Arrows and straight lines are added to the tool
                menu. They are erasable just like the drawn lines.
              </p>
            </li>
          </ul>
          <br />
          <ul>
            <li dir="ltr" style={{ marginLeft: 20 }}>
              <p dir="ltr">
                <b> Travel time pencil: </b> A new line type that displays the
                travel time running with a knife (6.573 m/s) has been added to
                the draw tool. You can use this to time rotations better. Let us
                know what you think of this feature and if you need additional
                travel speeds( e.g. walking, crouching).
              </p>
            </li>
          </ul>
          <br />
          <ul>
            <li dir="ltr" style={{ marginLeft: 20 }}>
              <p dir="ltr">
                <b>Subscription:</b> The biggest change will be the addition of
                a subscription tier to working spaces. From now on you can up-or
                downgrade the subscription of a space. A distinction is made
                between:
              </p>
            </li>
            <ul>
              <li dir="ltr" style={{ marginLeft: 40 }}>
                <p dir="ltr">
                  <b>Basic:</b>
                </p>
              </li>
              <ul>
                <li dir="ltr" style={{ marginLeft: 60 }}>
                  <p dir="ltr">All tools on board</p>
                </li>
                <li dir="ltr" style={{ marginLeft: 60 }}>
                  <p dir="ltr">Real-time collaboration in team space</p>
                </li>
                <li dir="ltr" style={{ marginLeft: 60 }}>
                  <p dir="ltr">Sequences</p>
                </li>
                <li dir="ltr" style={{ marginLeft: 60 }}>
                  <p dir="ltr">5 saved plays and 3 saved playbooks</p>
                </li>
              </ul>
              <li dir="ltr" style={{ marginLeft: 40 }}>
                <p dir="ltr">
                  <b>Pro: </b>
                </p>
              </li>
              <ul>
                <li dir="ltr" style={{ marginLeft: 60 }}>
                  <p dir="ltr">All tools on board</p>
                </li>
                <li dir="ltr" style={{ marginLeft: 60 }}>
                  <p dir="ltr">Real-time collaboration in team space</p>
                </li>
                <li dir="ltr" style={{ marginLeft: 60 }}>
                  <p dir="ltr">Sequences</p>
                </li>
                <li dir="ltr" style={{ marginLeft: 60 }}>
                  <p dir="ltr">Unlimited saved plays and playbooks</p>
                </li>
              </ul>
            </ul>
          </ul>
          <br />
          <p dir="ltr" style={{ marginLeft: 20 }}>
            The subscription costs for personal and team spaces will come at two
            different price points.
          </p>
          <ul>
            <li dir="ltr" style={{ marginLeft: 40 }}>
              <p dir="ltr">
                Personal Space Pro Subscription will cost 4$/month,
                20$/binannually or 36$/annually
              </p>
            </li>
            <ul>
              <li dir="ltr" style={{ marginLeft: 60 }}>
                <p dir="ltr">
                  Please note that plays in your personal space are only visible
                  to you and are not visible to team members of your other team
                  spaces.
                </p>
              </li>
            </ul>
            <li dir="ltr" style={{ marginLeft: 40 }}>
              <p>
                Team Space Pro Subscription will cost 20$/month,
                100$/binannually or 180$/annually
              </p>
            </li>
            <ul>
              <li dir="ltr" style={{ marginLeft: 60 }}>
                <p dir="ltr">
                  The space itself is upgraded. That means all team members can
                  use Pro features regardless of who paid the subscription.
                </p>
              </li>
            </ul>
          </ul>
          <br />
          <p dir="ltr">
            We would like to elaborate on why we decided to introduce
            subscriptions. We understand that this will initially seem to be
            disadvantageous for you. However, there are 3 primary reasons why we
            feel that there is no alternative:
          </p>
          <br />
          <p dir="ltr">
            <b>1.</b> Most importantly, we can only continue to run critbase if
            it is financially sustainable. Every week we put many hours of work
            into the project. In the long run, we wish to be able to spend all
            our time on the project. And the more time we can invest in the
            project, the better the product will be for you.
          </p>
          <br />
          <p dir="ltr">
            <b>2.</b> We can better evaluate what our core community really
            needs. Feedback from paying users is much more meaningful and
            valuable than feedback that metaphorically speaking
            &quot;doesn&lsquo;t have a price tag&quot;. Those of you who pay for
            the services we provide will also hold us accountable for meeting
            their expectations.
          </p>
          <br />
          <p dir="ltr">
            <b>3.</b>Non-paying users are not excluded. We will, as we do now,
            continue to strive to offer every feature in a toned-down version in
            free spaces as well.
          </p>
          <br />
          <p dir="ltr">
            We also want to give you an outlook on upcoming features. In the
            end, critbase aims to be a place that combines all tools for
            competitive teams. That&lsquo;s why we are working on adding a scrim
            matchmaking service to the website that allows you to match up with
            other teams and schedule scrims so you can all improve together!
          </p>
          <br />
          <p dir="ltr">
            Thanks to everybody that contributed by reporting bugs or giving
            feedback.
          </p>
          <p dir="ltr">
            We hope you are as excited as we are, remember this is just the
            beginning!
          </p>
          <br />
          <p dir="ltr">Max &amp; Alex</p>
          <div>
            <br />
          </div>
        </>
      )
    }
  ];

  return (
    <>
      {announcements.map((item) => (
        <Card
          key={item.title}
          sx={{
            mt: 2,
            border: `1px solid ${theme.palette.divider}`,
            maxWidth: '100%'
          }}
        >
          <CardHeader
            title={
              <Typography align="left" variant="h1">
                {item.title}
              </Typography>
            }
            subheader={item.createdAt}
          />

          <CardContent sx={{ pt: 0 }}>
            <Typography
              align="left"
              variant="body1"
              style={{
                fontSize: 14,
                maxWidth: '100%'
              }}
            >
              {item.content}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default AnnouncementsContent;
