import {
  Box,
  capitalize,
  Container,
  Dialog,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { FC, useState } from 'react';
import { backgroundColorSidebar } from 'src/theme/dark-theme-options';
import { ContactDialogContent } from './app/contact';
import { LegalDialogContent } from './app/legal';
import { PrivacyDialogContent } from './app/privacy';
import { TermsDialogContent } from './app/terms';
import CustomDialog from './CustomDialog';

type InfoDialogOptions = 'terms' | 'privacy' | 'legal' | 'contact';

const sectionDisclosures = [
  {
    title: 'Contact',
    dialog: 'contact' as InfoDialogOptions
  },
  {
    title: 'Terms & Conditions',
    dialog: 'terms' as InfoDialogOptions
  },
  {
    title: 'Privacy Policy',
    dialog: 'privacy' as InfoDialogOptions
  },
  {
    title: 'Legal Notice',
    dialog: 'legal' as InfoDialogOptions
  }
];

const Footer: FC = (props) => {
  const [disclosureDialogTab, setDisclosureDialogTab] =
    useState<InfoDialogOptions>(null);

  const handleOpenDisclosureDialog = (dialog: InfoDialogOptions) => () => {
    setDisclosureDialogTab(dialog);
  };

  const handleCloseDisclosureDialog = () => {
    setDisclosureDialogTab(null);
  };

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        py: smDown ? 2 : 4,
        borderTop: `1px solid ${theme.palette.divider}`
      }}
      {...props}
    >
      <Container maxWidth="md">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item sx={{ px: smDown ? 0.5 : 0, pb: smDown ? 0.25 : 0 }}>
            <Typography
              color="textSecondary"
              variant="caption"
              sx={{ fontSize: smDown && '0.65rem' }}
            >
              © 2021 Critbase
            </Typography>
            <Typography
              color="textSecondary"
              variant="caption"
              sx={{ pl: 2, fontSize: smDown && '0.65rem' }}
            >
              All rights reserved.
            </Typography>
          </Grid>
          <Grid item>
            {sectionDisclosures.map((section) => (
              <Typography
                key={section.dialog}
                sx={{
                  fontSize: smDown && '11px !important',
                  px: smDown ? 0.5 : 2,
                  '&:hover': { cursor: 'pointer', textDecoration: 'underline' }
                }}
                onClick={handleOpenDisclosureDialog(section.dialog)}
              >
                {section.title}
              </Typography>
            ))}
          </Grid>
        </Grid>

        <CustomDialog
          title={disclosureDialogTab && capitalize(disclosureDialogTab)}
          width={600}
          open={!!disclosureDialogTab}
          setOpen={setDisclosureDialogTab}
          onClose={handleCloseDisclosureDialog}
          buttons={[]}
        >
          {disclosureDialogTab === 'terms' && (
            <TermsDialogContent onClose={handleCloseDisclosureDialog} />
          )}
          {disclosureDialogTab === 'privacy' && (
            <PrivacyDialogContent onClose={handleCloseDisclosureDialog} />
          )}
          {disclosureDialogTab === 'legal' && (
            <LegalDialogContent onClose={handleCloseDisclosureDialog} />
          )}
          {disclosureDialogTab === 'contact' && (
            <ContactDialogContent onClose={handleCloseDisclosureDialog} />
          )}
        </CustomDialog>
      </Container>
    </Box>
  );
};

export default Footer;
