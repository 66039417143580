import CloseIcon from '@mui/icons-material/Close';
import type { Theme } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import type { SxProps } from '@mui/system';
import type { FC } from 'react';
import { OBJECT_SIZE } from 'src/constants';

interface IProps {
  sx?: SxProps<Theme>;
}

const CrossIcon: FC<IProps> = (props: SvgIconProps) => (
  <CloseIcon
    {...props}
    sx={{
      color: 'red',
      opacity: 0.9,
      width: OBJECT_SIZE - 10,
      height: OBJECT_SIZE - 10
    }}
  />
);

export default CrossIcon;
