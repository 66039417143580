import { Grow } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

/* eslint prefer-arrow-callback: ["error", { "allowNamedFunctions": true }] */
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});

export default Transition;
