import { Box, Button, Container, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import NotFoundIllustration from '../ressources/not-found.svg';

interface IProps {
  header?: string;
  title?: string;
  description?: string;
}

const NotFound: FC<IProps> = (props: IProps) => {
  const { header, title, description } = props;
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      <Helmet>
        <title>{header || 'Error: Page Not Found | Critbase'}</title>
      </Helmet>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.paper',
          display: 'flex',
          minHeight: '100%',
          px: 3,
          py: '80px'
        }}
      >
        <Container maxWidth="lg">
          <Typography
            align="center"
            color="textPrimary"
            variant={mobileDevice ? 'h4' : 'h1'}
          >
            {title || '404: The page you are looking for isn’t here'}
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 0.5, whiteSpace: 'normal' }}
            variant="subtitle2"
          >
            {description ||
              'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.'}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6
            }}
          >
            <Box
              alt="Under development"
              component="img"
              src={NotFoundIllustration}
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 400
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6
            }}
          >
            <Button
              color="primary"
              size="small"
              type="button"
              variant="contained"
              component={RouterLink}
              to="/"
            >
              Back to Home
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default NotFound;
