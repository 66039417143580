import { log } from 'firebase-functions/lib/logger';
import {
  arrayUnion,
  collection,
  doc,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  runTransaction,
  setDoc,
  Transaction,
  writeBatch
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { toJpeg } from 'html-to-image';
import { accountDataConverter } from 'src/data/converter/accountDataConverter';
import { playbookConverter } from 'src/data/converter/playbookConverter';
import { teamConverter } from 'src/data/converter/team/teamConverter';
import {
  trackAddSoloPlay,
  trackAddTeamPlay,
  trackClonePlay,
  trackDeleteSoloPlay,
  trackDeleteTeamPlay,
  trackUpdateSoloPlay,
  trackUpdateTeamPlay
} from 'src/data/mixpanel/setters/trackEvent';
import serverSideLogger from 'src/logging/serverSideLogger';
import { Play, Playbook, PlaybookInfo, PlayInfo } from 'src/types/play';
import { SpaceTier } from 'src/types/subscription';
import { Team } from 'src/types/team';
import { AccountData } from 'src/types/user';
import arrContainsObjWithID from 'src/utils/arrContainsObjWithID';
import playsInfoToThumbnail from 'src/utils/playsInfoToThumbnail';
import playToPlayInfo from 'src/utils/playToPlayInfo';
import { LoggerInput } from 'src/types/functionsInput';
import { auth, firestore, functions, storage } from '../../../lib/firebase';
import { playConverter } from '../../converter/playConverter';
import { updatePlaybook } from './playbook';

interface TransactionChanges {
  docRef: DocumentReference<any>;
  value: any;
}

const playsRef = collection(firestore, 'plays');
const playbooksRef = collection(firestore, 'playbooks');

const boardToJpeg = async (playId): Promise<string> => {
  const imgSize = 350;
  // get whiteboard element
  const boardNode = document.getElementById('cb-whiteboard');
  const prefix = 'thumbnail';
  const storagePath = `plays/${playId}/`;
  try {
    const dataUrl = await toJpeg(boardNode, {
      style: {
        transform: `scale(4.5, 4.5)`
      },
      cacheBust: true,
      quality: 1,
      canvasHeight: imgSize,
      canvasWidth: imgSize
    });
    const response = await fetch(dataUrl);
    const file = await response.blob();
    const extension = file.type.split('/')[1];

    // Makes reference to the storage bucket location of original image
    const originalFilename = `${prefix}_${playId}`;
    const originalRefPath = `${storagePath}${originalFilename}.${extension}`;

    const originalRef = ref(storage, originalRefPath);

    // resize image here not with extension

    // Starts the upload
    await uploadBytes(originalRef, file);
    // get download url
    const downloadResult = await getDownloadURL(originalRef);

    return downloadResult;
  } catch (err) {
    // log err
    return null;
  }
};

/**
 * add play document in plays collection and in user or team document as playInfo in firestore
 * @param {Play}play - Id of the play
 * @param {string}teamId - Id of the team, if the play is a team play, otherwise null
 */
export const addPlay = async (
  play: Play,
  trackEvent: boolean,
  playsInfoLength: number,
  spaceTier: SpaceTier,
  teamId?: string,
  createPhotoUrl = true
): Promise<DocumentReference<Play>> => {
  if (spaceTier === 'basic' && playsInfoLength >= 5) {
    throw new Error(
      'It is not possible to save more than 5 plays with the basic tier!'
    );
  }

  // call this function when we want to save the play
  const batch = writeBatch(firestore);
  let newDocRef: DocumentReference<Play>;

  // when we have already assigned an id, e.g. for new team plays
  if (play.id) {
    newDocRef = await doc(firestore, 'plays', play.id).withConverter(
      playConverter
    );
    await setDoc(newDocRef, play);
  } else {
    newDocRef = await doc(playsRef).withConverter(playConverter);
  }

  if (createPhotoUrl) {
    // create play thumbnail
    const imageUrl = await boardToJpeg(newDocRef.id);
    // need to set photoUrl here initially so it is contained in play
    play.photoURL = imageUrl;
  }

  batch.set(newDocRef, play);

  if (!teamId) {
    const userDocRef = doc(firestore, 'users', auth.currentUser.uid);

    batch.update(
      userDocRef,
      'playsInfo',
      arrayUnion(playToPlayInfo(play, newDocRef.id))
    );
  } else {
    const teamDocRef = doc(firestore, 'teams', teamId);

    batch.update(
      teamDocRef,
      'playsInfo',
      arrayUnion(playToPlayInfo(play, newDocRef.id))
    );
  }

  return batch
    .commit()
    .then(() => {
      if (trackEvent && teamId) {
        trackAddTeamPlay({
          team_id: teamId,
          play_id: newDocRef.id,
          play_title: play.title,
          map: play.map
        });
      }

      if (trackEvent && !teamId) {
        if (trackEvent) {
          trackAddSoloPlay({
            play_id: newDocRef.id,
            play_title: play.title,
            map: play.map
          });
        }
      }

      console.log(
        'Setter "addPlay" terminated. Added: ',
        play.title,
        ' created by ',
        auth.currentUser.displayName
      );
      return newDocRef;
    })
    .catch((e) => {
      console.error('Error occured while trying to add a Play:', e);
      const logObject: LoggerInput = {
        kind: 'error',
        function: 'addPlay',
        message: e,
        metaData: play
      };
      serverSideLogger(logObject);
      return newDocRef;
    });
};

/**
 * update play document in plays collection and in user or team document as playInfo in firestore
 * @param {Play}play - Id of the play
 * @param {string}teamId - Id of the team, if the play is a team play, otherwise null
 */

export const updatePlay = async (
  play: Play,
  trackEvent: boolean,
  isFirstUpdate: boolean,
  teamId?: string,
  createPhotoUrl = true
): Promise<DocumentReference<Play>> => {
  let returnRef: DocumentReference<Play>;

  // trigger setPlayInfoImage

  // create play thumbnail
  if (createPhotoUrl) {
    const imageUrl = await boardToJpeg(play.id);
    play.photoURL = imageUrl;
  }

  try {
    await runTransaction(firestore, async (transaction) => {
      const usersRef = collection(firestore, 'users');
      const teamsRef = collection(firestore, 'teams');
      const playDocRef = doc(playsRef, play.id).withConverter(playConverter);
      const playDoc = await transaction.get(playDocRef);

      const referencePlaybooks: DocumentSnapshot<Playbook>[] =
        (playDoc?.data()?.referencePlaybookIDs &&
          (await Promise.all(
            playDoc?.data()?.referencePlaybookIDs?.map(async (playbookId) => {
              const playbookDocRef = doc(
                playbooksRef,
                playbookId
              ).withConverter(playbookConverter);
              const playbookDoc = await transaction.get(playbookDocRef);
              return playbookDoc;
            })
          ))) ||
        [];

      if (teamId) {
        const teamDocRef: DocumentReference<Team> = doc(
          teamsRef,
          teamId
        ).withConverter(teamConverter);
        const teamDoc: DocumentSnapshot<Team> = await transaction.get(
          teamDocRef
        );
        if (!teamDoc.exists()) {
          throw new Error('Team not found!');
        }

        updatePlaysAndPlaybooksInfoOnPlayUpdate(
          transaction,
          teamDocRef,
          teamDoc,
          play,
          referencePlaybooks
        );

        updateReferencePlaybooksOnPlayUpdate(
          referencePlaybooks,
          transaction,
          play
        );
      } else {
        const userDocRef: DocumentReference<AccountData> = doc(
          usersRef,
          auth.currentUser.uid
        ).withConverter(accountDataConverter);
        const userDoc: DocumentSnapshot<AccountData> = await transaction.get(
          userDocRef
        );
        if (!userDoc.exists()) {
          throw new Error('User not found!');
        }

        updatePlaysAndPlaybooksInfoOnPlayUpdate(
          transaction,
          userDocRef,
          userDoc,
          play,
          referencePlaybooks
        );

        updateReferencePlaybooksOnPlayUpdate(
          referencePlaybooks,
          transaction,
          play
        );
      }

      await transaction.set(playDocRef, JSON.parse(JSON.stringify(play))); // trick to filter out undefined fields

      returnRef = playDocRef;
    });

    if (trackEvent && teamId) {
      if (isFirstUpdate) {
        trackAddTeamPlay({
          team_id: teamId,
          play_id: play.id,
          play_title: play.title,
          map: play.map
        });
      } else {
        trackUpdateTeamPlay({
          team_id: teamId,
          play_id: play.id,
          play_title: play.title,
          map: play.map
        });
      }
    }

    if (trackEvent && !teamId) {
      trackUpdateSoloPlay({
        play_id: play.id,
        play_title: play.title,
        map: play.map
      });
    }
  } catch (e) {
    console.error(e);
    const logObject: LoggerInput = {
      kind: 'error',
      function: 'updatePlay',
      message: e,
      metaData: play
    };
    serverSideLogger(logObject);
    throw new Error('Updating play failed.');
  }
  return returnRef;
};

export const multiClonePlays = async (
  playIds: string[],
  trackEvent: boolean,
  teamIds: string[],
  userId: string,
  spaceTier: SpaceTier
) =>
  runTransaction(firestore, async (trx: Transaction) => {
    if (spaceTier === 'basic') {
      throw new Error('It is not possible to clone plays with the basic tier!');
    }

    const promises = await Promise.all(
      playIds.map((id) =>
        clonePlay(id, trackEvent, teamIds, userId, trx, spaceTier)
      )
    );

    return promises;
  });

/**
 *
 * @param playId
 * @param trackEvent
 * @param teamIds
 * @param personalSelected
 * @returns
 */
export const clonePlay = async (
  playId: string,
  trackEvent: boolean,
  teamIds: string[],
  userId: string,
  trx: Transaction,
  spaceTier: SpaceTier
): Promise<void> => {
  if (spaceTier === 'basic') {
    throw new Error('It is not possible to clone plays with the basic tier!');
  }

  try {
    const teamsRef = collection(firestore, 'teams');
    const playDocRef = doc(playsRef, playId).withConverter(playConverter);
    const updateChanges: TransactionChanges[] = [];
    const setChanges: TransactionChanges[] = [];

    const playDoc = await trx.get(playDocRef);

    if (!playDoc.exists()) {
      throw new Error('Play Document does not exist');
    }

    const play: Play = playDoc.data();

    // get
    if (play) {
      await Promise.all(
        teamIds.map(async (id) => {
          // update play informations like id, whitelistedIds, referencePlaybookIds
          // read
          const teamDocRef: DocumentReference<Team> = doc(
            teamsRef,
            id
          ).withConverter(teamConverter);
          const teamDoc: DocumentSnapshot<Team> = await trx.get(teamDocRef);

          if (!teamDoc.exists()) {
            throw new Error('Team not found!');
          }

          const team: Team = teamDoc.data();

          // update play informations like id, whitelistedIds, referencePlaybookIds
          const clonedPlay: Play = {
            id: undefined,
            updatedAt: Date.now(),
            whitelistedIDs: team.memberIDs,
            referencePlaybookIDs: null,
            board: play.board,
            title: play.title,
            createdAt: play.createdAt,
            photoURL: play.photoURL,
            map: play.map
          };

          // write
          const newDocRef = doc(playsRef);

          updateChanges.push({
            docRef: teamDocRef,
            value: {
              playsInfo: await arrayUnion(
                playToPlayInfo(clonedPlay, newDocRef.id)
              )
            }
          });

          setChanges.push({
            docRef: newDocRef.withConverter(playConverter),
            value: clonedPlay
          });
        })
      );

      // only provided when cloning to personal space was selected
      if (userId) {
        // update play informations like id, whitelistedIds, referencePlaybookIds
        const clonedPlay: Play = {
          id: undefined,
          updatedAt: Date.now(),
          whitelistedIDs: [userId],
          referencePlaybookIDs: null,
          board: play.board,
          title: play.title,
          createdAt: play.createdAt,
          photoURL: play.photoURL,
          map: play.map
        };

        // write

        const userDocRef = doc(firestore, 'users', auth.currentUser.uid);
        const newDocRef = doc(playsRef);

        updateChanges.push({
          docRef: userDocRef,
          value: {
            playsInfo: arrayUnion(playToPlayInfo(clonedPlay, newDocRef.id))
          }
        });

        setChanges.push({
          docRef: newDocRef.withConverter(playConverter),
          value: clonedPlay
        });
      }

      // execute transaction creations and updates
      setChanges.forEach((data) => {
        trx.set(data.docRef, data.value);
      });

      updateChanges.forEach((data) => {
        trx.update(data.docRef, data.value);
      });
    }

    if (trackEvent) {
      trackClonePlay({
        team_ids: teamIds,
        user_id: userId,
        play_id: play.id,
        play_title: play.title,
        map: play.map
      });
    }
  } catch (e) {
    console.error(e);
    const logObject: LoggerInput = {
      kind: 'error',
      function: 'clonePlay',
      message: e,
      metaData: {
        playId,

        teamIds,
        userId
      }
    };
    serverSideLogger(logObject);
  }
};

/**
 * delete play document in plays collection and in user or team document as playInfo in firestore
 * @param {string}playId - Id of the play
 * @param {string}teamId - Id of the team, if the play is a team play, otherwise null
 */
export const deletePlay = async (
  playId: string,
  trackEvent: boolean,
  spaceTier: SpaceTier,
  teamId?: string
): Promise<void> => {
  // trx to delete play & play infos
  try {
    await runTransaction(firestore, async (transaction) => {
      const usersRef = collection(firestore, 'users');
      const teamsRef = collection(firestore, 'teams');
      const playDocRef = doc(playsRef, playId).withConverter(playConverter);
      const playDoc = await transaction.get(playDocRef);

      if (teamId) {
        // read
        const teamDocRef: DocumentReference<Team> = doc(
          teamsRef,
          teamId
        ).withConverter(teamConverter);
        const teamDoc: DocumentSnapshot<Team> = await transaction.get(
          teamDocRef
        );
        if (!teamDoc.exists()) {
          throw new Error('Team not found!');
        }

        // write
        await transaction.delete(playDocRef);
        await transaction.update(teamDocRef, {
          playsInfo: teamDoc
            .data()
            .playsInfo.filter((playInfo) => playInfo.id !== playId)
        });
      } else {
        // read
        const userDocRef: DocumentReference<AccountData> = doc(
          usersRef,
          auth.currentUser.uid
        ).withConverter(accountDataConverter);
        const userDoc: DocumentSnapshot<AccountData> = await transaction.get(
          userDocRef
        );
        if (!userDoc.exists()) {
          throw new Error('User not found!');
        }

        // write
        await transaction.delete(playDocRef);
        await transaction.update(userDocRef, {
          playsInfo: userDoc
            .data()
            .playsInfo.filter((playInfo) => playInfo.id !== playId)
        });
      }

      // update playbooks
      const playbookUpdates: Promise<DocumentReference<Playbook>>[] = playDoc
        .data()
        .referencePlaybookIDs?.map(async (playbookID) => {
          const playbookDocRef: DocumentReference<Playbook> = doc(
            firestore,
            'playbooks',
            playbookID
          ).withConverter(playbookConverter);
          const playbookDoc: DocumentSnapshot<Playbook> = await getDoc(
            playbookDocRef
          );
          const prevPlaybook = playbookDoc.data();

          const updatedPlaysInfo: PlayInfo[] = prevPlaybook.playsInfo.filter(
            (playInfo) => playInfo.id !== playId
          );

          const newPlaybook: Playbook = {
            id: prevPlaybook.id,
            title: prevPlaybook.title,
            photoURL: prevPlaybook.photoURL,
            updatedAt: prevPlaybook.updatedAt,
            createdAt: prevPlaybook.createdAt,
            description: prevPlaybook.description,
            numberOfPlays: updatedPlaysInfo.length,
            thumbnail: playsInfoToThumbnail(updatedPlaysInfo),
            playsInfo: updatedPlaysInfo,
            whitelistedIDs: prevPlaybook.whitelistedIDs
          };

          if (teamId) {
            return updatePlaybook(newPlaybook, false, teamId);
          }
          return updatePlaybook(newPlaybook, false);
        });

      if (playbookUpdates) {
        await Promise.allSettled(playbookUpdates);
      }
    });

    if (trackEvent && teamId) {
      trackDeleteTeamPlay({
        team_id: teamId,
        play_id: playId
      });
    }

    if (trackEvent && !teamId) {
      trackDeleteSoloPlay({
        play_id: playId
      });
    }
  } catch (e) {
    console.error(e);
    const logObject: LoggerInput = {
      kind: 'error',
      function: 'deletePlay',
      message: e,
      metaData: {
        playId,

        teamId
      }
    };
    serverSideLogger(logObject);
    throw new Error('deleting play failed');
  }
};

const updateReferencePlaybooksOnPlayUpdate = async (
  referencePlaybooks: DocumentSnapshot<Playbook>[],
  transaction: Transaction,
  play: Play
) => {
  referencePlaybooks.forEach(async (referencePlaybookDoc) => {
    const referencePlaybook = referencePlaybookDoc.data();
    const updatedPlaysInfo: PlayInfo[] = referencePlaybook.playsInfo.map(
      (playInfo) => {
        if (playInfo.id !== play.id) {
          return playInfo;
        }
        const newPlayInfo: PlayInfo = {
          id: play.id,
          title: play.title,
          photoURL: play.photoURL,
          map: play.map,
          updatedAt: Date.now(),
          createdAt: play.createdAt
        };

        return newPlayInfo;
      }
    );

    const newPlaybook: Playbook = {
      id: referencePlaybook.id,
      title: referencePlaybook.title,
      photoURL: referencePlaybook.photoURL,
      updatedAt: referencePlaybook.updatedAt,
      createdAt: referencePlaybook.createdAt,
      description: referencePlaybook.description,
      numberOfPlays: updatedPlaysInfo.length,
      thumbnail: playsInfoToThumbnail(updatedPlaysInfo),
      playsInfo: updatedPlaysInfo,
      whitelistedIDs: referencePlaybook.whitelistedIDs
    };

    await transaction.set(referencePlaybookDoc.ref, newPlaybook);
  });
};

const updatePlaysAndPlaybooksInfoOnPlayUpdate = async (
  transaction: Transaction,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parentDocRef: DocumentReference<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parentDoc: DocumentSnapshot<any>,
  play: Play,
  referencePlaybooks: DocumentSnapshot<Playbook>[]
) => {
  await transaction.update(parentDocRef, {
    playsInfo: parentDoc.data().playsInfo.map((playInfo) => {
      if (playInfo.id !== play.id) {
        return playInfo;
      }
      const newPlayInfo: PlayInfo = {
        id: play.id,
        title: play.title,
        photoURL: play.photoURL,
        map: play.map,
        updatedAt: Date.now(),
        createdAt: play.createdAt
      };

      return newPlayInfo;
    }),

    playbooksInfo: parentDoc.data().playbooksInfo
      ? parentDoc.data().playbooksInfo.map((playbookInfo) => {
          const matchingReferencePlaybookDoc: DocumentSnapshot<Playbook> =
            arrContainsObjWithID(
              referencePlaybooks,
              'id',
              playbookInfo.id,
              'object'
            );

          if (matchingReferencePlaybookDoc?.data()) {
            const matchingReferencePlaybook =
              matchingReferencePlaybookDoc.data();

            const updatedPlaysInfo: PlayInfo[] =
              matchingReferencePlaybook.playsInfo.map((playInfo) => {
                if (playInfo.id !== play.id) {
                  return playInfo;
                }
                const newPlayInfo: PlayInfo = {
                  id: play.id,
                  title: play.title,
                  photoURL: play.photoURL,
                  map: play.map,
                  updatedAt: Date.now(),
                  createdAt: play.createdAt
                };

                return newPlayInfo;
              });

            const newPlaybookInfo: PlaybookInfo = {
              id: matchingReferencePlaybook.id,
              title: matchingReferencePlaybook.title,
              photoURL: matchingReferencePlaybook.photoURL,
              updatedAt: matchingReferencePlaybook.updatedAt,
              createdAt: matchingReferencePlaybook.createdAt,
              description: matchingReferencePlaybook.description,
              numberOfPlays: updatedPlaysInfo.length,
              thumbnail: playsInfoToThumbnail(updatedPlaysInfo)
            };

            return newPlaybookInfo;
          }

          return playbookInfo;
        })
      : []
  });
};
