import { Play, PlayInfo } from 'src/types/play';

const playToPlayInfo = (play: Play, id: string): PlayInfo => {
  const playInfo = {
    id,
    title: play.title,
    photoURL: play.photoURL ? play.photoURL : null,
    map: play.map,
    updatedAt: play.updatedAt,
    createdAt: play.createdAt
  };

  return playInfo;
};

export default playToPlayInfo;
