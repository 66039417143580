import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  IconButton,
  Paper,
  styled,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  useTheme
} from '@mui/material';
import { FC, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import CustomTooltip from 'src/components/CustomTooltip';
import {
  addStepLayers,
  changeStepLayer,
  removeStepLayers
} from 'src/slices/board/boardModel';
import { setSelectedObject } from 'src/slices/board/boardUi';
import store, { RootState, useSelector } from 'src/store';
import exists from 'src/utils/exists';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0
    },
    '&:not(:first-of-type)': {
      borderRadius: `${theme.shape.borderRadius}px`
    },
    '&:first-of-type': {
      borderRadius: `${theme.shape.borderRadius}px`
    }
  }
}));

const MAX_AMOUNT_STEPS = 10;

const SequenceStepper: FC = (): ReactElement => {
  const theme: Theme = useTheme();
  const { playId: sessionId, teamId } = useParams();

  const { stepLayers, activeStep } = useSelector(
    (state: RootState) => state?.boardSlice.model
  ); // need to change to step layers syntax

  const stepsAmount = stepLayers ? Object.keys(stepLayers).length : null;
  const addStep = () => {
    const newStepCount = stepsAmount;
    if (stepsAmount <= newStepCount) {
      // increase stepAmount in store

      store.dispatch(addStepLayers(newStepCount, teamId ? sessionId : null));
    }
  };
  const removeStep = () => {
    const newStepCount = stepsAmount - 1; // bc we start at 0
    if (newStepCount >= 0) {
      // decrease step layers in store
      store.dispatch(removeStepLayers(stepsAmount, teamId ? sessionId : null));
    }
  };
  const renderButtons = (): ReactElement[] => {
    const buttons: ReactElement[] = [];
    Object.entries(stepLayers).forEach(([, layer]): void => {
      buttons.push(
        <ToggleButton key={layer.id} value={layer.id} sx={buttonSx}>
          <p>{layer.id}</p>
        </ToggleButton>
      );
    });

    return buttons;
  };

  const handleChange = (event, nextStep) => {
    if (exists(nextStep) && nextStep !== activeStep) {
      store.dispatch(setSelectedObject(null));
      store.dispatch(changeStepLayer(nextStep, teamId ? sessionId : null));
    }
  };

  const buttonSx = {
    height: '40px',
    width: '40px',
    margin: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    border: 0,
    color: theme.palette.text.secondary
  };

  return (
    <div
      className="cb-sequence-stepper"
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        zIndex: 100,
        margin: theme.spacing(2)
      }}
    >
      <Paper
        sx={{
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.divider}`,
          boxShadow: theme.shadows[19]
        }}
      >
        {stepsAmount && !(stepsAmount <= 1) && (
          <CustomTooltip title="Remove step" placement="top">
            <IconButton
              key="remove-step"
              onClick={removeStep}
              sx={{ ...buttonSx, marginRight: 0 }}
            >
              <RemoveIcon />
            </IconButton>
          </CustomTooltip>
        )}

        <StyledToggleButtonGroup
          onChange={handleChange}
          exclusive
          value={activeStep}
          color="primary"
        >
          {renderButtons()}
        </StyledToggleButtonGroup>
        {stepsAmount && !(stepsAmount >= MAX_AMOUNT_STEPS) && (
          <CustomTooltip title="Add step" placement="top">
            <IconButton
              key="add-step"
              onClick={addStep}
              size="medium"
              sx={{
                ...buttonSx,
                marginLeft: 0
              }}
            >
              <AddIcon />
            </IconButton>
          </CustomTooltip>
        )}
      </Paper>
    </div>
  );
};

export default SequenceStepper;
