import {
  addDoc,
  collection,
  getDocs,
  onSnapshot,
  query,
  where
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { firestore, functions } from 'src/lib/firebase';
import { LoggerInput } from 'src/types/functionsInput';
import serverSideLogger from 'src/logging/serverSideLogger';

interface DisplayPrice {
  amount: number;
  interval: 'week' | 'month' | 'year';
  intervalCount: number;
  priceId: string;
}

interface DisplayProduct {
  productName: string;
  productId: string;
  prices: DisplayPrice[];
}

const triggerCreatePortalLink = httpsCallable(
  functions,
  'ext-firestore-stripe-payments-createPortalLink',
  {}
);

const productsRef = collection(firestore, `products`);

export const createCheckoutSession = async (
  priceId: string,
  spaceId: string,
  uid: string
) => {
  const checkoutSessionRef = collection(
    firestore,
    `users/${uid}/checkout_sessions`
  );

  console.log('priceId:', priceId);

  console.log('spaceId:', spaceId);

  console.log('uid:', uid);

  try {
    console.log('checkoutsessionref:', checkoutSessionRef, ' price:', priceId);

    const docRef = await addDoc(checkoutSessionRef, {
      price: priceId,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
      metadata: {
        spaceId
      }
    });

    // Wait for the CheckoutSession to get attached by the extension
    await onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        console.error('Error occured while checking out.', error.message);

        throw new Error('Error occured while checking out.');
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
      }
    });
  } catch (err) {
    console.error('Error occured while checking out.', err);

    const logObject: LoggerInput = {
      kind: 'error',
      function: 'createCheckoutSession',
      message: err,
      metaData: {
        priceId,
        spaceId,
        uid
      }
    };
    serverSideLogger(logObject);

    throw new Error('Error occured while checking out.');
  }
};

export const listProductsAndPrices = async () => {
  try {
    const q = query(productsRef, where('active', '==', true));
    const querySnapshot = await getDocs(q);
    const resultsArr: DisplayProduct[] = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        // doc.data() is never undefined for query doc snapshots

        const pricesRef = collection(
          firestore,
          productsRef.path,
          doc.id,
          'prices'
        );

        const priceSnap = await getDocs(pricesRef);

        console.log('------product NAME: ------');

        console.log(doc.id, ' => ', doc.data());

        console.log('------prices: ------');

        priceSnap.docs.forEach((priceDoc, i) => {
          console.log(`${i}.`, priceDoc.id, ' => ', priceDoc.data());
        });

        const displayProduct = {
          productName: doc.data()?.name,
          productId: doc.id,

          // eslint-disable-next-line arrow-body-style
          prices: priceSnap.docs.map((priceDoc) => {
            return {
              amount: priceDoc.data()?.unit_amount,
              interval: priceDoc.data()?.interval,
              intervalCount: priceDoc.data()?.interval_count,
              priceId: priceDoc.id
            };
          })
        };

        return displayProduct;
      })
    );

    console.log('RESULT ARRAY:', resultsArr);

    return resultsArr;
  } catch (err) {
    console.error('Error occured while loading subscriptions', err);
    const logObject: LoggerInput = {
      kind: 'error',
      function: 'listProductsAndPrices',
      message: err,
      metaData: null
    };
    serverSideLogger(logObject);

    throw new Error('Error occured while loading subscriptions');
  }
};

export const redirectToCustomerPortal = async () => {
  try {
    const { data } = await triggerCreatePortalLink({
      returnUrl: window.location.origin,
      locale: 'auto' // Optional, defaults to "auto"
      // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
    });

    interface TriggerCreatePortalLinkResponse {
      [key: string]: string;
    }

    console.log('DATA:', data);

    const dataCasted = <TriggerCreatePortalLinkResponse>data;

    window.location.assign(dataCasted.url);
  } catch (err) {
    console.error('Error occured while redirecting to customer portal.', err);
    const logObject: LoggerInput = {
      kind: 'error',
      function: 'redirectToCustomerPortal',
      message: err,
      metaData: null
    };
    serverSideLogger(logObject);

    throw new Error('Error occured while redirecting to customer portal.');
  }
};
