import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';
import { IconButton, Popover, TextField, useTheme } from '@mui/material';
import { useState } from 'react';
import CustomTooltip from 'src/components/CustomTooltip';

interface IProps {
  playId: string;
  teamId: string;
}

const beforeText = 'Copy URL';
const afterText = 'URL copied!';

const SharePlayButton: React.FC<IProps> = (props: IProps) => {
  const { playId, teamId } = props;
  const [tooltip, setTooltip] = useState<string>(beforeText);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const playURL = `${window.location.hostname}/app/team/${teamId}/plays/${playId}`;
  const theme = useTheme();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleCopyClick = () => {
    setTooltip(afterText);
    navigator.clipboard.writeText(playURL);
  };

  return (
    <>
      <CustomTooltip title="Share play">
        <IconButton
          onClick={handlePopoverOpen}
          sx={{ border: 0, margin: theme.spacing(0.5) }}
        >
          <ShareIcon />
        </IconButton>
      </CustomTooltip>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        sx={{ p: theme.spacing(1) }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        onClose={handlePopoverClose}
      >
        <TextField
          value={playURL}
          autoFocus
          onFocus={(e) => {
            e.target.select();
          }}
          inputProps={{ sx: { padding: theme.spacing(0.75) } }}
          sx={{ margin: theme.spacing(0.75) }}
        />
        <CustomTooltip title={tooltip}>
          <IconButton
            sx={{
              border: 0,
              paddingTop: theme.spacing(0.75),
              paddingBottom: theme.spacing(0.75),
              marginTop: theme.spacing(0.75),
              marginBottom: theme.spacing(0.75),
              marginRight: theme.spacing(0.75)
            }}
            onClick={handleCopyClick}
            onMouseLeave={() => {
              // we use a timeout to prevent the tooltip text switching back to another text before fading out
              setTimeout(() => setTooltip(beforeText), 300);
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        </CustomTooltip>
      </Popover>
    </>
  );
};

export default SharePlayButton;
