import { Theme, Typography, useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { TransitionProps } from '@mui/material/transitions';
import { ReactElement, ReactNode } from 'react';
import hexToRGB from 'src/utils/hexToRGB';

interface IProps {
  title: string;
  subtitle?: string;
  subTitleType?: string;
  enterDelay?: number;
  placement?: any;
  children?: ReactElement<any, any> & ReactNode;
  open?: boolean;
  transitionProps?: TransitionProps;
}

const CustomTooltip: React.FC<IProps> = (props: IProps) => {
  const { title, subtitle, enterDelay, children, placement, transitionProps } =
    props;
  const theme: Theme = useTheme();
  const htmlTitle = (
    <>
      <Typography
        align="center"
        color="primary.main"
        sx={{ color: `${theme.palette.text.primary} !important` }}
      >
        {title}
      </Typography>
      {subtitle && (
        <Typography
          align="center"
          style={{
            color: hexToRGB(theme.palette.warning.main, '1')
          }}
        >
          [{subtitle}]
        </Typography>
      )}
    </>
  );

  const toolTipProps = {
    title: title.length > 0 ? htmlTitle : '',
    enterDelay,
    placement
  };

  return (
    <Tooltip
      leaveDelay={0}
      disableFocusListener
      {...toolTipProps}
      disableInteractive
      TransitionProps={transitionProps}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
