import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { IFeedback } from 'src/types/feedback';

type FeedbackNoId = Omit<IFeedback, 'id'>;

const feedbackConverter = {
  toFirestore: (feedback: IFeedback) => {
    const feedbackNoId: FeedbackNoId = {
      userId: feedback.userId,
      userName: feedback.userName,
      email: feedback.email,
      createdAt: feedback.createdAt,
      content: feedback.content
    };

    return feedbackNoId;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): IFeedback {
    const data = snapshot.data(options);
    const { id } = snapshot;
    return <IFeedback>{
      id,
      userId: data.userId,
      userName: data.userName,
      email: data.email,
      createdAt: data.createdAt,
      content: data.content
    };
  }
};

export { feedbackConverter };
