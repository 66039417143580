import { Container, DialogContent, Grid } from '@mui/material';
import type { FC } from 'react';
import CustomDialogHeader from 'src/components/CustomDialogHeader';
import HelpFAQ from './HelpFAQ';

interface IProps {}

const HelpDialogContent: FC<IProps> = (props: IProps) => (
  <DialogContent sx={{ height: 'auto', maxHeight: '750px' }}>
    <Container
      sx={{
        width: '100%',
        height: '100%'
      }}
    >
      <HelpFAQ />
    </Container>
  </DialogContent>
);

export default HelpDialogContent;
