import { FC } from 'react';
import { shallowEqual } from 'react-redux';
import useObjects from 'src/hooks/useObjects';
import { RootState, useSelector } from 'src/store';
import { IBoardVisibility, IObjects, IPoint } from 'src/types/board';
import DrawingObject from '../elements/DrawingObject';

interface IProps {
  wrapperScale: number;
  wrapperComponent: HTMLDivElement;
  wrapperPoint: IPoint;
}

const ObjectLayer: FC<IProps> = (props: IProps) => {
  const { wrapperScale, wrapperComponent, wrapperPoint }: IProps = props;
  const { visibility } = useSelector(
    (state: RootState) => state.boardSlice.ui,
    shallowEqual
  );

  const { mapRotation } = useSelector(
    (state: RootState) => state.boardSlice.model,
    shallowEqual
  );

  const objects: IObjects = useObjects();
  const { objectsVisible }: IBoardVisibility = visibility;

  return (
    <div
      className="cb-object-layer"
      style={{
        width: '100%',
        height: '100%',
        zIndex: 1
      }}
    >
      {objectsVisible &&
        objects &&
        Object.entries(objects).map(
          ([key, object]) =>
            object && (
              <DrawingObject
                key={key}
                object={object}
                wrapperScale={wrapperScale}
                wrapperComponent={wrapperComponent}
                wrapperPoint={wrapperPoint}
                mapRotation={mapRotation}
              />
            )
        )}
    </div>
  );
};

export default ObjectLayer;
