import { doc, onSnapshot } from 'firebase/firestore';
import serverSideLogger from 'src/logging/serverSideLogger';
import { updatePlaybook } from 'src/slices/playbook';
import store from 'src/store';
import { LoggerInput } from 'src/types/functionsInput';
import { firestore } from '../../../lib/firebase';
import { playbookConverter } from '../../converter/playbookConverter';

/**
 *
 * @param {string} playbookId
 * @returns
 */
export const subscribeToPlaybook = (playbookId, teamId: string | null) => {
  try {
    const ref = doc(firestore, 'playbooks', playbookId).withConverter(
      playbookConverter
    );

    return onSnapshot(
      ref,
      (document) => {
        if (document.data()) {
          store.dispatch(updatePlaybook(document.data(), document.exists()));
        } else {
          if (teamId) {
            window.location.href = `/app/team/${teamId}/playbooks`;
          }

          if (!teamId) {
            window.location.href = '/app/personal/playbooks';
          }
        }
      },
      (error) => {
        console.log(
          'Error occured while trying to subscribe to Playbook ',
          playbookId,
          ':',
          error
        );
      }
    );
  } catch (err) {
    console.error(
      'Error occured while trying to subscribe to Playbook ',
      playbookId,
      ':',
      err
    );

    const logObject: LoggerInput = {
      kind: 'error',
      function: 'subscribeToPlaybook',
      message: err,
      metaData: {
        playbookId,
        teamId
      }
    };
    serverSideLogger(logObject);
    return undefined;
  }
};
