import { addDoc, collection, DocumentReference } from 'firebase/firestore';
import { bugConverter } from 'src/data/converter/bugConverter';
import { trackReportBug } from 'src/data/mixpanel/setters/trackEvent';
import { auth, firestore } from 'src/lib/firebase';
import serverSideLogger from 'src/logging/serverSideLogger';
import { IBug } from 'src/types/bug';
import { LoggerInput } from 'src/types/functionsInput';

const bugsRef = collection(firestore, 'bugs');

export const reportBug = async (
  content: string,
  photoUrls?: string[]
): Promise<DocumentReference<IBug>> => {
  // call this function when we want to save the play

  const bug: IBug = {
    id: null,
    content,
    photoUrls,
    userId: auth.currentUser.uid,
    userName: auth.currentUser.displayName,
    email: auth.currentUser.email,
    createdAt: Date.now()
  };

  try {
    const result = await addDoc(bugsRef.withConverter(bugConverter), bug);

    trackReportBug({
      bug_id: result.id,
      content: bug.content,
      user_id: bug.userId,
      user_display_name: bug.userName,
      email: bug.email
    });

    return result;
  } catch (e) {
    console.error(e);
    const logObject: LoggerInput = {
      kind: 'error',
      function: 'reportBug',
      message: e,
      metaData: {
        content
      }
    };
    serverSideLogger(logObject);
    throw new Error('Creating a bug failed');
  }
};
