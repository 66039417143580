import EditIcon from '@mui/icons-material/Edit';
import { Box, TextField, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import store from 'src/store';
import hexToRGB from 'src/utils/hexToRGB';
import { setTitle as setPlayTitle } from '../../slices/play';
import CustomTooltip from '../CustomTooltip';

interface IProps {
  playTitle: string;
}

const PlayTitleInput: React.FC<IProps> = (props: IProps) => {
  const { playTitle } = props;
  const theme = useTheme();
  const handlePlayNameChange = (event) => {
    store.dispatch(setPlayTitle(event.target.value));
  };

  return (
    <Box sx={{ maxWidth: 300 }}>
      <CustomTooltip title="Change play name">
        <TextField
          autoComplete="off"
          placeholder="Play Title"
          value={playTitle || ''}
          size="small"
          onChange={handlePlayNameChange}
          InputProps={{
            endAdornment: (
              <EditIcon sx={{ fill: theme.palette.text.secondary }} />
            )
          }}
          sx={{
            mt: 0,
            pt: 0,
            width: '100%',
            backgroundColor: hexToRGB(theme.palette.primary.main, '0.015'),
            borderRadius: `${theme.shape.borderRadius}px`
          }}
        />
      </CustomTooltip>

      {/* <Typography
        variant="h4"
        sx={{
          fontStyle: 'italic',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          paddingRight: 2
        }}
      >
        {playTitle}
      </Typography> */}
    </Box>
  );
};

export default PlayTitleInput;
