import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from 'src/store';
import { IObjects } from 'src/types/board';

const useObjects = (): IObjects => {
  const objects: IObjects = useSelector((state: RootState) => {
    const { activeStep } = state.boardSlice.model;

    if (
      state?.boardSlice?.model?.stepLayers &&
      state.boardSlice.model.objects
    ) {
      const objectIds = new Set(
        state.boardSlice.model.stepLayers[activeStep]?.objects
      );
      return Object.fromEntries(
        Object.entries(state.boardSlice.model.objects).filter(([, val]) =>
          objectIds.has(val.id)
        )
      );
    }
    return undefined;
  }, shallowEqual);

  return objects;
};

export default useObjects;
