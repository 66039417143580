import { Divider, Grid, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import React from 'react';
import hexToRGB from 'src/utils/hexToRGB';

interface BreadcrumbData {
  title: string;
  link?: string;
}

interface PageHeaderProps {
  breadcrumbs: BreadcrumbData[];
  headerIcon: ReactNode;
}

const PageHeader: FC<PageHeaderProps> = (props) => {
  const { breadcrumbs, headerIcon } = props;
  const theme = useTheme();
  return (
    <>
      <Grid container>
        {breadcrumbs.map((breadcrumb, i) => (
          <>
            <Typography
              variant="h5"
              sx={{
                pl: i > 0 && 1.25,
                color: `${theme.palette.text.secondary} !important`,
                fontWeight: 600
              }}
            >
              {breadcrumb.title}
            </Typography>

            {i < breadcrumbs.length - 1 && (
              <Typography
                variant="h5"
                sx={{
                  pl: 1.25,
                  color: `${theme.palette.text.secondary} !important`,
                  fontWeight: 600
                }}
              >
                /
              </Typography>
            )}
          </>
        ))}
      </Grid>

      <Grid container sx={{ mt: 1.5, mb: 2 }}>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: 24,
            height: 24,
            backgroundColor: hexToRGB('#ffffff', '0.04'),
            borderRadius: `${theme.shape.borderRadius}px`,
            mr: 1.5
          }}
        >
          {React.cloneElement(headerIcon as React.ReactElement<any>, {
            sx: {
              fontSize: 14,
              fill: theme.palette.secondary.main
            }
          })}
        </Grid>

        <Typography variant="h3" sx={{ pt: '1px' }}>
          {breadcrumbs[breadcrumbs.length - 1].title}
        </Typography>
      </Grid>

      <Divider />
    </>
  );
};

PageHeader.propTypes = {
  breadcrumbs: PropTypes.any,
  headerIcon: PropTypes.node
};

export default PageHeader;
