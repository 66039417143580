import { Maps } from 'src/types/board';
import { PlayInfo } from 'src/types/play';

const playsInfoToThumbnail = (playInfos: PlayInfo[]): Maps[] => {
  const playInfosSorted: Maps[] = playInfos
    .sort((a, b) => b.updatedAt - a.updatedAt)
    .slice(0, 4)
    .map((playInfo) => playInfo.map);

  return playInfosSorted;
};

export default playsInfoToThumbnail;
