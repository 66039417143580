import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Typography, Box, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { FC } from 'react';
import { customOpacityDarkTheme } from 'src/constants';
import hexToRGB from 'src/utils/hexToRGB';

interface CustomDialogHeaderProps {
  title: string;
  onClose: Function;
}

const CustomDialogHeader: FC<CustomDialogHeaderProps> = (
  props: CustomDialogHeaderProps
) => {
  const { title, onClose } = props;
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        sx={{ width: '100%', mb: 0.5 }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          sx={{
            maxWidth: 300,
            [theme.breakpoints.down('xl')]: { maxWidth: 300 }
          }}
        >
          <Typography color="textPrimary" variant="h3">
            {title}
          </Typography>
        </Box>

        <IconButton
          onClick={() => onClose()}
          sx={{
            color: theme.palette.primary.main,
            backgroundColor: hexToRGB(
              theme.palette.primary.main,
              (customOpacityDarkTheme * 2.5).toString()
            ),
            '&:hover': {
              backgroundColor: hexToRGB(
                theme.palette.primary.main,
                (customOpacityDarkTheme * 5).toString()
              )
            }
          }}
        >
          <CloseIcon />
        </IconButton>
      </Grid>

      <Divider sx={{ pt: 1, mb: 3 }} />
    </>
  );
};

export default CustomDialogHeader;
