import { Box, Container, DialogContent, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import CustomDialogHeader from 'src/components/CustomDialogHeader';

interface IProps {
  onClose: Function;
}

const LegalDialogContent = (props: IProps) => {
  const { onClose } = props;

  return (
    <DialogContent sx={{ height: 'auto', maxHeight: '750px' }}>
      <Container
        sx={{
          width: '100%',
          height: '100%'
        }}
      >
        <Box>
          <Grid container spacing={4} sx={{ pb: 2 }}>
            <Grid item xs={12} lg={6}>
              <Typography variant="body2" sx={{ pb: 1 }}>
                Maximilian Hammer
              </Typography>

              <Typography variant="body2" color="text.secondary">
                Ebertplatz 7
              </Typography>
              <Typography variant="body2" color="text.secondary">
                50668 Cologne, Germany
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Typography variant="body2" sx={{ pb: 1 }}>
                Alexander Spindeler
              </Typography>

              <Typography variant="body2" color="text.secondary">
                Ebertplatz 7
              </Typography>
              <Typography variant="body2" color="text.secondary">
                50668 Cologne, Germany
              </Typography>
            </Grid>
          </Grid>
          <Typography sx={{ pt: 1 }} variant="body2" color="text.secondary">
            dev@critbase.com
          </Typography>
        </Box>
      </Container>
    </DialogContent>
  );
};

export default LegalDialogContent;
