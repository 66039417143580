import { combineReducers } from '@reduxjs/toolkit';
import { reducer as accountdataReducer } from 'src/slices/accountdata';
import { reducer as announcementReducer } from 'src/slices/announcement';
import { reducer as boardReducer } from 'src/slices/board/board';
import { reducer as playbooksReducer } from 'src/slices/playbook';
import { reducer as teamsReducer } from 'src/slices/team';
import { reducer as sessionInfosReducer } from 'src/slices/sessionInfos';
import { reducer as playsReducer } from 'src/slices/play';

const rootReducer = combineReducers({
  announcementSlice: announcementReducer,
  playbookSlice: playbooksReducer,
  accountDataSlice: accountdataReducer,
  teamSlice: teamsReducer,
  boardSlice: boardReducer,
  sessionInfosSlice: sessionInfosReducer,
  playSlice: playsReducer
});

export default rootReducer;
