import {
  DatabaseReference,
  get,
  ref,
  remove,
  runTransaction,
  set
} from 'firebase/database';
import {
  arrayUnion,
  doc,
  runTransaction as runTransactionFirestore
} from 'firebase/firestore';
import {
  HttpsCallable,
  httpsCallable,
  HttpsCallableResult
} from 'firebase/functions';
import { teamConverter } from 'src/data/converter/team/teamConverter';
import { trackJoinSession } from 'src/data/mixpanel/setters/trackEvent';
import { logger } from 'src/logging/clientSideLogger';
import serverSideLogger from 'src/logging/serverSideLogger';
import {
  addConnections,
  addConnectionsToStepLayer,
  cloneStepLayer,
  createConnection,
  createObject,
  filterObject,
  removeConnections,
  removeConnectionsFromObjects,
  removeConnectionsFromStepLayer,
  removeObject as removeObjectUtil,
  updateConnections
} from 'src/slices/board/utils';
import {
  IAddRemoveConnection,
  IAddRemoveValue,
  IBoard,
  IConnection,
  IDrawingLine,
  INode,
  IObject,
  IStepLayer,
  IStepLayers,
  Maps
} from 'src/types/board';
import { CreateSessionInput, LoggerInput } from 'src/types/functionsInput';
import { Play } from 'src/types/play';
import {
  Session,
  SessionInfo,
  SessionUser,
  SessionUsers
} from 'src/types/session';
import { Team, TeamMemberInfo } from 'src/types/team';
import rotateMapBy from 'src/utils/boardUtils/rotateMapBy';
import exists from 'src/utils/exists';
import { generatePlayId } from 'src/utils/generateFirestoreKey';
import generateMPEventGeneralPropsClientSide from 'src/utils/mixpanelUtils/generateMPEventGeneralPropsClientSide';
import SnackbarUtils from 'src/utils/snackbar/SnackbarUtils';
import { auth, firestore, functions, realTimeDB } from '../../../lib/firebase';
import { updateStepLayerIds } from '../helper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const triggerCreateSession: HttpsCallable<any, string> = httpsCallable(
  functions,
  'createSession'
);

export const sessionExists = async (id: string): Promise<boolean> => {
  const sessionRef: DatabaseReference = ref(realTimeDB, `sessions/${id}`);
  try {
    const snapshot = await get(sessionRef);
    return snapshot.exists();
  } catch (err) {
    return false;
  }
};

/**
 *
 * @param teamId
 */
export const createSession = async (
  teamId: string,
  play?: Play
): Promise<string> => {
  try {
    return await runTransactionFirestore(firestore, async (trx) => {
      const teamDocRef = doc(firestore, `teams/${teamId}`).withConverter(
        teamConverter
      );

      const teamDoc = await trx.get(teamDocRef);
      const team: Team = teamDoc.data();
      if (team.id) {
        const { currentUser } = auth;

        const timestamp: number = Date.now();
        const newBoard: IBoard = {
          stepLayers: {
            0: {
              id: 0,
              objects: [],
              lines: [],
              connections: [],
              commandsHistory: [],
              commandsFuture: []
            } as IStepLayer
          } as IStepLayers,
          lines: {},
          objects: {},
          connections: {},
          map: Maps.BIND,
          mapRotation: 0,
          commands: {},
          activeStep: 0 // Split as initial map is randomly chosen, maybe discuss what map makes most senseial map is randomly chosen, maybe discuss what map makes most sense
        };

        let playDoc;
        if (!play) {
          const newPlay: Play = {
            id: generatePlayId(),
            photoURL: null,
            title: `Untitled Play`, // TODO: add option to change title,
            map: newBoard.map,
            updatedAt: timestamp,
            createdAt: timestamp,
            board: newBoard,
            referencePlaybookIDs: [],
            whitelistedIDs: team.memberIDs
          };
          playDoc = newPlay;
        } else {
          playDoc = play;
        }

        const userArray: TeamMemberInfo[] = team.membersInfo;
        const sessionUsers = {};
        userArray.forEach(
          // eslint-disable-next-line no-return-assign
          (value: SessionUser) =>
            (sessionUsers[value.id] = {
              joinedAt: null,
              photoURL: value.photoURL || ' ',
              ...value
            })
        );

        sessionUsers[currentUser.uid] = {
          active: true,
          joinedAt: timestamp,
          photoURL: currentUser.photoURL || ' ',
          ...sessionUsers[currentUser.uid]
        };

        const initialSession: CreateSessionInput = {
          mpEventGeneralPropsClient: generateMPEventGeneralPropsClientSide(),
          info: {
            teamId: team.id,
            playId: playDoc.id || null,
            playTitle: playDoc.title,
            createdBy: currentUser.uid,
            createdAt: timestamp,
            updatedAt: timestamp,
            users: sessionUsers
          } as SessionInfo,
          play: playDoc
        };
        const { data: sessionKey }: HttpsCallableResult<string> =
          await triggerCreateSession(initialSession);

        // update team & play doc with sessionRef

        trx.update(teamDocRef, { sessionRefs: arrayUnion(sessionKey) });

        console.log(
          `Setter "createSession" terminated. Created session ${sessionKey} by user ${
            initialSession.info.createdBy
          } at ${new Date(initialSession.info.createdAt).toUTCString()}`
        );

        // snackbar

        return sessionKey;
      }
      return undefined;
    });
  } catch (err) {
    // snackbar
    SnackbarUtils.error(
      'Something went wrong creating this session. Please try again.'
    );
    console.error('Error occured while trying to create a Session:', err);

    const logObject: LoggerInput = {
      kind: 'error',
      function: 'createSession',
      message: err,
      metaData: {
        teamId,
        play
      }
    };

    serverSideLogger(logObject);
    return undefined;
  }
};

/**
 *
 * @param sessionId
 */
export const deleteSession = async (sessionId: string): Promise<boolean> => {
  if (sessionId) {
    const sessionRef: DatabaseReference = ref(
      realTimeDB,
      `sessions/${sessionId}`
    );

    try {
      await remove(sessionRef);

      console.log(
        `Setter "deleteSession" terminated. Deleted session ${sessionId}.`
      );
    } catch (err) {
      console.log(
        `Error occured while trying to delete a session ${sessionId}:`,
        err
      );

      const logObject: LoggerInput = {
        kind: 'error',
        function: 'deleteSession',
        message: err,
        metaData: {
          sessionId
        }
      };
      serverSideLogger(logObject);
    }

    return true;
  }
  return false;
};

export const updatePlayInSession = async (
  sessionId: string,
  play: Play
): Promise<boolean> => {
  if (sessionId) {
    try {
      const sessionRef: DatabaseReference = ref(
        realTimeDB,
        `sessions/${sessionId}/`
      );

      await runTransaction(sessionRef, (session: Session) => {
        if (session) {
          const timestamp: number = Date.now();
          // timestamp
          session.info.updatedAt = timestamp;

          // do not overwrite active board
          session.play = { ...play, board: session.play.board };
          session.info.playId = play.id;
          session.info.playTitle = play.title;

          console.log(`Session ${sessionId} Play Id added`);
        }

        return session;
      });
    } catch (err) {
      // snackbar
      SnackbarUtils.error(
        'Something went wrong updating the play in your session'
      );
      console.log(
        `Something went wrong updating the play in your session with id: ${sessionId}. Error: ${err}`
      );

      const logObject: LoggerInput = {
        kind: 'error',
        function: 'updatePlayInSession',
        message: err,
        metaData: {
          sessionId,
          play
        }
      };
      serverSideLogger(logObject);
      return undefined;
    }
  }
  return undefined;
};

/**
 *
 * @param sessionId
 */
export const joinSession = async (sessionId: string): Promise<Session> => {
  if (sessionId) {
    try {
      const userActiveRef: DatabaseReference = ref(
        realTimeDB,
        `sessions/${sessionId}/info/users/${auth.currentUser.uid}/active`
      );
      const updatedAtRef: DatabaseReference = ref(
        realTimeDB,
        `sessions/${sessionId}/info/updatedAt`
      );

      await set(userActiveRef, true);
      await set(updatedAtRef, Date.now());
    } catch (err) {
      // snackbar
      SnackbarUtils.error('Something went wrong joining this play.');
      console.log(
        `Someting went wrong when joining session with id: ${sessionId}. Error: ${err}`
      );
      const logObject: LoggerInput = {
        kind: 'error',
        function: 'joinSession',
        message: err,
        metaData: {
          sessionId
        }
      };
      serverSideLogger(logObject);
      return undefined;
    }
  }
  return undefined;
};
export const leaveSession = async (
  sessionId: string,
  currentUserId: string
): Promise<Session> => {
  if (sessionId) {
    try {
      const userActiveRef: DatabaseReference = ref(
        realTimeDB,
        `sessions/${sessionId}/info/users/${currentUserId}/active`
      );
      const updatedAtRef: DatabaseReference = ref(
        realTimeDB,
        `sessions/${sessionId}/info/updatedAt`
      );

      await set(userActiveRef, null);
      await set(updatedAtRef, Date.now());
    } catch (err) {
      console.error(
        `Something went wrong when removing a user from session with id: ${sessionId}. Error: ${err}`
      );
      const logObject: LoggerInput = {
        kind: 'error',
        function: 'leaveSession',
        message: err,
        metaData: {
          sessionId,
          currentUserId
        }
      };
      serverSideLogger(logObject);
      return undefined;
    }
  }
  return undefined;
};

/**
 *
 * @param line
 * @param playId
 * @returns
 */
export const addLine = async (
  line: IDrawingLine,
  sessionId: string,
  activeStep: number
): Promise<boolean> => {
  const { currentUser } = auth;

  try {
    if (sessionId && exists(activeStep) && line) {
      const sessionRef: DatabaseReference = ref(
        realTimeDB,
        `sessions/${sessionId}/`
      );

      await runTransaction(sessionRef, (session: Session) => {
        if (session) {
          if (session.play.board.stepLayers[activeStep]) {
            const timestamp: number = Date.now();
            // timestamp
            session.info.updatedAt = timestamp;

            // add line
            session.play.board.lines = {
              ...session.play.board.lines,
              [line.id]: line
            };

            // add line ref
            session.play.board.stepLayers[activeStep] = updateStepLayerIds(
              session.play.board.stepLayers[activeStep],
              line.id,
              'lines'
            );
          }
        }
        return session;
      });
      console.log(
        `Setter "addLine" terminated. Added line with id: ${line.id} created by ${currentUser.displayName}`
      );
    }
    return true;
  } catch (err) {
    // snackbar
    SnackbarUtils.error(
      'Something went wrong adding a line. Try reloading the page.'
    );
    console.log('Error occured while trying to add a line:', err);

    const logObject: LoggerInput = {
      kind: 'error',
      function: 'addLine',
      message: err,
      metaData: {
        sessionId,
        line,
        activeStep
      }
    };
    serverSideLogger(logObject);
    return false;
  }
};

export const removeLine = async (
  line: IDrawingLine,
  sessionId: string,
  activeStep: number
): Promise<void> => {
  try {
    const sessionRef: DatabaseReference = ref(
      realTimeDB,
      `sessions/${sessionId}/`
    );
    await runTransaction(sessionRef, (session: Session) => {
      if (session) {
        if (session.play.board.stepLayers[activeStep]) {
          const timestamp: number = Date.now();
          // timestamp
          session.info.updatedAt = timestamp;

          // remove line
          session.play.board.lines[line.id] = null;

          // remove line ref
          session.play.board.stepLayers[activeStep].lines =
            session.play.board.stepLayers[activeStep].lines.filter(
              (string) => string !== line.id
            );
        }
      }
      return session;
    });
  } catch (err) {
    SnackbarUtils.error(
      'Something went wrong removing a line. Try reloading the page.'
    );

    console.log('Error occured while trying to remove a line:', err);
    const logObject: LoggerInput = {
      kind: 'error',
      function: 'removeLine',
      message: err,
      metaData: {
        sessionId,
        line,
        activeStep
      }
    };
    serverSideLogger(logObject);
  }
};

export const addObject = async (
  value: IAddRemoveValue,
  sessionId: string,
  activeStep: number
): Promise<void> => {
  const { object, connections: newConnections } = value;
  try {
    if (sessionId && exists(activeStep) && object) {
      const sessionRef: DatabaseReference = ref(
        realTimeDB,
        `sessions/${sessionId}/`
      );
      await runTransaction(sessionRef, (session: Session) => {
        if (session) {
          if (session.play.board.stepLayers[activeStep]) {
            const timestamp: number = Date.now();
            session.info.updatedAt = timestamp;

            // create object

            session.play.board.objects = createObject(
              object,
              session.play.board.objects
            );

            // update object ref in layer
            session.play.board.stepLayers[activeStep] = updateStepLayerIds(
              session.play.board.stepLayers[activeStep],
              object.id,
              'objects'
            );

            // add connection
            if (newConnections) {
              // add connection references to objects
              const result = addConnections(
                session.play.board.connections,
                session.play.board.objects,
                newConnections
              );
              session.play.board.connections = result.connections;
              session.play.board.objects = result.objects;

              // add connection references to stepLayer
              session.play.board.stepLayers[activeStep].connections =
                addConnectionsToStepLayer(
                  session.play.board.stepLayers[activeStep].connections,
                  newConnections
                );
            }
          }
        }
        return session;
      });
    }
  } catch (err) {
    SnackbarUtils.error(
      'Something went wrong adding an object. Try reloading the page.'
    );

    console.log(`Error occured while trying to update a ${object.value}:`, err);
    const logObject: LoggerInput = {
      kind: 'error',
      function: 'addObject',
      message: err,
      metaData: {
        sessionId,
        object,
        activeStep
      }
    };
    serverSideLogger(logObject);
  }
};

export const addConnection = async (
  value: IAddRemoveConnection,
  sessionId: string,
  activeStep: number
): Promise<void> => {
  const { connection } = value;
  try {
    if (sessionId && exists(activeStep) && connection) {
      const sessionRef: DatabaseReference = ref(
        realTimeDB,
        `sessions/${sessionId}/`
      );
      await runTransaction(sessionRef, (session: Session) => {
        if (session) {
          if (session.play.board.stepLayers[activeStep]) {
            const timestamp: number = Date.now();
            session.info.updatedAt = timestamp;

            // create object

            session.play.board.connections = createConnection(
              session.play.board.connections,
              connection
            );

            // update object ref in layer
            session.play.board.stepLayers[activeStep] = updateStepLayerIds(
              session.play.board.stepLayers[activeStep],
              connection.id,
              'connections'
            );
          }
        }
        return session;
      });
    }
  } catch (err) {
    SnackbarUtils.error(
      'Something went wrong adding a connection. Try reloading the page.'
    );

    console.log(`Error occured while trying to update a connection:`, err);

    const logObject: LoggerInput = {
      kind: 'error',
      function: 'addConnection',
      message: err,
      metaData: {
        sessionId,
        connection,
        activeStep
      }
    };
    serverSideLogger(logObject);
  }
};

export const removeConnection = async (
  value: IAddRemoveConnection,
  sessionId: string,
  activeStep: number
): Promise<void> => {
  const { currentUser } = auth;
  const { connection } = value;
  try {
    if (sessionId && exists(activeStep) && connection) {
      const sessionRef: DatabaseReference = ref(
        realTimeDB,
        `sessions/${sessionId}/`
      );
      await runTransaction(sessionRef, (session: Session) => {
        if (session) {
          if (session.play.board.stepLayers[activeStep]) {
            const timestamp: number = Date.now();
            session.info.updatedAt = timestamp;

            // remove object
            session.play.board.connections = removeConnections(
              session.play.board.connections,
              [connection.id]
            );

            // remove connection references
            session.play.board.stepLayers[activeStep].connections =
              removeConnectionsFromStepLayer(
                session.play.board.stepLayers[activeStep].connections,
                [connection.id]
              );
          }
          console.log(
            `Setter "removeConnection" terminated. Removed connection with id: ${connection.id} executed by ${currentUser.displayName}`
          );
        }
        return session;
      });
    }
  } catch (err) {
    SnackbarUtils.error(
      'Something went wrong removing an connection. Try reloading the page.'
    );
    console.log(`Error occured while trying to remove a connection:`, err);

    const logObject: LoggerInput = {
      kind: 'error',
      function: 'removeConnection',
      message: err,
      metaData: {
        sessionId,
        connection,
        activeStep
      }
    };
    serverSideLogger(logObject);
  }
};

export const updateConnection = async (
  connection: IConnection,
  sessionId: string,
  activeStep: number
): Promise<void> => {
  try {
    if (sessionId && exists(activeStep) && connection) {
      const sessionRef: DatabaseReference = ref(
        realTimeDB,
        `sessions/${sessionId}/`
      );
      await runTransaction(sessionRef, (session: Session) => {
        if (session) {
          if (session.play.board.stepLayers[activeStep]) {
            const timestamp: number = Date.now();
            session.info.updatedAt = timestamp;

            // update object
            session.play.board.connections = {
              ...session.play.board.connections,
              [connection.id]: connection
            };
          }
        }
        return session;
      });
    }
  } catch (err) {
    SnackbarUtils.error(
      'Something went wrong updating an connection. Try reloading the page.'
    );
    console.log(`Error occured while trying to update a connection:`, err);

    const logObject: LoggerInput = {
      kind: 'error',
      function: 'updateConnection',
      message: err,
      metaData: {
        sessionId,
        connection,
        activeStep
      }
    };
    serverSideLogger(logObject);
  }
};

export const removeObject = async (
  value: IAddRemoveValue,
  sessionId: string,
  activeStep: number
): Promise<void> => {
  const { currentUser } = auth;
  const { object } = value;
  try {
    if (sessionId && exists(activeStep) && object) {
      const sessionRef: DatabaseReference = ref(
        realTimeDB,
        `sessions/${sessionId}/`
      );
      await runTransaction(sessionRef, (session: Session) => {
        if (session) {
          if (session.play.board.stepLayers[activeStep]) {
            const timestamp: number = Date.now();
            session.info.updatedAt = timestamp;
            if (session.play.board.objects[object.id]?.connections) {
              const { connections: connectionIds } =
                session.play.board.objects[object.id];

              // remove connections
              session.play.board.connections = removeConnections(
                session.play.board.connections,
                connectionIds
              );

              // remove connection references
              session.play.board.stepLayers[activeStep].connections =
                removeConnectionsFromStepLayer(
                  session.play.board.stepLayers[activeStep].connections,
                  connectionIds
                );
              session.play.board.objects = removeConnectionsFromObjects(
                session.play.board.objects,
                connectionIds
              );
            }
            // remove object
            session.play.board.objects = removeObjectUtil(
              object.id,
              session.play.board.objects
            );
          }
          console.log(
            `Setter "removeObject" terminated. Removed object with id: ${object.id} executed by ${currentUser.displayName}`
          );
        }
        return session;
      });
    }
  } catch (err) {
    SnackbarUtils.error(
      'Something went wrong removing an object. Try reloading the page.'
    );
    console.log(`Error occured while trying to remove a ${object.value}:`, err);

    const logObject: LoggerInput = {
      kind: 'error',
      function: 'removeObject',
      message: err,
      metaData: {
        sessionId,
        object,
        activeStep
      }
    };
    serverSideLogger(logObject);
  }
};

export const updateObject = async (
  obj: IObject,
  sessionId: string,
  activeStep: number
): Promise<void> => {
  try {
    if (sessionId && exists(activeStep) && obj) {
      const sessionRef: DatabaseReference = ref(
        realTimeDB,
        `sessions/${sessionId}/`
      );
      await runTransaction(sessionRef, (session: Session) => {
        if (session) {
          if (session.play.board.stepLayers[activeStep]) {
            const timestamp: number = Date.now();
            session.info.updatedAt = timestamp;

            // update object
            session.play.board.objects = {
              ...session.play.board.objects,
              [obj.id]: obj
            };

            if (session.play.board.objects[obj.id]?.connections) {
              const { connections: connectionIds } =
                session.play.board.objects[obj.id];
              const node: INode = {
                id: obj.id,
                position: obj.position
              };
              // update connection if connection exists
              session.play.board.connections = updateConnections(
                node,
                session.play.board.connections,
                connectionIds
              );
            }
          }
        }
        return session;
      });
    }
  } catch (err) {
    SnackbarUtils.error(
      'Something went wrong updating an object. Try reloading the page.'
    );
    console.log(`Error occured while trying to update a ${obj.value}:`, err);

    const logObject: LoggerInput = {
      kind: 'error',
      function: 'updateObject',
      message: err,
      metaData: {
        sessionId,
        obj,
        activeStep
      }
    };
    serverSideLogger(logObject);
  }
};

export const changeMap = async (
  map: Maps,
  sessionId: string
): Promise<DatabaseReference> => {
  const { currentUser } = auth;
  if (sessionId && map) {
    const mapRef: DatabaseReference = ref(
      realTimeDB,
      `sessions/${sessionId}/play/board/map`
    );

    try {
      const sessionRef: DatabaseReference = ref(
        realTimeDB,
        `sessions/${sessionId}/`
      );
      await runTransaction(sessionRef, (session: Session) => {
        if (session) {
          // time stamp
          const timestamp: number = Date.now();
          session.info.updatedAt = timestamp;
          // change map
          session.play.board.map = map;
        }
        return session;
      });

      console.log(
        `Setter "changeMap" terminated. Changed map to: ${map} created by ${currentUser.displayName}`
      );
    } catch (err) {
      SnackbarUtils.error(
        'Something went wrong while changing the map. Try reloading the page.'
      );
      console.log('Error occured while trying to change map:', err);

      const logObject: LoggerInput = {
        kind: 'error',
        function: 'changeMap',
        message: err,
        metaData: {
          sessionId,
          map
        }
      };
      serverSideLogger(logObject);
    }

    return mapRef;
  }
  return undefined;
};

export const rotateMap = async (
  sessionId: string
): Promise<DatabaseReference> => {
  const { currentUser } = auth;
  if (sessionId) {
    try {
      const sessionRef: DatabaseReference = ref(
        realTimeDB,
        `sessions/${sessionId}/`
      );
      await runTransaction(sessionRef, (session: Session) => {
        if (session) {
          // time stamp
          const timestamp: number = Date.now();
          session.info.updatedAt = timestamp;
          // flip map
          session.play.board.mapRotation = rotateMapBy(
            session.play.board.mapRotation,
            90
          );
        }
        return session;
      });

      console.log(
        `Setter "rotateMap" terminated. Flipped map by ${currentUser.displayName}`
      );
    } catch (err) {
      SnackbarUtils.error(
        'Something went wrong while flipping the map. Try reloading the page.'
      );
      console.log('Error occured while trying to flip map:', err);

      const logObject: LoggerInput = {
        kind: 'error',
        function: 'rotateMap',
        message: err,
        metaData: {
          sessionId
        }
      };
      serverSideLogger(logObject);
    }
  }
  return undefined;
};

export const addStepLayer = async (
  sessionId: string,
  newStepId: number
): Promise<DatabaseReference> => {
  if (sessionId) {
    const layersRef: DatabaseReference = ref(
      realTimeDB,
      `sessions/${sessionId}/play/board/stepLayers`
    );
    const sessionRef: DatabaseReference = ref(
      realTimeDB,
      `sessions/${sessionId}/`
    );
    try {
      await runTransaction(sessionRef, (session: Session) => {
        if (session) {
          // time stamp
          const timestamp: number = Date.now();
          session.info.updatedAt = timestamp;
          // get latest layer
          const latestLayerIndex =
            Object.keys(session.play.board.stepLayers).length - 1;

          session.play.board = cloneStepLayer(
            session.play.board,
            latestLayerIndex,
            newStepId
          );
          // duplicate layer
        }
        return session;
      });

      console.log(`Setter "addStepLayer" terminated.`);
    } catch (err) {
      SnackbarUtils.error(
        'Something went wrong adding a step. Try reloading the page.'
      );
      console.log('Error occured while trying to add a new layer:', err);

      const logObject: LoggerInput = {
        kind: 'error',
        function: 'addStepLayer',
        message: err,
        metaData: {
          sessionId,
          newStepId
        }
      };
      serverSideLogger(logObject);
    }

    return layersRef;
  }
  return undefined;
};

export const removeStepLayer = async (
  sessionId: string,
  stepLayer: number
): Promise<void> => {
  if (sessionId) {
    try {
      const sessionRef: DatabaseReference = ref(
        realTimeDB,
        `sessions/${sessionId}/`
      );
      await runTransaction(sessionRef, (session: Session) => {
        if (session) {
          // time stamp
          const timestamp: number = Date.now();
          session.info.updatedAt = timestamp;
          // remove elements
          session.play.board.lines = filterObject(
            session.play.board.lines,
            session.play.board.stepLayers[stepLayer].lines,
            true
          );
          session.play.board.objects = filterObject(
            session.play.board.objects,
            session.play.board.stepLayers[stepLayer].objects,
            true
          );
          session.play.board.connections = filterObject(
            session.play.board.connections,
            session.play.board.stepLayers[stepLayer].connections,
            true
          );

          // remove stepLayer
          session.play.board.stepLayers[stepLayer] = null;
        }
        return session;
      });
    } catch (err) {
      SnackbarUtils.error(
        'Something went wrong removing a step. Try reloading the page.'
      );
      console.log('Error occured while trying to remove a layer:', err);

      const logObject: LoggerInput = {
        kind: 'error',
        function: 'removeStepLayer',
        message: err,
        metaData: {
          sessionId,
          stepLayer
        }
      };
      serverSideLogger(logObject);
    }
  }
  return undefined;
};

export const clearBoard = async (
  sessionId: string
): Promise<DatabaseReference> => {
  const { currentUser } = auth;
  if (sessionId) {
    try {
      const sessionRef: DatabaseReference = ref(
        realTimeDB,
        `sessions/${sessionId}/`
      );
      await runTransaction(sessionRef, (session: Session) => {
        if (session) {
          // time stamp
          const timestamp: number = Date.now();
          session.info.updatedAt = timestamp;

          const newBoard: IBoard = {
            stepLayers: {
              0: {
                id: 0,
                objects: [],
                lines: [],
                connections: [],
                commandsHistory: [],
                commandsFuture: []
              } as IStepLayer
            } as IStepLayers,
            lines: {},
            objects: {},
            connections: {},
            map: Maps.BREEZE,
            mapRotation: 0,
            commands: {},
            activeStep: 0 // Split as initial map is randomly chosen, maybe discuss what map makes most senseial map is randomly chosen, maybe discuss what map makes most sense
          };
          // reset board
          session.play.board = newBoard;
        }
        return session;
      });

      console.log(
        `Setter "clearBoard" terminated. created by ${currentUser.displayName}`
      );
    } catch (err) {
      SnackbarUtils.error(
        'Something went clearing the board. Try reloading the page.'
      );
      console.log('Error occured while trying to clear board:', err);

      const logObject: LoggerInput = {
        kind: 'error',
        function: 'clearBoard',
        message: err,
        metaData: {
          sessionId
        }
      };
      serverSideLogger(logObject);
    }
  }
  return undefined;
};
