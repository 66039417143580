import {
  AppBar,
  Box,
  Button,
  Container,
  Link,
  Toolbar,
  useMediaQuery,
  useTheme
} from '@mui/material';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { appTopbarHeight } from 'src/constants';
import Logo from '../icons/Logo';

const MainNavbar: FC = () => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <AppBar
        elevation={0}
        sx={{
          color: 'text.secondary',
          backgroundColor: 'transparent'
        }}
        position="relative"
      >
        <Container maxWidth="md" sx={{ height: '100%' }}>
          <Toolbar
            sx={{
              height: appTopbarHeight,
              maxHeight: `${appTopbarHeight} !important`,
              minHeight: `${appTopbarHeight} !important`
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: '100% ',
                  width: smDown ? 65 : 85
                }}
              />
            </RouterLink>

            {!smDown && (
              <>
                <Link
                  variant="body1"
                  href="/pricing-team"
                  color="textSecondary"
                  sx={{ ml: 4, pt: 0.3 }}
                >
                  Team Pricing
                </Link>

                <Link
                  variant="body1"
                  href="/pricing-personal"
                  color="textSecondary"
                  sx={{ ml: 4, pt: 0.3 }}
                >
                  Solo Pricing
                </Link>

                <Link
                  variant="body1"
                  href="/announcements"
                  color="textSecondary"
                  sx={{ ml: 4, pt: 0.3 }}
                >
                  Announcements
                </Link>
              </>
            )}

            <Box sx={{ flexGrow: 1 }} />

            <Button
              sx={{ px: smDown ? 1 : 3, mr: 1 }}
              component={RouterLink}
              to="/authentication/login"
              variant="outlined"
            >
              Sign in
            </Button>

            <Button
              sx={{ px: smDown ? 1 : 3 }}
              component={RouterLink}
              to="/authentication/register"
              variant="contained"
            >
              Sign up
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
      {smDown && (
        <Box px={{ sm: 3, xs: 2 }}>
          <Link
            variant="body1"
            href="/pricing-team"
            color="textSecondary"
            sx={{ ml: 4, pt: 0.3 }}
          >
            Team Pricing
          </Link>

          <Link
            variant="body1"
            href="/pricing-personal"
            color="textSecondary"
            sx={{ ml: 4, pt: 0.3 }}
          >
            Solo Pricing
          </Link>

          <Link
            variant="body1"
            href="/announcements"
            color="textSecondary"
            sx={{ ml: 4, pt: 0.3 }}
          >
            Announcements
          </Link>
        </Box>
      )}{' '}
    </>
  );
};

export default MainNavbar;
