import {
  ControllerFunctions,
  IAddRemoveValue,
  ICommand,
  ICommandParams,
  IMoveValue,
  INode,
  IBoard,
  IAddRemoveConnection,
  IMoveConnectionValue
} from 'src/types/board';
import {
  addConnections,
  addConnectionsToStepLayer,
  createConnection,
  createObject,
  removeConnections,
  removeConnectionsFromObjects,
  removeConnectionsFromStepLayer,
  removeObject,
  updateConnections
} from './utils';

const CommandController = () => {
  const commands: ControllerFunctions = {
    DRAW(prevState: IBoard, params: ICommandParams): IBoard {
      const { value } = params;
      const { stepLayers, activeStep } = prevState;

      if (stepLayers[activeStep]) {
        if (!stepLayers[activeStep].lines) {
          stepLayers[activeStep].lines = []; // init lines when its still null
        }

        prevState.lines = { ...prevState.lines, [value.id]: value };
        stepLayers[activeStep].lines = [
          ...stepLayers[activeStep].lines,
          value.id
        ];
      }
      return {
        ...prevState
      };
    },
    ERASE(prevState: IBoard, params: ICommandParams): IBoard {
      const { value } = params;
      const { stepLayers, activeStep } = prevState;

      if (stepLayers[activeStep]) {
        // remove reference from stepLayer
        stepLayers[activeStep].lines = stepLayers[activeStep].lines.filter(
          (string) => string !== value.id
        );
        // remove actual line
        const { [value.id]: foo, ...otherLines } = prevState.lines;
        prevState.lines = otherLines;
      }
      return {
        ...prevState
      };
    },
    ADD_OBJECT(prevState: IBoard, params: ICommandParams): IBoard {
      const { value } = params;
      const { object, connections: newConnections }: IAddRemoveValue = value;
      const { stepLayers, activeStep } = prevState;
      if (stepLayers[activeStep]) {
        // add object
        prevState.objects = createObject(object, prevState.objects);
        // add object to stepLayer
        stepLayers[activeStep].objects = [
          ...stepLayers[activeStep].objects,
          object.id
        ];

        // add connection
        if (newConnections) {
          // add connection references to objects
          const result = addConnections(
            prevState.connections,
            prevState.objects,
            newConnections
          );
          prevState.connections = result.connections;
          prevState.objects = result.objects;

          // add connection references to stepLayer
          stepLayers[activeStep].connections = addConnectionsToStepLayer(
            stepLayers[activeStep].connections,
            newConnections
          );
        }
      }

      return {
        ...prevState
      };
    },
    REMOVE_OBJECT(prevState: IBoard, params: ICommandParams): IBoard {
      const { value } = params;
      const { object } = value;
      const { stepLayers, activeStep } = prevState;
      if (stepLayers[activeStep]) {
        if (prevState.objects[object.id]?.connections) {
          // delete connection if connection exists
          const { connections: connectionIds } = prevState.objects[object.id];

          // remove connections
          prevState.connections = removeConnections(
            prevState.connections,
            connectionIds
          );

          // remove connection references
          prevState.stepLayers[activeStep].connections =
            removeConnectionsFromStepLayer(
              prevState.stepLayers[activeStep].connections,
              connectionIds
            );
          prevState.objects = removeConnectionsFromObjects(
            prevState.objects,
            connectionIds
          );
        }
        // remove object
        prevState.objects = removeObject(object.id, prevState.objects);
      }
      return {
        ...prevState
      };
    },
    CHANGE_OBJECT(prevState: IBoard, params: ICommandParams): IBoard {
      const { value } = params;
      const { newObj }: IMoveValue = value;
      const { stepLayers, activeStep } = prevState;
      // CHANGE object
      if (stepLayers[activeStep]) {
        prevState.objects[newObj.id] = newObj;

        if (prevState.objects[newObj.id]?.connections) {
          const { connections: connectionIds } = prevState.objects[newObj.id];
          const node: INode = {
            id: newObj.id,
            position: newObj.position
          };
          // update connection if connection exists
          prevState.connections = updateConnections(
            node,
            prevState.connections,
            connectionIds
          );
        }
      }

      return {
        ...prevState
      };
    },
    REVERT_OBJECT(prevState: IBoard, params: ICommandParams): IBoard {
      const { value } = params;
      const { oldObj }: IMoveValue = value;
      const { stepLayers, activeStep } = prevState;
      if (stepLayers[activeStep]) {
        prevState.objects[oldObj.id] = oldObj;

        if (prevState.objects[oldObj.id]?.connections) {
          const { connections: connectionIds } = prevState.objects[oldObj.id];
          const node: INode = {
            id: oldObj.id,
            position: oldObj.position
          };
          // update connection if connection exists
          prevState.connections = updateConnections(
            node,
            prevState.connections,
            connectionIds
          );
        }
      }
      return {
        ...prevState
      };
    },

    ADD_CONNECTION(prevState: IBoard, params: ICommandParams): IBoard {
      const { value } = params;
      const { connection }: IAddRemoveConnection = value;
      const { stepLayers, activeStep } = prevState;
      // add connection
      if (stepLayers[activeStep]) {
        prevState.connections = createConnection(
          prevState.connections,
          connection
        );

        // add connection references to stepLayer
        stepLayers[activeStep].connections = addConnectionsToStepLayer(
          stepLayers[activeStep].connections,
          { [connection.id]: connection }
        );
      }

      return {
        ...prevState
      };
    },
    REMOVE_CONNECTION(prevState: IBoard, params: ICommandParams): IBoard {
      const { value } = params;
      const { connection }: IAddRemoveConnection = value;
      console.log(connection);

      const { stepLayers, activeStep } = prevState;
      if (stepLayers[activeStep]) {
        // delete connection if connection exists

        // remove connections
        prevState.connections = removeConnections(prevState.connections, [
          connection.id
        ]);

        // remove connection references
        prevState.stepLayers[activeStep].connections =
          removeConnectionsFromStepLayer(
            prevState.stepLayers[activeStep].connections,
            [connection.id]
          );
      }
      return {
        ...prevState
      };
    },
    CHANGE_CONNECTION(prevState: IBoard, params: ICommandParams): IBoard {
      const { value } = params;
      const { newConnection }: IMoveConnectionValue = value;
      const { stepLayers, activeStep } = prevState;
      // CHANGE object
      if (stepLayers[activeStep]) {
        prevState.connections[newConnection.id] = newConnection;
      }

      return {
        ...prevState
      };
    },
    REVERT_CONNECTION(prevState: IBoard, params: ICommandParams): IBoard {
      const { value } = params;
      const { oldConnection }: IMoveConnectionValue = value;
      const { stepLayers, activeStep } = prevState;
      if (stepLayers[activeStep]) {
        prevState.connections[oldConnection.id] = oldConnection;
      }
      return {
        ...prevState
      };
    },

    CHANGE_MAP(prevState: IBoard, params: ICommandParams): IBoard {
      const { value } = params;

      prevState.map = value.newMap;
      return {
        ...prevState
      };
    },
    REVERT_MAP(prevState: IBoard, params: ICommandParams): IBoard {
      const { value } = params;

      prevState.map = value.prevMap;
      return {
        ...prevState
      };
    }
  };
  return {
    execute(prevState, command: ICommand): IBoard {
      return commands[command.type](prevState, command.params);
    },
    invert(prevState, command: ICommand): IBoard {
      return commands[command.inverse](prevState, command.params);
    }
  };
};

export default CommandController;
