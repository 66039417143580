import { Team } from 'src/types/team';
import { AccountData } from 'src/types/user';

export const subscriptionRunning = (spaceObject: Team | AccountData) => {
  if (spaceObject.spaceTier !== 'basic' && !spaceObject.spaceTier?.cancelAt) {
    return true;
  }

  return false;
};
