import { Team, TeamInfo } from 'src/types/team';

const teamToTeamInfo = (team: Team, id: string): TeamInfo =>
  <TeamInfo>{
    id,
    displayName: team.displayName,
    photoURL: team.photoURL ? team.photoURL : null
  };

export { teamToTeamInfo };
