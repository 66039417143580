import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  customAspectRatioXPercentage,
  customOpacityDarkTheme,
  customOpacityLightTheme
} from 'src/constants';
import { updatePlaybook } from 'src/data/firestore/setters/playbook';
import useSettings from 'src/hooks/useSettings';
import { RootState, useSelector } from 'src/store';
import { Playbook, PlayInfo } from 'src/types/play';
import { SessionInfos, SessionInfo } from 'src/types/session';
import { Team } from 'src/types/team';
import { AccountData } from 'src/types/user';
import hexToRGB from 'src/utils/hexToRGB';
import { playbookToPlaybookInfo } from 'src/utils/playbookToPlaybookInfo';
import playsInfoToThumbnail from 'src/utils/playsInfoToThumbnail';
import { PlayPreviewBox } from '../../plays';
import { AddPlaysToPlaybookDialog } from './controls';
import PlaybookDetailsControls from './PlaybookDetailsControls';

const PlaybookDetails: FC = () => {
  const theme = useTheme();
  const settings = useSettings();
  const spacing = 12;
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const xlDown = useMediaQuery(theme.breakpoints.down('xl'));
  const [selectedPlaybookPlays, setSelectedPlaybookPlays] = useState<string[]>(
    []
  );
  const [allPlaybookPlays, setAllPlaybookPlays] = useState<PlayInfo[]>([]);
  const [addPlaysToPlaybookDialogOpen, setAddPlaysToPlaybookDialogOpen] =
    useState<boolean>(false);
  const { teamId } = useParams();
  const team: Team = useSelector((state: RootState) => state.teamSlice.team);
  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );
  const spaceTier = teamId ? team.spaceTier : accountData.spaceTier;

  const { enqueueSnackbar } = useSnackbar();

  const fullPlaybook: Playbook = useSelector(
    (state: RootState) => state.playbookSlice.playbook
  );

  const sessionInfos: SessionInfos = useSelector(
    (state: RootState) => state.sessionInfosSlice.sessionInfos
  );

  // returns sessionInfo when one exists
  const findSessionInfo = (playId: string): SessionInfo => {
    const result = Object.entries(sessionInfos).find(
      ([, value]) => value.playId === playId
    );

    return result?.[1] || null;
  };

  const maxNumberOfPlaysPerRow = () => {
    /*
    if (smDown) {
      return 2;
    }

    if (mdDown) {
      return 4;
    }
    */

    if (lgDown) {
      return 5;
    }

    if (xlDown) {
      return 5;
    }

    return 6;
  };

  const playInfosToRows = (playInfos: PlayInfo[], itemsPerRow: number) => {
    const playsInfoPlusAddButton = [...playInfos];

    const newPlayInfosArray: any[][] = [];

    while (playsInfoPlusAddButton.length)
      newPlayInfosArray.push(playsInfoPlusAddButton.splice(0, itemsPerRow));

    // fill up last row so that spacing is correct

    let fillups = [];
    if (newPlayInfosArray.at(-1)) {
      if (newPlayInfosArray.at(-1).length < itemsPerRow) {
        const numberOfFillups = itemsPerRow - newPlayInfosArray.at(-1).length;

        for (let i = 0; i < numberOfFillups; i++) {
          fillups = [...fillups, `fillup_${i}`];
        }
      }

      newPlayInfosArray[newPlayInfosArray.length - 1] =
        newPlayInfosArray[newPlayInfosArray.length - 1].concat(fillups);
    }
    return newPlayInfosArray;
  };

  const activeWorkspace = settings?.settings?.activeSpaceID;

  const playbook: Playbook = useSelector(
    (state: RootState) => state.playbookSlice.playbook
  );

  useEffect(() => {
    if (playbook) {
      setAllPlaybookPlays(playbook?.playsInfo);
    } else {
      setAllPlaybookPlays([]);
    }
  }, [playbook]);

  const handleClickCreatePlay = () => {
    setAddPlaysToPlaybookDialogOpen(true);
  };

  const handleToggleSelectAllPlaybookPlays = () => {
    if (selectedPlaybookPlays.length === 0) {
      setSelectedPlaybookPlays(allPlaybookPlays.map((play) => play.id));
    } else {
      setSelectedPlaybookPlays([]);
    }
  };

  const handleToggleSelectPlay = (targetId: string) => {
    if (selectedPlaybookPlays.includes(targetId)) {
      setSelectedPlaybookPlays(
        selectedPlaybookPlays.filter(
          (selectedPlayId) => selectedPlayId !== targetId
        )
      );
    } else {
      setSelectedPlaybookPlays([...selectedPlaybookPlays, targetId]);
    }
  };

  const handleAddPlaysToPlaybook = async (plays: PlayInfo[]) => {
    const updatedPlayInfos: PlayInfo[] = fullPlaybook.playsInfo.concat(plays);

    const updatedPlaybook: Playbook = {
      id: playbook.id,
      title: playbook.title,
      photoURL: playbook.photoURL,
      updatedAt: Date.now(),
      createdAt: playbook.createdAt,
      description: playbook.description,
      numberOfPlays: updatedPlayInfos.length,
      thumbnail: playsInfoToThumbnail(updatedPlayInfos),
      playsInfo: updatedPlayInfos,
      whitelistedIDs: playbook.whitelistedIDs
    };

    try {
      if (teamId) {
        await updatePlaybook(updatedPlaybook, true, teamId);
      } else {
        await updatePlaybook(updatedPlaybook, true);
      }
      enqueueSnackbar('Added plays to playbook', {
        variant: 'success'
      });
      setAddPlaysToPlaybookDialogOpen(false);
    } catch (err) {
      console.error('Adding plays to playbook failed: ', err);
      enqueueSnackbar('Adding plays to playbook failed', {
        variant: 'error'
      });

      setAddPlaysToPlaybookDialogOpen(false);
    }
  };

  return (
    <>
      <PlaybookDetailsControls
        selectedPlays={selectedPlaybookPlays}
        setSelectedPlays={setSelectedPlaybookPlays}
        toggleSelectAllPlays={handleToggleSelectAllPlaybookPlays}
        allPlays={allPlaybookPlays}
        setAllPlays={setAllPlaybookPlays}
        playbook={
          playbook ? playbookToPlaybookInfo(playbook, playbook.id) : null
        }
        addPlaysToPlaybookDialogOpen={addPlaysToPlaybookDialogOpen}
        setAddPlaysToPlaybookDialogOpen={setAddPlaysToPlaybookDialogOpen}
        handleAddPlaysToPlaybook={handleAddPlaysToPlaybook}
      />

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{
          width: `100%`,
          height: '100%'
        }}
      >
        {allPlaybookPlays.length > 0 ? (
          playInfosToRows(allPlaybookPlays, maxNumberOfPlaysPerRow()).map(
            (row) =>
              row.map((play) => {
                if (play === 'add') {
                  return (
                    <Grid
                      key={play}
                      item
                      sx={{
                        width: `calc(${
                          100 / maxNumberOfPlaysPerRow()
                        }% - ${spacing}px)`,
                        mt: `${spacing + 2}px`
                      }}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{ position: 'relative' }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            paddingTop: `${customAspectRatioXPercentage}%`,
                            borderRadius: `${theme.shape.borderRadius}px`,
                            backgroundColor: hexToRGB(
                              theme.palette.primary.main,
                              `${
                                theme.palette.mode === 'light'
                                  ? customOpacityLightTheme
                                  : customOpacityDarkTheme
                              }`
                            )
                          }}
                        />
                        <Grid
                          item
                          sx={{
                            position: 'absolute',
                            width: '14%'
                          }}
                        >
                          <AddIcon
                            sx={{
                              color: 'text.secondary',
                              width: '100%',
                              height: 'auto',
                              opacity: 0.5
                            }}
                          />
                        </Grid>

                        <Button
                          sx={{
                            borderRadius: `${theme.shape.borderRadius}px`,
                            p: 0,
                            top: 0,
                            left: 0,
                            position: 'absolute',
                            width: '100%',
                            pt: `${customAspectRatioXPercentage}%`,
                            border: '1px solid transparent',
                            backgroundColor: 'transparent'
                          }}
                          onClick={handleClickCreatePlay}
                        />

                        <AddPlaysToPlaybookDialog
                          open={addPlaysToPlaybookDialogOpen}
                          setOpen={setAddPlaysToPlaybookDialogOpen}
                          excludedPlayIDs={allPlaybookPlays.map(
                            (playbookPlay) => playbookPlay.id
                          )}
                          submitFunction={handleAddPlaysToPlaybook}
                        />
                      </Grid>
                    </Grid>
                  );
                }

                if (typeof play === 'string' && play.includes('fillup')) {
                  return (
                    <Grid
                      key={play}
                      item
                      sx={{
                        width: `calc(${
                          100 / maxNumberOfPlaysPerRow()
                        }% - ${spacing}px)`,
                        mt: `${spacing + 2}px`
                      }}
                    />
                  );
                }

                return (
                  <Grid
                    key={play.id}
                    item
                    sx={{
                      width: `calc(${
                        100 / maxNumberOfPlaysPerRow()
                      }% - ${spacing}px)`,
                      mt: `${spacing + 2}px`
                    }}
                  >
                    <PlayPreviewBox
                      play={play}
                      activeWorkspace={activeWorkspace}
                      selectionEnabled
                      selected={selectedPlaybookPlays.includes(play.id)}
                      toggleSelected={handleToggleSelectPlay}
                      sessionInfo={findSessionInfo(play.id)}
                    />
                  </Grid>
                );
              })
          )
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%', height: 180 }}
          >
            <Typography
              variant="body2"
              sx={{ color: theme.palette.text.secondary }}
            >
              There are currently no plays here.{' '}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PlaybookDetails;
