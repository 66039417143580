import { Button, Divider, Grid, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useParams } from 'react-router';
import useAuth from 'src/hooks/useAuth';
import { RootState, useSelector } from 'src/store';
import { Team } from 'src/types/team';
import { subscriptionRunning } from 'src/utils/subscriptionRunning';
import { targetUserPaysForSubscription } from 'src/utils/targetUserPaysForSubscription';
import SettingsSection from '../SettingsSection';
import DeleteTeamDialog from './DeleteTeamDialog';
import LeaveTeamDialog from './LeaveTeamDialog';

const TeamOtherSettings: FC = () => {
  const [deleteTeamDialogOpen, setDeleteTeamDialogOpen] = useState(false);
  const [leaveTeamDialogOpen, setLeaveTeamDialogOpen] = useState(false);
  const theme = useTheme();
  const { teamId } = useParams();
  const team: Team = useSelector((state: RootState) => state.teamSlice.team);
  const { user } = useAuth();

  return (
    <Grid
      container
      columnSpacing={6}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item container xs={6} rowSpacing={4}>
        <Grid item xs={12}>
          <SettingsSection title="leave team">
            <Typography
              sx={{ mt: 1, mb: 0, color: theme.palette.text.secondary }}
              variant="body2"
            >
              {subscriptionRunning(team) &&
              targetUserPaysForSubscription(team, user.id)
                ? 'You are paying for the subscription of this team. If you wish to leave the team, please cancel the subscription first. '
                : 'If you intend to leave the team, you can do so here. Keep in mind that you cannot undo this step yourself. To rejoin the team after leaving, you must be invited again.'}
            </Typography>
          </SettingsSection>
        </Grid>

        {(!subscriptionRunning(team) ||
          !targetUserPaysForSubscription(team, user.id)) && (
          <Grid item xs={12}>
            <Divider />
            <Button
              variant="text"
              color="error"
              sx={{
                mt: 2
              }}
              type="button"
              onClick={() => setLeaveTeamDialogOpen(true)}
            >
              Leave Team
            </Button>
          </Grid>
        )}

        <LeaveTeamDialog
          open={leaveTeamDialogOpen}
          setOpen={setLeaveTeamDialogOpen}
          teamID={teamId}
          teamDisplayName={team.displayName}
        />
      </Grid>

      {team.adminIDs.includes(user.id) && (
        <Grid item container xs={6} rowSpacing={4}>
          <Grid item xs={12}>
            <SettingsSection title="delete team">
              <Typography
                color="textSecondary"
                sx={{ mt: 1, mb: 0 }}
                variant="body2"
              >
                {subscriptionRunning(team) &&
                targetUserPaysForSubscription(team, user.id)
                  ? 'You are paying for the subscription of this team. If you wish to delete the team, please cancel the subscription first. '
                  : 'This is where you can delete the team if you intend to do so. Before you continue, however, you should keep in mind that by deleting this team, all existing data related to this team will be gone forever. This step is not reversible. Delete this team only if you are absolutely sure!'}
              </Typography>
            </SettingsSection>
          </Grid>

          {team.adminIDs.includes(user.id) &&
            (!subscriptionRunning(team) ||
              !targetUserPaysForSubscription(team, user.id)) && (
              <Grid item xs={12}>
                <Divider />
                <Button
                  variant="contained"
                  color="error"
                  sx={{
                    mt: 2
                  }}
                  type="button"
                  onClick={() => setDeleteTeamDialogOpen(true)}
                >
                  Delete Team
                </Button>
                <DeleteTeamDialog
                  open={deleteTeamDialogOpen}
                  setDialogOpen={setDeleteTeamDialogOpen}
                />
              </Grid>
            )}
        </Grid>
      )}
    </Grid>
  );
};

export default TeamOtherSettings;
