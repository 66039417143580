import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  useTheme
} from '@mui/material';
import type { FC } from 'react';

const sectionsFAQ = [
  {
    question: 'How do I create a play ?',
    answer:
      'To create a play in your personal space, click on the "Create Play" button on the homepage. The same applies to team spaces, however, in this case a session is created that other team mates can join so that they can create the play together. '
  },
  {
    question: 'How do I create a playbook ?',
    answer:
      'To create playbooks, navigate to the "Playbooks" section and click on "Create Playbook". Follow the instructions in the dialog that appears. If you want to merge existing plays into one playbook, navigate to the "Plays" section, select the target plays, and then click "Merge to Playbook".'
  },
  {
    question: 'How do I create a team ?',
    answer:
      'To create a team, you must first switch to your personal space via "Switch Space". Here you will find the "Create Team" panel on the right side, which opens a dialog. Follow the instructions in the "Create Team" dialog.'
  },
  {
    question: 'How do I invite other users to join my team ?',
    answer:
      'To invite other users to your team, first make sure that you are currently in the team-space to which you want to invite the user. Click on the "Invite User" button on the home page of your team space. Follow the instructions in the dialog that opens. '
  },
  {
    question: 'How do I remove or promote a team member as team admin ?',
    answer:
      'To remove or promote a team member to team admin, you must be admin of the team. The team mate you wish to promote/remove must have the "Team Member" role. On the homepage of your team, click on the three dots next to the team member in the "Team Members" panel. If you have the necessary permissions, you have the option to promote/remove the user here. '
  },
  {
    question: 'My question is not listed, what can I do ?',
    answer:
      'If your question is not listed here, please contact us so that we can personally assist you. You can either join our Discord server to message us directly, or send an email to: dev@critbase.com. '
  }
];

const HelpFAQ: FC = () => {
  const theme = useTheme();

  return (
    <Grid container>
      <Grid item xs={12} sx={{}}>
        {sectionsFAQ.map((section) => (
          <Accordion
            key={section.question}
            disableGutters
            sx={{
              '&:before': {
                display: 'none'
              }
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon sx={{ color: theme.palette.text.secondary }} />
              }
              sx={{
                px: 0
              }}
            >
              <Typography
                sx={{
                  py: 0
                }}
                variant="body1"
              >
                {section.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary" sx={{ py: 1 }}>
                {section.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
};

export default HelpFAQ;
