import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  AppBarProps,
  Box,
  Breadcrumbs,
  Grid,
  IconButton,
  Link,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { experimentalStyled, Theme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { FC, useEffect } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import SessionUserGroup from 'src/components/app/board/SessionUserGroup';
import { appSidebarWidth, appTopbarHeight } from 'src/constants';
import useMdDown from 'src/hooks/useMdDown';
import { RootState, useSelector } from 'src/store';
import { Play, Playbook } from 'src/types/play';
import { SessionUsers } from 'src/types/session';
import { Team } from 'src/types/team';
import { AccountData } from 'src/types/user';
import ChevronRightIcon from '../../icons/ChevronRight';
import SharePlayButton from './board/SharePlayButton';
import PlayTitleInput from './PlayTitleInput';

interface AppNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
  hidden: boolean;
}

const getBreadcrumbsByRoute = (
  theme: Theme,
  spaceID: string | null,
  route: string,
  playId: string | null,
  playbookId: string | null,
  team: Team | null,
  accountData: AccountData | null,
  activePlay: Play | null,
  activePlaybook: Playbook | null
) => {
  if (route.endsWith('personal') || route.endsWith(spaceID)) {
    return (
      <Typography color="textPrimary" variant="h4" sx={{ fontWeight: 600 }}>
        Home
      </Typography>
    );
  }

  if (route.endsWith('plays')) {
    return (
      <Typography color="textPrimary" variant="h4" sx={{ fontWeight: 600 }}>
        Plays
      </Typography>
    );
  }

  if (route.endsWith('playbooks')) {
    return (
      <Typography color="textPrimary" variant="h4" sx={{ fontWeight: 600 }}>
        Playbooks
      </Typography>
    );
  }

  if (route.endsWith(playId || 'create')) {
    return (
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={
          <ChevronRightIcon
            fontSize="small"
            sx={{ color: theme.palette.secondary.main }}
          />
        }
      >
        <Link
          color="textPrimary"
          component={RouterLink}
          to={spaceID ? `/app/team/${spaceID}/plays` : `/app/personal/plays`}
          variant="h4"
          sx={{ fontWeight: 600 }}
          underline="hover"
        >
          Plays
        </Link>

        {activePlay && <PlayTitleInput playTitle={activePlay?.title} />}
      </Breadcrumbs>
    );
  }

  if (route.endsWith(playbookId)) {
    return (
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={
          <ChevronRightIcon
            fontSize="small"
            sx={{ color: theme.palette.secondary.main }}
          />
        }
      >
        <Link
          color="textPrimary"
          component={RouterLink}
          to={
            spaceID
              ? `/app/team/${spaceID}/playbooks`
              : `/app/personal/playbooks`
          }
          variant="h4"
          sx={{ fontWeight: 600 }}
          underline="hover"
        >
          Playbooks
        </Link>

        <Typography
          color="primary"
          variant="h4"
          sx={{ fontWeight: 600, fontStyle: 'italic' }}
        >
          {activePlaybook?.title}
        </Typography>
      </Breadcrumbs>
    );
  }

  return <Typography> </Typography>;
};

const AppNavbarRoot = experimentalStyled(AppBar)(({ theme, hidden }) => ({
  boxShadow: 'none',
  color: theme.palette.text.primary,
  zIndex: theme.zIndex.drawer,

  [theme.breakpoints.up('lg')]: {
    paddingLeft: `${!hidden ? appSidebarWidth : 0}px`
  }
}));

console.log('rendered Navbar');

const AppNavbar: FC<AppNavbarProps> = (props) => {
  const { onSidebarMobileOpen, hidden, ...other } = props;
  const theme = useTheme();
  const mobile = useMediaQuery((currentTheme: Theme) =>
    currentTheme.breakpoints.down('lg')
  );

  const { teamId, playbookId, playId } = useParams();
  const location = useLocation();

  const team: Team = useSelector((state: RootState) => state?.teamSlice?.team);

  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );

  const play: Play = useSelector((state: RootState) => state?.playSlice?.play);

  const playbook: Playbook = useSelector(
    (state: RootState) => state.playbookSlice?.playbook
  );

  const sessionUsers: SessionUsers = useSelector(
    (state: RootState) =>
      state.sessionInfosSlice?.sessionInfos[play?.id]?.users || null
  );

  const mdDown = useMdDown();

  return (
    <>
      {(location.pathname.includes('create') || playId) && (
        <AppNavbarRoot
          {...other}
          hidden={hidden}
          sx={{
            '& .MuiToolbar-root': {
              minHeight: appTopbarHeight
            }
          }}
        >
          <Toolbar
            sx={{
              height: appTopbarHeight,
              p: '0px !important',
              backgroundColor: theme.palette.background.paper,
              borderBottom: `1px solid ${theme.palette.divider}`,
              boxShadow: theme.shadows[19]
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: '100%', px: mdDown ? 3.5 : 7, margin: '0 auto' }}
              maxWidth={
                location.pathname.includes('create') || playId ? 'false' : 'xl'
              }
            >
              <Grid
                item
                sx={{ height: appTopbarHeight, width: 'auto' }}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {(mobile || hidden) && (
                  <IconButton onClick={onSidebarMobileOpen} sx={{ mr: 3 }}>
                    <MenuIcon
                      sx={{
                        color: theme.palette.text.primary
                      }}
                    />
                  </IconButton>
                )}

                <>
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={
                      <ChevronRightIcon
                        fontSize="small"
                        sx={{ color: theme.palette.secondary.main }}
                      />
                    }
                  >
                    {teamId ? (
                      <Link
                        color="textPrimary"
                        component={RouterLink}
                        to={`/app/team/${teamId}`}
                        variant="h4"
                        sx={{ fontWeight: 600 }}
                        underline="hover"
                      >
                        {team?.displayName}
                      </Link>
                    ) : (
                      <Link
                        color="textPrimary"
                        component={RouterLink}
                        to="/app/personal"
                        variant="h4"
                        sx={{ fontWeight: 600 }}
                        underline="hover"
                      >
                        My Personal Space
                      </Link>
                    )}

                    {!mdDown &&
                      getBreadcrumbsByRoute(
                        theme,
                        teamId || null,
                        location.pathname,
                        playId,
                        playbookId,
                        team,
                        accountData,
                        play,
                        playbook
                      )}
                  </Breadcrumbs>
                </>
              </Grid>

              <Grid
                item
                container
                sx={{ width: 'auto', height: '100%' }}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {!mdDown && sessionUsers && (
                  <>
                    <Box
                      sx={{
                        ml: 1.5
                      }}
                    >
                      <SessionUserGroup sessionUsers={sessionUsers} />
                    </Box>
                  </>
                )}
                {/* only show share button when we are in a team play */}
                {playId && teamId && (
                  <Box
                    sx={{
                      ml: 1.5
                    }}
                  >
                    {play?.id && (
                      <SharePlayButton teamId={teamId} playId={playId} />
                    )}
                  </Box>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppNavbarRoot>
      )}
    </>
  );
};

AppNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default AppNavbar;
