import { DEFEND_COLOR, ATTACK_COLOR } from 'src/constants';
import { TeamSides } from 'src/types/board';

const determineBorderColor = (attack: TeamSides): string => {
  if (!attack) {
    return '#ffffff';
  }
  if (attack === TeamSides.ATTACK) {
    return ATTACK_COLOR;
  }
  if (attack === TeamSides.DEFEND) {
    return DEFEND_COLOR;
  }
  return '#ffffff';
};

export default determineBorderColor;
