import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { FC, useEffect, useState } from 'react';
import { removeTeamMember } from 'src/data/firestore/setters/team';
import { useSelector, RootState } from 'src/store';
import { Team } from 'src/types/team';
import { subscriptionRunning } from 'src/utils/subscriptionRunning';
import { targetUserPaysForSubscription } from 'src/utils/targetUserPaysForSubscription';

interface RemoveTeamMemberDialogProps {
  teamID: string;
  teamDisplayName: string;
  targetUserID: string;
  targetUserDisplayName: string;
  open: boolean;
  setOpen: Function;
}

const RemoveTeamMemberDialog: FC<RemoveTeamMemberDialogProps> = (props) => {
  const {
    teamID,
    teamDisplayName,
    targetUserID,
    targetUserDisplayName,
    open,
    setOpen
  } = props;
  const theme = useTheme();
  const [waitingForFunction, setWaitingForFunction] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const team: Team = useSelector((state: RootState) => state.teamSlice.team);

  const handleRemoveTeamMember = async () => {
    try {
      setWaitingForFunction(true);

      await removeTeamMember(teamID, targetUserID);

      setWaitingForFunction(false);

      enqueueSnackbar(
        `Removed '${targetUserDisplayName}' from '${teamDisplayName}'`,
        {
          variant: 'success'
        }
      );

      setOpen(false);
    } catch (err) {
      console.error(err);

      setWaitingForFunction(false);

      enqueueSnackbar(
        `Removing '${targetUserDisplayName}' from '${teamDisplayName} failed'`,
        {
          variant: 'error'
        }
      );

      setOpen(false);
    }
  };

  // cleanup on unmount
  useEffect(() => {
    const returnVal = null;
    return () => returnVal;
  }, []);

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        if (!waitingForFunction) {
          setOpen(false);
        }
      }}
    >
      {waitingForFunction ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '200px', width: '100%' }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <DialogContent sx={{ height: 'auto', maxHeight: '750px' }}>
          <Container
            sx={{
              pt: 2
            }}
          >
            <Typography
              color="textPrimary"
              component="span"
              gutterBottom
              variant="body1"
              sx={{ pb: 1, fontWeight: 500 }}
            >
              {subscriptionRunning(team) &&
              targetUserPaysForSubscription(team, targetUserID) ? (
                <>
                  {' '}
                  {targetUserDisplayName} pays for the subscription of your
                  team. You can only remove {targetUserDisplayName} from your
                  team once he is no longer paying for an active subscription to
                  this team.{' '}
                </>
              ) : (
                <>
                  You are about to remove
                  <Box fontStyle="italic" display="inline">
                    {' '}
                    {targetUserDisplayName}{' '}
                  </Box>
                  from team
                  <Box fontStyle="italic" display="inline">
                    {' '}
                    {teamDisplayName}
                  </Box>
                  . Are you sure you want to continue ?
                </>
              )}
            </Typography>

            <Grid container sx={{ py: 3 }} spacing={2}>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  color="primary"
                  onClick={() => setOpen(false)}
                  variant={
                    theme.palette.mode === 'dark' ? 'contained' : 'outlined'
                  }
                  sx={{ width: '100%' }}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {(!subscriptionRunning(team) ||
                  !targetUserPaysForSubscription(team, targetUserID)) && (
                  <Button
                    onClick={() => handleRemoveTeamMember()}
                    variant="contained"
                    sx={{
                      width: '100%',

                      backgroundColor: `${theme.palette.error.main}`,
                      '&:hover': {
                        backgroundColor: `${theme.palette.error.dark}`
                      }
                    }}
                  >
                    Remove
                  </Button>
                )}
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
      )}
    </Dialog>
  );
};

RemoveTeamMemberDialog.propTypes = {
  teamID: PropTypes.string,
  teamDisplayName: PropTypes.string,
  targetUserID: PropTypes.string,
  targetUserDisplayName: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func
};

export default RemoveTeamMemberDialog;
