import {
  Container,
  Grid,
  Typography,
  useMediaQuery,
  Card,
  CardHeader,
  CardContent
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import AnnouncementsContent from 'src/components/announcements/announcementsContent';

const Announcements: FC = () => {
  const theme = useTheme();

  return (
    <Grid
      alignItems="center"
      container
      justifyContent="center"
      direction="column"
      sx={{ mb: 2 }}
    >
      <Container maxWidth="lg">
        <Grid
          alignItems="center"
          container
          justifyContent="center"
          direction="column"
        >
          <Typography
            align="center"
            variant="h1"
            sx={{
              maxWidth: '1200px',
              fontWeight: 600,
              color: `${theme.palette.text.primary} !important`
            }}
          >
            Announcements
          </Typography>
          <AnnouncementsContent />
        </Grid>
      </Container>
    </Grid>
  );
};

export default Announcements;
