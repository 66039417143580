import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  DocumentReference,
  DocumentSnapshot,
  runTransaction,
  writeBatch
} from 'firebase/firestore';
import { accountDataConverter } from 'src/data/converter/accountDataConverter';
import { playConverter } from 'src/data/converter/playConverter';
import { teamConverter } from 'src/data/converter/team/teamConverter';
import {
  trackAddSoloPlaybook,
  trackAddTeamPlaybook,
  trackDeleteSoloPlaybook,
  trackDeleteTeamPlaybook,
  trackUpdateSoloPlaybook,
  trackUpdateTeamPlaybook
} from 'src/data/mixpanel/setters/trackEvent';
import { LoggerInput } from 'src/types/functionsInput';
import { Playbook } from 'src/types/play';
import { SpaceTier } from 'src/types/subscription';
import { Team } from 'src/types/team';
import { AccountData } from 'src/types/user';
import { playbookToPlaybookInfo } from 'src/utils/playbookToPlaybookInfo';
import { auth, firestore } from '../../../lib/firebase';
import { playbookConverter } from '../../converter/playbookConverter';
import serverSideLogger from 'src/logging/serverSideLogger';

const playbooksRef = collection(firestore, 'playbooks');
const playsRef = collection(firestore, 'plays');

/**
 * creates playbook document in playbooks collection and corresponding playInfo document in user or team in firestore
 * @param {Playbook} playbook - playbook object
 * @param {string}teamId - Id of the team, if the playbook is a team playbook, otherwise null
 */
export const addPlaybook = async (
  playbook: Playbook,
  trackEvent: boolean,
  spaceTier: SpaceTier,
  playbooksLength: number,
  teamId?: string
): Promise<DocumentReference<Playbook>> => {
  if (spaceTier === 'basic' && playbooksLength >= 3) {
    throw new Error('It is not possible to add playbooks with the basic tier!');
  }

  const batch = writeBatch(firestore);

  const newDocRef = await addDoc(
    playbooksRef.withConverter(playbookConverter),
    playbook
  );

  batch.set(newDocRef, playbook);

  if (!teamId) {
    const userDocRef = doc(firestore, 'users', auth.currentUser.uid);

    batch.update(
      userDocRef,
      'playbooksInfo',
      arrayUnion(playbookToPlaybookInfo(playbook, newDocRef.id))
    );
  } else {
    const teamDocRef = doc(firestore, 'teams', teamId);

    batch.update(
      teamDocRef,
      'playbooksInfo',
      arrayUnion(playbookToPlaybookInfo(playbook, newDocRef.id))
    );
  }

  // update referencePlaybookIDs of included Plays
  playbook.playsInfo.forEach((playInfo) => {
    const playRef = doc(firestore, 'plays', playInfo.id);

    batch.update(playRef, 'referencePlaybookIDs', arrayUnion(newDocRef.id));
  });

  return batch
    .commit()
    .then(() => {
      if (trackEvent && teamId) {
        trackAddTeamPlaybook({
          team_id: teamId,
          playbook_id: newDocRef.id,
          playbook_title: playbook.title,
          playbook_description: playbook.description,
          numbe_of_plays: playbook.numberOfPlays,
          playbook_thumbnail: playbook.thumbnail
        });
      }

      if (trackEvent && !teamId) {
        trackAddSoloPlaybook({
          playbook_id: newDocRef.id,
          playbook_title: playbook.title,
          playbook_description: playbook.description,
          numbe_of_plays: playbook.numberOfPlays,
          playbook_thumbnail: playbook.thumbnail
        });
      }

      return newDocRef;
    })
    .catch((err) => {
      console.error('Error occured while trying to add a Playbook:', err);
      const logObject: LoggerInput = {
        kind: 'error',
        function: 'addPlaybook',
        message: err,
        metaData: {
          playbook,
          trackEvent,
          spaceTier,
          playbooksLength,
          teamId
        }
      };
      serverSideLogger(logObject);
      throw new Error('Error occured while trying to add a Playbook');
    });
};

/**
 *   updates playbook document in playbooks collection and corresponding playInfo document in user or team in firestore
 * @param {Playbook} playbook - new playbook object
 * @param {string}teamId - Id of the team, if the playbook is a team playbook, otherwise null
 */
export const updatePlaybook = async (
  playbook: Playbook,
  trackEvent: boolean,
  teamId?: string
): Promise<DocumentReference<Playbook>> => {
  let returnedPlaybookDocRef;

  try {
    await runTransaction(firestore, async (transaction) => {
      const usersRef = collection(firestore, 'users');
      const teamsRef = collection(firestore, 'teams');
      const playbookDocRef = doc(playbooksRef, playbook.id).withConverter(
        playbookConverter
      );

      const prevPlaybookDoc: DocumentSnapshot<Playbook> = await transaction.get(
        playbookDocRef
      );

      const playChanges: PlayInfosChanges = getPlayInfosChanges(
        prevPlaybookDoc.data(),
        playbook
      );

      if (teamId) {
        // read
        const teamDocRef: DocumentReference<Team> = doc(
          teamsRef,
          teamId
        ).withConverter(teamConverter);
        const teamDoc: DocumentSnapshot<Team> = await transaction.get(
          teamDocRef
        );
        if (!teamDoc.exists()) {
          throw new Error('Team not found!');
        }

        // write
        await transaction.set(playbookDocRef, playbook);
        await transaction.update(teamDocRef, {
          playbooksInfo: teamDoc
            .data()
            .playbooksInfo.map((playbookInfo) =>
              playbookInfo.id === playbook.id
                ? playbookToPlaybookInfo(playbook, playbook.id)
                : playbookInfo
            )
        });
        returnedPlaybookDocRef = playbookDocRef;
      } else {
        // read
        const userDocRef: DocumentReference<AccountData> = doc(
          usersRef,
          auth.currentUser.uid
        ).withConverter(accountDataConverter);
        const userDoc: DocumentSnapshot<AccountData> = await transaction.get(
          userDocRef
        );
        if (!userDoc.exists()) {
          throw new Error('User not found!');
        }

        // write
        await transaction.set(playbookDocRef, playbook);
        await transaction.update(userDocRef, {
          playbooksInfo: userDoc
            .data()
            .playbooksInfo.map((playbookInfo) =>
              playbookInfo.id === playbook.id
                ? playbookToPlaybookInfo(playbook, playbook.id)
                : playbookInfo
            )
        });
        returnedPlaybookDocRef = playbookDocRef;
      }

      // update referencePlaybookIDs for added plays
      playChanges.addedPlayIDs.forEach(async (addedPlayID) => {
        const playDocRef = doc(playsRef, addedPlayID).withConverter(
          playConverter
        );
        await transaction.update(playDocRef, {
          referencePlaybookIDs: arrayUnion(playbook.id)
        });
      });

      // update referencePlaybookIDs for removed plays
      playChanges.removedPlayIDs.forEach(async (removedPlayID) => {
        const playDocRef = doc(playsRef, removedPlayID).withConverter(
          playConverter
        );
        await transaction.update(playDocRef, {
          referencePlaybookIDs: arrayRemove(playbook.id)
        });
      });
    });

    if (trackEvent && teamId) {
      trackUpdateTeamPlaybook({
        team_id: teamId,
        playbook_id: playbook.id,
        playbook_title: playbook.title,
        playbook_description: playbook.description,
        numbe_of_plays: playbook.numberOfPlays,
        playbook_thumbnail: playbook.thumbnail
      });
    }
    if (trackEvent && !teamId) {
      trackUpdateSoloPlaybook({
        playbook_id: playbook.id,
        playbook_title: playbook.title,
        playbook_description: playbook.description,
        numbe_of_plays: playbook.numberOfPlays,
        playbook_thumbnail: playbook.thumbnail
      });
    }
  } catch (e) {
    console.error(e);
    const logObject: LoggerInput = {
      kind: 'error',
      function: 'updatePlaybook',
      message: e,
      metaData: {
        playbook,
        trackEvent,
        teamId
      }
    };
    serverSideLogger(logObject);
    throw new Error(e);
  }

  return returnedPlaybookDocRef;
};

/**
 * delete playbook document in playbooks collection and in user or team document as playbookInfo in firestore
 * @param {string}playbookId - Id of the playbook
 * @param {string[]}playIDs - array of the ids in the playbook, used to remove playbook references in play documents
 * @param {string}teamId - Id of the team, if the playbook is a team playbook, otherwise null
 */
export const deletePlaybook = async (
  playbookId: string,
  trackEvent: boolean,
  teamId?: string
) => {
  try {
    await runTransaction(firestore, async (transaction) => {
      const usersRef = collection(firestore, 'users');
      const teamsRef = collection(firestore, 'teams');
      const playbookDocRef = doc(playbooksRef, playbookId).withConverter(
        playbookConverter
      );
      const playbookDoc: DocumentSnapshot<Playbook> = await transaction.get(
        playbookDocRef
      );
      const playIDs: string[] = playbookDoc
        .data()
        .playsInfo.map((playInfo) => playInfo.id);

      if (teamId) {
        // read
        const teamDocRef: DocumentReference<Team> = doc(
          teamsRef,
          teamId
        ).withConverter(teamConverter);
        const teamDoc: DocumentSnapshot<Team> = await transaction.get(
          teamDocRef
        );
        if (!teamDoc.exists()) {
          throw new Error('Team not found!');
        }

        // write
        await transaction.delete(playbookDocRef);
        await transaction.update(teamDocRef, {
          playbooksInfo: teamDoc
            .data()
            .playbooksInfo.filter(
              (playbookInfo) => playbookInfo.id !== playbookId
            )
        });
      } else {
        // read
        const userDocRef: DocumentReference<AccountData> = doc(
          usersRef,
          auth.currentUser.uid
        ).withConverter(accountDataConverter);
        const userDoc: DocumentSnapshot<AccountData> = await transaction.get(
          userDocRef
        );
        if (!userDoc.exists()) {
          throw new Error('User not found!');
        }

        //  write
        await transaction.delete(playbookDocRef);
        await transaction.update(userDocRef, {
          playbooksInfo: userDoc
            .data()
            .playbooksInfo.filter(
              (playbookInfo) => playbookInfo.id !== playbookId
            )
        });
      }

      playIDs.forEach(async (id) => {
        const playDocRef = doc(playsRef, id).withConverter(playConverter);
        await transaction.update(playDocRef, {
          referencePlaybookIDs: arrayRemove(playbookId)
        });
      });
    });

    if (trackEvent && teamId) {
      trackDeleteTeamPlaybook({
        team_id: teamId,
        playbook_id: playbookId
      });
    }

    if (trackEvent && !teamId) {
      trackDeleteSoloPlaybook({
        playbook_id: playbookId
      });
    }
  } catch (e) {
    console.error(e);
    const logObject: LoggerInput = {
      kind: 'error',
      function: 'deletePlaybook',
      message: e,
      metaData: {
        playbookId,
        trackEvent,
        teamId
      }
    };
    serverSideLogger(logObject);
    throw new Error('deleting playbook failed');
  }
};

// helper

export interface PlayInfosChanges {
  removedPlayIDs: string[];
  addedPlayIDs: string[];
}

export const getPlayInfosChanges = (
  prevPlaybook: Playbook,
  newPlaybook: Playbook
): PlayInfosChanges => {
  const prevPlaysInfoIds: string[] = prevPlaybook.playsInfo.map(
    (playInfo) => playInfo.id
  );
  const newPlaysInfoIds: string[] = newPlaybook.playsInfo.map(
    (playInfo) => playInfo.id
  );

  const returnObj: PlayInfosChanges = {
    removedPlayIDs: prevPlaysInfoIds.filter(
      (id) => !newPlaysInfoIds.includes(id)
    ),
    addedPlayIDs: newPlaysInfoIds.filter((id) => !prevPlaysInfoIds.includes(id))
  };

  return returnObj;
};
