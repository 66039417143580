import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

export default function useLgDown() {
  const theme = useTheme();

  const getLgDown = () => window.innerWidth <= theme.breakpoints.values.lg;

  const [lgDown, setLgDown] = useState(getLgDown());

  useEffect(() => {
    const onResize = () => {
      setLgDown(getLgDown());
    };

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return lgDown;
}
