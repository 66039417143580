import {
  CircularProgress,
  Container,
  DialogContent,
  Grid,
  Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';

interface IProps {
  title?: string;
}

const CircularLoadingDialog = (props: IProps) => {
  const { title } = props;

  return (
    <Dialog
      open
      onClose={() => {
        // not possible
      }}
      disableEscapeKeyDown
    >
      <DialogContent sx={{ height: 'auto', maxHeight: '750px' }}>
        <Container
          sx={{
            width: '100%',
            height: '100%'
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ p: 2 }}
          >
            {title && (
              <Grid item xs={12}>
                <Typography
                  color="textPrimary"
                  variant="h2"
                  align="center"
                  sx={{ mb: 4 }}
                >
                  {title}
                </Typography>
              </Grid>
            )}

            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default CircularLoadingDialog;
