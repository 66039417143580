import React, { FC } from 'react';
import { shallowEqual } from 'react-redux';
import {
  areaColor,
  areaTextColor,
  attackersTextColor,
  baseColor,
  defendersTextColor,
  mapSize,
  normalTextColor,
  objectsColor,
  wallsColor,
  BOARD_SIZE,
  outlineColor
} from 'src/constants';
import * as maps from 'src/ressources/maps';
import { RootState, useSelector } from 'src/store';
import { Maps } from 'src/types/board';
import './MapLayer.scss';

interface IProps {
  map: Maps;
  mapRotation: number;
}

// to be able to use in css we need to convert and auto
const negateRotation = (num: number) => `-${num}deg`;

const MapLayer: FC<IProps> = (props: IProps) => {
  const { map, mapRotation } = props;

  const { visibility } = useSelector(
    (state: RootState) => state.boardSlice.ui,
    shallowEqual
  );

  const styles = {
    '--base-color': `${baseColor}`,
    '--outline-color': `${outlineColor}`,
    '--objects-color': `${objectsColor}`,
    '--walls-color': `${wallsColor}`,
    '--area-color': `${areaColor}`,
    '--normal-text-color': `${normalTextColor}`,
    '--area-text-color': `${areaTextColor}`,
    '--attackers-text-color': `${attackersTextColor}`,
    '--defenders-text-color': `${defendersTextColor}`,
    '--text-rotation': negateRotation(mapRotation),
    '--map-base-visible': `${visibility.mapBaseVisible}`,
    '--map-objects-visible': `${visibility.objectsVisible}`,
    '--map-labels-visible': `${visibility.mapLabelsVisible}`,
    '--map-areas-visible': `${visibility.mapAreasVisible}`,
    width: '100%',
    height: '100%'
  };

  const classes = `
                    cb-map-layer ${
                      !visibility.mapBaseVisible ? 'cb-map-base-hidden' : ''
                    } 
                    ${
                      !visibility.mapObjectsVisible
                        ? 'cb-map-objects-hidden'
                        : ''
                    } 
                    ${
                      !visibility.mapLabelsVisible ? 'cb-map-labels-hidden' : ''
                    } 
                    ${!visibility.mapAreasVisible ? 'cb-map-areas-hidden' : ''}
                  `;

  const PickedMap = maps[map].map;

  return (
    <PickedMap
      className={classes}
      style={styles}
      x={BOARD_SIZE / 2 - mapSize / 2}
      y={BOARD_SIZE / 2 - mapSize / 2}
    />
  );
};

export default MapLayer;
