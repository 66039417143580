import CheckIcon from '@mui/icons-material/Check';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DateRangeIcon from '@mui/icons-material/DateRange';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { FC, useEffect, useState } from 'react';
import { listProductsAndPrices } from 'src/data/firestore/setters/subscription';
import {
  basicFeaturesPersonal,
  proFeaturesPersonal,
  subscriptionTierBasicColor,
  subscriptionTierProColor
} from 'src/constants';
import hexToRGB from 'src/utils/hexToRGB';
import { stripeConfig } from 'src/config';

const priceToInterval = (price) => {
  if (!price) {
    return '';
  }

  if (price.interval === 'month') {
    if (price.intervalCount === 1) {
      return 'monthly';
    }

    return 'biannually';
  }

  return 'annually';
};

const PricingPersonalSpace: FC = () => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [product, setProduct] = useState(null);
  const [price, setPrice] = useState(null);

  useEffect(() => {
    async function getProducts() {
      const response = await listProductsAndPrices();

      const targetProduct = response.find(
        (someProduct) =>
          someProduct.productId === stripeConfig.personalProductId
      );

      console.log('targetPRODUCT: ', targetProduct);

      setProduct(targetProduct);
    }

    getProducts();
  }, []);

  useEffect(() => {
    setPrice(product?.prices[0]);
  }, [product]);

  const priceFromInterval = (interval) => {
    if (interval === 'monthly') {
      return product?.prices.find(
        (price_i) => price_i.intervalCount === 1 && price_i.interval === 'month'
      );
    }

    if (interval === 'biannually') {
      return product?.prices.find(
        (price_i) => price_i.intervalCount === 6 && price_i.interval === 'month'
      );
    }

    return product?.prices.find((price_i) => price_i.interval === 'year');
  };

  return (
    <Grid
      alignItems="center"
      container
      justifyContent="center"
      direction="column"
      sx={{
        py: smDown ? 6 : 10
      }}
    >
      <Container maxWidth="lg">
        <Grid
          alignItems="center"
          container
          justifyContent="center"
          direction="column"
        >
          {!smDown && (
            <>
              <Typography
                align="center"
                variant="body1"
                sx={{
                  fontSize: smDown ? '1.1rem !important' : '2rem !important',
                  maxWidth: '1200px',
                  fontWeight: 600,
                  color: `${theme.palette.text.primary} !important`
                }}
              >
                Pricing - Personal Space
              </Typography>
            </>
          )}
        </Grid>
      </Container>

      {!product || !price ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%', height: 300 }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid
            container
            columnSpacing={3}
            rowSpacing={2}
            direction={smDown ? 'column' : 'row'}
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ py: 3, maxWidth: 800 }}
          >
            <Grid item container xs={12} sx={{ mb: 1 }}>
              <Grid
                container
                direction={smDown ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  p: 0.5,
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: `${theme.shape.borderRadius}px`
                }}
              >
                <Button
                  sx={{
                    width: 176,
                    backgroundColor:
                      priceToInterval(price) !== 'monthly' && 'transparent'
                  }}
                  onClick={() => setPrice(priceFromInterval('monthly'))}
                >
                  Monthly{' '}
                </Button>
                <Button
                  sx={{
                    width: 176,
                    mx: 0.5,
                    backgroundColor:
                      priceToInterval(price) !== 'biannually' && 'transparent'
                  }}
                  onClick={() => setPrice(priceFromInterval('biannually'))}
                >
                  Biannually
                  <Box
                    sx={{
                      backgroundColor: subscriptionTierProColor,
                      px: '5px',
                      borderRadius: 99,
                      ml: 0.5
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 9,
                        fontWeight: 600
                      }}
                    >
                      Save 1 month
                    </Typography>
                  </Box>
                </Button>
                <Button
                  sx={{
                    width: 176,
                    backgroundColor:
                      priceToInterval(price) !== 'annually' && 'transparent'
                  }}
                  onClick={() => setPrice(priceFromInterval('annually'))}
                >
                  Annually
                  <Box
                    sx={{
                      backgroundColor: subscriptionTierProColor,
                      px: '5px',
                      borderRadius: 99,
                      ml: 0.5
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 9,
                        fontWeight: 600
                      }}
                    >
                      Save 3 months
                    </Typography>
                  </Box>
                </Button>
              </Grid>
            </Grid>

            <Grid item container xs={6} justifyContent="center">
              <Grid
                item
                direction="column"
                container
                xs={12}
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: `${theme.shape.borderRadius}px`,
                  p: 5,
                  position: 'relative'
                }}
              >
                <Grid
                  item
                  sx={{
                    backgroundColor: hexToRGB(
                      subscriptionTierBasicColor,
                      '0.1'
                    ),
                    border: `1px solid ${subscriptionTierBasicColor}`,
                    px: 2,
                    py: 0.1,
                    borderRadius: `${theme.shape.borderRadius}px`
                  }}
                >
                  <Typography sx={{ fontWeight: 500, fontSize: 15 }}>
                    Basic Tier
                  </Typography>
                </Grid>

                <Typography
                  sx={{ fontWeight: 500, fontSize: 26, pt: 1.5, pb: 0.5 }}
                >
                  FREE
                </Typography>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mb: 3 }}
                >
                  No Payment Details required
                </Typography>
                <Divider sx={{ width: '100%', mb: 3 }} />
                {basicFeaturesPersonal.map((feature) => (
                  <Grid
                    key={feature}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ py: 1, pl: 1 }}
                  >
                    <CheckIcon
                      sx={{
                        color: subscriptionTierBasicColor,
                        backgroundColor: hexToRGB(
                          subscriptionTierBasicColor,
                          '0.3'
                        ),
                        borderRadius: 99,
                        p: '2px'
                      }}
                    />

                    <Typography sx={{ ml: 2 }}>{feature}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item container xs={6} justifyContent="center">
              <Grid
                item
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={12}
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: `${theme.shape.borderRadius}px`,
                  p: 5,
                  position: 'relative'
                }}
              >
                <Grid
                  item
                  sx={{
                    backgroundColor: hexToRGB(subscriptionTierProColor, '0.1'),
                    border: `1px solid ${subscriptionTierProColor}`,
                    px: 2,
                    py: 0.1,
                    borderRadius: `${theme.shape.borderRadius}px`
                  }}
                >
                  <Typography sx={{ fontWeight: 500, fontSize: 15 }}>
                    Pro Tier
                  </Typography>
                </Grid>

                <Typography
                  sx={{ fontWeight: 500, fontSize: 26, pt: 1.5, pb: 0.5 }}
                >
                  ${(price?.amount / 100)?.toFixed(2)}
                </Typography>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mb: 3 }}
                >
                  {priceToInterval(price) === 'monthly' && '/ per month'}
                  {priceToInterval(price) === 'biannually' &&
                    '/ every 6 months'}
                  {priceToInterval(price) === 'annually' && '/ every year'}
                </Typography>

                <Divider sx={{ width: '100%', mb: 4 }} />

                {proFeaturesPersonal.map((feature) => (
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{ py: 1, pl: 1 }}
                    key={feature}
                  >
                    <CheckIcon
                      sx={{
                        color: subscriptionTierProColor,
                        backgroundColor: hexToRGB(
                          subscriptionTierProColor,
                          '0.3'
                        ),
                        borderRadius: 99,
                        p: '2px'
                      }}
                    />

                    <Typography sx={{ ml: 2 }}>{feature}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default PricingPersonalSpace;
