import CachedIcon from '@mui/icons-material/Cached';
import {
  Box,
  Button,
  Container,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CustomDialogHeader from 'src/components/CustomDialogHeader';
import CustomTooltip from 'src/components/CustomTooltip';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import { RootState } from 'src/store';
import { TeamInfo } from 'src/types/team';
import hexToRGB from 'src/utils/hexToRGB';
import CustomAvatar from '../CustomAvatar';

type SpaceInfo = {
  title: string;
  id: string;
  subtitle: string;
  avatar: string;
};

interface SwitchSpaceDialogContentProps {
  handleSwitchSpace: Function;
  onClose: Function;
}

const teamInfosToTeamSpacesInfos = (teamInfos: TeamInfo[]) => {
  const result: SpaceInfo[] = teamInfos.map((teamInfo) => ({
    title: teamInfo.displayName,
    id: teamInfo.id,
    subtitle: 'a Valorant Team Space',
    avatar: teamInfo.photoURL
  }));

  return result;
};

const avatarToPersonalSpaceInfos = (userPhotoURL: string) => {
  const result: SpaceInfo[] = [
    {
      title: 'Personal Space',
      id: null,
      subtitle: 'your Personal Space',
      avatar: userPhotoURL
    }
  ];

  return result;
};

const SwitchSpaceDialogContent: FC<SwitchSpaceDialogContentProps> = (props) => {
  const { handleSwitchSpace, onClose } = props;

  const theme = useTheme();

  const { user } = useAuth();

  const { settings } = useSettings();

  const teamsInfo: TeamInfo[] = useSelector((state: RootState) =>
    state.accountDataSlice.accountData
      ? state.accountDataSlice.accountData.teamsInfo
      : []
  );

  return (
    <Container
      sx={{
        width: '100%',
        height: '100%',
        padding: '0px !important'
      }}
    >
      <Grid container sx={{ p: 1 }} spacing={3.5}>
        {[
          ...teamInfosToTeamSpacesInfos(teamsInfo),
          ...avatarToPersonalSpaceInfos(user.photoURL)
        ].map((space, i) => (
          <>
            <Grid
              key={space.id}
              item
              xs={12}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ width: 'auto' }}
              >
                <CustomAvatar
                  src={space.avatar}
                  isTeamAvatar={space.id !== null}
                />

                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  sx={{
                    width: 'auto',
                    height: '100%',
                    pl: 2
                  }}
                >
                  <Typography color="textPrimary" variant="body1">
                    {space.title}{' '}
                    {space.id === settings.activeSpaceID
                      ? '(currently selected)'
                      : ''}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="caption"
                    sx={{
                      color: `${theme.palette.text.secondary} !important`
                    }}
                  >
                    {space.subtitle}
                  </Typography>
                </Grid>
              </Grid>

              {space.id === settings.activeSpaceID ? null : (
                <Grid item>
                  <Button
                    onClick={() => handleSwitchSpace(space.id)}
                    size="small"
                    variant="text"
                  >
                    Switch to space
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        ))}
      </Grid>
    </Container>
  );
};

SwitchSpaceDialogContent.propTypes = {
  handleSwitchSpace: PropTypes.func.isRequired
};

export default SwitchSpaceDialogContent;
