import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_LINE_COLOR, DEFAULT_LINE_STROKEWIDTH } from 'src/constants';
import type { AppThunk } from 'src/store';
import {
  ActionTypes,
  BoardTools,
  BoardVisibilities,
  IBoardVisibility,
  ILineOptions,
  IUIBoard
} from 'src/types/board';

const initialState: IUIBoard = {
  visibility: {
    mapBaseVisible: true,
    mapObjectsVisible: true,
    mapAreasVisible: true,
    mapLabelsVisible: false,
    linesVisible: true,
    objectsVisible: true,
    connectionsVisible: true,
    areasOfEffectVisible: true
  } as IBoardVisibility,
  lineOptions: {
    color: DEFAULT_LINE_COLOR,
    strokeWidth: DEFAULT_LINE_STROKEWIDTH,
    strokeDashArray: null,
    showDistance: false
  } as ILineOptions,
  arrowOptions: {
    color: DEFAULT_LINE_COLOR,
    strokeWidth: DEFAULT_LINE_STROKEWIDTH,
    strokeDashArray: null,
    markerStart: null,
    markerEnd: null
  } as ILineOptions,
  selectedTool: BoardTools.SELECT,
  selectedObject: null,
  cursorState: null,
  lastSaveTimestamp: null
};

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    resetUi() {
      return initialState;
    },
    setSelectedTool(
      state: IUIBoard,
      action: PayloadAction<{ selectedTool: BoardTools }>
    ) {
      const { selectedTool } = action.payload;
      // always reset selected object when changing tool
      state.selectedObject = null;
      state.selectedTool = selectedTool;
    },
    setSelectedObject(
      state: IUIBoard,
      action: PayloadAction<{ selectedObjectId: string }>
    ) {
      const { selectedObjectId } = action.payload;

      return { ...state, selectedObject: selectedObjectId };
    },
    setCursorState(
      state: IUIBoard,
      action: PayloadAction<{ cursorState: ActionTypes }>
    ) {
      const { cursorState } = action.payload;
      state.cursorState = cursorState;
    },

    setLineOptions(
      state: IUIBoard,
      action: PayloadAction<{ options: ILineOptions }>
    ) {
      const { options } = action.payload;
      state.lineOptions = options;
    },

    setArrowOptions(
      state: IUIBoard,
      action: PayloadAction<{ options: ILineOptions }>
    ) {
      const { options } = action.payload;
      state.arrowOptions = options;
    },

    setLastSaveTimestamp(
      state: IUIBoard,
      action: PayloadAction<{ timestamp: number }>
    ) {
      const { timestamp } = action.payload;
      state.lastSaveTimestamp = timestamp;
    },

    toggleVisibility(
      state: IUIBoard,
      action: PayloadAction<{ visibility: BoardVisibilities }>
    ) {
      const { visibility } = action.payload;
      switch (visibility) {
        case BoardVisibilities.MAP_BASE: {
          state.visibility.mapBaseVisible = !state.visibility.mapBaseVisible;
          break;
        }
        case BoardVisibilities.MAP_OBJECTS: {
          state.visibility.mapObjectsVisible =
            !state.visibility.mapObjectsVisible;
          break;
        }
        case BoardVisibilities.MAP_AREAS: {
          state.visibility.mapAreasVisible = !state.visibility.mapAreasVisible;
          break;
        }
        case BoardVisibilities.MAP_LABELS: {
          state.visibility.mapLabelsVisible =
            !state.visibility.mapLabelsVisible;
          break;
        }
        case BoardVisibilities.LINES: {
          state.visibility.linesVisible = !state.visibility.linesVisible;
          break;
        }
        case BoardVisibilities.OBJECTS: {
          state.visibility.objectsVisible = !state.visibility.objectsVisible;
          break;
        }
        case BoardVisibilities.CONNECTIONS: {
          state.visibility.connectionsVisible =
            !state.visibility.connectionsVisible;
          break;
        }
        case BoardVisibilities.AREASOFEFFECT: {
          state.visibility.areasOfEffectVisible =
            !state.visibility.areasOfEffectVisible;
          break;
        }
        default: {
          break;
        }
      }
    },
    setVisibility(
      state: IUIBoard,
      action: PayloadAction<{ visibility: BoardVisibilities; value: boolean }>
    ) {
      const { visibility, value } = action.payload;
      switch (visibility) {
        case BoardVisibilities.MAP_BASE: {
          state.visibility.mapBaseVisible = value;
          break;
        }
        case BoardVisibilities.MAP_OBJECTS: {
          state.visibility.mapObjectsVisible = value;
          break;
        }
        case BoardVisibilities.MAP_AREAS: {
          state.visibility.mapAreasVisible = value;
          break;
        }
        case BoardVisibilities.MAP_LABELS: {
          state.visibility.mapLabelsVisible = value;
          break;
        }
        case BoardVisibilities.LINES: {
          state.visibility.linesVisible = value;
          break;
        }
        case BoardVisibilities.OBJECTS: {
          state.visibility.objectsVisible = value;
          break;
        }
        case BoardVisibilities.CONNECTIONS: {
          state.visibility.connectionsVisible = value;
          break;
        }
        default: {
          break;
        }
      }
    }
  }
});

export const { reducer } = slice;

export const setSelectedTool =
  (selectedTool: BoardTools): AppThunk =>
  (dispatch) => {
    const response = { selectedTool };
    dispatch(slice.actions.setSelectedTool(response));
  };
export const setSelectedObject =
  (selectedObjectId: string): AppThunk =>
  (dispatch) => {
    const response = { selectedObjectId };

    dispatch(slice.actions.setSelectedObject(response));
  };
export const setCursorState =
  (cursorState: ActionTypes): AppThunk =>
  (dispatch) => {
    const response = { cursorState };
    dispatch(slice.actions.setCursorState(response));
  };
export const toggleVisibility =
  (visibility: BoardVisibilities): AppThunk =>
  (dispatch) => {
    const response = { visibility };
    dispatch(slice.actions.toggleVisibility(response));
  };
export const setVisibility =
  (visibility: BoardVisibilities, value: boolean): AppThunk =>
  (dispatch) => {
    const response = { visibility, value };
    dispatch(slice.actions.toggleVisibility(response));
  };
export const setLineOptions =
  (options: ILineOptions): AppThunk =>
  (dispatch) => {
    const response = { options };
    dispatch(slice.actions.setLineOptions(response));
  };

export const setArrowOptions =
  (options: ILineOptions): AppThunk =>
  (dispatch) => {
    const response = { options };
    dispatch(slice.actions.setArrowOptions(response));
  };

export const setLastSaveTimestamp =
  (timestamp: number): AppThunk =>
  (dispatch) => {
    const response = { timestamp };
    dispatch(slice.actions.setLastSaveTimestamp(response));
  };

export const resetUi = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.resetUi());
};

export default slice;
