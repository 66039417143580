import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import serverSideLogger from 'src/logging/serverSideLogger';
import { updateAnnouncements } from 'src/slices/announcement';
import store from 'src/store';
import { Announcement } from 'src/types/announcement';
import { LoggerInput } from 'src/types/functionsInput';
import { firestore } from '../../../lib/firebase';
import { announcementConverter } from '../../converter/announcementsConverter';

const ref = collection(firestore, 'announcements');

/**
 *
 * @returns
 */
export const subscribeToAnnouncements = () => {
  try {
    // type angeben ? : "collection vs. query"
    const q = query(ref, orderBy('publishedAt', 'desc')).withConverter(
      announcementConverter
    );

    return onSnapshot(
      q,
      (querySnapshot) => {
        const items: Announcement[] = [];
        querySnapshot.forEach((doc) => {
          items.push(doc.data() as Announcement);
        });
        store.dispatch(updateAnnouncements(items));
      },
      (error) => {
        console.error(
          'Error occured while trying to subscribe to Announcements:',
          error
        );
      }
    );
  } catch (err) {
    console.error(
      'Error occured while trying to subscribe to Announcements:',
      err
    );
    const logObject: LoggerInput = {
      kind: 'error',
      function: 'subscribeToAnnouncements',
      message: err,
      metaData: null
    };
    serverSideLogger(logObject);

    return undefined;
  }
};
