import { Box, Button, Grid, Theme, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import AspectRatio from 'src/components/AspectRatio';
import { customOpacityDarkTheme, customOpacityLightTheme } from 'src/constants';
import { changeMap } from 'src/slices/board/boardModel';
import store from 'src/store';
import { Maps } from 'src/types/board';
import hexToRGB from 'src/utils/hexToRGB';
import mapToSource from 'src/utils/maptoSource';

const maps = Object.values(Maps);

const MapDrawer: React.FC = () => {
  const theme: Theme = useTheme();
  const { playId: sessionId, teamId } = useParams();

  const [activeMap, setActiveMap] = useState<Maps>(
    store.getState().boardSlice.model.map
  );

  const handleMapChange = (newMap: Maps) => {
    if (newMap) {
      setActiveMap(newMap);
      store.dispatch(changeMap(newMap, teamId ? sessionId : null));
    }
  };

  return (
    <Grid container sx={{ width: '100%', p: 1, pb: 3 }}>
      {maps.map((map) => (
        <Grid item xs={6} key={map} sx={{ mb: 2, px: 1.5 }}>
          <Typography
            sx={{
              color: `${theme.palette.text.secondary}!important`
            }}
            variant="overline"
          >
            {map}
          </Typography>
          <Box
            sx={{
              position: 'relative',
              mt: 0.5
            }}
          >
            <AspectRatio
              ratio={16 / 10}
              style={{
                backgroundColor: hexToRGB(
                  theme.palette.primary.main,
                  `${
                    theme.palette.mode === 'light'
                      ? customOpacityLightTheme
                      : customOpacityDarkTheme
                  }`
                ),
                borderRadius: `${theme.shape.borderRadius}px`,
                boxShadow: theme.shadows[24]
              }}
            >
              <img
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: `${theme.shape.borderRadius}px`,
                  filter: activeMap === map ? 'saturate(100%)' : 'saturate(0%)'
                }}
                alt={map}
                src={mapToSource(map)}
              />
            </AspectRatio>

            <Button
              sx={{
                borderRadius: `${theme.shape.borderRadius}px`,
                p: 0,
                top: 0,
                pt: `${62.5}%`,
                width: '100%',
                position: 'absolute',
                zIndex: 998
              }}
              onClick={() => handleMapChange(map)}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default MapDrawer;
