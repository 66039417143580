import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import MapIcon from '@mui/icons-material/Map';
import NearMeIcon from '@mui/icons-material/NearMe';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import PanToolIcon from '@mui/icons-material/PanTool';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import {
  Alert,
  AlertTitle,
  Box,
  CssBaseline,
  Divider,
  Fade,
  Grid,
  ListItemButton,
  Paper,
  Theme,
  ToggleButton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { useState } from 'react';
import { shallowEqual } from 'react-redux';
import CustomTooltip from 'src/components/CustomTooltip';
import {
  customOpacityDarkTheme,
  customOpacityDarkThemeBoard
} from 'src/constants';
import { ReactComponent as EraserIcon } from 'src/ressources/toolbar/eraser.svg';
import { setSelectedTool } from 'src/slices/board/boardUi';
import store, { RootState, useSelector } from 'src/store';
import { BoardTools, TeamSides } from 'src/types/board';
import hexToRGB from 'src/utils/hexToRGB';
import LogoValorantV from '../../../../../icons/LogoValorantV';
import ClearButton from '../ClearButton';
import SaveButton from '../SavePlayButton';
import ArrowDrawer from './ArrowDrawer';
import DrawingDrawer from './DrawingDrawer';
import MapDrawer from './MapDrawer';
import ValorantItemsDrawer from './ValorantItemsDrawer';

const normalDrawerWidth = 280;
const valorantItemsDrawerWidth = 330;
const mapsDrawerWidth = 480;

enum VerticalBreakpoints {
  verticalBreakPointXl = 1200,
  verticalBreakPointL = 1000,
  verticalBreakPointMd = 800,
  verticalBreakPointS = 600,
  verticalBreakPointXs = 400
}

const toolsPanelHeight = 480;

const darwingDrawerContentHeight = 437;
const mapDrawerContentHeight = 684;

type InfoTextVariantOption = 'info' | 'warning';

interface CustomDrawerHeaderProps {
  title: string;
  onClose: Function;
  theme: Theme;
  infoTextVariant?: InfoTextVariantOption;
  infoTextHeading?: string;
  infoText?: string;
}

const CustomDrawerHeader = (props: CustomDrawerHeaderProps) => {
  const { title, infoText, onClose, theme, infoTextHeading, infoTextVariant } =
    props;

  return (
    <Box sx={{ width: '100%', height: 'auto', p: 2, position: 'sticky', top: 0, backgroundColor: theme.palette.background.paper, zIndex: 2}}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3" sx={{ pl: 1 }}>
          {' '}
          {title}{' '}
        </Typography>
        <IconButton
          onClick={() => onClose()}
          size="small"
          sx={{
            backgroundColor: hexToRGB(
              theme.palette.primary.main,
              customOpacityDarkTheme.toString()
            )
          }}
        >
          <CloseIcon color="inherit" fontSize="large" fill="#d9d9d9" />
        </IconButton>
      </Grid>
      {infoText && (
        <Alert
          severity={infoTextVariant || 'info'}
          sx={{
            mt: 2.5,
            mb: 1.5,
            border: `1px solid ${
              infoTextVariant === 'warning'
                ? theme.palette.warning.main
                : theme.palette.info.main
            }`,
            mx: 1.5
          }}
        >
          {infoTextHeading && <AlertTitle>{infoTextHeading}</AlertTitle>}
          {infoText}
        </Alert>
      )}
    </Box>
  );
};

const determineVerticalBreakPoint = (paperProps): VerticalBreakpoints => {
  if (paperProps.verticalBreakPointXl)
    return VerticalBreakpoints.verticalBreakPointXl;
  if (paperProps.verticalBreakPointL)
    return VerticalBreakpoints.verticalBreakPointL;
  if (paperProps.verticalBreakPointMd)
    return VerticalBreakpoints.verticalBreakPointMd;
  if (paperProps.verticalBreakPointS)
    return VerticalBreakpoints.verticalBreakPointS;
  if (paperProps.verticalBreakPointXs)
    return VerticalBreakpoints.verticalBreakPointXs;

  return VerticalBreakpoints.verticalBreakPointL;
};

const determineDrawerLength = (
  verticalBreakPoint: VerticalBreakpoints
): number => {
  if (verticalBreakPoint === VerticalBreakpoints.verticalBreakPointXl)
    return 1000;
  if (verticalBreakPoint === VerticalBreakpoints.verticalBreakPointL)
    return 600;
  if (verticalBreakPoint === VerticalBreakpoints.verticalBreakPointMd)
    return 600;
  if (verticalBreakPoint === VerticalBreakpoints.verticalBreakPointS)
    return 450;
  if (verticalBreakPoint === VerticalBreakpoints.verticalBreakPointXs)
    return 200;

  return 600;
};

type DrawerItem = 'draw' | 'arrow' | 'valorant' | 'map' | 'files';

type DrawerListItem = {
  title: string;
  key: DrawerItem;
  icon: React.ReactElement<any, any>;
};

const ToolsDrawer: React.FC = () => {
  const theme = useTheme();
  const verticalBreakPointXl = useMediaQuery('(min-height:1200px)'); // 1200
  const verticalBreakPointL = useMediaQuery('(min-height:1000px)'); // 1200
  const verticalBreakPointMd = useMediaQuery('(min-height:800px)'); // 1200
  const verticalBreakPointS = useMediaQuery('(min-height:600px)'); // 1200
  const verticalBreakPointXs = useMediaQuery('(min-height:400px)'); // 1200
  const paperProps = {
    verticalBreakPointXl,
    verticalBreakPointL,
    verticalBreakPointMd,
    verticalBreakPointS,
    verticalBreakPointXs
  };
  const verticalBreakPoint: VerticalBreakpoints =
    determineVerticalBreakPoint(paperProps);
  const [attack, setAttack] = useState<TeamSides>(TeamSides.ATTACK);

  const [expanded, setExpanded] = useState<DrawerItem>(null);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const drawerItemList = (): DrawerListItem[] => [
    {
      title: 'Drawing Tools',
      key: 'draw',
      icon: <EditIcon />
    },
    {
      title: 'Arrows & Lines',
      key: 'arrow',
      icon: <NorthEastIcon />
    },
    {
      title: 'Valorant Items',
      key: 'valorant',
      icon: <LogoValorantV sx={{ width: 22, height: 22, ml: '-2px' }} />
    },
    {
      title: 'Map',
      key: 'map',
      icon: <MapIcon />
    }
  ];

  const handleListItemClick = (value: DrawerItem) => (event) => {
    if (event.type === 'click') {
      if (value === 'draw') {
        if (event.currentTarget.value === BoardTools.DRAW) {
          store.dispatch(setSelectedTool(BoardTools.SELECT));
        } else {
          store.dispatch(setSelectedTool(BoardTools.DRAW));
        }
      } else if (value === 'arrow') {
        store.dispatch(setSelectedTool(BoardTools.ARROW));
      } else {
        store.dispatch(setSelectedTool(BoardTools.SELECT));
      }

      if (expanded === value && drawerOpen) {
        setDrawerOpen(false);
      } else {
        setDrawerOpen(true);
      }

      setExpanded(value);
    }
  };

  const { selectedTool } = useSelector(
    (state: RootState) => state.boardSlice.ui,
    shallowEqual
  );

  const handleNonDrawerClick = (tool: BoardTools) => () => {
    setDrawerOpen(false);
    store.dispatch(setSelectedTool(tool));
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          height: toolsPanelHeight,
          top: `calc( 

            calc(50% - ${toolsPanelHeight / 2 + 50}px )

          )`,
          width: 50,
          position: 'absolute',
          zIndex: 1100,
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: `${theme.shape.borderRadius}px`,
          margin: theme.spacing(2),
          boxShadow: theme.shadows[19]
        }}
      >
        <CssBaseline />
        <Paper
          square
          sx={{
            backgroundColor: hexToRGB(
              theme.palette.primary.main,
              customOpacityDarkThemeBoard.toString()
            ),
            width: 48,
            borderRadius: `${theme.shape.borderRadius}px`,
            overflow: 'hidden'
          }}
        >
          <List sx={{ pt: 0 }}>
            <CustomTooltip title="Select" placement="right">
              <ToggleButton
                value={BoardTools.SELECT}
                onClick={handleNonDrawerClick(BoardTools.SELECT)}
                selected={selectedTool === BoardTools.SELECT}
                sx={{ border: 0, margin: theme.spacing(0.5) }}
              >
                <NearMeIcon style={{ fill: '#d9d9d9 ' }} />
              </ToggleButton>
            </CustomTooltip>

            <CustomTooltip
              title="Pan"
              subtitle="Space + Drag"
              placement="right"
            >
              <ToggleButton
                value={BoardTools.PAN}
                onClick={handleNonDrawerClick(BoardTools.PAN)}
                selected={selectedTool === BoardTools.PAN}
                sx={{ border: 0, margin: theme.spacing(0.5) }}
              >
                <PanToolIcon style={{ fill: '#d9d9d9 ' }} />
              </ToggleButton>
            </CustomTooltip>

            <CustomTooltip
              title="Text Field"
              subtitle="CTRL +  T"
              placement="right"
            >
              <ToggleButton
                value={BoardTools.PAN}
                onClick={handleNonDrawerClick(BoardTools.TEXT)}
                selected={selectedTool === BoardTools.TEXT}
                sx={{ border: 0, margin: theme.spacing(0.5) }}
              >
                <TextFieldsIcon style={{ fill: '#d9d9d9 ' }} />
              </ToggleButton>
            </CustomTooltip>

            <CustomTooltip
              title="Eraser"
              subtitle="CTRL +  E"
              placement="right"
            >
              <ToggleButton
                value={BoardTools.PAN}
                onClick={handleNonDrawerClick(BoardTools.ERASE)}
                selected={selectedTool === BoardTools.ERASE}
                sx={{ border: 0, margin: theme.spacing(0.5), marginBottom: 0 }}
              >
                <EraserIcon
                  style={{
                    fill: '#d9d9d9'
                  }}
                />
              </ToggleButton>
            </CustomTooltip>

            {drawerItemList().map(
              (option): React.ReactElement => (
                <div
                  key={option.key}
                  style={{
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1)
                  }}
                >
                  <CustomTooltip title={option.title} placement="left">
                    <ListItemButton
                      onClick={handleListItemClick(option.key)}
                      selected={
                        (option.key === expanded && drawerOpen) ||
                        selectedTool === option.key
                      }
                      sx={{
                        height: '40px',
                        maxHeight: '40px',
                        margin: theme.spacing(0.5),
                        borderRadius: `${theme.shape.borderRadius}px`,
                        p: '11px'
                      }}
                    >
                      {option.icon}
                    </ListItemButton>
                  </CustomTooltip>
                </div>
              )
            )}

            <Divider sx={{ mb: 1 }} />

            <SaveButton key="save-button" />

            <ClearButton key="clear-button" />
          </List>
        </Paper>

        <Fade in={drawerOpen && expanded !== null}>
          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              border: `1px solid ${theme.palette.divider}`,
              borderRadius: `${theme.shape.borderRadius}px`,
              position: 'absolute',
              height: 'auto',
              maxHeight: determineDrawerLength(verticalBreakPoint) + 2,
              left: `calc(${50}px + ${theme.spacing(2)})`,
              top: toolsPanelHeight / 2,
              transform: 'translate(0, -50%)',
              overflowX: 'hidden',
              overflowY: 'auto',
              boxShadow: theme.shadows[19]
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                backgroundColor: hexToRGB(
                  theme.palette.primary.main,
                  customOpacityDarkThemeBoard.toString()
                )
              }}
            >
              {drawerOpen && expanded === 'draw' && (
                <Box
                  sx={{
                    width: normalDrawerWidth
                  }}
                >
                  <CustomDrawerHeader
                    title="Drawing Tools"
                    onClose={() => setDrawerOpen(false)}
                    theme={theme}
                  />

                  <Box sx={{ px: 2 }}>
                    <DrawingDrawer />
                  </Box>
                </Box>
              )}

              {drawerOpen && expanded === 'arrow' && (
                <Box
                  sx={{
                    width: normalDrawerWidth
                  }}
                >
                  <CustomDrawerHeader
                    title="Arrow & Lines"
                    onClose={() => setDrawerOpen(false)}
                    theme={theme}
                  />

                  <Box sx={{ px: 2 }}>
                    <ArrowDrawer />
                  </Box>
                </Box>
              )}

              {drawerOpen && expanded === 'valorant' && (
                <Box
                  sx={{
                    width: valorantItemsDrawerWidth
                  }}
                >
                  <CustomDrawerHeader
                    title="Valorant Items"
                    theme={theme}
                    onClose={() => setDrawerOpen(false)}
                  />

                  <Box sx={{ px: 2 }}>
                    <ValorantItemsDrawer
                      attack={attack}
                      setAttack={setAttack}
                    />
                  </Box>
                </Box>
              )}
              {drawerOpen && expanded === 'map' && (
                <Box sx={{ width: mapsDrawerWidth }}>
                  <CustomDrawerHeader
                    theme={theme}
                    title="Maps"
                    onClose={() => setDrawerOpen(false)}
                  />

                  <Box sx={{ px: 2 }}>
                    <MapDrawer />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Fade>
      </Box>
    </>
  );
};

export default ToolsDrawer;
