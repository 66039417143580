import { Box, ClickAwayListener } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import CustomTooltip from 'src/components/CustomTooltip';
import {
  addCommand,
  updateSelectedConnections
} from 'src/slices/board/boardModel';
import { setCursorState, setSelectedObject } from 'src/slices/board/boardUi';
import store, { RootState, useSelector } from 'src/store';
import {
  ActionTypes,
  BoardTools,
  DragTypes,
  IAreaOfEffect,
  ICommand,
  ICommandParams,
  IObject,
  IPoint
} from 'src/types/board';
import determineObjectTitle from 'src/utils/boardUtils/determineObjectTitle';
import { relativeCoordinatesForMouseEvent } from 'src/utils/boardUtils/relativeCoordinatesForEvent';
import createResourceId from 'src/utils/createResourceId';
import AbilityMenu from '../tools/object/AbilityMenu';
import AreaOfEffectTransformer from '../tools/object/AreaOfEffectTransformer';
import ObjectMenu, { menuButtonClass } from '../tools/object/ObjectMenu';
import TextMenu from '../tools/textArea/TextMenu';
import ImgAvatar from './ImgAvatar';
import SvgAvatar from './SvgAvatar';
import TextArea from './TextArea';

interface IProps {
  object: IObject;
  wrapperScale: number;
  wrapperComponent: HTMLDivElement;
  wrapperPoint: IPoint;
  mapRotation: number;
}

const DrawingObject: FC<IProps> = (props: IProps) => {
  const { object, wrapperScale, wrapperComponent, wrapperPoint, mapRotation } =
    props;
  const { id, type, position, connections } = object;
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<IPoint>(position);
  const { playId, teamId } = useParams();
  const sessionId = teamId ? playId : null;

  const [edit, setEdit] = useState<boolean>(false);
  const [menuPosition, setMenuPosition] = useState<'top' | 'bottom'>('top');
  const { selectedTool, selectedObject, visibility } = useSelector(
    (state: RootState) => state.boardSlice.ui,
    shallowEqual
  );
  const { connectionsVisible } = visibility;
  const selected = selectedObject === id;
  const nodeRef = useRef<HTMLDivElement>(null);
  const renderTransformer = object.areaOfEffect !== undefined;
  const transformerHidden = object.areaOfEffect && object.areaOfEffect.hidden;

  useEffect(() => {
    if (!selected) {
      setEdit(false);
    }
  }, [selected]);

  useEffect(() => {
    if (position !== coordinates) {
      setCoordinates(position);
    }
  }, [position]);

  const handleDragStart = () => {
    if (selectedTool === BoardTools.SELECT) {
      setIsDragging(true);
      store.dispatch(setCursorState(ActionTypes.CHANGE_OBJECT));
    }
  };

  const handleDrag = (event) => {
    const point = relativeCoordinatesForMouseEvent(
      event,
      wrapperPoint.x,
      wrapperPoint.y,
      wrapperScale,
      mapRotation
    );

    if (selectedTool === BoardTools.SELECT) {
      if (connections?.length > 0 && connectionsVisible) {
        const tempObject: IObject = {
          ...object,
          position: {
            x: point?.x,
            y: point?.y
          }
        };

        store.dispatch(updateSelectedConnections(tempObject));
      }
      setCoordinates(point);
    }
  };

  const handleDragStop = (event, data) => {
    if (selectedTool === BoardTools.SELECT) {
      if (position?.x !== data?.x || position?.y !== data?.y) {
        const point = relativeCoordinatesForMouseEvent(
          event,
          wrapperPoint.x,
          wrapperPoint.y,
          wrapperScale,
          mapRotation
        );

        const newObj: IObject = {
          ...object,
          position: {
            x: point?.x,
            y: point?.y
          }
        };

        const oldObj: IObject = {
          ...object
        };
        const command: ICommand = {
          id: createResourceId(),
          type: ActionTypes.CHANGE_OBJECT,
          params: {
            id,
            value: {
              newObj,
              oldObj
            },
            stepLayer: store.getState().boardSlice.model.activeStep,
            sessionId,
            playId
          } as ICommandParams,
          inverse: ActionTypes.REVERT_OBJECT
        };

        setCoordinates(point);
        store.dispatch(addCommand(command));
        store.dispatch(setCursorState(null));
      }
    }
    setIsDragging(false);
  };

  const updateAreaOfEffect = (newAreaOfEffect: IAreaOfEffect) => {
    const newObj: IObject = {
      ...object,
      areaOfEffect: newAreaOfEffect
    };

    const oldObj: IObject = {
      ...object
    };

    const command: ICommand = {
      id: createResourceId(),
      type: ActionTypes.CHANGE_OBJECT,
      params: {
        id,
        value: {
          newObj,
          oldObj
        },
        stepLayer: store.getState().boardSlice.model.activeStep,
        sessionId,
        playId
      } as ICommandParams,
      inverse: ActionTypes.REVERT_OBJECT
    };

    store.dispatch(addCommand(command));
  };

  const handleMouseDown = () => {
    if (selectedTool === BoardTools.SELECT) {
      store.dispatch(setSelectedObject(object.id));
      // close object menu when already selected
    }
  };

  const handleObjectClick = (event: any) => {
    if (
      (event.target.classList.contains('cb-draggable-object') ||
        event.target.classList.contains('cb-aoe-svg') ||
        event.target.classList.contains('cb-aoe-transformer')) &&
      selected
    ) {
      store.dispatch(setSelectedObject(null));
    } else if (selectedTool === BoardTools.SELECT) {
      store.dispatch(setSelectedObject(object.id));

      // close object menu when already selected
    }
  };

  const handleEditClick = () => {
    if (selected) {
      setEdit(true);
    }
  };

  const renderChild = (ref: React.MutableRefObject<HTMLDivElement>) => {
    if (type === DragTypes.TEXTAREA) {
      return (
        <TextArea
          ref={ref}
          onEditClick={handleEditClick}
          selected={selected}
          object={object}
          edit={edit}
          isDragging={isDragging}
          wrapperScale={wrapperScale}
          mapRotation={mapRotation}
        />
      );
    }
    if (type === DragTypes.AGENT) {
      return (
        <CustomTooltip
          title={selected ? '' : determineObjectTitle(object)}
          placement="top"
          transitionProps={{ timeout: { enter: 150, exit: 50 } }}
        >
          <ImgAvatar
            ref={ref}
            object={object}
            selected={selected}
            isDragging={isDragging}
            wrapperScale={wrapperScale}
            mapRotation={mapRotation}
          />
        </CustomTooltip>
      );
    }
    return (
      <CustomTooltip
        title={selected ? '' : determineObjectTitle(object)}
        placement="top"
        transitionProps={{ timeout: { enter: 200, exit: 50 } }}
      >
        <SvgAvatar
          ref={ref}
          object={object}
          selected={selected}
          isDragging={isDragging}
          wrapperScale={wrapperScale}
          mapRotation={mapRotation}
        />
      </CustomTooltip>
    );
  };
  const handleClickAway = (event: any) => {
    // check for selected, so that only the selected objects clickAway listener is checked
    if (event.target.classList.contains('cb-object-layer') && selected) {
      store.dispatch(setSelectedObject(null));
    }
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Draggable
          enableUserSelectHack={false}
          defaultClassName="cb-draggable"
          cancel={`.cb-textinput, button.${menuButtonClass}, div.cb-ability-menu, div.cb-aoe-transformer`}
          bounds="parent"
          defaultPosition={coordinates}
          axis="none"
          position={{
            x: coordinates?.x,
            y: coordinates?.y
          }}
          disabled={selectedTool !== BoardTools.SELECT || isDragging}
          onStart={handleDragStart}
          onDrag={handleDrag}
          onStop={handleDragStop}
          scale={wrapperScale}
          offsetParent={wrapperComponent}
          onMouseDown={handleMouseDown}
        >
          <Box
            className="cb-draggable-object"
            onClick={handleObjectClick}
            sx={{
              position: 'absolute'
            }}
          >
            <AreaOfEffectTransformer
              objectID={object.id}
              renderTransformer={renderTransformer}
              transformerHidden={transformerHidden}
              child={renderChild(nodeRef)}
              selected={selected}
              isDragging={isDragging}
              wrapperScale={wrapperScale}
              mapRotation={mapRotation}
              menuPosition={menuPosition}
              setMenuPosition={setMenuPosition}
              targetObjectValue={object.value}
              updateAreaOfEffect={updateAreaOfEffect}
            />
          </Box>
        </Draggable>
      </ClickAwayListener>
      {selected && !edit && !isDragging && type !== DragTypes.AGENT && (
        <ObjectMenu
          object={object}
          open={selected && !edit}
          anchorEl={nodeRef.current}
          menuPosition={menuPosition}
        />
      )}

      {selected && !edit && !isDragging && type === DragTypes.AGENT && (
        <AbilityMenu
          object={object}
          open={selected && !edit}
          anchorEl={nodeRef.current}
          menuPosition={menuPosition}
        />
      )}

      {edit && <TextMenu object={object} anchorEl={nodeRef.current} />}
    </>
  );
};

export default DrawingObject;
