// Colors

import hexToRGB from 'src/utils/hexToRGB';

const neutral = {
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D1D5DB',
  400: '#989fab',
  500: '#6B7280',
  600: '#4B5563',
  700: '#374151',
  800: '#1F2937',
  900: '#111827'
};

const background = {
  paper: '#ffffff',
  default: '#ffffff'
};

const divider = '#edf1f7';

const primary = {
  main: '#0d0f12',
  light: '#1e252e',
  dark: '#0d1014',
  contrastText: '#f3f3f3'
};

const secondary = {
  main: '#10B981',
  light: '#3FC79A',
  dark: '#0B815A',
  contrastText: '#f3f3f3'
};

const success = {
  main: '#14B8A6',
  light: '#43C6B7',
  dark: '#0E8074',
  contrastText: '#f3f3f3'
};

const info = {
  main: '#2196F3',
  light: '#64B6F7',
  dark: '#0B79D0',
  contrastText: '#f3f3f3'
};

const warning = {
  main: '#FFB020',
  light: '#FFBF4C',
  dark: '#B27B16',
  contrastText: '#f3f3f3'
};

const error = {
  main: '#D14343',
  light: '#DA6868',
  dark: '#922E2E',
  contrastText: '#f3f3f3'
};

const text = {
  primary: '#1c1c1c',
  secondary: '#9c9ca6',
  disabled: 'rgba(55, 65, 81, 0.48)'
};

export const lightThemeOptions = {
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: '#67676e',
          color: '#FFFFFF'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-outlined': {
            backgroundColor: background.paper
          }
        },
        contained: {
          '&:hover': {
            backgroundColor: hexToRGB(primary.main, '0.7')
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-filledDefault': {
            backgroundColor: neutral[200],
            '& .MuiChip-deleteIcon': {
              color: neutral[400]
            }
          },
          '&.MuiChip-outlinedDefault': {
            '& .MuiChip-deleteIcon': {
              color: neutral[300]
            }
          }
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          borderColor: divider,

          '& .MuiDataGrid-row': {
            border: 0,
            backgroundColor: background.default,
            '&:nth-of-type(2n)': {
              backgroundColor: primary.contrastText
            }
          },
          '& .MuiDataGrid-cell': {
            border: 0
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            color: `${neutral[400]} !important`
          },
          '& .MuiDataGrid-columnsContainer': {
            borderBottom: `1px solid ${divider}`
          },
          '& .MuiDataGrid-columnSeparator': {
            color: neutral[300]
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: `1px solid ${divider}`
          },

          '& .MuiDataGrid-checkboxInput': {
            color: `${primary.main} !important`
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: primary.main
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: text.secondary
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: divider
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: 'solid',
          borderWidth: 1
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: 'solid',
          borderWidth: 1
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: neutral[500]
        },
        track: {
          backgroundColor: neutral[400],
          opacity: 1
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${divider}`
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[100],
          '.MuiTableCell-root': {
            color: neutral[700]
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: text.primary,
          '&.MuiTypography-subtitle1': {
            color: neutral[400]
          },
          '&.MuiTypography-subtitle2': {
            color: neutral[400]
          },
          '&.MuiTypography-body1': {
            color: neutral[600]
          },
          '&.MuiTypography-body2': {
            color: neutral[600]
          },
          '&.MuiTypography-caption': {
            color: neutral[600]
          },
          '&.MuiTypography-overline': {
            color: neutral[600]
          }
        }
      }
    }
  },
  palette: {
    action: {
      active: neutral[500],
      focus: 'rgba(55, 65, 81, 0.12)',
      hover: 'rgba(55, 65, 81, 0.04)',
      selected: 'rgba(55, 65, 81, 0.06)',
      disabledBackground: 'rgba(55, 65, 81, 0.12)',
      disabled: 'rgba(55, 65, 81, 0.26)'
    },
    background,
    divider,
    error,
    info,
    mode: 'light',
    neutral,
    primary,
    secondary,
    success,
    text,
    warning
  },
  shadows: [
    '0px 0px 60px 0px rgba(0, 0, 0, 0.0)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    // google login button
    '0px 2px 10px 0px rgba(145, 158, 171, 0.1),0px 10px 14px 1px rgba(145, 158, 171, 0.0),0px 4px 18px 3px rgba(145, 158, 171, 0.0)',
    '0px 2px 10px 0px rgba(145, 158, 171, 0.35),0px 10px 14px 1px rgba(145, 158, 171, 0.0),0px 4px 18px 3px rgba(145, 158, 171, 0.0)',
    // for navbar
    '0px 0px 10px 0px rgba(145, 158, 171, 0.2),0px 10px 14px 1px rgba(145, 158, 171, 0.0),0px 4px 18px 3px rgba(145, 158, 171, 0.0)',
    '0px 6px 6px -3px rgba(145, 158, 171, 0.2),0px 10px 14px 1px rgba(145, 158, 171, 0.14),0px 4px 18px 3px rgba(145, 158, 171, 0.12)',
    // change opacity here to make paper components pop out
    '0px 0px 60px 0px rgba(0, 0, 0, 0.0)'
  ]
};
