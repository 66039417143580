import PropTypes from 'prop-types';
import { FC, ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { startPlaybookListener, terminatePlaybookListener } from 'src/data';

interface PlaybookGuardProps {
  children?: ReactNode;
}

const PlaybookGuard: FC<PlaybookGuardProps> = ({ children }) => {
  const { playbookId } = useParams();
  const { teamId } = useParams();

  useEffect(
    () => () => {
      terminatePlaybookListener();
    },
    []
  );

  if (playbookId) {
    startPlaybookListener(playbookId, teamId);
  }
  return <>{children}</>;
};

PlaybookGuard.propTypes = {
  children: PropTypes.node
};

export default PlaybookGuard;
