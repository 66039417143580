/* eslint-disable @typescript-eslint/no-explicit-any */
const arrContainsObjWithID = (
  arr: any[],
  uniqueIdentifierKey: string,
  uniqueIdentifier: string,
  returnType: 'boolean' | 'object'
): any => {
  const result = arr.filter(
    (item) => item[uniqueIdentifierKey] === uniqueIdentifier
  );

  if (returnType === 'boolean') {
    if (result.length === 0) {
      return false;
    }
    return true;
  }

  if (returnType === 'object') {
    if (result.length === 0) {
      return null;
    }
    return result[0];
  }
  return undefined;
};

export default arrContainsObjWithID;
