import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'src/store';

const useCommandsFuture = (): string[] => {
  const commandsFuture: string[] = useSelector((state: RootState) => {
    const { activeStep } = state.boardSlice.model;
    return state.boardSlice.model.stepLayers[activeStep]?.commandsFuture;
  }, shallowEqual);

  return commandsFuture;
};

export default useCommandsFuture;
