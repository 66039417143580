import LocationOnIcon from '@mui/icons-material/LocationOn';
import type { Theme } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import type { SxProps } from '@mui/system';
import type { FC } from 'react';
import { OBJECT_SIZE } from 'src/constants';

interface IProps {
  sx?: SxProps<Theme>;
}

const PinIcon: FC<IProps> = (props: SvgIconProps) => (
  <LocationOnIcon
    {...props}
    sx={{
      color: '#E5E7EB',
      opacity: 0.9,
      width: OBJECT_SIZE - 10,
      height: OBJECT_SIZE - 10
    }}
  />
);

export default PinIcon;
