import type { FC } from 'react';
import { Container, DialogContent, Grid } from '@mui/material';
import CustomDialogHeader from 'src/components/CustomDialogHeader';
import BugsContact from './BugsContact';

interface IProps {
  onClose: () => void;
}

const BugsDialogContent: FC<IProps> = (props: IProps) => {
  const { onClose } = props;

  return (
    <Grid
      container
      sx={{
        width: '100%',
        height: '100%',
        p: 2,
        maxHeight: '750px'
      }}
    >
      <Grid item xs={12}>
        <BugsContact onClose={onClose} />
      </Grid>
    </Grid>
  );
};

export default BugsDialogContent;
