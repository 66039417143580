import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PersonIcon from '@mui/icons-material/Person';
import SecurityIcon from '@mui/icons-material/Security';
import PaymentIcon from '@mui/icons-material/Payment';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { appSidebarWidth } from 'src/constants';
import { RootState, useSelector } from 'src/store';
import { AccountData } from 'src/types/user';
import useAuth from '../../hooks/useAuth';
import CustomDialog from '../CustomDialog';
import { AccountSettingsDialog } from './account';
import CustomAvatar from './CustomAvatar';

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { playId, teamId } = useParams();
  const theme = useTheme();
  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );
  const { user, logout } = useAuth();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [accountSettingsDialogOpen, setAccountSettingsDialogOpen] =
    useState(false);

  const [accountSettingsDialogTab, setAccountSettingsDialogTab] =
    useState('general');

  const handleOpenAccountSettingsDialogGeneral = () => {
    setAccountSettingsDialogTab('general');

    setAccountSettingsDialogOpen(true);
  };

  const handleOpenAccountSettingsDialogSecurity = () => {
    setAccountSettingsDialogTab('security');

    setAccountSettingsDialogOpen(true);
  };

  const handleOpenAccountSettingsDialogSubscription = () => {
    setAccountSettingsDialogTab('subscription');

    setAccountSettingsDialogOpen(true);
  };

  const [signOutDialogOpen, setSignOutDialogOpen] = useState(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout(teamId && playId ? playId : null);
      navigate('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  if (!user) {
    return null;
  }

  return (
    <>
      <Button
        sx={{
          px: 0,
          borderRadius: '0px !important',
          width: '100%',
          height: '58px',
          backgroundColor: theme.palette.action.active,
          borderTop: `1px solid ${theme.palette.divider}`
        }}
        ref={anchorRef}
        onClick={handleOpen}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: '100%',
            height: '100%',
            px: 2
          }}
        >
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              width: '100%',
              height: 'auto'
            }}
          >
            <CustomAvatar src={user.photoURL} isTeamAvatar={false} />
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              sx={{
                flexGrow: 1,
                maxWidth: 'calc(100% - 51px)',
                pl: 1.5,
                pr: 0.5,
                height: '100%'
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  textTransform: 'none',
                  color: `${theme.palette.text.primary} !important`,
                  fontWeight: 500
                }}
              >
                {user.displayName}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  textTransform: 'none',
                  color: `${theme.palette.text.secondary} !important`
                }}
              >
                {accountData?.username}
              </Typography>
            </Grid>
            <ArrowForwardIosIcon
              sx={{ fill: theme.palette.text.secondary, fontSize: 14 }}
            />
          </Grid>
        </Grid>
      </Button>

      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: appSidebarWidth,
            mt: 1
          }
        }}
      >
        <Box sx={{ p: 3 }}>
          <Typography color="textPrimary" variant="h5" sx={{ fontWeight: 600 }}>
            {user.displayName}
          </Typography>
          <Typography color="textSecondary" variant="subtitle2">
            {accountData ? accountData?.username : null}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem
            sx={{ mb: 1, px: 3 }}
            onClick={() => {
              handleClose();
              handleOpenAccountSettingsDialogGeneral();
            }}
          >
            <ListItemIcon>
              <PersonIcon
                fontSize="small"
                sx={{ color: theme.palette.text.secondary }}
              />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">Profile</Typography>}
            />
          </MenuItem>
          <MenuItem
            sx={{ mb: 1, px: 3 }}
            onClick={() => {
              handleClose();
              handleOpenAccountSettingsDialogSecurity();
            }}
          >
            <ListItemIcon>
              <SecurityIcon
                fontSize="small"
                sx={{ color: theme.palette.text.secondary }}
              />
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body1">Security</Typography>}
            />
          </MenuItem>
          {!teamId && (
            <MenuItem
              sx={{ mb: 1, px: 3 }}
              onClick={() => {
                handleClose();
                handleOpenAccountSettingsDialogSubscription();
              }}
            >
              <ListItemIcon>
                <PaymentIcon
                  fontSize="small"
                  sx={{ color: theme.palette.text.secondary }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography color="textPrimary" variant="body1">
                    Subscription
                  </Typography>
                }
              />
            </MenuItem>
          )}
        </Box>
        <Box sx={{ px: 3, pb: 3, pt: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={() => {
              handleClose();
              setSignOutDialogOpen(true);
            }}
            variant="text"
            sx={{}}
          >
            Sign out
          </Button>
        </Box>
      </Popover>

      {accountSettingsDialogOpen &&
        accountSettingsDialogTab === 'subscription' && (
          <AccountSettingsDialog
            initialTab="subscription"
            open={accountSettingsDialogOpen}
            setDialogOpen={setAccountSettingsDialogOpen}
          />
        )}

      {accountSettingsDialogOpen && accountSettingsDialogTab === 'security' && (
        <AccountSettingsDialog
          initialTab="security"
          open={accountSettingsDialogOpen}
          setDialogOpen={setAccountSettingsDialogOpen}
        />
      )}

      {accountSettingsDialogOpen && accountSettingsDialogTab === 'general' && (
        <AccountSettingsDialog
          initialTab="general"
          open={accountSettingsDialogOpen}
          setDialogOpen={setAccountSettingsDialogOpen}
        />
      )}

      <CustomDialog
        open={signOutDialogOpen}
        setOpen={setSignOutDialogOpen}
        onClose={() => setSignOutDialogOpen(false)}
        title="Sign out"
        width={350}
        buttons={[
          {
            variant: 'text',
            title: 'Cancel',
            onClick: () => setSignOutDialogOpen(false)
          },
          {
            variant: 'contained',
            title: 'Sign out',
            onClick: handleLogout
          }
        ]}
      >
        <Grid container sx={{ p: 1 }}>
          <Grid item>
            <Typography variant="body1" color="textSecondary">
              Would you like to log out ? Alternatively you can stay logged in
              to retain quick access to your critbase-spaces.{' '}
            </Typography>
          </Grid>
        </Grid>
      </CustomDialog>
    </>
  );
};

export default AccountPopover;
