import { cloneDeep } from 'lodash';
import { FC } from 'react';
import { shallowEqual } from 'react-redux';
import useObjects from 'src/hooks/useObjects';
import * as maps from 'src/ressources/maps';

import { RootState, useSelector } from 'src/store';
import {
  DragTypes,
  IBoardVisibility,
  IObject,
  IObjects
} from 'src/types/board';
import determineAreaOfEffect from 'src/utils/boardUtils/determineAreaOfEffect';
import AreaOfEffectDisplay from '../tools/object/AreaOfEffectDisplay';

const AreasOfEffectLayer: FC<{}> = () => {
  const { visibility, selectedObject } = useSelector(
    (state: RootState) => state.boardSlice.ui,
    shallowEqual
  );

  const { map } = useSelector(
    (state: RootState) => state.boardSlice.model,
    shallowEqual
  );

  const { areasOfEffectVisible }: IBoardVisibility = visibility;

  const objects: IObjects = useObjects();
  const filteredObjects = Object.fromEntries(
    Object.entries(cloneDeep(objects)).filter(
      ([, val]) => val.type !== DragTypes.TEXTAREA
    )
  );
  // const { areasOfEffectVisible }: IBoardVisibility = visibility;

  const renderAOE = (object: IObject, masked: boolean): boolean => {
    const aoe = determineAreaOfEffect(object.value);

    if (masked) {
      return (
        aoe &&
        aoe.masked &&
        object &&
        object.areaOfEffect &&
        !object.areaOfEffect.hidden &&
        selectedObject !== object.id
      );
    }

    return (
      aoe &&
      !aoe.masked &&
      object &&
      object.areaOfEffect &&
      !object.areaOfEffect.hidden &&
      selectedObject !== object.id
    );
  };

  const selectMask = () => maps[map].mask;

  return (
    <>
      <div
        className="cb-aoe-layer-masked"
        style={{
          width: '100%',
          height: '100%',
          WebkitMaskImage: !selectMask() ? undefined : `url(${selectMask()})`,
          maskImage: !selectMask() ? undefined : `url(${selectMask()})`,
          WebkitMaskRepeat: 'no-repeat',
          maskRepeat: 'no-repeat',
          maskPosition: 'center center',
          WebkitMaskPosition: 'center center',
          maskSize: '100% 100%',
          WebkitMaskSize: '100% 100%'
        }}
      >
        {areasOfEffectVisible &&
          filteredObjects &&
          Object.entries(filteredObjects).map(
            ([key, object]) =>
              renderAOE(object, true) && (
                <AreaOfEffectDisplay
                  key={key}
                  targetObjectPosition={object.position}
                  targetObjectValue={object.value}
                  targetObjectAOE={object.areaOfEffect}
                />
              )
          )}
      </div>
      <div
        className="cb-aoe-layer-unmasked"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0
        }}
      >
        {areasOfEffectVisible &&
          filteredObjects &&
          Object.entries(filteredObjects).map(
            ([key, object]) =>
              renderAOE(object, false) && (
                <AreaOfEffectDisplay
                  key={key}
                  targetObjectPosition={object.position}
                  targetObjectValue={object.value}
                  targetObjectAOE={object.areaOfEffect}
                />
              )
          )}
      </div>
    </>
  );
};

export default AreasOfEffectLayer;
