import DotIcon from '@mui/icons-material/FiberManualRecord';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useTheme
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  addContact,
  getContactInLists,
  removeContact
} from 'src/api/marketing/send-grid';
import { sendGridConfig } from 'src/config';
import {
  trackSubscribeToNewsletter,
  trackUnsubscribeFromNewsletter
} from 'src/data/mixpanel/setters/trackEvent';
import useAuth from 'src/hooks/useAuth';
import SnackbarUtils from 'src/utils/snackbar/SnackbarUtils';
import SettingsSection from '../SettingsSection';

const AccountOtherSettings: FC = () => {
  const { user } = useAuth();
  const [subscribed, setSubscribed] = useState<boolean>(null);
  const [subscriptionLoading, setSubscriptionLoading] =
    useState<boolean>(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const loadNewsletterSubscription = async () => {
    setSubscriptionLoading(true);
    const [, body] = await getContactInLists(user.email, [
      sendGridConfig.newsletterId
    ]);

    if (body?.result.length > 0) {
      setSubscribed(true);
    }
    setSubscriptionLoading(false);
  };

  const handleChange = async (event) => {
    const { checked } = event.target;
    setSubscriptionLoading(true);
    if (checked) {
      // add to contact list
      // setLoading

      // add user to newsletter group
      try {
        await addContact(user.email, [sendGridConfig.newsletterId]);

        setSubscribed(true);

        trackSubscribeToNewsletter({});

        // snackbar
        SnackbarUtils.success('You successfully subscribed to the newsletter.');
      } catch (err) {
        // snackbar
        SnackbarUtils.error(
          'Subscribing to the newsletter failed. Please try again or reach out to the development team.'
        );

        // do nothing
      }
    } else {
      // remove from contact list
      try {
        // get subscription id
        const [, body] = await getContactInLists(user.email, [
          sendGridConfig.newsletterId
        ]);

        const subscriptionId = body?.result[0].id;

        await removeContact(subscriptionId, sendGridConfig.newsletterId);
        setSubscribed(false);

        trackUnsubscribeFromNewsletter({});

        // snackbar
        SnackbarUtils.success(
          'You successfully unsubscribed from the newsletter.'
        );
      } catch (err) {
        // snackbar
        SnackbarUtils.error(
          'Usubscribing from the newsletter failed. Please try again in a few minutes or reach out to the development team.'
        );

        // do nothing
      }
    }
    setSubscriptionLoading(false);
  };

  useEffect(() => {
    loadNewsletterSubscription();
  }, []);

  return (
    <Grid
      container
      columnSpacing={6}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item container xs={6} rowSpacing={4}>
        <Grid item xs={12}>
          <SettingsSection title="delete account">
            <Typography
              color="textSecondary"
              sx={{ mt: 1, mb: 1 }}
              variant="body2"
            >
              This is where you can delete your account if you intend to do so.
              Before you continue, however, you should keep in mind that by
              deleting the account, all existing data related to this account
              will be gone forever. This includes your: <br />
            </Typography>
            <Box sx={{ p: 1.5, width: '100%' }}>
              {[
                'Plays',
                'Playbooks',
                'uploaded Images',
                'Team Memberships'
              ].map((textItem) => (
                <Grid
                  key={textItem}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <DotIcon
                      sx={{
                        width: '6px',
                        height: 'auto',
                        fill: theme.palette.text.secondary
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      sx={{ pl: 1 }}
                    >
                      {textItem}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Box>
            <Typography color="textSecondary" variant="body2">
              This step is not reversible. Delete your account only if you are
              absolutely sure!
            </Typography>
          </SettingsSection>
        </Grid>

        <Grid item xs={12}>
          <Divider />

          <Button
            variant="contained"
            sx={{
              backgroundColor: `${theme.palette.error.main}`,

              mt: 2,
              '&:hover': {
                backgroundColor: `${theme.palette.error.dark}`
              }
            }}
            type="button"
            onClick={() => navigate('/delete-account')}
          >
            Delete Account
          </Button>
        </Grid>
      </Grid>

      <Grid item container xs={6} rowSpacing={4}>
        <Grid item xs={12}>
          <SettingsSection title="newsletter">
            <Grid item>
              {subscriptionLoading ? (
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    pb: 2,
                    pt: 2.5,
                    pl: 2
                  }}
                >
                  <CircularProgress size={20} />
                </Box>
              ) : (
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    pb: 1,
                    pl: 1,
                    pt: 1
                  }}
                >
                  <Checkbox
                    checked={subscribed}
                    disabled={subscriptionLoading}
                    color="secondary"
                    name="newsletter"
                    onChange={handleChange}
                    sx={{ mr: 1 }}
                  />

                  <Typography
                    sx={{ mb: 0, fontWeight: 500 }}
                    gutterBottom
                    variant="h5"
                  >
                    {subscribed
                      ? 'You are subscribed to our newsletter'
                      : 'Check this box to subscribe to our newsletter.'}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary" sx={{ mb: 2 }} variant="body2">
                Subscribing and unsubscribing may take a few minutes to be
                displayed correctly. You need to wait a few minutes to
                unsubscribe after recently subscribing.
              </Typography>
            </Grid>
          </SettingsSection>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccountOtherSettings;
