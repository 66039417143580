import {
  Box,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Tab,
  Tabs,
  useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import { ChangeEvent, FC, useState } from 'react';
import { PlaybookInfo } from 'src/types/play';
import CustomDialogHeader from '../../../../CustomDialogHeader';
import { PlaybookGeneralSettings, PlaybookOtherSettings } from './tabs';

const tabs = [
  { label: 'General', value: 'general' },
  { label: 'Other', value: 'other' }
];

interface PlaybookSettingsDialogProps {
  open: boolean;
  setDialogOpen: Function;
  playbook: PlaybookInfo;
}

const PlaybookSettingsDialog: FC<PlaybookSettingsDialogProps> = (props) => {
  const { open, setDialogOpen, playbook } = props;
  const [currentTab, setCurrentTab] = useState<string>('general');
  const [
    waitingForPlaybookSettingsChange,
    setWaitingForPlaybookSettingsChange
  ] = useState<boolean>(false);

  const theme = useTheme();

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    if (!waitingForPlaybookSettingsChange) {
      setCurrentTab(value);
    }
  };

  const onClose = () => {
    if (!waitingForPlaybookSettingsChange) {
      setDialogOpen(false);
      setCurrentTab('general');
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogContent sx={{ height: 'auto', maxHeight: '750px' }}>
        <Container
          sx={{
            width: '100%',
            height: '100%',
            py: 2
          }}
        >
          <CustomDialogHeader title="Playbook Settings" onClose={onClose} />

          <Box sx={{ mt: 1 }}>
            <Tabs
              indicatorColor="primary"
              onChange={handleTabsChange}
              scrollButtons="auto"
              textColor="inherit"
              value={currentTab}
              variant="scrollable"
              disabled={waitingForPlaybookSettingsChange}
              TabIndicatorProps={{
                style: {
                  backgroundColor: `${theme.palette.text.primary}`
                }
              }}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  disabled={waitingForPlaybookSettingsChange}
                />
              ))}
            </Tabs>
          </Box>
          <Divider />
          <Box sx={{ mt: 3, mb: 1 }}>
            {currentTab === 'general' && (
              <PlaybookGeneralSettings
                waitingForPlaybookSettingsChange={
                  waitingForPlaybookSettingsChange
                }
                setWaitingForPlaybookSettingsChange={
                  setWaitingForPlaybookSettingsChange
                }
                setDialogOpen={setDialogOpen}
                playbook={playbook}
              />
            )}
            {currentTab === 'other' && (
              <PlaybookOtherSettings playbook={playbook} />
            )}
          </Box>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

PlaybookSettingsDialog.propTypes = {
  open: PropTypes.bool,
  setDialogOpen: PropTypes.func,
  playbook: PropTypes.any
};

export default PlaybookSettingsDialog;
