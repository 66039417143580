import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
  useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import { FC, useRef, useState } from 'react';
import CustomTooltip from 'src/components/CustomTooltip';
import { TeamMemberData } from 'src/types/team';
import PromoteTeamMemberDialog from './PromoteTeamMemberDialog';
import RemoveTeamMemberDialog from './RemoveTeamMemberDialog';
import TeamMemberInfoDialog from './TeamMemberInfoDialog';

/* Component */

interface TeamMemberOptionsPopoverProps {
  userRole: 'Admin' | 'Member';
  targetMemberData: TeamMemberData;
  teamID: string;
  teamDisplayName: string;
}

const TeamMemberOptionsPopover: FC<TeamMemberOptionsPopoverProps> = (props) => {
  const { userRole, targetMemberData, teamID, teamDisplayName } = props;
  const [open, setOpen] = useState<boolean>(false);

  const [teamMemberInfoDialogOpen, setTeamMemberInfoDialogOpen] =
    useState<boolean>(false);

  const [removeTeamMemberDialogOpen, setRemoveTeamMemberDialogOpen] =
    useState<boolean>(false);

  const [promoteTeamMemberDialogOpen, setPromoteTeamMemberDialogOpen] =
    useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const theme = useTheme();

  const handleOpenTeamMemberInfoDialog = () => {
    setOpen(false);

    setTeamMemberInfoDialogOpen(true);
  };

  const handleOpenRemoveTeamMemberDialog = () => {
    setOpen(false);

    setRemoveTeamMemberDialogOpen(true);
  };

  const handleOpenPromoteTeamMemberDialog = () => {
    setOpen(false);

    setPromoteTeamMemberDialogOpen(true);
  };

  return (
    <>
      <CustomTooltip title="Options" placement="left">
        <IconButton
          edge="end"
          size="small"
          ref={anchorRef}
          onClick={() => setOpen(true)}
        >
          <MoreVertRoundedIcon
            sx={{
              p: '5px',
              width: 28,
              height: 'auto',
              color: theme.palette.text.secondary
            }}
          />
        </IconButton>
      </CustomTooltip>

      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        onClose={() => setOpen(false)}
        open={open}
        PaperProps={{
          sx: {
            width: 150,
            mt: 1,

            boxShadow: theme.shadows[23],
            backgroundColor:
              theme.palette.mode === 'dark'
                ? theme.palette.background.paper
                : theme.palette.background.default,
            border: `1px solid ${theme.palette.divider}`
          }
        }}
      >
        {targetMemberData.role === 'Member' && userRole === 'Admin' ? (
          <>
            <MenuItem
              key="info"
              sx={{
                px: 2
              }}
              onClick={handleOpenTeamMemberInfoDialog}
            >
              <ListItemIcon>
                <InfoOutlinedIcon
                  fontSize="small"
                  style={{ fill: `${theme.palette.text.primary}` }}
                />
              </ListItemIcon>

              <ListItemText
                primary={
                  <Typography color="textPrimary" variant="body1">
                    Info
                  </Typography>
                }
              />
            </MenuItem>
            <MenuItem
              key="promote"
              sx={{
                px: 2
              }}
              onClick={handleOpenPromoteTeamMemberDialog}
            >
              <ListItemIcon>
                <ArrowCircleUpIcon
                  fontSize="small"
                  style={{ fill: `${theme.palette.text.primary}` }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography color="textPrimary" variant="subtitle2">
                    Promote
                  </Typography>
                }
              />
            </MenuItem>
            <MenuItem
              key="remove"
              sx={{
                px: 2
              }}
              onClick={handleOpenRemoveTeamMemberDialog}
            >
              <ListItemIcon>
                <HighlightOffIcon
                  fontSize="small"
                  style={{ fill: `${theme.palette.error.main}` }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    sx={{ color: theme.palette.error.main }}
                  >
                    Remove
                  </Typography>
                }
              />
            </MenuItem>
          </>
        ) : (
          <MenuItem
            key="info"
            sx={{
              px: 2
            }}
            onClick={handleOpenTeamMemberInfoDialog}
          >
            <ListItemIcon>
              <InfoOutlinedIcon
                fontSize="small"
                style={{ fill: `${theme.palette.text.primary}` }}
              />
            </ListItemIcon>

            <ListItemText
              primary={
                <Typography color="textPrimary" variant="body2">
                  Info
                </Typography>
              }
            />
          </MenuItem>
        )}
      </Popover>

      <TeamMemberInfoDialog
        teamMemberData={targetMemberData}
        open={teamMemberInfoDialogOpen}
        setOpen={setTeamMemberInfoDialogOpen}
      />

      <RemoveTeamMemberDialog
        teamID={teamID}
        teamDisplayName={teamDisplayName}
        targetUserID={targetMemberData.id}
        targetUserDisplayName={targetMemberData.name}
        open={removeTeamMemberDialogOpen}
        setOpen={setRemoveTeamMemberDialogOpen}
      />

      <PromoteTeamMemberDialog
        teamID={teamID}
        teamDisplayName={teamDisplayName}
        targetUserID={targetMemberData.id}
        targetUserDisplayName={targetMemberData.name}
        open={promoteTeamMemberDialogOpen}
        setOpen={setPromoteTeamMemberDialogOpen}
      />
    </>
  );
};

TeamMemberOptionsPopover.propTypes = {
  userRole: PropTypes.any,
  targetMemberData: PropTypes.any,
  teamID: PropTypes.string,
  teamDisplayName: PropTypes.string
};

export default TeamMemberOptionsPopover;
