import { Suspense, lazy } from 'react';

import AuthGuard from './components/guards/AuthGuard';
import AppLayout from './components/app/AppLayout';
import GuestGuard from './components/guards/GuestGuard';
import TeamGuard from './components/guards/TeamGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import Playbooks from './pages/app/Playbooks';
import PersonalGuard from './components/guards/PersonalGuard';
import Board from './pages/app/Board';
import PlayGuard from './components/guards/PlayGuard';
import Playbook from './pages/app/Playbook';
import PlaybookGuard from './components/guards/PlaybookGuard';
import PricingTeamSpace from './pages/PricingTeamSpace';
import PricingPersonalSpace from './pages/PricingPersonalSpace';
import Announcements from './pages/Announcements';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(
  lazy(() => import('./pages/authentication/PasswordRecovery'))
);
const Register = Loadable(
  lazy(() => import('./pages/authentication/Register'))
);

const TeamInvitation = Loadable(
  lazy(() => import('./pages/authentication/TeamInviteLink'))
);

const UpdateEmail = Loadable(
  lazy(() => import('./pages/authentication/UpdateEmail'))
);

const Unsubscribe = Loadable(
  lazy(() => import('./pages/authentication/Unsubscribe'))
);

const Terms = Loadable(lazy(() => import('./pages/Terms')));

const Privacy = Loadable(lazy(() => import('./pages/Privacy')));

const VerifyEmail = Loadable(
  lazy(() => import('./pages/authentication/VerifyEmail'))
);
const DeleteAccount = Loadable(lazy(() => import('./pages/DeleteAccount')));

// Dashboard pages

// const Account = Loadable(lazy(() => import('./pages/app/Account')));
const Overview = Loadable(lazy(() => import('./pages/app/Overview')));
const Plays = Loadable(lazy(() => import('./pages/app/Plays')));

// Error pages

const NotFound = Loadable(lazy(() => import('./pages/NotFound')));

// Other pages
const Landing = Loadable(lazy(() => import('./pages/Landing')));

const routes = [
  {
    path: 'unsubscribe',
    element: <Unsubscribe />
  },
  {
    path: 'terms',
    element: <Terms />
  },
  {
    path: 'privacy',
    element: <Privacy />
  },
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },

      {
        path: 'verify-email',
        element: <VerifyEmail />
      }
    ]
  },
  {
    path: 'team-invitation/teamid/:teamId/teamName/:teamDisplayname/:timestamp/inviter/:inviterName',
    element: <TeamInvitation />
  },

  {
    path: 'delete-account',
    element: (
      <AuthGuard>
        <PersonalGuard>
          <DeleteAccount />
        </PersonalGuard>
      </AuthGuard>
    )
  },
  {
    path: 'update-email',
    element: (
      <AuthGuard>
        <PersonalGuard>
          <UpdateEmail />
        </PersonalGuard>
      </AuthGuard>
    )
  },
  {
    path: 'app',
    element: (
      <AuthGuard>
        <AppLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'personal/',
        element: (
          <PersonalGuard>
            <Overview />
          </PersonalGuard>
        )
      },
      {
        path: 'personal/plays',
        element: (
          <PersonalGuard>
            <Plays />
          </PersonalGuard>
        )
      },
      {
        path: 'personal/playbooks',
        element: (
          <PersonalGuard>
            <Playbooks />
          </PersonalGuard>
        )
      },
      {
        path: 'personal/playbooks/:playbookId/',
        element: (
          <PersonalGuard>
            <PlaybookGuard>
              <Playbook />
            </PlaybookGuard>
          </PersonalGuard>
        )
      },

      {
        path: 'personal/plays/create',
        element: (
          <PersonalGuard>
            <PlayGuard>
              <Board />
            </PlayGuard>
          </PersonalGuard>
        )
      },

      {
        path: 'personal/plays/:playId',
        element: (
          <PersonalGuard>
            <PlayGuard>
              <Board />
            </PlayGuard>
          </PersonalGuard>
        )
      },

      {
        path: 'team/:teamId',
        element: (
          <TeamGuard>
            <Overview />
          </TeamGuard>
        )
      },
      {
        path: 'team/:teamId/plays',
        element: (
          <TeamGuard>
            <Plays />
          </TeamGuard>
        )
      },
      {
        path: 'team/:teamId/plays/:playId',
        element: (
          <TeamGuard>
            <PlayGuard>
              <Board />
            </PlayGuard>
          </TeamGuard>
        )
      },
      // deprecated we need this one for transition
      {
        path: 'team/:teamId/session/:playId/plays/edit',
        element: (
          <TeamGuard>
            <PlayGuard>
              <Board />
            </PlayGuard>
          </TeamGuard>
        )
      },

      {
        path: 'team/:teamId/playbooks',
        element: (
          <TeamGuard>
            <Playbooks />
          </TeamGuard>
        )
      },
      {
        path: 'team/:teamId/playbooks/:playbookId',
        element: (
          <TeamGuard>
            <PlaybookGuard>
              <Playbook />
            </PlaybookGuard>
          </TeamGuard>
        )
      }
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: (
          <GuestGuard>
            {' '}
            <Landing />{' '}
          </GuestGuard>
        )
      },
      {
        path: 'announcements',
        element: (
          <GuestGuard>
            <Announcements />
          </GuestGuard>
        )
      },
      {
        path: 'pricing-personal',
        element: (
          <GuestGuard>
            <PricingPersonalSpace />
          </GuestGuard>
        )
      },
      {
        path: 'pricing-team',
        element: (
          <GuestGuard>
            <PricingTeamSpace />
          </GuestGuard>
        )
      },
      {
        path: '401',
        element: <NotFound />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <NotFound />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
