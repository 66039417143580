import type { Theme } from '@mui/material';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import type { SxProps } from '@mui/system';
import type { FC } from 'react';
import { OBJECT_SIZE } from 'src/constants';

interface IProps {
  sx?: SxProps<Theme>;
}

const SpikeIcon: FC<IProps> = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    sx={{
      color: '#E5E7EB',
      width: OBJECT_SIZE - 10,
      height: OBJECT_SIZE - 10,
      opacity: 0.9
    }}
  >
    <svg viewBox="0 0 48 48">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.6014 6.86283L3.76032 14.8899C3.42269 15.4497 3.41283 16.1509 3.73458 16.7202L9.22128 26.4283L13.9663 23.6878C13.8034 22.9608 13.7175 22.2047 13.7175 21.4285C13.7175 16.4323 17.2785 12.2681 22 11.3372V6L10.1212 6.00003C9.50046 6.00003 8.92458 6.32697 8.6014 6.86283ZM17.5094 41.0931L11.5409 30.5325L16.0365 27.936C17.9221 30.2422 20.789 31.7143 23.9996 31.7143C27.2104 31.7143 30.0774 30.2421 31.963 27.9357L36.4592 30.5325L30.4906 41.0931C30.1737 41.6539 29.5839 42 28.9451 42H19.0549C18.4161 42 17.8263 41.6539 17.5094 41.0931ZM44.2654 16.7202L38.7787 26.4283L34.033 23.6874C34.1958 22.9605 34.2817 22.2046 34.2817 21.4285C34.2817 16.4326 30.7211 12.2686 26 11.3374V6H37.8788C38.4995 6 39.0754 6.32694 39.3986 6.8628L44.2397 14.8898C44.5773 15.4497 44.5872 16.1509 44.2654 16.7202ZM23.9996 33L27.5984 39H20.4009L23.9996 33ZM37.5072 9.35388L34.0171 15.6429L41.2067 15.7639L37.5072 9.35388ZM13.9819 15.6431L6.78675 15.7642L10.4892 9.34919L13.9819 15.6431ZM23.7764 17.3197C23.9147 17.2398 24.0851 17.2398 24.2234 17.3197L27.5415 19.2354C27.6798 19.3152 27.765 19.4628 27.765 19.6225V23.4539C27.765 23.6136 27.6798 23.7612 27.5415 23.841L24.2234 25.7567C24.0851 25.8366 23.9147 25.8366 23.7764 25.7567L20.4583 23.841C20.32 23.7612 20.2348 23.6136 20.2348 23.4539V19.6225C20.2348 19.4628 20.32 19.3152 20.4583 19.2354L23.7764 17.3197ZM29.7764 17.9451C29.9147 18.0249 29.9999 18.1725 29.9999 18.3322V24.7442C29.9999 24.9039 29.9147 25.0515 29.7764 25.1313L24.2234 28.3374C24.0851 28.4172 23.9147 28.4172 23.7764 28.3374L18.2234 25.1313C18.0851 25.0515 17.9999 24.9039 17.9999 24.7442V18.3322C17.9999 18.1725 18.0851 18.0249 18.2234 17.9451L23.7764 14.739C23.9147 14.6592 24.0851 14.6592 24.2234 14.739L29.7764 17.9451Z"
      />
    </svg>
  </SvgIcon>
);

export default SpikeIcon;
