import Typography from '@mui/material/Typography';
import { String } from 'lodash';
import React, {
  FC,
  MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RUNNING_SPEED } from 'src/constants';
import { addCommand } from 'src/slices/board/boardModel';
import store, { RootState, useSelector } from 'src/store';
import {
  ActionTypes,
  BoardTools,
  ICommand,
  ICommandParams,
  IDrawingLine
} from 'src/types/board';
import createResourceId from 'src/utils/createResourceId';

// Running Speed with Knife 6.573m/s
// Every agent has same base speed´

// reference value 94.07244873046875 ~= 16.8072  meters
// 1 svg unit ~= 0,1786623
// in ~ 2.557 seconds

const calcRunningSpeed = (distance: number) =>
  Math.round(((distance * 0.1786623) / RUNNING_SPEED) * 10) / 10;

interface IProps {
  line: IDrawingLine;
  mapRotation: number;
}

const DrawingLine: FC<IProps> = (props: IProps) => {
  const { line, mapRotation } = props;
  const { id: idx, path, color, strokeWidth, strokeDashArray } = line;
  const { playId, teamId } = useParams();
  const sessionId = teamId ? playId : null;
  const pathRef = useRef<SVGPathElement>();
  const [runSpeed, setRunSpeed] = useState<number>()
  
  useEffect(
    () =>{ 
      const speed = calcRunningSpeed(pathRef?.current?.getTotalLength());
      setRunSpeed(speed)
    },
    [path, pathRef?.current]
  );
  const { activeStep } = useSelector(
    (state: RootState) => state.boardSlice.model,
    shallowEqual
  );
  const { selectedTool, cursorState } = useSelector(
    (state: RootState) => state.boardSlice.ui,
    shallowEqual
  );

  const handleMouseMove = (mouseEvent: MouseEvent): void => {
    if (mouseEvent.button === 0) {
      if (
        selectedTool === BoardTools.ERASE &&
        cursorState === ActionTypes.ERASE
      ) {
        const command: ICommand = {
          id: createResourceId(),
          type: ActionTypes.ERASE,
          params: {
            value: {
              ...line
            } as IDrawingLine,
            stepLayer: activeStep,
            sessionId,
            playId
          } as ICommandParams,
          inverse: ActionTypes.DRAW
        };
        store.dispatch(addCommand(command));
      }
    }
  };
  // TODO: touchMove event is not recognized yet, find out why
  const handleTouchMove = (): void => {
    if (
      selectedTool === BoardTools.ERASE &&
      cursorState === ActionTypes.ERASE
    ) {
      const command: ICommand = {
        id: createResourceId(),
        type: ActionTypes.ERASE,
        params: {
          value: {
            id: idx,
            color,
            path
          } as IDrawingLine,
          stepLayer: activeStep,
          sessionId,
          playId
        } as ICommandParams,
        inverse: ActionTypes.DRAW
      };
      store.dispatch(addCommand(command));
    }
  };

  const destructurePath = (str: string) => {
    const array = str.split(/(?=[LMC])/);
    return array.map((d) => {
      const pointsArray = d.slice(1, d.length).trim().split(' ');

      const pairsArray = [];
      for (let i = 0; i < pointsArray.length; i += 2) {
        pairsArray.push([pointsArray[i], pointsArray[i + 1]]);
      }
      return pairsArray;
    });
  };

  let pathTitlePos: string[];
  if (line.showDistance) {
    const destructeredPath = destructurePath(path);
    // eslint-disable-next-line prefer-destructuring
    pathTitlePos = destructeredPath[destructeredPath.length - 1][0];
  }
  // TODO: touchMove event is not recognized yet, find out why
  return (
    <>
      <defs>
        <marker
          id={`${idx}-head`}
          viewBox="0 0 10 10"
          refX="5"
          refY="5"
          markerWidth="5"
          markerHeight="5"
        >
          <circle cx="5" cy="5" r="4" fill={color} />
        </marker>
      </defs>
      {pathTitlePos && (
        <text
          href={`line-text#${idx}`}
          style={{
            transform: `rotate(-${mapRotation}deg) translate(10px, 0px)`,
            transformBox: 'fill-box',
            transformOrigin: 'center'
          }}
          fill={color}
          fontSize="12px"
          x={pathTitlePos[0]}
          y={pathTitlePos[1]}
        >
          {runSpeed}s
        </text>
      )}

      <path
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseMove}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchMove}
        id={idx}
        ref={pathRef}
        className="path"
        markerStart={pathTitlePos && `url(#${idx}-head)`}
        markerEnd={pathTitlePos && `url(#${idx}-head)`}
        style={{
          fill: 'none',
          strokeWidth,
          strokeDasharray: strokeDashArray,
          strokeLinejoin: 'round',
          strokeLinecap: 'round',
          stroke: color
        }}
        d={path}
      />
    </>
  );
};

export default DrawingLine;
