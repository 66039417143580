import {
  Button,
  capitalize,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Grid
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CircularLoadingDialog from 'src/components/CircularLoadingDialog';
import CustomDialogHeader from 'src/components/CustomDialogHeader';
import { createSession } from 'src/data/realtimeDb/setters/session';
import useSettings from 'src/hooks/useSettings';
import { toPersonalPlayCreate, toTeamPlay } from 'src/nav';
import { RootState, useSelector } from 'src/store';
import { PlayInfo } from 'src/types/play';
import { generatePlayId } from 'src/utils/generateFirestoreKey';

interface IProps {
  open: boolean;
  setOpen: Function;
  excludedPlayIDs: string[];
  submitFunction: Function;
}

interface DataGridPlayInfo {
  id: string;
  createdAt: string;
  map: string;
  title: string;
  updatedAt: string;
}

const playInfosToDataGridPlayInfos = (playInfos: PlayInfo[]) =>
  playInfos?.map((info) => {
    const dataGridPlayInfo: DataGridPlayInfo = {
      id: info.id,

      createdAt: `${moment(info.createdAt).fromNow()}`,

      map: capitalize(info.map),

      title: info.title,

      updatedAt: `${moment(info.updatedAt).fromNow()}`
    };
    return dataGridPlayInfo;
  }) || [];

const AddPlaysToPlaybookDialog: FC<IProps> = (props: IProps) => {
  const { open, setOpen, excludedPlayIDs, submitFunction } = props;
  const [selectedPlays, setSelectedPlays] = useState([]);
  const [waitingForFunctionResponse, setWaitingForFunctionResponse] =
    useState<boolean>(false);
  const { settings } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const [creatingPlay, setCreatingPlay] = useState<boolean>(false);

  const navigate = useNavigate();
  const activeWorkspace = settings?.activeSpaceID;

  const personalPlaysInfo: PlayInfo[] = useSelector(
    (state: RootState) => state.accountDataSlice.accountData.playsInfo
  );

  const teamPlaysInfo: PlayInfo[] = useSelector(
    (state: RootState) => state.teamSlice.team?.playsInfo
  );

  const columns: GridColDef[] = [
    { field: 'title', headerName: 'Title', flex: 1, minWidth: 120 },
    { field: 'map', headerName: 'Map', minWidth: 120 },
    { field: 'createdAt', headerName: 'created', minWidth: 120 },
    { field: 'updatedAt', headerName: 'updated', minWidth: 120 },
    { field: 'id', hide: true }
  ];

  const handleClickAddPlays = async () => {
    setWaitingForFunctionResponse(true);
    await submitFunction(
      activeWorkspace
        ? teamPlaysInfo.filter((playInfo) =>
            selectedPlays.includes(playInfo.id)
          )
        : personalPlaysInfo.filter((playInfo) =>
            selectedPlays.includes(playInfo.id)
          )
    );
    setSelectedPlays([]);
    setWaitingForFunctionResponse(false);
  };

  const handleClickCreatePlay = async () => {
    if (activeWorkspace) {
      setCreatingPlay(true);
      // start new play(session)
      const key = await createSession(activeWorkspace);

      navigate(toTeamPlay(activeWorkspace, key));
      setCreatingPlay(false);
    } else {
      navigate(toPersonalPlayCreate());
    }
  };

  const onClose = () => {
    if (!waitingForFunctionResponse) {
      setOpen(false);
      setSelectedPlays([]);
    }
  };

  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
        <DialogContent sx={{ height: 'auto', maxHeight: '750px' }}>
          <Container
            sx={{
              width: '100%',
              height: '100%',
              py: 2
            }}
          >
            <CustomDialogHeader title="Add Plays" onClose={onClose} />
            {waitingForFunctionResponse ? (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ height: '300px' }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                container
                item
                xs={12}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12} sx={{ my: 3 }}>
                  <DataGrid
                    loading={
                      activeWorkspace ? !teamPlaysInfo : !personalPlaysInfo
                    }
                    checkboxSelection
                    onSelectionModelChange={(newSelection) => {
                      setSelectedPlays(
                        newSelection.map((playID) => playID.toString())
                      );
                    }}
                    selectionModel={selectedPlays}
                    pageSize={10}
                    disableSelectionOnClick
                    disableColumnSelector
                    disableColumnMenu
                    disableColumnFilter
                    hideFooterPagination={
                      activeWorkspace
                        ? teamPlaysInfo?.length <= 10
                        : personalPlaysInfo?.length <= 10
                    }
                    density="compact"
                    autoHeight
                    rows={
                      activeWorkspace
                        ? playInfosToDataGridPlayInfos(
                            teamPlaysInfo?.filter(
                              (play) => !excludedPlayIDs.includes(play.id)
                            )
                          ) || []
                        : playInfosToDataGridPlayInfos(
                            personalPlaysInfo?.filter(
                              (play) => !excludedPlayIDs.includes(play.id)
                            )
                          ) || []
                    }
                    columns={columns}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    sx={{ mr: 1 }}
                    variant="outlined"
                    onClick={() => handleClickCreatePlay()}
                  >
                    Create Play
                  </Button>

                  <Button
                    sx={{}}
                    variant="contained"
                    onClick={() => handleClickAddPlays()}
                    disabled={selectedPlays.length === 0}
                  >
                    Add Play {selectedPlays.length > 1 && 's'}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Container>
        </DialogContent>
      </Dialog>
      {creatingPlay && (
        <CircularLoadingDialog title="Please wait for your board to be loaded" />
      )}
    </>
  );
};

export default AddPlaysToPlaybookDialog;
