import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { AccountData } from 'src/types/user';
import { useAccountDataSliceLogs } from 'src/constants';

interface AccountDataState {
  accountData: AccountData;
}

const initialState: AccountDataState = {
  accountData: {
    username: null,
    teamsInfo: [],
    playsInfo: [],
    playbooksInfo: [],
    pendingInvites: []
  }
};

const slice = createSlice({
  name: 'accountdata',
  initialState,
  reducers: {
    updateAccountData(
      state: AccountDataState,
      action: PayloadAction<{ accountData: AccountData }>
    ) {
      const { accountData } = action.payload;
      state.accountData = accountData;
    },
    clearAccountData(state: AccountDataState) {
      state.accountData = initialState.accountData;
    }
  }
});

export const { reducer } = slice;

export const updateAccountData =
  (accountDataUpdated: AccountData): AppThunk =>
  (dispatch) => {
    if (useAccountDataSliceLogs) {
      console.log('ACCOUNT DATA UPDATE: ');
      console.log(accountDataUpdated);
    }
    const response = { accountData: accountDataUpdated };
    dispatch(slice.actions.updateAccountData(response));
  };

export const clearAccountData = (): AppThunk => (dispatch) => {
  if (useAccountDataSliceLogs) {
    console.log('ACCOUNT DATA CLEARED!');
  }
  dispatch(slice.actions.clearAccountData());
};

export default slice;
