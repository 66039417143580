import { useEffect } from 'react';

type IProps = {
  [key in keyof WindowEventMap]?: EventListenerOrEventListenerObject;
};

const useGlobalDOMEvents = (props: IProps) => {
  useEffect(() => {
    Object.entries(props).forEach(([key, func]) => {
      window.addEventListener(key, func, false);
    });

    return () => {
      Object.entries(props).forEach(([key, func]) => {
        window.removeEventListener(key, func, false);
      });
    };
  }, []);
};

export default useGlobalDOMEvents;
