import { Grid, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { FC } from 'react';
import PlayPreviewBox from 'src/components/app/plays/PlayPreviewBox';
import useSettings from 'src/hooks/useSettings';
import { RootState, useSelector } from 'src/store';
import { PlayInfo } from 'src/types/play';
import { SessionInfo, SessionInfos } from 'src/types/session';
import sortPlays from 'src/utils/sortPlays';

interface IProps {
  maxNumberOfPlays: number;
  playInfos: PlayInfo[];
}

const PlaysPreviewList: FC<IProps> = (props: IProps) => {
  const theme = useTheme();
  const settings = useSettings();
  const { playInfos, maxNumberOfPlays } = props;
  const spacing = 12;
  const sessionInfos: SessionInfos = useSelector(
    (state: RootState) => state.sessionInfosSlice.sessionInfos
  );
  const activeWorkspace = settings?.settings?.activeSpaceID;

  // returns sessionInfo when one exists
  const findSessionInfo = (playId: string): SessionInfo => {
    const result = Object.entries(sessionInfos).find(
      ([, value]) => value.playId === playId
    );

    return result?.[1] || null;
  };

  const numberOfRenderedPlays = () => {
    if (playInfos) {
      if (playInfos.length > maxNumberOfPlays) {
        return maxNumberOfPlays;
      }
      return playInfos.length;
    }
    return 0;
  };

  return (
    <>
      {playInfos?.length === 0 ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%', height: 180 }}
        >
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.secondary }}
          >
            There are currently no plays here.{' '}
          </Typography>
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          sx={{
            width: `calc( (100% / ${maxNumberOfPlays}) * (${numberOfRenderedPlays()}))`,
            height: '100%'
          }}
        >
          {sortPlays(playInfos, 'editedDesc')?.map(
            (play, i) =>
              (i < maxNumberOfPlays || !maxNumberOfPlays) && (
                <Grid
                  key={play.id}
                  item
                  sx={{
                    width: `calc(${
                      100 / numberOfRenderedPlays()
                    }% - ${spacing}px)`
                  }}
                >
                  <PlayPreviewBox
                    play={play}
                    activeWorkspace={activeWorkspace}
                    selectionEnabled={false}
                    selected={false}
                    sessionInfo={findSessionInfo(play.id)}
                  />
                </Grid>
              )
          )}
        </Grid>
      )}
    </>
  );
};

PlaysPreviewList.propTypes = {
  maxNumberOfPlays: PropTypes.number,
  playInfos: PropTypes.any
};

export default PlaysPreviewList;
