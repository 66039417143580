import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      'Welcome to React': 'Welcome to React and react-i18next',
      play: {
        board: {
          save: {
            buttons: {
              confirm: {
                label: 'Save'
              },
              saveasnew: {
                label: 'Save as new play'
              },
              cancel: {
                label: 'Cancel'
              }
            },
            label: 'Save',
            title: 'Save your play',
            // description: 'Please give your play a fitting title so you can find it faster later on! If you decide to save your board as a new play, the play you edited remains the way it was. You will also be redirected to your newly created play.',
            description:
              'Please give your play a fitting title so you can find it faster later on! ',
            success: 'Succesfully saved Play: {{title}}',
            error:
              'Something went wrong when saving Play: {{title}}. Error message: {{errorMessage}}'
          },
          clear: {
            label: 'Clear',
            title: ' Clear the board',
            description:
              'Your current board will be deleted permanently unless you have saved your changes.',
            buttons: {
              confirm: {
                label: 'Clear'
              },
              cancel: {
                label: 'Cancel'
              },
              saveandclear: {
                label: 'Save & Clear'
              }
            }
          },
          mapSelector: {
            label: 'Map'
          },
          undo: {
            button: {
              label: 'Undo'
            }
          },
          redo: {
            button: {
              label: 'Redo'
            }
          },
          visibilitySelector: {
            label: 'Visibility',
            checkbox: {
              mapLabels: 'Map Callouts',
              mapAreas: 'Map Areas',
              mapBase: 'Map Base',
              mapObjects: 'Map Objects',
              lines: 'Lines',
              objects: 'Objects',
              connections: 'Connections',
              areasOfEffect: 'Areas of Effect'
            }
          }
        }
      },
      team: {
        session: {
          create: {
            button: {
              label: 'Create a Play'
            }
          },
          join: {
            button: {
              label: 'Join play'
            }
          }
        }
      }
    }
  },
  fr: {
    translation: {
      'Welcome to React': 'Bienvenue à React et react-i18next'
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
});
