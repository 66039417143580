import {
  Box,
  TextField,
  IconButton,
  InputAdornment,
  useTheme,
  Grid
} from '@mui/material';
import { useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import AddIcon from '@mui/icons-material/Add';
import * as Yup from 'yup';
import { Formik } from 'formik';

interface IProps {
  invitees: ChipItem[];
  setInvitees: Function;
}

interface ChipItem {
  username: string;
  email: string;
  displayName: string;
  photoURL: string | null;
}

const TeamInviteEmailInput: React.FC<IProps> = (props: IProps) => {
  const { invitees, setInvitees } = props;
  const theme = useTheme();

  const onSubmit = (values, { setStatus, resetForm }): void => {
    const invitee = {
      username: null,
      displayName: null,
      email: values.email,
      photoURL: null
    };
    setInvitees([...invitees, invitee]);
    setStatus({ success: true });
    resetForm();
  };

  return (
    <Formik
      initialValues={{
        email: ''
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Must be a valid email').max(255)
      })}
      onSubmit={onSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{ width: '100%', px: 3 }}
          >
            <TextField
              error={Boolean(touched.email && errors.email)}
              sx={{ width: '93%' }}
              placeholder="Type email address..."
              helperText={touched.email && errors.email}
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon color="primary" fontSize="small" />
                  </InputAdornment>
                ),
                sx: {
                  width: '100%',
                  borderRadius: 300,
                  pl: 3,
                  '& fieldset': {
                    borderColor: theme.palette.text.secondary
                  },
                  '& ::placeholder': {
                    color: `${theme.palette.text.secondary} !important`
                  },
                  height: '40px'
                }
              }}
            />

            <Grid item>
              <Box sx={{ mt: 2.5, width: 'auto' }}>
                <IconButton type="submit" disabled={!!errors.email}>
                  <AddIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default TeamInviteEmailInput;
