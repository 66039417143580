import { Box, Typography } from '@mui/material';
import List from '@mui/material/List';
import * as React from 'react';
import { ABILITIES } from 'src/ressources/shapes/abilities';
import { BASIC_OBJECTS } from 'src/ressources/shapes/basicObjects';
import { DragTypes, TeamSides } from 'src/types/board';
import {
  DrawerAccordion,
  DrawerAccordionDetails,
  DrawerAccordionSummary
} from '../../DrawerAccordion';
import ValorantItemList from '../ValorantItemList';

interface IProps {
  attack: TeamSides;
  setAttack: Function;
}

const ValorantItemsDrawer: React.FC<IProps> = (props: IProps) => {
  const { attack, setAttack } = props;

  const optionList = [
    {
      title: 'Basic',
      key: DragTypes.BASIC,
      list: BASIC_OBJECTS,
      filterEnabled: false
    },

    {
      title: 'Agents',
      key: DragTypes.ABILITY,
      nestedList: ABILITIES,
      filterEnabled: true
    }
  ];

  return (
    <Box>
      <List sx={{ pt: 0 }}>
        {optionList.map(
          (option): React.ReactElement => (
            <Box key={option.key}>
              <DrawerAccordion key={option.key} defaultExpanded>
                <DrawerAccordionSummary id={option.key}>
                  <Typography variant="subtitle1">{option.title}</Typography>
                </DrawerAccordionSummary>

                <DrawerAccordionDetails>
                  <ValorantItemList
                    dragType={option.key}
                    objects={option.list}
                    nestedObjects={option.nestedList}
                    filterEnabled={option.filterEnabled}
                    attack={option.key === DragTypes.BASIC ? undefined : attack}
                    setAttack={
                      option.key === DragTypes.BASIC ? undefined : setAttack
                    }
                  />
                </DrawerAccordionDetails>
              </DrawerAccordion>
            </Box>
          )
        )}
      </List>
    </Box>
  );
};

export default ValorantItemsDrawer;
