import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { PathPattern, useParams } from 'react-router';
import { matchPath, Outlet, useLocation } from 'react-router-dom';
import {
  appSidebarWidth,
  appSpacebarWidth,
  appTopbarHeight
} from 'src/constants';
import useMdDown from 'src/hooks/useMdDown';
import CustomDialogHeader from '../CustomDialogHeader';
import AppNavbar from './AppNavbar';
import AppSidebar from './AppSidebar';
import AppSpacebar from './AppSpacebar';
import MenuIcon from '@mui/icons-material/Menu';
import ThemeButton from './ThemeButton';
import hexToRGB from 'src/utils/hexToRGB';
import useLgDown from 'src/hooks/useLgDown';
import CustomDialog from '../CustomDialog';
import MaintenanceDialog from './MaintenanceDialog';

let closedUseDesktopDialog = false;
interface AppLayoutProps {
  children?: ReactNode;
}

const AppLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  minWidth: theme.breakpoints.values.md,
  width: '100%'
}));

const AppLayoutWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hidden'
  // @ts-ignore: customProp unkown
})(({ boardActive, theme, isSidebarMobileOpen, hidden }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: `${boardActive ? appTopbarHeight : 0}px`,
  paddingRight: `${!hidden ? appSpacebarWidth : 0}px`,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: `${!hidden ? appSidebarWidth : 0}px`
  }
}));

const AppLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const AppLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const hideSideBarPaths = [
  'app/personal/board/create',
  'app/personal/plays/:playId',
  'app/team/:teamId/plays/:playId',
  'app/team/:teamId/session/:sessionId/plays/:playId'
];

type ContextType = { isSidebarMobileOpen: boolean | null };

const AppLayout: FC<AppLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
    useState<boolean>(false);

  const [hidden, setHidden] = useState<boolean>(false);

  const location = useLocation();

  const { playId } = useParams();

  const boardActive =
    location.pathname.includes('board') ||
    location.pathname.includes('plays/create') ||
    playId;

  const mdDown = useMdDown();

  const lgDown = useLgDown();

  const [useDesktopDialogOpen, setUseDesktopDialogOpen] =
    useState<boolean>(mdDown);

  const theme = useTheme();

  useEffect(() => {
    if (!closedUseDesktopDialog) {
      if (mdDown) {
        setUseDesktopDialogOpen(true);
      } else {
        setUseDesktopDialogOpen(false);
      }
    }
  }, [mdDown]);

  const handleCloseUseDesktopDialog = () => {
    closedUseDesktopDialog = true;
    setUseDesktopDialogOpen(false);
  };

  useEffect(() => {
    setHidden(false);
    hideSideBarPaths.forEach((path) => {
      const match = matchPath(
        {
          path,
          exact: true,
          strict: false
        } as PathPattern,
        location.pathname
      );

      if (match || boardActive) {
        setHidden(true);
      }
    });
  }, [location.pathname]);

  return (
    <AppLayoutRoot>
      <AppNavbar
        onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
        hidden={hidden}
      />

      {!hidden && <AppSpacebar />}

      <AppSidebar
        onMobileClose={(): void => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
        hidden={hidden}
      />
      {
        <AppLayoutWrapper
          hidden={hidden}
          // @ts-ignore: customProp unkown
          boardActive={boardActive}
        >
          <AppLayoutContainer>
            <AppLayoutContent>
              {lgDown && (
                <div
                  style={{
                    paddingTop: 45
                  }}
                />
              )}

              {lgDown && (
                <Box
                  sx={{
                    position: 'absolute',
                    ml: 7,
                    borderRadius: `${theme.shape.borderRadius}px`,
                    backgroundColor: theme.palette.background.paper,
                    border: `1px solid ${theme.palette.divider}`
                  }}
                >
                  <IconButton
                    sx={{ borderRadius: `${theme.shape.borderRadius}px` }}
                    onClick={() => setIsSidebarMobileOpen(true)}
                  >
                    <MenuIcon sx={{ color: '#ffffff' }} />
                  </IconButton>
                </Box>
              )}
              <Outlet />
            </AppLayoutContent>
          </AppLayoutContainer>
        </AppLayoutWrapper>
      }

      <CustomDialog
        open={mdDown && useDesktopDialogOpen && !closedUseDesktopDialog}
        setOpen={handleCloseUseDesktopDialog}
        onClose={handleCloseUseDesktopDialog}
        title="Info"
        width={270}
        buttons={[]}
      >
        <Box
          sx={{
            width: '220px',
            position: 'relative'
          }}
        >
          <Typography variant="body2" color="textSecondary">
            Critbase is currently not optimized for small screens and mobile
            devices. Please switch to a desktop device for a better user
            experience.
          </Typography>
        </Box>
      </CustomDialog>
    </AppLayoutRoot>
  );
};

export default AppLayout;
