import { AGENTS } from 'src/ressources/shapes/agents';

const determineBackgroundColor = (objValue: string): string => {
  const [agent] = objValue.split('.'); // if this is definedn --> ability
  if (agent) {
    return AGENTS[agent]?.backgroundColor || 'transparent';
  }
  if (objValue) {
    return AGENTS[objValue]?.backgroundColor;
  }
  return 'transparent';
};

export default determineBackgroundColor;
