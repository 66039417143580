import { Container, DialogContent, Grid } from '@mui/material';
import type { FC } from 'react';
import CustomDialogHeader from 'src/components/CustomDialogHeader';
import FeedbackContact from './FeedbackContact';

interface IProps {
  onClose: () => void;
}

const FeedbackDialogContent: FC<IProps> = (props: IProps) => {
  const { onClose } = props;
  return (
    <Container
      sx={{
        width: '100%',
        height: '100%',
        maxHeight: '750px'
      }}
    >
      <Grid container sx={{ py: 2 }}>
        <Grid item xs={12}>
          <FeedbackContact onClose={onClose} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default FeedbackDialogContent;
