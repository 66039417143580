import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import { useNavigate } from 'react-router';
import { resetPassword } from 'src/data/firestore/setters/account';
import useAuth from 'src/hooks/useAuth';
import * as Yup from 'yup';
import LogoGoogle from '../../../icons/LogoGoogle';
import SettingsSection from '../SettingsSection';

const AccountSecuritySettings: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();

  return (
    <>
      {user.provider.includes('password') ? (
        <>
          <Grid
            container
            columnSpacing={6}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item container xs={6} rowSpacing={4}>
              <Grid item xs={12}>
                <SettingsSection title="password">
                  <Formik
                    initialValues={{
                      newPassword: '',
                      newPasswordConfirm: '',
                      oldPassword: '',
                      submit: null
                    }}
                    validationSchema={Yup.object().shape({
                      newPassword: Yup.string()
                        .min(7, 'Must be at least 7 characters')
                        .max(255)
                        .required('Required'),
                      newPasswordConfirm: Yup.string()
                        .oneOf(
                          [Yup.ref('newPassword'), null],
                          'Passwords must match'
                        )
                        .required('Required'),
                      oldPassword: Yup.string()
                        .min(7, 'Must be at least 7 characters')
                        .max(255)
                        .required('Required')
                    })}
                    onSubmit={async (
                      values,
                      { resetForm, setErrors, setStatus, setSubmitting }
                    ): Promise<void> => {
                      try {
                        await resetPassword(
                          user.email,
                          values.oldPassword,
                          values.newPassword
                        );
                        resetForm();
                        setStatus({ success: true });
                        setSubmitting(false);
                        enqueueSnackbar('Password updated', {
                          variant: 'success'
                        });
                      } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                      }
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values
                    }): JSX.Element => (
                      <form onSubmit={handleSubmit}>
                        <TextField
                          error={Boolean(
                            touched.newPassword && errors.newPassword
                          )}
                          fullWidth
                          helperText={touched.newPassword && errors.newPassword}
                          label="New Password"
                          name="newPassword"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="password"
                          value={values.newPassword}
                          variant="outlined"
                          sx={{ mt: 1.5 }}
                        />

                        <TextField
                          error={Boolean(
                            touched.newPasswordConfirm &&
                              errors.newPasswordConfirm
                          )}
                          fullWidth
                          helperText={
                            touched.newPasswordConfirm &&
                            errors.newPasswordConfirm
                          }
                          label="New Password Confirmation"
                          name="newPasswordConfirm"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="password"
                          value={values.newPasswordConfirm}
                          variant="outlined"
                          sx={{ mt: 2 }}
                        />

                        <TextField
                          error={Boolean(
                            touched.oldPassword && errors.oldPassword
                          )}
                          fullWidth
                          helperText={touched.oldPassword && errors.oldPassword}
                          label="Old Password"
                          name="oldPassword"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="password"
                          value={values.oldPassword}
                          variant="outlined"
                          sx={{ mt: 2 }}
                        />

                        <Divider sx={{ mt: 2.5 }} />

                        {errors.submit && (
                          <Box sx={{ mt: 3 }}>
                            <FormHelperText error>
                              {errors.submit}
                            </FormHelperText>
                          </Box>
                        )}

                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          sx={{ mt: 2 }}
                        >
                          Change Password
                        </Button>
                      </form>
                    )}
                  </Formik>
                </SettingsSection>
              </Grid>
            </Grid>

            <Grid item container xs={6} rowSpacing={4}>
              <Grid item xs={12}>
                <SettingsSection title="email">
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {user.email}
                  </Typography>
                  <Button
                    color="primary"
                    type="submit"
                    variant="text"
                    onClick={() => navigate('/update-email')}
                    sx={{ mt: 2 }}
                  >
                    Change Email Address
                  </Button>
                </SettingsSection>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container>
          <SettingsSection title="google authentication">
            <Grid
              container
              sx={{
                p: 7,
                backgroundColor: theme.palette.background.paper,
                borderRadius: `${theme.shape.borderRadius}px`,
                border: `1px solid ${theme.palette.divider}`
              }}
              direction="column"
              justifyContent="center"
              alignItems="stretch"
            >
              <Grid item container justifyContent="center" sx={{ mb: 3 }}>
                <LogoGoogle
                  sx={{
                    height: 'auto',
                    width: 60,
                    backgroundColor: '#ffffff',
                    borderRadius: 99,
                    p: 1
                  }}
                />
              </Grid>

              <Grid item container justifyContent="center">
                <Typography sx={{ width: '70%' }} align="center">
                  You are using a Google Account ({user.email}) to authenticate.
                  If you want to change your credentials, please use the
                  functions provided by Google.
                </Typography>
              </Grid>
            </Grid>
          </SettingsSection>
        </Grid>
      )}
    </>
  );
};

export default AccountSecuritySettings;
