import { FC, ReactElement } from 'react';
import { IAreaOfEffect, IPoint } from 'src/types/board';
import determineAreaOfEffect from 'src/utils/boardUtils/determineAreaOfEffect';

interface IProps {
  targetObjectPosition: IPoint;
  targetObjectValue: string;
  targetObjectAOE: IAreaOfEffect;
}

const vectorMagnitude = (x: number, y: number) => Math.sqrt(x ** 2 + y ** 2);

const calculateSVGHeight = (
  radius: number,
  svgTransformOriginYPercentage: number
) => {
  const offsetPerc = svgTransformOriginYPercentage / 100;

  const offsetDistance = radius * offsetPerc;

  const targetDistance = radius * (1 - offsetPerc);

  const scaleFactor = radius / targetDistance;

  return offsetDistance * scaleFactor + targetDistance * scaleFactor;
};

const AreaOfEffectDisplay: FC<IProps> = (props: IProps): ReactElement => {
  const { targetObjectValue, targetObjectAOE, targetObjectPosition } = props;
  const areaOfEffect = determineAreaOfEffect(targetObjectValue);
  const scalable = areaOfEffect.maxLength !== areaOfEffect.minLength;
  const svgOriginTransformXPercentage = 50; // 50: standard for now, change this if necessary

  const vectorLength = scalable
    ? vectorMagnitude(
        targetObjectAOE.dx - areaOfEffect.maxLength,
        targetObjectAOE.dy - areaOfEffect.maxLength
      )
    : areaOfEffect.maxLength;

  return (
    <div
      style={{
        position: 'absolute',
        height:
          calculateSVGHeight(
            vectorLength,
            areaOfEffect.originYMovementPercentage
          ) * (areaOfEffect.multiplySvgLength || 1),
        width: areaOfEffect.width,
        top: targetObjectPosition.y,
        left: targetObjectPosition.x,
        transform: `translate(-${svgOriginTransformXPercentage}%, -${
          100 - areaOfEffect.originYMovementPercentage
        }%) rotate(${
          Math.atan2(
            targetObjectAOE.dy - areaOfEffect.maxLength,
            targetObjectAOE.dx - areaOfEffect.maxLength
          ) *
            (180 / Math.PI) +
          90
        }deg)`,
        transformOrigin: `${svgOriginTransformXPercentage}% ${
          100 - areaOfEffect.originYMovementPercentage
        }%`
      }}
    >
      {areaOfEffect.svg}
    </div>
  );
};

export default AreaOfEffectDisplay;
