import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import PlayPreviewBox from 'src/components/app/plays/PlayPreviewBox';
import CircularLoadingDialog from 'src/components/CircularLoadingDialog';
import { createSession } from 'src/data/realtimeDb/setters/session';
import useSettings from 'src/hooks/useSettings';
import { toPersonalPlayCreate, toTeamPlay } from 'src/nav';
import { RootState, useSelector } from 'src/store';
import { PlayInfo } from 'src/types/play';
import { SessionInfo, SessionInfos } from 'src/types/session';
import { Team } from 'src/types/team';
import sortPlays, { PlaysSortByOptions } from 'src/utils/sortPlays';
import PlaysListControls from './PlaysListControls';

const PlaysList: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const settings = useSettings();
  const spacing = 12;
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));
  const xlDown = useMediaQuery(theme.breakpoints.down('xl'));
  const [selectedPlays, setSelectedPlays] = useState<string[]>([]);
  const [allPlays, setAllPlays] = useState<PlayInfo[]>([]);
  const [creatingPlay, setCreatingPlay] = useState<boolean>(false);
  const [selectedSortByOption, setSelectedSortByOption] =
    useState<PlaysSortByOptions>('editedDesc');

  const maxNumberOfPlaysPerRow = () => {
    /*
    if (smDown) {
      return 2;
    }

    if (mdDown) {
      return 4;
    }
    */

    if (lgDown) {
      return 5;
    }

    if (xlDown) {
      return 5;
    }

    return 6;
  };

  const playInfosToRows = (playInfos: PlayInfo[], itemsPerRow: number) => {
    const playsInfo = [...playInfos];

    const newPlayInfosArray: any[][] = [];

    while (playsInfo.length)
      newPlayInfosArray.push(playsInfo.splice(0, itemsPerRow));

    // fill up last row so that spacing is correct

    let fillups = [];

    if (newPlayInfosArray.at(-1)) {
      if (newPlayInfosArray.at(-1).length < itemsPerRow) {
        const numberOfFillups = itemsPerRow - newPlayInfosArray.at(-1).length;

        for (let i = 0; i < numberOfFillups; i++) {
          fillups = [...fillups, `fillup_${i}`];
        }
      }

      newPlayInfosArray[newPlayInfosArray.length - 1] =
        newPlayInfosArray[newPlayInfosArray.length - 1].concat(fillups);
    }

    return newPlayInfosArray;
  };

  const activeWorkspace = settings?.settings?.activeSpaceID;

  const personalPlaysInfo: PlayInfo[] = useSelector(
    (state: RootState) => state.accountDataSlice.accountData.playsInfo
  );

  const team: Team = useSelector((state: RootState) => state.teamSlice.team);

  const sessionInfos: SessionInfos = useSelector(
    (state: RootState) => state.sessionInfosSlice.sessionInfos
  );

  useEffect(() => {
    if (activeWorkspace) {
      setAllPlays(team?.playsInfo);
    } else {
      setAllPlays(personalPlaysInfo);
    }
  }, [personalPlaysInfo, team?.playsInfo]);

  const handleClickCreatePlay = async () => {
    if (activeWorkspace) {
      setCreatingPlay(true);
      // start new play(session)
      const key = await createSession(activeWorkspace);

      navigate(toTeamPlay(activeWorkspace, key));
      setCreatingPlay(false);
    } else {
      navigate(toPersonalPlayCreate());
    }
  };

  const handleToggleSelectAllPlays = () => {
    if (selectedPlays.length === 0) {
      setSelectedPlays(allPlays.map((play) => play.id));
    } else {
      setSelectedPlays([]);
    }
  };

  const handleToggleSelectPlay = (targetId: string) => {
    if (selectedPlays.includes(targetId)) {
      setSelectedPlays(
        selectedPlays.filter((selectedPlayId) => selectedPlayId !== targetId)
      );
    } else {
      setSelectedPlays([...selectedPlays, targetId]);
    }
  };

  // returns sessionInfo when one exists
  const findSessionInfo = (playId: string): SessionInfo => {
    const result = Object.entries(sessionInfos).find(
      ([, value]) => value.playId === playId
    );

    return result?.[1] || null;
  };

  return (
    <>
      <PlaysListControls
        selectedPlays={selectedPlays}
        setSelectedPlays={setSelectedPlays}
        toggleSelectAllPlays={handleToggleSelectAllPlays}
        allPlays={allPlays}
        handleCreatePlay={handleClickCreatePlay}
        selectedSortByOption={selectedSortByOption}
        setSelectedSortByOption={setSelectedSortByOption}
      />

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{
          width: `100%`,
          height: 'auto'
        }}
      >
        {allPlays?.length > 0 ? (
          playInfosToRows(
            sortPlays(allPlays, selectedSortByOption),
            maxNumberOfPlaysPerRow()
          ).map((row) =>
            row.map((play) => {
              if (typeof play === 'string' && play.includes('fillup')) {
                return (
                  <Grid
                    key={play}
                    item
                    sx={{
                      width: `calc(${
                        100 / maxNumberOfPlaysPerRow()
                      }% - ${spacing}px)`,
                      mt: `${spacing}px`
                    }}
                  />
                );
              }

              return (
                <Grid
                  key={play.id}
                  item
                  sx={{
                    width: `calc(${
                      100 / maxNumberOfPlaysPerRow()
                    }% - ${spacing}px)`,
                    mt: `${spacing + 2}px`
                  }}
                >
                  <PlayPreviewBox
                    play={play}
                    activeWorkspace={activeWorkspace}
                    selectionEnabled
                    selected={selectedPlays.includes(play.id)}
                    toggleSelected={handleToggleSelectPlay}
                    sessionInfo={findSessionInfo(play.id)}
                  />
                </Grid>
              );
            })
          )
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%', height: 180 }}
          >
            <Typography
              variant="body2"
              sx={{ color: theme.palette.text.secondary }}
            >
              There are currently no plays here.{' '}
            </Typography>
          </Grid>
        )}
      </Grid>
      {creatingPlay && (
        <CircularLoadingDialog title="Please wait for your board to be loaded" />
      )}
    </>
  );
};

export default PlaysList;
