import TopicIcon from '@mui/icons-material/Topic';
import { Box, Container, Grid } from '@mui/material';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { PlaybooksList } from 'src/components/app/playbooks';
import PageHeader from 'src/components/PageHeader';
import useSettings from 'src/hooks/useSettings';
import { RootState, useSelector } from 'src/store';
import { Team, TeamInfo } from 'src/types/team';
import selectTeamInfoProperty from 'src/utils/selectTeamInfoProperty';

const Playbooks: FC = () => {
  const { teamId } = useParams();

  const team: Team = useSelector((state: RootState) => state.teamSlice.team);

  const { settings } = useSettings();
  const teamsInfo: TeamInfo[] = useSelector((state: RootState) =>
    state.accountDataSlice.accountData
      ? state.accountDataSlice.accountData.teamsInfo
      : []
  );

  if (teamId && !team) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Playbooks | Critbase</title>
      </Helmet>

      <Box
        sx={{
          height: '100%',
          width: '100%'
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            height: 'auto',
            width: '100%',
            p: '0px !important'
          }}
        >
          <Grid
            container
            sx={{
              minWidth: '420px',
              width: '100%',
              maxHeight: '100%'
            }}
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item container sx={{ width: '100%', height: 'auto', p: 7 }}>
              <Grid item xs={12}>
                <PageHeader
                  breadcrumbs={[
                    {
                      title: settings.activeSpaceID
                        ? selectTeamInfoProperty(
                            teamsInfo,
                            settings.activeSpaceID,
                            'displayName'
                          )
                        : 'Personal Space'
                    },
                    { title: 'Playbooks' }
                  ]}
                  headerIcon={<TopicIcon />}
                />
              </Grid>

              <Grid
                item
                container
                sx={{
                  width: '100%',
                  height: 'auto',
                  pt: 2
                }}
              >
                <PlaybooksList />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Playbooks;
