import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import type { AppThunk } from 'src/store';
import { SessionInfos, SessionInfo } from 'src/types/session';

interface SessionInfosState {
  sessionInfos: SessionInfos;
}

const initialState: SessionInfosState = {
  sessionInfos: {}
};

const slice = createSlice({
  name: 'sessionInfosSlice',
  initialState,
  reducers: {
    addSessionInfo(
      state: SessionInfosState,
      action: PayloadAction<{
        info: SessionInfo;
        key: string;
      }>
    ) {
      const { info, key } = action.payload;
      state.sessionInfos[key] = info;
    },
    removeSessionInfo(
      state: SessionInfosState,
      action: PayloadAction<{ sessionKey: string }>
    ) {
      const { sessionKey } = action.payload;
      if (state.sessionInfos[sessionKey]) {
        const clonedSessionInfos = cloneDeep(state.sessionInfos);
        delete clonedSessionInfos[sessionKey];
        state.sessionInfos = clonedSessionInfos;
      }
    },
    clearSessionInfos() {
      return initialState;
    }
  }
});

export const { reducer } = slice;

export const addSessionInfo =
  (info: SessionInfo, key: string): AppThunk =>
  (dispatch) => {
    console.log('SESSIONINFOS UPDATE');
    const response = { info, key };
    dispatch(slice.actions.addSessionInfo(response));
  };
export const removeSessionInfo =
  (sessionKey: string): AppThunk =>
  (dispatch) => {
    console.log('SESSIONINFOS UPDATE');
    const response = { sessionKey };
    dispatch(slice.actions.removeSessionInfo(response));
  };
export const clearSessionInfos = (): AppThunk => (dispatch) => {
  console.log('SESSIONINFOS CLEARED');
  dispatch(slice.actions.clearSessionInfos());
};

export default slice;
