const addSizeToFileName = (
  filename: string,
  size: '100x100' | '200x200' | '300x300' | '350x350'
): string => {
  const resizedFileName = `${filename}_${size}`;

  return resizedFileName;
};

export default addSizeToFileName;
