import {
  AvatarGroup,
  styled,
  Theme,
  useMediaQuery,
  useTheme
} from '@mui/material';
import CustomTooltip from 'src/components/CustomTooltip';
import useAuth from 'src/hooks/useAuth';
import { SessionUsers } from 'src/types/session';
import CustomAvatar from '../CustomAvatar';
import StyledBadge from '../StyledBadge';
import ExtraUserPopover from './ExtraUserPopover';

const StyledAvatarGroup = styled(AvatarGroup)(({ theme }) => ({
  '& .MuiAvatarGroup-avatar': {
    ...theme.components.MuiAvatar
  },
  '& .MuiAvatar-rounded': {
    ...theme.components.MuiAvatar,
    left: 0,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#29292e'
  },
  '& .MuiAvatar-circular': {
    ...theme.components.MuiAvatar,
    left: 0,
    height: '32px',
    width: '32px',
    border: `1px solid #18181d`,
    backgroundColor: '#29292e'
  }
}));

interface IProps {
  sessionUsers: SessionUsers;
  rippleActivated?: boolean;
  sizeFactor?: number;
  showOnlyOnline?: boolean;
}

const SessionUserGroup: React.FC<IProps> = (props: IProps) => {
  const { sessionUsers, rippleActivated, sizeFactor, showOnlyOnline } = props;
  const theme: Theme = useTheme();
  const { user } = useAuth();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'));

  const determineMaxAvatars = () => {
    if (smDown || mdDown) {
      return 2;
    }
    if (lgDown) {
      return 3;
    }
    return 5;
  };

  const renderExtraAvatars = (users) => (
    <ExtraUserPopover
      users={users.map(([, value]) => value)}
      sizeFactor={sizeFactor || 0.85}
      rippleActivated={rippleActivated}
    />
  );

  const renderAvatars = () => {
    const items = [];
    if (sessionUsers) {
      const maxAvatars = determineMaxAvatars();
      let userArray = showOnlyOnline
        ? Object.entries(sessionUsers).filter(([, value]) => value.active)
        : Object.entries(sessionUsers);
      let extraUsers;
      if (userArray.length > maxAvatars) {
        // get n-last items
        extraUsers = userArray.splice(
          maxAvatars,
          userArray.length - maxAvatars
        );
        // get first items
        userArray = userArray.splice(userArray.length - maxAvatars, maxAvatars);
      }

      userArray.forEach(([, value]) => {
        items.push(
          <CustomTooltip
            key={`${value.displayName}-avatar`}
            title={
              user.id === value.id
                ? `${value.displayName} (you)`
                : value.displayName
            }
          >
            <StyledBadge
              isonline={value.active && 'true'}
              rippleactivated={rippleActivated}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
            >
              <CustomAvatar
                alt={value?.displayName}
                src={value?.photoURL}
                isTeamAvatar={false}
                sizeFactor={sizeFactor || 0.85}
              />
            </StyledBadge>
          </CustomTooltip>
        );
      });
      if (extraUsers) {
        items.push(renderExtraAvatars(extraUsers));
      }
    }
    return items;
  };

  return <StyledAvatarGroup max={999}>{renderAvatars()}</StyledAvatarGroup>;
};

export default SessionUserGroup;
