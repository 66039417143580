import {
  ListItemText,
  MenuItem,
  Popover,
  Typography,
  useTheme
} from '@mui/material';
import type { FC } from 'react';
import { playSortByOptions, PlaysSortByOptions } from 'src/utils/sortPlays';

interface IProps {
  selectedSortByOption: PlaysSortByOptions;
  setSelectedSortByOption: Function;
  anchorRef: React.MutableRefObject<HTMLButtonElement>;
  open: boolean;
  setOpen: Function;
}

const SortByPlaysListPopover: FC<IProps> = (props: IProps) => {
  const {
    selectedSortByOption,
    setSelectedSortByOption,
    anchorRef,
    open,
    setOpen
  } = props;
  const theme = useTheme();

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChangeSortByOption = (optionKey: string): void => {
    setSelectedSortByOption(optionKey);
    setOpen(false);
  };

  return (
    <Popover
      anchorEl={anchorRef.current}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      keepMounted
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          width: 170,
          mt: 1,

          boxShadow: theme.shadows[23],
          backgroundColor:
            theme.palette.mode === 'dark'
              ? theme.palette.background.paper
              : theme.palette.background.default,
          border: `1px solid ${theme.palette.divider}`
        }
      }}
    >
      {Object.keys(playSortByOptions).map((optionKey) => (
        <MenuItem
          onClick={() => handleChangeSortByOption(optionKey)}
          key={optionKey}
          sx={{
            px: 2,
            backgroundColor:
              selectedSortByOption === optionKey
                ? theme.palette.action.selected
                : null
          }}
        >
          <ListItemText
            primary={
              <Typography color="textPrimary" variant="subtitle2">
                {playSortByOptions[optionKey].label}
              </Typography>
            }
          />
        </MenuItem>
      ))}
    </Popover>
  );
};

export default SortByPlaysListPopover;
