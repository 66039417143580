import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Popover,
  Theme,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import React, { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toggleVisibility } from 'src/slices/board/boardUi';
import store, { RootState, useSelector } from 'src/store';
import { BoardVisibilities, IBoardVisibility } from 'src/types/board';
import { shallowEqual } from 'react-redux';
import CustomTooltip from 'src/components/CustomTooltip';
import hexToRGB from 'src/utils/hexToRGB';
import { customOpacityDarkThemeBoard } from 'src/constants';

interface IProps {
  sx?: SxProps<Theme>;
}

const VisibilitySelector: FC<IProps> = (props: IProps): ReactElement => {
  const { sx } = props;
  const theme: Theme = useTheme();
  const { t } = useTranslation();
  const {
    mapBaseVisible,
    mapObjectsVisible,
    mapAreasVisible,
    mapLabelsVisible,
    linesVisible,
    objectsVisible,
    connectionsVisible,
    areasOfEffectVisible
  }: IBoardVisibility = useSelector(
    (state: RootState) => state.boardSlice.ui.visibility,
    shallowEqual
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const handleVisibility = (newVisibility: BoardVisibilities) => {
    store.dispatch(toggleVisibility(newVisibility));
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="cb-board-map-selector">
      <CustomTooltip title="Visibility" placement="top">
        <IconButton onClick={handleOpen} size="large" sx={{ ...sx }}>
          <VisibilityIcon />
        </IconButton>
      </CustomTooltip>

      <Popover
        onClose={handleClose}
        anchorEl={anchorEl}
        open={!!anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        PaperProps={{
          sx: {
            mt: `-${theme.spacing(2)}`,
            border: '0px !important',
            boxShadow: theme.shadows[19]
          }
        }}
      >
        <FormControl component="fieldset">
          <Grid
            sx={{
              pl: 3,
              pr: 1,
              py: 1,
              backgroundColor: hexToRGB(
                theme.palette.primary.main,
                customOpacityDarkThemeBoard.toString()
              )
            }}
          >
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ mr: 2 }}
                    checked={mapBaseVisible}
                    color="primary"
                    onChange={() =>
                      handleVisibility(BoardVisibilities.MAP_BASE)
                    }
                    name="mapBase"
                  />
                }
                label={`${t('play.board.visibilitySelector.checkbox.mapBase')}`}
              />
            </Grid>
            <Grid item sx={{ pt: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ mr: 2 }}
                    checked={mapObjectsVisible}
                    color="primary"
                    onChange={() =>
                      handleVisibility(BoardVisibilities.MAP_OBJECTS)
                    }
                    name="mapObjects"
                  />
                }
                label={`${t(
                  'play.board.visibilitySelector.checkbox.mapObjects'
                )}`}
              />
            </Grid>

            <Grid item sx={{ pt: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ mr: 2 }}
                    checked={mapAreasVisible}
                    color="primary"
                    onChange={() =>
                      handleVisibility(BoardVisibilities.MAP_AREAS)
                    }
                    name="mapAreas"
                  />
                }
                label={`${t(
                  'play.board.visibilitySelector.checkbox.mapAreas'
                )}`}
              />
            </Grid>

            <Grid item sx={{ pt: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ mr: 2 }}
                    checked={mapLabelsVisible}
                    color="primary"
                    onChange={() =>
                      handleVisibility(BoardVisibilities.MAP_LABELS)
                    }
                    name="mapLabels"
                  />
                }
                label={`${t(
                  'play.board.visibilitySelector.checkbox.mapLabels'
                )}`}
              />
            </Grid>
            <Grid item sx={{ pt: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ mr: 2 }}
                    checked={linesVisible}
                    color="primary"
                    onChange={() => handleVisibility(BoardVisibilities.LINES)}
                    name="lines"
                  />
                }
                label={`${t('play.board.visibilitySelector.checkbox.lines')}`}
              />
            </Grid>
            <Grid item sx={{ pt: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ mr: 2 }}
                    checked={objectsVisible}
                    color="primary"
                    onChange={() => handleVisibility(BoardVisibilities.OBJECTS)}
                    name="objects"
                  />
                }
                label={`${t('play.board.visibilitySelector.checkbox.objects')}`}
              />
            </Grid>
            <Grid item sx={{ pt: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ mr: 2 }}
                    checked={connectionsVisible}
                    color="primary"
                    onChange={() =>
                      handleVisibility(BoardVisibilities.CONNECTIONS)
                    }
                    name="connections"
                  />
                }
                label={`${t(
                  'play.board.visibilitySelector.checkbox.connections'
                )}`}
              />
            </Grid>
            <Grid item sx={{ pt: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ mr: 2 }}
                    checked={areasOfEffectVisible}
                    color="primary"
                    onChange={() =>
                      handleVisibility(BoardVisibilities.AREASOFEFFECT)
                    }
                    name="areasOfEffect"
                  />
                }
                label={`${t(
                  'play.board.visibilitySelector.checkbox.areasOfEffect'
                )}`}
              />
            </Grid>
          </Grid>
        </FormControl>
      </Popover>
    </div>
  );
};

export default VisibilitySelector;
