import PropTypes from 'prop-types';
import React from 'react';

// Omits "keysToOmit" from "object"
export function omit(object, keysToOmit) {
  const result = {};

  Object.keys(object).forEach((key) => {
    if (keysToOmit.indexOf(key) === -1) {
      result[key] = object[key];
    }
  });

  return result;
}

const PROPS_TO_OMIT = [
  'children',
  'contentClassName',
  'ratio',
  'ratioClassName',
  'style',
  'tagName'
];

const AspectRatio = (props) => {
  const {
    children,
    className,
    contentClassName,
    ratio,
    ratioClassName,
    style,
    tagName
  } = props;

  const Tag = tagName;

  const cssStyle = {
    display: 'block',
    ...style
  };

  const paddingTop = ratio === 0 ? 100 : 100 / ratio;

  return (
    <Tag
      {...omit(props, PROPS_TO_OMIT)}
      className={`Ratio ${className}`}
      style={cssStyle}
    >
      <div
        className={`Ratio-ratio ${ratioClassName}`}
        style={{
          height: 0,
          position: 'relative',
          width: '100%',
          paddingTop: `${paddingTop}%`
        }}
      >
        <div
          className={`Ratio-content ${contentClassName}`}
          style={{
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%'
          }}
        >
          {children}
        </div>
      </div>
    </Tag>
  );
};

AspectRatio.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  ratio: PropTypes.number,
  ratioClassName: PropTypes.string,
  style: PropTypes.object,
  tagName: PropTypes.string
};

AspectRatio.defaultProps = {
  children: null,
  className: '',
  contentClassName: '',
  ratio: 1,
  ratioClassName: '',
  style: {},
  tagName: 'div'
};

export default AspectRatio;
