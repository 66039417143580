import { signInAnonymously } from '@firebase/auth';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SnackbarProvider } from 'notistack';
import { FC, useEffect, useRef } from 'react';
import { useRoutes } from 'react-router-dom';
import GlobalStyles from './theme/GlobalStyles';
import SplashScreen from './components/SplashScreen';
import { loggerConfig } from './config';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import './i18n';
import { auth } from './lib/firebase';
import { logger } from './logging/clientSideLogger';
import routes from './routes';
import { createTheme } from './theme';
import { SnackbarUtilsConfigurator } from './utils/snackbar/SnackbarUtils';

const useStyles = makeStyles({
  containerRoot: {
    marginTop: 65
  } // a style rule
});

const Website: FC = () => {
  const content = useRoutes(routes);
  const notiStackRef = useRef(null);
  const { settings } = useSettings();
  const classes = useStyles();
  const authContext = useAuth();
  useScrollReset();

  logger.init(loggerConfig);

  const theme = createTheme({
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme === 'dark' ? 'dark' : 'light'
  });

  const onClickDismiss = (key) => () => {
    notiStackRef.current.closeSnackbar(key);
  };

  // ensure that users are ALWAYS authenticated - if they don't use an authentication provider (e.g. "email" or "google.com") they will be authenticated as an anonymous user
  // anonymous user authentication is used to track events by anonymous users in mixpanel and link them (call alias()) to existing accounts if they sign in or register
  useEffect(() => {
    if (!authContext.isAuthenticated) {
      signInAnonymously(auth)
        .then(() => {
          // Signed in..
        })
        .catch((error) => {
          console.log(error);
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // ...
        });
    }
  }, [authContext.isAuthenticated]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          ref={notiStackRef}
          dense
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          action={(key) => (
            <IconButton onClick={onClickDismiss(key)}>
              <CloseIcon />
            </IconButton>
          )}
          classes={{ containerRoot: classes.containerRoot }}
        >
          {/* we use this to be able to call the snackbar outside of react files */}
          <SnackbarUtilsConfigurator />
          <GlobalStyles />
          {authContext.isInitialised ? content : <SplashScreen />}
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Website;
