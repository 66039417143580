// astra
import { cloneElement } from 'react';
import { ReactComponent as AstraCosmicDivideIcon } from 'src/ressources/shapes/abilities/astra/cosmic-divide.svg';
import { ReactComponent as AstraGravityWellIcon } from 'src/ressources/shapes/abilities/astra/gravity-well.svg';
import { ReactComponent as AstraNebulaIcon } from 'src/ressources/shapes/abilities/astra/nebula.svg';
import { ReactComponent as AstraNovaPulseIcon } from 'src/ressources/shapes/abilities/astra/nova-pulse.svg';
// breach
import { ReactComponent as BreachAftershockIcon } from 'src/ressources/shapes/abilities/breach/aftershock.svg';
import { ReactComponent as BreachFaultLineIcon } from 'src/ressources/shapes/abilities/breach/fault-line.svg';
import { ReactComponent as BreachFlashpointIcon } from 'src/ressources/shapes/abilities/breach/flashpoint.svg';
import { ReactComponent as BreachRollingThunderIcon } from 'src/ressources/shapes/abilities/breach/rolling-thunder.svg';
// brimstone
import { ReactComponent as BrimstoneIncendiaryIcon } from 'src/ressources/shapes/abilities/brimstone/incendiary.svg';
import { ReactComponent as BrimstoneOrbitalStrikeIcon } from 'src/ressources/shapes/abilities/brimstone/orbital-strike.svg';
import { ReactComponent as BrimstoneSkySmokeIcon } from 'src/ressources/shapes/abilities/brimstone/sky-smoke.svg';
import { ReactComponent as BrimstoneStimBeaconIcon } from 'src/ressources/shapes/abilities/brimstone/stim-beacon.svg';
// chamber
import { ReactComponent as ChamberHeadhunterIcon } from 'src/ressources/shapes/abilities/chamber/headhunter.svg';
import { ReactComponent as ChamberRendezvousIcon } from 'src/ressources/shapes/abilities/chamber/rendezvous.svg';
import { ReactComponent as ChamberTourdefranceIcon } from 'src/ressources/shapes/abilities/chamber/tour-de-france.svg';
import { ReactComponent as ChamberTrademarkIcon } from 'src/ressources/shapes/abilities/chamber/trademark.svg';
// cypher
import { ReactComponent as CypherCyberCageIcon } from 'src/ressources/shapes/abilities/cypher/cyber-cage.svg';
import { ReactComponent as CypherNeuralTheftIcon } from 'src/ressources/shapes/abilities/cypher/neural-theft.svg';
import { ReactComponent as CypherSpycamIcon } from 'src/ressources/shapes/abilities/cypher/spycam.svg';
import { ReactComponent as CypherTrapwireIcon } from 'src/ressources/shapes/abilities/cypher/trapwire.svg';
// fade
import { ReactComponent as FadeSeizeIcon } from 'src/ressources/shapes/abilities/fade/seize.svg';
import { ReactComponent as FadeHauntIcon } from 'src/ressources/shapes/abilities/fade/haunt.svg';
import { ReactComponent as FadeProwlerIcon } from 'src/ressources/shapes/abilities/fade/prowler.svg';
import { ReactComponent as FadeNightfallIcon } from 'src/ressources/shapes/abilities/fade/nightfall.svg';
// jett
import { ReactComponent as JettBladeStormIcon } from 'src/ressources/shapes/abilities/jett/blade-storm.svg';
import { ReactComponent as JettCloudburstIcon } from 'src/ressources/shapes/abilities/jett/cloudburst.svg';
import { ReactComponent as JettTailwindIcon } from 'src/ressources/shapes/abilities/jett/tailwind.svg';
import { ReactComponent as JettUpdraftIcon } from 'src/ressources/shapes/abilities/jett/updraft.svg';
// kayo
import { ReactComponent as KayoFlashDriveIcon } from 'src/ressources/shapes/abilities/kayo/fragment.svg';
import { ReactComponent as KayoFragmentIcon } from 'src/ressources/shapes/abilities/kayo/flash-drive.svg';
import { ReactComponent as KayoNullCMDIcon } from 'src/ressources/shapes/abilities/kayo/null-cmd.svg';
import { ReactComponent as KayoZeroPointIcon } from 'src/ressources/shapes/abilities/kayo/zero-point.svg';
// killjoy
import { ReactComponent as KilljoyAlarmbotIcon } from 'src/ressources/shapes/abilities/killjoy/alarmbot.svg';
import { ReactComponent as KilljoyLockdownIcon } from 'src/ressources/shapes/abilities/killjoy/lockdown.svg';
import { ReactComponent as KilljoyNanoswarmIcon } from 'src/ressources/shapes/abilities/killjoy/nanoswarm.svg';
import { ReactComponent as KilljoyTurretIcon } from 'src/ressources/shapes/abilities/killjoy/turret.svg';
// omen
import { ReactComponent as OmenDarkCoverIcon } from 'src/ressources/shapes/abilities/omen/dark-cover.svg';
import { ReactComponent as OmenFromTheShadowsIcon } from 'src/ressources/shapes/abilities/omen/from-the-shadows.svg';
import { ReactComponent as OmenParanoiaIcon } from 'src/ressources/shapes/abilities/omen/paranoia.svg';
import { ReactComponent as OmenShroudedStepIcon } from 'src/ressources/shapes/abilities/omen/shrouded-step.svg';
// phoenix
import { ReactComponent as PhoenixBlazeIcon } from 'src/ressources/shapes/abilities/phoenix/blaze.svg';
import { ReactComponent as PhoenixCurveballIcon } from 'src/ressources/shapes/abilities/phoenix/curveball.svg';
import { ReactComponent as PhoenixHotHandsIcon } from 'src/ressources/shapes/abilities/phoenix/hot-hands.svg';
import { ReactComponent as PhoenixRunItBackIcon } from 'src/ressources/shapes/abilities/phoenix/run-it-back.svg';
// raze
import { ReactComponent as RazeBlastPackIcon } from 'src/ressources/shapes/abilities/raze/blast-pack.svg';
import { ReactComponent as RazeBoomBotIcon } from 'src/ressources/shapes/abilities/raze/boom-bot.svg';
import { ReactComponent as RazePaintShellsIcon } from 'src/ressources/shapes/abilities/raze/paint-shells.svg';
import { ReactComponent as RazeShowstopperIcon } from 'src/ressources/shapes/abilities/raze/showstopper.svg';
// reyna
import { ReactComponent as ReynaDevourIcon } from 'src/ressources/shapes/abilities/reyna/devour.svg';
import { ReactComponent as ReynaDismissIcon } from 'src/ressources/shapes/abilities/reyna/dismiss.svg';
import { ReactComponent as ReynaEmpressIcon } from 'src/ressources/shapes/abilities/reyna/empress.svg';
import { ReactComponent as ReynaLeerIcon } from 'src/ressources/shapes/abilities/reyna/leer.svg';
// sage
import { ReactComponent as SageBarrierOrbIcon } from 'src/ressources/shapes/abilities/sage/barrier-orb.svg';
import { ReactComponent as SageHealingOrbIcon } from 'src/ressources/shapes/abilities/sage/healing-orb.svg';
import { ReactComponent as SageResurrectionIcon } from 'src/ressources/shapes/abilities/sage/resurrection.svg';
import { ReactComponent as SageSlowOrbIcon } from 'src/ressources/shapes/abilities/sage/slow-orb.svg';
import { ReactComponent as SkyeGuidingLightIcon } from 'src/ressources/shapes/abilities/skye/guiding_light.svg';
// skye
import { ReactComponent as SkyeRegrowthIcon } from 'src/ressources/shapes/abilities/skye/regrowth.svg';
import { ReactComponent as SkyeSeekersIcon } from 'src/ressources/shapes/abilities/skye/seekers.svg';
import { ReactComponent as SkyeTrailblazerIcon } from 'src/ressources/shapes/abilities/skye/trailblazer.svg';
// sova
import { ReactComponent as SovaHuntersFuryIcon } from 'src/ressources/shapes/abilities/sova/hunters-fury.svg';
import { ReactComponent as SovaOwlDroneIcon } from 'src/ressources/shapes/abilities/sova/owl-drone.svg';
import { ReactComponent as SovaReconBoltIcon } from 'src/ressources/shapes/abilities/sova/recon-bolt.svg';
import { ReactComponent as SovaShockBoltIcon } from 'src/ressources/shapes/abilities/sova/shock-bolt.svg';
// viper
import { ReactComponent as ViperPoisonCloudIcon } from 'src/ressources/shapes/abilities/viper/poison-cloud.svg';
import { ReactComponent as ViperSnakeBiteIcon } from 'src/ressources/shapes/abilities/viper/snake-bite.svg';
import { ReactComponent as ViperToxicScreenIcon } from 'src/ressources/shapes/abilities/viper/toxic-screen.svg';
import { ReactComponent as ViperVipersPitIcon } from 'src/ressources/shapes/abilities/viper/vipers-pit.svg';
// yoru
import { ReactComponent as YoruBlindSideIcon } from 'src/ressources/shapes/abilities/yoru/blindside.svg';
import { ReactComponent as YoruDimensionalDriftIcon } from 'src/ressources/shapes/abilities/yoru/dimensional_drift.svg';
import { ReactComponent as YoruFakeoutIcon } from 'src/ressources/shapes/abilities/yoru/fakeout.svg';
import { ReactComponent as YoruGatecrashIcon } from 'src/ressources/shapes/abilities/yoru/gatecrash.svg';
// neon
import { ReactComponent as NeonRelayBoltIcon } from 'src/ressources/shapes/abilities/neon/relay_bolt.svg';
import { ReactComponent as NeonHighGearIcon } from 'src/ressources/shapes/abilities/neon/high_gear.svg';
import { ReactComponent as NeonFastLaneIcon } from 'src/ressources/shapes/abilities/neon/fast_lane.svg';
import { ReactComponent as NeonOverdriveIcon } from 'src/ressources/shapes/abilities/neon/overdrive.svg';
// harbor
import { ReactComponent as HarborHighTideIcon } from 'src/ressources/shapes/abilities/harbor/high-tide.svg';
import { ReactComponent as HarborCoveIcon } from 'src/ressources/shapes/abilities/harbor/cove.svg';
import { ReactComponent as HarborCascadeIcon } from 'src/ressources/shapes/abilities/harbor/cascade.svg';
import { ReactComponent as HarborReckoningIcon } from 'src/ressources/shapes/abilities/harbor/reckoning.svg';
// gekko
import { ReactComponent as GekkoWingmanIcon } from 'src/ressources/shapes/abilities/gekko/wingman.svg';
import { ReactComponent as GekkoDizzyIcon } from 'src/ressources/shapes/abilities/gekko/dizzy.svg';
import { ReactComponent as GekkoMoshpitIcon } from 'src/ressources/shapes/abilities/gekko/moshpit.svg';
import { ReactComponent as GekkoThrashIcon } from 'src/ressources/shapes/abilities/gekko/thrash.svg';

// deadlock
import { ReactComponent as DeadlockAnnihilationIcon } from 'src/ressources/shapes/abilities/deadlock/annihilation.svg';
import { ReactComponent as DeadlockBarrierMeshIcon } from 'src/ressources/shapes/abilities/deadlock/barrier_mesh.svg';
import { ReactComponent as DeadlockGravnetIcon } from 'src/ressources/shapes/abilities/deadlock/gravnet.svg';
import { ReactComponent as DeadlockSonicSensorIcon } from 'src/ressources/shapes/abilities/deadlock/sonic_sensor.svg';

import hexToRGB from 'src/utils/hexToRGB';
import { NestedValorantItemListObjects } from '../../components/app/board/tools/ValorantItemList';
import { AgentTypes } from '../../types/board';

export const getAbilityIconColored = (
  color: string,
  icon: React.ReactElement
) =>
  cloneElement(icon, {
    style: {
      fill: color
    }
  });

const colorLight = hexToRGB('#ffffff', '0.8');
const colorDark = hexToRGB('#000000', '0.55');

export const ABILITIES: NestedValorantItemListObjects = {
  [AgentTypes.ASTRA]: {
    Q: {
      title: 'Nova Pulse',
      value: `${AgentTypes.ASTRA}.Q`,
      icon: getAbilityIconColored(colorLight, <AstraNovaPulseIcon />)
    },
    E: {
      title: 'Nebula',
      value: `${AgentTypes.ASTRA}.E`,
      icon: getAbilityIconColored(colorLight, <AstraNebulaIcon />)
    },
    C: {
      title: 'Gravity Well',
      value: `${AgentTypes.ASTRA}.C`,
      icon: getAbilityIconColored(colorLight, <AstraGravityWellIcon />)
    },
    X: {
      title: 'Astral Form / Cosmic Divide',
      value: `${AgentTypes.ASTRA}.X`,
      icon: getAbilityIconColored(colorLight, <AstraCosmicDivideIcon />)
    }
  },
  [AgentTypes.BREACH]: {
    Q: {
      title: 'Flashpoint',
      value: `${AgentTypes.BREACH}.Q`,
      icon: getAbilityIconColored(colorLight, <BreachFlashpointIcon />)
    },
    E: {
      title: 'Fault Line',
      value: `${AgentTypes.BREACH}.E`,
      icon: getAbilityIconColored(colorLight, <BreachFaultLineIcon />)
    },
    C: {
      title: 'Aftershock',
      value: `${AgentTypes.BREACH}.C`,
      icon: getAbilityIconColored(colorLight, <BreachAftershockIcon />)
    },
    X: {
      title: 'Rolling Thunder',
      value: `${AgentTypes.BREACH}.X`,
      icon: getAbilityIconColored(colorLight, <BreachRollingThunderIcon />)
    }
  },

  [AgentTypes.BRIMSTONE]: {
    Q: {
      title: 'Incendiary',
      value: `${AgentTypes.BRIMSTONE}.Q`,
      icon: getAbilityIconColored(colorLight, <BrimstoneIncendiaryIcon />)
    },
    E: {
      title: 'Sky Smoke',
      value: `${AgentTypes.BRIMSTONE}.E`,
      icon: getAbilityIconColored(colorLight, <BrimstoneSkySmokeIcon />)
    },
    C: {
      title: 'Stim Beacon',
      value: `${AgentTypes.BRIMSTONE}.C`,
      icon: getAbilityIconColored(colorLight, <BrimstoneStimBeaconIcon />)
    },
    X: {
      title: 'Orbital Strike',
      value: `${AgentTypes.BRIMSTONE}.X`,
      icon: getAbilityIconColored(colorLight, <BrimstoneOrbitalStrikeIcon />)
    }
  },
  [AgentTypes.CHAMBER]: {
    Q: {
      title: 'Headhunter',
      value: `${AgentTypes.CHAMBER}.Q`,
      icon: getAbilityIconColored(colorDark, <ChamberHeadhunterIcon />)
    },
    E: {
      title: 'Rendezvous',
      value: `${AgentTypes.CHAMBER}.E`,
      icon: getAbilityIconColored(colorDark, <ChamberRendezvousIcon />)
    },
    C: {
      title: 'Trademark',
      value: `${AgentTypes.CHAMBER}.C`,
      icon: getAbilityIconColored(colorDark, <ChamberTrademarkIcon />)
    },
    X: {
      title: 'Tour de France',
      value: `${AgentTypes.CHAMBER}.X`,
      icon: getAbilityIconColored(colorDark, <ChamberTourdefranceIcon />)
    }
  },
  [AgentTypes.CYPHER]: {
    Q: {
      title: 'Cyber Cage',
      value: `${AgentTypes.CYPHER}.Q`,
      icon: getAbilityIconColored(colorDark, <CypherCyberCageIcon />)
    },
    E: {
      title: 'Spycam',
      value: `${AgentTypes.CYPHER}.E`,
      icon: getAbilityIconColored(colorDark, <CypherSpycamIcon />)
    },
    C: {
      title: 'Trapwire',
      value: `${AgentTypes.CYPHER}.C`,
      icon: getAbilityIconColored(colorDark, <CypherTrapwireIcon />)
    },
    X: {
      title: 'Neural Theft',
      value: `${AgentTypes.CYPHER}.X`,
      icon: getAbilityIconColored(colorDark, <CypherNeuralTheftIcon />)
    }
  },
  [AgentTypes.FADE]: {
    Q: {
      title: 'Seize',
      value: `${AgentTypes.FADE}.Q`,
      icon: getAbilityIconColored(colorLight, <FadeSeizeIcon />)
    },
    E: {
      title: 'Haunt',
      value: `${AgentTypes.FADE}.E`,
      icon: getAbilityIconColored(colorLight, <FadeHauntIcon />)
    },
    C: {
      title: 'Prowler',
      value: `${AgentTypes.FADE}.C`,
      icon: getAbilityIconColored(colorLight, <FadeProwlerIcon />)
    },
    X: {
      title: 'Nightfall',
      value: `${AgentTypes.FADE}.X`,
      icon: getAbilityIconColored(colorLight, <FadeNightfallIcon />)
    }
  },
  [AgentTypes.JETT]: {
    Q: {
      title: 'Updraft',
      value: `${AgentTypes.JETT}.Q`,
      icon: getAbilityIconColored(colorDark, <JettUpdraftIcon />)
    },
    E: {
      title: 'Tailwind',
      value: `${AgentTypes.JETT}.E`,
      icon: getAbilityIconColored(colorDark, <JettTailwindIcon />)
    },
    C: {
      title: 'Cloudburst',
      value: `${AgentTypes.JETT}.C`,
      icon: getAbilityIconColored(colorDark, <JettCloudburstIcon />)
    },
    X: {
      title: 'Blade Storm',
      value: `${AgentTypes.JETT}.X`,
      icon: getAbilityIconColored(colorDark, <JettBladeStormIcon />)
    }
  },
  [AgentTypes.KAYO]: {
    Q: {
      title: 'FLASH/DRIVE',
      value: `${AgentTypes.KAYO}.Q`,
      icon: getAbilityIconColored(colorLight, <KayoFlashDriveIcon />)
    },
    E: {
      title: 'ZERO/POINT',
      value: `${AgentTypes.KAYO}.E`,
      icon: getAbilityIconColored(colorLight, <KayoZeroPointIcon />)
    },
    C: {
      title: 'FRAG/MENT',
      value: `${AgentTypes.KAYO}.C`,
      icon: getAbilityIconColored(colorLight, <KayoFragmentIcon />)
    },
    X: {
      title: 'NULL/CMD',
      value: `${AgentTypes.KAYO}.X`,
      icon: getAbilityIconColored(colorLight, <KayoNullCMDIcon />)
    }
  },
  [AgentTypes.KILLJOY]: {
    Q: {
      title: 'Alarmbot',
      value: `${AgentTypes.KILLJOY}.Q`,
      icon: getAbilityIconColored(colorDark, <KilljoyAlarmbotIcon />)
    },
    E: {
      title: 'Turret',
      value: `${AgentTypes.KILLJOY}.E`,
      icon: getAbilityIconColored(colorDark, <KilljoyTurretIcon />)
    },
    C: {
      title: 'Nanoswarm',
      value: `${AgentTypes.KILLJOY}.C`,
      icon: getAbilityIconColored(colorDark, <KilljoyNanoswarmIcon />)
    },
    X: {
      title: 'Lockdown',
      value: `${AgentTypes.KILLJOY}.X`,
      icon: getAbilityIconColored(colorDark, <KilljoyLockdownIcon />)
    }
  },
  [AgentTypes.OMEN]: {
    Q: {
      title: 'Paranoia',
      value: `${AgentTypes.OMEN}.Q`,
      icon: getAbilityIconColored(colorLight, <OmenParanoiaIcon />)
    },
    E: {
      title: 'Dark Cover',
      value: `${AgentTypes.OMEN}.E`,
      icon: getAbilityIconColored(colorLight, <OmenDarkCoverIcon />)
    },
    C: {
      title: 'Shrouded Step',
      value: `${AgentTypes.OMEN}.C`,
      icon: getAbilityIconColored(colorLight, <OmenShroudedStepIcon />)
    },
    X: {
      title: 'From the Shadows',
      value: `${AgentTypes.OMEN}.X`,
      icon: getAbilityIconColored(colorLight, <OmenFromTheShadowsIcon />)
    }
  },
  [AgentTypes.PHOENIX]: {
    Q: {
      title: 'Curveball',
      value: `${AgentTypes.PHOENIX}.Q`,
      icon: getAbilityIconColored(colorLight, <PhoenixCurveballIcon />)
    },
    E: {
      title: 'Hot Hands',
      value: `${AgentTypes.PHOENIX}.E`,
      icon: getAbilityIconColored(colorLight, <PhoenixHotHandsIcon />)
    },
    C: {
      title: 'Blaze',
      value: `${AgentTypes.PHOENIX}.C`,
      icon: getAbilityIconColored(colorLight, <PhoenixBlazeIcon />)
    },
    X: {
      title: 'Run it back',
      value: `${AgentTypes.PHOENIX}.X`,
      icon: getAbilityIconColored(colorLight, <PhoenixRunItBackIcon />)
    }
  },
  [AgentTypes.RAZE]: {
    Q: {
      title: 'Blast Pack',
      value: `${AgentTypes.RAZE}.Q`,
      icon: getAbilityIconColored(colorDark, <RazeBlastPackIcon />)
    },
    E: {
      title: 'Paint Shells',
      value: `${AgentTypes.RAZE}.E`,
      icon: getAbilityIconColored(colorDark, <RazePaintShellsIcon />)
    },
    C: {
      title: 'Boom Bot',
      value: `${AgentTypes.RAZE}.C`,
      icon: getAbilityIconColored(colorDark, <RazeBoomBotIcon />)
    },
    X: {
      title: 'Showstopper',
      value: `${AgentTypes.RAZE}.X`,
      icon: getAbilityIconColored(colorDark, <RazeShowstopperIcon />)
    }
  },
  [AgentTypes.REYNA]: {
    Q: {
      title: 'Devour',
      value: `${AgentTypes.REYNA}.Q`,
      icon: getAbilityIconColored(colorLight, <ReynaDevourIcon />)
    },
    E: {
      title: 'Dismiss',
      value: `${AgentTypes.REYNA}.E`,
      icon: getAbilityIconColored(colorLight, <ReynaDismissIcon />)
    },
    C: {
      title: 'Leer',
      value: `${AgentTypes.REYNA}.C`,
      icon: getAbilityIconColored(colorLight, <ReynaLeerIcon />)
    },
    X: {
      title: 'Empress',
      value: `${AgentTypes.REYNA}.X`,
      icon: getAbilityIconColored(colorLight, <ReynaEmpressIcon />)
    }
  },
  [AgentTypes.SAGE]: {
    Q: {
      title: 'Slow Orb',
      value: `${AgentTypes.SAGE}.Q`,
      icon: getAbilityIconColored(colorDark, <SageSlowOrbIcon />)
    },
    E: {
      title: 'Healing Orb',
      value: `${AgentTypes.SAGE}.E`,
      icon: getAbilityIconColored(colorDark, <SageHealingOrbIcon />)
    },
    C: {
      title: 'Barrier Orb',
      value: `${AgentTypes.SAGE}.C`,
      icon: getAbilityIconColored(colorDark, <SageBarrierOrbIcon />)
    },
    X: {
      title: 'Ressurection',
      value: `${AgentTypes.SAGE}.X`,
      icon: getAbilityIconColored(colorDark, <SageResurrectionIcon />)
    }
  },
  [AgentTypes.SKYE]: {
    Q: {
      title: 'Trailblazer',
      value: `${AgentTypes.SKYE}.Q`,
      icon: getAbilityIconColored(colorLight, <SkyeTrailblazerIcon />)
    },
    E: {
      title: 'Guiding Light',
      value: `${AgentTypes.SKYE}.E`,
      icon: getAbilityIconColored(colorLight, <SkyeGuidingLightIcon />)
    },
    C: {
      title: 'Regrowth',
      value: `${AgentTypes.SKYE}.C`,
      icon: getAbilityIconColored(colorLight, <SkyeRegrowthIcon />)
    },
    X: {
      title: 'Seekers',
      value: `${AgentTypes.SKYE}.X`,
      icon: getAbilityIconColored(colorLight, <SkyeSeekersIcon />)
    }
  },
  [AgentTypes.SOVA]: {
    Q: {
      title: 'Shock Bolt',
      value: `${AgentTypes.SOVA}.Q`,
      icon: getAbilityIconColored(colorDark, <SovaShockBoltIcon />)
    },
    E: {
      title: 'Recon Bolt',
      value: `${AgentTypes.SOVA}.E`,
      icon: getAbilityIconColored(colorDark, <SovaReconBoltIcon />)
    },
    C: {
      title: 'Owl Drone',
      value: `${AgentTypes.SOVA}.C`,
      icon: getAbilityIconColored(colorDark, <SovaOwlDroneIcon />)
    },
    X: {
      title: "Hunter's Fury",
      value: `${AgentTypes.SOVA}.X`,
      icon: getAbilityIconColored(colorDark, <SovaHuntersFuryIcon />)
    }
  },
  [AgentTypes.VIPER]: {
    Q: {
      title: 'Poison Cloud',
      value: `${AgentTypes.VIPER}.Q`,
      icon: getAbilityIconColored(colorLight, <ViperPoisonCloudIcon />)
    },
    E: {
      title: 'Toxic Screen',
      value: `${AgentTypes.VIPER}.E`,
      icon: getAbilityIconColored(colorLight, <ViperToxicScreenIcon />)
    },
    C: {
      title: 'Snake Bite',
      value: `${AgentTypes.VIPER}.C`,
      icon: getAbilityIconColored(colorLight, <ViperSnakeBiteIcon />)
    },
    X: {
      title: "Viper's Pit",
      value: `${AgentTypes.VIPER}.X`,
      icon: getAbilityIconColored(colorLight, <ViperVipersPitIcon />)
    }
  },
  [AgentTypes.YORU]: {
    Q: {
      title: 'Blindside',
      value: `${AgentTypes.YORU}.Q`,
      icon: getAbilityIconColored(colorLight, <YoruBlindSideIcon />)
    },
    E: {
      title: 'Gatecrash',
      value: `${AgentTypes.YORU}.E`,
      icon: getAbilityIconColored(colorLight, <YoruGatecrashIcon />)
    },
    C: {
      title: 'Fakeout',
      value: `${AgentTypes.YORU}.C`,
      icon: getAbilityIconColored(colorLight, <YoruFakeoutIcon />)
    },
    X: {
      title: 'Dimensional Drift',
      value: `${AgentTypes.YORU}.X`,
      icon: getAbilityIconColored(colorLight, <YoruDimensionalDriftIcon />)
    }
  },
  [AgentTypes.NEON]: {
    Q: {
      title: 'Relay Bolt',
      value: `${AgentTypes.NEON}.Q`,
      icon: getAbilityIconColored(colorLight, <NeonRelayBoltIcon />)
    },
    E: {
      title: 'High Gear',
      value: `${AgentTypes.NEON}.E`,
      icon: getAbilityIconColored(colorLight, <NeonHighGearIcon />)
    },
    C: {
      title: 'Fast Lane',
      value: `${AgentTypes.NEON}.C`,
      icon: getAbilityIconColored(colorLight, <NeonFastLaneIcon />)
    },
    X: {
      title: 'Overdrive',
      value: `${AgentTypes.NEON}.X`,
      icon: getAbilityIconColored(colorLight, <NeonOverdriveIcon />)
    }
  },
  [AgentTypes.HARBOR]: {
    Q: {
      title: 'Cove',
      value: `${AgentTypes.HARBOR}.Q`,
      icon: getAbilityIconColored(colorLight, <HarborCoveIcon />)
    },
    E: {
      title: 'High Tide',
      value: `${AgentTypes.HARBOR}.E`,
      icon: getAbilityIconColored(colorLight, <HarborHighTideIcon />)
    },
    C: {
      title: 'Cascade',
      value: `${AgentTypes.HARBOR}.C`,
      icon: getAbilityIconColored(colorLight, <HarborCascadeIcon />)
    },
    X: {
      title: 'Reckoning',
      value: `${AgentTypes.HARBOR}.X`,
      icon: getAbilityIconColored(colorLight, <HarborReckoningIcon />)
    }
  },
  [AgentTypes.GEKKO]: {
    Q: {
      title: 'Wingman',
      value: `${AgentTypes.GEKKO}.Q`,
      icon: getAbilityIconColored(colorDark, <GekkoWingmanIcon />)
    },
    E: {
      title: 'Dizzy',
      value: `${AgentTypes.GEKKO}.E`,
      icon: getAbilityIconColored(colorDark, <GekkoDizzyIcon />)
    },
    C: {
      title: 'Mosh Pit',
      value: `${AgentTypes.GEKKO}.C`,
      icon: getAbilityIconColored(colorDark, <GekkoMoshpitIcon />)
    },
    X: {
      title: 'THRASH',
      value: `${AgentTypes.GEKKO}.X`,
      icon: getAbilityIconColored(colorDark, <GekkoThrashIcon />)
    }
  },
  [AgentTypes.DEADLOCK]: {
    Q: {
      title: 'Sonic Sensor',
      value: `${AgentTypes.DEADLOCK}.Q`,
      icon: getAbilityIconColored(colorLight, <DeadlockSonicSensorIcon />)
    },
    E: {
      title: 'Barrier Mesh',
      value: `${AgentTypes.DEADLOCK}.E`,
      icon: getAbilityIconColored(colorLight, <DeadlockBarrierMeshIcon />)
    },
    C: {
      title: 'Gravnet',
      value: `${AgentTypes.DEADLOCK}.C`,
      icon: getAbilityIconColored(colorLight, <DeadlockGravnetIcon />)
    },
    X: {
      title: 'Annihilation',
      value: `${AgentTypes.DEADLOCK}.X`,
      icon: getAbilityIconColored(colorLight, <DeadlockAnnihilationIcon />)
    }
  }
};
