import TopicIcon from '@mui/icons-material/Topic';
import { Box, Container, Grid } from '@mui/material';
import type { FC } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { PlaybookDetails } from 'src/components/app/playbooks';
import PageHeader from 'src/components/PageHeader';
import useElementSize from 'src/hooks/useElementSize';
import useSettings from 'src/hooks/useSettings';
import { RootState, useSelector } from 'src/store';
import { PlaybookInfo } from 'src/types/play';
import { Team, TeamInfo } from 'src/types/team';
import { AccountData } from 'src/types/user';
import selectTeamInfoProperty from 'src/utils/selectTeamInfoProperty';

const PlaybookPage: FC = () => {
  const { teamId } = useParams();
  const { playbookId } = useParams();

  const team: Team = useSelector((state: RootState) => state.teamSlice.team);

  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );

  if (teamId && !team) {
    return null;
  }

  const playbook: PlaybookInfo = teamId
    ? team.playbooksInfo.find((playbookInfo) => playbookInfo.id === playbookId)
    : accountData.playbooksInfo.find(
        (playbookInfo) => playbookInfo.id === playbookId
      );

  const [squareRef, { width, height }] = useElementSize();

  const { settings } = useSettings();
  const teamsInfo: TeamInfo[] = useSelector((state: RootState) =>
    state.accountDataSlice.accountData
      ? state.accountDataSlice.accountData.teamsInfo
      : []
  );

  return (
    <>
      <Helmet>
        <title>Playbook | Critbase</title>
      </Helmet>
      <Box
        sx={{
          height: '100%',
          width: '100%'
        }}
        ref={squareRef}
      >
        <Container
          maxWidth="xl"
          sx={{
            height: 'auto',
            width: '100%',
            p: '0px !important'
          }}
        >
          <Grid
            container
            sx={{
              minWidth: '420px',
              width: '100%',
              maxHeight: '100%'
            }}
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item container sx={{ width: '100%', height: 'auto', p: 7 }}>
              <Grid item xs={12}>
                <PageHeader
                  breadcrumbs={[
                    {
                      title: settings.activeSpaceID
                        ? selectTeamInfoProperty(
                            teamsInfo,
                            settings.activeSpaceID,
                            'displayName'
                          )
                        : 'Personal Space'
                    },
                    { title: `Playbooks` },
                    { title: `${playbook.title}` }
                  ]}
                  headerIcon={<TopicIcon />}
                />
              </Grid>

              <Grid
                item
                container
                sx={{
                  width: '100%',
                  height: 'auto',
                  pt: 3
                }}
              >
                <PlaybookDetails />
              </Grid>
            </Grid>{' '}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default PlaybookPage;
