import { QueryDocumentSnapshot, SnapshotOptions } from 'firebase/firestore';
import { Team } from 'src/types/team';

type TeamNoID = Omit<Team, 'id'>;

const teamConverter = {
  toFirestore: (team: Team): TeamNoID => {
    const teamNoID: TeamNoID = {
      displayName: team.displayName,
      createdAt: team.createdAt,
      description: team.description,
      membersInfo: team.membersInfo,
      adminIDs: team.adminIDs,
      memberIDs: team.memberIDs,
      pendingInvites: team.pendingInvites,
      photoURL: team.photoURL,
      membersCount: team.membersCount,
      sessionRefs: team.sessionRefs || null,
      spaceTier: team.spaceTier || 'basic',
      playsInfo: team.playsInfo,
      playbooksInfo: team.playbooksInfo
    };

    return teamNoID;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Team | undefined {
    const data = snapshot.data(options) || undefined;
    const { id } = snapshot;
    return <Team>{
      id,
      displayName: data.displayName,
      createdAt: data.createdAt,
      description: data.description,
      membersCount: data.membersCount,
      membersInfo: data.membersInfo,
      adminIDs: data.adminIDs,
      memberIDs: data.memberIDs,
      pendingInvites: data.pendingInvites,
      photoURL: data.photoURL,
      sessionRefs: data.sessionRefs || null,
      spaceTier: data.spaceTier || 'basic',
      playsInfo: data.playsInfo,
      playbooksInfo: data.playbooksInfo
    };
  }
};

export { teamConverter };
