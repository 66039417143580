import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { StorageReference } from 'firebase/storage';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import type { FC } from 'react';
import { updateAvatarAndDisplayName } from 'src/data/firestore/setters/account';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { RootState, useSelector } from 'src/store';
import { AccountData } from 'src/types/user';
import * as Yup from 'yup';
import CustomAvatar from '../CustomAvatar';
import ImageUploader, { removeImageByRef } from '../ImageUploader';
import SettingsSection from '../SettingsSection';

const displayUploadedImage = (
  previousImageURL: string | null,
  imageDownloadURL: string | null,
  waitingForImage: boolean
) => {
  if (waitingForImage) {
    return <CircularProgress style={{ height: 90, width: 90, padding: 15 }} />;
  }

  if (imageDownloadURL) {
    return (
      <CustomAvatar
        src={imageDownloadURL}
        sizeFactor={1.5}
        isTeamAvatar={false}
      />
    );
  }

  if (previousImageURL) {
    return (
      <CustomAvatar
        src={previousImageURL}
        sizeFactor={1.5}
        isTeamAvatar={false}
      />
    );
  }

  return <CustomAvatar src={null} sizeFactor={1.25} isTeamAvatar={false} />;
};

const dataChanged = (initalDisplayName, currentDisplayName, imageRef) => {
  if (initalDisplayName === currentDisplayName && !imageRef) {
    return false;
  }

  return true;
};

interface AccountGeneralSettingsProps {
  waitingForImage: boolean;
  setWaitingForImage: Function;
  waitingForAccountSettingsChange: boolean;
  setWaitingForAccountSettingsChange: Function;
  imageRef: StorageReference | null;
  setImageRef: Function;
  imageDownloadURL: string;
  setImageDownloadURL: Function;
  setDialogOpen: Function;
}

const AccountGeneralSettings: FC<AccountGeneralSettingsProps> = (props) => {
  const {
    waitingForImage,
    setWaitingForImage,
    waitingForAccountSettingsChange,
    setWaitingForAccountSettingsChange,
    imageRef,
    setImageRef,
    imageDownloadURL,
    setImageDownloadURL,
    setDialogOpen
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { user } = useAuth();
  const isMountedRef = useIsMountedRef();
  const accountData: AccountData = useSelector(
    (state: RootState) => state.accountDataSlice.accountData
  );
  const { updateUserData } = useAuth();

  return (
    <>
      {waitingForAccountSettingsChange ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '300px' }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{
            name: user.displayName,
            submit: null
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().max(255).required('Name is required')
          })}
          onSubmit={async (
            values,
            { resetForm, setErrors, setStatus, setSubmitting }
          ): Promise<void> => {
            try {
              setWaitingForAccountSettingsChange(true);

              const response: any = await updateAvatarAndDisplayName(
                user.displayName,
                values.name,
                user.photoURL,
                imageDownloadURL
              );

              await updateUserData(
                response.displayName ? response.displayName : null,
                response.photoURL ? response.photoURL : null
              );

              enqueueSnackbar('Profile updated', {
                variant: 'success'
              });

              setWaitingForAccountSettingsChange(false);
              setDialogOpen(false);

              if (isMountedRef.current) {
                resetForm();
                setStatus({ success: true });
                setSubmitting(false);
                setImageRef(null);
                setImageDownloadURL(null);
              }
            } catch (err) {
              console.error(err);

              enqueueSnackbar('Profile update failed', {
                variant: 'error'
              });

              if (isMountedRef.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
                setWaitingForAccountSettingsChange(false);
                setImageRef(null);
                setImageDownloadURL(null);
                await removeImageByRef(imageRef);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }): JSX.Element => (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                columnSpacing={6}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid item container xs={6} rowSpacing={4}>
                  <Grid item xs={12}>
                    <SettingsSection title="avatar">
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          mt: 0,
                          width: '100%',
                          backgroundColor: theme.palette.background.paper,
                          border: `1px solid ${theme.palette.divider}`,
                          borderRadius: `${theme.shape.borderRadius}px`,
                          p: 6
                        }}
                      >
                        <Box>
                          {displayUploadedImage(
                            user.photoURL,
                            imageDownloadURL,
                            waitingForImage
                          )}
                        </Box>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'center',
                            py: 2,
                            width: '100%',
                            px: 4
                          }}
                        >
                          <ImageUploader
                            downloadURL={imageDownloadURL}
                            setDownloadURL={setImageDownloadURL}
                            setImageRef={setImageRef}
                            imageRef={imageRef}
                            storagePath={`users/${user.id}/`}
                            prefix={accountData.username}
                            setWaitingForImage={setWaitingForImage}
                            enqueueSnackbar={enqueueSnackbar}
                          />
                        </Box>

                        <Typography
                          color="textSecondary"
                          variant="body2"
                          align="center"
                        >
                          Click to upload an avatar or change the avatar you
                          have chosen.
                        </Typography>
                      </Grid>
                    </SettingsSection>
                  </Grid>

                  <Grid item xs={12}>
                    <SettingsSection title="display name">
                      <Grid item container spacing={4}>
                        <Grid item xs={12}>
                          <TextField
                            error={Boolean(touched.name && errors.name)}
                            fullWidth
                            helperText={
                              (touched.name && errors.name) ||
                              'Enter your new Display Name'
                            }
                            name="name"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </SettingsSection>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                    {errors.submit && (
                      <Box sx={{ mt: 2 }}>
                        <FormHelperText error>{errors.submit}</FormHelperText>
                      </Box>
                    )}
                    <Button
                      color="primary"
                      disabled={
                        isSubmitting ||
                        waitingForImage ||
                        !dataChanged(user.displayName, values.name, imageRef)
                      }
                      type="submit"
                      variant="contained"
                      sx={{
                        borderRadius: `${theme.shape.borderRadius}px`,
                        mt: 2
                      }}
                    >
                      Update Profile
                    </Button>
                  </Grid>
                </Grid>

                <Grid item container xs={6} rowSpacing={4}>
                  <Grid item xs={12}>
                    <SettingsSection title="note">
                      <Typography color="textSecondary" variant="body2">
                        These information will be publicly viewable by any user.
                        Do not use a Display Name or an Avatar that contains
                        sensitive information and should not be publicly
                        accessible !
                      </Typography>
                    </SettingsSection>
                  </Grid>

                  <Grid item xs={12}>
                    <SettingsSection title="username">
                      <Grid item xs={6}>
                        <Typography variant="body2">
                          {accountData.username}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: `${theme.palette.text.secondary}`,
                            pt: 0.25
                          }}
                        >
                          (not changeable)
                        </Typography>
                      </Grid>
                    </SettingsSection>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

AccountGeneralSettings.propTypes = {
  waitingForImage: PropTypes.bool,
  setWaitingForImage: PropTypes.func,
  waitingForAccountSettingsChange: PropTypes.bool,
  setWaitingForAccountSettingsChange: PropTypes.func,
  imageRef: PropTypes.any,
  setImageRef: PropTypes.func,
  imageDownloadURL: PropTypes.string,
  setImageDownloadURL: PropTypes.func,
  setDialogOpen: PropTypes.func
};

export default AccountGeneralSettings;
