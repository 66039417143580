import { Avatar, Box, Theme, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { OBJECT_SIZE } from 'src/constants';
import { ABILITIES } from 'src/ressources/shapes/abilities';
import { BASIC_OBJECTS } from 'src/ressources/shapes/basicObjects';
import { DragTypes, IObject } from 'src/types/board';
import determineBackgroundColor from 'src/utils/boardUtils/determineBackgroundColor';
import determineBorderColor from 'src/utils/boardUtils/determineBorderColor';
import hexToRGB from 'src/utils/hexToRGB';

interface IProps {
  object: IObject;
  selected: boolean;
  wrapperScale: number;
  mapRotation: number;
  isDragging?: boolean;
}

const SvgAvatar = React.forwardRef<any, IProps>((props: IProps, ref) => {
  const { object, selected, wrapperScale, mapRotation, isDragging } = props;
  const { id, type, value, attack } = object;
  const [agent, button] = value.split('.');
  const theme: Theme = useTheme();

  const renderObjSvg = (dragType: DragTypes, val: string): ReactElement => {
    if (dragType === DragTypes.BASIC) {
      return BASIC_OBJECTS[val]?.icon;
    }
    if (dragType === DragTypes.ABILITY) {
      return ABILITIES[agent]?.[button]?.icon;
    }
    return undefined;
  };

  const padding = () => {
    if (type === DragTypes.BASIC) {
      return 1.8;
    }

    if (type === DragTypes.AGENT) {
      return 0;
    }

    if (type === DragTypes.ABILITY) {
      return 0.33;
    }

    return 0;
  };

  return (
    <Box
      ref={ref}
      sx={{
        cursor: isDragging ? 'grabbing' : 'pointer',
        transform: `scale(${1 / wrapperScale})`,
        position: 'absolute',
        zIndex: 100,
        top: -OBJECT_SIZE / 2,
        left: -OBJECT_SIZE / 2,
        border: selected && `1px solid ${theme.palette.primary.main}`,
        borderRadius: 99,
        padding: selected && '5px',
        margin: selected && '-6px' // to counter the border when selected
      }}
    >
      <Box
        sx={{
          border:
            type === DragTypes.ABILITY
              ? `3px solid ${hexToRGB(determineBorderColor(attack), '0.3')}`
              : '3p solid transparent',
          boxShadow: selected && theme.shadows[19],
          borderRadius: 99,
          height: OBJECT_SIZE,
          width: OBJECT_SIZE
        }}
      >
        <Avatar
          {...props}
          alt={`${value}-${id}`}
          id={`${value}-${id}`}
          variant="circular"
          sx={{
            height: OBJECT_SIZE - 6,
            width: OBJECT_SIZE - 6,
            backgroundColor: determineBackgroundColor(value),
            margin: 0,
            p: padding,
            transform: `rotate(-${mapRotation}deg)`,
            overflow: 'hidden'
          }}
        >
          {renderObjSvg(type, value)}
        </Avatar>
      </Box>
    </Box>
  );
});

export default SvgAvatar;

/*
<Box
sx={{
  transform: `scale(${1 / wrapperScale})`,
  position: 'absolute',
  top: -AGENT_SIZE / 2,
  left: -AGENT_SIZE / 2,
  border: selected && `1px solid ${theme.palette.primary.main}`,
  borderRadius: 99,
  padding: selected && '6px',
  margin: selected && '-7px' // to counter the border when selected
}}
>
<Box
  sx={{
    border: `3px solid ${hexToRGB(determineBorderColor(attack), '0.3')}`,
    borderRadius: 99,
    height: AGENT_SIZE,
    width: AGENT_SIZE
  }}
>
  <Avatar
    {...props}
    alt={`${value}-${id}`}
    id={`${value}-${id}`}
    variant="circular"
    sx={{
      height: AGENT_SIZE - 6,
      width: AGENT_SIZE - 6,
      backgroundColor: determineBackgroundColor(value),
      margin: 0
    }}
    src={renderObjImg(value)}
  />
</Box>

  */
