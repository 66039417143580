import { Team } from 'src/types/team';

export const targetUserPaysForSubscription = (
  team: Team,
  targetUserId: string
) => {
  if (team.spaceTier === 'basic') {
    return false;
  }
  return team.spaceTier.subscriberId === targetUserId;
};
