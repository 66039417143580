import GroupIcon from '@mui/icons-material/Group';
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { RootState, useSelector } from 'src/store';
import { Team, TeamMemberData } from 'src/types/team';
import hexToRGB from 'src/utils/hexToRGB';
import CustomAvatar from '../CustomAvatar';
import { TeamInvitesDialog, TeamSettingsDialog } from '../team';
import { TeamMemberOptionsPopover } from '../team/memberoptions';

/* Constants */

const LIST_LENGTH = 5;

/* Helper */

const teamToTeamMemberData = (team: Team): TeamMemberData[] => {
  if (team) {
    const result: TeamMemberData[] = team.membersInfo.map((member) => ({
      id: member.id,
      name: member.displayName,
      username: member.username,
      joined: member.joinedAt,
      url: member.photoURL,
      alt: member.displayName.charAt(0),
      role: team.adminIDs.includes(member.id) ? 'Admin' : 'Member'
    }));

    return result;
  }
  return [];
};

const getListItemsMainList = (
  xlDown: boolean,
  teamMemberData: TeamMemberData[]
) => {
  if (teamMemberData.length < LIST_LENGTH) {
    return teamMemberData;
  }

  return teamMemberData.slice(0, LIST_LENGTH);
};

interface OverviewTeamMembersProps {
  padding: number;
}

const OverviewTeamMembers: FC<OverviewTeamMembersProps> = (
  props: OverviewTeamMembersProps
) => {
  const { padding } = props;
  const theme = useTheme();
  const xlDown = useMediaQuery(theme.breakpoints.down('xl'));
  const { teamId } = useParams();
  const { user } = useAuth();
  const team: Team = useSelector((state: RootState) => state.teamSlice.team);
  const [teamInvitesDialogOpen, setTeamInvitesDialogOpen] =
    useState<boolean>(false);
  const [teamMembersDialogOpen, setTeamMembersDialogOpen] =
    useState<boolean>(false);
  const teamMemberData = teamToTeamMemberData(team);

  const getUserRoleByID = (targetID: string): 'Member' | 'Admin' => {
    let returnVal: 'Member' | 'Admin' = 'Member';

    if (team.adminIDs.includes(targetID)) {
      returnVal = 'Admin';
    }

    return returnVal;
  };

  if (!teamId || (teamId && !team)) {
    return null;
  }

  return (
    <Grid
      container
      spacing={0}
      sx={{
        height: 'auto',
        borderRadius: `calc(${theme.shape.borderRadius}px)`,
        p: padding
      }}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Box sx={{ height: '100%', width: '100%' }}>
        <List sx={{ height: '100%', py: 0 }}>
          {getListItemsMainList(xlDown, teamMemberData).map((member, i) => (
            <ListItem
              key={`${member.name}`}
              sx={{
                p: 0,
                borderRadius: `calc(${`${theme.shape.borderRadius}px`})`,
                mt: i === 0 ? 0 : 3
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: '100%' }}
              >
                <CustomAvatar
                  src={member.url}
                  alt={member.alt}
                  isTeamAvatar={false}
                />

                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    flexGrow: 1,
                    maxWidth: 'calc(100% - 87px)',
                    px: 0,
                    height: '100%'
                  }}
                >
                  <Typography
                    sx={{
                      color: `${theme.palette.text.primary} !important`,
                      fontWeight: 500,
                      width: '100%'
                    }}
                    variant="h5"
                  >
                    {member.name}
                  </Typography>
                  <Typography
                    sx={{
                      width: '100%'
                    }}
                    variant="subtitle2"
                    color="textSecondary"
                  >
                    {member.role}
                  </Typography>
                </Grid>

                <TeamMemberOptionsPopover
                  userRole={getUserRoleByID(user.id)}
                  targetMemberData={member}
                  teamID={teamId}
                  teamDisplayName={team.displayName ? team.displayName : null}
                />
              </Grid>
            </ListItem>
          ))}
          {teamMemberData?.length >= LIST_LENGTH && (
            <ListItem
              sx={{
                p: 0,
                borderRadius: `calc(${`${theme.shape.borderRadius}px`})`,
                mt: 3
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ width: '100%' }}
              >
                <CustomAvatar
                  src={null}
                  alt="additional-members"
                  isTeamAvatar={false}
                >
                  <GroupIcon
                    sx={{
                      color: hexToRGB(theme.palette.text.secondary, '1')
                    }}
                  />
                </CustomAvatar>

                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    flexGrow: 1,
                    maxWidth: 'calc(100% - 87px)',
                    px: 0,
                    height: '100%',
                    ml: 1.5
                  }}
                >
                  <Typography
                    sx={{
                      color: `${theme.palette.text.primary} !important`,
                      fontWeight: 500,
                      width: '100%'
                    }}
                    variant="h5"
                  >
                    +2 members
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          )}
        </List>
      </Box>
      {teamMemberData?.length >= LIST_LENGTH && (
        <>
          <Grid
            item
            container
            xs={12}
            sx={{ px: 1, pt: 2 }}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="text"
              onClick={() => setTeamMembersDialogOpen(true)}
              fullWidth
            >
              View all
            </Button>
            {teamMembersDialogOpen && (
              <TeamSettingsDialog
                initialTab="members"
                open={teamMembersDialogOpen}
                setDialogOpen={setTeamMembersDialogOpen}
              />
            )}
          </Grid>
        </>
      )}

      <Grid
        item
        container
        xs={12}
        sx={{
          pt: teamMemberData?.length >= LIST_LENGTH ? 1 : 3,
          pb: 0.5,
          px: 1
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Button
          fullWidth
          variant="text"
          onClick={() => setTeamInvitesDialogOpen(true)}
        >
          Add member
        </Button>

        <TeamInvitesDialog
          open={teamInvitesDialogOpen}
          setDialogOpen={setTeamInvitesDialogOpen}
          inviteUsersViewOpen
        />
      </Grid>
    </Grid>
  );
};

export default OverviewTeamMembers;
